import ngModule from '../../../ng/index.js';
import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import rentalsService from '@services/rentals.service.js';
import { DELIVERY_STATUS_IDS } from '@const/delivery-statuses';
import { RENTAL_STATUS_IDS } from '@const/rental-status';
import { RENTAL_TYPES } from '@const/rental-types';

import toastService from '@services/toast.service.js';
import rentalStatusActionsConfirmationModalTemplate from './rental-status-actions-confirmation-modal.html';
import { userInFeatureGroups, FLOOR_RATE_APPROVAL } from '../../../helpers/admin-feature-permissions';

import deliveriesService from '@services/deliveries.service';
import RentalCancellationPopover from './rental-cancellation-popover.js';
import { useFlags } from '@equipmentshare/feature-flags';

function RentalStatusActionsCtrl(
	$q,
	$scope,
	$mdDialog,
	esAdminRentalService,
	esRentalService,
	esRentalOverdueTasksService,
	esCanonicalizer,
	esDeliveriesService
) {
	const controller = this;
	let refreshing = false;

	controller.$onChanges = refresh;

	$scope.$watchGroup(
		['rental.equipment_id', 'dropOffDelivery.delivery_status_id', 'returnDelivery.delivery_status_id'],
		refresh
	);
	function refresh() {
		if (refreshing) {
			return;
		}
		$scope.rentalActions = [];
		if (!controller.rental) {
			return;
		}

		controller.onSave();
		refreshing = true;
		$scope.rental = esCanonicalizer.canonicalize('rental', controller.rental);
		$scope.updatingRental = false;
		const xFields = '{ delivery_id, delivery_status_id }';
		const queryCanApproveRentals = () => {
			userInFeatureGroups(FLOOR_RATE_APPROVAL).then((canApprove) => {
				$scope.canApprove = canApprove;
				setRentalActions($scope.rental.rental_status, $scope.dropOffDelivery, $scope.returnDelivery);
				$scope.$apply();
			});
		};

		if ($scope.rental.rental_type_id != RENTAL_TYPES.MOBILE_TOOL_TRAILER) {
			$q.all([
				deliveriesService.fetch($scope.rental.drop_off_delivery_id, { headers: { 'X-Fields': xFields } }),
				deliveriesService.fetch($scope.rental.return_delivery_id, { headers: { 'X-Fields': xFields } }),
			])
				.then((deliveries) => {
					$scope.dropOffDelivery = esCanonicalizer.canonicalize('delivery', deliveries[0]);
					$scope.returnDelivery = esCanonicalizer.canonicalize('delivery', deliveries[1]);
					queryCanApproveRentals();
				})
				.finally(() => {
					refreshing = false;
				});
		} else {
			queryCanApproveRentals();
			refreshing = false;
		}
	}

	$scope.isDatesEffectedByDST = () => {
		return moment($scope.rental.start_date).isDST() !== moment($scope.rental.end_date).isDST();
	};

	function setRentalActions(rentalStatus, dropOffDelivery, returnDelivery) {
		const status = esAdminRentalService.RENTAL_STATUS;

		if (rentalStatus.rental_status_id === RENTAL_STATUS_IDS.NEEDS_APPROVAL_RENTAL_STATUS_ID) {
			if ($scope.canApprove) {
				$scope.rentalActions = [
					{ name: 'Approve Rental', disabled: false, className: 'md-primary', onclick: approveRental },
					{
						name: 'Cancel Rental',
						disabled: false,
						className: 'md-warn',
						onclick: function ($event) {
							openCancelPopover($event);
						},
					},
				];
			} else {
				$scope.rentalActions = [
					{
						name: 'Cancel Rental',
						disabled: false,
						className: 'md-warn',
						onclick: function ($event) {
							openCancelPopover($event);
						},
					},
				];
			}
		} else if (
			[status.OPEN_ID, status.DRAFT_ID, status.PENDING_ID, status.SCHEDULED_ID].indexOf(
				rentalStatus.rental_status_id
			) !== -1 &&
			dropOffDelivery.delivery_status_id !== DELIVERY_STATUS_IDS.COMPLETED
		) {
			$scope.rentalActions = [
				{
					name: 'On Rent Rental',
					disabled: !$scope.rental.equipment_id && !$scope.rental.active_part_assignment,
					className: 'md-primary',
					onclick: onRentConfirm,
				},
				{
					name: 'Cancel Rental',
					disabled: false,
					className: 'md-warn',
					onclick: function ($event) {
						openCancelPopover($event);
					},
				},
			];
		} else if (
			[status.OPEN_ID, status.DRAFT_ID, status.PENDING_ID, status.SCHEDULED_ID].indexOf(
				rentalStatus.rental_status_id
			) !== -1 &&
			dropOffDelivery.delivery_status_id === DELIVERY_STATUS_IDS.COMPLETED
		) {
			$scope.rentalActions = [
				{
					name: 'On Rent Rental',
					disabled: !$scope.rental.equipment_id && !$scope.rental.active_part_assignment,
					className: 'md-primary',
					onclick: onRentConfirm,
				},
			];
		} else if (rentalStatus.rental_status_id === status.ON_RENT_ID) {
			$scope.rentalActions = [
				{
					name: 'Off Rent Rental',
					disabled: false,
					className: 'md-primary',
					onclick: $scope.rental.rental_type_id === RENTAL_TYPES.BULK ? offRentPartRentalConfirm : offRentConfirm,
				},
			];
		} else if (
			rentalStatus.rental_status_id === status.OFF_RENT_ID &&
			returnDelivery &&
			returnDelivery.delivery_status_id !== DELIVERY_STATUS_IDS.COMPLETED
		) {
			$scope.rentalActions = [
				{ name: 'Re On Rent Rental', disabled: false, className: 'md-primary', onclick: reOnRentConfirm },
			];
		} else {
			$scope.rentalActions = [];
		}
	}

	function openCancelPopover($event) {
		if (!$event || !$event.currentTarget) {
			return;
		}
		const buttonElement = $event.currentTarget;
		const container = document.createElement('div');
		document.body.appendChild(container);

		if (window.__RENTAL_CANCELLATION__) {
			window.__RENTAL_CANCELLATION__.showCancellation({
				buttonElement,
				rentalId: $scope.rental.rental_id,
				cancelRental,
				container,
			});
		}
	}

	function onRentConfirm() {
		if ($scope.dropOffDelivery.delivery_status_id !== DELIVERY_STATUS_IDS.COMPLETED) {
			toastService.showError('Initial Drop Off must be completed to On Rent rental.');
			return;
		}
		showRentalDateConfirmation('onRent');
	}
	function onRentRental(startDate) {
		esAdminRentalService
			.patchRental($scope.rental.rental_id, {
				rental_status_id: RENTAL_STATUS_IDS.ON_RENT_RENTAL_STATUS_ID,
				start_date: startDate,
			})
			.then(() => {
				esRentalOverdueTasksService.refresh($scope.rental.order.market_id);
				toastService.showSuccess(`Rental #${$scope.rental.rental_id} was successfully on rented!`);
				refresh();
			})
			.catch((err) => {
				toastService.showError(
					err.data && err.data.message
						? err.data.message
						: `There was a problem on renting rental #${$scope.rental.rental_id}.`
				);
			})
			.finally(() => {
				$scope.closeDialog();
				$scope.updatingRental = false;
			});
	}

	function offRentConfirm() {
		showRentalDateConfirmation('offRent');
	}
	function offRentRental(endDate) {
		esAdminRentalService
			.patchRental($scope.rental.rental_id, {
				rental_status_id: RENTAL_STATUS_IDS.OFF_RENT_RENTAL_STATUS_ID,
				off_rent_date_requested: endDate,
				end_date: endDate,
			})
			.then(() => {
				esRentalOverdueTasksService.refresh($scope.rental.order.market_id);
				toastService.showSuccess(`Rental #${$scope.rental.rental_id} was successfully off rented!`);
				refresh();
			})
			.catch((err) => {
				toastService.showError(
					err.data && err.data.message
						? err.data.message
						: `There was a problem off renting rental #${$scope.rental.rental_id}.`
				);
			})
			.finally(() => {
				$scope.closeDialog();
				$scope.updatingRental = false;
			});
	}

	function offRentPartRentalConfirm() {
		showRentalDateConfirmation('offRentPartRental');
	}
	async function offRentPartRental(endDate, offRentQuantity) {
		try {
			await rentalsService.endPartRental($scope.rental.rental_id, {
				off_rent_quantity: offRentQuantity,
				end_date: moment(endDate).toISOString(),
			});
			esRentalOverdueTasksService.refresh($scope.rental.order.market_id);
			toastService.showSuccess(
				`${offRentQuantity} parts from Rental #${$scope.rental.rental_id} were successfully off rented!`
			);
			refresh();
		} catch (err) {
			const data = await err?.json();
			toastService.showError(
				data && data.message
					? data.message
					: `There was a problem off renting parts for rental #${$scope.rental.rental_id}.`
			);
		} finally {
			$scope.closeDialog();
			$scope.updatingRental = false;
		}
	}

	function reOnRentConfirm() {
		showRentalDateConfirmation('reOnRent');
	}
	function reOnRentRental(endDate) {
		esAdminRentalService
			.patchRental($scope.rental.rental_id, {
				rental_status_id: RENTAL_STATUS_IDS.ON_RENT_RENTAL_STATUS_ID,
				end_date: endDate,
			})
			.then(() => {
				esRentalOverdueTasksService.refresh($scope.rental.order.market_id);
				toastService.showSuccess(`Rental #${$scope.rental.rental_id} was successfully re-on rented!`);
				refresh();
			})
			.catch((err) => {
				toastService.showError(
					err.data && err.data.message
						? err.data.message
						: `There was a problem on renting rental #${$scope.rental.rental_id}.`
				);
			})
			.finally(() => {
				$scope.closeDialog();
				$scope.updatingRental = false;
			});
	}

	function cancelRental() {
		return esAdminRentalService
			.patchRental($scope.rental.rental_id, { rental_status_id: RENTAL_STATUS_IDS.CANCELLED_RENTAL_STATUS_ID })
			.then(() => {
				esRentalOverdueTasksService.refresh($scope.rental.order.market_id);
				toastService.showSuccess(`Rental #${$scope.rental.rental_id} was successfully cancelled!`);
				refresh();
			})
			.catch((err) => {
				toastService.showError(
					err.data && err.data.message
						? err.data.message
						: `There was a problem cancelling rental #${$scope.rental.rental_id}.`
				);
			});
	}

	function approveRental() {
		return esAdminRentalService
			.patchRental($scope.rental.rental_id, { rental_status_id: RENTAL_STATUS_IDS.DRAFT_RENTAL_STATUS_ID })
			.then(() => {
				esRentalOverdueTasksService.refresh($scope.rental.order.market_id);
				toastService.showSuccess(`Rental #${$scope.rental.rental_id} was successfully approved!`);
				refresh();
			})
			.catch((err) => {
				toastService.showError(
					err.data && err.data.message
						? err.data.message
						: `There was a problem approving rental #${$scope.rental.rental_id}.`
				);
			});
	}

	function showRentalDateConfirmation(action) {
		const rentalStartDate = $scope.rental ? moment($scope.rental.start_date).startOf('minute').toDate() : undefined;
		const rentalEndDate = moment($scope.rental.end_date).startOf('minute').toDate();

		$scope.rentalLength = esRentalService.getHumanizedDuration(rentalStartDate, rentalEndDate);
		$scope.dateConstraint = {
			constraint: action === 'onRent' ? 'max' : 'min',
			constraintDate:
				action === 'onRent' ? rentalEndDate : rentalStartDate ? rentalStartDate : moment().startOf('minute').toDate(),
			date: action === 'onRent' ? rentalStartDate : moment().startOf('minute').toDate(),
			message: setDateConstraintMessage(action),
			type: action === 'onRent' ? 'Start' : 'End',
		};
		const activeQuantity =
			$scope.rental && $scope.rental.active_part_assignment ? $scope.rental.active_part_assignment.quantity : 1;
		$scope.offRentQuantity = {
			max: activeQuantity,
			required: action === 'offRentPartRental',
			value: activeQuantity,
		};
		$scope.closeDialog = () => {
			$mdDialog.hide();
		};
		$scope.calculateRentalLength = () => {
			if ($scope.dateConstraint.type === 'Start') {
				$scope.rentalLength = esRentalService.getHumanizedDuration($scope.dateConstraint.date, rentalEndDate);
			}
			if ($scope.dateConstraint.type === 'End') {
				$scope.rentalLength = esRentalService.getHumanizedDuration(rentalStartDate, $scope.dateConstraint.date);
			}
		};
		$scope.confirmRentalDate = () => {
			$scope.updatingRental = true;
			if (action === 'onRent') {
				onRentRental(moment($scope.dateConstraint.date).valueOf());
			}
			if (action === 'offRent') {
				offRentRental(moment($scope.dateConstraint.date).valueOf());
			}
			if (action === 'offRentPartRental') {
				offRentPartRental(moment($scope.dateConstraint.date).valueOf(), $scope.offRentQuantity.value);
			}
			if (action === 'reOnRent') {
				reOnRentRental(moment($scope.dateConstraint.date).valueOf());
			}
		};

		$scope.calculateRentalLength();

		$mdDialog.show({
			template: rentalStatusActionsConfirmationModalTemplate,
			scope: $scope,
			preserveScope: true,
			clickOutsideToClose: true,
		});
	}

	function setDateConstraintMessage(action) {
		if (action === 'onRent') {
			return 'Date cannot be after the rental end date.';
		}
		if (action === 'offRent' || action === 'offRentPartRental') {
			return 'Date cannot be before the rental start date.';
		}
		if (action === 'reOnRent') {
			return 'Date must be in the future.';
		}
	}
}

ngModule.controller('RentalStatusActionsCtrl', RentalStatusActionsCtrl);
