import esApi from './es-api.service';

export function fetchWorkOrders(params) {
	const queryString = esApi.createQueryString(params);
	const workOrdersPromise = esApi.request(`/admin/work-orders${queryString}`);

	return workOrdersPromise;
}
export function getWorkOrderPrefix(workOrder) {
	const workOrderTypePrefixes = {
		1: 'WO',
		2: 'INSP',
	};
	return workOrderTypePrefixes[workOrder.work_order_type_id];
}

const workOrdersService = {
	fetchWorkOrders,
	getWorkOrderPrefix,
};

export default workOrdersService;
