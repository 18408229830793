import _map from 'lodash/map';

angular.module('esApiClient').factory('esOperationsService', function (esApiFactoryV2, esCanonicalizer, esDeliveriesService, $q) {
  let service = this;
  let canonicalizeAssetCondition = esCanonicalizer.canonicalizer('asset_condition_snapshot');

  service.getDeliveries = params => {
    return esApiFactoryV2.getDeliveries(params)
      .then(_ => _map(_, esDeliveriesService.transformDeliveryForLocalUse));
  };

  service.getDelivery = id => {
    return esApiFactoryV2.getDelivery(id)
      .then(esDeliveriesService.transformDeliveryForLocalUse);
  };

  service.getAsset = esApiFactoryV2.opsGetAsset;

  service.addConditionSnapshot = snapshot => {
    return esApiFactoryV2.addConditionSnapshot(snapshot);
  };

  service.addDeliveryInspection = (deliveryId, payload) => {
    return esApiFactoryV2.addDeliveryInspection(deliveryId, payload);
  };

  service.getConditionSnapshotsByDeliveryId = ( delivery_id, mask = null ) =>
    esApiFactoryV2.opsGetConditionSnapshots( { delivery_id }, mask )
      .then(canonicalizeAssetCondition);

  service.getConditionSnapshotsByRentalId = ( rental_id, mask = null ) =>
    esApiFactoryV2.opsGetConditionSnapshots( { rental_id }, mask )
      .then(canonicalizeAssetCondition);

  return {
    getDeliveries: service.getDeliveries,
    getDelivery: service.getDelivery,
    getAsset: service.getAsset,
    addConditionSnapshot: service.addConditionSnapshot,
    addDeliveryInspection: service.addDeliveryInspection,
    getConditionSnapshots: service.getConditionSnapshots,
    getConditionSnapshotsByRentalId: service.getConditionSnapshotsByRentalId
  }
});
