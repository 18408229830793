import angular from 'angular';
import _find from 'lodash/find';
import template from './maintenance-group-picker.html';

angular.module("esNgComponents")
    .directive('maintenanceGroupPicker', function () {
      return {
        template,
        controller: 'MaintenanceGroupPickerCtrl as ctrl',
        scope: {
          ngModel: '=',
          selectedMaintenanceGroup: '=',
          selectLabel: '@'
        }
      };
    })
    .controller('MaintenanceGroupPickerCtrl', function ($scope, esApiFactoryV2) {

      $scope.init = function () {
        esApiFactoryV2.getMaintenanceGroups()
            .then(function (response) {
              $scope.maintenanceGroups = response.data;
            });
      };

      $scope.$watch('ngModel', function (newModel, oldModel) {
        if (newModel !== oldModel) {
          syncGroupObjectWithIdProperty(newModel)
        }
      });

      $scope.init();

      function syncGroupObjectWithIdProperty(id) {
        var selectedGroup = _find($scope.maintenanceGroups, function (itGroup) {
          return itGroup.maintenance_group_id === parseInt(id);
        });
        $scope.selectedMaintenanceGroup = removeAngularObjectTracking(selectedGroup);
      }

      function removeAngularObjectTracking(objectWithTracking) {
        return angular.copy(objectWithTracking);
      }

    });
