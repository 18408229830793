import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ButtonGroup from "../button-group/button-group.js";
import Button from "../button/button.js";

import buttonDropDownStyles from "./button-drop-down.module.css";

const chevronIcon = "fasChevronDown";

/**
 * Uses all of the same properties available to a normal button.
 *
 * Renders a button that summons a drop-down menu of actions when clicked.
 * If a primary action is provided to this component, then a secondary button
 * will be rendered to summon the drop-down menu, and the primary button will
 * trigger the supplied primary action when clicked.
 */
export default class ButtonDropDown extends React.PureComponent{
  constructor( props ) {
    super( props );

    this.state = {
      isExpanded: false,
    };

    this.handleDocumentClick = this.handleDocumentClick.bind( this );
    this.setContainerRef = this.setContainerRef.bind( this );
    this.toggleExpansion = this.toggleExpansion.bind( this );
  }

  componentDidMount() {
    document.addEventListener( "click", this.handleDocumentClick, false );
  }

  componentWillUnmount() {
    document.removeEventListener( "click", this.handleDocumentClick, false );
  }

  closeExpansion() {
    this.setState( { isExpanded: false } );
  }

  executeAction( action ) {
    return ( ...args ) => {
      if ( typeof action === "function" ) {
        action( ...args );
      }

      this.closeExpansion();
    };
  }

  handleDocumentClick( evt ) {
    if ( this.state.isExpanded && !this.containerRef.contains( evt.target ) ) {
      this.closeExpansion();
    }
  }

  renderActions( actions ) {
    let actionsListClassNames = classNames( buttonDropDownStyles.dropdown, buttonDropDownStyles.actionList );

    return (
      <ul className={actionsListClassNames}>
        {actions.map( ( action ) => {
          let liProps = {
            className: classNames( { [ buttonDropDownStyles.disabled ]: action.disabled } ),
            onClick: !action.disabled ? this.executeAction( action.onClick ) : undefined,
          };

          return ( <li key={action.label} {...liProps}>{action.label}</li> );
        } )}
      </ul>
    );
  }

  renderButton() {
    let {
      actions,
      buttonIcon,
      children,
      isRightAligned,
      primaryAction,
      ...buttonProps
    } = this.props;

    let renderedActions = children
      ? ( <div className={buttonDropDownStyles.dropdown}>{children}</div> )
      : this.renderActions( actions );

    if ( primaryAction ) {
      let groupClassName = classNames( buttonDropDownStyles.group, {
        [ buttonDropDownStyles.rightAligned ]: isRightAligned,
      } );

      return (
        <ButtonGroup className={groupClassName} collapsed inline>
          <Button {...buttonProps} onClick={this.executeAction( primaryAction )} />
          {this.state.isExpanded && renderedActions}
          <Button {...buttonProps} icon={buttonIcon} label="" onClick={this.toggleExpansion} rightIcon />
        </ButtonGroup>
      );
    } else {
      return (
        <React.Fragment>
          {this.state.isExpanded && renderedActions}
          <Button {...buttonProps} icon={buttonIcon} onClick={this.toggleExpansion} rightIcon />
        </React.Fragment>
      );
    }
  }

  setContainerRef( containerRef ) {
    this.containerRef = containerRef;
  }

  toggleExpansion() {
    this.setState( { isExpanded: !this.state.isExpanded } );
  }

  render() {
    let { containerClassNames } = this.props;

    return (
      <span className={classNames( buttonDropDownStyles.container, containerClassNames )} ref={this.setContainerRef}>
        {this.renderButton()}
      </span>
    );
  }
}

ButtonDropDown.propTypes = {
  /** An array of objects which represent the actions that can be taken in the drop-down */
  actions: PropTypes.arrayOf(
    PropTypes.shape( {
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    } )
  ),
  /** Overrides the default icon rendered on the underlying Button */
  buttonIcon: PropTypes.string,
  /** Overrides any actions array passed in and renders content inside the dropdown instead */
  children: PropTypes.node,
  /** additional styles to be applied to the container element surrounding the ButtonDropDown itself */
  containerClassNames: PropTypes.string,
  /** Informs the button drop down that it is right-aligned, and should style itself accordingly. */
  isRightAligned: PropTypes.bool,
  /** If this `onClick` handler is defined, the drop-down will be rendered as a split-button. Otherwise, the entire button will summon the actions drop-down. */
  primaryAction: PropTypes.func,
};

ButtonDropDown.defaultProps = {
  actions: undefined,
  buttonIcon: chevronIcon,
  children: undefined,
  containerClassNames: undefined,
  isRightAligned: false,
  primaryAction: undefined,
};
