import React from "react";
import PropTypes from "prop-types";

import cn from "classnames";
import toastStyles from "./toast.module.css";

export class Toast extends React.PureComponent {
  constructor( props ) {
    super( props );

    this.state = { burnt: false };

    this.closeOnClick = this.closeOnClick.bind( this );
    this.burnToast = this.burnToast.bind( this );
  }

  componentDidMount() {
    this.timeout = setTimeout( this.burnToast, this.props.toast.timeout );
  }

  burnToast() {
    this.setState( { burnt: true } );

    setTimeout( () => {
      clearTimeout( this.timeout );
      this.props.burnToast( this.props.toast );
    }, 450 );
  }

  closeOnClick() {
    if( !this.props.toast.closeOnClick ) { return; }

    this.burnToast();
  }

  render() {
    var classname = cn(
      toastStyles.toast,
      toastStyles[this.props.toast.type],
      { [toastStyles.burnt]: this.state.burnt }
    );

    return (
      <div
        className={classname}
        onClick={this.closeOnClick}
      >
        {this.props.toast.message}
      </div>
    );
  }
}

Toast.propTypes = {
  /** Function that is passed in from ToastContainer to remove a toast */
  burnToast: PropTypes.func.isRequired,
  /** The Toast Object { closeOnClick: Boolean, id: Generated Number, message: String, type: String, timeout: Number } */
  toast: PropTypes.object.isRequired,
};

export default Toast;
