import markerIcon from './marker.png';
import styles from './static-marker-overlay.module.css';

export default function createOverlay(map) {
	const overlay = new google.maps.OverlayView();

	overlay.setMap(map);

	overlay.onAdd = () => {
		const container = (overlay.rootElement = document.createElement('div'));
		const marker = document.createElement('img');

		container.classList.add(styles.container);
		marker.src = markerIcon;
		marker.classList.add(styles.marker);

		container.appendChild(marker);
		overlay.getMap().getDiv().appendChild(container);
	};
	overlay.onRemove = () => {
		overlay.rootElement.parentNode.removeChild(overlay.rootElement);
		overlay.rootElement = null;
	};

	return overlay;
}
