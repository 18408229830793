import ngModule from '../ng/index.js';

import assetHomeTemplate from '../js/assets/asset-home.html';
import assetDetailsTemplate from '../js/asset/asset-detail.html';
import assetInspectionsTemplate from '../js/asset/inspections/inspections.html';
import assetInspectionTemplate from '../js/asset/inspections/inspection/inspection.html';
import assetServiceUpcomingTemplate from '../js/asset/asset-service/asset-service-upcoming.html';
import assetServiceHistoryTemplate from '../js/asset/asset-service/asset-service-history.html';

import '../js/asset';
import '../js/assets';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.assets', {
			url: '/assets?trackerId&serialNumber&equipmentClassId&query',
			template: assetHomeTemplate,
			controller: 'AssetHomeCtrl',
		})

		.state('home.asset-detail', {
			url: '/assets/asset/:assetId',
			template: assetDetailsTemplate,
			controller: 'AssetDetailCtrl',
		})
		.state('home.asset-detail.edit', {
			url: '/edit',
		})
		.state('home.asset-detail.rental-history', {
			url: '/rental-history',
		})
		.state('home.asset-detail.inspections', {
			url: '/inspections',
			template: assetInspectionsTemplate,
			controller: 'AssetDetailInspectionsCtrl',
		})
		.state('home.asset-detail.inspections.inspection', {
			url: '/:inspectionId',
			template: assetInspectionTemplate,
			controller: 'AssetDetailInspectionCtrl',
		})
		.state('home.asset-detail.service', {
			url: '/service',
			template: '<div ui-view></div>',
		})
		.state('home.asset-detail.service.upcoming', {
			url: '/upcoming',
			template: assetServiceUpcomingTemplate,
		})
		.state('home.asset-detail.service.history', {
			url: '/history',
			template: assetServiceHistoryTemplate,
		});
});
