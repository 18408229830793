import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import _compact from 'lodash/compact';

import { Autocomplete } from '@equipmentshare/react-ui-components';
import { CACHE_TIMES, ENTITY_TYPES, cacheKey } from '@equipmentshare/admin-hooks';

import rentalProtectionPlansService from '@services/rental-protection-plans.service';
import quotesService from '@services/quotes.service';

const STANDARD_RPP_TYPE_ID = 1;

const RentalProtectionPlanPicker = ({
	placeholder = 'None',
	onChange,
	selectedRentalProtectionPlanId,
	required = false,
	orderId,
	...rest
}) => {
	const { data: quote, isLoading: quoteLoading } = useQuery(
		cacheKey(ENTITY_TYPES.QUOTE, 'RentalProtectionPlanPicker'),
		() => quotesService.fetchQuotesByOrderId(orderId),
		{ staleTime: CACHE_TIMES.LONG, cacheTime: CACHE_TIMES.LONG }
	);

	const { data: rentalProtectionPlans = [], isLoading: rentalProtectionPlansLoading } = useQuery(
		cacheKey(ENTITY_TYPES.RENTAL_PROTECTION_PLAN, 'RentalProtectionPlanPicker'),
		() =>
			rentalProtectionPlansService.fetch({
				orderId,
				quoteCreatedDate: quote?.items?.[0]?.created_date ?? null,
				includedPlanId: selectedRentalProtectionPlanId,
			}),
		{ enabled: !quoteLoading, staleTime: CACHE_TIMES.LONG, cacheTime: CACHE_TIMES.LONG }
	);

	const isLoading = quoteLoading || rentalProtectionPlansLoading;

	const quoteCreatedDate = quote?.items?.[0]?.created_date ?? null;

	const rppOptionRenderer = (plan) => {
		const percent = plan.percent && Math.round(plan.percent * 10000) / 100;
		return _compact([plan.name, percent && `${percent}%`]).join(' - ');
	};

	const options = useMemo(
		() => (required ? rentalProtectionPlans : [...rentalProtectionPlans, { name: 'None', value: null }]),
		[required, rentalProtectionPlans]
	);

	// Default to the standard RPP
	const defaultRPP =
		// check if selectedRentalProtectionPlanId is `undefined` since `null` is equivalent to 'None'
		selectedRentalProtectionPlanId === undefined && rentalProtectionPlans
			? rentalProtectionPlans?.find((plan) => plan.rental_protection_plan_type_id === STANDARD_RPP_TYPE_ID)
					?.rental_protection_plan_id
			: selectedRentalProtectionPlanId;

	useEffect(() => {
		if (!isLoading) onChange(defaultRPP);
	}, [isLoading]);

	return (
		<Autocomplete
			showLoading={isLoading}
			labelKey="name"
			onChange={onChange}
			options={options}
			placeholder={placeholder}
			removeSelected={true}
			disableFiltering={true}
			required={required}
			value={defaultRPP}
			valueKey="rental_protection_plan_id"
			valueRenderer={(plan) => rppOptionRenderer(plan)}
			optionRenderer={(plan) => rppOptionRenderer(plan)}
			{...rest}
		/>
	);
};

RentalProtectionPlanPicker.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	orderId: PropTypes.number,
	selectedRentalProtectionPlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};

export default RentalProtectionPlanPicker;
