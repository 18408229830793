import React, { useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useColumns } from './column-provider.js';

const DEFAULT_FILTER_METHOD = (entry, { value }, key) => {
	return String(_get(entry, key, ''))
		.toLowerCase()
		.includes(String(value || '').toLowerCase());
};

const Context = React.createContext();

export function useFilter() {
	return useContext(Context);
}

const FilterProvider = ({ children }) => {
	const { columnIds } = useColumns();

	const [filterMethods, setFilterMethods] = useState({});
	const [filterStates, setFilterStates] = useState({});
	const [filterKeys, setFilterKeys] = useState({});
	const [filtersActive, setFiltersActive] = useState({});

	const setFilterMethod = useCallback(
		(columnId, method) => {
			setFilterMethods((s) => ({ ...s, [columnId]: method }));
		},
		[setFilterMethods]
	);
	const getFilterMethod = useCallback((columnId) => filterMethods[columnId] || DEFAULT_FILTER_METHOD, [filterMethods]);

	const setFilterState = useCallback(
		(columnId, state) => {
			setFilterStates((s) => {
				const existingState = s[columnId];
				const newState = typeof state === 'function' ? state(existingState) : state;

				return { ...s, [columnId]: newState };
			});
		},
		[setFilterStates]
	);
	const getFilterState = useCallback((columnId) => filterStates[columnId] || {}, [filterStates]);

	const setFilterKey = useCallback(
		(columnId, method) => {
			setFilterKeys((s) => ({ ...s, [columnId]: method }));
		},
		[setFilterKeys]
	);
	const getFilterKey = useCallback((columnId) => filterKeys[columnId], [filterKeys]);

	const setFilterActive = useCallback(
		(columnId, method) => {
			setFiltersActive((s) => ({ ...s, [columnId]: method }));
		},
		[setFiltersActive]
	);
	const getFilterActive = useCallback((columnId) => !!filtersActive[columnId], [filtersActive]);

	const filterObjects = useCallback(
		(objects = []) => {
			let filteredEntries = Array.from(objects);

			columnIds.forEach((columnId) => {
				const filterMethod = getFilterMethod(columnId);
				const filterState = getFilterState(columnId);
				const filterKey = getFilterKey(columnId);
				const filterActive = getFilterActive(columnId);

				if (filterMethod && filterState && filterKey && filterActive) {
					filteredEntries = filteredEntries.filter((obj) => filterMethod(obj, filterState, filterKey));
				}
			});

			return filteredEntries;
		},
		[columnIds, getFilterMethod, getFilterState, getFilterKey, getFilterActive]
	);

	const context = useMemo(
		() => ({
			setFilterState,
			getFilterState,
			setFilterMethod,
			getFilterMethod,
			setFilterKey,
			getFilterKey,
			setFilterActive,
			getFilterActive,
			filterObjects,
		}),
		[
			setFilterState,
			getFilterState,
			setFilterMethod,
			getFilterMethod,
			setFilterKey,
			getFilterKey,
			setFilterActive,
			getFilterActive,
			filterObjects,
		]
	);

	return <Context.Provider value={context}>{children}</Context.Provider>;
};

FilterProvider.Context = Context;
FilterProvider.propTypes = {
	children: PropTypes.node,
};

export default FilterProvider;
