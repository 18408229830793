import angular from 'angular';
import template from './pie-chart.html';
import './pie-chart-ctrl.js';

angular.module("esNgComponents").component('pieChart', {
  template,
  controller: 'PieChartCtrl',
  bindings: {
    data: '<'
  }
});
