import ngModule from '../../../ng/index.js';
import React, { Suspense } from 'react';
import { react2angular } from 'react2angular';
import withGlobalProviders from '@components/with-global-providers';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

const RentalCreateForm = React.lazy(() => import('./rental-create-form'));

export const RentalCreateFormComponent = ({ ...props }) => (
	<Suspense fallback={<LoadingOverlay />}>
		<RentalCreateForm {...props} />
	</Suspense>
);

ngModule.component(
	'reactRentalCreateForm',
	react2angular(withGlobalProviders(RentalCreateFormComponent), ['order', 'onSubmit', 'isVisible', 'onClose'])
);
