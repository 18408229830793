import esApi from './es-api.service';

export const MATCH_LEVELS = [
	'houseNumber',
	'intersection',
	'street',
	'postalCode',
	'district',
	'city',
	'county',
	'state',
	'country',
];

function getMatchLevel(hereLocation) {
	const matchLevel = hereLocation.resultType;

	if (matchLevel === 'locality') {
		return hereLocation.localityType;
	}

	if (matchLevel === 'administrativeArea') {
		return hereLocation.administrativeAreaType;
	}

	return matchLevel;
}

function isLessThanEqualToMatchLevel(matchLevel, level) {
	return MATCH_LEVELS.indexOf(level) <= MATCH_LEVELS.indexOf(matchLevel);
}

export function convertHereLocationToEsLocation(hereLocation) {
	const hereAddress = hereLocation.address;

	return {
		city: hereAddress.city,
		state: {
			name: hereAddress.state,
			abbreviation: hereAddress.stateCode,
		},
		zip_code: hereAddress.postalCode,
		street_1: [hereAddress.houseNumber || '', hereAddress.street].join(' ').trim(),
		latitude: hereLocation.position.lat,
		longitude: hereLocation.position.lng,
	};
}

export async function matches(searchtext) {
	let results = await esApi.request('/address-matches', { searchtext });

	if (!Array.isArray(results)) {
		results = [];
	}

	return results;
}

export async function validate(params = {}) {
	const results = await esApi.request('/address-validation', params);

	return !!results.is_valid_address;
}

const hereApiService = {
	MATCH_LEVELS,
	getMatchLevel,
	isLessThanEqualToMatchLevel,
	matches,
	validate,
	convertHereLocationToEsLocation,
};

export default hereApiService;
