import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _uniqBy from 'lodash/unionBy';

import { Cell, CellProps } from '@equipmentshare/es-admin-table-components';

import branchesService from '@services/branches.service';

let publicBranchCache: Branch[] | null = null;
let publicBranchRequest: Promise<Branch[]> | null = null;

async function fetchBranches() {
	const results: Branch[][] = await Promise.all([
		branchesService.fetchPublic(),
		branchesService.search({ is_billable_branch: true }),
	]);

	return _uniqBy(
		results.reduce((a, b) => a.concat(b), []),
		'id'
	);
}

type Branch = {
	name: string;
};

export type BranchNameCellProps = {
	branchId?: number;
	branch?: Branch;
} & CellProps;

export const BranchNameCell: React.VFC<BranchNameCellProps> = ({ branch, branchId, ...props }) => {
	const [branches, setBranches] = useState<Branch[]>([]);

	useEffect(() => {
		if (publicBranchCache) {
			setBranches(publicBranchCache);
		} else if (publicBranchRequest) {
			publicBranchRequest.then(setBranches);
		} else {
			publicBranchRequest = fetchBranches().then((loadedBranches) => {
				publicBranchRequest = null;
				publicBranchCache = loadedBranches;
				setBranches(loadedBranches);

				return loadedBranches;
			});
		}
	}, []);

	const branchName = _get(branch || _find(branches, ['id', branchId]), 'name');

	return <Cell {...props}>{branchName}</Cell>;
};

export default BranchNameCell;
