import React from 'react';
import PropTypes from 'prop-types';

import SortedRows from '../sorted-rows/sorted-rows.js';
import FilteredRows from '../filtered-rows/filtered-rows.js';
import VirtualizedBody from '../virtualized-body/virtualized-body.js';

const SortedVirtualizedBody = ({ rows, ...props }) => (
	<FilteredRows rows={rows}>
		{(filteredRows) => (
			<SortedRows rows={filteredRows}>{(sortedRows) => <VirtualizedBody rows={sortedRows} {...props} />}</SortedRows>
		)}
	</FilteredRows>
);

SortedVirtualizedBody.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object),
};

export default SortedVirtualizedBody;
