import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

import InputSuggestions from '../../../../../components/input-suggestions/input-suggestions';
import hereApiService from '@services/here-api.service';

export const AddressSuggestionInput = ({
	id,
	value,
	onChange,
	onSelectLocation,
	filterSuggestions,
	label,
	fullWidth,
	...inputProps
}) => {
	const [results, setResults] = useState([]);

	const queryPlaces = useCallback(
		_debounce(async (query) => {
			if (query.length < 3) {
				return;
			}

			setResults(await hereApiService.matches(query));
		}, 500),
		[setResults]
	);

	const handleInputChange = useCallback(
		(e) => {
			if (onChange) {
				onChange(e.target.value);
			}
		},
		[queryPlaces, onChange, onSelectLocation, results]
	);
	const handleSelectOption = useCallback(
		(option) => {
			if (onSelectLocation) {
				onSelectLocation(hereApiService.convertHereLocationToEsLocation(option));
			}
		},
		[onSelectLocation]
	);

	useEffect(() => {
		queryPlaces(value);
	}, [value, queryPlaces]);

	const optionRenderer = useCallback(({ option }) => option.address.label || '');
	const filteredResults = useMemo(() => {
		return filterSuggestions ? filterSuggestions(results) : results;
	}, [results, filterSuggestions]);

	return (
		<InputSuggestions
			id={id || 'address-suggestions'}
			options={filteredResults}
			isOpen={true}
			optionRenderer={optionRenderer}
			inputProps={{ ...inputProps, value, onChange: handleInputChange, fullWidth }}
			onSelectOption={handleSelectOption}
			label={label}
			fullWidth={fullWidth}
		/>
	);
};

AddressSuggestionInput.propTypes = {
	id: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onSelectLocation: PropTypes.func,
	filterSuggestions: PropTypes.func,
	label: PropTypes.string,
	fullWidth: PropTypes.bool,
};

export default AddressSuggestionInput;
