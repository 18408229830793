import { Auth0Client } from '@auth0/auth0-spa-js';
import { configure } from '@equipmentshare/es-browser-cookie';
import jwtDecode from 'jwt-decode';
import {
	AUTH0_API_AUDIENCE,
	AUTH0_CLIENT_ID,
	AUTH0_DOMAIN,
	IS_DEV,
	AUTH0_DISABLED,
	AUTH0_LOGIN_METHOD,
} from '@const/env';
import { SECURITY_LEVEL_IDS } from '@const/user-security-level';
import usersService from './users.service';
import iframeControllerService from './iframe-controller.service';
import qs from 'qs';

export class AuthService {
	client = null;

	cookies = null;

	overrideToken = null;

	constructor() {
		this.cookies = configure({
			secure: !IS_DEV,
		});
		this.client = new Auth0Client({
			domain: AUTH0_DOMAIN,
			client_id: AUTH0_CLIENT_ID,
			redirect_uri: location.origin,
			audience: AUTH0_API_AUDIENCE,
			useRefreshTokens: true,
			cacheLocation: 'localstorage',
		});
	}

	async getDecodedToken() {
		const token = await this.getApiToken();
		if (token) {
			return await jwtDecode(token);
		}
		return null;
	}

	async setUserData() {
		const token = await this.getApiToken();
		this.cookies.jwt.set(token);

		const user = await usersService.fetchSelf();

		this.cookies.userId.set(user.user_id);
		this.cookies.companyId.set(user.company.company_id);

		switch (user.security_level_id) {
			case SECURITY_LEVEL_IDS.ADMIN:
				this.cookies.roles.set(['admin']);
				break;
			case SECURITY_LEVEL_IDS.COMPANY_OWNER:
				this.cookies.roles.set(['company-owner']);
				break;
			default:
				this.cookies.roles.set([]);
				break;
		}
	}

	async handleRedirectCallback() {
		if (qs.parse(window.location.search).state) {
			await this.client.handleRedirectCallback();

			window.open(location.origin + location.pathname + location.hash, '_self');
		}
	}

	async checkAuthenticated() {
		if (this.overrideToken) {
			return true;
		}

		const isAuth = await this.client.isAuthenticated();

		if (!isAuth) {
			if (AUTH0_LOGIN_METHOD === 'popup') {
				await this.client.loginWithPopup();
			} else {
				await this.client.loginWithRedirect({
					redirect_uri: location.href,
				});
			}
			return false;
		}

		return true;
	}

	async getApiToken(config = {}) {
		if (this.overrideToken) {
			return this.overrideToken;
		}

		await this.checkAuthenticated();

		const token = await this.client.getTokenSilently(config);
		this.cookies.jwt.set(token);

		return token;
	}

	async isAuthenticated() {
		if (this.overrideToken) {
			return true;
		}

		try {
			return await this.client.isAuthenticated();
		} catch (e) {}

		return false;
	}

	async getAuth0User() {
		if (this.overrideToken) {
			return null;
		}

		try {
			return await this.client.getUser();
		} catch (e) {}

		return null;
	}

	async setup() {
		if (AUTH0_DISABLED) {
			this.overrideToken = await iframeControllerService.askForApiToken();

			if (!this.overrideToken) {
				console.error('Failed to get an api token from the parent window, falling back to using Auth0');
			}
		}

		// if an override token was not set. then use Auth0
		if (!this.overrideToken) {
			await this.handleRedirectCallback();

			// do an initial fetch of the api token to setup auth0 client see https://auth0.com/docs/libraries/auth0-spa-js#create-the-client
			// this is required if cacheLocation !== localstorage
			// try {
			// 	await client.getTokenSilently();
			// } catch ( e ) {}

			await this.checkAuthenticated();
		}

		try {
			await this.setUserData();
		} catch (e) {
			throw new Error('Failed to get user from the api');
		}
	}

	logout(...args) {
		if (this.overrideToken) {
			return;
		}

		this.cookies.remove();
		this.client.logout(...args);
	}
}

const authService = new AuthService();

export default authService;
