import esApi from './es-api.service';

async function search(query, opts) {
	return await esApi.request(esApi.url`/branch-rental-rates`, query, opts);
}

const branchRentalRatesService = {
	search,
};

export default branchRentalRatesService;
