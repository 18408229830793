import angular from 'angular';
import ngModule from '../../../../ng/index.js';
import moment from 'moment-timezone';

import template from './time-picker.html';

ngModule.filter('numberFixedLength', () => {
	return function (n, len) {
		let num = parseInt(n, 10);

		len = parseInt(len, 10);
		if (isNaN(num) || isNaN(len)) {
			return n;
		}
		num = `${num}`;
		while (num.length < len) {
			num = `0${num}`;
		}

		return num;
	};
});

function TimePickerCtrl($scope) {
	$scope.init = function () {
		const timeMoment = moment($scope.pickTime);
		const momentHours = timeMoment.hours();

		$scope.time = {
			hours: momentHours < 12 ? momentHours : momentHours - 12,
			minutes: timeMoment.minutes(),
			ampm: momentHours < 12 ? 'am' : 'pm',
		};
		if (momentHours === 0) {
			$scope.time.hours = 12;
		}
		// TODO: filter got in the way of user editing
		// $scope.time.minutes = $filter('numberFixedLength')($scope.time.minutes, 2);
	};
}

ngModule.controller('TimePickerCtrl', TimePickerCtrl);

ngModule.directive('timePicker', () => {
	function link(scope) {
		// scope.closeDrawer = $mdSidenav('swapEquipmentAssignment').close;

		scope.$watch('pickTime', (newR, oldR) => {
			if (newR !== oldR && !angular.isObject(scope.time)) {
				scope.init();
			}
		});

		scope.$watch('time.hours', (newValue, oldValue) => {
			if (newValue) {
				let newHours = 0;

				if (scope.time.ampm === 'am') {
					if (newValue !== 12) {
						newHours = newValue;
					}
				} else {
					newHours = newValue + 12;
				}
				const pickTimeMoment = moment(scope.pickTime).hours(newHours);

				scope.pickTime = pickTimeMoment.toDate();
			}
		});

		scope.$watch('time.minutes', (newValue, oldValue) => {
			if (newValue) {
				const pickTimeMoment = moment(scope.pickTime).minutes(newValue);

				scope.pickTime = pickTimeMoment.toDate();
			}
		});

		scope.$watch('time.ampm', (newValue, oldValue) => {
			if (newValue && oldValue && angular.isDefined(scope.time.hours) && angular.isDefined(scope.time.minutes)) {
				const hoursAs24 = moment(scope.pickTime).hours();
				let newHours = 0;

				switch (oldValue) {
					case 'am':
						newHours = hoursAs24 + 12;
						break;
					case 'pm':
						newHours = hoursAs24 - 12;
						break;
				}
				const pickTimeMoment = moment(scope.pickTime).hours(newHours);

				scope.pickTime = pickTimeMoment.toDate();
			}
		});
	}

	return {
		template,
		link,
		controller: 'TimePickerCtrl',
		scope: {
			pickTime: '=',
			parentForm: '=',
		},
	};
});
