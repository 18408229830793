import React from "react";
import PropTypes from "prop-types";

import Tooltip from "../../../tooltip/tooltip.js";

// TODO: uncomment when es-track can load images
// import assetUnpluggedIcon from "../icon-images/asset-unplugged.svg";

const AssetUnpluggedIcon = ( { tooltipDirection, unpluggedTimestamp } ) => {
  const text = unpluggedTimestamp ? `Unplugged ${unpluggedTimestamp}`.trim() : "Asset Unplugged";

  return (
    <Tooltip direction={tooltipDirection} text={text}>
      <img src="images/asset-unplugged.svg" />
    </Tooltip>
  ); };

AssetUnpluggedIcon.propTypes = {
  tooltipDirection: PropTypes.string,
  unpluggedTimestamp: PropTypes.string,
};
AssetUnpluggedIcon.defaultProps = {
  tooltipDirection: "right",
  unpluggedTimestamp: "",
};

export default AssetUnpluggedIcon;
