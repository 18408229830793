import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Input from "../input/input.js";
import Icon from "../../icon/icon.js";

import { readQrFromFile } from "./qr-service.js";

import qrReaderStyles from "./qr-reader.module.css";

export default class QrReader extends React.PureComponent {
  constructor( props ) {
    super( props );

    this.state = {
      textValue: props.textValue,
    };

    this.handleFileChange = this.handleFileChange.bind( this );
    this.handleTextChange = this.handleTextChange.bind( this );
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if ( newProps.textValue !== this.props.textValue ) {
      this.setTextValue( newProps.textValue );
    }
  }

  handleFileChange( fileEvent ) {
    const imageType = /^image\//;
    const file = [ ...fileEvent.target.files ].shift();

    if ( file && imageType.test( file.type ) ) {
      readQrFromFile( file ).then(
        ( decodedValue ) => { this.setTextValue( decodedValue ); },
        () => { this.props.onError( "No QR Code Found in Image" ); }
      );
    }
    else {
      this.props.onError( "Invalid Image" );
    }
  }

  handleTextChange( textEvent ) {
    this.setTextValue( textEvent.target.value );
  }

  setTextValue( textValue ) {
    this.setState( { textValue } );

    if( typeof this.props.onChange === "function" ) {
      this.props.onChange( textValue );
    }
  }

  render() {
    let className = classNames( qrReaderStyles.qrReader, {
      [qrReaderStyles.disabled]: this.props.disabled,
    } );

    return (
      <div className={className}>
        <Input
          {...this.props}
          onChange={this.handleTextChange}
          type="text"
          value={this.state.textValue}
        />
        <label className={qrReaderStyles.fileLabel}>
          <Icon className={qrReaderStyles.icon} name="falQrcode" />
          <input
            disabled={this.props.disabled || this.props.readonly}
            onChange={this.handleFileChange}
            type="file"
          />
        </label>
      </div>
    );
  }
}

QrReader.defaultProps = {
  disabled: false,
  onChange: () => {},
  onError: () => {},
  readonly: false,
  textValue: "",
};

QrReader.propTypes = {
  /** mark the input as disabled */
  disabled: PropTypes.bool,
  /** onChange event handler */
  onChange: PropTypes.func,
  /** error handler */
  onError: PropTypes.func,
  /** mark the input as readonly */
  readonly: PropTypes.bool,
  /** value to set for the text input */
  textValue: PropTypes.string,
};
