import moment from 'moment';
import { getFullName } from '@services/users.service.js';

export function sortStringIgnoreCase(a, b) {
	if (a === null || a === undefined) {
		return -1;
	} else if (b === null || b === undefined) {
		return 1;
	}

	a = String(a).toLowerCase();
	b = String(b).toLowerCase();

	if (a < b) {
		return -1;
	} else if (a > b) {
		return 1;
	} else {
		return 0;
	}
}

export function sortDate(a, b) {
	return Math.sign(moment(a).diff(b)) || 0;
}

export function sortPhoneNumber(a, b) {
	a = parseInt(String(a).replace(/[^\d]/g, ''));
	b = parseInt(String(b).replace(/[^\d]/g, ''));

	if (!Number.isFinite(b)) {
		return -1;
	}
	if (!Number.isFinite(a)) {
		return 1;
	}
	if (!Number.isFinite(a) && !Number.isFinite(b)) {
		return 0;
	}

	return sortStringIgnoreCase(a, b);
}

export function sortBoolean(a, b) {
	if (!a && b) {
		return -1;
	} else if (a && !b) {
		return 1;
	} else {
		return 0;
	}
}

export function sortUser(a, b) {
	return sortStringIgnoreCase(getFullName(a), getFullName(b));
}

export function sortBy(arr, prop, sortMethod) {
	return [...arr].sort((a, b) => {
		return sortMethod(a[prop], b[prop]);
	});
}
