import _each from 'lodash/each';
import map from 'lodash/fp/map';

angular.module('esApiClient').factory('esAdminMaintenanceService', function ($http, esApiFactoryV2, esEnv, esCanonicalizer, $q) {
  const asset = esCanonicalizer.canonicalizer('asset');
  const serviceRecord = esCanonicalizer.canonicalizer('service_record');
  const serviceInterval = esCanonicalizer.canonicalizer('service_interval');

  function getServiceRecord(serviceRecordId) {
    return esApiFactoryV2.getServiceRecord(serviceRecordId)
      .then(res => res.data)
      .then(serviceRecord);
  }

  function setServiceRecordPhotoUrls(record) {
    if (record.filename) {
      record._photoUrl = esApiFactoryV2.getPhotoUrl({photo: record});
    }
    return record;
  }

  function postServiceRecord(assetId, payload) {
    return esApiFactoryV2.postAssetServiceRecord(assetId, payload, false);
  }

  function patchServiceRecord(serviceRecordId, payload) {
    return esApiFactoryV2.patchServiceRecord(serviceRecordId, payload)
      .then(() => getServiceRecord(serviceRecordId));
  }

  function getAssetServiceRecords(assetId){
    return esApiFactoryV2.getAssetServiceRecords(assetId)
      .then(map(setServiceRecordPhotoUrls))
      .then(map(serviceRecord))
      .then(map(record => {
        record.asset = asset(record.asset_id);
        record.service_interval = serviceInterval(record.service_interval_id);
        return record;
      }))
  }

  function getAssetUpcomingServices(assetId){
    return esApiFactoryV2.getAssetUpcomingServices(assetId)
      .then(services => $q.all(services.map(setIntervals)))
  }

  function setIntervals( service ) {
    return esApiFactoryV2.getMaintenanceGroupInterval( service.maintenance_group_interval_id )
      .then( mgInterval => service.maintenance_group_interval = mgInterval )
      .then( () => esApiFactoryV2.getMaintenanceGroup( service.maintenance_group_id ) )
      .then( maintenanceGroup => service.maintenance_group = maintenanceGroup )
      .then( () => {
        _each(service.intervals, interval => {
          interval.usage_interval = esCanonicalizer.canonicalize('usage_interval', interval.usage_interval_id);
          interval.time_interval = esCanonicalizer.canonicalize('time_interval_id', interval.time_interval_id);
        })
      })
      .then(() => service);
  }

  return {
    getServiceRecord,
    patchServiceRecord,
    setServiceRecordPhotoUrls,
    postServiceRecord,
    getAssetServiceRecords,
    getAssetUpcomingServices
  }
});
