import ngModule from '../ng/index.js';

import template from '../views/dispatch/dispatch-home.html';
import '../views/dispatch';

ngModule.config(($stateProvider) => {
	$stateProvider.state('home.dispatch', {
		url: '/dispatch',
		template,
		controller: 'DispatchHomeCtrl',
		menuItem: 'dispatch',
	});
});
