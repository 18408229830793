import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Link } from "../es/ui-router";

import buttonStyles from "../button/button.module.css";

export const buttonTypes = [ "primary", "positive", "negative", "link", "transparent", "outline" ];

export const RouterLink = ( { params, to, className, buttonType, children, iconClass, rightIcon, large, small, styles, uppercase } ) => (

  <Link
    className={classNames(
      buttonStyles.btn, className, buttonType && buttonStyles[buttonType], styles,
      small && buttonStyles.small, large && buttonStyles.large, uppercase && buttonStyles.uppercase
    )}
    params={params}
    to={to}
  >
    {( iconClass && !rightIcon ) && (
      <i aria-hidden="true" className={iconClass} />
    )}
    <span>{ children }</span>
    {( iconClass && rightIcon ) && (
      <i aria-hidden="true" className={iconClass} />
    )}
  </Link>
);

RouterLink.propTypes = {
  /** Button type */
  buttonType: PropTypes.oneOf( buttonTypes ),
  /** button label */
  children: PropTypes.string,
  /** Button class */
  className: PropTypes.string,
  /** icon class for example `fa fa-plus` */
  iconClass: PropTypes.string,
  /** larger button */
  large: PropTypes.bool,
  /** params to pass with the url */
  params: PropTypes.object,
  /** when `iconClass` is provided rightIcon will place the icon on the right */
  rightIcon: PropTypes.bool,
  /** smaller button */
  small: PropTypes.bool,
  /** extra styles applied to the button */
  styles: PropTypes.string,
  /** the destination url */
  to: PropTypes.string,
  /** uppercase label */
  uppercase: PropTypes.bool
};

RouterLink.defaultProps = {
  buttonType: null,
  children: null,
  className: null,
  iconClass: null,
  large: false,
  params: null,
  rightIcon: false,
  small: false,
  styles: null,
  to: "",
  uppercase: false,
};

export default RouterLink;
