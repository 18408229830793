import { ApolloClient, from, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import DebounceLink from 'apollo-link-debounce';

import onErrorLink from './error-handlers.js';

import inventoryIntrospection from './inventory/inventory-fragment-introspection.json';

import esApi from '@services/es-api.service';
import authService from '@services/auth.service';

const DEFAULT_DEBOUNCE_TIME_IN_MS = 300;

const httpLink = createHttpLink({
	uri: esApi.buildEndpoint(esApi.url`/inventory/graphql`),
});

const authLink = setContext(async (_, { headers }) => {
	const token = await authService.getApiToken();

	return {
		headers: {
			...headers,
			authorization: token || '',
		},
	};
});

const possibleTypes = inventoryIntrospection.__schema.types.reduce((types: Record<string, string[]>, currType) => {
	if (currType.possibleTypes) {
		types[currType.name] = currType.possibleTypes.map((possibleType) => possibleType.name);
	}

	return types;
}, {});

export const inventoryApolloClient = new ApolloClient({
	link: from([new DebounceLink(DEFAULT_DEBOUNCE_TIME_IN_MS), onErrorLink, authLink, httpLink]),
	cache: new InMemoryCache({
		possibleTypes,
	}),
});

export const reactQueryInventoryApolloClient = new ApolloClient({
	link: from([new DebounceLink(DEFAULT_DEBOUNCE_TIME_IN_MS), authLink, httpLink]),
	cache: new InMemoryCache({
		possibleTypes,
	}),
	defaultOptions: {
		query: {
			fetchPolicy: 'no-cache',
		},
	},
});

export default inventoryApolloClient;
