import React from "react";
import PropTypes from "prop-types";

/**
 * IconLayers
 *
 * @return {Component} - An IconLayers React component
 */
export function IconLayers( { children } ) {
  return (
    <span className="fa-layers fa-fw">
      {children}
    </span>
  );
}

IconLayers.propTypes = {
  /** The children elements to be used inside the layers */
  children: PropTypes.any.isRequired,
};

export default IconLayers;
