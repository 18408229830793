import { makeXFields } from '@helpers/xFields';

export const equipmentClassXFields = makeXFields`{ category_id, equipment_class_id, name }`;

export const rentalXFields = makeXFields`{
  rental_id,
  rental_type_id,
  start_date,
  end_date,
  off_rent_date_requested,
  price_per_hour,
  price_per_day,
  price_per_week,
  price_per_month,
  rental_status {
    rental_status_id,
    name
  },
  order {
    order_id,
		reference,
		purchase_order {
			purchase_order_id,
			name
		},
    user {
      user_id,
      first_name,
      last_name,
      phone_number,
      email_address,
      company {
        company_id,
        name,
        net_terms_id,
        net_terms {
          name
        }
      }
    },
    notes
  },
  equipment {
    equipment_id,
    asset_id,
    name,
    model,
    equipment_model {
      equipment_model_id,
      name,
      equipment_make {
        equipment_make_id,
        name
      },
      equipment_classes {
        equipment_class_id,
        name
      }
    },
    make {
      equipment_make_id,
      name
    },
    company {
      name
    }
  },
  equipment_class {
    equipment_class_id,
    name
  },
  current_location {
    latitude,
    longitude,
    location_id,
    nickname,
    street_1,
    city,
    state {
      state_id,
      abbreviation
    }
  },
  part_assignments,
	quantity,
	drop_off_delivery_id,
	return_delivery_id,
	off_rent_note {
		rental_note_id,
		rental_note_type_id,
		rental_note_type,
		content,
	},
}`;

const xFields = {
	equipmentClassXFields,
	rentalXFields,
};

export default xFields;
