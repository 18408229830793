import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames/bind';
import _get from 'lodash/get';

import { Card } from '@equipmentshare/react-ui-components/dist/card';
import { ListGroupItem, ListGroup } from '@equipmentshare/react-ui-components/dist/list-group';

import { CenteredLoadingSpinner } from '@components/loading-spinner/loading-spinner';
import rentalTakeoverCardService from '@services/rental-takeover-card.service';

import styles from './equipment-assignments-tab.module.css';

const cx = classNames.bind(styles);

function getAssignmentateRange(assignment) {
	const dateFormat = 'MMM D, YYYY';
	const startDate = _get(assignment, 'start_date');
	const endDate = _get(assignment, 'end_date');

	return [moment(startDate).format(dateFormat), endDate ? moment(endDate).format(dateFormat) : 'Current'].join(' - ');
}

const showRentalCard = (rentalId) => rentalTakeoverCardService.showRentalCard(rentalId);

const renderData = (assignments) => (
	<div className={cx('layout')}>
		{assignments.length === 0 ? (
			<div className={cx('noData')}>No rentals exist for this asset</div>
		) : (
			<React.Fragment>
				{assignments.map((assignment) => (
					<Card cardClass={cx('card')} key={assignment.equipment_assignment_id}>
						<Card.Header className={cx('card-header')}>
							<strong onClick={() => showRentalCard(_get(assignment, 'rental_id'))}>
								Rental #{_get(assignment, 'rental_id')}
							</strong>
							<span>( {getAssignmentateRange(assignment)} )</span>
						</Card.Header>
						<ListGroup noBorder>
							<ListGroupItem className={cx('list-group-item')}>
								<span>Status</span>
								<strong>{_get(assignment, 'rental.rental_status.name')}</strong>
							</ListGroupItem>
							<ListGroupItem className={cx('list-group-item')}>
								<span>Borrowing Company</span>
								<strong>{_get(assignment, 'rental.order.user.company.name')}</strong>
							</ListGroupItem>
							<ListGroupItem className={cx('list-group-item')}>
								<span>Requesting User</span>
								<strong>
									{_get(assignment, 'rental.order.user.first_name')} {_get(assignment, 'rental.order.user.last_name')}
								</strong>
							</ListGroupItem>
						</ListGroup>
					</Card>
				))}
			</React.Fragment>
		)}
	</div>
);

const renderLoading = () => <CenteredLoadingSpinner size="xxl" />;

const EquipmentAssignmentsTab = ({ assignments = [], isLoading = false }) => {
	if (isLoading) {
		return renderLoading();
	}

	return renderData(assignments);
};

EquipmentAssignmentsTab.propTypes = {
	assignments: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.bool,
};

export default EquipmentAssignmentsTab;
