import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
	& > * {
		display: inline;
	}
`;

const WarningText = styled.span`
	text-transform: uppercase;
	color: var(--color-theme-negative);
`;

export const CodPaymentInfo: React.VFC = () => (
	<Container>
		<span> &middot; </span>
		<WarningText>Payment Upfront Required</WarningText>
	</Container>
);

export default CodPaymentInfo;
