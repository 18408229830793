import esApi from './es-api.service';

export async function fetch(id, query, opts) {
	return await esApi.request(esApi.url`/makes/${id}`, query, opts);
}

export async function fetchAll() {
	return await esApi.request(esApi.url`/makes`);
}

export async function search(query, opts) {
	return await esApi.request(esApi.url`/makes`, query, opts);
}

const makesService = {
	fetch,
	fetchAll,
	search,
};

export default makesService;
