import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import errorHandlerService from '@services/error-handler.service';
import { formatDate } from '../../../js/formatting';
import { FilterState } from './filter-provider';
import { useLayoutProvider } from './layout-provider';
import { getRatesServiceData } from '../rate.helpers';
import { setRateProps } from './rate-data.utils';
import toastService from '@services/toast.service';

const Context = createContext();

export function useRatesProvider() {
	return useContext(Context);
}

const RatesProvider = ({ children }) => {
	const [rateType, setRateType] = useState('company');
	const [rates, onSetRates] = useState([]);
	const [ratesCount, setRatesCount] = useState('0');
	const [selectedRateIds, onSetSelectedRateIds] = useState([]);
	const [selectedRatesCount, setSelectedRatesCount] = useState('0');
	const [endpointServiceData, setEndpointServiceData] = useState();
	const { state: filterState } = useContext(FilterState);
	const { setLoading } = useLayoutProvider();

	const setRates = useCallback((_rates) => onSetRates(_rates), [onSetRates]);
	const setSelectedRateIds = useCallback(
		(_selectedRateIds) => onSetSelectedRateIds(_selectedRateIds),
		[onSetSelectedRateIds]
	);

	// counts are purely for UI so set as String
	useEffect(() => setRatesCount(rates.length.toLocaleString()), [rates]);
	useEffect(() => setSelectedRatesCount(selectedRateIds.length.toLocaleString()), [selectedRateIds]);

	useEffect(() => {
		if (!_.isEmpty(filterState.filterQuery)) {
			fetchRates();
		} else {
			setLoading(false);
		}
	}, [filterState.filterQuery]);

	useEffect(() => setEndpointServiceData(getRatesServiceData(filterState.rateType)), [filterState.rateType]);

	const selectedRates = useMemo(() => {
		return rates.filter((rate) => selectedRateIds.includes(rate._rentalRateId));
	}, [rates, selectedRateIds]);

	const mergeUpdatesAndSetRates = (modifiedRates) => {
		setRates(
			rates.map(
				(rate) => modifiedRates.find((modifiedRate) => modifiedRate._rentalRateId === rate._rentalRateId) || rate
			)
		);
		return setSelectedRateIds([]);
	};

	const createRate = async (payload) => {
		setLoading(true);

		try {
			const res = await endpointServiceData.service.create(payload);
			const newRate = setRateProps([res], filterState.rateType);

			return setRates([...newRate, ...rates]);
		} catch (err) {
			throw new Error(err);
		} finally {
			setLoading(false);
		}
	};

	const fetchRates = async () => {
		setLoading(true);

		try {
			const opts = { headers: { 'X-Fields': endpointServiceData.xFields } };
			let rateData = await endpointServiceData.service.search(filterState.filterQuery, opts);

			if (!(rateData instanceof Array)) {
				rateData = [rateData];
			}

			const _rowData = setRateProps(rateData, filterState.rateType);

			setRates(_rowData);
		} catch (err) {
			errorHandlerService.genericErrorHandler(err);
		} finally {
			setLoading(false);
		}
	};

	const updateRateExpirationDate = async (endDate) => {
		setLoading(true);

		try {
			const res = await endpointServiceData.service.updateMultiple(selectedRateIds, { end_date: endDate });
			const expiredRates = await setRateProps(res, filterState.rateType);

			toastService.showSuccess(`Rate(s) successfully updated with an end date of ${formatDate(endDate)}`);
			return mergeUpdatesAndSetRates(expiredRates);
		} catch (err) {
			throw new Error(err);
		} finally {
			setLoading(false);
		}
	};

	const voidRates = async () => {
		setLoading(true);

		try {
			const payload = filterState.rateType === 'location' ? { active: false } : { voided: true };
			const res = await endpointServiceData.service.updateMultiple(selectedRateIds, payload);
			const expiredRates = await setRateProps(res, filterState.rateType);

			toastService.showSuccess('Rate(s) successfully voided');
			return mergeUpdatesAndSetRates(expiredRates);
		} catch (err) {
			throw new Error(err);
		} finally {
			setLoading(false);
		}
	};

	const resetRates = () => {
		onSetRates([]);
	};

	const context = useMemo(
		() => ({
			rates,
			ratesCount,
			rateType,
			setRates,
			setRateType,
			createRate,
			updateRateExpirationDate,
			voidRates,
			resetRates,

			selectedRatesCount,
			selectedRates,
			selectedRateIds,
			setSelectedRateIds,
		}),
		[
			rates,
			ratesCount,
			rateType,
			setRates,
			setRateType,
			createRate,
			updateRateExpirationDate,
			voidRates,
			resetRates,

			selectedRatesCount,
			selectedRates,
			selectedRateIds,
			setSelectedRateIds,
		]
	);

	return <Context.Provider value={context}>{children}</Context.Provider>;
};

RatesProvider.propTypes = {
	children: PropTypes.node,
};

RatesProvider.Context = Context;

export default RatesProvider;
