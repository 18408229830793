import _isObject from 'lodash/isObject';
import _transform from 'lodash/transform';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';

import { formatCurrency } from '@equipmentshare/admin-hooks';

import { safeAdd } from '@services/math.service';

function createQueryString(data: Record<string, any>) {
	const params = Object.keys(data).reduce((acc: string[], curr) => {
		acc.push(`${curr}=${data[curr]}`);
		return acc;
	}, []);
	return params.join('&');
}

export function generateUrlWithParams(queryData: Record<string, any>, url = null) {
	let path = url || location.href;
	if (path.indexOf('?') !== -1) {
		const parts = path.split('?');
		path = parts[0];
	}

	const queryParams = `${createQueryString(queryData)}`;

	return `${path}${queryParams ? `?${queryParams}` : ''}`;
}

export function difference(object: Record<string, any>, base: Record<string, any>) {
	const changes = (o: Record<string, any>, b: Record<string, any>) => {
		return _transform(o, (accumulator: Record<string, any>, value, key) => {
			if (!_isEqual(value, b[key])) {
				if (_isObject(value) && _isObject(b[key])) {
					accumulator[key] = changes(value, b[key]);
				} else {
					accumulator[key] = value;
				}
			}
		});
	};

	return changes(object, base);
}

export function pick(obj: Record<string, any> = {}, fields: string[] = []) {
	return fields.reduce((newObj, field) => ({ ...newObj, [field]: obj[field] }), {});
}

export function isString(value: any): value is string {
	return (value && typeof value === 'string') || value instanceof String;
}

export function pickShallowChangedProperties(original: Record<string, any>, changed: Record<string, any>) {
	const changedKeys = Object.keys(changed).filter((key) => {
		return original[key] !== changed[key];
	});

	return _pick({ ...original, ...changed }, changedKeys);
}

export function pickPopulatedProperties(object: Record<string, any>) {
	Object.keys(object).forEach(
		(key) => (object[key] === null || object[key] === undefined || object[key] === '') && delete object[key]
	);
	return object;
}

export function parseFloatDefaultToNull(v: string) {
	return Number.isFinite(parseFloat(v)) ? parseFloat(v) : null;
}

export function isValidFiveDigitZipCode(zip: string) {
	return /^\d{5}$/.test(zip);
}

export function formatCurrencyString(s: string) {
	return formatCurrency(parseFloat(s));
}

export function safeAddAsString(a: string | null, b: string | null) {
	if (!a && !b) {
		return '';
	}

	if (!a) {
		return b || '';
	}

	if (!b) {
		return a || '';
	}

	return String(safeAdd(parseFloat(a), parseFloat(b)));
}

export function omitFieldFromObject(obj: Record<string, any> = {}, fields: string[] = []) {
	const newObj = { ...obj };
	fields.forEach((field) => {
		if (field in newObj) {
			delete newObj[field];
		}
	});
	return newObj;
}
