import { useQuery } from 'react-query';

import { ENTITY_TYPES, cacheKey } from '@equipmentshare/admin-hooks';

import rentalsService from '@services/rentals.service.js';

export default function useRentalLoader(rentalId, { enabled, xFields, ...opts } = {}) {
	return useQuery(
		cacheKey(ENTITY_TYPES.RENTAL, 'useRentalLoader', rentalId),
		() => rentalsService.fetch(rentalId, xFields && { headers: { 'X-Fields': xFields } }),
		{ enabled: !!rentalId && (enabled ?? true), ...opts }
	);
}
