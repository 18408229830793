import map from 'lodash/fp/map';

angular.module('esApiClient').factory('esAdminUserService', function ($q, esApiFactoryV2, esCanonicalizer) {
  let canonicalizer = esCanonicalizer.canonicalizer('user');
  let cachedUsers = [];

  function getUser(userId, ignoreCache = false) {
    if(cachedUsers.includes(userId) && !ignoreCache){
      return $q.resolve(canonicalizer(userId));
    }
    else return esApiFactoryV2.adminGetUser(userId)
      .then(canonicalizer)
      .then(user => {
        cachedUsers.push(user.user_id);
        return user;
      })
  }

  function searchUsers(query, isSalesperson, companyId) {
    let params = {query, is_salesperson: isSalesperson, company_id: companyId};

    for(let key in params){
      if(params[key] === undefined){
        delete params[key];
      }
    }

    return esApiFactoryV2.adminSearchUsers(params)
      .then(map(canonicalizer));
  }

  function patchUser(userId, payload){
    return esApiFactoryV2.adminPatchUser(userId, payload)
      .then(() => getUser(userId, true));
  }

  function createUser(payload) {
    return esApiFactoryV2.adminPostUser(payload)
      .then(canonicalizer);
  }

  function clearCache() {
    cachedUsers = [];
  }

  return {
    getUser,
    searchUsers,
    patchUser,
    createUser,
    clearCache
  }
});
