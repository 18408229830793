angular.module( "esAuthentication" ).factory( "authTokenHttpInterceptor", authTokenHttpInterceptor );

function authTokenHttpInterceptor ( $q, User, $location, authRoutePath ) {
  function hasCompanyIdOrOnResetPassword() {
    return User.getCompanyId() || $location.path() === authRoutePath.changePassword;
  }

  return {
    request: ( config ) => {
      if ( User.hasJWTToken() && User.hasUserId() && hasCompanyIdOrOnResetPassword() ) {
        config.headers.Authorization = "Bearer " + User.getJWTToken();
      }

      if ( User.hasApiToken() && User.hasUserId() && hasCompanyIdOrOnResetPassword() ) {
        config.headers.Authorization = "Bearer " + User.getApiToken();
      }

      return config || $q.when( config );
    },
    responseError: ( rejection ) => {
      return $q.reject( rejection );
    }
  };
}
