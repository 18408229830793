import React from 'react';
import { react2angular } from 'react2angular';
import withGlobalProviders from '@components/with-global-providers';

import ngModule from '../../ng/index.js';
import { Route } from '@equipmentshare/react2angular-router';

import LayoutView from './layout';
export const Routes = () => (
	<>
		<Route state="home.rates.company" component={LayoutView} />
		<Route state="home.rates.location" component={LayoutView} />
		<Route state="home.rates.branch" component={LayoutView} />
	</>
);

ngModule.component('ratesRoutes', react2angular(withGlobalProviders(Routes)));

export default Routes;
