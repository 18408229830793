import _get from 'lodash/get';
import esApi from './es-api.service';

export function getFullName(...n) {
	const base = n.slice().shift();

	if (typeof base === 'object') {
		const firstName = _get(base, 'first_name', '');
		const lastName = _get(base, 'last_name', '');

		return `${firstName} ${lastName}`;
	}

	return n.reduce((acc, curr) => `${acc} ${curr}`);
}

export const getFullNameCollapsed = (...n) => getFullName(...n).replace(/\s+/g, '');

async function search(query, opts) {
	return await esApi.request(esApi.url`/admin/users`, query, opts);
}

async function fetch(id, query, opts) {
	return await esApi.request(`/admin/users/${id}`, query, opts);
}

export async function fetchMultiple(userIds, query, opts) {
	return await Promise.all(
		userIds.map(async (id) => {
			return await fetch(id, query, opts);
		})
	);
}

async function fetchSelf() {
	return await esApi.request('/user');
}

async function updateSelf(userId, payload) {
	return await esApi.request(esApi.url`/user/${userId}`, { method: 'PATCH', body: payload });
}

async function adminCreate(payload) {
	return await esApi.request(esApi.url`/admin/users`, null, { body: payload, method: 'POST' });
}

async function adminUpdate(userId, payload, query) {
	return await esApi.request(esApi.url`/admin/users/${userId}`, query, { body: payload, method: 'PATCH' });
}

const usersService = {
	search,
	fetch,
	fetchMultiple,
	fetchSelf,
	updateSelf,
	getFullName,
	getFullNameCollapsed,
	adminCreate,
	adminUpdate,
};

export default usersService;
