import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _has from 'lodash/has';
import classNames from 'classnames/bind';

import { ListGroup, ListGroupItem } from '@equipmentshare/react-ui-components/dist/list-group';
import AssetEngineCodesIcon from '@equipmentshare/react-ui-components/dist/es/asset-status-icons/icons/asset-diagnostic-codes-icon';

import styles from './asset-status-tab.module.css';

const cx = classNames.bind(styles);

function getAssetLocationImageUrl(status) {
	const locationString = `${status.location.latitude},${status.location.longitude}`;

	const base = 'https://maps.googleapis.com/maps/api/staticmap';
	const query = {
		center: locationString,
		zoom: 13,
		size: '500x300',
		maptype: 'roadmap',
		markers: locationString,
		key: 'AIzaSyAzLinN-4rrmzamxNuZiyf3il_aE_ALEFo',
	};
	const queryString = _reduce(
		query,
		(arr, value, key) => {
			arr.push(`${key}=${value}`);

			return arr;
		},
		[]
	).join('&');

	return encodeURI(`${base}?${queryString}`);
}
function getAssetLocationUrl(status) {
	const locationString = `${status.location.latitude},${status.location.longitude}`;

	return encodeURI(`https://maps.google.com/maps?q=${locationString}`);
}

function getAssetLocationString(status) {
	return `${status.street}, ${status.city} ${_get(status, 'state.abbreviation')}`;
}

class AssetStatusTab extends PureComponent {
	render() {
		const { asset } = this.props;

		return (
			<div className={cx('layout')}>
				<h4 className={cx('header')}>Details</h4>
				<ListGroup className={cx('list-group')}>
					{_has(asset, 'name') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Type</span>
							<strong>{_get(asset, 'name')}</strong>
						</ListGroupItem>
					)}
					{_has(asset, 'description') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Configuration/Options</span>
							<strong>{_get(asset, 'description')}</strong>
						</ListGroupItem>
					)}
					{_has(asset, 'serial_number') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Serial Number</span>
							<strong>{_get(asset, 'serial_number')}</strong>
						</ListGroupItem>
					)}
					{_has(asset, 'company.name') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Owned By</span>
							<strong>{_get(asset, 'company.name')}</strong>
						</ListGroupItem>
					)}
					{_has(asset, 'asset_status.hours') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Hours</span>
							<strong>{_get(asset, 'asset_status.hours')} hrs</strong>
						</ListGroupItem>
					)}
					{_has(asset, 'vin') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>VIN</span>
							<strong>{_get(asset, 'vin')}</strong>
						</ListGroupItem>
					)}
				</ListGroup>
				<h4 className={cx('header')}>Status</h4>
				<ListGroup>
					{!!_get(asset, 'asset_status.engine_rpm_timestamp') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>RPMs</span>
							<strong>{_get(asset, 'asset_status.engine_rpm')}</strong>
						</ListGroupItem>
					)}
					{!!_get(asset, 'asset_status.coolant_temperature_timestamp') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Coolant Temperature</span>
							<strong>{_get(asset, 'asset_status.coolant_temperature')}&deg;F</strong>
						</ListGroupItem>
					)}
					{!!_get(asset, 'asset_status.engine_oil_temperature_timestamp') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Oil Temperature</span>
							<strong>{_get(asset, 'asset_status.engine_oil_temperature')}&deg;F</strong>
						</ListGroupItem>
					)}
					{!!_get(asset, 'asset_status.battery_voltage_timestamp') && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Battery</span>
							<strong>{_get(asset, 'asset_status.battery_voltage')}v</strong>
						</ListGroupItem>
					)}
					{asset.diagnostic_codes && asset.diagnostic_codes.length > 0 && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Active Engine Codes</span>
							<AssetEngineCodesIcon asset={asset} tool />
						</ListGroupItem>
					)}
				</ListGroup>
				<h4 className={cx('header')} />
				<ListGroup>
					{asset.asset_status.last_parked_timestamp && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Last Parked</span>
							<strong>{moment(asset.asset_status.last_parked_timestamp).fromNow()}</strong>
						</ListGroupItem>
					)}
					{asset.asset_status.last_checkin_timestamp && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Last Check in</span>
							<strong>{moment(asset.asset_status.last_checkin_timestamp).fromNow()}</strong>
						</ListGroupItem>
					)}
					{asset.asset_status.location && (
						<ListGroupItem className={cx('list-group-item')}>
							<span>Location</span>
							<strong>{getAssetLocationString(asset.asset_status)}</strong>
						</ListGroupItem>
					)}
				</ListGroup>
				{asset.asset_status.location && (
					<a href={getAssetLocationUrl(asset.asset_status)} target="_blank">
						<img className={cx('map')} src={getAssetLocationImageUrl(asset.asset_status)} />
					</a>
				)}
			</div>
		);
	}
}

AssetStatusTab.propTypes = {
	asset: PropTypes.object.isRequired,
};

export default AssetStatusTab;
