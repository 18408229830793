import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './body.module.css';

const Body = ({ children, className, style, scrollable }) => (
	<div className={classNames(styles.body, scrollable && styles.scrollable, className)} style={style}>
		{children}
	</div>
);

Body.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	style: PropTypes.object,
	scrollable: PropTypes.bool,
};

export default Body;
