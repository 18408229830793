import angular from 'angular';

import { debounceCheckForUpdates } from '../updates';
import { API_URL } from '../const/env';

export const ngModule = angular
	.module('esBilling', [
		'ngRoute',
		'ui.router',
		'ui.mask',
		'ngMaterial',
		'ngMessages',
		'scDateTime',
		'esApiClient',
		'esNgComponents',
		'credit-cards',
		'angularResizable',
	])
	.config(($httpProvider) => {
		// replace es-api-client "authTokenHttpInterceptor" with "ngHttpRequestInterceptor"
		// see: https://gitlab.internal.equipmentshare.com/es-frontend/es-api-client/-/blob/master/src/auth/token-http-interceptor-service.js
		$httpProvider.interceptors.pop();
		$httpProvider.interceptors.push('ngHttpRequestInterceptor');
	})
	.run(($rootScope, $state, $mdMedia, $window, $timeout, esApiFactoryV2) => {
		// set variables on the root scope
		$rootScope.$state = $state;
		$rootScope.$mdMedia = $mdMedia;

		$rootScope.$on('$stateChangeStart', (evt, to, params) => {
			debounceCheckForUpdates();

			if (to.redirectTo) {
				evt.preventDefault();
				$state.go(to.redirectTo, params, { location: 'replace' });
			}
		});

		esApiFactoryV2.setUrlRoot(API_URL);
	})
	.constant('APPLICATION_ID', 1);

export default ngModule;
