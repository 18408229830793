import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EditLineIcon, CloseLineIcon } from '@equipmentshare/ds2-icons';

import Table from '@components/table';
import { LineItemTypeNameCell } from '@components/common-table-cells';
import LineItemForm from './line-item-form/line-item-form';

const LineItemsTable = ({
	lineItems,
	onDeleteLineItem,
	showCreateForm,
	onCancelAddLineItem,
	onCreateLineItem,
	onUpdateLineItem,
	editingLineItemId,
	onEditLineItem,
	onCancelEditLineItem,
	companyId,
}) => {
	const handleDeleteLineItem = useCallback(
		(lineItem) => onDeleteLineItem && onDeleteLineItem(lineItem),
		[onDeleteLineItem]
	);

	const iconProps = {
		size: 'l',
	};

	const renderLineItem = (lineItem) =>
		lineItem.line_item_id === editingLineItemId ? (
			<LineItemForm
				entry={lineItem}
				key={lineItem.line_item_id}
				onSubmit={onUpdateLineItem}
				onCancelClick={onCancelEditLineItem}
				initialLineItem={lineItem}
				companyId={companyId}
			/>
		) : (
			<Table.Row key={lineItem.line_item_id} entry={lineItem}>
				<LineItemTypeNameCell columnId="type" lineItemType={lineItem.line_item_type} />
				<Table.MarkdownCell columnId="description" source={lineItem.description} />
				<Table.Cell columnId="qty">{lineItem.number_of_units}</Table.Cell>
				<Table.Cell columnId="price">{lineItem.price_per_unit}</Table.Cell>
				<Table.ActionCell columnId="actions">
					<Table.ActionCell.Action icon={EditLineIcon} iconProps={iconProps} onClick={() => onEditLineItem(lineItem)} />
					<Table.ActionCell.Action
						icon={CloseLineIcon}
						iconProps={iconProps}
						onClick={() => handleDeleteLineItem(lineItem)}
					/>
				</Table.ActionCell>
			</Table.Row>
		);

	return (
		<Table>
			<Table.Head>
				<Table.Header>
					<Table.Column columnId="type" columnWidth="15%">
						Charge Type
					</Table.Column>
					<Table.Column columnId="description">Description</Table.Column>
					<Table.Column columnId="qty" columnWidth={100}>
						Qty
					</Table.Column>
					<Table.Column columnId="price" columnWidth={150}>
						Unit Price
					</Table.Column>
					<Table.ActionColumn columnId="actions" numberOfActions={2} />
				</Table.Header>
			</Table.Head>
			<Table.Body>
				{lineItems.map(renderLineItem)}
				{showCreateForm && <LineItemForm onSubmit={onCreateLineItem} onCancelClick={onCancelAddLineItem} />}
			</Table.Body>
		</Table>
	);
};

LineItemsTable.propTypes = {
	lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
	onCreateLineItem: PropTypes.func,
	onUpdateLineItem: PropTypes.func,
	onDeleteLineItem: PropTypes.func,
	showCreateForm: PropTypes.bool,
	onCancelAddLineItem: PropTypes.func,
	editingLineItemId: PropTypes.number,
	onEditLineItem: PropTypes.func,
	onCancelEditLineItem: PropTypes.func,
	companyId: PropTypes.number,
};

export default LineItemsTable;
