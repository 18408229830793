import ngModule from '../../../../ng/index.js';
import template from './address-picker-gmap.html';

function addressPickerGmap() {
	function link(scope, elem) {
		scope.el = elem[0];
		elem.css({ height: '300px', width: '100%', display: 'block' });
	}

	return {
		link,
		template,
		controller: 'AddressPickerGmapCtrl',
		scope: {
			ngModel: '=',
			dragEndCallback: '=',
			allowScrollZoom: '<',
		},
	};
}

/**
 * Provides a pin-on-a-map picker to choose an exact location, with a search
 * mechanism to find addresses etc. The model value should be passed in as an
 * object with `latitude` and `longitude` attributes. This will be augmented
 * automatically with reverse-geocoded city and state_id whenever the pin
 * location is first set and any time changed thereafter.
 */
ngModule.directive('addressPickerGmap', addressPickerGmap);
