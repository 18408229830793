import angular from 'angular';
import template from './model-picker.html';

angular.module("esNgComponents")
  .directive('modelPicker', function () {
    return {
      template,
      controller: 'ModelPickerCtrl as ctrl',
      scope: {
        ngModel: '=',
        selectedModel: '=',
        selectedMakeId: '=',
        selectLabel: '@'
      }
    };
  })
  .controller('ModelPickerCtrl', function ($scope, esApiFactoryV2) {
    var controller = this;

    $scope.$watch('ngModel', function (newModel, oldModel) {
      if (newModel === undefined) {
        $scope.selectedModel = undefined;
        $scope.model = {};
        $scope.searchText = '';
      }
    });

    controller.querySearch = function (query, selectedMakeId) {
      return esApiFactoryV2.queryModels(query, selectedMakeId)
        .then(function (response) {
          return response;
        });
    };

    controller.selectModel = function (model) {
      if (model) {
        $scope.ngModel = model.equipment_model_id;
      } else {
        $scope.ngModel = null;
      }
    };
  });
