import ngModule from '../ng/index.js';
import '@views/inventory/routes';

ngModule.config(($stateProvider) => {
	$stateProvider.state('home.inventory', {
		url: '/inventory?branchIds&categoryId&equipmentClassIds&assetTypeIds',
		template: `
				<inventory-routes style="height:100%" />
      `,
	});
});
