import angular from 'angular';
import template from './rental-activity-feed.html';

function RentalActivityFeedCtrl($scope, esRentalActivityService) {
	$scope.state = {
		feed: [],
		currentRentalId: undefined,
	};

	$scope.$watch('rentalId', (rentalId) => {
		if ($scope.state.currentRentalId !== rentalId) {
			$scope.state.currentRentalId = rentalId;
			esRentalActivityService.getActivitiesForRental(rentalId).then((feed) => {
				$scope.state.feed = feed;
			});
		}
	});

	$scope.icon = function (activity) {
		switch (activity.type) {
			case 'assign_equipment':
			case 'unassign_equipment':
				return 'directions_car';
			case 'send_invoice':
				return 'attach_money';
			case 'drop_off_equipment_to_customer':
			case 'pickup_equipment_from_customer':
			case 'customer_picks_up_equipment':
			case 'customer_drops_off_equipment':
				return 'headset_mic';
			default:
				return 'bookmark';
		}
	};
}

angular
	.module('esBilling')
	.directive('rentalActivityFeed', () => {
		return {
			template,
			controller: 'RentalActivityFeedCtrl',
			scope: {
				rentalId: '=',
			},
		};
	})
	.controller('RentalActivityFeedCtrl', RentalActivityFeedCtrl);
