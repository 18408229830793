import { gql } from '@apollo/client';

export const openTransfersQuery = gql`
	query GetOpenTransfersForAsset($assetId: ID!) {
		transferOrders(filters: { statuses: [Requested, Approved], assetIds: [$assetId] }) {
			edges {
				node {
					id
					status
					toBranch {
						id
						name
					}
					fromBranch {
						id
						name
					}
				}
			}
		}
	}
`;
