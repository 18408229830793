import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@equipmentshare/react-ui-components';
import { formatAddress } from '@equipmentshare/admin-hooks';
import locationOptionRenderer from './location-option-renderer';
import { filterLocation } from './helpers';

const formatLocationAddress = (location) => formatAddress(location, false);

const LocationAutocomplete = ({ locations, selectedLocationId, onChange, ...props }) => (
	<Autocomplete
		options={locations}
		labelKey="nickname"
		noResultsText="No Locations Found"
		onChange={onChange}
		optionRenderer={locationOptionRenderer}
		valueRenderer={formatLocationAddress}
		value={selectedLocationId}
		valueKey="location_id"
		filterOption={filterLocation}
		optionHeight={48}
		removeSelected={false}
		{...props}
	/>
);

LocationAutocomplete.propTypes = {
	locations: PropTypes.array,
	selectedLocationId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	onChange: PropTypes.func,
};

export default LocationAutocomplete;
