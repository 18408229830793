import { OutputOf } from 'io-ts';

import { PARTS_RETAIL_SALE_LINE_ITEM_TYPES } from '@equipmentshare/billing-common/types/LineItemType';
import { invoiceGet, invoiceSearch, invoiceCreate } from '@equipmentshare/billing-service-dtos/dto/interface/Invoice';
import { user } from '@equipmentshare/billing-service-dtos/dto/User';
import { lineItemType } from '@equipmentshare/billing-service-dtos/dto/LineItemType';

import { RENTAL_LINE_ITEM_TYPES, LINE_ITEM_TYPES } from '@const/invoices';

export namespace Invoice {
	export type User = OutputOf<typeof user>;
	export type LineItemType = OutputOf<typeof lineItemType>;
	export type GetDtoData = OutputOf<typeof invoiceGet['response']>;
	export type SearchDtoData = OutputOf<typeof invoiceSearch['response']>['invoices'][number];
	export type CreateRequest = OutputOf<typeof invoiceCreate['request']>;
	export type CreateResponse = OutputOf<typeof invoiceCreate['response']>;
	export type LineItem = Omit<GetDtoData['lineItems'][number], 'lineItemTypeId'> & { lineItemTypeId: number | null };
	export type LineItemStringId = Omit<LineItem, 'id'> & { id: string };
	export type LineItemEstimate = {
		amount: number;
		line_item_id: number | string;
		tax_amount: number;
		tax_rate_percentage?: number;
	};
	export type Estimate = {
		tax_amount: number;
		line_items?: LineItemEstimate[];
	};

	export type BillingServiceInvoice = GetDtoData | SearchDtoData;
	export type LineItemAnyIdFormat = LineItem | LineItemStringId;

	export const isGetDtoData = (i: BillingServiceInvoice): i is GetDtoData => 'billingApprovedData' in i;

	export const getRentalLineItem = (invoice: GetDtoData) => {
		return invoice.lineItems.find((li) => RENTAL_LINE_ITEM_TYPES.includes(li.lineItemTypeId));
	};

	export const isRentalInvoice = (invoice: BillingServiceInvoice) => {
		return isGetDtoData(invoice) && !!getRentalLineItem(invoice);
	};

	export const includesRetailSaleLineItem = (lineItems: Readonly<LineItemAnyIdFormat[]>) => {
		return lineItems.some((li) => !!li.lineItemTypeId && PARTS_RETAIL_SALE_LINE_ITEM_TYPES.has(li.lineItemTypeId));
	};

	export const isRetailSaleInvoice = (invoice: BillingServiceInvoice) => {
		return isGetDtoData(invoice) && includesRetailSaleLineItem(invoice.lineItems);
	};

	export const convertShippingAddress = (shippingAddress: GetDtoData['shipFrom'] | GetDtoData['shipTo']) => {
		return {
			nickname: shippingAddress?.nickname,
			address: {
				city: shippingAddress?.address.city || '',
				street_1: shippingAddress?.address.street1 || '',
				street_2: shippingAddress?.address.street2 || '',
				zip_code: shippingAddress?.address.zipCode || '',
				state_abbreviation: shippingAddress?.address.stateAbbrev || '',
				state: { abbreviation: '', name: '' },
			},
		};
	};

	export const getFullUserName = (user?: User) => {
		const firstName = user?.firstName || '';
		const lastName = user?.lastName || '';

		return `${firstName} ${lastName}`;
	};

	export const getRentalLineItems = (lineItems: LineItemAnyIdFormat[]) => {
		return lineItems.filter((li) => !!li.lineItemTypeId && RENTAL_LINE_ITEM_TYPES.includes(li.lineItemTypeId));
	};

	export const getNonRentalLineItems = (lineItems: LineItemAnyIdFormat[]) => {
		return lineItems.filter((li) => !li.lineItemTypeId || !RENTAL_LINE_ITEM_TYPES.includes(li.lineItemTypeId));
	};
}

export default Invoice;
