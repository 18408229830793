import authService from '@services/auth.service';
import { API_URL } from '@const/env';

// FEATURE GROUPS
export const FLOOR_RATE_APPROVAL = ['Floor Rate Approvers'];
export const EDIT_ASSET_OWNERSHIP_PAYOUT = ['Fleet Operations'];
export const MTT_BATCH_ON_RENT = ['MTT Batch on Rent'];
/**
 * @async
 * @param groupList - A list of authorized groups with permission to access a certain feature (defined above)
 * @returns {Promise<Boolean>} - whether the user can approve rentals with a status of "Needs Approval"
 */
export async function userInFeatureGroups(groupList) {
	if (groupList) {
		const auth0User = await authService.getAuth0User();
		const apiUrl = API_URL.includes('localhost') ? 'https://staging-api.equipmentshare.com' : API_URL;
		if (auth0User[`${apiUrl}/groups`]) {
			let userGroups = auth0User[`${apiUrl}/groups`];
			userGroups = userGroups.map((group) => group.toLowerCase());
			const groupListLowerCase = groupList.map((group) => group.toLowerCase());
			return userGroups.some((group) => groupListLowerCase.includes(group));
		}
	}
	return false;
}
