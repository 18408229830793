angular.module('esApiClient').factory('esAdminTrackerService', function (esApiFactoryV2, $log) {
  $log.info('esAdminTrackerService()');

  function getTrackerVendors() {
    return esApiFactoryV2.getTrackerVendors();
  }

  function getCompanyTrackers(companyId) {
    var that = this;
    return esApiFactoryV2.getCompanyTrackers(companyId)
        .then(function (response) {
          return that.trackers = response.data;
        });
  }

  function getTracker(trackerId) {
    var that = this;
    return esApiFactoryV2.adminGetTracker(trackerId)
        .then(function (response) {
          return that.tracker = response.data;
        });
  }

  function getTrackers(config) {
    var that = this;

    if (config.tracker_id)
      return esApiFactoryV2.adminGetTracker(config.tracker_id)
        .then(function (response) {
          return that.trackers = [response.data];
        });

    return esApiFactoryV2.adminGetTrackers(config)
        .then(function (response) {
          return that.trackers = response;
        });
  }

  function postTracker(tracker) {
    return esApiFactoryV2.adminPostTracker(tracker);
  }

  function patchTracker(tracker) {
    return esApiFactoryV2.adminPatchTracker(tracker);
  }

  return {
    tracker: undefined,
    trackers: undefined,
    getCompanyTrackers: getCompanyTrackers,
    getTracker: getTracker,
    getTrackers: getTrackers,
    patchTracker: patchTracker,
    postTracker: postTracker,
    getTrackerVendors: getTrackerVendors
  }
});
