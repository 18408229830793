import map from 'lodash/fp/map';

import _pick from 'lodash/pick';
import _find from 'lodash/find';
import _clone from 'lodash/clone';
import _filter from 'lodash/filter';

import moment from "moment";

angular.module('esApiClient').factory('esDeliveriesService', function (esApiFactoryV2, esCanonicalizer, $q, esAdminRentalService) {
  let service = this;

  let deliveryStatuses = [
    {delivery_status_id: 1, name: 'Requested'},
    {delivery_status_id: 3, name: 'Completed'},
    {delivery_status_id: 4, name: 'Cancelled'}
  ];

  let partRentalTypeId = 3;

  let deliveryStatusIds = {
    REQUESTED: 1,
    CONFIRMED: 2,
    COMPLETED: 3,
    CANCELLED: 4
  };

  let deliveryTypes = {
    /** INITIAL Dropoff happens at beginning of rental, means we drop it off */
    INITIAL_DROPOFF: "INITIAL DROPOFF",
    /** FINAL Return happens at end of rental, means we go get equipment */
    FINAL_RETURN: "FINAL RETURN",
      /** SWAP Dropoff happens intra-rental, means we are sending a new asset to a job site */
    SWAP_DROPOFF: "SWAP DROPOFF",
    /** SWAP Return happens intra-rental, means we are returning an asset */
    SWAP_RETURN: "SWAP RETURN",
    /** Move happens intra-rental, means we move equipment between sites */
    MOVE: "MOVE",
    /** A delivery that's not associated with a rental */
    TRANSPORT: "TRANSPORT",
    /** A partial return that's associated with a part rental */
    PARTIAL_RETURN: "PARTIAL RETURN"
  };

  let deliveryFacilitatorTypes = {
    /** When the delivery is completed by an employee */
    IN_HOUSE: {delivery_facilitator_type_id: 1, name: 'In House'},
    /** When the delivery is contracted out to the delivery vendor */
    OUTSOURCED: {delivery_facilitator_type_id: 2, name: 'Third Party'},
    /** When a customer is responsible for the delivery */
    CUSTOMER: {delivery_facilitator_type_id: 3, name: 'Customer'}
  };

  let deliveryFieldsToPatch = ['charge', 'delivery_company_id',
    'delivery_status_id', 'order_id', 'scheduled_date', 'rental_id',
    'driver_user_id', 'run_name', 'note', 'contact_name', 'contact_phone_number', 'location_id', 'facilitator_type_id', 'purchase_order_id', 'purchase_order_number'];

  let deliveryGraphQLMask = `{
    quantity,
    purchase_order_id,
    purchase_order_number,
    part_display,
    delivery_photos,
    delivery_details,
    part_id,
    charge,
    delivery_id,
    scheduled_date,
    run_name,
    note,
    contact_name,
    contact_phone_number,
    completed_by_user_id,
    completed_by_user,
    completed_date,
    origin {
      location_id,
      nickname,
      name,
      street_1,
      street_2,
      city,
      latitude,
      longitude,
      state {
        abbreviation
      }
    },
    order {
      order_id,
      user {
        user_id,
        company {
          company_id,
          name
        }
      }
    },
    destination {
      location_id,
      nickname,
      name,
      street_1,
      street_2,
      city,
      latitude,
      longitude,
      state {
        abbreviation
      }
    },
    asset_id,
    asset {
      asset_id,
      description,
      make_id,
      make {
        make_id,
        name
      },
      model
    },
    driver_user_id,
    driver {
      user_id,
      first_name,
      last_name
    },
    facilitator_type_id,
    facilitator_type {
      delivery_facilitator_type_id,
      name
    },
    delivery_status_id,
    delivery_status {
      delivery_status_id,
      name
    },
    rental_id,
    rental {
      rental_id,
      rental_type_id,
      start_date,
      end_date,
      off_rent_date_requested,
      part_assignments,
      price_per_hour,
      price_per_day,
      price_per_week,
      price_per_month,
      taxable,
      job_description,
      drop_off_delivery_required,
      drop_off_delivery_id,
      return_delivery_required,
      return_delivery_id,
      rental_status_id,
      rental_status {
        rental_status_id,
        name
      },
      equipment_class_id,
      equipment_class {
        equipment_class_id,
        name
      },
      equipment_assignments {
        drop_off_delivery_id,
        return_delivery_id
      },
      equipment_id,
      equipment {
        asset_id,
        equipment_id,
        name,
        year,
        model,
        description
      },
      order_id,
      order {
        order_id,
        user {
          user_id,
          company {
            company_id,
            name
          }
        }
      }
      current_location {
        location_id,
        nickname,
        name,
        street_1,
        street_2,
        city,
        latitude,
        longitude,
        state {
          abbreviation
        }
      }
    }
  }`.replace( /\n/g, " " ).replace( /( )+/g, " " ).trim();

  let canonicalize = esCanonicalizer.canonicalizer('delivery');

  service.getDeliveries = filter => {
    return esApiFactoryV2.adminGetDeliveries(filter, deliveryGraphQLMask)
      .then(map(service.transformDeliveryForLocalUse))
      .then(map(canonicalize));
  };

  service.getDelivery = (deliveryId, fields) => {
    return esApiFactoryV2.adminGetDelivery(deliveryId, fields)
      .then(service.transformDeliveryForLocalUse)
      .then(canonicalize);
  };

  service.getDeliveryByRentalAndType = (rental, type) => {
    if (typeof rental === 'number') {
      return esAdminRentalService.getRental(rental)
        .then( rental => service.getDeliveryByRentalAndType(rental, type));
    }

    switch (type) {
      case deliveryTypes.INITIAL_DROPOFF:
        return service.getDelivery(rental.drop_off_delivery_id);
        break;
      case deliveryTypes.FINAL_RETURN:
        return service.getDelivery(rental.return_delivery_id);
        break;
      default:
        return $q.reject(new Error(type + " deliveries cannot be updated by referring to the rental."));
    }
  };

  service.updateDeliveryByRentalAndType = (rental, type, changes) => {
    return service.getDeliveryByRentalAndType(rental, type)
      .then((delivery) => service.updateDelivery(delivery.delivery_id, changes))
  };

  service.createDelivery = (delivery) => {
    let payload = _clone(delivery);
    payload.scheduled_date = moment(payload.scheduled_date).valueOf();
    return esApiFactoryV2.adminPostDelivery(payload)
      .then(service.transformDeliveryForLocalUse)
      .then(canonicalize);
  };

  service.updateDelivery = (id, changes) => {
    let patch = _pick(changes, deliveryFieldsToPatch);
    if(patch.scheduled_date) {
      patch.scheduled_date = moment(patch.scheduled_date).valueOf();
    }
    return esApiFactoryV2
      .adminPatchDelivery(id, patch)
      .then(() => esApiFactoryV2.adminGetDelivery(id))
      .then(service.transformDeliveryForLocalUse)
      .then(canonicalize);
  };

  service.transformDeliveryForLocalUse = (delivery) => {
    delivery._type = inferDeliveryType(delivery);
    delivery.scheduled_date = moment(delivery.scheduled_date);

    if (delivery.facilitator_type_id === deliveryFacilitatorTypes.CUSTOMER.delivery_facilitator_type_id) {
      delivery.facilitator_type = deliveryFacilitatorTypes.CUSTOMER;
    } else if (delivery.facilitator_type_id === deliveryFacilitatorTypes.IN_HOUSE.delivery_facilitator_type_id) {
      delivery.facilitator_type = deliveryFacilitatorTypes.IN_HOUSE;
    } else if (delivery.facilitator_type_id === deliveryFacilitatorTypes.OUTSOURCED.delivery_facilitator_type_id) {
      delivery.facilitator_type = deliveryFacilitatorTypes.OUTSOURCED;
    }

    if (!delivery.delivery_status) {
      delivery.delivery_status = _find(deliveryStatuses, status => {
        return status.delivery_status_id == delivery.delivery_status_id
      });
    }
    return canonicalize(delivery);
  };

  function isSwapReturnDelivery(delivery) {
    let isReturn = false;

    if (delivery.rental) {
      isReturn = delivery.delivery_id === delivery.rental.return_delivery_id;
      if (delivery.rental.equipment_assignments) {
        isReturn = _filter(delivery.rental.equipment_assignments, a => {
          return a.return_delivery_id === delivery.delivery_id
        }).length >= 1;
      }
    }

    return isReturn
  }

  function isSwapDropOffDelivery(delivery) {
    let isDropOff = false;

    if (delivery.rental) {
      if (delivery.delivery_id === delivery.rental.drop_off_delivery_id) {
        isDropOff = true;
      }
      if (delivery.rental.equipment_assignments) {
        isDropOff = _filter(delivery.rental.equipment_assignments, a => {
          return a.drop_off_delivery_id === delivery.delivery_id
        }).length >= 1;
      }
    }

    return isDropOff
  }

  function isPartialReturnDelivery(delivery) {
    if ( delivery.rental && delivery.rental.rental_type_id === 3 && delivery.rental.part_assignments ) {
      return delivery.rental.part_assignments.some( ( assignment ) => assignment.return_delivery_id === delivery.delivery_id );
    }

    return false;
  }


  function inferDeliveryType(delivery) {
    if (delivery.rental && delivery.delivery_id === delivery.rental.drop_off_delivery_id) {
      return deliveryTypes.INITIAL_DROPOFF;
    } else if (delivery.rental && delivery.delivery_id === delivery.rental.return_delivery_id) {
      return deliveryTypes.FINAL_RETURN;
    } else if (isSwapDropOffDelivery(delivery)) {
      return deliveryTypes.SWAP_DROPOFF;
    } else if (isSwapReturnDelivery(delivery)) {
      return deliveryTypes.SWAP_RETURN;
    } else if (isPartialReturnDelivery(delivery)) {
      return deliveryTypes.PARTIAL_RETURN;
    } else if (delivery.rental){
      return deliveryTypes.MOVE;
    }
    return deliveryTypes.TRANSPORT;
  }
  /**
   * Based on delivery type return a short hand to
   * be displayed within the color marker for accessability
   * @param  {string} type delivery type
   * @return {string}      short type
   */
  service.getShortDeliveryType = type => {
    switch(type) {
      case deliveryTypes.INITIAL_DROPOFF: {
        return "D";
      }
      case deliveryTypes.SWAP_DROPOFF: {
        return "SD";
      }
      case deliveryTypes.FINAL_RETURN: {
        return "R";
      }
      case deliveryTypes.SWAP_RETURN: {
        return "SR";
      }
      case deliveryTypes.MOVE: {
        return "M";
      }
      case deliveryTypes.TRANSPORT: {
        return "T";
      }
      case deliveryTypes.PARTIAL_RETURN: {
        return "PR"
      }
      default:
        return "";
    }
  }
  /**
   * When the delivery type is initial dropoff check the scheduled_date
   * and determine what class should be returned to denote
   * today, tomorrow, third day and future initial dropoff deliviries
   * @param  {object} delivery delivery object
   * @return {string}          custom class
   */
  service.getInitialDropoffStyle = (delivery) => {
    if(!delivery || delivery._type !== deliveryTypes.INITIAL_DROPOFF) {
      return '';
    }
    const { _type, scheduled_date } = delivery;
    let dt = moment(scheduled_date);
    let tomorrow = moment().add(1, 'day').startOf('day');
    let third = moment().add(2, 'day').startOf('day');
    if (dt.isSameOrBefore(moment(), 'day')) {
      return 'today';
    } else if (dt.isSameOrBefore(tomorrow)) {
      return 'tomorrow';
    } else if (dt.isSameOrBefore(third)) {
      return 'third-day';
    } else if (dt.isAfter(third)) {
      return 'future';
    }
  }

  /**
   * Fetch deliveries for the given rental from API, update local canonical versions to match the
   * result and return the canonical deliveries.
   *
   * Useful if you've made changes to a rental, or something related to the rental schedule, and you
   * want to ensure you have the latest versions of the rentals deliveries locally.
   */
  service.refreshDeliveriesByRentalId = rentalId => {
    return esApiFactoryV2.adminGetDeliveries({rental_id: rentalId})
      .then(map(service.transformDeliveryForLocalUse))
      .then(map(canonicalize));
  };

  return {
    deliveryStatuses: deliveryStatuses,
    deliveryStatusIds: deliveryStatusIds,
    deliveryFacilitatorTypes: deliveryFacilitatorTypes,
    deliveryTypes: deliveryTypes,
    getDelivery: service.getDelivery,
    getDeliveries: service.getDeliveries,
    getDeliveryByRentalAndType: service.getDeliveryByRentalAndType,
    createDelivery: service.createDelivery,
    updateDelivery: service.updateDelivery,
    updateDeliveryByRentalAndType: service.updateDeliveryByRentalAndType,
    refreshDeliveriesByRentalId: service.refreshDeliveriesByRentalId,
    transformDeliveryForLocalUse: service.transformDeliveryForLocalUse,
    getShortDeliveryType: service.getShortDeliveryType,
    getInitialDropoffStyle: service.getInitialDropoffStyle
  }
})
;
