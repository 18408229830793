import React from "react";
import PropTypes from "prop-types";

import IconLayers from "./components/icon-layers.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { iconList } from "./icon-config.js";

export let sizeOptions = [ "xs","sm","lg","2x","3x","4x","5x","6x","7x","8x","9x","10x" ];
export let flipOptions = [ "horizontal", "vertical", "both" ];

/**
 * In order to use this component correctly the icon desired must be imported into
 * the `icon-config.js` file and added to the FontAwesome library.  Once this has been
 * done you can refer to the icon by it's new name (`fasPlus`).  This allows multiple
 * icons from different typesets to be imported and referenced if needed (`falPlus`, `fasPlus`)
 *
 * See `icon-config.js` for an example on how to import and add icons to the Library
 *
 * @return {Component} - An Icon React component
 */
export function Icon( { className, mask, name, onClick, ...props } ) {
  if ( !name ) {
    return null;
  }

  return (
    <FontAwesomeIcon
      className={className ? className : undefined}
      icon={iconList[name]}
      key={name}
      mask={mask ? iconList[mask] : undefined}
      onClick={onClick ? onClick : undefined}
      {...props}
    />
  );
}

Icon.Layers = IconLayers;
Icon.propTypes = {
  className: PropTypes.string,
  /** The mask icon name to be rendered - pulled from iconList in icon-config.js */
  mask: PropTypes.string,
  /** The icon name to be rendered - pulled from iconList in icon-config.js */
  name: PropTypes.string.isRequired,
  /** A function used to trigger when the icon is clicked */
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  className: null,
  mask: null,
  onClick: null,
};

export default Icon;
