import { gql } from '@apollo/client';

export const createTransferOrderMutation = gql`
	mutation CreateTransferOrder($input: CreateTransferOrderInput!) {
		createTransferOrder(input: $input) {
			success
			message
			transferOrderId
		}
	}
`;
