import ngModule from '../../../../ng/index.js';
import template from './purchase-order-picker.html';

function purchaseOrderPicker() {
	return {
		template,
		controller: 'PurchaseOrderPickerCtrl as ctrl',
		scope: {
			purchaseOrder: '=',
			isRequired: '@',
		},
	};
}

/********
 * Controller
 */
function PurchaseOrderPickerCtrl($scope, $http, esEnv) {
	const controller = this;
	$scope.searchText = '';

	$scope.$watch('purchaseOrder', (newValue, oldValue) => {
		if (!newValue) {
			$scope.searchText = '';
		}
	});

	controller.search = (search) => {
		return $http
			.post(`${esEnv.getHost()}/things/federated/graphql`, {
				query: `
			query GetPurchaseOrders($search: String!) {
				purchaseOrders(search: $search, paging: { limit: 10 }) {
					edges {
						node {
							id
							purchaseOrderNumber
						}
					}
				}
			}
			`,
				variables: { search },
			})
			.then((response) => {
				return response.data.data.purchaseOrders.edges.map((edge) => ({
					purchase_order_id: edge.node.id,
					purchase_order_number: edge.node.purchaseOrderNumber,
				}));
			});
	};

	controller.selectPurchaseOrder = (selectedPO) => {
		if (angular.isObject(selectedPO)) {
			angular.extend($scope.purchaseOrder, selectedPO);
		} else {
			$scope.purchaseOrder = null;
		}
	};
}

ngModule
	.directive('purchaseOrderPicker', purchaseOrderPicker)
	.controller('PurchaseOrderPickerCtrl', PurchaseOrderPickerCtrl);
