import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import { RATES_BE_TO_FE_MAP, RATE_TYPE_IDS } from '@const/rate-types';
import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@equipmentshare/ds2';
import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';
import branchesService from '@services/branches.service';
import companyDocumentService from '@services/company-documents.service';
import equipmentClassService from '@services/equipment-class.service';
import rentalRatesService from '@services/rental-rates.service';
import RateSelect from '@views/orders/rental-create-form-components/rate-select';
import RatesDrawer from '@views/orders/rental-create-form-components/rates-drawer';
import EquipmentClassSelect from '@views/orders/rental-create-form-components/equipment-class-select';
import RentalProtectionPlanSelect from '@views/orders/rental-create-form-components/rental-protection-plan-select';
import {
	renderRateTypeName,
	rentalRateWarningBelowBench,
	rentalRateWarningBelowFloor,
} from '@views/orders/rental-create-form/rental-utils';
import styles from './rental-pricing-edit-form.module.css';
import { pickShallowChangedProperties } from '@helpers/utilities';
import { RENTAL_TYPES } from '@const/rental-types';
import { getMultipliedRates, getBaseRates } from '@helpers/shift-rates';
import { SHIFT_RATE_TYPES } from '@const/rate-types';
import rentalsService from '@services/rentals.service';
import errorHandlerService from '@services/error-handler.service';
import RentalEstimate from '@components/rental-estimate';
import toastService from '@services/toast.service.js';
import { useFlags } from '@equipmentshare/feature-flags';

import { userInFeatureGroups, FLOOR_RATE_APPROVAL } from '../../helpers/admin-feature-permissions';

function shouldDisableShiftRates(statusName) {
	return ['On Rent', 'Off Rent', 'Billed', 'Completed'].includes(statusName);
}

const RentalPricingEditForm = ({
	allowEdit = true,
	updateRentalObject,
	rental,
	asset,
	startDate,
	endDate,
	taxable,
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedEquipmentClassId, setSelectedEquipmentClassId] = useState(undefined);
	const [selectedRentalProtectionPlanId, setSelectedRentalProtectionPlanId] = useState(undefined);
	const [showRatesDrawer, setShowRatesDrawer] = useState(false);
	const [selectedRates, setSelectedRates] = useState({
		hourlyRate: undefined,
		dailyRate: undefined,
		weeklyRate: undefined,
		monthlyRate: undefined,
	});
	const [selectedRateTypeId, setSelectedRateTypeId] = useState(undefined);
	const [selectedRateTypeName, setSelectedRateTypeName] = useState(renderRateTypeName(undefined));
	const [disabledRates, setDisabledRates] = useState(true);
	const [canApprove, setCanApprove] = useState(false);
	const [selectedPricingType, setSelectedPricingType] = useState('1');
	const [selectedShiftTypeId, setSelectedShiftTypeId] = useState(undefined);
	const [disabledShiftRates, setDisabledShiftRates] = useState(false);
	const [baseRates, setBaseRates] = useState({});
	const [additionalRateApplied, setAdditionalRateApplied] = useState(false);
	const [ratesList, setRatesList] = useState();

	const approvalPermission = async () => {
		try {
			const userCanApprove = await userInFeatureGroups(FLOOR_RATE_APPROVAL);
			setCanApprove(userCanApprove);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		approvalPermission();
	}, []);

	useEffect(() => {
		setSelectedShiftTypeId(rental.shift_type_id !== null ? rental.shift_type_id : SHIFT_RATE_TYPES.single);
		const baseRates = getBaseRates(rental.shift_type_id, rental, 'price_per');
		setBaseRates(baseRates);
	}, [rental]);

	const isPartRental = useMemo(() => rental.rental_type_id === RENTAL_TYPES.BULK, [rental.rental_type_id]);

	const companyId = useMemo(() => rental.order.user.company.company_id, [rental.order.user]);

	const { data: rentalFloater } = useQuery(
		`rental-floater-${companyId}`,
		() => companyDocumentService.getCurrentRentalFloater(companyId),
		{ enabled: !!(companyId && isEditing && !isPartRental) }
	);

	const { data: branch } = useQuery(
		`branch-id-${rental.order.branch_id}`,
		() => branchesService.fetch(rental.order.branch_id),
		{ enabled: !!(rental.order.branch_id && isEditing && !isPartRental) }
	);

	const { data: equipmentClass } = useQuery(
		`equipment-class-${selectedEquipmentClassId}`,
		() => equipmentClassService.fetch(selectedEquipmentClassId),
		{ enabled: !!(selectedEquipmentClassId && isEditing && !isPartRental) }
	);

	const { isLoading: isLoadingRates, data: availableRates } = useQuery(
		[
			'availableRates',
			rental?.order?.branch_id,
			rental?.current_location?.location_id,
			selectedEquipmentClassId,
			companyId,
		],
		() =>
			rentalRatesService.search({
				branch_id: rental.order.branch_id,
				equipment_class_id: selectedEquipmentClassId,
				company_id: companyId,
				location_id: rental.current_location.location_id,
			}),
		{ enabled: !!(rental && selectedEquipmentClassId && isEditing && !isPartRental) }
	);

	const handlePricingTypeChange = (pricingType) => {
		setSelectedPricingType(pricingType);
	};

	const getRatesByShift = (rates) => {
		let shift = rental.shift_type_id ? rental.shift_type_id : 1;
		if (selectedShiftTypeId) {
			shift = selectedShiftTypeId;
		}
		return getMultipliedRates(shift, rates, 'rate');
	};

	const rentalFloaterStatus = useMemo(() => {
		if (rentalFloater) {
			return companyDocumentService.getRentalFloaterStatus(document);
		}
		return undefined;
	}, [rentalFloater]);

	useEffect(() => {
		// If Advertised rate not included, add empty rate for rate type
		if (
			!isLoadingRates &&
			availableRates &&
			!availableRates?.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.ADVERTISED_RATE_TYPE_ID)
		) {
			setRatesList(
				availableRates?.concat([
					{
						rate_type_id: RATE_TYPE_IDS.ADVERTISED_RATE_TYPE_ID,
					},
				])
			);
		}

		// If Floor rate not included, add empty rate for rate type
		if (
			!isLoadingRates &&
			availableRates &&
			!availableRates?.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.FLOOR_RATE_TYPE_ID)
		) {
			setRatesList(
				availableRates?.concat([
					{
						rate_type_id: RATE_TYPE_IDS.FLOOR_RATE_TYPE_ID,
					},
				])
			);
		}

		if (
			availableRates &&
			availableRates.length > 0 &&
			!isLoadingRates &&
			selectedEquipmentClassId !== rental.equipment_class_id
		) {
			const defaultRate = availableRates.find((rate) => rate.default);
			handleRateSelect(defaultRate, false);
		}
	}, [availableRates, isLoadingRates, selectedEquipmentClassId, rental.equipment_class_id]);

	useEffect(() => {
		let rates = {
			hourlyRate: rental.price_per_hour,
			dailyRate: rental.price_per_day,
			weeklyRate: rental.price_per_week,
			monthlyRate: rental.price_per_month,
		};
		setSelectedEquipmentClassId(rental.equipment_class_id);
		setSelectedRentalProtectionPlanId(rental.rental_protection_plan_id);
		rates = getRatesByShift(baseRates);
		setSelectedRates(rates);
		setSelectedRateTypeId(rental.rate_type_id);
		setSelectedRateTypeName(renderRateTypeName(rental.rate_type));
		setDisabledRates(true);
		setIsEditing(false);
		setShowRatesDrawer(false);
	}, [rental, baseRates]);

	useEffect(() => {
		setDisabledShiftRates(shouldDisableShiftRates(rental.rental_status.name));
	}, [rental.rental_status.name]);

	const handleRateChange = (evt) => {
		const { name, value } = evt.target;
		const rates = { ...selectedRates, [name]: parseFloat(value) };
		setSelectedRates(rates);
	};

	const handleRateSelect = (rate, shouldShowToast = true) => {
		let rates = {
			hourlyRate: _get(rate, 'price_per_hour', undefined),
			dailyRate: _get(rate, 'price_per_day', undefined),
			weeklyRate: _get(rate, 'price_per_week', undefined),
			monthlyRate: _get(rate, 'price_per_month', undefined),
		};
		setSelectedRateTypeId(_get(rate, 'rate_type_id', RATE_TYPE_IDS.CUSTOM_RATE_TYPE_ID));
		setSelectedRateTypeName(renderRateTypeName(rate));
		setShowRatesDrawer(false);
		setDisabledRates(true);
		if (shouldShowToast && selectedShiftTypeId !== SHIFT_RATE_TYPES.single) {
			toastService.showInfo(`Shift Rate Set To Single Shift`);
			setSelectedShiftTypeId(1);
		}
		setAdditionalRateApplied(true);
		setSelectedRates(rates);
		setSelectedPricingType('1');
	};

	const handleOverride = () => {
		setShowRatesDrawer(false);
		setSelectedRateTypeId(RATE_TYPE_IDS.CUSTOM_RATE_TYPE_ID);
		setSelectedRateTypeName('Manual');
		if (selectedShiftTypeId !== SHIFT_RATE_TYPES.single) {
			toastService.showInfo(`Shift Rate Set To Single Shift`);
			setSelectedShiftTypeId(1);
			setSelectedRates(baseRates);
		}
		setDisabledRates(false);
	};

	const currentRateValues = Object.keys(RATES_BE_TO_FE_MAP).reduce((rates, key) => {
		rates[key] = selectedRates[RATES_BE_TO_FE_MAP[key].stateKey];
		return rates;
	}, {});

	const renderRateWarningText = useCallback(() => {
		// Only display "Needs Approval" warning when the user can't approve and the rate type is custom and below the floor rate
		const needApprovalText = !canApprove && selectedRateTypeId === RATE_TYPE_IDS.CUSTOM_RATE_TYPE_ID;
		const rateWarningTextBelowFloor = rentalRateWarningBelowFloor(
			availableRates,
			currentRateValues,
			needApprovalText,
			selectedPricingType === '2'
		);
		let rateWarningTextBelowBench;
		if (rateWarningTextBelowFloor === undefined) {
			rateWarningTextBelowBench = rentalRateWarningBelowBench(availableRates, currentRateValues);
		}
		if (rateWarningTextBelowFloor || rateWarningTextBelowBench) {
			return (
				<div className={styles.rateWarningText}>
					<span className={rateWarningTextBelowFloor ? styles.negativeBold : styles.negative}>
						{rateWarningTextBelowFloor || rateWarningTextBelowBench}
					</span>
				</div>
			);
		}
		return undefined;
	}, [availableRates, selectedRates, selectedRateTypeId, currentRateValues]);

	useEffect(() => {
		if (!currentRateValues.price_per_week && !currentRateValues.price_per_month && !isEditing) {
			setSelectedPricingType('2');
		} else if (currentRateValues.price_per_week && currentRateValues.price_per_month && !isEditing) {
			setSelectedPricingType('1');
		}
	}, [currentRateValues]);

	useEffect(() => {
		const shiftType = selectedShiftTypeId !== null ? selectedShiftTypeId : SHIFT_RATE_TYPES.single;
		if (shiftType !== SHIFT_RATE_TYPES.single) {
			const rates = getRatesByShift(baseRates);
			setSelectedEquipmentClassId(rental.equipment_class_id);
			setSelectedRentalProtectionPlanId(rental.rental_protection_plan_id);
			setSelectedRateTypeId(rental.rate_type_id);
			setSelectedRates(rates);
		} else if (!additionalRateApplied) {
			setSelectedRates(baseRates);
		}
		setAdditionalRateApplied(false);
	}, [selectedShiftTypeId]);

	const handleCancel = () => {
		setIsEditing(false);
		setSelectedEquipmentClassId(rental.equipment_class_id);
		setSelectedRateTypeName(renderRateTypeName(rental.rate_type));
		setSelectedRates({
			hourlyRate: rental.price_per_hour,
			dailyRate: rental.price_per_day,
			weeklyRate: rental.price_per_week,
			monthlyRate: rental.price_per_month,
		});
		setSelectedRentalProtectionPlanId(rental.rental_protection_plan_id);
		setDisabledRates(true);
		setAdditionalRateApplied(false);
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		setIsLoading(true);
		const payload = pickShallowChangedProperties(rental, {
			price_per_hour: selectedPricingType === '2' ? null : selectedRates.hourlyRate,
			price_per_day: selectedRates.dailyRate,
			price_per_week: selectedPricingType === '2' ? null : selectedRates.weeklyRate,
			price_per_month: selectedPricingType === '2' ? null : selectedRates.monthlyRate,
			rate_type_id: selectedRateTypeId,
			equipment_class_id: selectedEquipmentClassId,
			rental_protection_plan_id: selectedRentalProtectionPlanId || null,
			shift_type_id: selectedShiftTypeId,
		});
		try {
			await rentalsService.update(rental.rental_id, payload);
			const updatedRental = await rentalsService.fetch(rental.rental_id);
			updateRentalObject(updatedRental);
			setIsEditing(false);
			setDisabledRates(true);
			setAdditionalRateApplied(false);
			toastService.showSuccess(`Rental #${rental.rental_id} successfully updated.`);
		} catch (error) {
			errorHandlerService.genericErrorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<form className={styles.section} onSubmit={handleSubmit}>
			{(isLoadingRates || isLoading) && <LoadingOverlay />}
			{!isPartRental && (
				<div className={styles.sectionRow}>
					<div className={styles.sectionItem}>
						<EquipmentClassSelect
							className={styles.dropdownSelect}
							equipmentClassId={selectedEquipmentClassId}
							onChange={setSelectedEquipmentClassId}
							disabled={!isEditing}
						/>
					</div>
					<div className={styles.sectionItem}>
						<RentalProtectionPlanSelect
							className={styles.dropdownSelect}
							rentalProtectionPlanId={selectedRentalProtectionPlanId}
							onChange={setSelectedRentalProtectionPlanId}
							rentalFloater={rentalFloater}
							rentalFloaterStatus={rentalFloaterStatus}
							required={rentalFloaterStatus && !rentalFloaterStatus.isValid && !rentalFloaterStatus.isPartiallyValid}
							disabled={!isEditing}
							orderId={rental.order.order_id}
						/>
					</div>
				</div>
			)}
			<div>
				{availableRates && (
					<RatesDrawer
						isOpen={showRatesDrawer}
						rates={ratesList ?? availableRates}
						setIsOpen={setShowRatesDrawer}
						branch={branch}
						equipmentClass={equipmentClass}
						onRateSelect={handleRateSelect}
					/>
				)}
			</div>
			<div className={styles.sectionRow}>
				<RateSelect
					dailyRate={selectedRates.dailyRate}
					hourlyRate={selectedRates.hourlyRate}
					weeklyRate={selectedRates.weeklyRate}
					monthlyRate={selectedRates.monthlyRate}
					hourlyRateRequired={!isPartRental}
					showSubheader={isPartRental}
					rateTypeStyle={styles.rateType}
					rateTypeName={selectedRateTypeName}
					required={!isPartRental}
					enableShiftPricing={!isPartRental}
					handleRateChange={handleRateChange}
					handleShiftChange={setSelectedShiftTypeId}
					disabled={(!isPartRental && disabledRates) || !isEditing}
					disabledShift={!isEditing || disabledShiftRates}
					selectedPricingType={selectedPricingType}
					handlePricingTypeChange={handlePricingTypeChange}
					shiftId={selectedShiftTypeId}
				/>
				{renderRateWarningText()}
				<div className={styles.sectionButtons}>
					{allowEdit &&
						(isEditing ? (
							<EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
								<EuiFlexItem grow={false}>
									<EuiButton fill type="submit">
										SAVE
									</EuiButton>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiButton color="text" onClick={handleCancel}>
										CANCEL
									</EuiButton>
								</EuiFlexItem>
							</EuiFlexGroup>
						) : (
							<div className={styles.inlineRight}>
								<EuiButton color="text" onClick={() => setIsEditing(true)}>
									EDIT
								</EuiButton>
							</div>
						))}
				</div>
			</div>
			{!isPartRental && (
				<React.Fragment>
					<div className={styles.sectionRow}>
						<EuiFlexGroup gutterSize="s">
							<EuiFlexItem grow={false}>
								<EuiButton fill color="danger" onClick={handleOverride} isDisabled={!isEditing}>
									Manual Override
								</EuiButton>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton
									fill
									onClick={() => setShowRatesDrawer(!showRatesDrawer)}
									isDisabled={_isEmpty(availableRates) || !isEditing}
								>
									Additional Rates
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</div>
					<div className={styles.sectionRow}>
						<RentalEstimate
							asset={asset}
							rental={rental}
							startDate={startDate}
							endDate={endDate}
							taxable={taxable}
							rates={selectedRates}
							rentalProtectionPlanId={selectedRentalProtectionPlanId}
							equipmentClassId={selectedEquipmentClassId}
						/>
					</div>
				</React.Fragment>
			)}
		</form>
	);
};

RentalPricingEditForm.propTypes = {
	allowEdit: PropTypes.bool,
	asset: PropTypes.object,
	endDate: PropTypes.instanceOf(Date),
	rental: PropTypes.object.isRequired,
	startDate: PropTypes.instanceOf(Date),
	taxable: PropTypes.bool,
	updateRentalObject: PropTypes.func,
};

export default RentalPricingEditForm;
