import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './head.module.css';

export const Head = ({ children, className, style }) => (
	<div className={classNames(styles.head, className)} style={style}>
		{children}
	</div>
);

Head.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	style: PropTypes.object,
};

export default React.memo(Head);
