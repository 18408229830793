import esApi from '@services/es-api.service';

export async function search(query, opts) {
	return await esApi.request(esApi.url`/admin/payout-program-assignments`, query, opts);
}

export async function create(payload) {
	return await esApi.request(esApi.url`/admin/payout-program-assignments`, null, { body: payload, method: 'POST' });
}

export async function update(payoutAssignmentId, payload) {
	return await esApi.request(esApi.url`/admin/payout-program-assignments/${payoutAssignmentId}`, null, {
		body: payload,
		method: 'PATCH',
	});
}

export async function getPrograms() {
	return await esApi.request(esApi.url`/payout-program/v2/payout-programs/`, null, { method: 'GET' });
}

const payoutProgramAssignmentsService = {
	search,
	create,
	update,
	getPrograms,
};

export default payoutProgramAssignmentsService;
