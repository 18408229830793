angular.module('esApiClient').factory('esAccountService', function (esApiFactoryV2, $log, $q) {
  $log.info('esAccountService()');

  var service = this;

  service.getUser = function (forceReload) {
    $log.debug("getUser() > service.user", service.user);
    if (service.user && !forceReload)
      return $q.when(service.user);
    else {
      return esApiFactoryV2.getMyAccount()
          .then(function (response) {
            return service.user = response.data;
          });
    }
  };

  service.updateUserDisplay = function (user) {
    if (user) {
      service.userDisplay = angular.copy(user);
    } else {
      service.userDisplay = angular.copy(service.user);
    }
  };

  return {
    getUser: service.getUser,
    updateUserDisplay: service.updateUserDisplay
  }
});
