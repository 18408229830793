import esApi from './es-api.service';

export async function fetch(companyId, purchaseOrderId) {
	return await esApi.request(`/companies/${companyId}/purchase-orders/${purchaseOrderId}`);
}

export async function search(companyId, query, opts) {
	return await esApi.request(`/companies/${companyId}/purchase-orders`, query, opts);
}

export async function create(companyId, payload) {
	const purchaseOrder = await esApi.request(
		`/companies/${companyId}/purchase-orders`,
		{},
		{
			body: payload,
			method: 'POST',
		}
	);

	return purchaseOrder;
}

async function update(companyId, purchaseOrderId, payload) {
	const purchaseOrder = await esApi.request(
		esApi.url`/companies/${companyId}/purchase-orders/${purchaseOrderId}`,
		{},
		{
			body: payload,
			method: 'PATCH',
		}
	);

	return purchaseOrder;
}

const poService = {
	fetch,
	search,
	create,
	update,
};

export default poService;
