import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";

import csvStyles from "./csv-download.module.css";

const CsvDownload = ( { csvTitle, csvString, disabled, startDate, endDate } ) => {
  let startMoment = startDate ? moment( startDate.toISOString() ).format( "MM-DD-YYYY" ) : "";
  let endMoment = endDate ? moment( endDate.toISOString() ).format( "MM-DD-YYYY" ) : "";
  let href = disabled ? "" : `data:application/octet-stream;base64, ${btoa( csvString )}`;
  let downloadString = startMoment && endMoment
    ? `-${moment( startDate.toISOString() ).format( "MM-DD-YYYY" )}-${moment( endDate.toISOString() ).format( "MM-DD-YYYY" )}` : "";
  let download = disabled ? "" : `${csvTitle}${downloadString}.csv`;
  let linkClassName = classNames(
    csvStyles.download,
    {
      [csvStyles.disabled]: disabled
    }
  );


  return (
    <a
      className={linkClassName}
      download={download}
      href={href}
    >
      <button
        className={csvStyles.button}
        disabled={disabled}
        type="button"
      >
        <i />
        <span>Export to CSV</span>
      </button>
    </a>
  );
};


CsvDownload.defaultProps = {
  disabled: false,
  endDate: undefined,
  startDate: undefined
};

CsvDownload.propTypes = {
  /** csv data string to be exported on button click */
  csvString: PropTypes.string.isRequired,
  /** title of download */
  csvTitle: PropTypes.string.isRequired,
  /** boolean to disable button */
  disabled: PropTypes.bool,
  /** date object of end date */
  endDate: PropTypes.object,
  /** date object of start date */
  startDate: PropTypes.object
};

export default CsvDownload;
