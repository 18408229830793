import React from "react";
import PropTypes from "prop-types";

export class GoogleMap extends React.Component {
  constructor( props ) {
    super( props );

    this.googleMapTemplate = {
      ...this.props.template,
      center: this.props.center,
      options: {
        scrollwheel: false,
        mapTypeControl: this.props.mapTypeControl,
        maxZoom: this.props.maxZoom,
        mapTypeId: this.props.mapTypeId,
      },
      zoom: this.props.zoom
    };

    this.refMap = this.refMap.bind( this );
  }

  componentDidMount() {
    this.googleMap = this.createMap( this.googleMapTemplate );
  }

  refMap( el ){
    this.map = el;
  }

  createMap( mapTemplate ) {
    let googleMap = new google.maps.Map( this.map, {
      center: {
        lat: mapTemplate.center.latitude,
        lng: mapTemplate.center.longitude,
      },
      clickableIcons: false,
      gestureHandling: "greedy",
      mapTypeControl: mapTemplate.options.mapTypeControl,
      mapTypeId: mapTemplate.options.mapTypeId,
      zoom: mapTemplate.zoom,
      styles: [
        {
          featureType: "poi",
          stylers: [ { visibility: mapTemplate.showPOI ? "on" : "off" } ],
        }
      ]
    } );

    google.maps.event.addListenerOnce( googleMap, "idle", () => {
      if( this.props.setMap ){
        this.props.setMap( googleMap );
      }
    } );

    return googleMap;
  }

  render() {
    return (
      <div className="flex-fit" ref={this.refMap} />
    );
  }
}

GoogleMap.propTypes = {
  center: PropTypes.shape( {
    latitude: PropTypes.number,
    longitude: PropTypes.number
  } ),
  /** Turn the Street/Satellite topography buttons on(true) or off(false). */
  mapTypeControl: PropTypes.bool,
  mapTypeId: PropTypes.string,
  maxZoom: PropTypes.number,
  setMap: PropTypes.func,
  template: PropTypes.object,
  zoom: PropTypes.number
};

GoogleMap.defaultProps = {
  center: {
    "latitude": 38.95,
    "longitude": -92.33
  },
  mapTypeControl: true,
  mapTypeId: "roadmap",
  maxZoom: 21,
  setMap: () => {},
  template: {
    "topography": null,
    "showGeofences": true,
    "showTraffic": false,
    "showPOI": false,
  },
  zoom: 7
};


export default GoogleMap;
