import React from 'react';
import styled from 'styled-components';

import { formatAddress, Location } from '@equipmentshare/admin-hooks';

export const Address = styled.address`
	white-space: pre;
	font-style: normal;
`;

export type AddressDisplayProps = {
	className?: string;
	address: Location;
	nickname?: string | null;
	inline?: boolean;
};

export const AddressDisplay: React.VFC<AddressDisplayProps> = ({ className, address, nickname, inline }) => (
	<Address className={className}>
		{nickname && (
			<div>
				<strong>{nickname}</strong>
			</div>
		)}
		{address && formatAddress(address, !inline)}
	</Address>
);

export default AddressDisplay;
