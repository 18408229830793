import ngModule from '../../../ng/index.js';
import template from './rental-agreement.html';

ngModule.directive('rentalAgreement', () => {
	return {
		template,
		controller: 'RentalAgreementCtrl',
		scope: {
			orderId: '=',
			orderTotalCost: '=',
			user: '=',
			rentals: '=',
			initExpandState: '<',
		},
	};
});
