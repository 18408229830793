import React from 'react';
import PropTypes from 'prop-types';

import styles from './error-fallback.module.css';

const ErrorFallback = (props) => {
	const { error, errorInfo } = props;

	return error ? (
		<div className={styles.container}>
			<h4 className={styles.errorText}>An Error occurred, please contact engineering :(</h4>
			<pre className={styles.errorText}>Error: {error.message}</pre>
			<details>
				<summary>Stack</summary>
				<pre className={styles.errorText}>{error.stack}</pre>
			</details>
			{errorInfo && (
				<details>
					<summary>ComponentStack</summary>
					<pre className={styles.warningText}>{errorInfo.componentStack}</pre>
				</details>
			)}
		</div>
	) : null;
};

ErrorFallback.propTypes = {
	error: PropTypes.object,
	errorInfo: PropTypes.object,
};

export default ErrorFallback;
