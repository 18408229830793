import moment from 'moment-timezone';
import _get from 'lodash/get';
import ngModule from '../../../ng/index.js';

import toastService from '@services/toast.service.js';
import { createErrorHandler } from '../../../state/helpers.js';
import companyDocumentService from '@services/company-documents.service';
import errorHandlerService from '@services/error-handler.service';
import { fetchBranchRentalRates } from '@services/branches.service';
import { fetchWorkOrders } from '@services/work-orders.service.js';
import rentalPurchaseOptionsService from '@services/rental-purchase-options.service';
import locationsService from '@services/locations.service';
import localCache from '@services/local-cache.service';
import { ESTRACK_DOMAIN } from '@const/env';

function RentalCreateFormCtrl($scope, esApiFactoryV2, esAdminRentalService, esDeliveriesService, esCompanyService) {
	const controller = this;
	let asset_id = null;

	$scope.rentalPurchaseOptions = [];

	const getRentalPurchaseOptions = async () => {
		await localCache.cacheFirst(
			localCache.createEntityKey('rental-purchase-options'),
			() => rentalPurchaseOptionsService.search(),
			(rpoOptions) => ($scope.rentalPurchaseOptions = rpoOptions)
		);
	};

	$scope.esTrackWorkOrdersUrl = `${ESTRACK_DOMAIN}#/service/work-orders`;
	$scope.submitting = false;
	$scope.showLocationWarning = false;

	controller.$onInit = () => {
		esApiFactoryV2
			.getRentalProtectionPlans()
			.then((plans) => ($scope.rentalProtectionPlans = plans))
			.then(controller.checkRentalFloaterStatus);

		getRentalPurchaseOptions();

		$scope.clear();
	};

	$scope.clear = () => {
		esCompanyService.isCompanyTaxable(controller.order.user.company.company_id).then((isTaxable) => {
			$scope.rental = {
				order: controller.order,
				taxable: isTaxable,
				startDate: moment().add(1, 'hours').startOf('hour').toDate(),
				duration: 0,
				rental_status_id: 2,
				location_id: null,
				rental_purchase_option_id: null,
			};
		});
	};

	$scope.handleLocationIdChange = (locationId, location) => {
		$scope.rental.location_id = locationId;
		$scope.showLocationWarning = !locationsService.hasLatLng(location);
		$scope.$digest();
	};
	$scope.onPeriodChanged = () => {
		const start = $scope.rental.startDate;
		const totalESDays = $scope.rental.duration;

		$scope.rental.endDate = moment(start).add(totalESDays, 'days').toDate();
		$scope.rentalEnd = moment($scope.rental.endDate).format('MM/DD/YY');
	};

	$scope.setDeliveryRequired = (deliveryRequired) => {
		$scope.rental.dropOffDeliveryRequired = $scope.rental.returnDeliveryRequired = deliveryRequired !== 'false';
	};

	controller.setDefaultRentalPricing = async () => {
		if ($scope.rental.equipment_class) {
			try {
				const rentalRate = await fetchBranchRentalRates(controller.order.market_id, {
					equipment_class_id: $scope.rental.equipment_class.equipment_class_id,
					company_id: controller.order.user.company.company_id,
				});

				$scope.rental.hour = rentalRate.price_per_hour;
				$scope.rental.day = rentalRate.price_per_day;
				$scope.rental.week = rentalRate.price_per_week;
				$scope.rental.month = rentalRate.price_per_month;
			} catch (err) {
				errorHandlerService.genericErrorHandler(err);
			}
		} else {
			$scope.rental.hour = undefined;
			$scope.rental.day = undefined;
			$scope.rental.week = undefined;
			$scope.rental.month = undefined;
		}
	};

	controller.checkRentalFloaterStatus = () => {
		const DEFAULT_15_PERCENT_RPP = 5;

		companyDocumentService.getCurrentRentalFloater(controller.order.user.company.company_id).then((document) => {
			$scope.currentFloaterDoc = document;

			const status = ($scope.floaterDocStatus = companyDocumentService.getRentalFloaterStatus(document));

			if (status.isValid || status.isPartiallyValid) {
				$scope.rental.rental_protection_plan_id = null;
				$scope.allowNoRpp = true;
				$scope.allowRpp = false;
			} else {
				$scope.rental.rental_protection_plan_id = DEFAULT_15_PERCENT_RPP;
				$scope.allowNoRpp = false;
				$scope.allowRpp = true;
			}

			$scope.$digest();
		});
	};

	$scope.equipmentChange = () => {
		const WORK_ORDER_STATUS_OPEN = 1;
		const newAssetId = _get($scope, 'rental.equipment.asset_id');

		if (!newAssetId) {
			$scope.workOrders = null;
			asset_id = null;

			return;
		}

		if (newAssetId !== asset_id) {
			asset_id = newAssetId;
			const query = {
				asset_id: asset_id,
				work_order_status_id: WORK_ORDER_STATUS_OPEN,
			};

			const workOrdersPromise = fetchWorkOrders(query);

			workOrdersPromise.then((workOrders) => {
				$scope.workOrders = workOrders.length > 0 ? workOrders : undefined;
				$scope.$digest();
			});
		}
	};

	$scope.getWorkOrderPrefix = (workOrder) => {
		const workOrderTypePrefixes = {
			1: 'WO',
			2: 'INSP',
		};

		return workOrderTypePrefixes[workOrder.work_order_type_id];
	};

	$scope.equipmentClassChange = controller.setDefaultRentalPricing;

	$scope.submit = () => {
		if (!$scope.rental.equipment_class) {
			toastService.showError('Equipment class must be selected.');
			return;
		}

		if (!$scope.rental.endDate || !$scope.rental.startDate) {
			toastService.showError('A rental start and end date must be selected.');
			return;
		}

		$scope.submitting = true;

		esAdminRentalService
			.createRental(controller.createPayload())
			.then((rental) => {
				esDeliveriesService.refreshDeliveriesByRentalId(rental.rental_id);
				if (controller.onSubmit) {
					controller.onSubmit(rental);
					$scope.clear();
				}
			})
			.catch(createErrorHandler())
			.finally(() => ($scope.submitting = false));
	};

	controller.createPayload = () => {
		if (moment($scope.rental.startDate).isDST() !== moment($scope.rental.endDate).isDST()) {
			const totalESDays = $scope.rental.duration;

			$scope.rental.startDate = moment($scope.rental.startDate).utc();
			$scope.rental.endDate = moment($scope.rental.startDate).utc().add(totalESDays, 'days');
		}

		return {
			order_id: controller.order.order_id,
			start_date: moment($scope.rental.startDate).valueOf(),
			end_date: moment($scope.rental.endDate || moment($scope.rental.startDate).add(1, 'days')).valueOf(),
			rental_status_id: $scope.rental.rental_status_id,
			drop_off_delivery_required: !!$scope.rental.dropOffDeliveryRequired,
			return_delivery_required: !!$scope.rental.returnDeliveryRequired,
			equipment_class_id: $scope.rental.equipment_class ? $scope.rental.equipment_class.equipment_class_id : null,
			equipment_id: $scope.rental.equipment ? $scope.rental.equipment.asset_id : null,
			delivery_charge: $scope.rental.delivery,
			job_description: $scope.rental.job_description,
			return_charge: $scope.rental.pickup,
			price_per_hour: $scope.rental.hour,
			price_per_day: $scope.rental.day,
			price_per_week: $scope.rental.week,
			price_per_month: $scope.rental.month,
			rental_protection_plan_id: $scope.rental.rental_protection_plan_id || null,
			rental_purchase_option_id: $scope.rental.rental_purchase_option_id || null,
			taxable: !!$scope.rental.taxable,
			poc_name: $scope.rental.contact_name,
			poc_phone_number: $scope.rental.contact_phone_number,
			location_id: $scope.rental.location_id,
			rental_type_id: 1,
		};
	};
}

ngModule.controller('RentalCreateFormCtrl', RentalCreateFormCtrl);
