import ngModule from '../../../ng/index.js';
import moment from 'moment-timezone';
import toastService from '@services/toast.service.js';
import { createErrorHandler } from '../../../state/helpers.js';

function RentalSwapDialogCtrl($scope, esApiFactoryV2, $mdDialog, rental, onSave) {
	$scope.cancel = $mdDialog.cancel;
	$scope.rental = rental;
	$scope.previousStartDate = rental.equipment_assignments[rental.equipment_assignments.length - 1].start_date;
	$scope.newEquipment = null;
	$scope.endDate = moment().startOf('day').startOf('minute').toDate();
	$scope.startDate = moment().startOf('day').add(1, 'day').startOf('minute').toDate();
	$scope.dropOffCharge = 0;
	$scope.returnCharge = 0;

	$scope.submitForm = () => {
		if ($scope.saving) {
			return;
		}

		const payload = {
			asset_id: $scope.newEquipment.asset_id,
			end_date: moment.utc($scope.endDate).valueOf(),
			start_date: moment.utc($scope.startDate).valueOf(),
			prior_assignment_return_charge: $scope.returnCharge,
			drop_off_charge: $scope.dropOffCharge,
		};

		$scope.saving = true;
		esApiFactoryV2
			.adminPostSwapEquipmentAssignment($scope.rental.rental_id, payload)
			.then(() => {
				$mdDialog.hide(true);
				onSave();
				toastService.showSuccess('Equipment swap scheduled');
			})
			.catch(createErrorHandler('There is a conflict with this assignment.'))
			.finally(() => ($scope.saving = false));
	};
}

ngModule.controller('RentalSwapDialogCtrl', RentalSwapDialogCtrl);
