import React, { useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

const Context = React.createContext();

export const SORT_DIRECTIONS = {
	ASC: 1,
	NONE: 0,
	DESC: -1,
};
const DEFAULT_SORT_METHOD = (a, b) => {
	if (a === null || a === undefined) {
		return -1;
	} else if (b === null || b === undefined) {
		return 1;
	}

	if (!isNaN(a)) {
		a = parseFloat(a);
	}
	if (!isNaN(b)) {
		b = parseFloat(b);
	}

	if (a < b) {
		return -1;
	} else if (a > b) {
		return 1;
	}

	return 0;
};

export function useSort() {
	return useContext(Context);
}

const SortProvider = ({ children }) => {
	const [sortDirections, setSortDirections] = useState({});
	const [sortKeys, setSortKeys] = useState({});
	const [sortMethods, setSortMethods] = useState({});
	const [sortColumnId, setSortColumnId] = useState(null);

	const setSortMethod = useCallback(
		(columnId, sortMethod) => {
			setSortMethods((state) => ({ ...state, [columnId]: sortMethod }));
		},
		[setSortMethods]
	);
	const getSortMethod = useCallback(
		(columnId) => {
			return sortMethods[columnId] || DEFAULT_SORT_METHOD;
		},
		[sortMethods]
	);

	const setSortKey = useCallback(
		(columnId, sortKey) => {
			setSortKeys((state) => ({ ...state, [columnId]: sortKey }));
		},
		[setSortKeys]
	);
	const getSortKey = useCallback((columnId) => sortKeys[columnId], [sortKeys]);

	const setSortDirection = useCallback(
		(columnId, sortDirection) => {
			setSortDirections((state) => {
				const direction = typeof sortDirection === 'function' ? sortDirection(state[columnId]) : sortDirection;

				return { ...state, [columnId]: direction };
			});
		},
		[setSortDirections]
	);
	const getSortDirection = useCallback(
		(columnId) => {
			return sortDirections[columnId] || SORT_DIRECTIONS.NONE;
		},
		[sortDirections]
	);

	const sortObjects = useCallback(
		(objects = []) => {
			if (!sortColumnId) {
				return objects;
			}

			const sorted = Array.from(objects);
			const sortDirection = getSortDirection(sortColumnId);
			const sortMethod = getSortMethod(sortColumnId);
			const sortKey = getSortKey(sortColumnId);

			if (typeof sortMethod === 'function') {
				if (typeof sortKey === 'string') {
					sorted.sort((a, b) => sortMethod(_get(a, sortKey), _get(b, sortKey)));
				} else {
					sorted.sort(sortMethod);
				}
			}

			if ((sortKey || sortMethod) && sortDirection === SORT_DIRECTIONS.DESC) {
				sorted.reverse();
			}

			return sorted;
		},
		[getSortMethod, getSortKey, getSortDirection, sortColumnId]
	);

	const context = useMemo(
		() => ({
			setSortDirection,
			getSortDirection,
			setSortMethod,
			getSortMethod,
			setSortKey,
			getSortKey,
			sortObjects,
			sortColumnId,
			setSortColumnId,
		}),
		[
			setSortDirection,
			getSortDirection,
			setSortMethod,
			getSortMethod,
			setSortKey,
			getSortKey,
			sortObjects,
			sortColumnId,
			setSortColumnId,
		]
	);

	return <Context.Provider value={context}>{children}</Context.Provider>;
};

SortProvider.Context = Context;
SortProvider.propTypes = {
	children: PropTypes.node,
};

export default SortProvider;
