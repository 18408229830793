import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Row from '../row/row.js';
import Cell from '../cell/cell.js';

import styles from './expandable-row.module.css';

export class ExpandableRow extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
		};

		this.handleClick = this.handleClick.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		const { onPostExpandedChange } = this.props;

		if (this.isExpanded() !== this.isExpanded(prevProps, prevState) && onPostExpandedChange) {
			onPostExpandedChange(this.isExpanded());
		}
	}

	handleClick() {
		const { onExpandedChange } = this.props;
		const expanded = this.isExpanded();

		if (onExpandedChange) {
			onExpandedChange(!expanded);
		} else {
			this.setState({ expanded: !expanded });
		}
	}

	isExpanded(props = this.props, state = this.state) {
		if (props.expanded !== null) {
			return props.expanded;
		}

		return state.expanded;
	}

	render() {
		const {
			className,
			children,
			showExpandIcon,
			expandContent,
			subComponent: SubComponent,
			expandColumnId,
			expandedClass,
			entry,
			expanded,
			onPostExpandedChange,
			onExpandedChange,
			style,
			...props
		} = this.props;
		const isExpanded = this.isExpanded();

		const tableCellIconState = isExpanded ? 'fa-compress' : 'fa-expand';
		const content = expandContent || <SubComponent entry={entry} />;

		return (
			<div style={style}>
				<Row {...props} className={classNames(className, isExpanded && expandedClass)} onClick={this.handleClick}>
					{showExpandIcon && (
						<Cell columnId={expandColumnId || 'expand'}>
							<i className={classNames('fa', tableCellIconState)} aria-hidden="true" />
						</Cell>
					)}
					{children}
				</Row>
				{isExpanded && <div className={styles.expandable}>{content}</div>}
			</div>
		);
	}
}

ExpandableRow.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	entry: PropTypes.object,
	expandColumnId: PropTypes.string,
	expandContent: PropTypes.node,
	expanded: PropTypes.bool,
	expandedClass: PropTypes.string,
	onPostExpandedChange: PropTypes.func,
	onExpandedChange: PropTypes.func,
	showExpandIcon: PropTypes.bool,
	subComponent: PropTypes.func,
	style: PropTypes.object,
};
ExpandableRow.defaultProps = {
	showExpandIcon: false,
	expanded: null,
};

export default ExpandableRow;
