import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import cardStyles from "../card.module.css";

const CardFooter = ( { children, className, ...props } ) => (
  <div className={classNames( cardStyles.footer, className )} {...props}>
    {children}
  </div>
);

CardFooter.propTypes = {
  /** Markup to be displayed in the card's footer */
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

CardFooter.defaultProps = {
  className: null,
};

export default CardFooter;
