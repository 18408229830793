export const DELIVERY_STATUSES = [
	{ id: 1, name: 'Requested' },
	{ id: 2, name: 'Confirmed' },
	{ id: 3, name: 'Completed' },
	{ id: 4, name: 'Cancelled' },
];

export const DELIVERY_STATUS_IDS = {
	REQUESTED: 1,
	CONFIRMED: 2,
	COMPLETED: 3,
	CANCELLED: 4,
};
