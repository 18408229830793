import angular from 'angular';
import template from './table-row-group.html';

angular.module("esNgComponents").component('tableRowGroup', {
  template,
  controller: function ($scope, $timeout) {
    let ctrl = this;

    if(ctrl.collapsed === undefined && ctrl.initExpanded !== undefined){
      ctrl.collapsed = !ctrl.initExpanded;
    }

    $scope.toggle = () => {
      ctrl.collapsed = !ctrl.collapsed;
      $timeout(() =>{
        ctrl.onToggle();
      }, 0);
    }
  },
  transclude: {
    buttons: '?buttons'
  },
  bindings: {
    title: '@',
    collapsed: '=?',
    initExpanded: '<',
    onToggle: '&'
  }
});
