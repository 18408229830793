import React from 'react';
import { Link } from 'react-router-dom';

import { EuiInMemoryTable } from '@equipmentshare/ds2';
import { useFlags } from '@equipmentshare/feature-flags';

import { formatDate } from '../../js/formatting';
import { InvoiceDownloadButton } from './invoice-download-button/invoice-download-button';
import { InvoiceStatusDisplay } from '@components/invoice-status-display/invoice-status-display';
import { OpenInNewTabButton } from '@components/open-in-new-tab-button/open-in-new-tab-button';
import { getStatusSortValue } from '@helpers/invoice-status';
import { Invoice } from '@helpers/invoice';
import { formatCurrencyString } from '@helpers/utilities';

export type SimpleInvoiceTableProps = {
	invoices: Invoice.SearchDtoData[];
};

export const SimpleInvoiceTable: React.VFC<SimpleInvoiceTableProps> = ({ invoices }) => {
	const { enableBillingDisputesAdmin } = useFlags();

	return (
		<EuiInMemoryTable
			sorting={true}
			allowNeutralSort={false}
			itemId="invoiceId"
			tableLayout="auto"
			columns={[
				{
					field: 'invoiceNo',
					name: 'Invoice',
					dataType: 'string',
					sortable: (invoice) => invoice.invoiceNo.toLowerCase(),
					render: (_, invoice) => (
						<Link to={`/home/transactions/invoices/${invoice.invoiceId}`}>{invoice.invoiceNo}</Link>
					),
				},
				{
					field: 'invoiceDate',
					name: 'Date',
					dataType: 'date',
					sortable: true,
					render: (_, invoice) => formatDate(invoice.invoiceDate),
				},
				{
					field: 'status',
					name: 'Status',
					sortable: (invoice) => getStatusSortValue(invoice, enableBillingDisputesAdmin),
					render: (_, invoice) => <InvoiceStatusDisplay invoice={invoice} />,
				},
				{
					field: 'billedAmount',
					name: 'Amount',
					dataType: 'number',
					sortable: true,
					render: (_, invoice) => formatCurrencyString(invoice.billedAmount || ''),
				},
				{
					field: 'owedAmount',
					name: 'Balance',
					dataType: 'number',
					sortable: true,
					render: (_, invoice) => formatCurrencyString(invoice.owedAmount || ''),
				},
				{
					actions: [
						{
							render: (invoice) => <InvoiceDownloadButton invoice={invoice} size="xs" display="empty" />,
						},
						{
							render: (invoice) => <OpenInNewTabButton url={`/#/home/transactions/invoices/${invoice.invoiceId}`} />,
						},
					],
				},
			]}
			items={invoices}
		/>
	);
};

export default SimpleInvoiceTable;
