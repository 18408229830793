import React from 'react';
import PropTypes from 'prop-types';

import Column from '../column/column.js';

export const ActionColumn = ({ actionWidth, numberOfActions, columnId, ...props }) => {
	const columnWidth = `calc( ${numberOfActions} * ${actionWidth} )`;

	return <Column columnId={columnId} columnWidth={columnWidth} {...props} />;
};

ActionColumn.propTypes = {
	actionSpacing: PropTypes.number,
	numberOfActions: PropTypes.number.isRequired,
	actionWidth: PropTypes.any,
	columnId: PropTypes.string.isRequired,
};
ActionColumn.defaultProps = {
	actionWidth: '3em',
	columnId: 'actions',
};

export default ActionColumn;
