import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../tooltip/tooltip";
import assetStatusIcons from "../asset-status-icons.module.css";

const AssetBrakeStatusIcon = ( { tooltipDirection, statusCode } ) => {
  let statusClass = `${statusCode}`;

  return (
    <Tooltip direction={tooltipDirection} text="Brake Status">
      <div className={`${assetStatusIcons.diagnosticCodesIcon} ${assetStatusIcons[statusClass]}`} />
    </Tooltip>
  );
};

AssetBrakeStatusIcon.propTypes = {
  statusCode: PropTypes.string,
  tooltipDirection: PropTypes.string,
};
AssetBrakeStatusIcon.defaultProps = {
  statusCode: "ok",
  tooltipDirection: "right"
};

export default AssetBrakeStatusIcon;