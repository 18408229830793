import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

let radioId = 0;

import formControlStyles from "../form-control.module.css";

export default function RadioButton ( { className, id, label, onChange, selected, value } ) {
  let defaultId = `radio${radioId++}`;

  return (
    <div className={classNames( formControlStyles.control, className )}>
      <label htmlFor={id || defaultId}>
        {label}
      </label>
      <input
        aria-labelledby={id || defaultId}
        checked={selected}
        id={id || defaultId}
        name={id || defaultId}
        onChange={onChange}
        type="radio"
        value={value}
      />
    </div>
  );
}

RadioButton.propTypes = {
  /** custom class */
  className: PropTypes.string,
  /** an id for the button (optional) */
  id: PropTypes.string,
  /** text for the radio button label */
  label: PropTypes.string,
  /** onChange event handler */
  onChange: PropTypes.func,
  /** is this button selected or not */
  selected: PropTypes.bool,
  /** value to be used for the radio button */
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ] ).isRequired
};

RadioButton.defaultProps = {
  className: "",
  id: "",
  label: "",
  labelClassName: "",
  onChange: () => {},
  selected: false,
};
