import map from 'lodash/fp/map';

angular.module('esApiClient').factory('esBranchService', function (esApiFactoryV2, esCanonicalizer) {
  let canonicalizer = esCanonicalizer.canonicalizer('branch');

  function getBranches(query) {
    return esApiFactoryV2.getBranches(query)
      .then(map(canonicalizer))
  }

  function getCompanyBranches(companyId, query) {
    return esApiFactoryV2.getCompanyBranches(query)
      .then(map(canonicalizer))
  }

  return {
    getBranches,
    getCompanyBranches
  }
});
