import angular from 'angular';
import React from 'react';
import { react2angular } from 'react2angular';
import { TextArea } from '@equipmentshare/react-ui-components/dist/form-controls/textarea';

const TextAreaComponent = ({ forceUpdate, ...props }) => <TextArea {...props} />;

angular
	.module('esBilling')
	.component(
		'reactTextArea',
		react2angular(TextAreaComponent, Object.keys(TextArea.propTypes).concat(['forceUpdate']))
	);
