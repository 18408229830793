import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { EuiFlyout, EuiFlyoutHeader, EuiFlyoutBody, EuiTitle } from '@equipmentshare/ds2';
import { ENTITY_TYPES } from '@equipmentshare/admin-hooks';

import POCreateForm from '@components/po-create-form/po-create-form.js';

const POCreateFormDrawer = ({ companyId, onClose, onCreatePO, isOpen = false }) => {
	const queryClient = useQueryClient();

	if (!isOpen) {
		return null;
	}

	const handleCreated = (purchaseOrder) => {
		queryClient.invalidateQueries([ENTITY_TYPES.PURCHASE_ORDER]);

		if (onCreatePO) {
			onCreatePO(purchaseOrder);
		}
	};

	return (
		<EuiFlyout size="s" onClose={onClose}>
			<EuiFlyoutHeader hasBorder>
				<EuiTitle size="s">
					<h1>Create Purchase Order</h1>
				</EuiTitle>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<POCreateForm onCancel={onClose} onCreatePO={handleCreated} companyId={companyId} />
			</EuiFlyoutBody>
		</EuiFlyout>
	);
};

POCreateFormDrawer.propTypes = {
	companyId: PropTypes.number,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onCreatePO: PropTypes.func,
};

export default POCreateFormDrawer;
