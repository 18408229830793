import _ from 'lodash';

import esApi from '@services/es-api.service';
import { RENTAL_LINE_ITEM_TYPES, PARTS_LINE_ITEM_TYPES } from '@const/invoices';

const SAVED_FIELDS = [
	'description',
	'lineItemTypeId',
	'branchId',
	'assetId',
	'numberOfUnits',
	'pricePerUnit',
	'taxable',
	'partId',
];
function getLineItemPayload(lineItem) {
	const payload = {
		description: lineItem.description,
		line_item_type_id: lineItem.lineItemTypeId,
		branch_id: lineItem.branchId,
		asset_id: lineItem.assetId,
		number_of_units: parseFloat(lineItem.numberOfUnits),
		price_per_unit: parseFloat(lineItem.pricePerUnit),
		taxable: lineItem.taxable,
		part_id: lineItem.partId,
	};

	payload.branch_id = payload.branch_id || undefined;
	payload.asset_id = payload.asset_id || undefined;

	return payload;
}
function isLineItemChanged(original, changed) {
	return !_.isEqual(_.pick(original, SAVED_FIELDS), _.pick(changed, SAVED_FIELDS));
}

function isPartLineItem(lineItem) {
	return PARTS_LINE_ITEM_TYPES.includes(lineItem.line_item_type_id);
}

async function create(payload) {
	return await esApi.request('/line-items', {}, { body: payload, method: 'POST' });
}

async function fetch(lineItemId) {
	return await esApi.request(esApi.url`/line-items/${lineItemId}`);
}

async function search(query) {
	return await esApi.request('/line-items', query);
}

async function update(id, payload) {
	return await esApi.request(esApi.url`/line-items/${id}`, null, { body: payload, method: 'PATCH' });
}

async function destroy(id) {
	return await esApi.request(esApi.url`/line-items/${id}`, null, { method: 'DELETE' });
}

async function batchUpdate(invoiceId, lineItems = [], originalLineItems = [], lineItemsToDelete = [], onDeleteError) {
	for (const lineItem of lineItems) {
		const original = _.find(originalLineItems, ['id', lineItem.id]);

		if (original && isLineItemChanged(original, lineItem)) {
			const payload = getLineItemPayload(lineItem);

			await update(original.id, payload);
		}
	}

	const createLineItems = _.differenceBy(lineItems, originalLineItems, 'id');
	for (const lineItem of createLineItems) {
		const payload = getLineItemPayload(lineItem);

		payload.invoice_id = invoiceId;

		await create(payload);
	}

	const itemsDeleted = [];
	for (const lineItemId of lineItemsToDelete) {
		await destroy(lineItemId)
			.then(() => itemsDeleted.push(lineItemId))
			.catch((e) => {
				onDeleteError(itemsDeleted);
				throw e;
			});
	}
}

function getRentalLineItems(lineItems) {
	return lineItems.filter((li) => _.includes(RENTAL_LINE_ITEM_TYPES, li.line_item_type_id));
}

function getNonRentalLineItems(lineItems) {
	return lineItems.filter((li) => !_.includes(RENTAL_LINE_ITEM_TYPES, li.line_item_type_id));
}

const lineItemsService = {
	fetch,
	create,
	search,
	update,
	destroy,
	batchUpdate,
	getRentalLineItems,
	getNonRentalLineItems,
	isPartLineItem,
};

export default lineItemsService;
