import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { isString } from '@helpers/utilities';
import { reducer } from './reducer';
import {
	UPDATE_RATE_TYPE,
	RESET_RATE_TYPE,
	INSERT_FILTER_ROW,
	REMOVE_FILTER_ROW,
	UPDATE_FILTER_ROW,
	RESET_FILTER_ROWS,
	UPDATE_ACTIVE_FILTER_GROUPS,
	RESET_FILTER_GROUPS,
	SET_FILTER_QUERY,
	RESET_FILTER_QUERY,
} from './actions';
import { filterGroupOptions, filterTypeIds } from './filter-group-options';

import { useLayoutProvider } from './layout-provider';

export const FilterState = createContext();
export const FilterDispatch = createContext();

export function useFilterState() {
	return useContext(FilterState);
}

export function useFilterDispatch() {
	return useContext(FilterDispatch);
}

const FilterProvider = ({ children }) => {
	const [state, dispatch] = reducer();
	const { setLoading } = useLayoutProvider();

	const getFilterProps = (_id, name, type) => {
		const label = filterGroupOptions[state.rateType].find((option) => option.value === type).label;

		return {
			_id,
			label,
			name,
			type,
		};
	};

	const getRowByType = (type) => {
		return state.rows.find((row) => row.type === type);
	};

	const getTypeIdsFromArray = (type, idName) => {
		return _get(getRowByType(type), 'value', []).reduce((acc, curr) => {
			acc.push(curr[idName]);

			return acc;
		}, []);
	};

	const filterRowRemoveReset = (rows) => {
		const removeFilterState = (name) => {
			const _filter = state.rows.find((row) => row.type === name);
			updateActiveFilterGroups(name);
			removeFilterRow(_get(_filter, 'id', null));
		};

		if (Array.isArray(rows)) {
			rows.forEach((name) => {
				removeFilterState(name);
			});
		} else {
			removeFilterState(rows);
		}
		updateActiveFilterGroups();
	};

	/**
	 * Dispatchers
	 */

	/** state.filterQuery */
	const createFilterQueryItems = () => {
		return new Promise(() => {
			const filterObj = Object.entries(filterTypeIds).reduce((acc, [key, val]) => {
				const _isString = isString(val);
				const _val = _isString ? val : val.key;
				const _prop = _isString ? val : val.queryParam;
				let row;

				if (!_isString && val?.multi) {
					row = getTypeIdsFromArray(key, _val);
					if (row && !_isEmpty(row)) {
						acc[_prop] = [...row];
					}
				} else {
					row = _get(getRowByType(key), 'value', null);
					if (row && !_isEmpty(row)) {
						acc[_prop] = row[_val];
					}
				}

				return acc;
			}, {});

			setFilterQuery(filterObj);
		});
	};

	const setFilterQuery = (filterQuery) => {
		dispatch({ type: SET_FILTER_QUERY, filterQuery });
	};

	const resetFilterQuery = () => dispatch({ type: RESET_FILTER_QUERY });

	/** state.rows */
	const insertFilterRow = () => {
		dispatch({ type: INSERT_FILTER_ROW });
	};

	const removeFilterRow = (id) => {
		dispatch({ type: REMOVE_FILTER_ROW, id });
	};

	const updateFilterRow = (row) => {
		// row type 'location' can only exist when a company row with a single value exists
		if (row.type === 'company') {
			if (!row?.value || row.value.length >= 2) {
				filterRowRemoveReset('location');
			}
		}
		dispatch({ type: UPDATE_FILTER_ROW, row });
	};

	const updateFilterRowNoReset = (row) => {
		dispatch({ type: UPDATE_FILTER_ROW, row });
	};

	const resetFilterRows = () => dispatch({ type: RESET_FILTER_ROWS });

	/** state.filterGroups */
	const updateActiveFilterGroups = (group, disable = false) => {
		dispatch({ type: UPDATE_ACTIVE_FILTER_GROUPS, group, disable });
	};

	const resetFilterGroups = (rateType) => dispatch({ type: RESET_FILTER_GROUPS, rateType });

	/** state.rateType */
	const updateRateType = (rateType) => dispatch({ type: UPDATE_RATE_TYPE, rateType });

	const resetRateType = () => dispatch({ type: RESET_RATE_TYPE });

	/** batch processes */
	const groupSelect = (group, rowIndex) => {
		updateFilterRow({ id: rowIndex, type: group });
		updateActiveFilterGroups(group, true);
	};

	const removeRow = (filterType, rowIndex) => {
		removeFilterRow(rowIndex);
		updateActiveFilterGroups(filterType);
	};

	const rowPopulate = (group, value) => {
		updateActiveFilterGroups(group, true);
		updateFilterRow(value);
	};

	const clearFilters = (rateType) => {
		resetFilterGroups(rateType);
		resetFilterRows();
		resetFilterQuery();
	};

	const getFilterChips = (chipData) => {
		return state.rows.reduce((acc, curr) => {
			if (!curr.type || !curr.value) {
				return acc;
			}

			if (Array.isArray(curr.value)) {
				curr.value.map((val) => {
					acc.push(getFilterProps(val._id, val.name, curr.type));

					return acc;
				});
			} else if (_isEmpty(curr.value) && chipData) {
				acc.push(getFilterProps(chipData.value._id, chipData.value.name, chipData.type));
			} else {
				acc.push(getFilterProps(curr.value._id, curr.value.name, curr.type));
			}

			return acc;
		}, []);
	};

	const removeFilterChip = (chip) => {
		setLoading(true);
		const chipRow = getRowByType(chip.type);

		if (Array.isArray(chipRow.value)) {
			chipRow.value = chipRow.value.filter((val) => val._id !== chip._id);
			updateFilterRow(chipRow);
		} else {
			chipRow.value = {};
			updateFilterRowNoReset(chipRow);
		}
		if (!chipRow.value.length) {
			removeRow(chip.type, chipRow.id);
		}
		createFilterQueryItems();
	};

	const dispatcher = useMemo(
		() => ({
			insertFilterRow,
			removeFilterRow,
			updateFilterRow,
			resetFilterRows,

			updateActiveFilterGroups,
			resetFilterGroups,

			updateRateType,
			resetRateType,

			createFilterQueryItems,
			setFilterQuery,
			resetFilterQuery,

			groupSelect,
			removeRow,
			rowPopulate,
			clearFilters,

			getFilterChips,
			removeFilterChip,
		}),
		[
			insertFilterRow,
			removeFilterRow,
			updateFilterRow,
			resetFilterRows,

			updateActiveFilterGroups,
			resetFilterGroups,

			updateRateType,
			resetRateType,

			createFilterQueryItems,
			setFilterQuery,
			resetFilterQuery,

			groupSelect,
			removeRow,
			rowPopulate,
			clearFilters,

			getFilterChips,
			removeFilterChip,
		]
	);

	return (
		<FilterState.Provider value={{ state }}>
			<FilterDispatch.Provider value={{ dispatch, dispatcher }}>{children}</FilterDispatch.Provider>
		</FilterState.Provider>
	);
};

FilterProvider.propTypes = {
	children: PropTypes.node,
};

export default FilterProvider;
