import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, Autocomplete } from '@equipmentshare/react-ui-components';
import { RATE_PRICING_TYPES } from '@const/rate-pricing-types';

import SectionWrapper from './section-wrapper';

import styles from './rental-create-form-components.module.css';
import ShiftPicker from '@components/shift-picker';

const RateSelect = ({
	dailyRate,
	disabled,
	disabledShift,
	handleRateChange,
	selectedPricingType,
	handlePricingTypeChange,
	hourlyRate,
	hourlyRateRequired = true,
	monthlyRate,
	shiftId,
	enableShiftPricing,
	handleShiftChange,
	rateTypeName = '',
	rateTypeStyle,
	required = false,
	showSubheader = true,
	weeklyRate,
}) => {
	const rates = [
		{ name: 'hourlyRate', value: hourlyRate, onChange: handleRateChange, label: 'Hourly', hourlyRateRequired },
		{ name: 'dailyRate', value: dailyRate, onChange: handleRateChange, label: 'Daily', required },
		{ name: 'weeklyRate', value: weeklyRate, onChange: handleRateChange, label: 'Weekly', required },
		{ name: 'monthlyRate', value: monthlyRate, onChange: handleRateChange, label: '4-Week', required },
	];

	return (
		<SectionWrapper header="Rates" subHeader={showSubheader ? '(per part)' : null}>
			<div className={styles.sectionRow}>
				<div className={styles.sectionItem}>
					<Autocomplete
						className={styles.dropdownSelect}
						labelKey="name"
						onChange={handlePricingTypeChange}
						disabled={disabled}
						options={RATE_PRICING_TYPES}
						simpleValue={false}
						valueKey="id"
						value={selectedPricingType}
						label="Pricing Type"
					/>
				</div>
				{enableShiftPricing && (
					<div className={styles.sectionItem}>
						<ShiftPicker
							className={styles.dropdownSelect}
							onChange={handleShiftChange}
							name="shiftId"
							disabled={disabledShift}
							selectedShiftId={shiftId}
							label="Shift"
						/>
					</div>
				)}
			</div>
			{selectedPricingType === '2' ? (
				<div className={styles.rowSelectors}>
					<Input
						className={styles.rateInput}
						disabled={disabled}
						iconName="fa fa-dollar"
						key={rates[1].label}
						label={rates[1].label}
						min={0}
						name={rates[1].name}
						onChange={rates[1].onChange}
						required={rates[1].required}
						step={0.01}
						type="number"
						value={rates[1].value}
					/>
				</div>
			) : (
				<div className={styles.rowSelectors}>
					{rates.map((rate) => (
						<Input
							className={styles.rateInput}
							disabled={disabled}
							iconName="fa fa-dollar"
							key={rate.label}
							label={rate.label}
							min={0}
							name={rate.name}
							onChange={rate.onChange}
							required={rate.required}
							step={0.01}
							type="number"
							value={rate.value}
						/>
					))}
				</div>
			)}
			{rateTypeName && (
				<div className={classNames(styles.rateType, rateTypeStyle && rateTypeStyle)}>{rateTypeName} rates applied</div>
			)}
		</SectionWrapper>
	);
};

RateSelect.propTypes = {
	dailyRate: PropTypes.number,
	disabled: PropTypes.bool,
	disabledShift: PropTypes.bool,
	shiftId: PropTypes.number,
	handleRateChange: PropTypes.func.isRequired,
	handleShiftChange: PropTypes.func,
	enableShiftPricing: PropTypes.bool,
	selectedPricingType: PropTypes.string,
	handlePricingTypeChange: PropTypes.func.isRequired,
	hourlyRate: PropTypes.number,
	hourlyRateRequired: PropTypes.bool,
	monthlyRate: PropTypes.number,
	rateTypeStyle: PropTypes.object,
	rateTypeName: PropTypes.string,
	required: PropTypes.bool,
	showSubheader: PropTypes.bool,
	weeklyRate: PropTypes.number,
};

export default RateSelect;
