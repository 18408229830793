import ngModule from '../../../ng/ng-module.js';
import rentalsService from '../../../services/rentals.service.js';

import template from './rental-details.html';

function RentalDetailsCtrl($scope, $state) {
	$scope.$watch(
		() => $state.params.rentalId,
		(newValue) => {
			if (newValue) {
				this.fetchRental();
			}
		}
	);

	this.fetchRental = async () => {
		const rental = await rentalsService.fetch(parseInt($state.params.rentalId, 10));
		$scope.rental = rental;
		$scope.$digest();
	};
}

ngModule
	.component('rentalDetails', {
		template,
		controller: 'RentalDetailsCtrl',
	})
	.controller('RentalDetailsCtrl', RentalDetailsCtrl);
