import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EuiTitle, EuiButton, EuiFlexGroup, EuiFlexItem } from '@equipmentshare/ds2';
import { EuiSimpleFlyout } from '@equipmentshare/es-admin-eui-simple-components';
import { withState } from '@equipmentshare/react2angular-router';
import LinkButton from '@components/link-button/link-button';
import ExportButton from './export-button';
import RateDrawer from '../rate-drawer';
import CsvUploadModal from '../modals/csv-upload-modal';
import VoidModal from '../modals/void-modal';
import ExpirationModal from '../modals/expiration-modal';
import FilterPopover from '../filter-popover';
import { useRatesProvider } from '../providers/rates-provider';
import { useFilterState } from '../providers/filter-provider';
import styles from './header.module.css';

const ButtonGroup = styled(EuiFlexGroup)`
	margin-bottom: 0.8em;
`;

export const Header = ({ disableModifierButtons, path, drawerOpen, onDrawerClose }) => {
	const [csvModalIsOpen, onSetCsvModalIsOpen] = useState(false);
	const toggleCsvUploadModal = () => onSetCsvModalIsOpen(!csvModalIsOpen);

	const [voidModalIsOpen, onSetVoidModalIsOpen] = useState(false);
	const toggleVoidModal = () => onSetVoidModalIsOpen(!voidModalIsOpen);

	const [expirationModalIsOpen, setExpirationModalIsOpen] = useState(false);
	const toggleExpirationModal = () => setExpirationModalIsOpen(!expirationModalIsOpen);

	const { state: filterState } = useFilterState();

	const { selectedRates, selectedRatesCount } = useRatesProvider();

	return (
		<div className={styles.header}>
			<div className={styles.row}>
				<div className={styles.title}>
					<EuiTitle size="s">
						<h1>Rates</h1>
					</EuiTitle>
				</div>
				{!disableModifierButtons && (
					<div className={styles.actions}>
						<EuiButton color="text" size="s" onClick={toggleCsvUploadModal}>
							CSV Upload
						</EuiButton>
						<LinkButton color="text" size="s" title="New Rate" to={`${path}.add-rate`} />
					</div>
				)}
			</div>

			<ButtonGroup gutterSize="none">
				<EuiFlexItem grow={false}>
					<LinkButton
						title="Company"
						to="home.rates.company"
						fill={path.includes('rates.company')}
						color={path.includes('rates.company') ? 'primary' : 'text'}
						size="s"
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<LinkButton
						title="Location"
						to="home.rates.location"
						fill={path.includes('rates.location')}
						color={path.includes('rates.location') ? 'primary' : 'text'}
						size="s"
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<LinkButton
						title="Branch"
						to="home.rates.branch"
						fill={path.includes('rates.branch')}
						color={path.includes('rates.branch') ? 'primary' : 'text'}
						size="s"
					/>
				</EuiFlexItem>
			</ButtonGroup>

			<div className={styles.row}>
				<FilterPopover rateType={filterState.rateType} />

				<EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
					<EuiFlexItem grow={false}>
						<ExportButton rateType={filterState.rateType} />
					</EuiFlexItem>
					{!disableModifierButtons && (
						<React.Fragment>
							<EuiFlexItem grow={false}>
								<EuiButton color="text" size="s" isDisabled={disableModifierButtons} onClick={toggleExpirationModal}>
									Edit Expiration Dates
								</EuiButton>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton color="danger" size="s" isDisabled={disableModifierButtons} onClick={toggleVoidModal}>
									Void Rates
								</EuiButton>
							</EuiFlexItem>
						</React.Fragment>
					)}
				</EuiFlexGroup>
			</div>

			{drawerOpen ? (
				<EuiSimpleFlyout onClose={onDrawerClose} title="Add Rate" paddingSize="m">
					<RateDrawer rateType={filterState.rateType} onClose={onDrawerClose} />
				</EuiSimpleFlyout>
			) : null}

			<CsvUploadModal isOpen={csvModalIsOpen} onClose={toggleCsvUploadModal} />

			<VoidModal
				isOpen={voidModalIsOpen}
				onClose={toggleVoidModal}
				selectedRates={selectedRates}
				selectedRatesCount={selectedRatesCount}
			/>

			<ExpirationModal
				isOpen={expirationModalIsOpen}
				onClose={toggleExpirationModal}
				selectedRates={selectedRates}
				selectedRatesCount={selectedRatesCount}
			/>
		</div>
	);
};

Header.propTypes = {
	disableModifierButtons: PropTypes.bool,
	path: PropTypes.string,
	drawerOpen: PropTypes.bool,
	onDrawerClose: PropTypes.func,
};

export default withState(Header);
