import _get from 'lodash/get';
import toastService from '@services/toast.service.js';

const ERROR_EVENT = {
	unauthorized: 'You must be logged into the perform this action',
	forbidden: 'You do not have permission to perform this action',
	notFound: 'Resource not found',
	serverError: 'The server encountered an error. Please contact engineering',
};

export function createErrorHandler(defaultMessage = 'An unexpected error occurred. Please contact engineering') {
	return (err) => {
		let message = defaultMessage;

		if (typeof err.error === 'string' && err.error.length > 0) {
			message = err.error || defaultMessage;
		} else if (typeof err.error === 'object') {
			message = err.error.message || defaultMessage;
		} else if (_get(err, 'data.message')) {
			message = _get(err, 'data.message');
		} else if (typeof err.message === 'string') {
			message = err.message || defaultMessage;
		} else if (ERROR_EVENT[err.name]) {
			message = ERROR_EVENT[err.name];
		}

		if (err instanceof Error || typeof message !== 'string') {
			console.error(err);
		}
		if (typeof message === 'string') {
			toastService.showError(message);
		} else {
			toastService.showError(defaultMessage);
		}
	};
}
