import _map from "lodash/map";
import _each from "lodash/each";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _remove from "lodash/remove";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";

import AssetJobs from "@equipmentshare/kernel-legacy/src/es/jobs/Assets.js";

angular.module( "esApiClient" ).factory( "esEquipmentService", esEquipmentService );

export default function esEquipmentService ( esApiFactoryV2, $q, $log, esEnv, esCanonicalizer ) {
  let canonicalCategory = esCanonicalizer.canonicalizer( "category" );
  let canonicalClass = esCanonicalizer.canonicalizer( "equipment_class" );
  let categories;

  function getCategories () {
    if ( categories ) {
      return $q.when( categories );
    }

    return esApiFactoryV2.getCategories()
        .then( ( response ) => {
          let canonicalize = ( category ) => {
            category = canonicalCategory( category );
            category.categories = _map( category.categories, canonicalize );
            if( category.parent_category_id ) {
              category.category = canonicalCategory( category.parent_category_id );
            }

            return category;
          };

          categories = _map( response.data, canonicalize );

          _remove( categories, ( category ) => {
            return !category.active;
          } );
          _forEach( categories, ( category ) => {
            category._photoUrl = esEnv.getCdnHost() + "/img/" + category.image;
          } );

          return categories;
        } );
  }

  function getEquipmentClasses ( categoryId ) {
    return esApiFactoryV2.getEquipmentClasses( categoryId )
      .then( ( classes ) => {
        classes = _map( classes, ( cls ) => {
          cls = canonicalClass( cls );
          cls.category = canonicalCategory( cls.category_id );
          setCategoryOrClassPhotoUrls( cls );

          return cls;
        } );

        return classes;
      } );
  }

  function getEquipmentClass ( equipmentClassId ) {
    return esApiFactoryV2.getEquipmentClass( equipmentClassId )
      .then( ( cls ) => {
        cls = canonicalClass( cls );
        cls.category = canonicalCategory( cls.category_id );
        setCategoryOrClassPhotoUrls( cls );

        return cls;
      } );
  }

  function setIsFilterActive ( equipmentList, isActive ) {
    _each( equipmentList, ( asset ) => {
      asset._isFilterActive = isActive;
    } );
  }

  function setEquipmentHasTracker ( equipmentList ) {
    _forEach( equipmentList, ( equipment ) => {
      equipment._hasTracker = ( !_isEmpty( equipment.current_location ) || !_isEmpty( equipment.current_status ) );
    } );
  }

  function setHasDiagnosticCodes ( equipmentList ) {
    _each( equipmentList, ( equipment ) => {
      equipment._hasDiagnosticCodes = equipment.diagnostic_codes && equipment.diagnostic_codes.length > 0;
    } );
  }

  function setEquipmentPhotoUrl ( equipmentList ) {
    _forEach( equipmentList, ( equipment ) => {
      equipment._photoUrl = esApiFactoryV2.getPhotoUrl( equipment, false, true );
    } );
  }

  function setCategoryOrClassPhotoUrls( categoryOrClass ) {
    if( categoryOrClass.photo ) {
      categoryOrClass._smallPhotoUrl = esEnv.getCdnHost() + "/uploads/small/" + categoryOrClass.photo.filename;
      categoryOrClass._mediumPhotoUrl = esEnv.getCdnHost() + "/uploads/medium/" + categoryOrClass.photo.filename;
    }
  }

  function getUsersEquipment ( forceReload, fields ) {
    var that = this;
    var deferred = $q.defer();

    if ( that.equipmentList && !forceReload ) {
      deferred.resolve( that.equipment );
    } else {
      AssetJobs
        .fetchAll( fields )
        .subscribe( ( event ) => {
          if ( event.name === "assets" ) {
            let equipment = _filter( event.assets, "equipment_id" );

            that.equipmentList = equipment;
            setEquipmentHasTracker( that.equipmentList );
            setHasDiagnosticCodes( that.equipmentList );
            setEquipmentPhotoUrl( that.equipmentList );
            setIsFilterActive( that.equipmentList, true );
            deferred.resolve( that.equipmentList );
          }
        } );
    }

    return deferred.promise;
  }

  function getEquipmentById ( equipmentId, forceReload ) {
    var that = this;
    var deferred = $q.defer();

    if ( that.equipment && that.equipment.equipment_id === equipmentId && !forceReload ) {
      deferred.resolve( that.equipment );
    } else {
      AssetJobs
        .fetchAsset( equipmentId )
        .subscribe( ( event ) => {
          if ( event.name === "asset" ) {
            let equipment = event.asset;

            that.equipment = esCanonicalizer.canonicalize( "asset", equipment );
            setEquipmentHasTracker( [ that.equipment ] );
            setHasDiagnosticCodes( [ that.equipment ] );
            setEquipmentPhotoUrl( [ that.equipment ] );
            setIsFilterActive( [ that.equipment ], true );
            deferred.resolve( that.equipment );
          }
        } );
    }

    return deferred.promise;
  }

  function getSubcategories ( parentCategoryId ) {
    return getCategories()
      .then( ( allCategories ) => {
        var parentCategory = _find( allCategories, ( category ) => {
          return category.category_id === parentCategoryId;
        } );

        var subcategories = parentCategory.categories;

        _remove( subcategories, ( subcategory ) => {
          return !subcategory.active;
        } );

        _forEach( subcategories, ( subcategory ) => {
          subcategory._photoUrl = esEnv.getCdnHost() + "/img/" + subcategory.image;
        } );

        return subcategories;
      } );
  }

  function patchEquipment ( equipmentId, equipment ) {
    return esApiFactoryV2.patchEquipment( equipmentId, equipment );
  }

  function uploadPhoto ( equipmentId, formData ) {
    return esApiFactoryV2.postPhoto( formData );
  }

  return {
    categories: categories,
    equipment: undefined,
    equipmentList: undefined,
    getUsersEquipment: getUsersEquipment,
    getEquipmentById: getEquipmentById,
    getCategories: getCategories,
    getSubcategories: getSubcategories,
    getEquipmentClasses: getEquipmentClasses,
    getEquipmentClass: getEquipmentClass,
    patchEquipment: patchEquipment,
    uploadPhoto: uploadPhoto
  };
}
