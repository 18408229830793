import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EuiIcon, EuiToolTip } from '@equipmentshare/ds2';
import { InformationFillIcon, FilterLineIcon } from '@equipmentshare/ds2-icons';
import { Stack } from '@equipmentshare/es-admin-components';
import { useFlags } from '@equipmentshare/feature-flags';

import SortIcon from './sort-icon/sort-icon.js';
import { useColumns } from '../providers/column-provider.js';
import { SORT_DIRECTIONS, useSort } from '../providers/sort-provider.js';

import styles from './column.module.css';
import FilterPopover from '../filter-popover/filter-popover.js';
import { useFilter } from '../providers/filter-provider.js';

export const Column = ({
	columnId,
	sortKey,
	sortMethod,
	filterKey,
	filterMethod,
	filterComponent,
	columnWidth,
	onClick,
	title,
	children,
	className,
	empty,
	titleClassName,
	initialSortDirection,
}) => {
	const filterElement = useRef();
	const { register, unregister, setWidth } = useColumns();
	const { setSortKey, setSortMethod, sortColumnId, getSortDirection, setSortDirection, setSortColumnId } = useSort();
	const { getFilterActive } = useFilter();

	// register and unregister column
	useEffect(() => {
		register(columnId);

		return () => unregister(columnId);
	}, [columnId]);

	// update column width
	useEffect(() => {
		if (columnId) {
			setWidth(columnId, columnWidth);
		}

		return () => setWidth(columnId, null);
	}, [columnId, columnWidth, setWidth]);

	// update sort methods
	useEffect(() => {
		setSortKey(columnId, sortKey);
		setSortMethod(columnId, sortMethod);

		return () => {
			setSortKey(columnId, null);
			setSortMethod(columnId, null);
		};
	}, [columnId, sortKey, sortMethod, setSortKey, setSortMethod]);

	const isActiveSortColumn = sortColumnId === columnId;
	const filterable = !!filterKey;
	const clickable = !!onClick;
	const sortable = !!sortKey || !!sortMethod;
	const hoverable = clickable || sortable;

	const handleClick = useCallback(
		(event) => {
			if (onClick) {
				onClick(event);
			}

			if (filterElement.current && filterElement.current.contains(event.target)) {
				return;
			}
			if (!sortable) {
				return;
			}

			if (isActiveSortColumn) {
				setSortDirection(columnId, (direction) => -direction);
			} else {
				setSortDirection(columnId, initialSortDirection);
			}

			setSortColumnId(columnId);
		},
		[onClick, columnId, isActiveSortColumn, setSortDirection, sortable, setSortColumnId, filterElement]
	);

	const style = { width: columnWidth };
	const headerTitle = title || (typeof children === 'string' || typeof children === 'number' ? String(children) : null);
	const rateHeaders = ['Book', 'Advertised', 'Benchmark', 'Floor'];

	return (
		<div
			title={headerTitle}
			style={style}
			className={classNames(
				styles.column,
				sortable && styles.sortable,
				filterable && styles.filterable,
				hoverable && styles.hoverable,
				clickable && styles.clickable,
				empty && styles.empty,
				className
			)}
		>
			<div className={styles.headerInner}>
				{filterable && (
					<FilterPopover
						columnId={columnId}
						filterComponent={filterComponent}
						filterKey={filterKey}
						filterMethod={filterMethod}
						refFilterComponent={filterElement}
						anchor="sw"
						align="bottom right"
						trigger={({ openPopover, closePopover, isOpen }) => (
							<button
								type="button"
								className={classNames(styles.filterButton, getFilterActive(columnId) && styles.filterActive)}
								onClick={isOpen ? closePopover : openPopover}
							>
								<EuiIcon type={FilterLineIcon} />
							</button>
						)}
					/>
				)}
				<div className={classNames(styles.title, titleClassName)} onClick={handleClick}>
					<Stack justifyContent="center" gap="0.4rem">
						<span>{!empty && children}</span>
						{rateHeaders.indexOf(children) !== -1 ? (
							<EuiToolTip content={'Hourly / Day / Week / 4-Week'}>
								<EuiIcon type={InformationFillIcon} />
							</EuiToolTip>
						) : null}
					</Stack>
				</div>
				{sortable && (
					<button className={styles.sortButton} type="button" onClick={handleClick}>
						<SortIcon sortDirection={isActiveSortColumn ? getSortDirection(columnId) : SORT_DIRECTIONS.NONE} />
					</button>
				)}
			</div>
		</div>
	);
};

Column.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	titleClassName: PropTypes.string,
	/** the id of the column */
	columnId: PropTypes.string.isRequired,
	infoIcon: PropTypes.node,
	/** the width of the column */
	columnWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	empty: PropTypes.bool,
	/**
	 * the component to use for the filter input
	 * it will be passed "value" and "onChange" props
	 *
	 * "onChange" will be a method that accepts a new value as the first argument `onChange(newValue)`
	 * */
	filterComponent: PropTypes.any,
	/** the property to use when filtering the rows */
	filterKey: PropTypes.string,
	filterMethod: PropTypes.func,
	/** the initial direction to sort the rows when this column is sorted */
	initialSortDirection: PropTypes.oneOf([0, 1, -1]),
	/** the property to sort the rows with when this column is sorted */
	sortKey: PropTypes.string,
	/**
	 * a method used to sort the rows when this column is sored
	 * if "sortKey" is set then the method will be called with (entryASortKey, entryBSortKey, entryA, entryB)
	 * but if "sortKey" is not set then this method is called with (entryA, entryB)
	 */
	sortMethod: PropTypes.func,
	title: PropTypes.string,
	onClick: PropTypes.func,
};

Column.defaultProps = {
	initialSortDirection: SORT_DIRECTIONS.ASC,
};

export default React.memo(Column);
