import React from 'react';
import PropTypes from 'prop-types';

import { EuiIcon } from '@equipmentshare/ds2';
import { MenuLineIcon } from '@equipmentshare/ds2-icons';

import styles from './mobile-nav.module.css';

const MobileNav = ({ children, onClick }) => (
	<div className={styles.nav}>
		<button className={styles.menuButton} onClick={onClick}>
			<EuiIcon type={MenuLineIcon} />
		</button>
		<span>{children}</span>
	</div>
);

MobileNav.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func,
};
MobileNav.defaultProps = {
	children: null,
};

export default MobileNav;
