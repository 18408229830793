import esApi from './es-api.service';

export async function fetchAsset(assetId = null) {
	if (!assetId) {
		throw new Error(`No assetId supplied: ${assetId}`);
	}

	return await esApi.request(esApi.url`/assets/${assetId}`);
}

export async function getAssetStatus(assetId) {
	return await esApi.request(esApi.url`/assets/${assetId}/status`);
}

export async function updateAsset(assetId = null, payload = null) {
	if (!assetId || !payload) {
		throw new Error(`Missing required parameter - assetId: ${assetId}, payload: ${payload}`);
	}

	const req = await esApi.createRequest(esApi.buildEndpoint(`/assets/${assetId}`), { method: 'PATCH', body: payload });

	return fetch(req).then((res) => {
		if (!res.ok) {
			return Promise.reject(res);
		}

		return res;
	});
}

export async function searchAssets(query, queryOpts) {
	return await esApi.request(esApi.url`/admin/assets`, query, queryOpts);
}

const assetsService = {
	fetchAsset,
	getAssetStatus,
	updateAsset,
	searchAssets,
};

export default assetsService;
