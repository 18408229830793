import { gql } from '@apollo/client';
import getOperationNameFromQuery from '../../utils/get-operation-name-from-query.js';

const partQuery = gql`
	query Part($partId: Int!) {
		part(partId: $partId) {
			id
			__typename
			partClass {
				id
				description
				__typename
			}
		}
	}
`;

export const partQueryName = getOperationNameFromQuery(partQuery);
export default partQuery;
