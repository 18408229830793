import mapTag from 'map-tag';
import qs from 'qs';

import { API_URL } from '@const/env';
import authService from './auth.service';

const defaultOpts = {
	method: 'get',
	apiUrl: API_URL,
};

function extendDefaultOpts(opts) {
	const headers = opts && opts.headers ? { ...defaultOpts.headers, ...opts.headers } : defaultOpts.headers || {};

	return {
		...defaultOpts,
		...opts,
		headers,
	};
}

function createQueryString(params, opts = {}) {
	return qs.stringify(params, {
		addQueryPrefix: true,
		indices: false,
		...opts,
	});
}

/** build out an api endpoint */
function buildEndpoint(endpoint, query, opts) {
	const options = extendDefaultOpts(opts);

	const urlPrefix = options.apiUrl || '';
	const queryString = query ? createQueryString(query, options) : '';

	return urlPrefix + endpoint + queryString;
}

async function createRequestOptions(opts) {
	const options = extendDefaultOpts(opts);

	// add api token
	if (!options.headers.Authorization) {
		const token = await authService.getApiToken();

		options.headers = {
			...options.headers,
			Authorization: `Bearer ${token}`,
		};
	}

	// stringify JSON bodies
	if (typeof options.body === 'object' && !(options.body instanceof FormData)) {
		options.body = JSON.stringify(options.body);

		if (!options.headers || !options.headers['Content-Type']) {
			options.headers = {
				...options.headers,
				'Content-Type': 'application/json',
			};
		}
	}

	return options;
}
async function createRequest(url, opts) {
	const options = await createRequestOptions(opts);

	return new Request(url, options);
}

/** parse response to json, or reject if response.ok === false */
function handleResponse(res, opts) {
	if (!res.ok) {
		return Promise.reject(res);
	}

	if (res.headers.get('content-type') === 'application/json' || opts?.forceJson) {
		return res.json();
	}

	return res.text();
}

/** create a request from endpoint and add basic handlers */
async function requestWithHandler(endpoint, query, opts, handler) {
	const options = extendDefaultOpts(opts);

	const url = buildEndpoint(endpoint, query, options);
	const req = await createRequest(url, options);

	return fetch(req).then(handler);
}

/** same as requestWithHandler but use default JSON response handler */
async function request(endpoint, query, opts) {
	//return requestWithHandler(endpoint, query, opts, handleResponse);
	return requestWithHandler(endpoint, query, opts, (res) => handleResponse(res, opts));
}

export function setOverrideApiUrl(apiUrl) {
	if (apiUrl) {
		defaultOpts.apiUrl = apiUrl;
	} else {
		delete defaultOpts.apiUrl;
	}
}

const esApi = {
	createQueryString,
	requestWithHandler,
	request,
	buildEndpoint,
	createRequestOptions,
	createRequest,
	setOverrideApiUrl,
	url: mapTag(encodeURIComponent),
};

export default esApi;
