import ngModule from '../../ng/index.js';
import _filter from 'lodash/filter';
import _reverse from 'lodash/reverse';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';

import moment from 'moment-timezone';

function esRentalActivityService(esApiFactoryV2, $q, esCanonicalizer) {
	const service = this;

	function RentalActivity(opts) {
		this.delivery_id = opts.delivery_id;
		this.rental_id = opts.rental_id;
		this.type = opts.type;
		this.state = opts.state;
		this.date = moment(opts.date);
	}

	RentalActivity.prototype.describe = function () {
		// TODO: this should be a property that we get from the backend, not generated on the fly
		return this.type.charAt(0).toUpperCase() + this.type.slice(1).replace(/_/g, ' ');
	};

	service.new_group = function (delivery_id, rental_id, start, activities) {
		activities = activities || [];

		return esCanonicalizer.canonicalize('activity_group', {
			activity_group_id: rental_id,
			delivery_id,
			rental_id,
			start,
			activities: activities || [],
			overdue_activities: _filter(activities, service.isOverdueDispatchActivity),
		});
	};

	service.getActivitiesForRental = function (rentalId) {
		return esApiFactoryV2
			.adminGetRentalActivity(rentalId, { start: 0, end: new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000 })
			.then(service.mapToActivityObjects)
			.then(service.sortActivitiesByDate);
	};

	service.refreshActivitiesForRental = (rentalId) => {
		return esApiFactoryV2
			.adminGetRentalActivity(rentalId, { start: 0, end: new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000 })
			.then(service.mapToActivityObjects)
			.then(service.groupActivitiesByRental);
	};

	service.sortActivitiesByDate = function (activities) {
		return _reverse(_sortBy(activities, activityDate));
	};

	service.getOverdueRentalActivities = function (markets) {
		return esApiFactoryV2
			.adminGetRentalActivity({
				start: moment().subtract(2, 'months').valueOf(),
				end: moment().subtract(1, 'days').valueOf(),
				market_id: markets,
				state: 'anticipated',
			})
			.then(service.mapToActivityObjects)
			.then(service.groupActivitiesByRental);
	};

	service.mapToActivityObjects = function (rawActivities) {
		return _map(rawActivities, (raw) => {
			return new RentalActivity({
				delivery_id: raw.data.delivery_id || null,
				rental_id: raw.rental_id,
				type: raw.type,
				state: raw.state,
				date: moment(raw.date),
			});
		});
	};

	const activitiesDispatchersCareAbout = [
		'drop_off_equipment_to_customer',
		'pickup_equipment_from_customer',
		'customer_picks_up_equipment',
		'customer_drops_off_equipment',
		'deliver_asset',
		'assign_equipment',
		'on_rent',
		'off_rent',
	];

	service.isOverdueDispatchActivity = function (a) {
		return (
			activitiesDispatchersCareAbout.indexOf(a.type) != -1 && a.state === 'anticipated' && a.date.isBefore(moment())
		);
	};

	service.groupActivitiesByRental = function (activities) {
		const groups = [];

		_forEach(_groupBy(activities, 'rental_id'), (activities, rental_id) => {
			rental_id = parseInt(rental_id);
			const groupActivities = service.sortActivitiesByDate(activities);

			groups.push(
				service.new_group(groupActivities[0].delivery_id, rental_id, groupActivities[0].date, groupActivities)
			);
		});

		return groups;
	};

	service.groupActivitiesByDateAndRental = function (activities) {
		const groupingThreshold = moment.duration(2, 'hours').asMilliseconds();
		const groups = [];

		_forEach(_groupBy(activities, 'rental_id'), (activities, rental_id) => {
			let priorDate = 0;
			let currentGroup;

			rental_id = parseInt(rental_id);

			_forEach(_sortBy(activities, activityDate), (activity) => {
				if (moment(activity.date).valueOf() - priorDate > groupingThreshold) {
					groups.push((currentGroup = service.new_group(activity.delivery_id, rental_id, activity.date)));
				}

				priorDate = moment(activity.date).valueOf();
				currentGroup.activities.push(activity);
			});
		});

		return groups;
	};

	function activityDate(a) {
		return moment(a.date).valueOf();
	}

	return {
		getOverdueRentalActivities: service.getOverdueRentalActivities,
		groupActivitiesByDateAndRental: service.groupActivitiesByDateAndRental,
		getActivitiesForRental: service.getActivitiesForRental,
		refreshActivitiesForRental: service.refreshActivitiesForRental,
	};
}

ngModule.factory('esRentalActivityService', esRentalActivityService);
