function placeAttrsByType(addrComponents) {
	return (addrComponents || []).reduce((acc, data) => {
		data.types.forEach((type) => {
			acc[type] = data;
		});

		return acc;
	}, {});
}

function placeGet(byType) {
	return (key, short) => {
		if (!(key in byType)) {
			return '';
		}

		return short ? byType[key].short_name : byType[key].long_name;
	};
}

export function parseGooglePlace(place = {}) {
	const byType = placeAttrsByType(place.address_components);
	const getter = placeGet(byType);

	return {
		formattedAddress: place.formatted_address,
		streetNumber: getter('street_number'),
		streetNameLong: getter('route'),
		streetNameShort: getter('route', true),
		city:
			getter('locality') ||
			getter('sublocality') ||
			getter('sublocality_level_1') ||
			getter('neighborhood') ||
			getter('administrative_area_level_3') ||
			getter('administrative_area_level_2'),
		county: getter('administrative_area_level_2'),
		stateShort: getter('administrative_area_level_1', true),
		stateLong: getter('administrative_area_level_1'),
		countryShort: getter('country', true),
		countryLong: getter('country'),
		zipCode: getter('postal_code'),
	};
}

export function convertPlaceToLocation(place) {
	const parsed = parseGooglePlace(place);

	const location = {
		city: parsed.city,
		state: {
			name: parsed.stateLong,
			abbreviation: parsed.stateShort,
		},
		zip_code: parsed.zipCode || null,
		street_1: [parsed.streetNumber, parsed.streetNameShort].join(' '),
		latitude: place.geometry ? place.geometry.location.lat() : undefined,
		longitude: place.geometry ? place.geometry.location.lng() : undefined,
	};

	if (parsed.countryShort.toLowerCase() === 'nz') {
		location.state = parsed.countryLong;
	}

	return location;
}

let service;
function getPlaceService() {
	if (!service) {
		service = new google.maps.places.PlacesService(document.createElement('div'));
	}

	return service;
}

export async function getPlaceDetails(placeId) {
	return new Promise((res) => {
		getPlaceService().getDetails({ placeId }, (place) => {
			res(place);
		});
	});
}

export async function textSearch(query = '', opts = {}) {
	if (query.length < 3) {
		return [];
	}

	return new Promise((res) => {
		getPlaceService().textSearch({ query, ...opts }, (places) => {
			res(places);
		});
	});
}

const googlePlacesService = {
	parseGooglePlace,
	convertPlaceToLocation,
	textSearch,
	getPlaceDetails,
};

export default googlePlacesService;
