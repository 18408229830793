import moment from 'moment-timezone';
import ngModule from '../../../ng/index.js';

function TransportCreateFormCtrl($scope, esDeliveriesService) {
	const controller = this;

	controller.$onInit = () => {
		$scope.clear();
	};

	$scope.submit = (form) => {
		if (controller.onSubmit) {
			controller.onSubmit(controller.createPayload());
			$scope.clear();
		}
	};

	$scope.clear = () => {
		$scope.transport = {
			order: controller.order,
			scheduled_date: moment().add(1, 'hours').startOf('hour').toDate(),
			destination: null,
			asset: null,
			charge: undefined,
			note: '',
			contact_name: '',
			contact_phone_number: '',
		};
	};

	controller.createPayload = () => {
		return {
			order_id: controller.order.order_id,
			delivery_status_id: esDeliveriesService.deliveryStatusIds.REQUESTED,
			facilitator_type_id: esDeliveriesService.deliveryFacilitatorTypes.IN_HOUSE.delivery_facilitator_type_id,
			location_id: $scope.transport.destination ? $scope.transport.destination.location_id : null,
			asset_id: $scope.transport.asset ? $scope.transport.asset.asset_id : null,
			charge: $scope.transport.charge,
			scheduled_date: moment($scope.transport.scheduled_date),
			note: $scope.transport.note,
			contact_name: $scope.transport.contact_name,
			contact_phone_number: $scope.transport.contact_phone_number,
		};
	};

	return controller;
}

ngModule.controller('TransportCreateFormCtrl', TransportCreateFormCtrl);
