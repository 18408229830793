import React from "react";
import PropTypes from "prop-types";
import _startCase from "lodash/startCase";

import Tooltip from "../../../tooltip/tooltip.js";
import TooltipText from "../components/tooltip-text/tooltip-text.js";
import AssetMapIconFactory from "../asset-map-icon-factory.js";

function getOutOfLockDescription( reasons ) {
  const defaultReason = "Too long since last contact";

  if ( !Array.isArray( reasons ) ) {
    return defaultReason;
  }

  const overrides = {
    "stale-gps-location": "Stale GPS Location",
  };

  const formattedReasons = reasons.map( ( reason ) => (
    <li key={reason}>
      {overrides[ reason ] || _startCase( reason )}
    </li>
  ) );

  return formattedReasons.length ? ( <ul>{formattedReasons}</ul> ) : defaultReason;
}

// eslint-disable-next-line react/no-multi-comp
const AssetTrackerIcon = ( { assetStatus, markerColor, statusCode, tooltipDirection } ) => {
  const locationIconSrc = AssetMapIconFactory.getMapMarker( null, statusCode, {
    markerColor,
    direction: statusCode === "asset-heading" ? assetStatus.direction : 0
  } );

  const assetStatusNames = {
    "asset-not-communicating": <TooltipText description={getOutOfLockDescription( assetStatus.out_of_lock )} title="Out of Lock" />,
    "asset-running": <TooltipText description="Ignition is on and not idling" title="Running" />,
    "asset-heading": <TooltipText description="Speed > 0" title="Driving" />,
    "asset-idling": <TooltipText description="Ignition on and no speed > 5 min" title="Idling" />,
    "asset-being-hauled": <TooltipText description="Being Hauled" />,
    "asset-bluetooth-node": <TooltipText description="Tracker is bluetooth node" />,
    "asset-off": <TooltipText description="Ignition off" title="Off" />,
    "manual-location": <TooltipText description="Live location of asset is not available" title="Manual Location" />,
  };

  return (
    <Tooltip direction={tooltipDirection} text={assetStatusNames[statusCode]}>
      <img src={locationIconSrc} />
    </Tooltip>
  );
};

AssetTrackerIcon.propTypes = {
  assetStatus: PropTypes.object.isRequired,
  markerColor: PropTypes.string,
  statusCode: PropTypes.string.isRequired,
  tooltipDirection: PropTypes.string,
};
AssetTrackerIcon.defaultProps = {
  tooltipDirection: "right",
  markerColor: null,
};
export default AssetTrackerIcon;
