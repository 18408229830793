import ngModule from '../ng/index.js';

import '@views/companies/routes-component';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.companies', {
			url: '/companies',
			template: `
        <react-companies-view style="height:100%"/>
      `,
			redirectTo: 'home.companies.search',
		})
		.state('home.companies.search', {
			url: '/search?query',
		})
		.state('home.companies.company', {
			url: '/:companyId',
		})
		.state('home.companies.company.activity', {
			url: '/activity',
		})
		.state('home.companies.company.orders', {
			url: '/orders?search&branch&submitted',
		})
		.state('home.companies.company.invoices', {
			url: '/invoices',
		})
		.state('home.companies.company.transactions', {
			url: '/transactions',
			redirectTo: 'home.companies.company.transactions.all',
		})
		.state('home.companies.company.transactions.all', {
			url: '/all?status&start&end',
		})
		.state('home.companies.company.transactions.invoices', {
			url: '/invoices?status&start&end',
		})
		.state('home.companies.company.transactions.credit-notes', {
			url: '/credit-notes?status&start&end',
		})
		.state('home.companies.company.transactions.payments', {
			url: `/payments?${[
				'status',
				'order',
				'stripeId',
				'isErpSynced',
				'hasBalance',
				'before',
				'after',
				'submitted',
				'reference',
				'checkNumber',
				'enteredAsPrepayment',
				'amount',
			].join('&')}`,
		})
		.state('home.companies.company.documents', {
			url: '/documents?showVoided',
		})
		.state('home.companies.company.edit', {
			url: '/edit',
		})
		.state('home.companies.company.users', {
			url: '/users',
		})
		.state('home.companies.company.users.edit', {
			url: `/edit/:userId?${['zipCode'].join('&')}`,
		})
		.state('home.companies.company.users.add', {
			url: '/add',
		})
		.state('home.companies.company.rental-rates', {
			url: '/rental-rates',
		})
		.state('home.companies.company.rental-rates.add', {
			url: '/add',
		})
		.state('home.companies.company.purchase-orders', {
			url: '/purchase-orders',
		})
		.state('home.companies.company.locations', {
			url: '/locations',
		})
		.state('home.companies.company.locations.create', {
			url: '/create',
		})
		.state('home.companies.company.locations.location', {
			url: '/:locationId',
		})
		.state('home.companies.company.locations.location.edit', {
			url: '/edit',
		})
		.state('home.companies.company.settings', {
			url: '/settings',
		});
});
