import React from 'react';

import { EuiButtonIcon, EuiButtonIconProps, EuiToolTip } from '@equipmentshare/ds2';
import { Download2LineIcon } from '@equipmentshare/ds2-icons';

import { useInvoiceDownloadAction } from '@hooks/use-invoice-download-action';
import { Invoice } from '@helpers/invoice';

export type InvoiceDownloadButtonProps = {
	invoice: Invoice.SearchDtoData;
} & Omit<EuiButtonIconProps, 'iconType' | 'isDisabled' | 'onClick'>;

export const InvoiceDownloadButton: React.VFC<InvoiceDownloadButtonProps> = ({ invoice, ...props }) => {
	const { action, isLoading } = useInvoiceDownloadAction(invoice);

	return (
		<EuiToolTip content={'Download PDF'} delay="long">
			<EuiButtonIcon
				aria-label="Download"
				{...props}
				iconType={Download2LineIcon}
				onClick={action}
				isDisabled={isLoading}
			/>
		</EuiToolTip>
	);
};
