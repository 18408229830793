import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _flow from 'lodash/flow';
import { useQuery } from 'react-query';
import { useFlags } from '@equipmentshare/feature-flags';

import { withState } from '@equipmentshare/react2angular-router';
import { heapEvent } from '@equipmentshare/heaper';
import { EuiIcon } from '@equipmentshare/ds2';
import {
	UserFillIcon,
	RefreshLineIcon,
	ArrowLeftSLineIcon,
	ArrowRightSLineIcon,
	LogoutBoxRLineIcon,
	AlertLineIcon,
	AddCircleLineIcon,
	ContactsBookLineIcon,
	FileTextLineIcon,
	CustomerServiceLineIcon,
	ListUnorderedIcon,
	CheckboxBlankCircleLineIcon,
	CurrencyLineIcon,
	MoneyDollarBoxLineIcon,
	MoneyDollarCircleLineIcon,
	ToolsLineIcon,
	ArrowLeftRightFillIcon,
} from '@equipmentshare/ds2-icons';

import NavLink from './nav-link/nav-link.js';
import toastService from '@services/toast.service.js';
import { HeaperComponent } from '@components/heaper/heaper-component';
import { RotatingIcon } from '@components/rotating-icon/rotating-icon';

import authService from '@services/auth.service';
import { checkForUpdates } from '../../updates.js';
import { ESTRACK_DOMAIN, IS_USING_STAGING_API, TRANSFERS_DOMAIN } from '@const/env';

import logoSrc from '../../images/menu-logo.svg';
import logoCollapsedSrc from '../../images/small-logo.svg';
import { withBreakpoints } from '../breakpoints';
import { withErrorHandler } from '../error-boundary';

import styles from './app-menu.module.css';

const NAV_ITEMS = [
	{
		route: 'home.orders.create',
		label: 'New Order',
		icon: AddCircleLineIcon,
		isActive: (path) => path.includes('home.orders'),
	},
	{
		route: 'home.companies',
		label: 'Contacts',
		icon: ContactsBookLineIcon,
		isActive: (path) => path.includes('home.contacts') || path.includes('home.companies'),
	},
	{
		route: 'home.rentals',
		label: 'Rentals',
		icon: FileTextLineIcon,
		isActive: (path) => path.includes('home.rentals'),
	},
	{
		route: 'home.dispatch',
		label: 'Dispatch',
		icon: CustomerServiceLineIcon,
		isActive: (path) => path.includes('home.dispatch'),
	},
	{
		route: 'home.inventory',
		label: 'Inventory',
		icon: ListUnorderedIcon,
		isActive: (path) => path.includes('home.inventory'),
	},
	{
		route: 'home.assets',
		label: 'Assets',
		icon: CheckboxBlankCircleLineIcon,
		isActive: (path) => path.includes('home.assets'),
	},
	{
		href: TRANSFERS_DOMAIN,
		label: 'Transfers',
		icon: ArrowLeftRightFillIcon,
		target: '_blank',
		isActive: () => false,
	},
	{
		route: 'home.transactions',
		label: 'Transactions',
		icon: CurrencyLineIcon,
		isActive: (path) => path.includes('home.transactions'),
	},
	{
		route: 'home.payments',
		label: 'Payments',
		icon: MoneyDollarBoxLineIcon,
		isActive: (path) => path.includes('home.payments'),
	},
	{
		route: 'home.rates',
		label: 'Rates',
		icon: MoneyDollarCircleLineIcon,
		isActive: (path) => path.includes('home.rates'),
	},
	{
		href: `${ESTRACK_DOMAIN}#/home/service`,
		label: 'Service',
		icon: ToolsLineIcon,
		isActive: () => false,
	},
];

export const AppMenu = ({ match, breakpoints, isOpen, toggleOpen }) => {
	const [checkingUpdates, setCheckingUpdates] = useState(false);
	const { path } = match;
	const { data: auth0User } = useQuery(['auth0-user', 'AppMenu'], () => authService.getAuth0User());

	const handleLogout = () => {
		heapEvent(`Admin - Navigation Menu - Click - Logout`, {});
		authService.logout();
	};

	const handleToggleOpen = useCallback(() => {
		toggleOpen(!isOpen);
	}, [toggleOpen, isOpen]);

	const handleItemClick = useCallback(
		(label) => {
			heapEvent(`Admin - Navigation Menu - Click - ${label}`, {});

			if (breakpoints.includes('tablet-portrait-and-down') && isOpen) {
				toggleOpen(false);
			}
		},
		[isOpen, breakpoints, toggleOpen]
	);

	const handleRefreshClick = useCallback(() => {
		setCheckingUpdates(true);

		setTimeout(async () => {
			try {
				const foundUpdates = await checkForUpdates();

				if (!foundUpdates) {
					toastService.showSuccess('No updates found');
				}
			} catch (e) {
				toastService.showError('Failed to check for updates');
			} finally {
				setCheckingUpdates(false);
			}
		}, 1000);
	}, [setCheckingUpdates]);

	const { enableAssetTransferRequest } = useFlags();

	return (
		<div className={classNames(styles.container, isOpen && styles.open)}>
			{/*
			 * Adding HeaperComponent here to initialize heaper with the useHeaper hook.
			 * It is done here rather than in GlobalProviders since the app menu is loaded once for the application and
			 * GlobalProviders is loaded for each view/component it is wrapped around which resulted in multiple calls
			 * to initialize heaper.
			 */}
			<HeaperComponent />
			<div className={styles.backdrop} onClick={handleToggleOpen} />
			<nav className={classNames(styles.menu)}>
				<div className={styles.logoContainer}>
					<img src={isOpen ? logoSrc : logoCollapsedSrc} alt="Rent" />
				</div>
				<div className={styles.scrollContainer}>
					{NAV_ITEMS.filter(
						(item) => item.label !== 'Transfers' || (item.label === 'Transfers' && enableAssetTransferRequest)
					).map(({ route, label, icon, isActive, ...props }) => (
						<NavLink
							key={route || label}
							to={route}
							label={label}
							title={label}
							icon={icon}
							active={isActive(path)}
							collapsed={!isOpen}
							onClick={handleItemClick}
							{...props}
						/>
					))}
					<NavLink onClick={handleLogout} icon={LogoutBoxRLineIcon} label="Logout" collapsed={!isOpen} />
					{IS_USING_STAGING_API && (
						<NavLink icon={AlertLineIcon} collapsed={!isOpen} className={styles.stagingWarning}>
							<span>Testing Environment</span>
							<br />
							<small>Changes reset nightly</small>
						</NavLink>
					)}
				</div>
				{auth0User && (
					<div className={styles.userCard}>
						<div className={styles.userAvatar}>
							{auth0User.picture ? (
								<img src={auth0User.picture} alt={auth0User.name} style={{ width: '100%' }} />
							) : (
								<EuiIcon type={UserFillIcon} />
							)}
						</div>
						<div className={styles.userInfo} data-testid="user-name-and-email">
							<span className={styles.userName}>{auth0User.name}</span>
							<span className={styles.userEmail}>{auth0User.email}</span>
						</div>
					</div>
				)}
				<div className={styles.bottomButtons}>
					<button
						className={styles.refreshButton}
						onClick={handleRefreshClick}
						title="Check for updates"
						disabled={checkingUpdates}
					>
						<RotatingIcon type={RefreshLineIcon} size="l" spin={checkingUpdates} />
					</button>
					<button
						className={styles.collapseButton}
						onClick={handleToggleOpen}
						title={isOpen ? 'Collapse Menu' : 'Expand Menu'}
					>
						<EuiIcon type={isOpen ? ArrowLeftSLineIcon : ArrowRightSLineIcon} size="l" />
					</button>
				</div>
			</nav>
		</div>
	);
};

AppMenu.propTypes = {
	isOpen: PropTypes.bool,
	toggleOpen: PropTypes.func,
	breakpoints: PropTypes.array,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	match: PropTypes.shape({
		path: PropTypes.string,
	}).isRequired,
};
AppMenu.defaultProps = {
	breakpoints: [],
};

export default _flow([withState, withBreakpoints(), withErrorHandler()])(AppMenu);
