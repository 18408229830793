import React from 'react';
import PropTypes from 'prop-types';

import { EuiSimpleFlyout } from '@equipmentshare/es-admin-eui-simple-components';

import CreateForm from '../create-form/create-form';

export const CreateDrawer = ({ isOpen, onClose, companyId, userId, onCreateLocation, onSelectExistingGeofence }) => {
	if (!isOpen) {
		return null;
	}

	return (
		<EuiSimpleFlyout onClose={onClose} title="Create Location" size="m" maxWidth="500px" outsideClickCloses={false}>
			<CreateForm
				userId={userId}
				companyId={companyId}
				onCancel={onClose}
				onCreateLocation={onCreateLocation}
				onSelectExistingGeofence={onSelectExistingGeofence}
			/>
		</EuiSimpleFlyout>
	);
};

CreateDrawer.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	userId: PropTypes.number.isRequired,
	companyId: PropTypes.number,
	onCreateLocation: PropTypes.func,
	onSelectExistingGeofence: PropTypes.func,
};

export default CreateDrawer;
