import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '@equipmentshare/react2angular-router';
import { EuiIcon } from '@equipmentshare/ds2';

import styles from './nav-link.module.css';

const NavLink = ({ children, to, label, className, onClick, active, icon, collapsed, ...props }) => {
	const clickable = !!onClick || !!to;

	return (
		<Link
			className={classNames(
				styles.link,
				active && styles.active,
				collapsed && styles.collapsed,
				clickable && styles.clickable,
				className
			)}
			to={to}
			onClick={() => onClick(label)}
			title={label}
			{...props}
		>
			{!!icon && (
				<span className={styles.iconContainer}>
					<EuiIcon className={styles.icon} type={icon} size="l" />
				</span>
			)}
			{children ? <div className={styles.contents}>{children}</div> : <span className={styles.label}>{label}</span>}
		</Link>
	);
};

NavLink.propTypes = {
	children: PropTypes.node,
	active: PropTypes.bool,
	className: PropTypes.string,
	collapsed: PropTypes.bool,
	icon: PropTypes.any,
	label: PropTypes.string,
	onClick: PropTypes.func,
	to: PropTypes.string,
};

export default NavLink;
