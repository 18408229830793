import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import formControlStyles from "../form-control.module.css";
import selectDropDownStyles from "./select-dropdown.module.css";

let selectId = 0;

export default class SelectDropDown extends PureComponent {
  UNSAFE_componentWillMount() {
    this._id = `select-dropdown${selectId++}`;
  }
  isRequired() {
    return ( this.props.required && <span className={formControlStyles.required}>&nbsp;(required)</span> );
  }
  render() {
    const {
      children,
      className,
      disabled,
      iconClickHandler,
      iconName,
      id,
      label,
      name,
      onChange,
      placeholder,
      required,
      value,
      ...props
    } = this.props;

    let iconClasses = classNames(
      formControlStyles.icon,
      iconName,
      { [formControlStyles.hasAction]: iconClickHandler }
    );

    return (
      <div className={classNames( formControlStyles.control, selectDropDownStyles.select, className )}>
        {label &&
          <label htmlFor={id || this._id}>{ label }{ this.isRequired() }</label>
        }
        <div className={classNames( formControlStyles.inputContainer, { [formControlStyles.hasIcon]: !!iconName } )}>
          {iconName &&
            <span aria-hidden="true" className={formControlStyles.iconContainer} onClick={iconClickHandler}>
              <i className={iconClasses} />
            </span>
          }
          <select
            aria-labelledby={id || this._id}
            className={formControlStyles.input}
            disabled={disabled}
            id={id || this._id}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            value={value || ""}
            {...props}
          >
            <option disabled hidden value="">{placeholder}</option>
            { children }
          </select>
        </div>
      </div>
    );
  }
}

SelectDropDown.propTypes = {
  children: PropTypes.oneOfType( [
    PropTypes.element,
    PropTypes.node,
    PropTypes.array
  ] ).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconClickHandler: PropTypes.oneOfType( [
    PropTypes.func,
    PropTypes.instanceOf( null )
  ] ),
  iconName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
  ] ),
};

SelectDropDown.defaultProps = {
  className: "",
  disabled: false,
  iconClickHandler: null,
  iconName: null,
  id: null,
  label: null,
  name: null,
  onChange: () => {},
  placeholder: null,
  required: false,
  value: null,
};
