import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow } from '@equipmentshare/ds2';
import { EuiSimpleDatePicker, EuiSimpleModal } from '@equipmentshare/es-admin-eui-simple-components';

import errorHandlerService from '@services/error-handler.service';
import { dateToISO, TODAY } from '../../rate.helpers';

import { useRatesProvider } from '../../providers/rates-provider';

import styles from '../modals.module.css';

const ButtonGroup = styled(EuiFlexGroup)`
	margin-top: 8px;
`;

export const ExpirationModal = ({ isOpen, onClose, selectedRates, selectedRatesCount }) => {
	const [loading, setLoading] = useState(false);
	const [expirationDate, setExpirationDate] = useState(TODAY);
	const { updateRateExpirationDate } = useRatesProvider();

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		setLoading(true);

		try {
			const expirationDateISO = dateToISO(expirationDate);
			await updateRateExpirationDate(expirationDateISO);

			setLoading(false);
			setExpirationDate(TODAY);
			return onClose();
		} catch (err) {
			return errorHandlerService.genericErrorHandler(
				err,
				'Unable to edit the expiration dates of the selected rates, please contact engineering'
			);
		}
	};

	if (!isOpen) {
		return null;
	}

	return (
		<EuiSimpleModal title="Edit Expiration Dates" onClose={onClose}>
			<form className={styles.layout} onSubmit={handleSubmit}>
				<div className={styles.content}>
					{selectedRates.length ? (
						<React.Fragment>
							<div className={styles.contentHeader}>
								Edit Expiration Dates on all selected Rates ({selectedRatesCount})?
							</div>
							<EuiFormRow label="Expires On" fullWidth>
								<EuiSimpleDatePicker
									className={styles.input}
									selected={expirationDate}
									onChange={setExpirationDate}
									required
									fullWidth
								/>
							</EuiFormRow>
						</React.Fragment>
					) : (
						'No rates are selected'
					)}
				</div>
				<ButtonGroup gutterSize="s" justifyContent="flexEnd">
					<EuiFlexItem grow={false}>
						<EuiButton fill size="s" type="submit" isDisabled={loading || !selectedRates.length} isLoading={loading}>
							Save
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButton color="text" size="s" onClick={onClose}>
							Cancel
						</EuiButton>
					</EuiFlexItem>
				</ButtonGroup>
			</form>
		</EuiSimpleModal>
	);
};

ExpirationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	selectedRates: PropTypes.array,
	selectedRatesCount: PropTypes.string,
};

export default ExpirationModal;
