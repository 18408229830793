import { isNonEmptyArray } from '@apollo/client/utilities';
import esApi from '@services/es-api.service';

export async function fetch({ orderId, quoteCreatedDate, includedPlanId }, opts) {
	let identifier = quoteCreatedDate ? `quote_created_date=${quoteCreatedDate}` : `order_id=${orderId}`;
	if (includedPlanId) {
		identifier = `${identifier}&included_plan_id=${includedPlanId}`;
	}

	return await esApi.request(
		orderId || quoteCreatedDate
			? esApi.url`/rental-protection-plans?` + identifier
			: esApi.url`/rental-protection-plans`,
		null,
		opts
	);
}

const rentalProtectionPlansService = {
	fetch,
};

export default rentalProtectionPlansService;
