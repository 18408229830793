import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import Icon from "../icon/icon.js";

import styles from "./chip.module.css";

const boundStyles = classNames.bind( styles );

export const TYPES = {
  default: "default",
  primary: "primary",
  negative: "negative",
  positive: "positive",
};

class Chip extends PureComponent {
  constructor( ...args ){
    super( ...args );

    this.handleCloseClick = this.handleCloseClick.bind( this );
  }

  handleCloseClick( event ){
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if( this.props.onClose ){
      this.props.onClose( event );
    }
  }

  render(){
    const { closeLabel, onClick, href, showCloseIcon, rounded, iconElement, children, chipType, className } = this.props;

    let closeIcon = showCloseIcon && (
      <button aria-label={closeLabel} className={styles.close} onClick={this.handleCloseClick}>
        <Icon name="farTimes" />
      </button>
    );
    let icon = iconElement && (
      <div className={styles.icon}>{iconElement}</div>
    );
    let contents = (
      <span className={styles.text}>{children}</span>
    );

    const isLink = !!href;
    const isClickable = !!onClick || isLink;
    const elementClassName = boundStyles( [
      "chip",
      chipType || "default",
      { "link": isLink },
      { "clickable": isClickable },
      { "hasIcon": iconElement },
      { "hasCloseIcon": showCloseIcon },
      { "rounded": rounded },
      className
    ] );


    if( isLink ){
      return (
        <a className={elementClassName} href={href} onClick={onClick}>
          {icon}
          {contents}
          {closeIcon}
        </a>
      );
    }
    else {
      return (
        <div className={elementClassName} onClick={onClick}>
          {icon}
          {contents}
          {closeIcon}
        </div>
      );
    }
  }
}

Chip.propTypes = {
  children: PropTypes.any.isRequired,
  chipType: PropTypes.oneOf( Object.values( TYPES ) ),
  className: PropTypes.string,
  closeLabel: PropTypes.string,
  href: PropTypes.string,
  iconElement: PropTypes.any,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  rounded: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
};
Chip.defaultProps = {
  chipType: "default",
  className: null,
  closeLabel: "Remove",
  href: null,
  iconElement: null,
  onClick: null,
  onClose: null,
  rounded: false,
  showCloseIcon: false,
};

export default Chip;
