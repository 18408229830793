import angular from 'angular';
import ngModule from '../../../ng/index.js';
import _filter from 'lodash/filter';
import toastService from '@services/toast.service.js';
import { pickShallowChangedProperties, omitFieldFromObject } from '../../../helpers/utilities.ts';
import { createErrorHandler } from '../../../state/helpers.js';
import { formatAddress } from '@equipmentshare/admin-hooks';
import { IS_USING_STAGING_API } from '@const/env';
import moment from 'moment';

window.mdSelectOnKeyDownOverride = function (event) {
	event.stopPropagation();
};

function DeliveryEditFormCtrl(
	$scope,
	$q,
	esCanonicalizer,
	esDeliveriesService,
	esAdminRentalService,
	esApiFactoryV2,
	esRentalActivityService,
	esRentalOverdueTasksService
) {
	const controller = this;

	$scope.init = () => {
		const deliveryFacilitatorTypes = {
			/** When the delivery is completed by an employee */
			IN_HOUSE: { delivery_facilitator_type_id: 1, name: 'In House' },
			/** When the delivery is contracted out to the delivery vendor */
			OUTSOURCED: { delivery_facilitator_type_id: 2, name: 'Third Party' },
			/** When a customer is responsible for the delivery */
			CUSTOMER: { delivery_facilitator_type_id: 3, name: 'Customer' },
		};
		$scope.deliveryFacilitatorTypes = deliveryFacilitatorTypes;
		$scope.rentalStatuses = esAdminRentalService.rentalStatuses;

		if (!$scope.delivery) {
			return;
		}

		$scope.error = undefined;
		$scope.creatingLocation = undefined;
		$scope.delivery = esCanonicalizer.canonicalize('delivery', $scope.delivery);

		const isInitialDropOff = $scope.delivery._type === esDeliveriesService.deliveryTypes.INITIAL_DROPOFF;
		const isFinalReturn = $scope.delivery._type === esDeliveriesService.deliveryTypes.FINAL_RETURN;
		const isSwapReturn = $scope.delivery._type === esDeliveriesService.deliveryTypes.SWAP_RETURN;

		$scope.deliveryStatuses =
			isInitialDropOff || isFinalReturn || isSwapReturn
				? esDeliveriesService.deliveryStatuses.filter(
						(status) => status.delivery_status_id !== esDeliveriesService.deliveryStatusIds.CANCELLED
				  )
				: esDeliveriesService.deliveryStatuses;

		controller.loadOrder();

		$scope.deliveryToEdit = controller.copyDeliveryToEdit($scope.delivery);
		if (!$scope.deliveryToEdit.equipment_id) {
			$scope.equipmentSearchText = '';
		}
		$scope.customerNotes = $scope.rental?.off_rent_note?.content;
		$scope.isSaveDisabled = true;
	};

	// Can't use object equality watch because angular can't handle circular dependencies in objects:
	// so we have to hard-code which properties on the delivery to trigger updates on.
	$scope.$watch(
		() => {
			return $scope.delivery
				? `${$scope.delivery.delivery_id}$${$scope.delivery.scheduled_date}$${$scope.delivery.asset_id}`
				: null;
		},
		() => {
			if (!$scope.delivery) {
				return;
			}
			if (!$scope.deliveryToEdit || $scope.delivery.delivery_id !== $scope.deliveryToEdit.delivery_id) {
				$scope.init();
			} else {
				// Just refresh our view model if it's the same delivery that's changed
				$scope.deliveryToEdit = controller.copyDeliveryToEdit($scope.delivery);
				$scope.isSaveDisabled = $scope.shouldDisableSave();
			}
		}
	);

	$scope.$watch('deliveryToEdit.purchaseOrder', () => {
		if ($scope.deliveryToEdit.purchaseOrder) {
			$scope.deliveryToEdit.purchase_order_id = $scope.deliveryToEdit.purchaseOrder.purchase_order_id;
			$scope.deliveryToEdit.purchase_order_number = $scope.deliveryToEdit.purchaseOrder.purchase_order_number;
		} else {
			$scope.deliveryToEdit.purchase_order_id = null;
			$scope.deliveryToEdit.purchase_order_number = null;
		}
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.delivery_status_id', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.charge', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.contact_name', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.contact_phone_number', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.scheduled_date', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.note', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.facilitator_type_id', () => {
		if (
			$scope.deliveryToEdit.facilitator_type_id ===
			$scope.deliveryFacilitatorTypes.IN_HOUSE.delivery_facilitator_type_id
		) {
			$scope.restrictDriverToCompanyId = $scope.deliveryToEdit.order.supplier_company_id;
		} else {
			$scope.restrictDriverToCompanyId = undefined;
		}

		// Clear purchase order if not third party
		if (
			$scope.deliveryToEdit.facilitator_type_id !==
			$scope.deliveryFacilitatorTypes.OUTSOURCED.delivery_facilitator_type_id
		) {
			$scope.purchaseOrder = null;
			$scope.deliveryToEdit.purchase_order_id = null;
			$scope.deliveryToEdit.purchase_order_number = null;
		}

		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.driver_user_id', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.run_name', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.$watch('deliveryToEdit.location_id', () => {
		$scope.isSaveDisabled = $scope.shouldDisableSave();
	});

	$scope.shouldDisableSave = () => {
		return Object.keys(controller.omitUnusedFieldsFromDeliveryToEdit()).length === 0;
	};

	$scope.saveDelivery = (form) => {
		if (form.$invalid || $scope.saving) {
			return;
		}

		if (
			$scope.deliveryToEdit.facilitator_type_id ===
				$scope.deliveryFacilitatorTypes.OUTSOURCED.delivery_facilitator_type_id &&
			!$scope.deliveryToEdit.purchase_order_id
		) {
			toastService.showError('A purchase order is required for third party deliveries.');
			return;
		}

		$scope.saving = true;

		const deliveryPayload = controller.omitUnusedFieldsFromDeliveryToEdit();

		return esDeliveriesService
			.updateDelivery($scope.delivery.delivery_id, deliveryPayload)
			.then(controller.saveCompleted)
			.catch(controller.handleAPIError)
			.then(() => ($scope.saving = false));
	};

	$scope.machineChange = (newEquipment) => {
		$scope.deliveryToEdit.rental.equipment = newEquipment;
	};

	$scope.setLocationFromOrder = (deliveryToCreate) => {
		if ($scope.delivery.rental.current_location) {
			deliveryToCreate.location_id = $scope.delivery.rental.current_location.location_id;
		}
	};

	$scope.toggleCreateLocation = (bool) => {
		$scope.creatingLocation = bool;
	};

	$scope.setLocation = (location) => {
		$scope.toggleCreateLocation(false);
		$scope.deliveryToEdit.location_id = location.location_id;
		$scope.deliveryToEdit.location = location;
	};

	$scope.setNewLocation = (location) => {
		$scope.toggleCreateLocation(false);
		esApiFactoryV2
			.postCompanyLocation($scope.orderUserCompanyId, location)
			.then((createdLocation) => {
				$scope.setLocation(createdLocation);
				controller.loadLocations();
			})
			.catch(controller.handleAPIError);
	};

	$scope.fetchExistingGeofences = (lat, lng) => {
		return esApiFactoryV2
			.getCompanyGeofences($scope.orderUserCompanyId, { latitude: lat, longitude: lng })
			.then((geofences) => _filter(geofences, (g) => g.location));
	};

	$scope.formatLocation = (location) => {
		return location ? formatAddress(location) : 'N/A';
	};

	$scope.isFinalReturn = () => {
		return $scope.delivery.delivery_id === $scope.delivery.rental.return_delivery_id;
	};

	$scope.isPODelivery = () => {
		return (
			$scope.deliveryToEdit.facilitator_type_id ===
			$scope.deliveryFacilitatorTypes.OUTSOURCED.delivery_facilitator_type_id
		);
	};

	$scope.isProd = () => {
		return !IS_USING_STAGING_API;
	};

	$scope.deliveryId = () => {
		return $scope.delivery.delivery_id;
	};

	$scope.locationFilter = (location) => {
		const value = `${location.street_1} - ${location.city}, ${location.state.name} ${location.zip || ''}`;

		if (!$scope.searchValue) {
			// show full list if user has not entered a search value
			return true;
		}

		if (value.toLowerCase().indexOf($scope.searchValue.toLowerCase()) !== -1) {
			return true;
		} else {
			return false;
		}
	};

	controller.loadLocations = () => {
		if ($scope.orderUserCompanyId) {
			const esLocations = esApiFactoryV2.adminGetLocations({ location_type: 1, query: '' }).then((esLocations) => {
				$scope.esLocations = esLocations;
			});
			const customerLocations = esApiFactoryV2.getCompanyLocations($scope.orderUserCompanyId).then((locations) => {
				$scope.customerLocations = locations;
			});
			return $q.all(esLocations, customerLocations);
		}
	};

	controller.saveCompleted = (savedDelivery) => {
		esRentalActivityService.refreshActivitiesForRental(savedDelivery.rental_id);
		esRentalOverdueTasksService.refresh($scope.delivery.order.market_id);

		toastService.showSuccess('Changes saved.');
		if ($scope.onSave) {
			$scope.onSave($scope.delivery);
		}
	};

	controller.handleAPIError = createErrorHandler(
		'An unexpected error occurred. If the problem does not go away, please contact engineering to have it resolved.'
	);

	controller.copyDeliveryToEdit = (delivery) => {
		const copy = angular.copy(delivery);

		copy.driver = delivery.driver && delivery.driver.user_id ? delivery.driver : undefined;
		copy.location_id = copy.location ? copy.location.location_id : copy.location_id;
		copy.purchaseOrder = delivery.purchase_order_id
			? {
					purchase_order_id: copy.purchase_order_id,
					purchase_order_number: copy.purchase_order_number,
			  }
			: null;

		return copy;
	};

	controller.loadOrder = () => {
		if (!$scope.delivery.order) {
			return;
		}

		if ($scope.delivery.order.user != null) {
			$scope.orderUserId = $scope.delivery.order.user.user_id;
			$scope.orderUserCompanyId = $scope.delivery.order.user.company.company_id;
			controller.loadLocations();
		} else {
			esApiFactoryV2
				.adminGetOrder($scope.delivery.order.order_id)
				.then((order) => {
					$scope.order = order;
					$scope.orderUserId = $scope.order.user.user_id;
					$scope.orderUserCompanyId = $scope.order.user.company.company_id;
					controller.loadLocations();
				})
				.catch(controller.handleAPIError);
		}
	};

	controller.omitUnusedFieldsFromDeliveryToEdit = () => {
		const deliveryWithChangedValues = pickShallowChangedProperties($scope.delivery, $scope.deliveryToEdit);
		const deliveryWithScheduledDateChecked = moment(deliveryWithChangedValues.scheduled_date).isSame(
			$scope.delivery.scheduled_date
		)
			? omitFieldFromObject(deliveryWithChangedValues, ['scheduled_date'])
			: deliveryWithChangedValues;

		return omitFieldFromObject(deliveryWithScheduledDateChecked, [
			'completed_by_user',
			'delivery_details',
			'delivery_photos',
			'delivery_status',
			'destination',
			'facilitator_type',
			'order',
			'origin',
			'location',
			'rental',
			'driver',
			'purchaseOrder',
		]);
	};

	// TODO: Remove this call if not needed by Dispatch delivery edit form
	$scope.init();
}

ngModule.controller('DeliveryEditFormCtrl', DeliveryEditFormCtrl);
