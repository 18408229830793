import { useQuery } from 'react-query';

import { ENTITY_TYPES, cacheKey, CACHE_TIMES } from '@equipmentshare/admin-hooks';
import { useSimpleApiClient } from '@equipmentshare/simple-json-api-client';

export default function useEquipmentClassLoader(query, { xFields, ...opts } = {}) {
	const client = useSimpleApiClient();

	return useQuery(
		cacheKey(ENTITY_TYPES.EQUIPMENT_CLASS, 'useEquipmentClassLoader', null, xFields),
		() => client.get('/equipment-classes', { query, headers: { 'x-Fields': xFields } }),
		{
			staleTime: CACHE_TIMES.LONG,
			cacheTime: CACHE_TIMES.LONG,
			...opts,
		}
	);
}
