import ngModule from '../ng/index.js';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.credit-notes', {
			url: '/credit-notes',
			redirectTo: 'home.transactions.credit-notes',
		})
		.state('home.credit-notes.create', {
			url: '/create',
			redirectTo: 'home.transactions.credit-notes.create',
		})
		.state('home.credit-notes.create.from-invoice', {
			url: '/from-invoice?invoiceId&memo',
			redirectTo: 'home.transactions.credit-notes.create.from-invoice',
		})
		.state('home.credit-notes.create.standalone', {
			url: '/standalone?companyId',
			redirectTo: 'home.transactions.credit-notes.create.standalone',
		})
		.state('home.credit-notes.credit-note', {
			url: '/:creditNoteId',
			redirectTo: 'home.transactions.credit-notes.credit-note',
		})
		.state('home.credit-notes.credit-note.allocate', {
			url: '/allocate',
			redirectTo: 'home.transactions.credit-notes.credit-note.allocate',
		});
});
