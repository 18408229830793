import React from 'react';
import PropTypes from 'prop-types';
import rmMarkdown from 'remove-markdown';

import { MarkdownFormatNoLinks } from '@equipmentshare/es-admin-eui-simple-components';

import Cell from '../cell/cell.js';

import styles from './markdown-cell.module.css';

export const MarkdownCell = ({ className, source, ...props }) => (
	<Cell className={className} title={rmMarkdown(source)} {...props}>
		<MarkdownFormatNoLinks className={styles.markdown} textSize="relative">
			{source}
		</MarkdownFormatNoLinks>
	</Cell>
);

MarkdownCell.propTypes = {
	source: PropTypes.string,
	className: PropTypes.string,
	columnId: PropTypes.string,
};

export default MarkdownCell;
