import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';
import Header from './header';

import { useFilterDispatch } from './providers/filter-provider';

import styles from './layout-view.module.css';

export const LayoutView = ({ history, path }) => {
	const { dispatcher } = useFilterDispatch();
	const isCompany = path.includes('rates.company');
	const isLocation = path.includes('rates.location');
	const isBranch = path.includes('rates.branch');

	const sideDrawerIsOpen = path === 'home.rates.company.add-rate' || path === 'home.rates.location.add-rate';

	const pathArray = path.split('.');

	const pathMeta = {
		drawerType: sideDrawerIsOpen ? pathArray.pop() : null,
		rateType: pathArray[2],
	};

	useEffect(() => dispatcher.updateRateType(pathMeta.rateType), []);

	const renderViewWithSuspense = (View) => (
		<Suspense fallback={<LoadingOverlay />}>
			<View />
		</Suspense>
	);

	const getView = () => {
		if (isCompany) {
			return renderViewWithSuspense(React.lazy(() => import('./company')));
		} else if (isLocation) {
			return renderViewWithSuspense(React.lazy(() => import('./location')));
		} else if (isBranch) {
			return renderViewWithSuspense(React.lazy(() => import('./branch')));
		}
		return null;
	};

	return (
		<div className={styles.layout}>
			<Header
				disableModifierButtons={isBranch}
				path={path}
				drawerOpen={sideDrawerIsOpen}
				onDrawerClose={() => history.push('^')}
			/>
			{getView()}
		</div>
	);
};

LayoutView.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	path: PropTypes.string.isRequired,
};

export default LayoutView;
