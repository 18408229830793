import { equipmentClassXFields } from './xFields';

const filterGroupOptionDefaults = {
	branchOption: { label: 'Branch', value: 'branch', disabled: false },
	companyOption: { label: 'Company', value: 'company', disabled: false },
	equipmentClassOption: { label: 'Equipment Class', value: 'equipmentClass', disabled: false },
	locationOption: { label: 'Location', value: 'location', disabled: false },
};

export const filterGroupOptions = {
	branch: [filterGroupOptionDefaults.branchOption, filterGroupOptionDefaults.equipmentClassOption],
	company: [filterGroupOptionDefaults.companyOption, filterGroupOptionDefaults.equipmentClassOption],
	location: [
		filterGroupOptionDefaults.companyOption,
		filterGroupOptionDefaults.equipmentClassOption,
		filterGroupOptionDefaults.locationOption,
	],
};

export const filterDefaults = {
	defaults: {
		required: false,
	},
	branch: {
		clearable: false,
		multi: true,
		placeholder: 'Select Branch',
		simpleValue: false,
	},
	company: {
		clearable: false,
		multi: true,
		placeholder: 'Select Customer',
		simpleValue: false,
	},
	equipmentClass: {
		clearable: false,
		multi: true,
		placeholder: 'Select Equipment Classes',
		simpleValue: false,
		xFields: equipmentClassXFields,
	},
	location: {
		clearable: false,
		multi: true,
		placeholder: 'Select Location',
		simpleValue: false,
	},
};

export const filterTypeIds = {
	branch: {
		key: 'id',
		multi: true,
		queryParam: 'branch_id',
	},
	company: {
		key: 'company_id',
		multi: true,
		queryParam: 'company_id',
	},
	equipmentClass: {
		key: 'equipment_class_id',
		multi: true,
		queryParam: 'equipment_class_id',
	},
	location: {
		key: 'location_id',
		multi: true,
		queryParam: 'location_id',
	},
};
