import React, { cloneElement, Children, Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Step from "./components/step.js";

import { iconList } from "../icon/icon-config.js";

import styles from "./steps.module.css";

export default class Steps extends Component {
  getSizeClassName( size ) {
    switch( size ) {
      case "small":
        return styles.stepsSmall;
      case "large":
        return styles.stepsLarge;
    }
  }

  getDirectionClassName( direction ) {
    return direction === "horizontal"
      ? styles.stepsHorizontal
      : styles.stepsVertical;
  }

  getLabelDirectionClassName( labelPlacement ) {
    return labelPlacement === "horizontal"
      ? styles.stepsLabelHorizontal
      : styles.stepsLabelVertical;
  }

  render() {
    let {
      style = {}, className, children, direction,
      labelPlacement, status, size, current, initial,
      icons
    } = this.props;
    let filteredChildren = React.Children.toArray( children ).filter( ( c ) => !!c );
    let classString = classNames(
      styles.steps,
      this.getDirectionClassName( direction ),
      this.getLabelDirectionClassName( labelPlacement ),
      this.getSizeClassName( size ),
      className );

    return (
      <div className={classString} style={style}>
        {
          Children.map( filteredChildren, ( child, index ) => {
            if ( !child ) {
              return null;
            }

            let stepNumber = initial + index;
            let childProps = {
              ...child.props,
              stepNumber: stepNumber + 1,
              wrapperStyle: style || child.props.style,
              icons: child.props.icons || icons,
            };

            if ( status === "error" && index === current - 1 ) {
              childProps.className = styles.stepsNextError;
            }

            if ( !child.props.status ) {
              if ( stepNumber === current ) {
                childProps.status = status;
              } else if ( stepNumber < current ) {
                childProps.status = "finish";
              } else {
                childProps.status = "wait";
              }
            }

            return cloneElement( child, childProps );
          } )
        }
      </div>
    );
  }
}

Steps.Step = Step;

Steps.propTypes = {
  /** The children to be rendered inside the Steps container */
  children: PropTypes.any.isRequired,
  /** A classname that is applied to the outer container of the steps component */
  className: PropTypes.string,
  /** The current step number */
  current: PropTypes.number,
  /** Specify the direction of the steps */
  direction: PropTypes.oneOf( [ "horizontal", "vertical" ] ),
  /** Override icons that can be used if passed in for the finish and error icons */
  icons: PropTypes.shape( {
    finish: PropTypes.oneOf( Object.keys( iconList ) ),
    error: PropTypes.oneOf( Object.keys( iconList ) ),
  } ),
  /** The initial step number */
  initial: PropTypes.number,
  /** Direction of the label placements */
  labelPlacement: PropTypes.oneOf( [ "horizontal", "vertical" ] ),
  /** The size of the Steps component as a string */
  size: PropTypes.oneOf( [ "normal", "large", "small" ] ),
  /** The default status used in child steps */
  status: PropTypes.oneOf( [ "wait", "process", "error", "finish" ] ),
  /** Additional styles that can get directly applied to the outer container */
  style: PropTypes.object,
};

Steps.defaultProps = {
  className: undefined,
  current: 0,
  direction: "horizontal",
  icons: undefined,
  initial: 0,
  labelPlacement: "horizontal",
  size: "normal",
  status: "process",
  style: {},
};
