import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import tableStyles from "./es-virtualized-table.module.css";
import {
  SORT_DIRECTION_NONE,
  SORT_DIRECTION_ASCENDING,
  SORT_DIRECTION_DESCENDING
} from "./constants";
import SortIndicator from "./components/sort-indicator/sort-indicator";

export default class ESColumnHeader extends PureComponent {
  constructor( props ) {
    super( props );

    this.state = {
      filterValue: props.filterValue,
    };

    this.handleFilterChange = this.handleFilterChange.bind( this );
    this.handleSortChange = this.handleSortChange.bind( this );
    this.filterInputTimer = undefined;
  }

  handleFilterChange( event ) {
    if ( this.props.filterable ) {
      let filterValue = event.target.value;

      this.setState( { filterValue } );
      
      if( this.props.onFilterChange ){
        clearTimeout( this.filterInputTimer );
        if( filterValue ){
          this.filterInputTimer = setTimeout( () => {
            this.props.onFilterChange( filterValue );
          }, 500 );
        }
      }
      this.props.handleFilterChange( this.props.columnKey, filterValue );
    }
  }

  handleSortChange() {
    if ( this.props.sortable ) {
      const newSortDirection = ( this.props.sortDirection === SORT_DIRECTION_ASCENDING ) ? SORT_DIRECTION_DESCENDING : SORT_DIRECTION_ASCENDING;
      
      if ( this.props.onSortClick ) {
        this.props.onSortClick( newSortDirection );
      }
      this.props.handleSortChange( this.props.columnKey, newSortDirection );
    }
  }

  renderFilter() {
    if ( this.props.filterable ) {
      return (
        <input
          onChange={this.handleFilterChange}
          placeholder={`Filter ${this.props.title}`}
          type="text"
          value={this.state.filterValue}
        />
      );
    }

    return null;
  }

  renderHeadingTitle() {
    const { sortDirection } = this.props;

    return (
      <div
        className={tableStyles.columnTitle}
        onClick={this.handleSortChange}
        title={this.props.title}
      >
        <div className={tableStyles.title}>{this.props.title}</div>
        {this.props.sortable && (
          <SortIndicator direction={sortDirection} />
        )}
      </div>
    );
  }

  render() {
    let { customHeader } = this.props;
    let className = classNames( {
      [tableStyles.activeSort]: this.props.sortDirection !== SORT_DIRECTION_NONE,
      [tableStyles.sortable]: this.props.sortable,
    } );

    let content = customHeader ? (
      customHeader()
    ) : (
      <div className={className}>
        {this.renderHeadingTitle()}
        {this.renderFilter()}
      </div>
    );

    return content;
  }
}

ESColumnHeader.defaultProps = {
  customHeader: null,
  filterValue: "",
  filterable: true,
  onFilterChange: undefined,
  onSortClick: undefined,
  sortDirection: SORT_DIRECTION_NONE,
  sortable: true,
  title: "",
};

ESColumnHeader.propTypes = {
  columnKey: PropTypes.string.isRequired,
  customHeader: PropTypes.func,
  filterValue: PropTypes.string,
  filterable: PropTypes.bool,
  handleFilterChange: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  onSortClick: PropTypes.func,
  sortDirection: PropTypes.string,
  sortable: PropTypes.bool,
  title: PropTypes.string,
};
