import map from 'lodash/fp/map';

angular.module('esApiClient').factory('esMarketService', function ($q, esApiFactoryV2, esCanonicalizer) {
  let cache, canonicalizer = esCanonicalizer.canonicalizer('market');

  function getMarkets() {
    if(cache) {
      return $q.resolve(cache);
    }
    else {
      return esApiFactoryV2.getMarkets()
        .then(map(canonicalizer))
    }
  }

  function clearCache(){
    cache = undefined;
    return true;
  }

  return {
    clearCache: clearCache,
    getMarkets,
    getMarket: canonicalizer
  }
});
