import angular from 'angular';

const ERROR_MESSAGE = 'esToast.showError was removed from ng-components in 6.0.0, please use react-ui-components toastService';

angular.module("esNgComponents").service('esToast', function ($mdToast) {
  function showError( message ) {
    throw new Error(ERROR_MESSAGE);
  }

  function showSuccess( message, options = {} ) {
    throw new Error(ERROR_MESSAGE);
  }

  function showSimple( message, buttonTxt = "Got it" ) {
    var toast = $mdToast.simple()
      .content( message )
      .action( buttonTxt )
      .position( "top center" )
      .hideDelay( 50000 );

    $mdToast.show( toast ).then( ( response ) => {
      if ( response == "ok" ) {
        $mdToast.hide();
      }
    } );
  }

  /** Useful for plugging into .catch() on promises */
  function errorHandler( defaultMessage="An unexpected error occurred. Please contact engineering." ) {
    return (err) => {
      let message = err && err.message ? err.message :
        err && err.data && err.data.message ? err.data.message : defaultMessage;
      service.showError(message);
    }
  }

  let service = {
    showError,
    showSuccess,
    showSimple,
    errorHandler
  };

  return service;
});
