import { useFlags } from '@equipmentshare/feature-flags';
import useLocationSearchQuery from '@hooks/use-location-search-query';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type QueryParams = {
	s_flag?: boolean;
	sessionid?: string | null;
	company_id?: string | null;
};

const getStateFromParams = (params: QueryParams = {}) => {
	const sFlag = params.s_flag;
	const sessionid = params.sessionid;
	const companyId = params.company_id;

	return { sFlag, sessionid, companyId };
};

export const RedirectFromSlimcdPayment = () => {
	const { enableBillingPayFromAdminCompanyTransactionsInvoiceView: canPayFromInvoiceView } = useFlags();
	const history = useHistory();
	const queryParams = useLocationSearchQuery();

	const { sessionid, sFlag, companyId } = getStateFromParams(queryParams);

	useEffect(() => {
		if (canPayFromInvoiceView && companyId && sFlag && sessionid) {
			history.push({
				pathname: `/home/companies/${companyId}/transactions/invoices`,
				search: `?s_flag=${sFlag}&sessionid=${sessionid}`,
			});
		} else {
			history.push('/home/companies/search');
		}
	}, [companyId, sFlag, sessionid]);

	return <></>;
};
