import { useIdentity, useAuth0 } from '@equipmentshare/auth0-react';

export const useIdentityWithProviderCheck = () => {
	const { isLoading: loadingAuth } = useAuth0();

	if (loadingAuth) {
		return null;
	}

	return useIdentity();
};

export default useIdentityWithProviderCheck;
