import _sortBy from "lodash/sortBy";
import _uniq from "lodash/uniq";

const GREY_THRESHOLD = 0.1;

class Color {
  constructor( hexVal ) {
    this.updateColor( hexVal );
  }

  updateColor( hexVal ) {
    this.hex = hexVal;

    this._setRGB();
    this._setLuminance();
    this._setHue();
  }

  _setRGB() {
    let hex = this.hex.substring( 1 );

    this.red = parseInt( hex.substring( 0, 2 ), 16 ) / 255;
    this.green = parseInt( hex.substring( 2, 4 ), 16 ) / 255;
    this.blue = parseInt( hex.substring( 4, 6 ), 16 ) / 255;

    this.isGrey = (
      ( Math.abs( this.red - this.blue ) < GREY_THRESHOLD ) &&
      ( Math.abs( this.red - this.green ) < GREY_THRESHOLD )
    );
  }

  _setLuminance() {
    this.luminance = Math.sqrt( 0.241 * this.red + 0.691 * this.green + 0.068 * this.blue );
  }

  _setHue() {
    let hue = 0;
    let max = Math.max( this.red, this.green, this.blue );
    let min = Math.min( this.red, this.green, this.blue );
    let chroma = max - min;

    if ( this.green === max ) {
      hue = 120 + 60 * ( ( ( this.blue - min ) - ( this.red - min ) ) / chroma );
    }
    if ( this.blue === max ) {
      hue = 240 + 60 * ( ( ( this.red - min ) - ( this.green - min ) ) / chroma );
    }
    if ( this.red === max ) {
      hue = 360 + 60 * ( ( ( this.green - min ) - ( this.blue - min ) ) / chroma );
    }

    this.hue = ( hue + 180 ) % 360;
  }
}

export function sortColors( hexValues ) {
  let uniqHexValues = _uniq( hexValues );
  let colors = uniqHexValues.map( ( hexVal ) => new Color( hexVal ) );
  let greys = colors.filter( ( { isGrey } ) => ( isGrey ) );
  let nonGreys = colors.filter( ( { isGrey } ) => ( !isGrey ) );

  return [
    ..._sortBy( greys, "luminance" ),
    ..._sortBy( nonGreys, "hue" ),
  ];
}
