import ngModule from '../../../../ng/index.js';
import _filter from 'lodash/filter';

function CompanyLocationPickerCtrl($scope, esApiFactoryV2, $timeout, $q) {
	const controller = this;

	controller.init = () => {
		$scope.companyLocations = [];
		controller.loadLocations();
	};

	controller.locationChanged = function () {
		if ($scope.ngChange) {
			$timeout(() => {
				$scope.ngChange($scope.ngModel);
			});
		}
	};

	controller.setLocation = (loc) => {
		$scope.ngModel = loc;
		controller.toggleLocationForm(false);
	};

	controller.toggleLocationForm = (toggle) => {
		$scope.newLocationForm = toggle;
	};

	controller.queryLocations = function (searchText) {
		const found = _filter($scope.companyLocations, (l) => {
			return (
				(l.nickname && l.nickname.toLowerCase().includes(searchText.toLowerCase())) ||
				(l.street_1 && l.street_1.toLowerCase().includes(searchText.toLowerCase())) ||
				(l.city && l.city.toLowerCase().includes(searchText.toLowerCase()))
			);
		});

		return $q.when(found);
	};
	controller.loadLocations = () => {
		esApiFactoryV2.getCompanyLocations($scope.company.company_id).then((locations) => {
			$scope.companyLocations = locations.reverse();
		});
	};
	$scope.handleLocationCreated = (location) => {
		$scope.ngModel = location;
		$scope.newLocationForm = false;
		controller.loadLocations();
		$scope.$digest();
	};
	$scope.handleDrawerClose = () => {
		$scope.newLocationForm = false;
		$scope.$digest();
	};
	$scope.handleSelectExistingGeofence = (geofence) => {
		$scope.ngModel = geofence.location;
		$scope.newLocationForm = false;
		$scope.$digest();
	};

	controller.init();
}

ngModule.controller('CompanyLocationPickerCtrl', CompanyLocationPickerCtrl);
