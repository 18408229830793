import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";

import ModalDialog from "./modal-dialog";

import modalCreatorStyles from "./modal-creator.module.css";

class ModalCreator extends PureComponent {
  constructor( props ) {
    super( props );

    this.handleModalClose = this.handleModalClose.bind( this );
  }

  UNSAFE_componentWillMount() {
    this.modalContainer = document.createElement( "div" );
    this.modalContainer.className = modalCreatorStyles.container;

    if( this.props.isOpen ){
      document.body.appendChild( this.modalContainer );
    }
  }

  UNSAFE_componentWillReceiveProps( nextProps ){
    if( this.props.isOpen !== nextProps.isOpen ){
      if( nextProps.isOpen ){
        document.body.appendChild( this.modalContainer );
      }
      else {
        this.modalContainer.remove();
      }
    }
  }

  componentWillUnmount() {
    if ( this.isModalContainerInDOM() ) {
      this.modalContainer.remove();
    }
  }

  handleModalClose() {
    if ( this.isModalContainerInDOM() ) {
      const { handleClose } = this.props;

      if ( handleClose ) {
        handleClose();
      }
    }
  }

  isModalContainerInDOM() {
    return !!this.modalContainer.parentNode;
  }

  render() {
    const {
      children,
      containerClass,
      contentPadding,
      customStyles,
      isOpen,
      outerContainerClass,
      renderHeader,
      shouldCloseOnOverlayClick,
      showCornerCloseButton,
      title,
    } = this.props;

    if( isOpen ){
      return (
        <Portal node={this.modalContainer}>
          <ModalDialog
            containerClass={containerClass}
            contentPadding={contentPadding}
            customStyles={customStyles}
            handleClose={this.handleModalClose}
            outerContainerClass={outerContainerClass}
            renderHeader={renderHeader}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            showCornerCloseButton={showCornerCloseButton}
            title={title}
          >
            {children}
          </ModalDialog>
        </Portal>
      );
    }

    return null;
  }
}

ModalCreator.defaultProps = {
  containerClass: "",
  contentPadding: true,
  handleClose: null,
  isOpen: false,
  customStyles: {},
  outerContainerClass: "",
  renderHeader: true,
  shouldCloseOnOverlayClick: true,
  showCornerCloseButton: true,
  title: ""
};

ModalCreator.propTypes = {
  children: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ] ).isRequired,
  /** CSS class to be added to final dialog container.  Useful for adding additional styles to your final modal dialog. */
  containerClass: PropTypes.string,
  /** modal body content padding */
  contentPadding: PropTypes.bool,
  customStyles: PropTypes.object,
  /** handle close should give the consumer control over the isOpen state */
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  /** used for targeting the entire modal to customize with css */
  outerContainerClass: PropTypes.string,
  /** should we render the header for the modal */
  renderHeader: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  /** display the corner close button on the top right */
  showCornerCloseButton: PropTypes.bool,
  /** modal title */
  title: PropTypes.string
};

export default ModalCreator;
