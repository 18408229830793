if ( !String.prototype.endsWith ) {
  String.prototype.endsWith = function( searchString, position ) {
    let subjectString = this.toString();
    if ( typeof position !== "number" || !isFinite( position ) || Math.floor( position ) !== position || position > subjectString.length ) {
      position = subjectString.length;
    }
    position -= searchString.length;
    let lastIndex = subjectString.indexOf( searchString, position );
    return lastIndex !== -1 && lastIndex === position;
  };
}

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}
