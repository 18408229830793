import _pick from 'lodash/pick';
import map from 'lodash/fp/map';

angular.module('esApiClient').factory('esAdminOrderService', function (esApiFactoryV2, esCanonicalizer) {
  const canonicalize = esCanonicalizer.canonicalizer('order');

  function getOrders(config) {
    return esApiFactoryV2.adminGetOrders(config)
      .then(map(canonicalize));
  }

  function getOrder(orderId) {
    return esApiFactoryV2.adminGetOrder(orderId)
      .then(canonicalize);
  }

  function postOrder(order) {
    return esApiFactoryV2.adminPostOrder(order);
  }

  function patchOrder(order) {
    const patchOrder = _pick(order, [
      'company_id', 'branch_id', 'location_id', 'purchase_order_id', 'salesperson_user_id',
      'user_id', 'order_status_id', 'accepted_date', 'accepted_by', 'secondary_salesperson_user_ids'
    ]);

    return esApiFactoryV2.adminPatchOrder(order.order_id, patchOrder);
  }

  return {
    getOrders,
    getOrder,
    postOrder,
    patchOrder
  }
});
