import ngModule from '../../../ng/index.js';
import template from './compact-delivery.html';

ngModule
	.component('compactDelivery', {
		template,
		controller: 'CompactDeliveryCtrl',
		bindings: {
			delivery: '<',
			ngClick: '&',
			ngMouseover: '&',
			ngMouseleave: '&',
		},
		transclude: true,
	})
	.controller('CompactDeliveryCtrl', function CompactDeliveryCtrl($scope, esDeliveriesService) {
		const ctrl = this;

		$scope.getShortDeliveryType = esDeliveriesService.getShortDeliveryType;
		$scope.getInitialDropoffStyle = esDeliveriesService.getInitialDropoffStyle;

		ctrl.$onChanges = () => {
			$scope.delivery = ctrl.delivery;
		};

		$scope.ngClick = () => ctrl.ngClick({ delivery: ctrl.delivery });
		$scope.ngMouseover = () => ctrl.ngMouseover({ delivery: ctrl.delivery });
		$scope.ngMouseleave = () => ctrl.ngMouseleave({ delivery: ctrl.delivery });
	});
