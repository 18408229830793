export var esToast = esToast || {};

var id = 0;
var defaults = {
  timeout: 4000,
  closeOnClick: true,
};

export var listeners = new Set();

export function subscribe( fn ) {
  listeners.add( fn );
}
export function unsubscribe( fn ) {
  listeners.delete( fn );
}

export function startToasting() {}

export function showToast( type, message, options = defaults ) {
  let toast = {
    closeOnClick: options.closeOnClick,
    id,
    message,
    timeout: options.timeout,
    type,
  };

  listeners.forEach( ( fn ) => fn( toast ) );

  id++;
}

export function showSuccess( message = "Success!", options ) {
  showToast( "success", message, options );
}

export function showError( message = "Error!", options ) {
  showToast( "error", message, options );
}

export function showInfo( message = "Info!", options ) {
  showToast( "info", message, options );
}
