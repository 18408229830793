import React, { Suspense } from 'react';
import { react2angular } from 'react2angular';
import { Route, Switch, Redirect } from 'react-router-dom';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

import withGlobalProviders from '@components/with-global-providers';
import ngModule from '../../ng/index.js';

const CreateView = React.lazy(() => import('./order-create-view/order-create-view'));
const OrderRoutes = React.lazy(() => import('./details-view/routes'));

const Routes = () => (
	<Suspense fallback={<LoadingOverlay />}>
		<Switch>
			<Route path="/home/orders/create">
				<CreateView />
			</Route>
			<Route path="/home/orders/:orderId">
				<OrderRoutes />
			</Route>
			<Redirect from="/home/orders" to="/home/rentals" />
		</Switch>
	</Suspense>
);

ngModule.component('orderRoutes', react2angular(withGlobalProviders(Routes)));

export default Routes;
