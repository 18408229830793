import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import { determineBrakeStatus } from "./helpers.js";
import AssetDiagnosticCodesIcon from "./icons/asset-diagnostic-codes-icon.js";
import AssetKeypadIcon from "./icons/asset-keypad-icon.js";
import AssetUnpluggedIcon from "./icons/asset-unplugged-icon.js";
import AssetBrakeStatusIcon from "./icons/asset-brake-status-icon.js";
import BleLowBatteryIcon from "./icons/ble-low-battery-icon.js";

import assetStatusIcons from "./asset-status-icons.module.css";
import AssetTrackerIcon from "./icons/asset-tracker-icon.js";

export const AssetStatusIcons = ( {
  asset,
  assetStatus,
  extraStyles,
  markerColor,
  statusCode,
  tooltipDirection,
  unpluggedTimestamp,
} ) => {
  assetStatus = assetStatus || asset.asset_status;

  let brakeStatus = determineBrakeStatus( assetStatus );

  let bleLowBattery = assetStatus && assetStatus.ble_low_battery;
  let hasKeypad = !!asset.keypad || !!( asset.equipment && asset.equipment.keypad );
  let brakeStatusIcon = brakeStatus ? ( <AssetBrakeStatusIcon statusCode={brakeStatus} /> ) : null;
  let engineCodesIcon = asset.diagnostic_codes && asset.diagnostic_codes.length > 0 ? (
    <AssetDiagnosticCodesIcon asset={asset} tooltipDirection={tooltipDirection} />
  ) : null;
  let unpluggedIcon = assetStatus && assetStatus.unplugged ? (
    <AssetUnpluggedIcon tooltipDirection={tooltipDirection} unpluggedTimestamp={unpluggedTimestamp} />
  ) : null;

  let classNames = classnames( assetStatusIcons.icons, extraStyles );

  return (
    <div className={classNames}>
      {brakeStatusIcon && (
        <div className={classnames( assetStatusIcons.icon, "brake-status-icon" )}>
          {brakeStatusIcon}
        </div>
      )}
      {unpluggedIcon || engineCodesIcon && (
        <div className={classnames( assetStatusIcons.icon, "engine-icon" )}>
          {unpluggedIcon || engineCodesIcon}
        </div>
      )}
      {bleLowBattery && (
        <div className={classnames( assetStatusIcons.icon, "low-battery-icon" )}>
          <BleLowBatteryIcon tooltipDirection={tooltipDirection} />
        </div>
      )}
      {hasKeypad && (
        <div className={classnames( assetStatusIcons.icon, "keypad-icon" )}>
          <AssetKeypadIcon tooltipDirection={tooltipDirection} />
        </div>
      )}
      
      <div className={classnames( assetStatusIcons.icon, "tracker-icon" )}>
        <AssetTrackerIcon
          assetStatus={assetStatus}
          markerColor={markerColor}
          statusCode={statusCode}
          tooltipDirection={tooltipDirection}
        />
      </div>
    </div>
  );
};

AssetStatusIcons.defaultProps = {
  assetStatus: null,
  extraStyles: null,
  markerColor: undefined,
  tooltipDirection: "right",
  unpluggedTimestamp: "",
};

AssetStatusIcons.propTypes = {
  asset: PropTypes.shape( {
    diagnostic_codes: PropTypes.array,
    keypad: PropTypes.any,
    asset_status: PropTypes.shape( {
      unplugged: PropTypes.bool
    } )
  } ).isRequired,
  assetStatus: PropTypes.object,
  extraStyles: PropTypes.string,
  markerColor: PropTypes.string,
  statusCode: PropTypes.string.isRequired,
  tooltipDirection: PropTypes.string,
  unpluggedTimestamp: PropTypes.string,
};

export default AssetStatusIcons;
