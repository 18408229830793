import angular from 'angular';

angular.module("esNgComponents").controller('TableHeaderColumnCtrl', function ($scope) {
  let controller = this;

  $scope.isSortedByProperty = () => {
    if(controller.sort){
      return controller.property === controller.sort.orderBy;
    }
    else return controller.property === controller.orderBy;
  };

  $scope.isReverse = () => {
    if(controller.sort){
      return controller.sort.reverse;
    }
    else return controller.reverse;
  };

  $scope.onClick = () => {
    let sortData = controller.sort || controller;

    if(controller.property){
      sortData.reverse = $scope.isSortedByProperty() ? !$scope.isReverse() : !!controller.initReverse;
      sortData.orderBy = controller.property;

      controller.onChangeSort({
        orderBy: sortData.orderBy,
        reverse: sortData.reverse
      })
    }
  };
});