import angular from 'angular';

angular.module("esNgComponents").controller('TakeoverCardCtrl', function ($scope, $timeout, takeoverCard, $log) {
  $log.info("TakeoverCardCtrl");

  $scope.init = function () {
    $log.info("TakeoverCardCtrl > init()");
    $log.debug("$scope.takeoverCardId", $scope.takeoverCardId);
    $scope.takeoverCard = takeoverCard.registerNewCard($scope.takeoverCardId);
    $log.debug("$scope.takeoverCard", $scope.takeoverCard);
  };

  $scope.closeTakeoverCard = function () {
    $log.info("closeTakeoverCard()");
    takeoverCard.close($scope.takeoverCardId);

    if(typeof $scope.onClose === 'function'){
      $scope.onClose();
    }
  };

  $scope.toggleExpanded = () => {
    if($scope.expandable){
      $scope.takeoverCard.toggle();
    }
  };

  $scope.init();
});
