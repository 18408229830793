import ngModule from '../../../ng/index.js';
import template from './delivery-table-row.html';

ngModule.component('dispatchTableRow', {
	template,
	controller($scope, esDeliveriesService) {
		$scope.entry = this.entry;

		$scope.getShortDeliveryType = esDeliveriesService.getShortDeliveryType;
		$scope.getInitialDropoffStyle = esDeliveriesService.getInitialDropoffStyle;
	},
	bindings: {
		entry: '=',
	},
});
