import esApi from './es-api.service';

export async function fetchAll() {
	const states = await esApi.request('/states');

	return states;
}

const statesService = {
	fetchAll,
};

export default statesService;
