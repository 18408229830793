import React from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withAutoSizer(autoSizerProps = {}) {
	return (WrappedComponent) => {
		const WithAutoSizer = (props) => {
			return (
				<AutoSizer {...autoSizerProps}>
					{({ width, height }) => <WrappedComponent width={width} height={height} {...props} />}
				</AutoSizer>
			);
		};

		WithAutoSizer.displayName = `WithAutoSizer(${getDisplayName(WrappedComponent)})`;
		/* https://reacttraining.com/react-router/web/api/withRouter/componentwrappedcomponent */
		WithAutoSizer.WrappedComponent = WrappedComponent;

		WithAutoSizer.propTypes = {};
		WithAutoSizer.defaultProps = {};

		return WithAutoSizer;
	};
}
