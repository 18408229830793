import 'angular';
import 'angular-ui-mask';
import 'angular-route';
import 'angular-ui-router';
import 'angular-aria';
import 'angular-messages';
import 'angular-material';
import 'angular-resizable';
import 'sc-date-time';
import 'angular-simple-logger';
import 'angular-credit-cards';

// import the css
// import "angular-material/angular-material.min.css";
import '../scss/_fixed-angular-material.scss';
import 'angular-resizable/angular-resizable.min.css';
import 'sc-date-time/sc-date-time.css';

import '@equipmentshare/ng-components/dist/styles.css';

// import the sass
import '../scss/main-mgmt-app.scss';
import '../scss/main.scss';

// TODO: remove once es-track uses can load images
// override default loaders and tell webpack to move these images over to the /images folder
// these images are used for the AssetStatusIcons component in @equipmentshare/react-ui-components
import '!file-loader?name=images/[name].[ext]!../images/keypad-icon.svg';
import '!file-loader?name=images/[name].[ext]!../images/asset-unplugged.svg';

import ngModule from './ng-module.js';

import './helpers/ng-http-request-interceptor';

export default ngModule;
