import angular from 'angular';
import Chart from 'chart.js';

angular.module("esNgComponents").controller('LineChartCtrl', function() {
  let controller = this;

  controller.$onInit = refresh;
  controller.$onChanges = refresh;

  function refresh() {
      let ctx = document.getElementById('lineChartCanvas');
      new Chart(ctx, {
        type: 'line',
        data: controller.data
      });
  }
});
