import ngModule from '../../ng/ng-module.js';
import { react2angular } from 'react2angular';
import CompanyLocationPicker from './company-location-picker.js';
import withGlobalProviders from '@components/with-global-providers';

ngModule.component(
	'reactCompanyLocationPicker',
	react2angular(
		withGlobalProviders(CompanyLocationPicker),
		Object.keys(CompanyLocationPicker.propTypes).concat('forceUpdate')
	)
);
