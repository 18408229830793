import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import styled from 'styled-components';

import { CompanyPicker } from '@equipmentshare/es-admin-components';
import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';
import { EuiButton, EuiFormRow, EuiCheckbox, EuiText, EuiTitle } from '@equipmentshare/ds2';

import assetOwnerService from '@services/asset-owner.service';
import companiesService from '@services/companies.service';
import toastService from '@services/toast.service.js';

import { VEHICLE_TYPE_ID } from '../asset-edit-form/assetTypeFields';

const FormLayout = styled.form`
	padding: 10px 15px;
`;

const FlexEndContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const OwnerEditForm = ({ asset, refresh }) => {
	const [company, setCompany] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [removeFromFleet, setRemoveFromFleet] = useState(false);

	const setNewCompany = async (id) => {
		if (id) {
			const companyDetails = await companiesService.fetch(id);
			setCompany(companyDetails);
		} else {
			setCompany({});
		}
	};
	const toggleRemoveFromFleet = () => {
		setRemoveFromFleet(!removeFromFleet);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsLoading(true);
		try {
			const updateModel = {
				company_id: company.company_id,
				remove_from_rental_fleet: removeFromFleet,
			};
			if (asset.asset_type_id !== VEHICLE_TYPE_ID) {
				updateModel.location_id = company.billing_location.location_id;
			}
			await assetOwnerService.update(asset.asset_id, updateModel);
			toastService.showSuccess(`Asset #${asset.asset_id} successfully updated`);
			refresh();
		} catch (error) {
			if (error.status === 204) {
				toastService.showSuccess(`Asset #${asset.asset_id} successfully updated`);
				refresh();
			} else {
				const errorResponse = await error.json();
				const message = _get(errorResponse, 'message', 'Unable to update Owner');
				toastService.showError(message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const renderBillingLocation = useCallback(() => {
		if (company.billing_location) {
			const street = _get(company, 'billing_location.street_1', 'None');
			const city = _get(company, 'billing_location.city', 'None');
			const state = _get(company, 'billing_location.state.name', 'None');
			return `${street} - ${city}, ${state}`;
		}
		return 'None';
	}, [company]);

	return (
		<FormLayout action="#" onSubmit={handleSubmit}>
			{isLoading && <LoadingOverlay />}
			<EuiFormRow fullWidth>
				<EuiTitle size="s">
					<h2>Change Ownership</h2>
				</EuiTitle>
			</EuiFormRow>
			<EuiFormRow label="New Owner" fullWidth>
				<CompanyPicker
					onChange={setNewCompany}
					selectedCompanyId={company.company_id}
					placeholder="Start typing a company name"
					isRequired
					fullWidth
				/>
			</EuiFormRow>
			<EuiFormRow fullWidth>
				<EuiCheckbox
					id="remove_from_fleet"
					label="Remove from Rental Fleet"
					checked={removeFromFleet}
					onChange={toggleRemoveFromFleet}
				/>
			</EuiFormRow>
			<EuiFormRow label="Billing Location" fullWidth>
				<EuiText>{renderBillingLocation()}</EuiText>
			</EuiFormRow>
			<EuiFormRow fullWidth>
				<FlexEndContainer>
					<EuiButton fill type="submit" isDisabled={!company.billing_location}>
						Update Owner
					</EuiButton>
				</FlexEndContainer>
			</EuiFormRow>
		</FormLayout>
	);
};

OwnerEditForm.propTypes = {
	asset: PropTypes.object,
	refresh: PropTypes.func,
};

export default OwnerEditForm;
