import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EuiToolTip } from '@equipmentshare/ds2';

import styles from './filter-display-button.module.css';

const FilterDisplayButton = ({ className, onClick, tooltipText = 'Filter Selector' }) => {
	return (
		<EuiToolTip position="right" content={tooltipText}>
			<div className={classNames(className, styles.button)} onClick={onClick}>
				<div className={styles.iconBar}></div>
				<div className={styles.iconBar}></div>
				<div className={styles.iconBar}></div>
			</div>
		</EuiToolTip>
	);
};

FilterDisplayButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	tooltipText: PropTypes.string,
};

export default FilterDisplayButton;
