import ngModule from '../../../ng/index.js';
import template from './simple-map.html';
import './simple-map-ctrl.js';

ngModule.component('simpleMap', {
	template,
	controller: 'SimpleMapCtrl',
	bindings: {
		mapTemplate: '<',
		center: '<',
		points: '<',
		point: '<',
		onMapCreated: '&',
		onMapUpdated: '&',
	},
});
