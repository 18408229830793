import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Tab, { TabPropTypes } from "./tab.js";
import TabMenuItem from "./tab-menu-item.js";

import tabsStyles from "./tabs.module.css";

let lastTabId = 0;

const KEY_ARROW_LEFT = 37;
const KEY_ARROW_UP = 38;
const KEY_ARROW_RIGHT = 39;
const KEY_ARROW_DOWN = 40;

export default class Tabs extends PureComponent {
  constructor( ...args ){
    super( ...args );

    this.state = {
      selected: this.props.defaultTab
    };

    this.handleTabMenuItemClick = this.handleTabMenuItemClick.bind( this );
    this.handleKeyDown = this.handleKeyDown.bind( this );
  }

  getChildContext(){
    return {
      orientation: this.props.orientation,
      tabsHandleClick: this.handleTabMenuItemClick,
      tabsHandleKeyDown: this.handleKeyDown
    };
  }

  UNSAFE_componentWillReceiveProps( nextProps ){
    if( nextProps.selected !== this.props.selected ){
      let currentTab = this.getTabId( this.getSelected() );
      let nextTab = this.getTabId( nextProps.selected );

      if( nextTab !== currentTab ){
        this.setSelected( nextProps.selected );
      }
    }
  }

  setSelected( newTab, focus = false ){
    let oldTabIndex = this.getTabIndex( this.getSelected() );
    let newTabIndex = this.getTabIndex( newTab );

    if( this.isTabDisabled( newTab ) || newTabIndex === oldTabIndex ){
      return;
    }

    this.setState( {
      selected: newTab,
      focus
    }, () => {
      if( this.props.onChange ){
        this.props.onChange( this.getTabId( newTabIndex ), this.getTabId( oldTabIndex ), newTabIndex, oldTabIndex );
      }
    } );
  }

  getNextTab( index ) {
    const count = this.tabs.length;

    // Look for non-disabled tab from index to the last tab on the right
    for ( let i = index + 1; i < count; i++ ) {
      if ( !this.isTabDisabled( i ) ) {
        return i;
      }
    }

    // If no tab found, continue searching from first on left to index
    for ( let i = 0; i < index; i++ ) {
      if ( !this.isTabDisabled( i ) ) {
        return i;
      }
    }

    return index;
  }

  getPrevTab( index ) {
    let i = index;

    // Look for non-disabled tab from index to first tab on the left
    while ( i-- ) {
      if ( !this.isTabDisabled( i ) ) {
        return i;
      }
    }

    // If no tab found, continue searching from last tab on right to index
    i = this.tabs.length;
    while ( i-- > index ) {
      if ( !this.isTabDisabled( i ) ) {
        return i;
      }
    }

    return index;
  }

  handleKeyDown( event ){
    let currentTabIndex = this.getTabIndex( this.getSelected() );
    let newTabIndex = currentTabIndex;
    let preventDefault = false;

    if ( event.keyCode === KEY_ARROW_LEFT || event.keyCode === KEY_ARROW_UP ) {
      newTabIndex = this.getPrevTab( newTabIndex );
      preventDefault = true;
    }
    else if ( event.keyCode === KEY_ARROW_RIGHT || event.keyCode === KEY_ARROW_DOWN ) {
      newTabIndex = this.getNextTab( newTabIndex );
      preventDefault = true;
    }

    // This prevents scrollbars from moving around
    if ( preventDefault ) {
      event.preventDefault();
    }

    if( this.props.onSelect && this.props.onSelect( this.getTabId( newTabIndex ), this.getTabId( currentTabIndex ), newTabIndex, currentTabIndex ) === false ){
      return;
    }

    if( newTabIndex !== currentTabIndex && this.props.selected === null ){
      this.setSelected( newTabIndex, true );
    }
  }

  handleTabMenuItemClick( newTab ){
    let currentTabIndex = this.getTabIndex( this.getSelected() );
    let newTabIndex = this.getTabIndex( newTab );

    if( newTabIndex !== currentTabIndex ){
      if( this.props.onSelect && this.props.onSelect( this.getTabId( newTabIndex ), this.getTabId( currentTabIndex ), newTabIndex, currentTabIndex ) === false ){
        return;
      }

      if( this.props.selected === null ){
        this.setSelected( newTab );
      }
    }
  }

  createTabIds(){
    this.tabIds = this.tabIds || [];
    let diff = this.tabIds.length - this.tabs.length;

    while ( diff++ < 0 ) {
      let id = lastTabId++;

      this.tabIds.push( `tab-${id}` );
    }
  }

  getSelected(){
    if( this.props.selected !== null ){
      return this.props.selected;
    }
    if( this.state && this.state.selected !== null ){
      return this.state.selected;
    }

    return this.props.defaultTab;
  }

  getTab( tabId ){
    return this.tabs.find( ( tab, i ) => this.getTabId( i ) === tabId || i === tabId || tab === tabId );
  }

  isTabDisabled( tabId ){
    let tab = this.getTab( tabId );

    if( tab ){
      return tab.props.disabled;
    }

    return false;
  }

  getTabId( index ){
    if( this.tabs[index] && this.tabs[index].props.id ){
      return this.tabs[index].props.id;
    }
    else {
      return this.tabIds[index];
    }
  }

  getTabIndex( tabId ){
    return this.tabs.indexOf( this.getTab( tabId ) );
  }

  renderTabMenu(){
    let orientationClassName = {
      [tabsStyles.verticalList]: this.props.orientation === "vertical",
      [tabsStyles.horizontalList]: this.props.orientation === "horizontal",
      [tabsStyles.divider]: this.props.withDivider
    };
    let classes = classNames( tabsStyles.list, orientationClassName );

    return (
      <ul className={classes} role="tablist">
        {
          this.tabs.map( ( tab, i ) => (
            <TabMenuItem
              disabled={tab.props.disabled}
              focus={this.state.focus}
              id={this.getTabId( i )}
              key={this.getTabId( i )}
              selected={this.getSelectedTab() === tab}
            >
              {tab.props.title}
            </TabMenuItem>
          ) )
        }
      </ul>
    );
  }

  getSelectedTab(){
    const selected = this.getSelected();

    return this.tabs
      .find( ( _, tabIndex ) => selected === this.getTabId( tabIndex ) || selected === tabIndex );
  }

  render() {
    let { orientation, responsive, className, children } = this.props;

    this.tabs = children.filter( Boolean );
    this.createTabIds();

    let tabsClasses = classNames( tabsStyles.tabs, className, {
      [tabsStyles.responsiveVerticalTabs]: responsive && orientation === "vertical",
      [tabsStyles.responsiveHorizontalTabs]: responsive && orientation === "horizontal",
    } );

    return (
      <div className={tabsClasses}>
        {this.renderTabMenu()}
        {this.getSelectedTab()}
      </div>
    );
  }
}

Tabs.Panel = Tab;
Tabs.childContextTypes = {
  orientation: PropTypes.string,
  tabsHandleKeyDown: PropTypes.func,
  tabsHandleClick: PropTypes.func
};

Tabs.propTypes = {
  /** accepts an array of `Tab`-like elements */
  children: PropTypes.arrayOf(
    PropTypes.oneOfType( [
      PropTypes.bool,
      PropTypes.shape( TabPropTypes )
    ] )
  ),
  /** custom class */
  className: PropTypes.string,
  /** the initial tab to show */
  defaultTab: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string
  ] ),
  /** called when the tabs change */
  onChange: PropTypes.func,
  /** called before the tabs are changed, if `false` is returned it will cancel the change */
  onSelect: PropTypes.func,
  /** "vertical" or "horizontal" (default) show the tabs above the content or to the left of the content */
  orientation: PropTypes.oneOf( [ "horizontal", "vertical" ] ),
  /** forces the tabs to fill up the entire space available */
  responsive: PropTypes.bool,
  /** the currently selected tab */
  selected: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string
  ] ),
  withDivider: PropTypes.bool,
};

Tabs.defaultProps = {
  children: null,
  className: null,
  defaultTab: 0,
  selected: null,
  onSelect: null,
  onChange: null,
  orientation: "horizontal",
  responsive: false,
  withDivider: false,
};
