import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useQuery } from 'react-query';

import { Tabs, AssetStatusIcons } from '@equipmentshare/react-ui-components';
import { ENTITY_TYPES, cacheKey } from '@equipmentshare/admin-hooks';

import AssetStatusTab from '@components/asset-info-drawer/asset-status-tab';
import EquipmentAssignmentsTab from '@components/asset-info-drawer/equipment-assignments-tab';

import assetsService from '@services/assets.service';
import imageService from '@services/image.service';
import assetEquipmentAssignmentsService from '@services/asset-equipment-assignments.service';

import styles from './asset-info-drawer.module.css';
import AssetEditForm from '@components/asset-edit-form/asset-edit-form';
import OwnerEditForm from '@components/owner-edit-form/owner-edit-form';
import PayoutProgramForm from '@components/payout-program-form/payout-program-form';
import { CenteredLoadingSpinner } from '@components/loading-spinner/loading-spinner';
import { getAssetStatusCode } from './asset-status';
import AssetTransferTab from './asset-transfer-tab/AssetTransferTab';
import { useFlags } from '@equipmentshare/feature-flags';

import xFields from './xFields';

import { userInFeatureGroups, EDIT_ASSET_OWNERSHIP_PAYOUT } from '@helpers/admin-feature-permissions';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '@services/graphql/asset-transfer-api/asset-transfer-apollo-client';
const AssetInfoDrawer = ({ assetId }) => {
	const [isFleetOperations, setIsFleetOperations] = useState(false);
	const {
		data: asset,
		isSuccess: assetLoaded,
		refetch: refetchAsset,
	} = useQuery(cacheKey(ENTITY_TYPES.ASSET, 'AssetInfoDrawer', { assetId }), () => assetsService.fetchAsset(assetId), {
		enabled: !!assetId,
	});

	const { data: equipmentAssignments, isSuccess: equipmentAssignmentsLoaded } = useQuery(
		cacheKey(ENTITY_TYPES.EQUIPMENT_ASSIGNMENT, 'AssetInfoDrawer', { assetId }),
		() =>
			assetEquipmentAssignmentsService.fetch(assetId, { headers: { 'X-Fields': xFields.assetEquipmentAssignments } }),
		{ enabled: !!assetId }
	);

	const {
		data: assetStatus,
		isSuccess: assetStatusLoaded,
		refetch: refetchAssetStatus,
	} = useQuery(
		cacheKey(ENTITY_TYPES.ASSET_STATUS, 'AssetInfoDrawer', { assetId }),
		() => assetsService.getAssetStatus(assetId),
		{ enabled: !!assetId }
	);

	const refetchData = useCallback(() => {
		refetchAsset();
		refetchAssetStatus();
	}, [refetchAsset, refetchAssetStatus]);

	const renderAssetStatusTab = useCallback(() => {
		if (!assetStatusLoaded || !assetLoaded || !asset) {
			return <CenteredLoadingSpinner size="xxl" />;
		}
		asset.asset_status = { ...assetStatus };
		return <AssetStatusTab className={styles.assetInfo} asset={asset} />;
	}, [asset, assetStatus, assetStatusLoaded, assetLoaded]);

	const renderBackgroundImage = useCallback(() => {
		if (!assetLoaded || !asset) {
			return <CenteredLoadingSpinner size="xxl" />;
		}
		const imageURL = imageService.fetchURL(asset);
		if (imageURL) {
			return <div className={styles.assetThumbnail} style={{ backgroundImage: `url(${imageURL})` }}></div>;
		}
	}, [asset, assetLoaded]);

	const getMakeModel = useCallback(() => {
		const make =
			_get(asset, 'make.name') ||
			_get(asset, 'equipment_model.make') ||
			_get(asset, 'equipment_model.equipment_make.name');
		const model = _get(asset, 'equipment_model.name') || _get(asset, 'model');

		var makeModel = '';
		if (make) {
			makeModel = make;
			if (model) {
				makeModel = makeModel + ' ' + model;
			}
		} else if (model) {
			makeModel = model;
		}

		return makeModel;
	}, [asset]);

	const renderAssetInfo = useCallback(() => {
		if (!asset || !assetStatus) {
			return '';
		}
		asset.asset_status = assetStatus;
		return (
			<div className={styles.assetInfo}>
				<h3 className={styles.assetTitle}>
					{getMakeModel()}
					<AssetStatusIcons extraStyles={styles.statusIcons} asset={asset} statusCode={getAssetStatusCode(asset)} />
				</h3>
				<p className={styles.assetSubHeader}>
					{asset.custom_name} &middot; {asset.serial_number}
				</p>
			</div>
		);
	}, [asset, assetStatus, getMakeModel]);

	const { enableAssetTransferTab } = useFlags();

	const renderAssetEditForm = useCallback(() => {
		if (!assetLoaded || !asset) {
			return <CenteredLoadingSpinner size="xxl" />;
		}
		return (
			<AssetEditForm
				userIsFleetOperations={isFleetOperations}
				asset={{ ...asset }}
				refresh={refetchData}
				editRentalService={!enableAssetTransferTab}
			/>
		);
	}, [asset, assetLoaded, refetchData, isFleetOperations]);

	const renderTransferTab = useCallback(() => {
		if (!assetLoaded || !asset) {
			return <CenteredLoadingSpinner size="xxl" />;
		}
		return (
			<ApolloProvider client={apolloClient}>
				<AssetTransferTab
					assetId={asset.asset_id}
					userIsFleetOperations={isFleetOperations}
					rentalBranch={asset.rental_branch}
					serviceBranch={asset.service_branch}
					inventoryBranch={asset.inventory_branch}
					companyId={asset.company.company_id}
					refresh={refetchData}
				/>
			</ApolloProvider>
		);
	}, [asset, assetLoaded, refetchData, isFleetOperations]);

	const renderOwnerEditForm = useCallback(() => {
		if (!assetLoaded || !asset) {
			return <CenteredLoadingSpinner size="xxl" />;
		}
		return <OwnerEditForm asset={asset} refresh={refetchData} />;
	}, [asset, assetLoaded, refetchData]);

	const renderPayoutProgramForm = useCallback(() => {
		if (!assetLoaded || !asset) {
			return <CenteredLoadingSpinner size="xxl" />;
		}
		return <PayoutProgramForm assetId={assetId} refresh={refetchData} />;
	}, [asset, assetLoaded, assetId, refetchData]);

	async function renderOwnerEditAndPayoutFormsCheck() {
		try {
			const renderForms = await userInFeatureGroups(EDIT_ASSET_OWNERSHIP_PAYOUT);
			setIsFleetOperations(renderForms);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		renderOwnerEditAndPayoutFormsCheck();
	}, []);

	return (
		<div className={styles.container}>
			{renderBackgroundImage()}
			{renderAssetInfo()}
			<Tabs withDivider>
				<Tabs.Panel className={styles.container} title="Status">
					{renderAssetStatusTab()}
				</Tabs.Panel>
				<Tabs.Panel className={styles.container} title="Edit">
					{renderAssetEditForm()}
					{isFleetOperations && (
						<div>
							<hr className={styles.sectionLine} />
							{renderOwnerEditForm()}
							<hr className={styles.sectionLine} />
							{renderPayoutProgramForm()}
						</div>
					)}
				</Tabs.Panel>
				<Tabs.Panel className={styles.container} title="History">
					<EquipmentAssignmentsTab assignments={equipmentAssignments || []} isLoading={!equipmentAssignmentsLoaded} />
				</Tabs.Panel>
				{enableAssetTransferTab && (
					<Tabs.Panel className={styles.container} title="Transfer">
						{renderTransferTab()}
					</Tabs.Panel>
				)}
			</Tabs>
		</div>
	);
};

AssetInfoDrawer.propTypes = {
	assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AssetInfoDrawer;
