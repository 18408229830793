import { useEffect, useState, useCallback, useMemo } from 'react';

export function useAsyncLoader(fetchData, watch = [], wait = false) {
	const [loaded, setLoaded] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [result, setResult] = useState(null);

	// use useCallback on dataFetcher so it only changes when "watch" or "wait" changes
	const fetcher = useMemo(() => fetchData, [...watch, wait]);

	const load = useCallback(async () => {
		setLoaded(false);
		setLoading(false);

		if (typeof fetcher === 'function' && !wait) {
			try {
				setLoading(true);
				setError(null);

				const r = await fetcher({ setLoading, setLoaded, setError, setResult });
				setResult(r);
				setLoaded(true);
			} catch (err) {
				setError(err);
				setResult(null);
			} finally {
				setLoading(false);
			}
		} else {
			setResult(null);
			setError(null);
		}
	}, [setError, setResult, setLoading, setLoaded, fetcher, wait]);

	// call the load method when it changes or when "watch" changes
	useEffect(() => {
		load();
	}, [load, ...watch]);

	// just call the load method again
	const refresh = load;

	return {
		result,
		setResult,
		isLoading,
		loaded,
		error,
		refresh,
	};
}

export default useAsyncLoader;
