import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import buttonGroupStyles from "./button-group.module.css";

export default function ButtonGroup( {
  children,
  className,
  collapsed,
  inline,
  inlineRight,
  responsive,
  right,
  topMargin
} ) {
  let style = topMargin ? { marginTop: topMargin } : {};

  let groupClassName = classNames(
    buttonGroupStyles.btnGroup,
    className,
    {
      [ buttonGroupStyles.collapsed ]: collapsed,
      [ buttonGroupStyles.inline ]: inline || inlineRight,
      [ buttonGroupStyles.responsive ]: responsive,
      [ buttonGroupStyles.right ]: inlineRight || right
    }
  );

  return (
    <div className={groupClassName} style={style}>
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  /** class name for overriding button group styles at implementation */
  className: PropTypes.string,
  /** removes padding between the buttons */
  collapsed: PropTypes.bool,
  /** displays the buttons inline instead of vertical */
  inline: PropTypes.bool,
  /** displays the buttons inline, right justified */
  inlineRight: PropTypes.bool,
  /** displays the buttons inline with responsive wrapping */
  responsive: PropTypes.bool,
  /** displays the buttons right justified */
  right: PropTypes.bool,
  /** takes a number+unit to add a top margin as an inline style */
  topMargin: PropTypes.string,
};

ButtonGroup.defaultProps = {
  className: null,
  collapsed: false,
  inline: false,
  inlineRight: false,
  responsive: false,
  right: false,
  topMargin: null,
};
