import angular from 'angular';
import _map from 'lodash/map';
import _isFunction from 'lodash/isFunction';

import template from './company-picker.html';

angular.module("esNgComponents")
  .directive('companyPicker', function () {
    return {
      template,
      controller: 'CompanyPickerCtrl as ctrl',
      scope: {
        ngModel: '=',
        selectedCompany: '=?',
        selectLabel: '@',
        name: '=?',
        required: '=?',
        pickerDisabled: '=?',
        filter: '<'
      }
    };
  })
  .controller('CompanyPickerCtrl', function ($scope, esCompanyService) {
    var controller = this;

    $scope.$watch('ngModel', function (newModel, oldModel) {
      controller.updateSelectedCompany();

      if (newModel === undefined || newModel === null) {
        $scope.selectedCompany = undefined;
        $scope.searchText = '';
      }
    });

    controller.updateSelectedCompany = () => {
      if($scope.ngModel){
        esCompanyService.getCompany($scope.ngModel)
          .then(company => {
            if($scope.ngModel === company.company_id){
              $scope.selectedCompany = company;
            }
          })
          .catch(err => $scope.selectedCompany = undefined);
      }
      else{
        $scope.selectedCompany = undefined;
      }
    };

    controller.querySearch = function (query) {
      return esCompanyService.searchCompanies(query)
        .then(companies => _isFunction($scope.filter) ? _map(companies, $scope.filter) : companies);
    };

    controller.selectCompany = function (company) {
      if (company) {
        $scope.ngModel = company.company_id;
      } else {
        $scope.ngModel = null;
      }
      controller.updateSelectedCompany();
    };
  });
