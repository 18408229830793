import ngModule from '../ng/index.js';

import template from '../js/layout/layout.html';
import '../js/layout';

ngModule.config(($stateProvider, $urlRouterProvider) => {
	$stateProvider.state('home', {
		url: '/home',
		template,
		controller: 'LayoutCtrl',
	});

	$urlRouterProvider.otherwise('/home/rentals');
});
