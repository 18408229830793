import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { EuiConfirmModal, EuiFormRow, EuiText } from '@elastic/eui';

import { BranchPicker } from '@equipmentshare/es-admin-components';
import { useQuery } from '@apollo/client';
import { isEnabledForTransferQuery } from '@components/asset-info-drawer/asset-transfer-tab/queries/is-enabled-for-transfer';
import CenteredLoadingSpinner from '@components/loading-spinner/loading-spinner';

interface TransferModalFormOwnProps {
	initialRentalBranchId: number;
	inventoryBranchId: number;
	companyId: number;
	setIsModalVisible: (arg0: boolean) => void;
	onTransferModalConfirm: (rentalBranchId: number, isBetaEnabled: boolean) => void;
}

const TransferModalForm: FunctionComponent<TransferModalFormOwnProps> = ({
	initialRentalBranchId,
	inventoryBranchId,
	companyId,
	setIsModalVisible,
	onTransferModalConfirm,
}) => {
	const [toBranchId, setToBranchId] = useState<number>(initialRentalBranchId);
	const fromBranchId = !!initialRentalBranchId ? initialRentalBranchId : inventoryBranchId;
	const { data: isEnabledForBetaData, loading: loadingBetaCheck } = useQuery(isEnabledForTransferQuery, {
		variables: { fromBranchId: initialRentalBranchId, toBranchId: toBranchId },
		skip: toBranchId < 1 || toBranchId === fromBranchId,
		fetchPolicy: 'network-only',
	});
	const isBetaEnabled = isEnabledForBetaData?.isEnabledForTransfer ?? false;

	return (
		<EuiConfirmModal
			title="Transfer Asset"
			onCancel={() => setIsModalVisible(false)}
			isLoading={loadingBetaCheck}
			onConfirm={() => {
				setIsModalVisible(false);
				onTransferModalConfirm(toBranchId, isBetaEnabled);
			}}
			confirmButtonDisabled={loadingBetaCheck}
			confirmButtonText="Send"
			cancelButtonText="Cancel"
			buttonColor="primary"
		>
			<EuiFormRow label="Transfer To" fullWidth>
				<BranchPicker
					selectedBranchId={toBranchId}
					selectedBranchCompanyId={companyId} // this will allow fetching the rental branch if the asset is outside of EquipmentShare (1854)
					placeholder="Select Branch"
					onChange={(value) => setToBranchId(value !== undefined ? value : -1)}
					isClearable={false}
					fullWidth
				/>
			</EuiFormRow>
			<EuiFormRow>
				{loadingBetaCheck ? (
					<CenteredLoadingSpinner size="xxl" />
				) : isBetaEnabled ? (
					<EuiText>
						By clicking send, a transfer request will be created and must be approved before the asset can be
						transferred.
					</EuiText>
				) : (
					<EuiText>
						Note: Transferring an asset implies that you or the new branch have coordinated delivery. Once you send,
						ownership is transferred instantly.
					</EuiText>
				)}
			</EuiFormRow>
		</EuiConfirmModal>
	);
};

export default TransferModalForm;
