import angular from 'angular';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';

angular.module("esNgComponents").service('takeoverCard', function ($log, $q) {
  $log.info("Service takeoverCard");
  var cards = [];
  return {
    open: function (id) {
      $log.info("open()", id);
      var card = getCard(id);
      if(card) {
        card.visible = true;
        card.promise = card.promise || $q.defer();
        return card.promise.promise;
      }
      return $q.reject("No such takeover card exists");
    },
    close: function (id) {
      $log.info("close()", id);
      $log.debug("getCard(id)", getCard(id));
      getCard(id).close()
    },
    closeAll: function () {
      _forEach( cards, function (card) {
        getCard(card.id).close();
      });
    },
    setCardElement: function (id, el, events = {}) {
      $log.info("setCardElement()", id, el);
      getCard(id).element = el;
    },
    registerNewCard: function (id, el) {
      $log.debug("registerNewCard()");
      cards.push({
        id: id,
        visible: false,
        expanded: false,
        element: el,
        promise: null,
        close: function () {
          $log.debug("takeoverCard.close()");
          this.visible = false;
          if(this.promise) {
            this.promise.resolve();
            this.promise = null;
          }
        },
        toggle: function () {
          this.expanded = !this.expanded;
        }
      });
      $log.debug("registered", cards);
      return getCard(id);
    }
  };
  function getCard(id) {
    return _find(cards, ['id', id]);
  }
});
