import React from "react";
import PropTypes from "prop-types";

import Button from "../../../button/button.js";
import ButtonDropDown from "../../../button-drop-down/button-drop-down.js";

export default function CsvExportButton( { exportAllCsv, exportFilteredCsv, disableFilteredExport } ) {
  const baseButtonProps = { buttonType: "primary", label: "Export" };

  if ( exportFilteredCsv ) {
    const actions = [
      { disabled: false, label: "Export all (.csv)", onClick: exportAllCsv },
      { disabled: disableFilteredExport, label: "Export modified (.csv)", onClick: exportFilteredCsv }
    ];

    return <ButtonDropDown {...baseButtonProps} actions={actions} isRightAligned primaryAction={exportAllCsv} />;
  } else {
    return <Button {...baseButtonProps} onClick={exportAllCsv} />;
  }
}

CsvExportButton.defaultProps = {
  disableFilteredExport: false,
  exportFilteredCsv: null,
};

CsvExportButton.propTypes = {
  disableFilteredExport: PropTypes.bool,
  exportAllCsv: PropTypes.func.isRequired,
  exportFilteredCsv: PropTypes.func,
};
