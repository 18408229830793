import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";
import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";

import Popover from "./popover";

const ID_PREFIX = "popover-container-";
let popoverIds = 0;

const KEYCODES = {
  ESCAPE: 27
};

export default class PopoverCreator extends PureComponent {
  constructor( ...args ){
    super( ...args );

    this.forceUpdateFailsafe = 0;
    this.state = {
      isOpen: false
    };

    this.refAnchor = this.refAnchor.bind( this );
    this.openPopover = this.openPopover.bind( this );
    this.closePopover = this.closePopover.bind( this );

    this.handleScroll = this.handleScroll.bind( this );
    this.handleKeydown = this.handleKeydown.bind( this );
    this.handleOutsideMouseClick = this.handleOutsideMouseClick.bind( this );
  }

  UNSAFE_componentWillMount(){
    this.portalNode = document.createElement( "div" );
    this.portalNode.id = ID_PREFIX + ( popoverIds++ );
    this.portalNode.classList.add( "popover-container" );
  }

  componentDidMount() {
    if ( this.props.closeOnEsc ) {
      document.addEventListener( "keydown", this.handleKeydown );
    }

    if ( this.props.closeOnOutsideClick ) {
      document.addEventListener( "click", this.handleOutsideMouseClick );
    }

    window && window.addEventListener( "scroll", this.handleScroll, true );
  }

  componentDidUpdate(){
    // dont re-render the component if the anchor dose not exist or if its gone over the failsafe
    if( !this.anchor || this.forceUpdateFailsafe++ >= 10 ){
      return;
    }

    let rect = this.anchor.getBoundingClientRect();
    let props = [ "x", "y", "width", "height" ];

    // re-render the component if the anchors position has changed
    let newPosition = _pick( rect, props );
    let oldPosition = _pick( this.lastRect, props );

    if( !_isEqual( newPosition, oldPosition ) ){
      this.forceUpdateFailsafe = 10;
      this.forceUpdate();
      this.forceUpdateFailsafe = 0;
    }

    this.lastRect = rect;
  }

  componentWillUnmount(){
    this.portalNode.remove();

    if ( this.props.closeOnEsc ) {
      document.removeEventListener( "keydown", this.handleKeydown );
    }
    if ( this.props.closeOnOutsideClick ) {
      document.removeEventListener( "click", this.handleOutsideMouseClick );
    }
    window && window.removeEventListener( "scroll", this.handleScroll );
  }

  refAnchor( el ){
    this.anchor = el;
  }

  handleOutsideMouseClick( e ) {
    if ( !this.state.isOpen ) {
      return;
    }
    const root = this.portalNode;

    if( !root ){
      return;
    }

    let popoverContainsElement = root.contains( e.target );
    let documentContainsElement = root.ownerDocument.contains( e.target );
    let wasPrimaryButton = e.button === 0;

    if ( !documentContainsElement || popoverContainsElement || !wasPrimaryButton ) {
      return;
    }
    this.closePopover();
  }

  handleKeydown( e ) {
    if ( e.keyCode === KEYCODES.ESCAPE && this.state.isOpen ) {
      this.closePopover();
    }
  }

  handleScroll(){
    if( !this.state.isOpen ){
      return;
    }

    if( this.props.closeOnScroll ){
      this.closePopover();
    }
    else{
      this.forceUpdate();
    }
  }

  openPopover( e ) {
    if ( this.state.isOpen ) {
      return;
    }
    if ( e && e.nativeEvent ) {
      e.nativeEvent.stopImmediatePropagation();
    }

    document.body.appendChild( this.portalNode );
    this.setState( { isOpen: true }, this.props.onOpen );
  }

  closePopover() {
    if ( !this.state.isOpen ) {
      return;
    }

    this.portalNode.remove();
    this.setState( { isOpen: false }, this.props.onClose );
  }

  render() {
    const { children, trigger, anchor, align, offset, popoverClassName } = this.props;

    let isOpen = this.state.isOpen;
    let triggerElement = trigger && trigger( {
      isOpen,
      openPopover: this.openPopover,
      closePopover: this.closePopover
    } );

    return (
      <span className="popover-anchor" ref={this.refAnchor}>
        {triggerElement}
        {isOpen &&
          <Portal node={this.portalNode}>
            <Popover align={align} anchor={anchor} anchorNode={this.anchor} className={popoverClassName} offset={offset}>
              {children}
            </Popover>
          </Portal>
        }
      </span>
    );
  }
}

PopoverCreator.propTypes = {
  /** the `align` prop passed to the Popover component */
  align: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.arrayOf( PropTypes.string )
  ] ),
  /** the anchor provided to the Popover component */
  anchor: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.func
  ] ),
  /** the popovers contents */
  children: PropTypes.any,
  /** close the popover when the Esc key is pressed */
  closeOnEsc: PropTypes.bool,
  /** close the popover when the user clicks outside of the popover */
  closeOnOutsideClick: PropTypes.bool,
  /** close the popover when the window fires the `scroll` event */
  closeOnScroll: PropTypes.bool,
  /** a CSS value or an object that will be used to offset the popover from its anchor */
  offset: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape( {
      top: PropTypes.any,
      left: PropTypes.any,
      right: PropTypes.any,
      bottom: PropTypes.any,
      vertical: PropTypes.any,
      horizontal: PropTypes.any,
    } )
  ] ),
  /** called when the popover closes */
  onClose: PropTypes.func,
  /** called when the popover opens */
  onOpen: PropTypes.func,
  /** custom popover class */
  popoverClassName: PropTypes.string,
  /** a react component that will be passed the functions to open / close the popover */
  trigger: PropTypes.func,
};
PopoverCreator.defaultProps = {
  align: undefined,
  anchor: undefined,
  children: null,
  closeOnEsc: true,
  closeOnOutsideClick: true,
  closeOnScroll: true,
  offset: undefined,
  onClose: null,
  onOpen: null,
  popoverClassName: "",
  trigger: null,
};
