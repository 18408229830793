import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EuiButton } from '@equipmentshare/ds2';
import { useHistory } from '@equipmentshare/react2angular-router';

const LinkButton = ({ to, params, title, replaceHistory = false, ...props }) => {
	const history = useHistory();

	const onClick = useCallback(() => {
		if (to && history) {
			if (replaceHistory) {
				history.replace(to, params, { location: 'replace' });
			} else {
				history.push(to, params);
			}
		}
	}, [to, params, history, replaceHistory]);

	return (
		<EuiButton onClick={onClick} {...props}>
			{title}
		</EuiButton>
	);
};

LinkButton.propTypes = {
	to: PropTypes.string,
	children: PropTypes.element,
	params: PropTypes.object,
	replaceHistory: PropTypes.bool,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	title: PropTypes.string.isRequired,
};

export default LinkButton;
