import ngModule from '../../../../ng/index.js';
import template from './equipment-picker.html';
import esApi from '@services/es-api.service';

function equipmentPicker() {
	return {
		template,
		controller: 'EquipmentPickerCtrl as ctrl',
		scope: {
			selectLabel: '@',
			searchText: '=?',
			ngModel: '=',
			machineChange: '=',
			ngDisabled: '<?',
			formStart: '=?',
			ngRequired: '=',
			ngBlur: '&',
			ngFocus: '&',
		},
	};
}

// it's named EquipmentPicker, but now it's an asset picker.
function EquipmentPickerCtrl($scope, $timeout) {
	const controller = this;

	controller.querySearch = (query) => {
		return esApi.request(esApi.url`/admin/assets?query=` + query);
	};

	controller.change = () => {
		if ($scope.machineChange) {
			$timeout(() => {
				$scope.machineChange($scope.ngModel);
			});
		}
	};
}

ngModule.directive('equipmentPicker', equipmentPicker).controller('EquipmentPickerCtrl', EquipmentPickerCtrl);
