import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { dateToISO } from '../rate.helpers';
import { RENTAL_RATE_TYPES } from '@const/company-rental-rates';
import { EuiSimpleDatePicker } from '@equipmentshare/es-admin-eui-simple-components';
import { EuiFormRow, EuiButton, EuiRadioGroup, EuiFieldNumber } from '@equipmentshare/ds2';
import { CompanyPicker, EquipmentClassPicker, Stack } from '@equipmentshare/es-admin-components';
import errorHandlerService from '@services/error-handler.service';
import toastService from '@services/toast.service';
import LocationPicker from '@components/location-picker';
import { useRatesProvider } from '../providers/rates-provider';
import { reducer } from './rate-drawer.reducer';

import styles from './rate-drawer.module.css';

const RATE_TYPE_FIELDS = [
	{ label: 'Custom Rates', id: String(RENTAL_RATE_TYPES.FIXED) },
	{ label: 'Discount Factor (%)', id: String(RENTAL_RATE_TYPES.PERCENT) },
];

const FIXED_RATE_FIELDS = [
	{
		id: 'hourlyRate',
		label: 'Hourly Rate',
	},
	{
		id: 'dailyRate',
		label: 'Daily Rate',
	},
	{
		id: 'weeklyRate',
		label: 'Weekly Rate',
	},
	{
		id: 'monthlyRate',
		label: '4-Week Rate',
	},
];

export const RateDrawer = ({ rateType, onClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [state, dispatch] = reducer();
	const { createRate } = useRatesProvider();

	const dispatcher = useCallback((name, value) => {
		dispatch({ type: 'UPDATE', name, value });
	}, []);

	const dispatchReset = () => dispatch({ type: 'RESET' });

	const createPayload = () => {
		let payload = {
			company_id: state.companyId,
			equipment_class_id: state.equipmentClassId,
			rental_rate_type_id: state.rateTypeId,
			end_date: dateToISO(state.expirationDate),
		};

		if (state.rateTypeId === RENTAL_RATE_TYPES.FIXED) {
			payload = {
				...payload,
				price_per_hour: state.hourlyRate,
				price_per_day: state.dailyRate,
				price_per_week: state.weeklyRate,
				price_per_month: state.monthlyRate,
				percent_discount: null,
			};
		} else {
			payload = {
				...payload,
				price_per_hour: null,
				price_per_day: null,
				price_per_week: null,
				price_per_month: null,
				percent_discount: state.rateFactor,
			};
		}

		if (rateType === 'location') {
			delete payload.company_id; // Saca ese maíz de mi cara
			payload = {
				...payload,
				location_id: state.locationId,
			};
		}

		return payload;
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		setIsLoading(true);
		const rateTypeLabel = RATE_TYPE_FIELDS.find((field) => field.id === String(state.rateTypeId)).label;

		try {
			const payload = createPayload();

			await createRate(payload);
			setIsLoading(false);
			dispatchReset();
			onClose();
			return toastService.showSuccess('New Rate successfully created');
		} catch (err) {
			return errorHandlerService.genericErrorHandler(err, `Unable to create ${rateTypeLabel}`);
		}
	};

	const handleCancel = () => {
		dispatchReset();
		onClose();
	};

	const handleSetCompany = (id) => dispatcher('companyId', id);

	const handleSetLocation = (id) => dispatcher('locationId', id);

	const handleSetEquipmentClass = (id) => dispatcher('equipmentClassId', id);

	const handleChangeAsDate = (dt) => dispatcher('expirationDate', moment(dt));

	const handleSetRateType = (id) => dispatcher('rateTypeId', parseFloat(id));

	const handleSetRateFactor = (evt) => dispatcher('rateFactor', parseFloat(evt.target.value));

	const handleSetFixedRate = (evt) => dispatcher(evt.target.name, parseFloat(evt.target.value));

	const handleFixedRateBlur = (evt) => dispatcher(evt.target.name, parseFloat(evt.target.value).toFixed(2));

	return (
		<form onSubmit={handleSubmit} className={styles.layout}>
			<div className={styles.pickers}>
				<EuiFormRow label="Company" fullWidth>
					<CompanyPicker
						isDisabled={isLoading}
						onChange={handleSetCompany}
						placeholder="Select Company"
						isRequired
						selectedCompanyId={state.companyId}
						fullWidth
					/>
				</EuiFormRow>
				{rateType === 'location' && (
					<LocationPicker
						className={styles.picker}
						companyId={state.companyId}
						disabled={!state.companyId || isLoading}
						label="Location"
						onChange={handleSetLocation}
						placeholder="Select Location"
						required
						selectedLocationId={state.locationId}
					/>
				)}
				<EuiFormRow label="Equipment Class" fullWidth>
					<EquipmentClassPicker
						className={styles.picker}
						isDisabled={isLoading}
						onChange={handleSetEquipmentClass}
						placeholder="Select Equipment Class"
						isRequired
						selectedEquipmentClassId={state.equipmentClassId}
						fullWidth
					/>
				</EuiFormRow>
			</div>

			<div className={styles.rateOptions}>
				<EuiFormRow label="Rate Type" fullWidth>
					<EuiRadioGroup
						className={styles.radioGroup}
						id="rental_type_id"
						onChange={handleSetRateType}
						options={RATE_TYPE_FIELDS}
						idSelected={String(state.rateTypeId)}
					/>
				</EuiFormRow>
			</div>

			<div className={styles.inputs}>
				{/* FIXED RATES */}
				{state.rateTypeId === RENTAL_RATE_TYPES.FIXED &&
					FIXED_RATE_FIELDS.map((rate) => {
						return (
							<EuiFormRow key={rate.id} label={rate.label} fullWidth>
								<EuiFieldNumber
									disabled={isLoading}
									id={rate.id}
									min={0}
									name={rate.id}
									onBlur={handleFixedRateBlur}
									onChange={handleSetFixedRate}
									placeholder={rate.label}
									required
									step={0.01}
									type="number"
									value={state[rate.id] || ''}
									fullWidth
									autoComplete="off"
								/>
							</EuiFormRow>
						);
					})}
				{/* RATE FACTOR */}
				{state.rateTypeId === RENTAL_RATE_TYPES.PERCENT && (
					<EuiFormRow key="rateFactor" label="Rate Factor" fullWidth>
						<EuiFieldNumber
							disabled={isLoading}
							max={100}
							min={0}
							name="rateFactor"
							onChange={handleSetRateFactor}
							placeholder="Discount"
							required
							step={0.01}
							type="number"
							value={state.rateFactor || ''}
							fullWidth
							autoComplete="off"
						/>
					</EuiFormRow>
				)}
				<EuiFormRow label="Expires On" fullWidth>
					<EuiSimpleDatePicker
						disabled={isLoading}
						onChange={handleChangeAsDate}
						placeholder="MM/DD/YYYY"
						required
						selected={state.expirationDate}
						fullWidth
					/>
				</EuiFormRow>
			</div>

			<div className={styles.formActions}>
				<Stack>
					<EuiButton color="primary" fill disabled={state.isLoading} type="submit">
						Save
					</EuiButton>
					<EuiButton color="text" onClick={handleCancel}>
						Cancel
					</EuiButton>
				</Stack>
			</div>
		</form>
	);
};

export default RateDrawer;

RateDrawer.propTypes = {
	rateType: PropTypes.string,
	onClose: PropTypes.func,
};
