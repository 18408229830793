import { useCallback, useState } from 'react';
import { saveAs } from 'file-saver';

import { heapEvent } from '@equipmentshare/heaper';

import invoicesService from '@services/invoices.service';
import errorHandlerService from '@services/error-handler.service';
import toastService from '@services/toast.service';
import { Invoice } from '@helpers/invoice';

export function createFilename(invoice: Invoice.BillingServiceInvoice) {
	let billType = '';
	const approved = Invoice.isGetDtoData(invoice) ? invoice?.billingApprovedData.approved : invoice?.billingApproved;
	const invoiceNumber = invoice?.invoiceNo;

	if (invoice && !approved) {
		billType = Invoice.isRentalInvoice(invoice) ? '_DRAFT' : '_ESTIMATE';
	}

	return `Invoice_${invoiceNumber ?? ''}${billType}.pdf`;
}

export async function downloadAndSaveInvoice(invoiceId: number, filename: string) {
	const { url } = await invoicesService.requestPdfAccess(invoiceId);
	const blob = await fetch(url).then((res) => res.blob());

	saveAs(blob, filename);
}

export function useInvoiceDownloadAction(invoice: Invoice.BillingServiceInvoice) {
	const [isLoading, setLoading] = useState(false);
	const invoiceId = invoice.invoiceId;

	const action = useCallback(async () => {
		try {
			setLoading(true);

			const filename = createFilename(invoice);
			await downloadAndSaveInvoice(invoiceId, filename);

			heapEvent('Admin - Transactions - Download - Invoice', {
				invoice_id: invoiceId,
			});
			toastService.showSuccess('Invoice PDF downloaded');
		} catch (e) {
			errorHandlerService.genericErrorHandler(e);
		} finally {
			setLoading(false);
		}
	}, [invoice, setLoading, invoiceId]);

	return {
		action,
		isLoading,
	};
}
