import ngModule from '../../../ng/index.js';
import template from './delivery-asset-condition.html';
import imageService from '../../../services/image.service.js';

function DeliveryAssetConditionController($scope) {
	/**
	 * use the Image api to retrieve the url for a photo with a given size
	 * @param  {string} filename file name
	 * @param  {string} size     photo size: small,large,medium,thumbnail
	 * @return {string}          photo url
	 */
	const photoUrlBySize = (filename, size) => {
		return imageService.fetchURL({
			photo: {
				filename,
				[size]: true,
			},
		});
	};
	// process photos into thumbnail and large

	$scope.hours = () => {
		if ($scope.deliveryDetails.hours_reading) {
			return $scope.deliveryDetails.hours_reading;
		} else if ($scope.deliveryDetails.asset_status_hours) {
			return $scope.deliveryDetails.asset_status_hours;
		}
		return '--';
	};

	$scope.photos = $scope.deliveryPhotos.map(({ photo }) => ({
		large: photoUrlBySize(photo.filename, 'large'),
		thumbnail: photoUrlBySize(photo.filename, 'thumbnail'),
	}));
}

ngModule
	.directive('deliveryAssetCondition', () => {
		return {
			template,
			scope: {
				deliveryPhotos: '=',
				deliveryDetails: '=',
				asset: '=',
			},
			controller: 'DeliveryAssetConditionController',
		};
	})
	.controller('DeliveryAssetConditionController', DeliveryAssetConditionController);
