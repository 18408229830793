import ngModule from '../../ng/index.js';
import _find from 'lodash/find';
import moment from 'moment-timezone';
import { createErrorHandler } from '../../state/helpers.js';

function AssetDetailCtrl($scope, esApiFactoryV2, esCanonicalizer, $state, $stateParams) {
	const canonicalizer = esCanonicalizer.canonicalizer('asset');

	$scope.getBatteryVoltage = () => {
		const batteryVoltage = $scope.asset.asset_status.battery_voltage;

		return batteryVoltage ? `${batteryVoltage}v` : '--';
	};

	$scope.getLastParkedAt = () => {
		// calculate the difference between the last time the engine was turned off and now
		const lastEngineOff = $scope.asset.asset_status.last_engine_off_timestamp;

		if (lastEngineOff) {
			const diff = moment(lastEngineOff).diff(moment());

			return moment.duration(diff).humanize(true);
		}

		return '--';
	};

	$scope.hasOpenAssignmant = () => {
		return !$scope.loadingAssignments && !!_find($scope.equipmentAssignments, ['end_date', null]);
	};

	$scope.setAssetLocation = () => {
		$scope.assetLocation = null;
		if ($scope.asset && $scope.asset.asset_status && $scope.asset.asset_status.location) {
			const location = $scope.asset.asset_status.location;

			$scope.assetLocation = {
				lat: location.latitude,
				lng: location.longitude,
			};
		}
	};

	const baseState = 'home.asset-detail';
	const tabStateOverrides = {
		service: 'home.asset-detail.service.upcoming',
	};

	$scope.isTabSelected = (tab) => {
		const state = [baseState, tab].join('.');

		return $state.includes(state);
	};
	$scope.tabSelected = (tab) => {
		const state = [baseState, tab].join('.');

		if (!$state.includes(state)) {
			$state.go(tabStateOverrides[tab] || state);
		}
	};

	$scope.init = () => {
		$scope.gettingAssetData = false;
		$scope.assetId = $stateParams.assetId;
		$scope.noStatus = true;
		$scope.equipmentAssignments = [];

		esApiFactoryV2
			.getAsset($scope.assetId)
			.then((res) => res.data)
			.then(canonicalizer)
			.then((asset) => {
				$scope.asset = asset;
				$scope.isVehicle = $scope.asset.asset_type_id === 2;
				// equipment
				if (!$scope.isVehicle) {
					$scope.assetName = asset.make.equipment_make_id === 1 ? asset.name : `${asset.make.name} ${asset.model}`;
				} else {
					// vehicle
					$scope.assetName = `${asset.make.name} ${asset.model}`;
				}
				$scope.assetPhoto = esApiFactoryV2.getPhotoUrl(asset, false, true);
			})
			.then($scope.getAssetStatus)
			.then($scope.getAssetEquipmentAssignments);
	};

	$scope.getAssetStatus = () => {
		return esApiFactoryV2.getAssetStatus($scope.assetId).then((status) => {
			const _asset = $scope.asset;
			$scope.asset = Object.assign(_asset, {
				asset_status: status,
			});
			$scope.batteryVoltage = $scope.getBatteryVoltage();
			$scope.lastParkedAt = $scope.getLastParkedAt();
			$scope.setAssetLocation();
		});
	};

	$scope.getAssetEquipmentAssignments = () => {
		$scope.loadingAssignments = true;

		return esApiFactoryV2
			.adminGetAssetEquipmentAssignments($scope.assetId)
			.then((assignments) => {
				$scope.equipmentAssignments = assignments;
				$scope.loadingAssignments = false;

				return assignments;
			})
			.then((assignments) => {
				$scope.rentId = null;
				if (assignments.length) {
					// find an assignment with "on rent" or "off rent" and return its rentId
					const assignment = _find(
						assignments,
						({ rental }) => rental.rental_status.name === 'On Rent' || rental.rental_status.name === 'Off Rent'
					);

					$scope.rentId = assignment && assignment.rental_id ? assignment.rental_id : null;
				}
			})
			.catch(createErrorHandler('Failed to get asset assignments, please contact engineering.'));
	};

	$scope.init();
}

ngModule.controller('AssetDetailCtrl', AssetDetailCtrl);
