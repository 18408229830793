export const SECURITY_LEVEL_IDS = {
	ADMIN: 1,
	COMPANY_OWNER: 2,
	SALESPERSON: 4,
	COMPANY_MANAGER: 5,
};
export const SECURITY_LEVEL_NAMES = {
	[SECURITY_LEVEL_IDS.ADMIN]: 'ES Admin',
	[SECURITY_LEVEL_IDS.COMPANY_OWNER]: 'Company Owner',
	[SECURITY_LEVEL_IDS.SALESPERSON]: 'ES Salesperson',
	[SECURITY_LEVEL_IDS.COMPANY_MANAGER]: 'Company Manager',
};

export const SECURITY_LEVELS = Object.values(SECURITY_LEVEL_IDS).map((id) => ({
	security_level_id: id,
	name: SECURITY_LEVEL_NAMES[id],
}));

export const SECURITY_LEVEL_OPTIONS = Object.values(SECURITY_LEVEL_IDS).map((id) => ({
	value: id,
	text: SECURITY_LEVEL_NAMES[id],
}));
