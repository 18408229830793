import esApi from '@services/es-api.service';
import authService from './auth.service.js';

export async function fetch(applicationId, companyId) {
	if (!companyId) {
		companyId = authService.cookies.companyId.get();
	}
	return await esApi.request(esApi.url`/companies/${companyId}/preferences?client_application_id=${applicationId}`);
}

export async function update(applicationId, viewSettings, companyId) {
	if (!companyId) {
		companyId = authService.cookies.companyId.get();
	}
	const payload = {
		application_company_preference_id: viewSettings.application_company_preference_id,
		client_application_id: applicationId,
		preferences: viewSettings.preferences,
	};
	return await esApi.request(esApi.url`/companies/${companyId}/preferences`, null, { body: payload, method: 'PUT' });
}

const companyViewSettingsService = {
	fetch,
	update,
};

export default companyViewSettingsService;
