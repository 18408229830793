import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { sortColors } from "./helpers.js";
import colorpickerStyles from "./colorpicker.module.css";

export default function ColorPicker( props ) {
  let { colors, handleChange, selectedColor, sort } = props;

  let handleClick = ( color ) => () => handleChange( color );

  if ( sort ) {
    colors = sortColors( colors ).map( ( color ) => color.hex );
  }

  return (
    <div className={colorpickerStyles.colorpicker}>
      {
        colors.map( ( color ) => {
          let style = {
            backgroundColor: color
          };

          let selectedColorClass = classNames(
            colorpickerStyles.container,
            {
              [colorpickerStyles.selected]: selectedColor && color.toLowerCase() === selectedColor.toLowerCase()
            }
          );

          return (
            <div className={selectedColorClass} key={"color-" + color} onClick={handleClick( color )}>
              <div className={colorpickerStyles.color} style={style} />
            </div>
          );
        } )
      }
    </div>
  );
}

ColorPicker.propTypes = {
  /** Array of color values */
  colors: PropTypes.array,
  /** Callback when a color is selected */
  handleChange: PropTypes.func,
  /** Currently selected color, defaults to first color in colors  */
  selectedColor: PropTypes.string,
  /** Sort colors based on hue  */
  sort: PropTypes.bool,
};

ColorPicker.defaultProps = {
  colors: [
    "#4e4e4e",
    "#909194",
    "#cfcfd1",
    "#2ab6ea",
    "#094874",
    "#46294e",
    "#7e2779",
    "#ed3895",
    "#f6911e",
    "#fcb22e",
    "#9dcc45",
    "#60aa45",
    "#3b6731"
  ],
  handleChange: () => {},
  selectedColor: null,
  sort: false,
};
