import { formatAddress } from '@equipmentshare/admin-hooks';

const fixSearchString = (str) => String(str).replace(/[-\s]/gi, '').toLowerCase();
const includes = (str, query) => !!str && fixSearchString(str).includes(fixSearchString(query));

export function filterLocation(location, query) {
	const searchTerm = String(query).toLowerCase().replace(/[-\s]/gi, '');

	return includes(location.nickname, searchTerm) || includes(formatAddress(location, false), searchTerm);
}
