import angular from 'angular';
import template from './table-row-drawer.html';

angular.module("esNgComponents").component('tableRowDrawer', {
  template,
  transclude: true,
  controller: function(){},
  bindings: {
    open: '<'
  }
});