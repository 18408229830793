import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import formControlStyles from "../form-control.module.css";

let inputId = 0;

export default class Input extends PureComponent {
  constructor( props ) {
    super( props );

    this.state = {
      inputHasContent: false
    };

    this.isRequired = this.isRequired.bind( this );
    this.handleKeyUp = this.handleKeyUp.bind( this );
  }

  UNSAFE_componentWillMount() {
    this._id = `input${inputId++}`;
  }

  isRequired() {
    return ( this.props.required && <span className={formControlStyles.required}>&nbsp;(required)</span> );
  }

  handleKeyUp( value ) {
    this.setState( {
      inputHasContent: !!value
    } );

    this.props.onKeyUp();
  }

  render() {
    /* eslint-disable no-unused-vars */ // disable rule for onKeyUp
    const {
      autocomplete,
      autofocus,
      className,
      iconClickHandler,
      iconName,
      id,
      label,
      name,
      onKeyUp,
      placeholder,
      readonly,
      swapLabel,
      value,
      ...inputProps
    } = this.props;
    /* eslint-enableable no-unused-vars */

    let inputAttrs = {
      "aria-labelledby": id || this._id,
      "autoComplete": autocomplete,
      "autoFocus": autofocus,
      "className": formControlStyles.input,
      "id": id || this._id,
      "name": name || id,
      "onKeyUp": this.handleKeyUp,
      "readOnly": readonly,
      placeholder,
      ...inputProps,
    };

    // Allows uncontrolled inputs to work correctly
    // by only applying a value if one is provided.
    if ( value != null ) { // eslint-disable-line no-eq-null
      inputAttrs.value = value;
    }

    let inputLabel = ( swapLabel && placeholder )
      ? placeholder
      : label;

    return (
      <div className={classNames( formControlStyles.control, className )}>
        {inputLabel &&
          <label htmlFor={id || this._id}>{inputLabel}{this.isRequired()}</label>
        }
        <div className={classNames( formControlStyles.inputContainer, { [formControlStyles.hasIcon]: !!iconName } )}>
          {
            iconName &&
            <span aria-hidden="true" className={classNames( formControlStyles.iconContainer, { [formControlStyles.hasAction]: iconClickHandler } )} onClick={iconClickHandler}>
              <i className={iconName} />
            </span>
          }
          <input {...inputAttrs} />
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  /** see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes */
  autocomplete: PropTypes.string,
  /** see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes */
  autofocus: PropTypes.bool,
  /** custom class */
  className: PropTypes.string,
  /** disabled boolean */
  disabled: PropTypes.bool,
  /** set icon click handler *only* if `iconName` is provided */
  iconClickHandler: PropTypes.oneOfType( [
    PropTypes.func,
    PropTypes.instanceOf( null )
  ] ),
  /** icon name for example `fa fa-paperclip` */
  iconName: PropTypes.string,
  /** input id attribute. If not set a random id will be assigned with the prefix `input` */
  id: PropTypes.string,
  /** label for the input */
  label: PropTypes.string,
  /** maximum character limit */
  maxLength: PropTypes.number,
  /** name attribute */
  name: PropTypes.string,
  /** onChange event handler */
  onChange: PropTypes.func,
  /** onKeyDown event handler */
  onKeyDown: PropTypes.func,
  /** onKeyUp event handler */
  onKeyUp: PropTypes.func,
  /** the placeholder text for the input, see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-placeholder */
  placeholder: PropTypes.string,
  /** mark the input as readonly */
  readonly: PropTypes.bool,
  /** mark the input as required */
  required: PropTypes.bool,
  /** see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#Controlling_step_size */
  step: PropTypes.number,
  /** creates placeholder -> label swap behavior - requires placeholder value  */
  swapLabel: PropTypes.bool,
  /** input type for example "text", "number", "email" etc. */
  type: PropTypes.string,
  /** inital value */
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
  ] ),
};
Input.defaultProps = {
  autocomplete: "off",
  autofocus: false,
  className: "",
  disabled: false,
  iconClickHandler: null,
  iconName: "",
  id: "",
  label: "",
  maxLength: null,
  name: "",
  onChange: null,
  onKeyDown: () => {},
  onKeyUp: () => {},
  placeholder: "",
  readonly: false,
  required: false,
  step: null,
  swapLabel: false,
  type: "text",
  value: "",
};
