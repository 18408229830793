import angular from 'angular';
import ngModule from '../../../ng/index.js';
import _includes from 'lodash/includes';
import toastService from '@services/toast.service.js';
import rentalContractsService from '@services/rental-contracts.service';

// These placeholders are different from the '%%rental link%%'-style placeholders,
// they are (for now) expected to be replaced manually by the user, because equipment name
// is an exceptionally nuanced thing, best done by humanoids for now.
const EQUIPMENT_PLACEHOLDER = '<ENTER EQUIPMENT>';
const NAME_PLACEHOLDER = '<ENTER NAME>';

const MESSAGE_BODY = {};

MESSAGE_BODY.NZ = `Hi ${NAME_PLACEHOLDER},

Thanks for using EquipmentShare - We really appreciate your business.

The Rental Agreement for the ${EQUIPMENT_PLACEHOLDER} is ready for your approval. Just click the link below to review and sign (scroll down to the bottom, type your name, and click "Agree").

Rental Agreement Link: %%rental_link%%

Payment Instructions - Cash Accounts

We require all cash account customers to have a credit card on file with EquipmentShare. If you have not set this up yet you can talk to us in person or give us a call on (09) 8877866.

Once you have completed your rental agreement, we will run the estimated charges for the rental through your credit card and send a receipt to your email address.

On completion of the rental we will send you an invoice, refund any monies owing or charge for any additional rental, fuel, or other items.

Credit Accounts

If you like, you can also sign up for an EquipmentShare credit account. Credit account customers are invoiced at the end of the job with payment due on the 20th of the month following. You can find the credit account application at:

https://equipmentshare.co.nz/creditapp

Just fill this in, scan and send back to accounts@equipmentshare.co.nz

Health & Safety Instructions

EquipmentShare has an obligation to make sure that you (or your operator/ equipment supervisor) has suitable experience to operate the equipment we lend you.

New customers must fill in the electronic operator competency form in the following link:

https://goo.gl/forms/POcfSOFEWoQ1Keew2

Simply type in your details and press submit.

You should always use appropriate personal protective equipment (PPE) when operating Equipment.

If at anytime you notice a fault with the Equipment please call EquipmentShare immediately on 09 8877866.

If you have any questions or need anything else feel free to get in touch anytime.

Thanks again,

Danny & Michael
Your EquipmentShare Team

Ph: 09 8877866
Yard Location: 8 Tacoma Rd, Wiri, Auckland`;

MESSAGE_BODY.US = `Hi ${NAME_PLACEHOLDER},

The Rental Agreement for the ${EQUIPMENT_PLACEHOLDER} is ready for your approval. Click the link below to review and sign. To sign, click the grey box labeled “Rental Agreement Terms”, scroll down to the bottom of the terms and conditions, type your name, title, and click “I Agree”.

Rental Agreement Link: %%rental_link%%

If you have any questions or need anything else feel free to reach out anytime. Thanks again for using EquipmentShare!

Best,


The EquipmentShare Team`;

function RentalAgreementCtrl($scope, esApiFactoryV2, $q, $location, esRentalService, $log, esEnv) {
	const controller = this;

	$scope.init = () => {
		$scope.agreement = {
			email: angular.copy($scope.user.email_address),
			sms: angular.copy($scope.user.phone_number),
			isSendOptionEmail: true,
			messageSubject: 'EquipmentShare Rental Agreement',
			messageBody: controller.getMessageBody(),
		};

		$scope.cardExpanded = !!$scope.initExpandState;

		$scope.setLinks();
	};

	$scope.setLinks = () => {
		$scope.rentalAgreementLink = '';
		rentalContractsService
			.getRentalAgreementLink($scope.orderId)
			.then((url) => {
				$scope.rentalAgreementLink = url;
			})
			.catch((e) => {
				toastService.showError(`Could not fetch contract for order ${$scope.orderId}`);
			});
	};

	$scope.sendAgreement = (agreement) => {
		if (!agreement.isSendOptionEmail && !agreement.isSendOptionSms) {
			toastService.showError('Email or SMS must be selected.');

			return;
		}

		if (agreement.isSendOptionEmail && !agreement.email) {
			toastService.showError('An email address must be provided.');

			return;
		}

		if (agreement.isSendOptionSms && !agreement.sms) {
			toastService.showError('A phone number must be provided.');

			return;
		}

		let cleanMessageBody;
		let cleanSubject;

		if (!agreement.messageBody) {
			toastService.showError('A Message Body must be provided.');

			return;
		} else if (
			agreement.messageBody.indexOf(EQUIPMENT_PLACEHOLDER) > -1 ||
			agreement.messageBody.indexOf(NAME_PLACEHOLDER) > -1
		) {
			toastService.showError(
				'Please replace the <ENTER NAME> and <ENTER EQUIPMENT> placeholders before you send the message.'
			);

			return;
		} else {
			cleanMessageBody = controller.sanitizeMessageBody(agreement.messageBody);
			cleanSubject = controller.sanitizeMessageBody(agreement.messageSubject);
		}
		$log.debug('cleanMessageBody', cleanMessageBody);

		$scope.submitting = true;

		const apiRequests = [];

		if (agreement.isSendOptionEmail) {
			const payloadEmail = {
				order_id: $scope.orderId,
				subject: cleanSubject,
				message: cleanMessageBody,
				method: 'email',
				recipient: agreement.email,
			};

			apiRequests.push(esApiFactoryV2.postAdminRentalAgreement(payloadEmail));
		}

		if (agreement.isSendOptionSms) {
			const payloadSms = {
				order_id: $scope.orderId,
				message: cleanMessageBody,
				method: 'sms',
				recipient: agreement.sms,
			};

			apiRequests.push(esApiFactoryV2.postAdminRentalAgreement(payloadSms));
		}

		$q.all(apiRequests)
			.then(() => {
				$log.info('apiRequests all SUCCESS');
				toastService.showSuccess('Rental Agreement successfully sent.');
				$scope.submitting = false;
			})
			.catch(() => {
				$log.info('apiRequests FAILURE');
				toastService.showError('There was an error sending Rental Agreement.');
				$scope.submitting = false;
			});
	};

	$scope.displayJobLocationWarning = () => {
		if ($scope.rentals && !$scope.rentals.length) {
			return true;
		}

		return !$scope.rentals.every((rental) => rental.current_location && rental.current_location.location_id);
	};

	$scope.displayBillingLocationWarning = () =>
		!$scope.user.company.billing_location_id && !$scope.user.company.billing_location;

	controller.sanitizeMessageBody = (originalBody) => {
		let replaced = originalBody.replace(/\r\n/g, '<br><br>').replace(/\n/g, '<br>');

		replaced = replaced.replace(/%%rental_link%%/g, '<a href="%%rental_link%%">%%rental_link%%</a>');

		return replaced;
	};

	controller.getMessageBody = () => {
		const contry = _includes(esEnv.getHost(), '.nz') ? 'NZ' : 'US';

		return MESSAGE_BODY[contry] || MESSAGE_BODY.US;
	};

	$scope.init();
}

ngModule.controller('RentalAgreementCtrl', RentalAgreementCtrl);
