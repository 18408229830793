import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import cardStyles from "../card.module.css";

const CardHeader = ( { children, className, ...props } ) => (
  <div className={classNames( cardStyles.header, className )} {...props}>
    {children}
  </div>
);

CardHeader.propTypes = {
  /** Markup to be displayed in the card's header */
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

CardHeader.defaultProps = {
  className: null,
};

export default CardHeader;
