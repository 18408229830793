import _get from 'lodash/get';
import googlePlacesService from '@services/google-places.service';

let service;
function getPlaceService() {
	if (!service) {
		service = new google.maps.Geocoder();
	}

	return service;
}

function geocode(params) {
	return new Promise((resolve, reject) => {
		getPlaceService().geocode(params, (results, status) => {
			if (status === 'OK') {
				resolve(results);
			} else {
				reject(status.error);
			}
		});
	});
}
async function geocodePlusCode(plusCode) {
	const results = await geocode({ address: plusCode });

	return results[0];
}
async function geocodePlusCodeWithZip(plusCode) {
	const place = await geocodePlusCode(plusCode);
	const placeWithPostalCodeArray = await geocodeLatLng(place.geometry.location);
	const placeWithPostalCode = placeWithPostalCodeArray[0];

	const copyComponents = ['postal_code', 'postal_code_suffix', 'route', 'street_number'];
	placeWithPostalCode.address_components.forEach((component) => {
		if (component.types.find((t) => copyComponents.includes(t))) {
			place.address_components.push(component);
		}
	});

	return place;
}
async function geocodeLatLng(latlng) {
	const results = await geocode({ location: latlng });

	return results;
}

async function getAddressLatLng(location) {
	const address = [location.street_1, location.city, location.state.name, location.zip_code].join(' ');
	const results = await geocode({ address });

	const latlng = _get(results[0], 'geometry.location', null);
	return {
		latitude: latlng.lat(),
		longitude: latlng.lng(),
	};
}

async function getAddressFromLatLng(latlng) {
	const results = await geocodeLatLng(latlng);
	const locations = results.map((result) => googlePlacesService.convertPlaceToLocation(result));
	const completeLocation = locations.find((location) => {
		const hasRequiredInfo = !!location.street_1 && !!location.city && !!location.state;
		return hasRequiredInfo;
	});

	return completeLocation || locations[0];
}

const googleGeocoderService = {
	geocodeLatLng,
	geocodePlusCode,
	geocodePlusCodeWithZip,
	getAddressLatLng,
	getAddressFromLatLng,
};

window.googleGeocoderService = googleGeocoderService;

export default googleGeocoderService;
