import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EuiBadge } from '@equipmentshare/ds2';
import { CloseLineIcon } from '@equipmentshare/ds2-icons';

import styles from './filtered-chip-group.module.css';

const FilteredChipGroup = ({ children, containerClassName, chipClassName, filters = [], onClose = () => {} }) => {
	const renderChip = (filter, idx) => (
		<EuiBadge
			key={`filterChip_${idx}`}
			className={classNames(styles.filterChip, chipClassName)}
			color="hollow"
			iconType={CloseLineIcon}
			iconSide="right"
			iconOnClick={() => {
				handleOnClose(filter, idx);
			}}
			iconOnClickAriaLabel="remove"
		>
			{`${filter.label}: ${filter.name}`}
		</EuiBadge>
	);

	const chips = useMemo(() => {
		return filters.map((filter, idx) => renderChip(filter, idx));
	}, [filters]);

	const handleOnClose = (filter) => onClose(filter);

	return (
		<div className={classNames(styles.layout, containerClassName)}>
			{chips}
			{children}
		</div>
	);
};

FilteredChipGroup.propTypes = {
	children: PropTypes.node,
	chipClassName: PropTypes.string,
	containerClassName: PropTypes.string,
	filters: PropTypes.array,
	onClose: PropTypes.func,
};

export default FilteredChipGroup;
