import React from 'react';
import { RowMeasurer } from './row-measurer.js';

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withRowMeasurer() {
	return (WrappedComponent) => {
		const WithRowMeasurer = (props) => (
			<RowMeasurer {...props}>{(childProps) => <WrappedComponent {...childProps} />}</RowMeasurer>
		);

		WithRowMeasurer.displayName = `WithRowMeasurer(${getDisplayName(WrappedComponent)})`;
		WithRowMeasurer.WrappedComponent = WrappedComponent;

		return WithRowMeasurer;
	};
}

export default withRowMeasurer;
