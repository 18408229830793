import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSelection } from '../providers/selection-provider.js';
import CheckboxColumn from '../checkbox-column/checkbox-column.js';
import { Column } from '../column/column.js';

export const SelectionColumn = ({ showCheckbox, ...props }) => {
	const { selectedIds, selectAllIds, ids } = useSelection();
	const handleSelect = useCallback(() => selectAllIds(), [selectAllIds]);

	if (!showCheckbox) {
		return <Column {...props} />;
	}

	return (
		<CheckboxColumn
			{...props}
			checked={selectedIds.length === ids.length && ids.length > 0}
			onChange={handleSelect}
			disabled={ids.length === 0}
		/>
	);
};

SelectionColumn.propTypes = {
	children: PropTypes.node,
	columnId: PropTypes.string,
	showCheckbox: PropTypes.bool,
};
SelectionColumn.defaultProps = {
	columnWidth: '3rem',
	columnId: 'selection',
	showCheckbox: true,
};

export default SelectionColumn;
