import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { useQueryClient } from 'react-query';

import { ENTITY_TYPES } from '@equipmentshare/admin-hooks';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFieldText, EuiFieldNumber, EuiFormRow } from '@equipmentshare/ds2';
import { EuiSimpleDatePicker } from '@equipmentshare/es-admin-eui-simple-components';

import poService from '@services/purchase-order.service';
import errorHandlerService from '@services/error-handler.service';
import toastService from '@services/toast.service.js';
import styles from './po-create-form.module.css';

const startOfDay = moment().startOf('day');

const POCreateForm = ({ companyId, className, onCancel, onCreatePO }) => {
	const queryClient = useQueryClient();
	// Form metadata
	const [loading, setLoading] = useState(false);
	const [showAdvanced, setShowAdvanced] = useState(false);

	// Form data
	const [name, setName] = useState('');
	const [budgetAmount, setBudgetAmount] = useState(null);
	const [startDate, setStartDate] = useState(startOfDay);
	const [endDate, setEndDate] = useState(null);

	const resetFormData = () => {
		setName('');
		setBudgetAmount(null);
		setStartDate(startOfDay);
		setEndDate(null);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (loading) {
			return Promise.resolve(null);
		}

		const trimmedName = name.trim();
		if (trimmedName.length < 3) {
			toastService.showError('Name must be at least 3 characters.');
			return Promise.resolve(null);
		}

		const payload = {
			name: trimmedName,
			budget_amount: budgetAmount,
			start_date: startDate,
			end_date: endDate,
		};

		setLoading(true);

		try {
			const purchaseOrder = await poService.create(companyId, payload);

			if (onCreatePO) {
				onCreatePO(purchaseOrder);
			}

			resetFormData();
			queryClient.invalidateQueries([ENTITY_TYPES.PURCHASE_ORDER]);

			return purchaseOrder;
		} catch (e) {
			errorHandlerService.genericErrorHandler(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form className={classNames(styles.layout, className)} onSubmit={handleSubmit}>
			<EuiFormRow label="Name">
				<EuiFieldText
					className={styles.input}
					value={name}
					onChange={(e) => setName(e.target.value)}
					required
					placeholder="Name"
					minLength={3}
					maxLength={255}
				/>
			</EuiFormRow>
			<div className={classNames(styles.advancedOptions, showAdvanced ? styles.open : '')}>
				<EuiFormRow label="Budget Amount">
					<EuiFieldNumber
						className={styles.input}
						value={budgetAmount || ''}
						onChange={(e) => setBudgetAmount(e.target.value)}
						placeholder="Budget Amount"
						type="number"
					/>
				</EuiFormRow>
				<EuiFormRow label="Start Date">
					<EuiSimpleDatePicker
						selected={startDate}
						onChange={(d) => setStartDate(d)}
						required
						placeholderText="Start Date"
						maxDate={endDate}
					/>
				</EuiFormRow>
				<EuiFormRow label="End Date">
					<EuiSimpleDatePicker
						selected={endDate}
						onChange={(d) => setEndDate(d)}
						placeholderText="End Date"
						minDate={startDate}
					/>
				</EuiFormRow>
			</div>
			<a className={styles.showAdvanced} onClick={() => setShowAdvanced(!showAdvanced)}>
				{showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
			</a>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<EuiButton fill type="submit" isDisabled={loading} isLoading={loading}>
						Save
					</EuiButton>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton color="text" onClick={onCancel}>
						Cancel
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>
		</form>
	);
};

POCreateForm.propTypes = {
	companyId: PropTypes.number,
	className: PropTypes.string,
	onCancel: PropTypes.func,
	onCreatePO: PropTypes.func,
};

export default POCreateForm;
