import esApi from './es-api.service';

async function search(query, opts) {
	return await esApi.request(esApi.url`/location-rental-rates`, query, opts);
}

async function create(payload) {
	return await esApi.request(esApi.url`/location-rental-rates`, null, { body: payload, method: 'POST' });
}

async function update(locationId, payload) {
	return await esApi.request(esApi.url`/location-rental-rates/${locationId}`, {}, { body: payload, method: 'PATCH' });
}

export async function updateMultiple(ids, payload) {
	return await Promise.all(
		ids.map(async (id) => {
			return await update(id, payload);
		})
	);
}

export async function getBulkUploadTemplate() {
	return await esApi.request(esApi.url`/location-rental-rates/bulk`);
}

export async function bulkUpload(payload) {
	return await esApi.request(esApi.url`/location-rental-rates/bulk`, null, { body: payload, method: 'POST' });
}

const locationRentalRatesService = {
	search,
	create,
	update,
	updateMultiple,
	getBulkUploadTemplate,
	bulkUpload,
};

export default locationRentalRatesService;
