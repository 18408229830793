import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './row.module.css';

export class Row extends PureComponent {
	render() {
		const { children, onClick, style, className, component: Element, entry, ...props } = this.props;
		const clickable = !!onClick;

		return (
			<Element
				style={style}
				className={classNames(styles.row, className, clickable && styles.clickable)}
				onClick={onClick}
				{...props}
			>
				{children}
			</Element>
		);
	}
}

Row.propTypes = {
	component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	children: PropTypes.node,
	className: PropTypes.string,
	onClick: PropTypes.func,
	style: PropTypes.object,
};
Row.defaultProps = {
	component: 'div',
};

export default Row;
