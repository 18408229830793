import _get from 'lodash/get';
import _round from 'lodash/round';
import _uniqBy from 'lodash/unionBy';
import ngModule from '../../../ng/index.js';

function DeliveryMapService(esDeliveriesService) {
	const hasLatLng = (coords) => !!coords.latitude && !!coords.longitude;

	// TODO: move the delivery colors to a more appropriate place
	const deliveryColors = {
		'INITIAL DROPOFF': {
			today: '#F79722',
			tomorrow: '#F9B159',
			'third-day': '#FBCB91',
			future: '#FDE5C8',
		},
		'FINAL RETURN': '#1A9AC6',
		'SWAP DROPOFF': '#FFFF88',
		'SWAP RETURN': '#5CC6EA',
		MOVE: '#2CBC9B',
		TRANSPORT: '#8E60BE',
	};

	function getMakeModel(asset) {
		if (!asset) {
			return '--';
		}

		return `${_get(asset, 'make.name', '')} ${_get(asset, 'model', '')}`;
	}

	function getDeliveryColor(delivery) {
		switch (delivery._type) {
			case esDeliveriesService.deliveryTypes.INITIAL_DROPOFF:
				return deliveryColors[delivery._type][esDeliveriesService.getInitialDropoffStyle(delivery)];
			default:
				return deliveryColors[delivery._type];
		}
	}

	/**
	 * creates the svg icon for a group of delivery markers
	 * @param {Array} group - an array of markers
	 * @returns {string} - returns a SVG string
	 */
	function createGroupIcon(group) {
		const markerSize = '28';
		const deliveries = _uniqBy(
			group.map((m) => m.delivery),
			'delivery_id'
		);
		const deliveriesByColor = {};

		// loop through each delivery and sort it by its color
		deliveries.forEach((delivery) => {
			const color = getDeliveryColor(delivery);

			deliveriesByColor[color] = (deliveriesByColor[color] += 1) || 1;
		});

		// convert the object of delivery colors into an array
		const pieRadius = 50;
		const pieSections = Reflect.ownKeys(deliveriesByColor).map((color) => [color, deliveriesByColor[color]]);

		let piePaths = [];

		if (pieSections.length === 1) {
			// if there is only one color then dont pother using paths, just use a <circle>
			piePaths.push(`<circle r="${pieRadius}" fill="${pieSections[0][0]}"></circle>`);
		} else {
			// create a <path> for each color and add it to an array
			let startAngle = 0;

			piePaths = pieSections.map((section) => {
				const color = section[0];
				const count = section[1];

				const angle = (count / deliveries.length) * (Math.PI * 2);
				const endAngle = startAngle + angle;
				const startArk = [pieRadius * Math.cos(startAngle), pieRadius * Math.sin(startAngle)].map((v) => _round(v, 5));
				const endArk = [pieRadius * Math.cos(endAngle), pieRadius * Math.sin(endAngle)].map((v) => _round(v, 5));
				const moreThanHalf = angle > Math.PI;

				startAngle = endAngle;

				return `<path d="M 0,0 L ${startArk.join(',')} A ${pieRadius},${pieRadius} 0 ${
					moreThanHalf ? 1 : 0
				},1 ${endArk.join(',')} Z" fill="${color}"></path>`;
			});
		}

		const icon = `
			<svg width="${markerSize}" height="${markerSize}" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				<g transform="translate(50,50)">
					${piePaths.join('')}
					<circle r="35" fill="white"></circle>
				</g>
			</svg>
		`;

		return `data:image/svg+xml;charset=UTF-8;base64,${btoa(icon.replace(/\n/g, '').replace(/>( )+</, '><'))}`;
	}

	/**
	 * creates a svg icon for a single marker
	 * @param delivery - the delivery object
	 * @param locationType - the type of location on the delivery. either ("origin", "destination")
	 * @returns {string} - returns a svg icon
	 */
	function createDeliveryIcon(delivery, locationType) {
		const markerSize = '24';
		const color = getDeliveryColor(delivery) || 'black';
		const icon = `
			<svg width="${markerSize}" height="${markerSize}" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				<g transform="translate(50,50)">
					<circle r="50" fill="black"></circle>
					<circle r="40" fill="${color}"></circle>
				</g>
			</svg>
		`;

		return `data:image/svg+xml;charset=UTF-8;base64,${btoa(icon.replace(/\n/g, '').replace(/>( )+</, '><'))}`;
	}

	/**
	 * takes a group of markers and creates a group maker for them
	 * @param {Array} group - an array of markers
	 * @returns {{label: string, icon: string, lat: number, lng: number, deliveries: Array}}
	 */
	function createGroupMarker(group) {
		const deliveries = _uniqBy(
			group.map((m) => m.delivery),
			'delivery_id'
		);

		return {
			label: String(deliveries.length),
			icon: createGroupIcon(group),
			lat: group[0].lat,
			lng: group[0].lng,
			deliveries,
		};
	}

	/**
	 * creates a marker for a single delivery
	 * @param delivery - the delivery object
	 * @param locationType - the type of location on the delivery. either ("origin", "destination")
	 * @returns {{title: string, label: string, icon: string, lat: number, lng: number, deliveries: Array}}
	 */
	function createDeliveryMarker(delivery, locationType) {
		const isBulkRental = delivery.rental?.rental_type_id === 3;

		const _asset = !isBulkRental ? `asset: ${getMakeModel(delivery.asset)}` : null;

		const _class = !isBulkRental
			? `class: ${_get(delivery, '_itemDescription', '--')}`
			: `bulk item: ${_get(delivery, '_itemDescription', '--')} / qty: ${_get(delivery, 'quantity', '--')}`;

		const _title = [`type: ${delivery._type}`, `location: ${locationType}`];

		if (!isBulkRental) {
			_title.push(_asset, _class);
		} else {
			_title.push(_class);
		}

		const title = _title.join('\n');

		const icon = createDeliveryIcon(delivery, locationType) || null;
		const label = delivery.driver && {
			fontFamily: 'sans-serif',
			fontSize: '0.6rem',
			fontWeight: 'bold',
			text: String(delivery.driver.first_name.substr(0, 1) + delivery.driver.last_name.substr(0, 1)).toUpperCase(),
		};

		return {
			title,
			icon,
			delivery,
			label,
			lat: delivery[locationType].latitude,
			lng: delivery[locationType].longitude,
		};
	}

	function getDeliveryMarkers(delivery) {
		const markers = [];

		switch (delivery._type) {
			case esDeliveriesService.deliveryTypes.INITIAL_DROPOFF:
				if (delivery.destination && hasLatLng(delivery.destination)) {
					markers.push(createDeliveryMarker(delivery, 'destination'));
				}
				break;
			case esDeliveriesService.deliveryTypes.FINAL_RETURN:
				if (delivery.origin && hasLatLng(delivery.origin)) {
					markers.push(createDeliveryMarker(delivery, 'origin'));
				}
				break;
			case esDeliveriesService.deliveryTypes.SWAP_DROPOFF:
			case esDeliveriesService.deliveryTypes.SWAP_RETURN:
			case esDeliveriesService.deliveryTypes.MOVE:
			case esDeliveriesService.deliveryTypes.TRANSPORT:
				if (delivery.origin && hasLatLng(delivery.origin)) {
					markers.push(createDeliveryMarker(delivery, 'origin'));
				}
				if (delivery.destination && hasLatLng(delivery.destination)) {
					markers.push(createDeliveryMarker(delivery, 'destination'));
				}
				break;
		}

		return markers;
	}

	/**
	 * groups markers by their latitude/longitude
	 * @param {Array} markers - an array of markers
	 * @returns {Array}
	 */
	function groupMakersByLocation(markers) {
		const locations = {};

		markers.forEach((marker) => {
			const key = `${marker.lat}|${marker.lng}`;

			locations[key] = locations[key] || [];
			locations[key].push(marker);
		});

		const groups = [];

		for (const i in locations) {
			groups.push(locations[i]);
		}

		return groups;
	}

	function createMarkerGroups(groups) {
		return groups.map((group) => {
			if (group.length > 1) {
				return createGroupMarker(group);
			} else {
				return group[0];
			}
		});
	}

	function getMapMarkers(deliveries) {
		const markers = deliveries.map((d) => getDeliveryMarkers(d)).reduce((a, b) => a.concat(b), []);

		const groups = groupMakersByLocation(markers);

		return createMarkerGroups(groups);
	}

	return {
		getDeliveryColor,
		createGroupIcon,
		createDeliveryIcon,
		createGroupMarker,
		createDeliveryMarker,
		getDeliveryMarkers,
		groupMakersByLocation,
		createMarkerGroups,
		getMapMarkers,
	};
}

ngModule.factory('DeliveryMapService', DeliveryMapService);
