import ngModule from '../../../ng/ng-module.js';

function RentalTakeoverCardService() {
	this.rentalId = null;
	this.deliveryId = null;
	this.isOpen = false;
	this.position = {
		left: '10%',
		right: '10%',
	};
	this.expanded = true;
	this.hideTabs = [];
	this.rentalChangeListeners = new Set();

	this.setRentalId = (rentalId) => (this.rentalId = rentalId);
	this.setDeliveryId = (deliveryId) => (this.deliveryId = deliveryId);
	this.setOpen = (open) => (this.isOpen = !!open);
	this.setExpanded = (expanded) => (this.expanded = !!expanded);
	this.setHideTabs = (hideTabs) => (this.hideTabs = !!hideTabs);
	this.addRentalChangeListener = (fn) => this.rentalChangeListeners.add(fn);
	this.removeRentalChangeListener = (fn) => this.rentalChangeListeners.delete(fn);

	return this;
}

ngModule.factory('rentalTakeoverCardService', RentalTakeoverCardService);
