import { Component } from "react";
import PropTypes from "prop-types";

import _isEqual from "lodash/isEqual";
import _sum from "lodash/sum";

/**
 * High-order component that auto-calculates column-widths for `Grid` cells.
 *
 * Based on the ColumnSizer HoC that comes packaged with react-virtualized
 * https://github.com/bvaughn/react-virtualized/blob/master/source/ColumnSizer/ColumnSizer.js
 */
export default class ESColumnSizer extends Component {
  constructor( props, context ) {
    super( props, context );

    let columnWidths = this._getColumnWidths( props );

    this.state = { columnWidths };

    this._doesExceedWidth = this._doesExceedWidth.bind( this );
    this._getColumnWidth = this._getColumnWidth.bind( this );
    this._registerChild = this._registerChild.bind( this );
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    let somethingChanged = (
      !_isEqual( newProps.columns, this.props.columns ) ||
      newProps.containerWidth !== this.props.containerWidth ||
      newProps.defaultMinColumnWidth !== this.props.defaultMinColumnWidth
    );

    if ( somethingChanged ) {
      let columnWidths = this._getColumnWidths( newProps );

      this.setState( { columnWidths } );
    }
  }

  componentDidUpdate( prevProps ) {
    if ( prevProps.containerWidth !== this.props.containerWidth && this._registeredChild ) {
      this._registeredChild.recomputeGridSize();
    }
  }

  _doesExceedWidth() {
    return this.props.containerWidth < _sum( this.state.columnWidths );
  }

  _getColumnWidth( { index } ) {
    return this.state.columnWidths[ index ];
  }

  _registerChild( child ) {
    if ( child && typeof child.recomputeGridSize !== "function" ) {
      throw Error( "Unexpected child type registered; only Grid/MultiGrid children are supported." );
    }

    this._registeredChild = child;

    if ( this._registeredChild ) {
      this._registeredChild.recomputeGridSize();
    }
  }

  /**
   * Calculates the maximum width that can be distributed to each column
   * while honoring any fixed width or minimum width properties.
   *
   * @returns { Array } Pixel values that represent the width of each column.
   */
  _getColumnWidths( { columns, containerWidth, defaultMinColumnWidth } ) {
    let fixedWidthColumns = {};

    // Map a consistent data structure, ensuring that minWidth is set for every column.
    let configuredColumns = columns.map( ( column ) => ( {
      key: column.key,
      width: column.width,
      minWidth: ( column.minWidth || defaultMinColumnWidth ),
    } ) );

    let addToFixedWidthColumns = ( key, width ) => { fixedWidthColumns[ key ] = width; };

    let calculateColumnWidths = () => {
      let fixedKeys = Object.keys( fixedWidthColumns );
      let flexibleColumns = configuredColumns.filter( ( column ) => ( !fixedKeys.includes( column.key ) ) );
      let distributedWidth = 0;

      if ( flexibleColumns.length ) {
        let availableWidth = containerWidth - _sum( Object.values( fixedWidthColumns ) );

        distributedWidth = availableWidth / flexibleColumns.length;

        // If a flexible column's minWidth property has been violated, consider
        // its width fixed and redistribute the remaining available space.
        if ( flexibleColumns.some( ( column ) => ( column.minWidth > distributedWidth ) ) ) {
          flexibleColumns
            .filter( ( column ) => ( column.minWidth > distributedWidth ) )
            .forEach( ( { key, minWidth } ) => addToFixedWidthColumns( key, minWidth ) );

          return calculateColumnWidths();
        }
      }

      return configuredColumns.map( ( column ) => (
        Math.floor( fixedWidthColumns[ column.key ] || distributedWidth )
      ) );
    };

    // Lock in any columns that have a fixed width.
    configuredColumns
      .filter( ( column ) => ( typeof column.width !== "undefined" ) )
      .forEach( ( { key, width } ) => addToFixedWidthColumns( key, width ) );

    return calculateColumnWidths();
  }

  render() {
    return this.props.children( {
      getColumnWidth: this._getColumnWidth,
      registerChild: this._registerChild,
      doesExceedWidth: this._doesExceedWidth,
    } );
  }
}

ESColumnSizer.defaultProps = {
  defaultMinColumnWidth: 1,
};

ESColumnSizer.propTypes = {
  /**
   * Function responsible for rendering a virtualized Grid.
   * This function should implement the following signature:
   * ({ adjustedWidth, getColumnWidth, registerChild }) => PropTypes.element
   *
   * The specified :getColumnWidth function should be passed to the Grid's :columnWidth property.
   * The :registerChild should be passed to the Grid's :ref property.
   * The :adjustedWidth property is optional; it reflects the lesser of the overall width or the width of all columns.
   */
  children: PropTypes.func.isRequired,

  /** Columns in Grid or Table child */
  columns: PropTypes.array.isRequired,

  /** Width of Grid or Table child */
  containerWidth: PropTypes.number.isRequired,

  /** Minimum width to apply to columns that don't specify their own minimum width */
  defaultMinColumnWidth: PropTypes.number,
};
