import React from "react";
import PropTypes from "prop-types";

import boundStyles from "./banner.module.css";

/**
 * It's hard to tell in the living document due to the gray container,
 * but this component wraps children with a colored box so that we have
 * a consistent highlight throughout the consuming applications.
 *
 * @returns {Component} - A Banner React component
 */
function Banner( { children } ) {
  return (
    <div className={boundStyles.banner}>
      {children}
    </div>
  );
}

Banner.propTypes = {
  /** The content that will be displayed inside of the banner. */
  children: PropTypes.node.isRequired,
};

export default Banner;
