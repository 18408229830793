import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withErrorHandler } from '@components/error-boundary';
import styles from './google-map.module.css';

// This is necessary because the default behavior of google maps is to change the
// center on zoom and they don't provide a simple way of having different behavior.
// Adapted from: https://stackoverflow.com/a/45514283/4918389
function applyCustomZoom(container, map) {
	function handleWheelEvent(event) {
		// to know whether it was wheel up or down
		const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));
		map.setZoom(map.getZoom() + delta);
	}

	function handleZoom() {
		map.setZoom(map.getZoom() + 1);
	}

	container.addEventListener('mousewheel', handleWheelEvent, true);
	container.addEventListener('DOMMouseScroll', handleWheelEvent, true);
	container.addEventListener('dblclick', handleZoom, true);
}

export class GoogleMap extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		onCreateMap: PropTypes.func,
		fixedZoomEnabled: PropTypes.bool,
		mapOptions: PropTypes.shape({
			// copied from https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
			controlSize: PropTypes.number,
			draggable: PropTypes.bool,
			fullscreenControl: PropTypes.bool,
			zoom: PropTypes.number,
			minZoom: PropTypes.number,
			maxZoom: PropTypes.number,
			zoomControl: PropTypes.bool,
			mapTypeId: PropTypes.string,
			streetViewControl: PropTypes.bool,
		}),
	};

	static defaultProps = {
		mapOptions: {},
	};

	constructor(...args) {
		super(...args);

		this.refContainer = this.refContainer.bind(this);
	}

	refContainer(el) {
		this.element = el;

		if (this.element) {
			this.createMap();
		}
	}

	createMap() {
		const { onCreateMap, mapOptions, fixedZoomEnabled } = this.props;

		const fixedZoomOptions = fixedZoomEnabled
			? {
					scrollwheel: false,
					disableDoubleClickZoom: true,
			  }
			: {};

		this.map = new google.maps.Map(this.element, {
			center: { lat: 38.9517, lng: -92.3341 },
			zoom: 15,
			...fixedZoomOptions,
			...mapOptions,
		});

		if (fixedZoomEnabled) {
			applyCustomZoom(this.element, this.map);
		}

		if (onCreateMap) {
			onCreateMap(this.map);
		}
	}

	render() {
		const { className } = this.props;

		return <div className={classNames(className, styles.container)} ref={this.refContainer} />;
	}
}

export default withErrorHandler()(GoogleMap);
