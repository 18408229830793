import _isEmpty from 'lodash/isEmpty';

import moment from "moment";

angular.module('esApiClient').factory('esAssetStatus', function ($log) {
  $log.info("esAssetStatus()");

  function isOutOfLock(currentStatus) {
    var olderThanTwentyMins = dateMoreThanTwentyMinutesAgo(currentStatus.last_location_timestamp);
    var olderThanTwelveHours = dateMoreThanHoursAgo(currentStatus.last_location_timestamp, 12);

    var activeOutOfLock = currentStatus.engine_active === true && olderThanTwentyMins;
    var inactiveOutOfLock = currentStatus.engine_active === false && olderThanTwelveHours;

    var missingLocation = _isEmpty(currentStatus.location)
        || currentStatus.location.latitude === undefined
        || currentStatus.location.longitude === undefined;

    return (activeOutOfLock || inactiveOutOfLock || missingLocation);
  }

  function dateMoreThanTwentyMinutesAgo(reportTimestamp) {
    var now = moment();
    var then = moment(reportTimestamp);
    var diffMins = Math.round(moment.duration(now.diff(then)).asMinutes());

    return diffMins > 20;
  }

  function dateMoreThanHoursAgo(reportTimestamp, hours) {
    var now = moment();
    var then = moment(reportTimestamp);
    var diffHrs = Math.round(moment.duration(now.diff(then)).asHours());

    return diffHrs > hours;
  }

  return {
    'isOutOfLock': isOutOfLock,
  };
});
