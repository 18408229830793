import React, { useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { ENTITY_TYPES, cacheKey, useCompanyLoader } from '@equipmentshare/admin-hooks';

import LocationAutocomplete from '../../js/components/location-autocomplete/location-autocomplete';
import CreateDrawer from '../../js/components/location-drawer/create-drawer/create-drawer';
import companiesService from '@services/companies.service';
import locationsService from '@services/locations.service';

import styles from './company-location-picker.module.css';
import { useQuery } from 'react-query';

const CompanyLocationPicker = ({
	className,
	companyId,
	onChange,
	selectedLocationId,
	includeEsLocations,
	...props
}) => {
	const { data: company } = useCompanyLoader(companyId);
	const {
		data: companyLocations = [],
		isLoading: companyIsLoading,
		refetch: refetchLocations,
	} = useQuery(
		cacheKey(ENTITY_TYPES.LOCATION, 'CompanyLocationPicker', { companyId }),
		() => companiesService.fetchLocations(companyId),
		{ enabled: !!companyId }
	);
	const { data: esLocations = [], isLoading: esLocationsIsLoading } = useQuery(
		cacheKey(ENTITY_TYPES.LOCATION, 'location-picker', { location_type: 1, query: '' }),
		() => locationsService.search({ location_type: 1, query: '' }),
		{ enabled: !!includeEsLocations }
	);

	const isLoading = includeEsLocations ? companyIsLoading || esLocationsIsLoading : companyIsLoading;
	const locations = includeEsLocations ? companyLocations.concat(esLocations) : companyLocations;

	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawerOpen = useCallback(() => setDrawerOpen((v) => !v), [setDrawerOpen]);
	const handleCreateLocation = useCallback(
		(location) => {
			if (onChange) {
				if (location) {
					onChange(location.location_id, location);
					refetchLocations();
				}
				setDrawerOpen(false);
			}
		},
		[onChange, refetchLocations]
	);
	const handleSelectGeofence = useCallback(
		(geofence) => handleCreateLocation(geofence.location),
		[handleCreateLocation]
	);

	return (
		<div className={className}>
			<LocationAutocomplete
				className={className}
				locations={locations}
				selectedLocationId={selectedLocationId}
				onChange={onChange}
				isLoading={isLoading}
				{...props}
			/>
			{company && (
				<Fragment>
					<a className={styles.createLink} onClick={toggleDrawerOpen}>
						New location
					</a>
					<CreateDrawer
						isOpen={drawerOpen}
						userId={company.owner_user_id}
						companyId={company.company_id}
						onCreateLocation={handleCreateLocation}
						onSelectExistingGeofence={handleSelectGeofence}
						onClose={toggleDrawerOpen}
					/>
				</Fragment>
			)}
		</div>
	);
};

CompanyLocationPicker.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	companyId: PropTypes.number,
	onChange: PropTypes.func,
	selectedLocationId: PropTypes.number,
	includeEsLocations: PropTypes.bool,
};

export default CompanyLocationPicker;
