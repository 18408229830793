import map from 'lodash/fp/map';
import moment from "moment";

angular.module('esApiClient').factory('esOpsInspectionsService', function ($http, esApiFactoryV2, esEnv, esCanonicalizer, opsAssetRepository) {
  let canonicalizeItem = esCanonicalizer.canonicalizer('asset_inspection_item');
  let canonicalizeInspection = (inspection) => {
    inspection = esCanonicalizer.canonicalize('asset_inspection', inspection);

    inspection.inspection_items = inspection.inspection_items && inspection.inspection_items.map(canonicalizeItem);
    inspection.created = inspection.created && moment(inspection.created).toDate();
    inspection.completed_timestamp = inspection.completed_timestamp && moment(inspection.completed_timestamp).toDate();

    return inspection;
  };

  const TASK_STATUES = {
    SOFT_DOWN: 1,
    HARD_DOWN: 2
  };
  const STATUS_NAMES = {
    1: "Soft Down",
    2: "Hard Down"
  };

  function _generateEquipmentPhotoAndLocation(equipment) {
    if(!equipment)
      return equipment;

    if (equipment.photo)
      equipment._photoUrl = esEnv.cdnHost + '/uploads/small/' + equipment.photo.filename;

    if (equipment.asset_status && equipment.asset_status.location) {
      let lat = equipment.asset_status.location.latitude;
      let lng = equipment.asset_status.location.longitude;
      equipment.asset_status.location._staticImageUrl = 'https://maps.googleapis.com/maps/api/staticmap?size=336x190&zoom=14&language=en&center='+lat+','+lng+'&markers=color:red%7C'+lat+','+lng+'&key=AIzaSyAWBUtLI4zQPWFsadq6jVQzJ946ykm8Iwk';
      equipment.asset_status.location._locationLink = 'http://maps.google.com/maps?q='+lat+','+lng+'&key=AIzaSyAWBUtLI4zQPWFsadq6jVQzJ946ykm8Iwk'
    }

    return equipment;
  }
  function getAssetEquipment(equipmentId) {
    return esApiFactoryV2.adminGetEquipment(equipmentId)
      .then(equipment => {
        return _generateEquipmentPhotoAndLocation(equipment);
      });
  }

  function assetSearch(assetId) {
    return esApiFactoryV2.adminSearchEquipment({query: assetId})
      .then( assets => {
        if(assets.length > 0) {
          let firstAssetReturned = assets[0];
          return _generateEquipmentPhotoAndLocation(firstAssetReturned);
        }
      });
  }

  function getAsset(assetId) {
    return esApiFactoryV2.opsGetAsset(assetId)
      .then(esCanonicalizer.canonicalizer('asset'))
      .then(asset => opsAssetRepository.getAssetInventoryStatus(asset.asset_id))
      .then(asset => _generateEquipmentPhotoAndLocation(asset));
  }

  function getAssetInspectionStatuses(params) {
    return esApiFactoryV2.getAssetInspectionStatuses(params);
  }

  function getAssetStatus(assetId) {
    return esApiFactoryV2.getAssetStatus(assetId);
  }

  function getInspections(assetId) {
    return esApiFactoryV2.getAssetInspections(assetId)
      .then(map(canonicalizeInspection));
  }

  function getInspection(assetId, inspectionId) {
    return esApiFactoryV2.getAssetInspection(assetId, inspectionId)
      .then(canonicalizeInspection);
  }

  function createInspection(assetId, rentalId) {
    return esApiFactoryV2.createAssetInspection(assetId, rentalId)
    .then(canonicalizeInspection)
    .then((inspection) => opsAssetRepository.getAssetInventoryStatus(assetId)
      .then(() => inspection));
  }

  function createInspectionItem(assetId, inspectionId, item) {
    return esApiFactoryV2.createInspectionItem(assetId, inspectionId, item)
    .then(canonicalizeItem)
    .then((inspection) => opsAssetRepository.getAssetInventoryStatus(assetId)
      .then(() => inspection));
  }

  function getInspectionItem(assetId, inspectionId, itemId) {
    return esApiFactoryV2.getInspectionItem(assetId, inspectionId, itemId)
      .then(canonicalizeItem);
  }

  function patchInspectionItem(assetId, inspectionId, itemId, payload) {
    return esApiFactoryV2.patchInspectionItem(assetId, inspectionId, itemId, payload)
      .then(() => opsAssetRepository.getAssetInventoryStatus(assetId))
      .then(() => getInspectionItem(assetId, inspectionId, itemId));
  }

  function resolveInspectionItem(assetId, inspectionId, inspectionItemId, resolvedNotes) {
    let data = {resolved: true, resolved_notes: resolvedNotes};
    return patchInspectionItem(assetId, inspectionId, inspectionItemId, data);
  }

  function completeInspection(assetId, inspectionId) {
    let data = {completed: true};
    return esApiFactoryV2.patchAssetInspection(assetId, inspectionId, data)
      .then(() => opsAssetRepository.getAssetInventoryStatus(assetId))
      .then(() => getInspection(assetId, inspectionId));
  }

  function getInspectionColor(inspection){
    if(!inspection.completed){
      let openItems = inspection.inspection_items
        .filter(item => !item.resolved);

      if(openItems.find(item => item.asset_inspection_item_fail_type.asset_inspection_item_fail_type_id === TASK_STATUES.HARD_DOWN)){
        return "color-negative";
      }
      if(openItems.find(item => item.asset_inspection_item_fail_type.asset_inspection_item_fail_type_id === TASK_STATUES.SOFT_DOWN)){
        return "color-neutral";
      }

      return "color-neutral";
    }

    return "color-positive";
  }

  function getInspectionStatusName(inspection) {
    if(!inspection.completed){
      let openItems = inspection.inspection_items
        .filter(item => !item.resolved);

      if(openItems.find(item => item.asset_inspection_item_fail_type.asset_inspection_item_fail_type_id === TASK_STATUES.HARD_DOWN)){
        return STATUS_NAMES[TASK_STATUES.HARD_DOWN];
      }
      if(openItems.find(item => item.asset_inspection_item_fail_type.asset_inspection_item_fail_type_id === TASK_STATUES.SOFT_DOWN)){
        return STATUS_NAMES[TASK_STATUES.SOFT_DOWN];
      }

      return "Incomplete";
    }
    return "Completed";
  }

  function getInspectionItemColor(item) {
    if(!item || !item.asset_inspection_item_fail_type)
      return '';

    if(!item.resolved){
      switch(item.asset_inspection_item_fail_type.asset_inspection_item_fail_type_id){
        case TASK_STATUES.SOFT_DOWN:
          return 'color-neutral';
        case TASK_STATUES.HARD_DOWN:
          return 'color-negative';
      }
    }
    else {
      return 'color-positive';
    }
  }

  return {
    getAsset: getAsset,
    getAssetEquipment: getAssetEquipment,
    assetSearch: assetSearch,
    getAssetInspectionStatuses: getAssetInspectionStatuses,
    getAssetStatus,
    getInspections,
    getInspection,
    createInspection,
    createInspectionItem,
    patchInspectionItem,
    resolveInspectionItem,
    completeInspection,
    TASK_STATUES,
    STATUS_NAMES,
    getInspectionColor,
    getInspectionStatusName,
    getInspectionItemColor
  };
});
