import BREAKPOINTS from '../../responsive/breakpoints.json';

export default function getActiveBreakpoints(width = window.innerWidth) {
	return BREAKPOINTS.filter((breakpoint) => {
		return (
			(breakpoint.minWidth ? width >= breakpoint.minWidth : true) &&
			(breakpoint.maxWidth ? width <= breakpoint.maxWidth : true)
		);
	}).map((breakpoint) => breakpoint.name);
}
