import React, { Suspense } from 'react';
import { react2angular } from 'react2angular';
import withGlobalProviders from '@components/with-global-providers';

import ngModule from '../../ng/index.js';
import { Route } from '@equipmentshare/react2angular-router';
import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

const RentalsView = React.lazy(() => import('./rentals-view'));

export const RentalRoutes = () => (
	<Suspense fallback={<LoadingOverlay />}>
		<Route state="home.rentals.search" component={RentalsView} exact />
	</Suspense>
);

ngModule.component('rentalRoutes', react2angular(withGlobalProviders(RentalRoutes)));
