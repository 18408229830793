import angular from 'angular';
import template from './invoice.html';

angular.module("esNgComponents").directive('invoiceComponent', function() {
  return {
    template,
    scope: {
      invoice: '=',
      company: '='
    }
  };
});
