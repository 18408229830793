import React from 'react';

import ErrorBoundary from './error-boundary';

const withErrorHandler =
	(opts = {}) =>
	(WrappedComponent) => {
		const WithErrorHandler = (props) => {
			const { onError } = props;

			return (
				<ErrorBoundary fallbackComponent={opts.fallbackComponent} onError={onError}>
					<WrappedComponent {...props} />
				</ErrorBoundary>
			);
		};

		WithErrorHandler.displayName = `WithErrorHandler(${
			WrappedComponent.displayName || WrappedComponent.name || 'Component'
		})`;
		WithErrorHandler.WrappedComponent = WrappedComponent;

		return WithErrorHandler;
	};

export default withErrorHandler;
