import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EuiButton } from '@equipmentshare/ds2';
import { EuiSimpleFlyout } from '@equipmentshare/es-admin-eui-simple-components';
import { useFlags } from '@equipmentshare/feature-flags';

import styles from './rental-create-form-components.module.css';
import { RATE_TYPE_IDS, RATE_TYPE_ORDERING_WEIGHTS } from '@const/rate-types';
import { renderRateTypeName } from '../rental-create-form/rental-utils';

const RatesDrawer = ({ isOpen = false, rates = [], equipmentClass = {}, branch = {}, setIsOpen, onRateSelect }) => {
	const renderRate = (rate) => (
		<div className={styles.rate} key={rate.rate_type_id}>
			<p className={styles.rateHeadingDetail} data-testid="rate-header">
				{renderRateTypeName(rate)}
			</p>
			<div className={styles.rateRow}>
				<div className={styles.rateDetail}>
					<p className={styles.heading}>Hourly</p>
					<p className={styles.headingDetail}>
						{rate.price_per_hour ? `$${rate.price_per_hour?.toLocaleString()}` : '--'}
					</p>
				</div>
				<div className={styles.rateDetail}>
					<p className={styles.heading}>Daily</p>
					<p className={styles.headingDetail}>
						{rate.price_per_day ? `$${rate.price_per_day?.toLocaleString()}` : '--'}
					</p>
				</div>
				<div className={styles.rateDetail}>
					<p className={styles.heading}>Weekly</p>
					<p className={styles.headingDetail}>
						{rate.price_per_week ? `$${rate.price_per_week?.toLocaleString()}` : '--'}
					</p>
				</div>
				<div className={styles.rateDetail}>
					<p className={styles.heading}>4-Week</p>
					<p className={styles.headingDetail}>
						{rate.price_per_month ? `$${rate.price_per_month?.toLocaleString()}` : '--'}
					</p>
				</div>
				<div className={styles.rateSelectButton}>
					<EuiButton disabled={!rate.branch_id} onClick={() => onRateSelect(rate)}>
						Use rate
					</EuiButton>
				</div>
			</div>
		</div>
	);

	const sortOrder = (rate, nextRate) => {
		/* Janky Sort function that swaps the rate type ids for floor/book to match business logic/order
			 Location -> Company -> Benchmark -> Book */
		let rateToCompare = rate.rate_type_id;
		let nextRateToCompare = nextRate.rate_type_id;
		if (rateToCompare === RATE_TYPE_IDS.BENCHMARK_RATE_TYPE_ID) {
			rateToCompare = RATE_TYPE_IDS.BOOK_RATE_TYPE_ID;
		} else if (nextRate.rate_type_id === RATE_TYPE_IDS.BENCHMARK_RATE_TYPE_ID) {
			nextRateToCompare = RATE_TYPE_IDS.BOOK_RATE_TYPE_ID;
		}
		return nextRateToCompare - rateToCompare;
	};

	const sortOrderWithAdvertisedRate = (rate, nextRate) => {
		// sort rates using ordering weights, with larger values appearing earlier in the sorted array
		let rateToCompare = rate.rate_type_id;
		let nextRateToCompare = nextRate.rate_type_id;

		return RATE_TYPE_ORDERING_WEIGHTS[nextRateToCompare] - RATE_TYPE_ORDERING_WEIGHTS[rateToCompare];
	};

	if (!isOpen) {
		return null;
	}

	return (
		<EuiSimpleFlyout onClose={() => setIsOpen(false)} title="Additional Rates" size="35%" paddingSize="m">
			<div className={styles.rateSection}>
				<p className={styles.heading}>Market</p>
				<p className={styles.headingDetail}>{_get(branch, 'name', '--')}</p>
			</div>
			<div className={styles.rateSection}>
				<p className={styles.heading}>Equipment Class</p>
				<p className={styles.headingDetail}>{_get(equipmentClass, 'name', '--')}</p>
			</div>
			<div className={styles.ratesContainer}>
				{/* Filter out Floor rates */}
				{rates.sort(sortOrderWithAdvertisedRate).map((rate) => renderRate(rate))}
			</div>
		</EuiSimpleFlyout>
	);
};

RatesDrawer.propTypes = {
	branch: PropTypes.object,
	equipmentClass: PropTypes.object,
	isOpen: PropTypes.bool,
	onRateSelect: PropTypes.func,
	rates: PropTypes.array,
	setIsOpen: PropTypes.func,
};

export default RatesDrawer;
