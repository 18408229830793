import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import checkboxStyles from "./checkbox.module.css";

/**
 * Controlled Checkbox: the parent controls the checked state, and passes in an onChange method, if desired.
 *
 * Controlled components are preferred by React so, `<Checkbox/>` would be used in the majority of cases where a checkbox is needed.
 * `<UncontrolledCheckbox/>` can be used in cases where something closer to a native checkbox is a better choice.
 */
export default class Checkbox extends PureComponent {
  constructor( props ) {
    super( props );

    this.handleChange = this.handleChange.bind( this );
    this.handleClick = this.handleClick.bind( this );
  }

  UNSAFE_componentWillMount() {
    this.id = "cb" + Math.round( Math.random() * 10000 );
  }

  renderRequiredElement() {
    if ( this.props.required ){
      return ( <span className={checkboxStyles.required}> {this.props.requiredMsg || "(Required)"}</span> );
    }

    return null;
  }

  handleChange( event ) {
    if ( typeof this.props.onChange === "function" ) {
      this.props.onChange( event );
    }
  }

  handleClick( event ) {
    if ( typeof this.props.onClick === "function" ) {
      this.props.onClick( event );
    }
  }

  render() {
    const {
      checked,
      className,
      disabled,
      labelText,
      name,
      partiallyChecked,
      uppercase,
      value
    } = this.props;

    return (
      <div className={classNames( className )}>
        <input
          checked={checked}
          className={checkboxStyles.checkboxInput}
          disabled={disabled}
          id={this.id}
          name={name}
          onChange={this.handleChange}
          onClick={this.handleClick}
          type="checkbox"
          value={value}
        />
        <label
          className={classNames(
            checkboxStyles.checkboxLabel,
            {
              [checkboxStyles.partiallyChecked]: partiallyChecked,
              [checkboxStyles.uppercase]: uppercase
            }
          )}
          htmlFor={this.id}
        >
          <div className={checkboxStyles.checkboxVisual} />
          {labelText}
          {this.renderRequiredElement()}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  /** toggling checked/unchecked is handled by the parent */
  checked: PropTypes.bool,
  /** custom class name used to overwrite checkbox styles */
  className: PropTypes.string,
  /** sets the disabled attribute */
  disabled: PropTypes.bool,
  /** checkbox label */
  labelText: PropTypes.node,
  /** name attribute, can be used to create a checkbox group */
  name: PropTypes.string,
  /** Checkbox change event */
  onChange: PropTypes.func,
  /** Checkbox click event, used to toggle parent's checked state */
  onClick: PropTypes.func,
  /** toggles partially checked state */
  partiallyChecked: PropTypes.bool,
  /** applies a "required" label, consumer must enforce required behavior */
  required: PropTypes.bool,
  /** replaces the default "required" label with a custom message */
  requiredMsg: PropTypes.string,
  /** uppercase label */
  uppercase: PropTypes.bool,
  /** value attribute */
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
  ] ),
};

Checkbox.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
  labelText: null,
  name: null,
  onChange: null,
  onClick: null,
  partiallyChecked: false,
  required: false,
  requiredMsg: null,
  uppercase: false,
  value: ""
};
