import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ButtonGroup from "../button-group/button-group.js";
import Button, { buttonTypes } from "../button/button.js";
import ModalCreator from "../modal-dialog/modal-creator.js";

import dialogStyles from "./confirmation-dialog.module.css";

export default function ConfirmationDialog ( { cancelText, children, content, title, okText, okType, onCancel, onOk, contentPadding, showCornerCloseButton } ) {
  return (
    <ModalCreator
      containerClass={dialogStyles.dialog}
      contentPadding={false}
      handleClose={onCancel}
      isOpen
      renderHeader
      shouldCloseOnOverlayClick={false}
      showCornerCloseButton={showCornerCloseButton}
      title={title}
    >
      <div className={contentPadding ? classNames( dialogStyles.body, dialogStyles.contentPadding ) : dialogStyles.body}>
        { content && <span>{content}</span> }
        { children }
      </div>
      <footer className={dialogStyles.footer}>
        <ButtonGroup className={dialogStyles.contentPadding} inlineRight>
          <Button buttonClassName={dialogStyles.cancelButton} buttonType="outline" label={cancelText} onClick={onCancel} />
          <Button buttonType={okType} label={okText} onClick={onOk} />
        </ButtonGroup>
      </footer>
    </ModalCreator>
  );
}

ConfirmationDialog.propTypes = {
  /** Override the default cancel button text */
  cancelText: PropTypes.string,
  /** Provide child components for rendering */
  children: PropTypes.node,
  /** The content of the dialog */
  content: PropTypes.string,
  /** Override the default contentPadding of underlying ModalCreator */
  contentPadding: PropTypes.bool,
  /** Override the default ok button text */
  okText: PropTypes.string,
  /** The type of button used for the OK/Confirm - should be one of the available button types see: https://styles.equipmentshare.com/#button */
  okType: PropTypes.oneOf( buttonTypes ),
  /** method to fire off when cancel is clicked */
  onCancel: PropTypes.func.isRequired,
  /** method to fire off when ok is clicked */
  onOk: PropTypes.func.isRequired,
  /** Override the default showCornerCloseButton of underlying ModalCreator */
  showCornerCloseButton: PropTypes.bool,
  /** The title of the confirmation dialog */
  title: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  cancelText: "Cancel",
  children: null,
  content: null,
  okText: "Ok",
  okType: "primary",
  title: null,
  contentPadding: true,
  showCornerCloseButton: false,
};
