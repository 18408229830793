import ngModule from '../../../ng/index.js';
import _debounce from 'lodash/debounce';
import _round from 'lodash/round';

/* global google */

function simpleMapService() {
	const MARKER_EVENTS = {
		onClick: 'click',
	};

	function createMap(mapData, element = document.getElementById('simpleMap')) {
		const noMapData = {
			zoom: 13,
			center: { lat: 38.9517, lng: -92.3341 },
			mapTypeId: google.maps.MapTypeId.ROADMAP,
		};

		const map = new google.maps.Map(element, {
			zoom: mapData.zoom || noMapData.zoom,
			center: mapData.center ? { lat: mapData.center.lat, lng: mapData.center.lng } : noMapData.center,
			mapTypeId: mapData.topography ? getMapTypeId(mapData.topography) : noMapData.MapTypeId,
			disableDefaultUI: mapData.hideControls || false,
		});

		map.markers = [];
		map.mapData = mapData;

		updateMap(map, mapData);
		centerMap(map, mapData);

		google.maps.event.addDomListener(
			window,
			'resize',
			_debounce(() => {
				centerMap(map, map.mapData);
			}, 250)
		);

		return map;
	}

	function updateMap(map, mapData) {
		map.mapData = mapData;
		let pointsPositionChanged = false;
		const previousMarkerCount = map.markers.length;

		mapData.points.forEach((point, i) => {
			let marker = map.markers[i];

			if (!marker) {
				marker = new google.maps.Marker({
					map,
					optimized: false,
				});

				map.markers.push(marker);
			}

			marker.point = point;

			// check to see if the marker has moved, but only down to the 4th decimal
			if (
				!marker.position ||
				_round(marker.position.lat(), 4) !== _round(point.lat, 4) ||
				_round(marker.position.lng(), 4) !== _round(point.lng, 4)
			) {
				pointsPositionChanged = true;
			}

			marker.setOpacity(point.opacity || 1);
			marker.setAnimation(point.animation);
			marker.setIcon(point.icon);
			if (point.label !== undefined) {
				marker.setLabel(point.label);
			} else {
				marker.setLabel(null);
			}
			if (point.title !== undefined) {
				marker.setTitle(String(point.title));
			} else {
				marker.setTitle(null);
			}
			marker.setPosition({ lat: point.lat, lng: point.lng });
			marker.setZIndex(point.zIndex);

			if (!marker._listeners) {
				marker._listeners = {};
			}

			for (const propName in MARKER_EVENTS) {
				const eventName = MARKER_EVENTS[propName];
				const listener = marker._listeners[eventName];

				if (point[propName] && (!listener || listener.cache !== point[propName])) {
					if (listener) {
						listener.remove();
						delete marker._listeners[eventName];
					}

					marker._listeners[eventName] = marker.addListener(eventName, () => {
						if (marker.point[propName]) {
							marker.point[propName](marker.point);
						}
					});
					// marker._listeners[eventName] = marker.addListener( eventName, point[propName].bind( point, point, marker ) );
					marker._listeners[eventName].cache = point[propName];
				} else if (!point[propName] && listener) {
					listener.remove();
					delete marker._listeners[eventName];
				}
			}
		});

		if (pointsPositionChanged || mapData.points.length !== previousMarkerCount) {
			centerMap(map, mapData);
		}

		while (map.markers.length > mapData.points.length) {
			const marker = map.markers.pop();

			marker.setMap(null);
			for (const i in marker._listeners) {
				marker._listeners[i].remove();
			}
		}

		return map;
	}

	function centerMap(map, mapData) {
		if (mapData.points && mapData.points.length > 1) {
			const bounds = new google.maps.LatLngBounds();

			mapData.points.forEach((point) => bounds.extend(point));

			const boundsJSON = bounds.toJSON();

			if (boundsJSON.north === boundsJSON.south && boundsJSON.east === boundsJSON.west) {
				map.setCenter(bounds.getCenter());
			} else {
				map.fitBounds(bounds);
			}
		} else if (mapData.points && mapData.points.length === 1) {
			map.setCenter(mapData.points[0]);
		} else if (mapData.center) {
			map.setCenter(map.center);
		}

		return map;
	}

	function triggerResize(map) {
		google.maps.event.trigger(map, 'resize');
	}

	function getMapTypeId(topography) {
		if (!topography) {
			return;
		}

		if (topography === 'satellite') {
			return google.maps.MapTypeId.HYBRID;
		}

		if (topography === 'street') {
			return google.maps.MapTypeId.ROADMAP;
		}
	}

	return {
		createMap,
		updateMap,
		centerMap,
		triggerResize,
	};
}

ngModule.factory('simpleMapService', simpleMapService);
