import React from 'react';
import PropTypes from 'prop-types';

import EquipmentClassPicker from '@components/equipment-class-picker';

import SectionWrapper from './section-wrapper';

import styles from '../rental-create-form-components/rental-create-form-components.module.css';

const EquipmentClassSelect = ({ equipmentClassId, onChange, required = false, ...rest }) => (
	<SectionWrapper header="Equipment Class" subHeader="(Select 'Misc Equipment' if unknown)">
		<EquipmentClassPicker
			className={styles.extendedInput}
			onChange={onChange}
			name="equipmentClassId"
			selectedEquipmentClassId={equipmentClassId}
			showMiscellaneousFirst={true}
			required={required}
			{...rest}
		/>
	</SectionWrapper>
);

EquipmentClassSelect.propTypes = {
	equipmentClassId: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
};

export default EquipmentClassSelect;
