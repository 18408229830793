import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "../icon/icon.js";

import dialogStyles from "./modal-dialog.module.css";

/**
 * Modal Dialog is used by ModalCreator to wrap around the dialog content
 * @private
 * @ignore
 */
export default class ModalDialog extends PureComponent {
  constructor( props ) {
    super( props );

    this.handleClose = this.handleClose.bind( this );
    this.handleCloseOnOverlayClick = this.handleCloseOnOverlayClick.bind( this );
  }

  handleClose() {
    if( this.props.handleClose ){
      this.props.handleClose();
    }
  }

  handleCloseOnOverlayClick() {
    if ( this.props.shouldCloseOnOverlayClick ) {
      this.handleClose();
    }
  }

  render() {
    const {
      children,
      containerClass,
      contentPadding,
      customStyles,
      outerContainerClass,
      renderHeader,
      title,
      showCornerCloseButton
    } = this.props;

    return (
      <div className={classNames( dialogStyles.dialog, `${outerContainerClass}` )}>
        <div className={dialogStyles.overlay} onClick={this.handleCloseOnOverlayClick} />
        <div className={classNames( dialogStyles.container, `${containerClass}` )}>
          { renderHeader &&
            <header className={dialogStyles.header}>
              <div className={dialogStyles.title}>{title}</div>
              { showCornerCloseButton &&
                <button className={dialogStyles.close} onClick={this.handleClose}>
                  <Icon name="farTimes" />
                </button>
              }
            </header>
          }
          <div
            className={classNames(
              dialogStyles.body,
              {
                [ dialogStyles.contentPadding ]: contentPadding,
              }
            )}
            style={customStyles}
          >
            { children }
          </div>
        </div>
      </div>
    );
  }
}

ModalDialog.propTypes = {
  children: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ] ).isRequired,
  containerClass: PropTypes.string,
  contentPadding: PropTypes.bool,
  customStyles: PropTypes.object,
  handleClose: PropTypes.func,
  outerContainerClass: PropTypes.string,
  renderHeader: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  showCornerCloseButton: PropTypes.bool,
  title: PropTypes.string
};

ModalDialog.defaultProps = {
  containerClass: "",
  contentPadding: true,
  customStyles: {},
  handleClose: null,
  outerContainerClass: "",
  renderHeader: true,
  title: "",
  shouldCloseOnOverlayClick: true,
  showCornerCloseButton: true,
};
