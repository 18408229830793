import { useMutation, useQueryClient } from 'react-query';
import rentalsService from '@services/rentals.service';
import errorHandlerService from '@services/error-handler.service';

const useRentalCancellation = (mutationOptions = {}) => {
	const queryClient = useQueryClient();

	return useMutation(
		async ({ rentalId, cancelType, cancelNote }) => {
			try {
				const response = await rentalsService.cancelRental(rentalId, cancelType, cancelNote);
				return response;
			} catch (error) {
				console.error('Request failed: ', error);
				throw error;
			}
		},
		{
			onError: (e) => errorHandlerService.genericErrorHandler(e, 'failed to cancel rental'),
			onSuccess: () => {
				queryClient.invalidateQueries(['rentals']);
			},
			...mutationOptions,
		}
	);
};

export default useRentalCancellation;
