import ngModule from '../../ng/ng-module.js';
import { react2angular } from 'react2angular';
import LocationDisplayMap from './location-display-map.js';
import withGlobalProviders from '@components/with-global-providers';

ngModule.component(
	'reactLocationDisplayMap',
	react2angular(
		withGlobalProviders(LocationDisplayMap),
		Object.keys(LocationDisplayMap.propTypes).concat('forceUpdate')
	)
);
