import ngModule from '../../../ng/index.js';
import template from './rental-edit-component.html';

ngModule.directive('rentalEditComponent', () => {
	return {
		template,
		scope: {
			rental: '=',
			selectedDelivery: '=?',
			selectedTab: '=',
			onRentalChange: '=',
			hideTabs: '<',
		},
		controller: 'RentalEditComponentCtrl',
	};
});
