import React, { useState, useEffect } from 'react';
import {
	EuiButton,
	EuiConfirmModal,
	EuiFormRow,
	EuiFieldText,
	EuiFlexItem,
	EuiFlexGroup,
	EuiText,
	EuiSwitch,
} from '@elastic/eui';
import type { FunctionComponent } from 'react';

import { BranchPicker } from '@equipmentshare/es-admin-components';

const getOrDeriveRentalBranch = (inFleet: boolean, rentalBranch: string, inventoryBranch: string): string => {
	// if an asset has a rental branch, regardless if inRentalFleet is set, its returned
	if (rentalBranch !== '') {
		return rentalBranch;
	}

	return inFleet ? inventoryBranch : '';
};

const getOrDeriveRentalBranchId = (
	inRentalFleet: boolean,
	rentalId: number | undefined,
	inventoryId: number
): number | undefined => {
	// did we pull out of rental fleet?
	if (!inRentalFleet) {
		return undefined;
	}

	return inRentalFleet && rentalId == null ? inventoryId : rentalId;
};

const getOrDeriveRentalBranchName = (inRentalFleet: boolean, rentalName: string, inventoryName: string): string => {
	if (inRentalFleet) {
		return rentalName !== '' ? rentalName : inventoryName;
	}
	return 'N/A';
};

interface NonNullableBranch {
	id: number;
	name: string;
}

interface NullableBranch {
	id: number | undefined;
	name: string;
}

// per the assets schema, rental_branch_id and service_branch_id are not null
interface SettingsModalOwnProps {
	branches: {
		inventoryBranch: NonNullableBranch;
		rentalBranch: NullableBranch;
		serviceBranch: NullableBranch;
	};
	companyId: number;
	isFleetOperations: boolean;
	setIsModalVisible: (arg0: boolean) => void;
	onSettingsModalConfirm: (rentalBranchId: number | undefined, serviceBranchId: number | undefined) => void;
}

const SettingsModalForm: FunctionComponent<SettingsModalOwnProps> = ({
	branches: {
		inventoryBranch: { id: inventoryId, name: inventoryName },
		rentalBranch: { id: rentalId, name: rentalName },
		serviceBranch: { id: serviceId, name: serviceName },
	},
	companyId,
	isFleetOperations,
	setIsModalVisible,
	onSettingsModalConfirm,
}) => {
	const [inRentalFleet, setInRentalFleet] = useState<boolean>(true);
	const [serviceBranch, setServiceBranch] = useState<number | undefined>(serviceId);

	useEffect(() => {
		setInRentalFleet(rentalName !== '');
	}, []);

	return (
		<EuiConfirmModal
			title="Advanced Settings"
			style={{ minWidth: '500px' }}
			onCancel={() => setIsModalVisible(false)}
			onConfirm={() => {
				setIsModalVisible(false);
				onSettingsModalConfirm(getOrDeriveRentalBranchId(inRentalFleet, rentalId, inventoryId), serviceBranch);
			}}
			confirmButtonText="Save"
			cancelButtonText="Cancel"
			buttonColor="primary"
		>
			<EuiFormRow label="Inventory Branch" fullWidth>
				<EuiText size="s">{inventoryName}</EuiText>
			</EuiFormRow>
			<EuiFormRow label="Rental Service Provider" fullWidth>
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiText size="s">{getOrDeriveRentalBranchName(inRentalFleet, rentalName, inventoryName)}</EuiText>
					</EuiFlexItem>
					<EuiFlexItem>
						{isFleetOperations && (
							<EuiSwitch
								label="Rental Fleet"
								checked={inRentalFleet}
								onChange={() => setInRentalFleet(!inRentalFleet)}
							/>
						)}
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFormRow>

			<EuiFormRow label="Maintenance Service Provider" fullWidth>
				<BranchPicker
					selectedBranchId={serviceBranch}
					selectedBranchCompanyId={companyId} // this will allow fetching the service branch if the asset is outside of EquipmentShare (1854) */}
					placeholder="No Maintenance Service Provider"
					onChange={setServiceBranch}
					isClearable={isFleetOperations}
					fullWidth
				/>
			</EuiFormRow>
			<EuiFormRow>
				<EuiText>
					Selections here will not change the ownership of the asset. To change ownership, the asset will need to be
					transferred.
				</EuiText>
			</EuiFormRow>
		</EuiConfirmModal>
	);
};

export default SettingsModalForm;
