import angular from 'angular';
import ngModule from '../../ng/index.js';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';
import { parse } from 'json2csv';

import { heapEvent } from '@equipmentshare/heaper';

import { downloadCsvFile } from '@helpers/csv';
import { getAssetStatusColor } from '@const/asset-status-colors';
import toastService from '@services/toast.service.js';
import esApi from '@services/es-api.service';
import styles from './asset-home.module.css';

function AssetHomeCtrl($scope, $stateParams, $state, esApiFactoryV2, $mdSidenav, $timeout, $q, esAdminRentalService) {
	const controller = this;

	$scope.styles = styles;

	function stateParamsHaveValue() {
		if (Object.keys($stateParams).length > 0) {
			const propertiesWithValue = _filter(Object.keys($stateParams), (key) => {
				return angular.isDefined($stateParams[key]);
			});

			return propertiesWithValue.length > 0;
		}

		return null;
	}

	$scope.sort = {
		orderBy: 'asset_id',
		reverse: false,
	};

	$scope.assetDetailsOpen = false;

	$scope.init = () => {
		$scope.emptyResults = true;
		controller.setEmptyFilters();
		$scope.markets = esAdminRentalService.markets;
		$scope.hasSearched = false;
		$scope.isEmptyState = true;

		if (stateParamsHaveValue()) {
			$scope.filters.tracker_id = $stateParams.trackerId;
			$scope.filters.serial_number = $stateParams.serialNumber;
			$scope.filters.query = $stateParams.query;

			// resolve equipment class name by equipment_class_id and populate the picker
			if ($stateParams.equipmentClassId) {
				esApiFactoryV2.getEquipmentClass($stateParams.equipmentClassId).then((equipmentClass) => {
					$scope.filters.equipment_class_id = equipmentClass.name;
				});
			}

			$scope.search();
		}
	};

	function getAssets(isSearchEquipment, isSearchVehicles, filters = null) {
		if (!isSearchEquipment && !isSearchVehicles) {
			return [];
		}

		// build the query string
		let include_param =
			'{keypad tracker {tracker_type} inventory_status equipment_make equipment_model {first_equipment_class} rental_branch company}';
		let query_string = `?page=1&size=${filters?.limit ?? 100}&include=${include_param}`;

		// the old search to /admin/equipment returned assets that weren't vehicles -
		// not neccessarily assets that were equipment. I've decided in the interest of time
		// to not fix that functionality. That choice explains the logic below when setting,
		// *or not setting* the asset_id query param

		// if only vehicles is checked, just search vehicles
		if (isSearchVehicles && !isSearchEquipment) {
			query_string += '&asset_type_id=2';
		}
		// if only equipment is checked, search for everything that isn't a vehicle
		if (!isSearchVehicles && isSearchEquipment) {
			query_string += '&asset_type_id=1&asset_type_id=3&asset_type_id=4&asset_type_id=5&asset_type_id=6';
		}
		// this leaves the case of both boxes being checked. if so, don't specify the asset type id

		if (filters?.equipment_class_id) {
			query_string += `&equipment_class_id=${filters.equipment_class_id}`;
		}
		if (filters?.tracker_id) {
			query_string += `&tracker_id=${filters.tracker_id}`;
		}
		if (filters?.serial_number) {
			query_string += `&serial_number=${encodeURIComponent(filters.serial_number)}`;
		}
		if (filters?.vin) {
			query_string += `&vin=${encodeURIComponent(filters.vin)}`;
		}
		if (filters?.query) {
			query_string += `&query_string=${encodeURIComponent(filters.query)}`;
		}
		if (filters?.rental_branch_id) {
			query_string += `&rented_from_branch_id=${filters.rental_branch_id}`;
		}
		if (filters?.company_id) {
			query_string += `&company_id=${filters.company_id}`;
		}
		if (filters?.equipment_make_id) {
			query_string += `&equipment_make_id=${filters.equipment_make_id}`;
		}
		if (filters?.equipment_model_id) {
			query_string += `&equipment_model_id=${filters.equipment_model_id}`;
		}

		return esApi.request(esApi.url`/v2/assets` + query_string);
	}

	$scope.search = () => {
		$scope.gettingAssetData = true;
		controller.setAssets(true);
		$scope.hasSearched = true;
	};

	$scope.clearFilters = () => {
		controller.setEmptyFilters();
	};

	$scope.setAssetToEdit = (asset) => {
		$scope.assetToEdit = asset.asset_id;
		$scope.assetDetailsOpen = true;
	};

	$scope.closeDrawer = () => {
		$scope.assetToEdit = null;
		$scope.assetDetailsOpen = false;
		$scope.$digest();
	};

	const createCsvData = () => {
		const getValueStr = (obj, path) => _get(obj, path) || '--';

		return $scope.assets.map((item) => ({
			ID: getValueStr(item, 'asset_id'),
			Make: getValueStr(item, 'make.name'),
			Model: getValueStr(item, 'equipment_model.name'),
			Class: getValueStr(item, 'equipment_model.equipment_classes[0].name'),
			'Rental Branch': getValueStr(item, 'rental_branch.name'),
			Company: `${getValueStr(item, 'company.name')} (${getValueStr(item, 'company.company_id')} )`,
			'Custom Name': getValueStr(item, 'custom_name'),
			Type: getValueStr(item, 'name'),
			Keypad: _get(item, 'features.keypad') || '--',
			RapidRents: _get(item, 'features.rapidRent') || '--',
			Tracker: _get(item, 'features.tracker') || '--',
			Status: getValueStr(item, 'inventory_status'),
		}));
	};

	$scope.downloadCsv = () => {
		try {
			const filename = `assets_${moment().format('MM-DD-YYYY_hhmm')}.csv`;
			const fields = [
				'ID',
				'Make',
				'Model',
				'Class',
				'Rental Branch',
				'Company',
				'Custom Name',
				'Type',
				'Keypad',
				'RapidRents',
				'Tracker',
				'Status',
			];
			const csvString = parse(createCsvData(), { fields });
			return downloadCsvFile(csvString, filename);
		} catch (err) {
			toastService.showError(
				'There was an issue generating the CSV file. If the issue persists please contact engineering.'
			);
		}
	};

	controller.setEmptyFilters = () => {
		$scope.filters = {
			equipment_class_id: null,
			tracker_id: null,
			serial_number: null,
			query: null,
		};
		$scope.isSearchEquipment = true;
		$scope.isSearchVehicles = true;
	};

	controller.setAssets = () => {
		// copy the filters to be applied to the api call; we're going to modify this programatically
		const _filters = angular.copy($scope.filters);

		// set the upper limit of how many items can be returned per api call
		$scope.maxResults = 500;
		_filters.limit = $scope.maxResults;

		// $scope.filters stores the entire equipment_class object
		// only the id is used for the actual query
		if (_filters.equipment_class_id) {
			_filters.equipment_class_id = _filters.equipment_class_id.equipment_class_id;
		} else if ($stateParams.equipmentClassId) {
			_filters.equipment_class_id = parseInt($stateParams.equipmentClassId, 10);
		}

		// force at least 1 filter to be applied
		const filterExistsWithValue = (filterList, filters) => {
			return filterList.some((filter) => {
				const hasKey = Object.keys(filters).indexOf(filter) >= 0;
				if (hasKey) {
					return filters[filter];
				}

				return false;
			});
		};

		const availableFilters = [
			'company_id',
			'equipment_make_id',
			'equipment_model_id',
			'query',
			'rental_branch_id',
			'serial_number',
			'tracker_id',
			'equipment_class_id',
			'vin',
		];

		const hasFilters = filterExistsWithValue(availableFilters, _filters);
		if (!hasFilters) {
			$scope.gettingAssetData = false;
			return toastService.showError('At least one filter must be applied.');
		}

		// heap
		heapEvent('Admin - Assests - Search - Assests', {
			...$scope.filters,
			equipment_class_id: $scope.filters.equipment_class_id?.equipment_class_id,
		});

		getAssets($scope.isSearchEquipment, $scope.isSearchVehicles, _filters).then((assets) => {
			$scope.$apply(function () {
				// map assets
				assets = assets.length
					? assets.map((asset) => {
							const features = _pickBy({
								keypad: _get(asset, 'keypad', null) || null ? 'Keypad' : null,
								rapidRent: asset.available_to_rapid_rent ? 'Rapid Rents' : null,
								tracker: _get(asset, 'tracker.tracker_type.name') || null,
							});
							const filterKeys = Object.values(features).join();
							const asset_status_color = getAssetStatusColor(asset.inventory_status?.value, 'hex');

							return {
								features,
								filterKeys,
								asset_status_color,
								...asset,
							};
					  })
					: [];

				// update scope vars
				$scope.equipment = assets.filter((asset) => asset.asset_type_id == 1);
				$scope.vehicles = assets.filter((asset) => asset.asset_type_id == 2);
				$scope.other = assets.filter((asset) => asset.asset_type_id != 1 && asset.asset_type_id != 2);
				$scope.max_returned = assets.length === $scope.maxResults;
				$scope.gettingAssetData = false;
				$scope.assets = assets;
			});
		});
	};

	$scope.init();
}

ngModule.controller('AssetHomeCtrl', AssetHomeCtrl);
