import map from 'lodash/fp/map';

import moment from "moment";

angular.module('esApiClient').factory('esCompanyService', function ($q, esApiFactoryV2, esCanonicalizer) {
  let service;
  let canonicalizer = esCanonicalizer.canonicalizer('company');
  let cachedCompanies = [];

  const companyDocumentTypes = {
    RENTAL_FLOATER: 1,
    TAX_EXEMPT: 2,
    GENERAL_LIABILITY: 3
  };
  // @TODO return the amount of days from the backend
  const companyNetTermsDaysMap = {
    1: {
      "net_terms_id": 1,
      "name": "Cash on Delivery",
      "days": 0
    },
    2: {
      "net_terms_id": 2,
      "name": "Net 30",
      "days": 30
    },
    3: {
      "net_terms_id": 3,
      "name": "Net 45",
      "days": 45
    },
    4: {
      "net_terms_id": 4,
      "name": "Net 15",
      "days": 15
    },
    5: {
      "net_terms_id": 5,
      "name": "Net 40",
      "days": 40
    },
    6: {
      "net_terms_id": 6,
      "name": "Net 60",
      "days": 60
    },
    7: {
      "net_terms_id": 7,
      "name": "Net 90",
      "days": 90
    },
    8: {
      "net_terms_id": 8,
      "name": "Net 120",
      "days": 120
    }
  };


  function parseCurrency(company) {
    if(company.credit_limit)
      company.credit_limit = parseFloat(company.credit_limit);

    return company;
  }

  function getCompany(companyId, ignoreCache = false){
    if(cachedCompanies.includes(companyId) && !ignoreCache){
      service.company = canonicalizer(companyId);
      return $q.resolve(canonicalizer(companyId));
    }
    else return esApiFactoryV2.getCompany(companyId)
      .then(parseCurrency)
      .then(canonicalizer)
      .then(company => {
        cachedCompanies.push(company.company_id);
        service.company = company;
        return company;
      });
  }

  function getCompanyUsers(companyId) {
    return esApiFactoryV2.getCompaniesUsers(companyId)
      .then(parseCurrency)
      .then(esCanonicalizer.canonicalizer('user'));
  }

  function patchCompany(companyId, payload) {
    return esApiFactoryV2.adminPatchCompany(companyId, payload)
      .then(parseCurrency)
      .then(() => getCompany(companyId, true));
  }

  function searchCompanies(query) {
    return esApiFactoryV2.adminSearchCompanies(query)
      .then(map(canonicalizer));
  }
  function getDocumentTypes() {
    return esApiFactoryV2.getCompaniesDocumentTypes()
      .then(map(esCanonicalizer.canonicalizer('company_document_type')));
  }

  function getDocuments() {
    return esApiFactoryV2.getCompaniesDocuments();
  }

  function getCompanyDocuments(companyId) {
    return esApiFactoryV2.getCompanyDocuments(companyId)
      .then(map(esCanonicalizer.canonicalizer('company_document')));
  }

  function isCompanyTaxable(companyId) {
    function isValidDocument(doc) {
      return !doc.voided &&
        (!doc.valid_from || moment().isAfter(doc.valid_from)) &&
        (!doc.valid_until || moment().isBefore(doc.valid_until));
    }
    function isValidTaxExemption(doc) {
      return isValidDocument(doc) && doc.document_type.company_document_type_id === companyDocumentTypes.TAX_EXEMPT;
    }
    return service.getCompanyDocuments(companyId).then( documents => {
      for(let document of documents) {
        if(isValidTaxExemption(document)) {
          return false;
        }
      }
      return true;
    });
  }
  function getLatestNonVoidedRentalFloater(companyId) {
    return service.getCompanyDocuments(companyId)
      .then( docs => docs.filter( doc => !doc.voided && doc.document_type.company_document_type_id === companyDocumentTypes.RENTAL_FLOATER )
        .sort( ( a, b) => moment(b.valid_until).diff(moment(a.valid_until)) )[0] //newset to oldest
      );
  }
  function checkRentalFloaterExpiration(companyId) {
    return getLatestNonVoidedRentalFloater(companyId)
      .then( latestDoc => {
        const EXPIRING_SOON_DAYS_RANGE = 45;
        const EXPIRING_SOON = "expired_soon";
        const EXPIRED = "expired";

        if(latestDoc){
          let expiringSoon = !latestDoc.valid_until.isSameOrAfter( moment().add( EXPIRING_SOON_DAYS_RANGE, "days" ) );
          let expired = latestDoc.valid_until.isBefore( moment() );

          if ( expiringSoon ){
            return EXPIRING_SOON;
          }
          else if ( expired ) {
            return EXPIRED;
          }
        }

        return null;
      } );
  }
  function addCompanyDocument(companyId, payload) {
    return esApiFactoryV2.addCompanyDocument(companyId, payload)
  }

  function getCompanyDocument(companyDocumentId) {
    return esApiFactoryV2.getCompanyDocument(companyDocumentId)
      .then(esCanonicalizer.canonicalizer('company_document'))
  }

  function updateCompanyDocument(companyDocumentId, payload) {
    return esApiFactoryV2.updateCompanyDocument(companyDocumentId, payload)
      .then(() => esApiFactoryV2.getCompanyDocument(companyDocumentId));
  }

  function getNetTerms() {
    return esApiFactoryV2.adminGetNetTerms()
      .then(map(esCanonicalizer.canonicalizer('net_term')));
  }

  function getCompanyBillingProviders() {
    return esApiFactoryV2.adminGetBillingProviders()
      .then(map(esCanonicalizer.canonicalizer('billing_provider')));
  }
  function clearCache() {
    cachedCompanies = [];
  }

  return service = {
    company: undefined,
    getCompany,
    getCompanyUsers,
    patchCompany,
    getNetTerms,
    clearCache,
    searchCompanies,
    getDocumentTypes,
    getDocuments,
    getCompanyDocuments,
    addCompanyDocument,
    updateCompanyDocument,
    getCompanyBillingProviders,
    /**
     * Return a promise of a boolean determining if this company will have its bills taxed,
     * based on proof-of-tax-exemption being on file or not
     **/
    isCompanyTaxable,
    checkRentalFloaterExpiration,
    companyDocumentTypes,
    companyNetTermsDaysMap
  }
});
