import ngModule from '../../ng/index.js';

function AppCtrl($rootScope, $location) {
	$rootScope.gotoEquipment = function () {
		$location.path('/home');
	};

	$rootScope.gotoJobs = function () {
		$location.path('/jobs');
	};
}

ngModule.controller('AppCtrl', AppCtrl);
