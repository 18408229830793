import { useReducer } from 'react';

import { filterGroupOptions } from './filter-group-options';
import {
	UPDATE_RATE_TYPE,
	RESET_RATE_TYPE,
	INSERT_FILTER_ROW,
	REMOVE_FILTER_ROW,
	UPDATE_FILTER_ROW,
	RESET_FILTER_ROWS,
	UPDATE_ACTIVE_FILTER_GROUPS,
	RESET_FILTER_GROUPS,
	SET_FILTER_QUERY,
	RESET_FILTER_QUERY,
} from './actions';

/**
 *
 * RateType - store rate type for view/options
 */
const initialRateType = 'company';
const initRateType = (rateType = initialRateType) => {
	return rateType;
};

/**
 * FilterGroups - Group Selector Options
 */
const initialFilterGroupOptions = filterGroupOptions[initialRateType];
const initFilterGroups = (filterGroups = initialFilterGroupOptions) => {
	return filterGroups;
};

/**
 * FilterQuery - store filter query data
 */
const initialFilterQuery = {};
const initFilterQuery = (filterQuery = initialFilterQuery) => {
	return filterQuery;
};

/**
 * Rows - store filter data by row
 */
let rowId = 0;
const getNewRowId = () => ++rowId;
export const initialRows = [{ id: rowId, type: '', value: null }];
const initRows = (rows = initialRows) => {
	return rows;
};

const initialState = {
	filterGroups: initialFilterGroupOptions,
	filterQuery: initialFilterQuery,
	rows: initialRows,
	rateType: initialRateType,
};

const init = () => {
	return {
		...initialState,
	};
};

const _reducer = (state, action) => {
	switch (action.type) {
		case UPDATE_RATE_TYPE:
			return {
				...state,
				filterGroups: initFilterGroups(filterGroupOptions[action.rateType]),
				rateType: action.rateType,
			};
		case RESET_RATE_TYPE:
			return {
				...state,
				initFilterGroups,
				initRateType,
			};
		case INSERT_FILTER_ROW:
			return {
				...state,
				rows: [...state.rows, { id: getNewRowId() }],
			};
		case REMOVE_FILTER_ROW:
			return {
				...state,
				rows: state.rows.filter((row) => row.id !== action.id),
			};
		case UPDATE_FILTER_ROW:
			return {
				...state,
				rows: [...state.rows.filter((row) => row.id !== action.row.id), { ...action.row }],
			};
		case RESET_FILTER_ROWS:
			return {
				...state,
				rows: initRows(),
			};
		case UPDATE_ACTIVE_FILTER_GROUPS:
			return {
				...state,
				filterGroups: state.filterGroups.map((filterGroup) => {
					return filterGroup.value === action.group
						? { ...filterGroup, disabled: !!action.disable }
						: { ...filterGroup };
				}),
			};
		case RESET_FILTER_GROUPS:
			return {
				...state,
				filterGroups: initFilterGroups(filterGroupOptions[action.rateType]),
			};
		case SET_FILTER_QUERY:
			return {
				...state,
				filterQuery: { ...action.filterQuery },
			};
		case RESET_FILTER_QUERY:
			return {
				...state,
				filterQuery: initFilterQuery(),
			};
		default:
			return state;
	}
};

export function reducer() {
	return useReducer(_reducer, initialState, init);
}
