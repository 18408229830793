import { RATES_BE_TO_FE_MAP, RATE_TYPES, RATE_TYPE_IDS } from '@const/rate-types';

import { FACILITATOR_TYPES_BY_NAME } from '@const/delivery-facilitator-types';

export const getFacilitatorTypeId = (deliveryType) => {
	const key = Object.keys(FACILITATOR_TYPES_BY_NAME).find((e) => deliveryType.toUpperCase() === e);
	return FACILITATOR_TYPES_BY_NAME[key];
};

export const renderRateTypeName = (rate) => {
	/* Map BE Rate Type ID to FE Display */
	return (rate?.rate_type_id && RATE_TYPES[rate.rate_type_id]) || 'Manual';
};

/* currentValues is expected to be and object with the following keys { `price_per_hour`, `price_per_day', 'price_per_week', 'price_per_month' } */
export const rentalRateWarningBelowBench = (availableRates, currentValues) => {
	if (availableRates && availableRates.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.BENCHMARK_RATE_TYPE_ID)) {
		const benchmarkRate = availableRates.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.BENCHMARK_RATE_TYPE_ID);
		// Check which current rates are below benchmark rate
		const ratesBelowBenchmark = Object.keys(currentValues)
			.map((key) => {
				return currentValues[key] < benchmarkRate[key] ? RATES_BE_TO_FE_MAP[key].name : undefined;
			})
			.filter((val) => val); // Filter out empty input values from being included

		if (ratesBelowBenchmark.length > 0) {
			return `${
				ratesBelowBenchmark.join(', ') + (ratesBelowBenchmark.length > 1 ? ' are' : ' is')
			} below the Benchmark rate.`;
		}
	}
	return undefined;
};

/* currentValues is expected to be and object with the following keys { `price_per_hour`, `price_per_day', 'price_per_week', 'price_per_month' } */
export const rentalRateWarningBelowFloor = (availableRates, currentValues, needApprovalText, dayOnly) => {
	if (availableRates && availableRates.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.FLOOR_RATE_TYPE_ID)) {
		const floorRate = availableRates.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.FLOOR_RATE_TYPE_ID);

		// Check which current rates are below benchmark rate
		const ratesBelowFloor = Object.keys(currentValues)
			.map((key) => {
				// If using only daily rate, then ignore others
				if (dayOnly) {
					return key === 'price_per_day' && currentValues['price_per_day'] < floorRate['price_per_day']
						? RATES_BE_TO_FE_MAP['price_per_day'].name
						: undefined;
				}
				return currentValues[key] < floorRate[key] ? RATES_BE_TO_FE_MAP[key].name : undefined;
			})
			.filter((val) => val); // Filter out empty input values from being included

		if (ratesBelowFloor.length > 0) {
			let rateWarningTextFloorRate = `${
				ratesBelowFloor.join(', ') + (ratesBelowFloor.length > 1 ? ' are' : ' is')
			} below the Floor rate.`;

			if (needApprovalText && ratesBelowFloor.filter((rate) => !rate.toLowerCase().includes('hour')).length > 0) {
				const companyRate = availableRates.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.COMPANY_RATE_TYPE_ID);
				const locationRate = availableRates.find((rate) => rate.rate_type_id === RATE_TYPE_IDS.LOCATION_RATE_TYPE_ID);

				const matchesCompanyRate =
					companyRate &&
					currentValues['price_per_day'] === companyRate['price_per_day'] &&
					currentValues['price_per_week'] === companyRate['price_per_week'] &&
					currentValues['price_per_month'] === companyRate['price_per_month'];

				const matchesLocationRate =
					locationRate &&
					currentValues['price_per_day'] === locationRate['price_per_day'] &&
					currentValues['price_per_week'] === locationRate['price_per_week'] &&
					currentValues['price_per_month'] === locationRate['price_per_month'];

				if (!matchesCompanyRate && !matchesLocationRate) {
					rateWarningTextFloorRate += ` This rental will need to be approved before it can go into "Draft" status.`;
				}
			}
			return rateWarningTextFloorRate;
		}
	}
	return undefined;
};
