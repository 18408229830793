import React, { Suspense } from 'react';
import { react2angular } from 'react2angular';
import { Route, Switch } from 'react-router-dom';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

import withGlobalProviders from '@components/with-global-providers';
import ngModule from '../../ng/index.js';

const InvoicesRoutes = React.lazy(() => import('./invoices/routes'));
const CreditNotesRoutes = React.lazy(() => import('./credit-notes/routes'));

const Routes = () => (
	<Suspense fallback={<LoadingOverlay />}>
		<Switch>
			<Route path="/home/transactions/invoices">
				<InvoicesRoutes />
			</Route>
			<Route path="/home/transactions/credit-notes">
				<CreditNotesRoutes />
			</Route>
		</Switch>
	</Suspense>
);

ngModule.component('transactionsRoutes', react2angular(withGlobalProviders(Routes)));

export default Routes;
