angular.module('esApiClient').factory('esAdminPayoutService', function (esApiFactoryV2, $log) {
  $log.info('esAdminPayoutService()');

  function getPayouts(config) {
    var that = this;
    return esApiFactoryV2.adminGetPayouts(config)
        .then(function (response) {
          return that.payouts = response.data;
        });
  }

  function getPayout(payoutId) {
    var that = this;
    return esApiFactoryV2.adminGetPayout(payoutId)
        .then(function (response) {
          return that.payout = response.data;
        });
  }

  function patchPayout(payout) {
    return esApiFactoryV2.adminPatchPayout(payout);
  }

  function postPayout(payout) {
    return esApiFactoryV2.adminPostPayout(payout);
  }

  return {
    payouts: undefined,
    payout: undefined,
    getPayouts: getPayouts,
    getPayout: getPayout,
    patchPayout: patchPayout,
    postPayout: postPayout
  }
});
