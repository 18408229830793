import angular from 'angular';
import Chart from 'chart.js';

angular.module("esNgComponents").controller('PieChartCtrl', function($filter) {
  let controller = this;

  controller.$onInit = refresh;
  controller.$onChanges = refresh;

  function refresh() {
      let ctx = document.getElementById('pieChartCanvas');
      new Chart(ctx, {
        type: 'pie',
        data: controller.data,
        options: {
          animation:{
              animateScale:true
          },
          legend: {
            labels: {
              usePointStyle: true
            }
          },
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                return reformatTooltips(tooltipItems, data);
              }
            }
          }
        }
      });
  }

  function reformatTooltips(tooltipItems, data) {
    return data.labels[tooltipItems.index] + ': ' + $filter('nullCurrency')(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])
  }
});
