import ngModule from '../../../ng/index.js';
import template from './rental-history.html';

ngModule.directive('rentalHistory', () => {
	return {
		restrict: 'E',
		template,
		controller: 'RentalHistoryCtrl',
		scope: {
			assignments: '=?',
			loadingAssignments: '=',
		},
	};
});
