import ngModule from '../../../ng/index.js';
import template from './driver-list.html';

ngModule.component('driverList', {
	template,
	controller: 'DriverListCtrl',
	bindings: {
		deliveries: '<',
		onDeliverySelect: '&',
		onDeliveryFocus: '&',
		onDeliveryBlur: '&',
		onDriverFocus: '&',
		onDriverBlur: '&',
	},
});
