import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _pick from 'lodash/pick';
import _sortBy from 'lodash/sortBy';

import { EuiButton } from '@equipmentshare/ds2';
import { useInvoicesLoader } from '@equipmentshare/admin-hooks';

import SimpleInvoiceTable from '@components/simple-invoice-table/simple-invoice-table';
import LineItemsTable from './line-items-table/line-items-table';

import lineItemsService from '@services/line-items.service';
import errorHandlerService from '@services/error-handler.service';
import useRentalLoader from '@hooks/data/use-rental-loader';

import styles from './line-items-view.module.css';

const sortByInvoiceNumber = (arr) => _sortBy(arr, 'invoiceNo').reverse();
const selectInvoices = (data) => sortByInvoiceNumber(data?.invoices);

const LineItemsView = ({ rentalId }) => {
	const [showCreateForm, setShowCreateForm] = useState(false);
	const [editingLineItemId, setEditingLineItemId] = useState(null);

	const { data: rental, refetch: refetchRental } = useRentalLoader(rentalId);
	const { data: rentalInvoices } = useInvoicesLoader({ rentalId }, { select: selectInvoices });

	const unbilledLineItems = rental ? rental.line_items.filter((li) => !li.invoice_id) : [];

	const handleToggleCreateForm = useCallback(() => setShowCreateForm((v) => !v), [setShowCreateForm]);
	const handleCreateLineItem = useCallback(
		async (payload) => {
			try {
				await lineItemsService.create({
					...payload,
					rental_id: rentalId,
				});
				setShowCreateForm(false);
			} catch (e) {
				errorHandlerService.genericErrorHandler(e);
			}

			refetchRental();
		},
		[rentalId, refetchRental, setShowCreateForm]
	);
	const handleDeleteLineItem = useCallback(
		async (lineItem) => {
			try {
				await lineItemsService.destroy(lineItem.line_item_id);
			} catch (e) {
				errorHandlerService.genericErrorHandler(e);
			}

			refetchRental();
		},
		[refetchRental]
	);
	const handleUpdateLineItem = useCallback(
		async (payload) => {
			try {
				await lineItemsService.update(
					payload.line_item_id,
					_pick(payload, ['line_item_type_id', 'description', 'number_of_units', 'price_per_unit', 'part_id'])
				);
				setShowCreateForm(false);
				setEditingLineItemId(null);
			} catch (e) {
				errorHandlerService.genericErrorHandler(e);
			}

			refetchRental();
		},
		[refetchRental, setShowCreateForm, setEditingLineItemId]
	);

	return (
		<div className={editingLineItemId || showCreateForm ? styles.extendedContainerHeight : undefined}>
			<div className={styles.header}>
				<h2 className={styles.title}>Unbilled Line Items</h2>
				<EuiButton fill onClick={handleToggleCreateForm}>
					Add Line Item
				</EuiButton>
			</div>
			{unbilledLineItems.length > 0 || showCreateForm ? (
				<LineItemsTable
					companyId={rental.order.user.company.company_id}
					lineItems={unbilledLineItems}
					showCreateForm={showCreateForm}
					onCancelAddLineItem={handleToggleCreateForm}
					onCreateLineItem={handleCreateLineItem}
					onDeleteLineItem={handleDeleteLineItem}
					onUpdateLineItem={handleUpdateLineItem}
					editingLineItemId={editingLineItemId}
					onEditLineItem={(lineItem) => setEditingLineItemId(lineItem.line_item_id)}
					onCancelEditLineItem={() => setEditingLineItemId(null)}
				/>
			) : (
				<p className={styles.emptyPlaceholder}>None</p>
			)}
			<div className={styles.header}>
				<h2 className={styles.title}>Invoices</h2>
			</div>
			{rentalInvoices ? (
				<SimpleInvoiceTable invoices={rentalInvoices} />
			) : (
				<p className={styles.emptyPlaceholder}>No Invoices</p>
			)}
		</div>
	);
};

LineItemsView.propTypes = {
	rentalId: PropTypes.number.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
};

export default LineItemsView;
