import * as Rollbar from 'rollbar';
import authService from './auth.service';
import { checkUpdates } from '@equipmentshare/reload-notification/dist/reload-notification.js';

import { IS_DEV, ROLLBAR_ENABLED, ROLLBAR_ENV, ROLLBAR_TOKEN } from '@const/env';
import usersService from './users.service';

// extract just the path out of the hash routing
const extractPath = /^[^?]+/;
// extract the query string from the hash routing (including the "?")
const extractSearch = /\?.+/;

function asyncTimeout(time) {
	return new Promise((res) => setTimeout(() => res(null), time));
}

class AnalyticsService {
	setup() {
		const setup = Promise.all([this.initRollbar()]);

		return Promise.race([setup, asyncTimeout(1000)]);
	}

	async initRollbar() {
		const version = await checkUpdates.fetchCurrentVersion();

		const config = {
			enabled: ROLLBAR_ENABLED,
			verbose: IS_DEV,
			accessToken: ROLLBAR_TOKEN,
			captureUncaught: true,
			captureUnhandledRejections: true,
			host: location.hostname,
			ignoredMessages: [
				'docs-homescreen-gb-container', // https://rollbar.com/trey-2/ESTrack/items/75/ - Probably a user's browser extension
				'digest already in progress',
				'Loading CSS Chunk',
				'ChunkLoadError',
			],
			payload: {
				environment: ROLLBAR_ENV,
				client: {
					javascript: {
						code_version: version,
						guess_uncaught_frames: true,
					},
				},
			},
		};

		Rollbar.init(config);
	}

	async updateAuthenticatedUser() {
		const isAuth = await authService.isAuthenticated();

		if (isAuth) {
			const user = await usersService.fetchSelf();

			const id = user.user_id;
			const email = user.email_address;
			const username = `${user.first_name} ${user.last_name}`;

			Rollbar.configure({
				payload: {
					person: {
						id,
						username,
						email,
					},
				},
			});
		}
	}

	critical(msg, trace, other) {
		Rollbar.critical(msg, trace, other);
	}

	error(msg, trace, other) {
		Rollbar.error(msg, trace, other);
	}

	warning(msg, trace, other) {
		Rollbar.warning(msg, trace, other);
	}

	info(msg, trace, other) {
		Rollbar.info(msg, trace, other);
	}

	debug(msg, trace, other) {
		Rollbar.debug(msg, trace, other);
	}
}

const analyticsService = new AnalyticsService();

export default analyticsService;
