export const RENTAL_STATUS_IDS = {
	NEEDS_APPROVAL_RENTAL_STATUS_ID: 1,
	DRAFT_RENTAL_STATUS_ID: 2,
	PENDING_RENTAL_STATUS_ID: 3,
	SCHEDULED_RENTAL_STATUS_ID: 4,
	ON_RENT_RENTAL_STATUS_ID: 5,
	OFF_RENT_RENTAL_STATUS_ID: 6,
	COMPLETED_RENTAL_STATUS_ID: 7,
	CANCELLED_RENTAL_STATUS_ID: 8,
	BILLED_RENTAL_STATUS_ID: 9,
};
