import csvString from 'csv-string';
import { saveAs } from 'file-saver';

/**
 * parses a csv string (Copied from kernel-legacy CSV.parseString)
 * @param {String} csv - the csv string to parse
 * @param {Boolean} [parseHeaders=true] - whether to parse the file with headers
 * @deprecated
 * @returns {Array} - returns an array of rows
 */
export function parseString(csv, parseHeaders = true) {
	const rows = csvString.parse(csv);

	if (parseHeaders) {
		const headers = rows.shift();
		const data = rows.map((row) => {
			const obj = {};

			headers.forEach((col, i) => {
				obj[col] = row[i];
			});

			return obj;
		});

		return {
			headers,
			data,
		};
	}

	return rows;
}

export function downloadCsvFile(csv, filename) {
	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	return saveAs(blob, filename);
}
