import React from "react";
import PropTypes from "prop-types";

import ArrowIcon, { DIRECTIONS } from "./components/arrow-icon/arrow-icon.js";
import {
  SORT_DIRECTION_NONE,
  SORT_DIRECTION_ASCENDING,
  SORT_DIRECTION_DESCENDING
} from "../../../../utils/constants.js";

import sortIndicatorStyles from "./sort-indicator.module.css";

export default function SortArrows( { direction } ) {
  const isAscending = direction === SORT_DIRECTION_ASCENDING;
  const isDescending = direction === SORT_DIRECTION_DESCENDING;

  return (
    <div className={sortIndicatorStyles.sortIndicator}>
      <ArrowIcon filled={isAscending} />
      <ArrowIcon direction={DIRECTIONS.down} filled={isDescending} />
    </div>
  );
}

SortArrows.defaultProps = {
  direction: SORT_DIRECTION_NONE
};

SortArrows.propTypes = {
  direction: PropTypes.oneOf( [ SORT_DIRECTION_NONE, SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING ] )
};
