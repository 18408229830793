import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EuiIcon } from '@equipmentshare/ds2';
import { CheckboxCircleFillIcon, CloseCircleFillIcon } from '@equipmentshare/ds2-icons';

import Cell from '../cell/cell.js';

import styles from './bool-cell.module.css';

export const BoolCell = ({ value, className, hideIcon, ...props }) => (
	<Cell {...props} className={classNames(styles.cell, className)}>
		{!hideIcon && (
			<EuiIcon
				className={value ? styles.boolTrue : styles.boolFalse}
				type={value ? CheckboxCircleFillIcon : CloseCircleFillIcon}
			/>
		)}
	</Cell>
);

BoolCell.propTypes = {
	className: PropTypes.string,
	value: PropTypes.bool,
	columnId: PropTypes.string,
	hideIcon: PropTypes.bool,
};

export default BoolCell;
