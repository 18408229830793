import ngModule from '../ng/index';
import '@views/debug/routes';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.debug', {
			url: '/debug',
			template: '<debug-routes flex="100" />',
			redirectTo: 'home.debug.user',
		})
		.state('home.debug.user', {
			url: `/user`,
		});
});
