import React, { Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

import InputSuggestions from '../input-suggestions/input-suggestions';
import googlePlacesService from '@services/google-places.service.js';
import { GOOGLE_PLUS_CODE_REGEX } from '@const/regexp';
import googleGeocoderService from '@services/google-geocoder.service.js';

const optionRenderer = ({ option: place }) => {
	if (place.types.includes('plus_code') && place.plus_code) {
		return place.plus_code.compound_code;
	} else {
		return (
			<Fragment>
				<span>{place.name || (place.geometry && place.geometry.location.toString())}</span>
				<br />
				<small>{place.formatted_address}</small>
			</Fragment>
		);
	}
};

export const GooglePlacesSuggestionInput = ({
	className,
	id,
	value,
	onChange,
	onSelectLocation,
	filterSuggestions,
	fullWidth,
	...inputProps
}) => {
	const [results, setResults] = useState([]);

	const queryPlaces = useCallback(
		_debounce(async (query) => {
			if (query.length < 3) {
				return;
			}

			if (GOOGLE_PLUS_CODE_REGEX.test(query)) {
				const place = await googleGeocoderService.geocodePlusCodeWithZip(query);

				setResults([place]);
			} else {
				setResults(await googlePlacesService.textSearch(query));
			}
		}, 500),
		[setResults]
	);

	const handleInputChange = (e) => {
		onChange && onChange(e.target.value);
	};
	const handleSelectOption = async (place) => {
		if (onSelectLocation) {
			if (!place.address_components) {
				const placeDetails = await googlePlacesService.getPlaceDetails(place.place_id);

				onSelectLocation(googlePlacesService.convertPlaceToLocation(placeDetails));
			} else {
				onSelectLocation(googlePlacesService.convertPlaceToLocation(place));
			}
		}
	};

	useEffect(() => {
		queryPlaces(value || '');
	}, [value, queryPlaces]);

	const filteredResults = useMemo(() => {
		return filterSuggestions ? filterSuggestions(results) : results;
	}, [results, filterSuggestions]);

	return (
		<InputSuggestions
			className={className}
			id={id || 'google-places-suggestions'}
			options={filteredResults}
			isOpen={true}
			optionRenderer={optionRenderer}
			inputProps={{ ...inputProps, value, onChange: handleInputChange, fullWidth }}
			onSelectOption={handleSelectOption}
			fullWidth={fullWidth}
		/>
	);
};

GooglePlacesSuggestionInput.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onSelectLocation: PropTypes.func,
	filterSuggestions: PropTypes.func,
	fullWidth: PropTypes.bool,
};

export default GooglePlacesSuggestionInput;
