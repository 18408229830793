import esApi from './es-api.service';
import { RENTAL_STATUS_IDS } from '@const/rental-status';

async function fetch(rentalId, opts) {
	return await esApi.request(esApi.url`/rentals/${rentalId}`, null, opts);
}

async function invoiceEstimate(payload) {
	return await esApi.request('/rentals/invoice-estimate', null, { body: payload, method: 'POST' });
}

async function invoiceEstimateMultiple(payloads) {
	return await Promise.all(
		payloads.map((payload) => {
			return invoiceEstimate(payload);
		})
	);
}

async function search(query, opts) {
	return await esApi.request(esApi.url`/admin/rentals`, query, opts);
}

async function create(payload) {
	return await esApi.request(esApi.url`/admin/rentals`, null, { body: payload, method: 'POST' });
}

async function assignPart(rentalId, payload) {
	return await esApi.request(esApi.url`/admin/rentals/${rentalId}/assigned-part`, null, {
		body: payload,
		method: 'POST',
	});
}

async function endPartRental(rentalId, payload) {
	return await esApi.request(esApi.url`/admin/rentals/${rentalId}/assigned-part`, null, {
		body: payload,
		method: 'PATCH',
	});
}

async function update(rentalId, payload) {
	return await esApi.request(esApi.url`/admin/rentals/${rentalId}`, null, { body: payload, method: 'PATCH' });
}

async function updateMultiple(rentalIds, payload) {
	return await Promise.all(
		rentalIds.map(async (id) => {
			await update(id, payload);
		})
	);
}

async function createMultiple(payload) {
	return await esApi.request(esApi.url`/admin/multiple-rentals`, null, { body: payload, method: 'POST' });
}

async function cancelRental(rentalId, cancelType, cancelNote) {
	const payload = {
		rental_status_id: RENTAL_STATUS_IDS.CANCELLED_RENTAL_STATUS_ID,
		cancel_reason_type: cancelType,
		...(cancelNote && { cancel_reason_note: cancelNote }),
	};

	return await esApi.request(esApi.url`/admin/rentals/${rentalId}`, payload, {
		body: payload,
		method: 'PATCH',
	});
}

const rentalsService = {
	search,
	create,
	fetch,
	invoiceEstimate,
	invoiceEstimateMultiple,
	assignPart,
	endPartRental,
	update,
	updateMultiple,
	createMultiple,
	cancelRental,
};

export default rentalsService;
