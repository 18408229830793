import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { parse } from 'json2csv';
import styled from 'styled-components';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFilePicker, EuiTitle } from '@equipmentshare/ds2';
import { EuiSimpleModal } from '@equipmentshare/es-admin-eui-simple-components';

import equipmentClassService from '@services/equipment-class.service';
import errorHandlerService from '@services/error-handler.service';
import localCache from '@services/local-cache.service';
import { equipmentClassExportXFields } from '../../providers/xFields';
import { FilterState } from '../../providers/filter-provider';
import { getRatesServiceData } from '../../rate.helpers';
import toastService from '@services/toast.service';
import { downloadCsvFile } from '@helpers/csv';

import styles from '../modals.module.css';

const ButtonGroup = styled(EuiFlexGroup)`
	margin-top: 8px;
`;

export const CsvUploadModal = ({ isOpen, onClose }) => {
	const { state: filterState } = useContext(FilterState);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState();
	const [endpointServiceData, setEndpointServiceData] = useState();

	useEffect(() => setEndpointServiceData(getRatesServiceData(filterState.rateType)), [filterState.rateType]);

	const handleFileUpload = (files) => setFile(files[0]);

	const createPayload = () => {
		const payload = new FormData();
		payload.append('file', file, 'file'); // Third param renames the file to "file"

		return payload;
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		setLoading(true);

		try {
			const payload = createPayload();

			await endpointServiceData.service.bulkUpload(payload);
			setLoading(false);
			onClose();

			return toastService.showSuccess('Rates successfully uploaded. Processing will begin shortly.');
		} catch (err) {
			return errorHandlerService.genericErrorHandler(err);
		}
	};

	const handleCsvTemplateDownload = async () => {
		setLoading(true);
		try {
			const template = await endpointServiceData.service.getBulkUploadTemplate();
			const fileName = `${filterState.rateType}_rental_rate_example`;
			return downloadCsvFile(template, fileName);
		} catch (err) {
			return errorHandlerService.genericErrorHandler(err);
		} finally {
			setLoading(false);
		}
	};

	const generateCsv = (equipmentClasses = []) => {
		try {
			const fileName = `equipment_class_xrefs-${moment().format('MM-DD-YYYY_hhmm')}`;
			const fields = ['Equipment Class ID', 'Name', 'Description'];
			const csvData = equipmentClasses.map((eqClass) => ({
				'Equipment Class ID': eqClass.equipment_class_id,
				Name: eqClass?.name || '--',
				Description: eqClass?.description || '--',
			}));

			const csvString = parse(csvData, { fields });
			return downloadCsvFile(csvString, fileName);
		} catch (err) {
			return errorHandlerService.genericErrorHandler(
				err,
				'There was an issue generating the CSV file. If the issue persists please contact engineering.'
			);
		}
	};

	const handleEquipmentClassDownload = async () => {
		setLoading(true);
		try {
			const equipmentClasses = await localCache.cacheFirst(localCache.createEntityKey('equipment-class-data'), () =>
				equipmentClassService.search({}, { headers: { 'X-Fields': equipmentClassExportXFields } })
			);

			return generateCsv(equipmentClasses);
		} catch (err) {
			return errorHandlerService.genericErrorHandler(err);
		} finally {
			setLoading(false);
		}
	};

	if (!isOpen) {
		return null;
	}

	return (
		<EuiSimpleModal title="CSV Upload" onClose={onClose}>
			<form className={styles.layout} onSubmit={handleSubmit}>
				<div className={styles.content}>
					<div className={styles.inputLabel}>
						<EuiTitle size="xs">
							<h2>Select File</h2>
						</EuiTitle>
						<div className={styles.subLabel}>Only files of type &quot;.csv&quot; are permitted.</div>
					</div>
					<EuiFilePicker
						accept=".csv"
						className={styles.input}
						onChange={handleFileUpload}
						type="file"
						display="default"
						value={undefined}
						required
						fullWidth
					/>
					<EuiFlexGroup gutterSize="s">
						<EuiFlexItem grow={false}>
							<EuiButton fill size="s" isDisabled={loading} onClick={handleCsvTemplateDownload} isLoading={loading}>
								Download CSV Template
							</EuiButton>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButton fill size="s" isDisabled={loading} onClick={handleEquipmentClassDownload} isLoading={loading}>
								Download Equipment Class XRef
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</div>
				<ButtonGroup gutterSize="s" justifyContent="flexEnd">
					<EuiFlexItem grow={false}>
						<EuiButton fill size="s" type="submit" isDisabled={loading || !file} isLoading={loading}>
							Upload Prices
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButton color="text" size="s" onClick={onClose}>
							Cancel
						</EuiButton>
					</EuiFlexItem>
				</ButtonGroup>
			</form>
		</EuiSimpleModal>
	);
};

CsvUploadModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default CsvUploadModal;
