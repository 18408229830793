import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  get as _get,
  isArray as _isArray,
  isEmpty as _isEmpty,
  isNil as _isNil
} from "lodash";
import Select, { createFilter } from "react-select";

import MenuList from "./menuList";
import InputLabel from "../../input-label/input-label.js";

let instanceId = 1;

const findOption = ( options, valueKey, value ) => {
  return !_isNil( value ) && options.find( ( opt ) => opt[valueKey] === value );
};

import formControlStyles from "../form-control.module.css";
import autocompleteStyles from "./autocomplete.module.css";

const indicatorPadding = "8px 2px";
const customStyles = {
  indicatorsContainer: ( provided ) => ( { ...provided, paddingRight: "4px" } ),
  indicatorSeparator: ( provided ) => ( { ...provided, display: "none" } ),
  clearIndicator: ( provided ) => ( { ...provided, padding: indicatorPadding } ),
  dropdownIndicator: ( provided ) => ( { ...provided, padding: indicatorPadding } ),
  loadingIndicator: ( provided ) => ( { ...provided, padding: indicatorPadding } ),
  option: ( provided ) => ( { ...provided, ...autocompleteStyles.autocompleteOption } ),
};

export default class Autocomplete extends PureComponent {
  constructor ( ...args ) {
    super( ...args );

    this._instancePrefix = "autocomplete-" + ( instanceId++ ) + "-";

    this.state = {
      inputValue: "",
    };
    this.handleOnChange = this.handleOnChange.bind( this );
    this.handleInputChange = this.handleInputChange.bind( this );
    this.filterOption = this.filterOption.bind( this );
    this.getOptionLabel = this.getOptionLabel.bind( this );
    this.getOptionValue = this.getOptionValue.bind( this );
    this.isOptionDisabled = this.isOptionDisabled.bind( this );
    this.loadingMessage = this.loadingMessage.bind( this );
    this.noOptionsMessage = this.noOptionsMessage.bind( this );
    this.formatOptionLabel = this.formatOptionLabel.bind( this );
  }

  handleOnChange( selectedOption ) {
    const { onChange, valueKey, multi, simpleValue, options, delimiter } = this.props;

    let selectedValues = null;
    let selectedOptions = null;

    if( multi ){
      const optionValues = selectedOption && selectedOption.map( ( option ) => option[valueKey] );

      if( simpleValue ) {
        selectedValues = !_isNil( optionValues ) ? optionValues.join( delimiter ) : "";
        selectedOptions = !_isNil( selectedOption ) ? selectedOption : [ null ];
      } else {
        selectedValues = optionValues || [];
        selectedOptions = selectedOption || [];
      }
    }
    else {
      const value = _get( selectedOption, valueKey, null );

      selectedValues = value;
      selectedOptions = findOption( options, valueKey, value ) || null;
    }

    if( onChange ){
      onChange( selectedValues, selectedOptions );
    }
  }

  handleInputChange( inputValue ) {
    const { onInputChange } = this.props;

    if( onInputChange ){
      let newInputValue = onInputChange( inputValue );

      if( newInputValue !== undefined ){
        inputValue = newInputValue;
      }
    }

    this.setState( {
      inputValue
    } );

    return inputValue;
  }

  disabledFilterOptions(){
    return true;
  }

  optionRenderer( option, { context } ) {
    const { optionRenderer, valueRenderer, labelKey } = this.props;
    const isSelectedOptionInInput = context === "value";
    const isMenuOption = context === "menu";

    if ( valueRenderer && isSelectedOptionInInput ) {
      return valueRenderer( option );
    } else if ( optionRenderer && isMenuOption ) {
      return optionRenderer( option );
    }

    return option[labelKey];
  }

  formatOptionLabel( option, context ) {
    return this.optionRenderer( option, context );
  }

  getOptionLabel( option ) {
    const { labelKey } = this.props;

    return option[labelKey];
  }

  getOptionValue( option ) {
    const { valueKey } = this.props;

    return option[valueKey];
  }

  isOptionDisabled( option ) {
    return option.disabled;
  }

  loadingMessage() {
    const { loadingText } = this.props;

    return loadingText;
  }

  noOptionsMessage() {
    const { noResultsText } = this.props;

    return noResultsText;
  }

  getSelectedValue() {
    const { delimiter, multi, options, simpleValue, value, valueKey } = this.props;
    const hasNoValue = _isNil( value ) || ( _isArray( value ) && _isEmpty( value ) );
    const hasNoOptions = _isNil( options ) || _isEmpty( options );
    let selectedValue;

    if ( hasNoValue || hasNoOptions ) {
      return "";
    }

    if ( multi ) {
      const values = simpleValue ? value.split( delimiter ) : value;

      selectedValue = options.filter( ( option ) => values.includes( option[valueKey] ) );
    } else {
      selectedValue = options.find( ( option ) => option[valueKey] === value ) || "";
    }

    return selectedValue;
  }

  filterOption(  ) {
    const { filterOption } = this.props;

    if ( filterOption ) {
      return ( option, input ) => filterOption( { ...option.data }, input );
    }

    // ignoreAccents is for general performance gains
    return createFilter( { ignoreAccents: false } );
  }

  render () {
    const { inputValue } = this.state;
    const {
      disabled, disableFiltering, id, isLoading, label, loadOptions, maxHeight, menuDetached,
      name, onBlur, onFocus, optionHeight, options, placeholder, required,
      className, multi, removeSelected, delimiter, autoFocus, clearable, ...restProps
    } = this.props;
    const selectValue = this.getSelectedValue();
    const menuPortalTarget = menuDetached ? { menuPortalTarget: document.body } : {};
    const menuPortalTargetStyles = menuDetached ? { menuPortal: ( provided ) => ( { ...provided, zIndex: 999999 } ) } : {};

    let inputClassNames = classNames(
      className,
      autocompleteStyles.autocomplete,
      formControlStyles.control,
      {
        [formControlStyles.fadeIn]: !!inputValue
      }
    );

    return (
      <InputLabel
        className={inputClassNames}
        htmlFor={id || this._instancePrefix}
        label={label}
        required={required}
      >
        <Select
          {...restProps}
          autoFocus={autoFocus}
          classNamePrefix="react-select"
          components={{ MenuList }}
          delimiter={delimiter}
          filterOption={disableFiltering ? this.disabledFilterOptions : this.filterOption()}
          formatOptionLabel={this.formatOptionLabel}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          hideSelectedOptions={removeSelected}
          inputId={id || this._instancePrefix}
          isClearable={clearable}
          isDisabled={disabled}
          isLoading={isLoading}
          isMulti={multi}
          isOptionDisabled={this.isOptionDisabled}
          loadOptions={loadOptions}
          loadingMessage={this.loadingMessage}
          maxHeight={maxHeight}
          name={name}
          noOptionsMessage={this.noOptionsMessage}
          onBlur={onBlur}
          onChange={this.handleOnChange}
          onFocus={onFocus}
          onInputChange={this.handleInputChange}
          optionHeight={optionHeight}
          options={options || []}
          placeholder={placeholder}
          styles={{ ...customStyles, ...menuPortalTargetStyles }}
          value={selectValue}
          {...menuPortalTarget}
        />
        {/* react-select v3 does not support `required` currently below is a work around */}
        {/* onChange is only to silence react warnings as input can not be read only */}
        {!disabled && (
          <input
            autoComplete="off"
            className={autocompleteStyles.autocompleteHiddenRequired}
            required={required}
            onChange={() => {}}
            tabIndex={-1}
            value={selectValue}
          />
        )}
      </InputLabel>
    );
  }
}

Autocomplete.propTypes = {
  /** auto focus */
  autoFocus: PropTypes.bool,
  /** component classname */
  className: PropTypes.string,
  clearable: PropTypes.bool,
  /** whether to disable option filtering */
  disableFiltering: PropTypes.bool,
  /** disabled state, see [Input](#!/Input) */
  disabled: PropTypes.bool,
  /** a custom filter method */
  filterOption: PropTypes.func,
  /** the id of the html input */
  id: PropTypes.any,
  /** enables or disables the loading state of the input */
  isLoading: PropTypes.bool,
  /** a label for the input, see [Input](#!/Input) */
  label: PropTypes.string,
  /** a string for getting an options label */
  labelKey: PropTypes.string,
  /** a function that is called to load options */
  loadOptions: PropTypes.func,
  /** custom text to show when the input is in loading state */
  loadingText: PropTypes.string,
  /* the max height of the menu */
  maxHeight: PropTypes.number,
  /** detaches the menu (set `true` when in places like modals) */
  menuDetached: PropTypes.bool,
  /** the name of the input */
  name: PropTypes.string,
  /** custom text to show when there is no options that match */
  noResultsText: PropTypes.string,
  onBlur: PropTypes.func,
  /** called when the user selects an option */
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  /** called when the input changes */
  onInputChange: PropTypes.func,
  /** explicit height of menu option */
  optionHeight: PropTypes.number,
  /** a function that is used to render the contents of an option in the option menu */
  optionRenderer: PropTypes.func,
  /** an array of options */
  options: PropTypes.arrayOf( PropTypes.shape( {
    /** whether the option is disabled */
    disabled: PropTypes.bool
  } ) ),
  /** the placeholder text to show when no value is selected */
  placeholder: PropTypes.string,
  /** required state, see [Input](#!/Input) */
  required: PropTypes.bool,
  /** the value of the input */
  value: PropTypes.oneOfType( [
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf( PropTypes.oneOfType( [
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ] ) )
  ] ),
  /** a string used to get the value of an option */
  valueKey: PropTypes.string,
  /** a function that is used to render the selected value */
  valueRenderer: PropTypes.func,

  /** enable multi-select @see https://github.com/JedWatson/react-select/tree/v1.x#multiselect-options */
  multi: PropTypes.bool,
  removeSelected: PropTypes.bool,
  simpleValue: PropTypes.bool,
  delimiter: PropTypes.string,
};

Autocomplete.defaultProps = {
  autoFocus: null,
  className: null,
  clearable: true,
  disabled: false,
  disableFiltering: false,
  filterOption: null,
  id: null,
  isLoading: false,
  label: null,
  labelKey: "label",
  loadingText: "Loading...",
  loadOptions: null,
  maxHeight: 250,
  menuDetached: false,
  name: null,
  noResultsText: "No results found",
  onBlur: null,
  onChange: null,
  onFocus: null,
  onInputChange: null,
  optionRenderer: null,
  optionHeight: 35,
  options: [],
  placeholder: "Select...",
  required: false,
  value: null,
  valueKey: "value",
  valueRenderer: undefined,
  multi: false,
  removeSelected: true,
  simpleValue: true,
  delimiter: ",",
};
