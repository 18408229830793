const statusEnum = { "ok": 1, "warning": 2, "error": 3 };
const validStatuses = Object.keys( statusEnum );

export function determineBrakeStatus( assetStatus ){
  const statuses = [
    assetStatus.emergency_air_status,
    assetStatus.service_brakes_status,
    assetStatus.stop_lamp_power_status,
    assetStatus.tpms_high_pressure_status,
    assetStatus.tpms_high_temperature_status,
    assetStatus.tpms_low_pressure_status
  ].filter( Boolean );

  return statuses
    .filter( ( itemStatus ) => ( validStatuses.includes( itemStatus ) ) )
    .reduce( ( highestSeverity, itemStatus ) => (
      ( highestSeverity === null || statusEnum[itemStatus] > statusEnum[highestSeverity] )
        ? itemStatus
        : highestSeverity
    ), null );
}
