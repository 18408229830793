import ngModule from '../../../../ng/index.js';
import template from './company-location-picker.html';

ngModule.directive('companyLocationPicker', () => {
	return {
		template,
		controller: 'CompanyLocationPickerCtrl as $ctrl',
		scope: {
			ngModel: '=',
			ngChange: '=',
			ngDisabled: '<',
			ngRequired: '<',
			company: '=',
			label: '@',
			nameInputLabel: '@',
			mdAutofocus: '<',
		},
	};
});
