import React, { FunctionComponent, useState } from 'react';
import { EuiButton, EuiForm, EuiFormRow, EuiText } from '@equipmentshare/ds2';
import { difference } from '@helpers/utilities';
import { transferAsset } from '@services/transfer.service';
import styled from 'styled-components';
import toastService from '@services/toast.service.js';

import SettingsModalForm from './SettingsModalForm';
import TransferModalForm from './TransferModalForm';
import { useMutation, useQuery } from '@apollo/client';
import { openTransfersQuery } from '@components/asset-info-drawer/asset-transfer-tab/queries/open-transfers';
import CenteredLoadingSpinner from '@components/loading-spinner/loading-spinner';
import { FetchResult } from '@apollo/client/link/core';
import { MutationFunctionOptions } from '@apollo/client/react/types/types';
import { createTransferOrderMutation } from '@components/asset-info-drawer/asset-transfer-tab/mutations/create-transfer-order';

const FormLayoutInner = styled.div`
	padding: 4px 4px;
`;
const FormLayoutButtonGroup = styled.div`
	padding: 4px 0px;
`;

interface BranchInfo {
	branch_id: number;
	company_id: number;
	name: string;
}

interface OptionalBranchInfo {
	branch_id: number | undefined;
	company_id: number | undefined;
	name: string | undefined;
}

interface AssetTransferTabOwnProps {
	assetId: number;
	inventoryBranch: BranchInfo;
	rentalBranch: OptionalBranchInfo;
	serviceBranch: OptionalBranchInfo;
	companyId: number;
	userIsFleetOperations: boolean;
	refresh: () => void;
}

const getTitle = (canTransfer: boolean): string =>
	canTransfer ? 'Transfer Asset' : 'Third party assets that aren’t able to be rented can’t be transferred.';

const FIVE_SECONDS_IN_MS = 5000;

const AssetTransferTab: FunctionComponent<AssetTransferTabOwnProps> = ({
	assetId,
	rentalBranch,
	serviceBranch,
	inventoryBranch,
	companyId,
	userIsFleetOperations,
	refresh,
}) => {
	const [isTransferModalVisible, setIsTransferModalVisible] = useState<boolean>(false);
	const [isSettingsModalVisible, setIsSettingsModalVisible] = useState<boolean>(false);
	const [transferOrderCreated, setTransferOrderCreated] = useState<boolean>(false);

	const [transferMutation] = useMutation(createTransferOrderMutation);
	const { data: openTransfers, loading: openTransfersLoading } = useQuery(openTransfersQuery, {
		variables: { assetId: assetId },
		pollInterval: FIVE_SECONDS_IN_MS,
		// if we have fetched the latest data, then we need to trust it as the most correct state
		onCompleted: () => setTransferOrderCreated(false),
	});
	const openTransfer = openTransfers?.transferOrders.edges.length ? openTransfers.transferOrders.edges[0].node : null;
	const isLoading = openTransfersLoading;

	const phase3CanTransfer = !openTransfer && !transferOrderCreated;
	const canTransfer = phase3CanTransfer && (companyId === 1854 || !!rentalBranch);

	// handles the network call for transfers (both 1854 assets and external assets)
	const onTransferModalConfirm = async (branchId: number, isPhase3BetaEnabled: boolean) => {
		// customer set a "transfer" to the same branch it currently is at, and ISP and MSP also match
		if (
			branchId === rentalBranch?.branch_id &&
			branchId === serviceBranch?.branch_id &&
			branchId === inventoryBranch?.branch_id
		) {
			toastService.showError('Unable to transfer asset. The Asset is already located at the branch');
			return;
		}

		if (!isPhase3BetaEnabled) {
			await handlePhase2Transfer(companyId, assetId, branchId, rentalBranch, refresh);
		} else {
			await handlePhase3Transfer(assetId, branchId, transferMutation, setTransferOrderCreated);
		}
	};

	// for changing MSP or removing from rental fleet.
	const onSettingsModalConfirm = async (rentalId: number | undefined, serviceId: number | undefined) => {
		if (rentalBranch?.branch_id === rentalId && serviceBranch?.branch_id === serviceId) {
			// safety check here, no network call if nothing changed
			return;
		}

		const payload = difference(
			{
				rental_branch_id: rentalId === undefined ? null : rentalId,
				service_branch_id: serviceId === undefined ? null : serviceId,
			},
			{ rental_branch_id: rentalBranch?.branch_id, service_branch_id: serviceBranch?.branch_id }
		); // diff current v what we started with

		try {
			await transferAsset(assetId, payload);
			toastService.showSuccess(`Updated Asset #${assetId}`);
			refresh();
		} catch (err: any) {
			toastService.showError(`Error Updating asset #${assetId}`);
		}
	};

	if (isLoading) {
		return <CenteredLoadingSpinner size="xxl" />;
	}

	return (
		<EuiForm component="form" style={{ marginTop: '10px' }}>
			{rentalBranch && (
				<FormLayoutInner>
					<EuiFormRow label="Rental Service Provider">
						<EuiText>{rentalBranch?.name ?? '---'}</EuiText>
					</EuiFormRow>
				</FormLayoutInner>
			)}
			{!rentalBranch && (
				<FormLayoutInner>
					<EuiFormRow label="Inventory Branch" fullWidth>
						<EuiText>{inventoryBranch?.name ?? '---'}</EuiText>
					</EuiFormRow>
				</FormLayoutInner>
			)}
			<FormLayoutInner>
				<EuiFormRow label="Maintenance Service Provider">
					<EuiText>{serviceBranch?.name ?? '---'}</EuiText>
				</EuiFormRow>
			</FormLayoutInner>

			<FormLayoutButtonGroup>
				<FormLayoutInner>
					<EuiFormRow fullWidth>
						<EuiButton
							fill
							title={getTitle(canTransfer)}
							disabled={!canTransfer}
							fullWidth
							size="s"
							onClick={() => setIsTransferModalVisible(true)}
						>
							Transfer
						</EuiButton>
					</EuiFormRow>
				</FormLayoutInner>
				<FormLayoutInner>
					<EuiFormRow fullWidth>
						{/*
						Attempting to mess with advanced settings during a transfer
						(adjusting RSP or MSP) will result in those fields getting overridden upon completion of the transfer
						so we disable this functionality until the transfer is complete.
						*/}
						<EuiButton
							disabled={!phase3CanTransfer}
							fullWidth
							iconType="gear"
							size="s"
							onClick={() => setIsSettingsModalVisible(true)}
						>
							Advanced Settings
						</EuiButton>
					</EuiFormRow>
				</FormLayoutInner>
				{!!openTransfer && (
					<>
						<FormLayoutInner>
							<EuiFormRow>
								<EuiText>{getTransferOrderStatusMessage(openTransfer)}</EuiText>
							</EuiFormRow>
						</FormLayoutInner>
					</>
				)}
				{isTransferModalVisible && (
					<TransferModalForm
						initialRentalBranchId={rentalBranch?.branch_id ?? 0}
						inventoryBranchId={inventoryBranch.branch_id}
						companyId={companyId}
						setIsModalVisible={setIsTransferModalVisible}
						onTransferModalConfirm={onTransferModalConfirm}
					/>
				)}
				{isSettingsModalVisible && (
					<SettingsModalForm
						branches={{
							inventoryBranch: { id: inventoryBranch.branch_id, name: inventoryBranch.name ?? '' },
							rentalBranch: { id: rentalBranch?.branch_id, name: rentalBranch?.name ?? '' },
							serviceBranch: { id: serviceBranch?.branch_id, name: serviceBranch?.name ?? '' },
						}}
						companyId={companyId}
						isFleetOperations={userIsFleetOperations}
						setIsModalVisible={setIsSettingsModalVisible}
						onSettingsModalConfirm={onSettingsModalConfirm}
					/>
				)}
			</FormLayoutButtonGroup>
		</EuiForm>
	);
};

const getTransferOrderStatusMessage = (openTransfer: any) => {
	if (openTransfer.status === 'Requested') {
		return `There is an active transfer request to send this asset to ${openTransfer.toBranch.name}`;
	}

	if (openTransfer.status === 'Approved') {
		return `The asset is in transit to ${openTransfer.toBranch.name}`;
	}

	return 'There is an active transfer for this asset';
};

async function handlePhase2Transfer(
	companyId: number,
	assetId: number,
	branchId: number,
	rentalBranch: OptionalBranchInfo,
	refresh: () => void
) {
	let reqObject;
	// for transfering assets, we only change the ISP if it is an 1854 asset,
	// otherwise, we cannot change the ISP of an asset that does not belong to us.
	// Doing so will error on the server side as well
	const isInRentalFleet = !!rentalBranch?.branch_id;
	if (companyId === 1854) {
		// if there is a rental branch previously assigned, then we update the rental branch on transfer.
		// If there is not a rental branch id this is a NON RENTAL FLEET asset and you should not attach a rental branch
		// upon transfer
		reqObject = {
			rental_branch_id: isInRentalFleet ? branchId : null,
			service_branch_id: branchId,
			inventory_branch_id: branchId,
		};
	} else {
		// non es-owned asset
		if (isInRentalFleet) {
			reqObject = {
				rental_branch_id: branchId,
				service_branch_id: branchId,
			};
		} else {
			toastService.showError(
				'Cannot transfer this asset. The asset is not owned by EquipmentShare and not in the rental fleet.'
			);
			return;
		}
	}
	try {
		await transferAsset(assetId, reqObject);
		toastService.showSuccess(`Asset #${assetId} has been transferred`);
		refresh();
	} catch (err: any) {
		toastService.showError(`Error transferring asset #${assetId}`);
	}
}

async function handlePhase3Transfer(
	assetId: number,
	branchId: number,
	transferMutation: (options?: MutationFunctionOptions<any, any, any, any>) => Promise<FetchResult<any>>,
	onComplete: (success: boolean) => void
) {
	let result: Awaited<ReturnType<typeof transferMutation>>;
	try {
		result = await transferMutation({
			variables: {
				input: {
					assetId,
					toBranchId: branchId,
				},
			},
			// force a refresh so that the info shows up in the UI
			refetchQueries: [openTransfersQuery],
		});
	} catch (e) {
		toastService.showError('An error occurred creating a transfer request. Try again later.');
		onComplete(false);
		return;
	}

	if (!result.data.createTransferOrder?.success) {
		const message =
			result.data.createTransferOrder?.message ?? 'An error occurred creating a transfer request. Try again later.';
		toastService.showError(message);
		onComplete(false);
		return;
	}

	toastService.showSuccess(`A transfer request has been created for #${assetId} and is awaiting approval.`);
	onComplete(true);
}

export default AssetTransferTab;
