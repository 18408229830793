import React from 'react';
import ngModule from '../../../ng/index.js';
import { react2angular } from 'react2angular';
import { ToastContainer } from '@equipmentshare/react-ui-components';

import styles from './toast-container.module.css';

const WrappedToastContainer = () => <ToastContainer className={styles.loaf} />;

ngModule.component('toastContainer', react2angular(WrappedToastContainer));
