import { MomentInput } from 'moment';

const BLUETOOTH_TAG = 5;
const EQUIPMENT_ASSET_TYPE_ID = 1;

type Status =
	| 'asset-off'
	| 'asset-bluetooth-node'
	| 'asset-running'
	| 'asset-heading'
	| 'asset-idling'
	| 'asset-being-hauled'
	| 'asset-not-communicating'
	| 'manual-location';

type AssetStatus = {
	engine_active?: boolean;
	is_being_hauled?: boolean;
	is_ble_node?: boolean;
	speed?: number;
	location_source?: string;
	start_idle_timestamp?: MomentInput;
	out_of_lock_timestamp?: MomentInput;
};
type Asset = {
	asset_type_id?: number;
	equipment_id?: number;
	asset_status?: AssetStatus;
	asset_tags?: {
		tag_id: number;
	}[];
};

export const getAssetStatusCode = (asset: Asset) => {
	const assetStatus = asset.asset_status;

	const isEngineOn = !!assetStatus?.engine_active;
	const isEquipment = asset.asset_type_id === EQUIPMENT_ASSET_TYPE_ID || !!asset.equipment_id;
	const isMoving = !!assetStatus?.engine_active && (assetStatus?.speed || 0) > 0;
	const isIdling = !!assetStatus?.start_idle_timestamp;
	const isBeingHauled = !!assetStatus?.is_being_hauled;
	const hasBluetooth =
		!!assetStatus?.is_ble_node || (asset.asset_tags || []).some((tag) => tag.tag_id === BLUETOOTH_TAG);
	const isOutOfLock = !!assetStatus?.out_of_lock_timestamp;
	const hasManualLocation = parseInt(assetStatus?.location_source || '', 10) === 2;

	const equipmentRunningConditions = isEquipment && isEngineOn;
	const vehicleRunningConditions = isEngineOn && !isMoving && !isIdling;

	const isRunning = isEquipment ? equipmentRunningConditions : vehicleRunningConditions;

	const statuses: Record<Status, boolean> = {
		'asset-off': !isEngineOn && !hasBluetooth,
		'asset-bluetooth-node': !isEngineOn && hasBluetooth,
		'asset-running': isRunning,
		'asset-heading': isEngineOn && !isEquipment && isMoving,
		'asset-idling': isEngineOn && !isEquipment && isIdling,
		'asset-being-hauled': isEquipment && isBeingHauled,
		'asset-not-communicating': isOutOfLock,
		'manual-location': hasManualLocation,
	};

	const precedence: Status[] = [
		'asset-off',
		'asset-bluetooth-node',
		'asset-running',
		'asset-heading',
		'asset-idling',
		'asset-being-hauled',
		'asset-not-communicating',
		'manual-location',
	];

	const defaultStatusCode = hasBluetooth ? 'asset-bluetooth-node' : 'asset-off';

	const statusCode = precedence.reduce((status, code) => {
		let newStatus = status;

		if (statuses[code]) {
			newStatus = code;
		}

		return newStatus;
	}, defaultStatusCode);

	return statusCode;
};
