import ngModule from '../ng/index.js';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.invoices', {
			url: `/invoices?${['query', 'branch', 'status', 'start', 'end', 'salesperson', 'company'].join('&')}`,
			redirectTo: 'home.transactions.invoices.search',
		})
		.state('home.invoices.create', {
			url: '/create?companyId&reference&date&memo',
			redirectTo: 'home.transactions.invoices.create',
		})
		.state('home.invoices.invoice', {
			url: '/:invoiceId',
			redirectTo: 'home.transactions.invoices.invoice',
		})
		.state('home.invoices.invoice.pdf', {
			url: '/pdf',
			redirectTo: 'home.transactions.invoices.invoices.pdf',
		});
});
