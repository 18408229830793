import ngModule from '../ng/index.js';
import locationsService from '@services/locations.service';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.redirect', {
			url: '/redirect',
			redirectTo: 'home.rentals',
		})
		.state('home.redirect.location', {
			url: '/location/:locationId',
			onEnter: ($state, $stateParams) => {
				const locationId = parseInt($stateParams.locationId, 10) || null;

				if (locationId) {
					locationsService.fetch(locationId).then((location) => {
						$state.go('home.companies.company.locations.location', {
							companyId: location.company_id,
							locationId: location.location_id,
						});
					});
				}
			},
		});
});
