import React from 'react';
import styled from 'styled-components';

import { Stack } from '@equipmentshare/es-admin-components';
import { EuiToolTip, EuiIcon, EuiText, THEME_TOKENS } from '@equipmentshare/ds2';
import { CloseFillIcon } from '@equipmentshare/ds2-icons';

import toastService from '@services/toast.service';

const types: Record<string, any> = {
	success: THEME_TOKENS.colors.LIGHT.success,
	error: THEME_TOKENS.colors.LIGHT.danger,
	info: THEME_TOKENS.colors.LIGHT.highlight,
};
const textColors: Record<string, string> = {
	success: 'black',
	error: 'white',
	info: 'black',
};

const Container = styled.div<{ type: string }>`
	background: ${(p) => types[p.type] || 'white'};
	min-width: 300px;
	color: ${(p) => textColors[p.type] || 'black'};
	pointer-events: all;
	max-width: 50vw;
	padding: 0.5rem 0.8rem;
`;

const Header = styled(Stack)`
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;
`;

const IconButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	color: inherit;
	padding: 0.2rem 0.5rem;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`;
const CloseButton = styled(IconButton)`
	padding: 0.2rem;
`;

export type ToastType = {
	id: number;
	title: string;
	type: string;
	description?: string;
	raw: string | null;
};

export type ToastProps = {
	toast: ToastType;
};

export const Toast: React.VFC<ToastProps> = ({ toast }) => (
	<Container type={toast.type}>
		<Header gap="3rem">
			<EuiText>
				<span>{toast.title}</span>
			</EuiText>
			<Stack gap=".2rem">
				{!!toast.raw && (
					<EuiToolTip content="Copy to Clipboard" position="bottom">
						<IconButton onClick={() => navigator.clipboard.writeText(toast.raw || '')}>Copy</IconButton>
					</EuiToolTip>
				)}
				<CloseButton onClick={() => toastService.burnToast(toast.id)}>
					<EuiIcon type={CloseFillIcon} />
				</CloseButton>
			</Stack>
		</Header>
		{toast.description && <span>{toast.description}</span>}
	</Container>
);

export default Toast;
