import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ExpandableRow from '../expandable-row/expandable-row.js';

const expandedRows = new Map();

export const useForceUpdate = () => {
	const update = useState()[1];

	return useCallback(() => update({}), [update]);
};

export const PersistentExpandableRow = ({ children, id, ...props }) => {
	const isExpanded = !!expandedRows.get(id);
	const forceUpdate = useForceUpdate();

	const handleExpandChange = useCallback(
		(expanded) => {
			expandedRows.set(id, expanded);

			forceUpdate();
		},
		[id]
	);

	return (
		<ExpandableRow {...props} expanded={isExpanded} onExpandedChange={handleExpandChange}>
			{children}
		</ExpandableRow>
	);
};

PersistentExpandableRow.propTypes = {
	children: PropTypes.node,
	id: PropTypes.number,
};

export default PersistentExpandableRow;
