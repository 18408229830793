import localforage from 'localforage';
import qs from 'qs';
import moment from 'moment';

const cacheStorage = localforage.createInstance({
	name: 'localforage',
	storeName: 'keyvaluepairs',
});
const metadataStorage = localforage.createInstance({
	name: 'localforage',
	storeName: 'metadata',
});

async function getMetadata(cacheKey) {
	const metadata = await metadataStorage.getItem(cacheKey);

	return metadata || {};
}
async function writeMetadata(cacheKey, metadata) {
	const old = await getMetadata(cacheKey);

	return await metadataStorage.setItem(cacheKey, { ...old, ...metadata });
}

function isMetadataExpired(metadata) {
	const now = new Date();

	return !metadata.expires || metadata.expires <= now;
}

async function pruneCache() {
	const cacheKeys = await cacheStorage.keys();

	for (const cacheKey of cacheKeys) {
		const metadata = await getMetadata(cacheKey);

		if (isMetadataExpired(metadata)) {
			cacheStorage.removeItem(cacheKey);
			metadataStorage.removeItem(cacheKey);
		}
	}
}

function createEntityKey(type, id, query) {
	return [type, id, query && qs.stringify(query)].filter(Boolean).join('|');
}

async function getItem(key) {
	const item = await cacheStorage.getItem(key);
	const metadata = await getMetadata(key);

	return item && !isMetadataExpired(metadata) ? item : null;
}

const DEFAULT_OPTS = {
	expirationTime: moment.duration(1, 'day').asMilliseconds(),
	cacheFirst: false,
};
function createCachingStrategy(opts = {}) {
	const options = { ...DEFAULT_OPTS, ...opts };

	return async (key, asyncFn, setResult) => {
		const item = await cacheStorage.getItem(key);
		const metadata = await getMetadata(key);

		if (item && !isMetadataExpired(metadata)) {
			if (setResult) {
				await setResult(item);
			}

			if (options.cacheFirst) {
				return item;
			}
		}

		const result = await asyncFn();

		await Promise.all([
			cacheStorage.setItem(key, result),
			writeMetadata(key, { expires: moment().add(options.expirationTime).toDate() }),
		]);

		if (setResult) {
			await setResult(result);
		}

		return result;
	};
}

const loadBoth = createCachingStrategy();
const cacheFirst = createCachingStrategy({
	cacheFirst: true,
	expirationTime: moment.duration(30, 'minutes').asMilliseconds(),
});

const localCache = {
	getItem,
	pruneCache,
	createEntityKey,
	createCachingStrategy,
	cacheFirst,
	loadBoth,
};

export default localCache;
