import ngModule from '../../ng/ng-module.js';
import { react2angular } from 'react2angular';
import RentalPricingEditForm from './rental-pricing-edit-form.js';
import withGlobalProviders from '@components/with-global-providers';

ngModule.component(
	'reactRentalPricingEditForm',
	react2angular(
		withGlobalProviders(RentalPricingEditForm),
		Object.keys(RentalPricingEditForm.propTypes).concat('forceUpdate')
	)
);
