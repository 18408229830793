import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Toast, ToastType } from './toast';
import toastService from '@services/toast.service';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	position: fixed;
	top: 1rem;
	left: 1rem;
	right: 1rem;
	pointer-events: none;
	z-index: 10000;
`;

const ToastStack: React.VFC = () => {
	const [toasts, setToasts] = useState<ToastType[]>([]);

	useEffect(() => {
		toastService.addListener(setToasts);

		return () => toastService.removeListener(setToasts);
	}, [setToasts]);

	return (
		<Container>
			{toasts.map((toast) => (
				<Toast key={toast.id} toast={toast} />
			))}
		</Container>
	);
};

export default ToastStack;
