import esApi from '@services/es-api.service';

export async function search(query, opts) {
	return await esApi.request(esApi.url`/admin/shifts`, query, opts);
}

const shiftsService = {
	search,
};

export default shiftsService;
