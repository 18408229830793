import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import _get from 'lodash/get';

import styles from '../rental-create-form-components/rental-create-form-components.module.css';

const RentalFloaterText = ({ rentalFloater, rentalFloaterStatus }) => {
	const [className, setClassName] = useState('negative');

	const message = useMemo(() => {
		setClassName('negative');
		if (!rentalFloaterStatus.exists || rentalFloaterStatus.isVoided) {
			return 'No Rental Floater Document on file';
		} else if (rentalFloaterStatus.isExpired && !rentalFloaterStatus.isInGracePeriod) {
			return `Rental Floater Doc expired on ${moment(_get(rentalFloater, 'valid_until')).format('M/D/YYYY')}`;
		} else if (rentalFloaterStatus.isInGracePeriod) {
			setClassName('neutral');
			return `Rental Floater Doc expired on ${moment(_get(rentalFloater, 'valid_until')).format(
				'M/D/YYYY'
			)} but is still within the 30 day grace period"}`;
		}
		setClassName('positive');
		return `Rental Floater Doc expires on ${moment(_get(rentalFloater, 'valid_until')).format('M/D/YYYY')}`;
	}, [rentalFloaterStatus]);

	return <div className={classNames([styles[className], styles.floaterText])}>{message}</div>;
};

RentalFloaterText.propTypes = {
	rentalFloater: PropTypes.object,
	rentalFloaterStatus: PropTypes.object,
};

export default RentalFloaterText;
