import React from 'react';
import moment from 'moment-timezone';

import { EuiBadge, COLORSLIGHT } from '@equipmentshare/ds2';

import branchRentalRatesService from '@services/branch-rental-rates.service';
import companyRentalRatesService from '@services/company-rental-rates.service';
import locationRentalRatesService from '@services/location-rental-rates.service';
import xFields from './providers/xFields';

import { formatCurrency, formatDate, formatPercent } from '../../js/formatting';
import { formatAddress } from '@equipmentshare/admin-hooks';

import styles from './rates-table/rates-table.module.css';

const EXPIRED_STRING = 'Expired';
const VOIDED_STRING = 'Voided';

export const TODAY = moment().startOf('day');

export const dateToISO = (_date) => moment(_date).startOf('day').toDate().toISOString();

const getName = (firstName = null, lastName = null) => {
	return `${firstName ? `${firstName} ` : ''}${lastName ? `${lastName} ` : ''}`;
};

export const getPriceByType = (rate, priceProp) => {
	if (rate?.percent_discount) {
		return renderPercentCellDatum(rate.percent_discount);
	}
	return renderCurrencyCellDatum(rate[priceProp]);
};

const renderChip = (title, label) => {
	return (
		<div className={styles.chipWrapper} title={title}>
			<EuiBadge className={styles.chip} color={COLORSLIGHT.darkShade}>
				{label}
			</EuiBadge>
		</div>
	);
};

export const getExpiredCellDataForExport = (rate) => {
	const expires = renderExpiresCellDatum(rate);

	return expires.label ? expires.label : expires.dt;
};

export const renderExpiresCellDatum = ({ _voided, date_voided, voided_by_user, end_date }) => {
	// Voided
	if (_voided) {
		return {
			dt: formatDate(date_voided),
			label: `${VOIDED_STRING} ${formatDate(date_voided)} By ${getName(
				voided_by_user.first_name,
				voided_by_user.last_name
			)}`,
		};
	}

	// Expired
	if (moment(end_date).isBefore(TODAY)) {
		return {
			dt: formatDate(end_date),
			label: `${EXPIRED_STRING} ${formatDate(end_date)}`,
		};
	}

	return {
		dt: formatDate(end_date),
		label: null,
	};
};

export const renderExpiresCellChip = (rate) => {
	const chipDatum = renderExpiresCellDatum(rate);

	if (chipDatum?.label?.includes(EXPIRED_STRING) || chipDatum?.label?.includes(VOIDED_STRING)) {
		return renderChip(
			chipDatum.label,
			rate._voided ? `${VOIDED_STRING} ${chipDatum.dt}` : `${EXPIRED_STRING} ${chipDatum.dt}`
		);
	}

	return chipDatum.dt;
};

export const renderCurrencyCellDatum = (amount) => formatCurrency(amount) || '--';

export const renderPercentCellDatum = (amount) => `-${formatPercent(amount)}`;

export const renderDateCellDatum = (_date) => formatDate(_date);

export const renderAddedByCellDatum = (rate) => {
	const createdBy = rate.created_by_user;
	const firstName = createdBy?.first_name || '';
	const lastName = createdBy?.last_name || '';
	return getName(firstName, lastName);
};

export const renderLocationCellDatum = (rate) => {
	const location = rate?.location || null;
	return location ? `${location?.nickname} - ${formatAddress(location)}` : '';
};

export const getRatesServiceData = (rateType) => {
	switch (rateType) {
		case 'branch':
			return {
				service: branchRentalRatesService,
				xFields: xFields.branchXFields,
			};
		case 'company':
			return {
				service: companyRentalRatesService,
				xFields: xFields.companyXFields,
			};
		case 'location':
			return {
				service: locationRentalRatesService,
				xFields: xFields.locationXFields,
			};
		default:
			return null;
	}
};
