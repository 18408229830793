import { getUrls, getVendorSettings } from "@equipmentshare/env-config";
import { getEnvironment } from "@equipmentshare/which-env";

angular.module( "esApiClient" ).service( "esEnv", esEnv );

function esEnv( $window ) {
  const { api, cdn } = getUrls( $window );
  const { segmentKey } = getVendorSettings( $window );
  const env = getEnvironment( $window ) === "production" ? "production" : "development";

  this.urlRoot = api;
  this.apiHost = api;
  this.cdnHost = cdn;
  this.envLevel = env;
  this.vars = { segmentKey };

  this.read = ( key ) => this.vars[key];
  this.getHost = () => this.apiHost;
  this.getCdnHost = () => this.cdnHost;
  this.getEnvLevel = () => this.envLevel;

  return this;
}
