import angular from 'angular';
import template from './takeover-card.html';
import "./takeover-card-ctrl.js";

angular.module("esNgComponents").directive('takeoverCard', function (takeoverCard, $compile, $timeout, $log) {
  function link(scope, element, attrs) {
    $log.info("takeoverCard link()");
    $log.debug("link > scope.takeoverCardId", scope.takeoverCardId);
    takeoverCard.setCardElement(scope.takeoverCardId, element);
  }

  return {
    transclude: true,
    replace: true,
    template,
    controller: 'TakeoverCardCtrl',
    link: link,
    scope: {
      title: '@',
      cardTitle: '@',
      takeoverCardId: '@',
      maxHeight: '@',
      expandable: '<',
      onClose: '&',
      removeContentOnClose: '<'
    }
  };
});
