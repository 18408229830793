import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Input from "../input/input.js";

/**
 * Any props passed in will get passed down into the Input component, the only
 * prop that gets modified is onChange
 */
export class StrictNumberInput extends PureComponent {
  constructor( props ) {
    super( props );

    this.onChange = this.onChange.bind( this );
  }

  onChange( event ) {
    if ( String( event.target.value ).match( /^\d*$/ ) ) {
      this.props.onChange( event );
    }
  }

  render() {
    return (
      <Input {...this.props} onChange={this.onChange} type="text" />
    );
  }
}

StrictNumberInput.propTypes = {
  /** onChange event handler */
  onChange: PropTypes.func.isRequired,
};

export default StrictNumberInput;
