import localCache from '@services/local-cache.service';
import useAsyncLoader from './use-async-loader';

export function useCachedLoader(cacheKey, fn, watch, wait) {
	return useAsyncLoader(
		({ setLoaded, setResult, setLoading }) => {
			return localCache.loadBoth(cacheKey, fn, (result) => {
				setResult(result);
				setLoaded(true);
				setLoading(false);
			});
		},
		watch,
		wait
	);
}

export default useCachedLoader;
