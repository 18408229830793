import ngModule from '../../ng/index.js';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';
import _forEach from 'lodash/map';
import _filter from 'lodash/filter';
import Rx from 'rxjs';

import moment from 'moment-timezone';

function esRentalOverdueTasksService(esApiFactoryV2) {
	const service = this;
	const taskStreamsByMarket = {};

	function getOrCreateMarketStream(marketId) {
		if (!taskStreamsByMarket[marketId]) {
			taskStreamsByMarket[marketId] = new Rx.Subject();
		}

		return taskStreamsByMarket[marketId];
	}

	/**
	 * Return an Rx Observable that yields a list of overdue tasks, grouped into objects by rental:
	 *
	 *     [
	 *       { rental_id: <rental_id>, tasks: [ .. ] },
	 *       ..
	 *     ]
	 *
	 * The observable will yield a new list each time this service is asked to refresh,
	 * hence any subscriber can use this to get updates whenever the overdue tasks change.
	 */
	service.observeOverdueTasks = function observeOverdueTasks(marketId, now) {
		const stream = getOrCreateMarketStream(marketId);

		service.refresh(marketId, now);

		return stream;
	};

	service.refresh = function refresh(marketId, now) {
		if (!taskStreamsByMarket[marketId]) {
			// If an overdue task stream falls in the forest, and noone is subscribed to it, it does not make a sound.
			return;
		}

		now = now || moment();
		const highestDueDate = (group) =>
			_reduce(group.tasks, (val, next) => (val.isBefore(next.due_date) ? val : moment(next.due_date)), moment());
		const isBeforeCurrentCalendarDay = (task) => moment(task.due_date).startOf('day').isBefore(now.startOf('day'));

		esApiFactoryV2.adminGetOverdueRentalTasks({ market_id: marketId }).then((groups) => {
			_forEach(groups, (_) => (_.tasks = _filter(_.tasks, isBeforeCurrentCalendarDay)));
			groups = _filter(groups, (_) => _.tasks.length > 0);
			groups = _sortBy(groups, [highestDueDate]);
			getOrCreateMarketStream(marketId).next(groups);
		});
	};

	return {
		observeOverdueTasks: service.observeOverdueTasks,
		refresh: service.refresh,
	};
}

ngModule.factory('esRentalOverdueTasksService', esRentalOverdueTasksService);
