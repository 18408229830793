import React from 'react';
import styled from 'styled-components';

import { EuiLoadingSpinner } from '@equipmentshare/ds2';
import type { EuiLoadingSpinnerProps } from '@equipmentshare/ds2';

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem;
`;

export type CenteredLoadingSpinnerProps = EuiLoadingSpinnerProps;

export const CenteredLoadingSpinner: React.VFC<CenteredLoadingSpinnerProps> = ({ className, ...props }) => {
	return (
		<SpinnerContainer className={className}>
			<EuiLoadingSpinner {...props} />
		</SpinnerContainer>
	);
};

export default CenteredLoadingSpinner;
