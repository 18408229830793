import angular from 'angular';
import template from './make-picker.html';

angular.module("esNgComponents")
  .directive('makePicker', function () {
    return {
      template,
      controller: 'MakePickerCtrl as ctrl',
      scope: {
        ngModel: '=',
        selectedMake: '=',
        selectLabel: '@',
        isRequired: '<'
      }
    };
  })
  .controller('MakePickerCtrl', function ($scope, esApiFactoryV2) {
    var controller = this;

    $scope.$watch('ngModel', function (newModel, oldModel) {
      if (newModel === undefined) {
        $scope.selectedMake = undefined;
        $scope.make = {};
        $scope.searchText = '';
      }
    });

    controller.querySearch = function (query) {
      return esApiFactoryV2.queryMakes(query)
        .then(function (response) {
          return response;
        });
    };

    controller.selectMake = function (make) {
      if (make) {
        $scope.ngModel = make.equipment_make_id;
      } else {
        $scope.ngModel = null;
      }
    };
  });
