import React, { createContext, useContext, useState, useCallback } from 'react';

const CancellationContext = createContext(null);

export const useCancellation = () => {
	const context = useContext(CancellationContext);
	if (!context) {
		throw new Error('useCancellation must be used within CancellationProvider');
	}
	return context;
};

export const CancellationProvider = ({ children }) => {
	const [state, setState] = useState({
		isOpen: false,
		buttonElement: null,
		rentalId: null,
		cancelRental: null,
		container: null,
	});

	const showCancellation = useCallback((data) => {
		setState({
			isOpen: true,
			...data,
		});
	}, []);

	const hideCancellation = useCallback(() => {
		setState((prev) => {
			if (prev.container && prev.container.parentNode) {
				prev.container.parentNode.removeChild(prev.container);
			}
			return {
				...prev,
				isOpen: false,
			};
		});
	}, []);

	React.useEffect(() => {
		window.__RENTAL_CANCELLATION__ = {
			showCancellation,
			hideCancellation,
		};
		return () => {
			delete window.__RENTAL_CANCELLATION__;
		};
	}, [showCancellation, hideCancellation]);

	return (
		<CancellationContext.Provider value={[state, showCancellation, hideCancellation]}>
			{children}
		</CancellationContext.Provider>
	);
};

export { CancellationContext };
