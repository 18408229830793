import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';

import { Autocomplete } from '@equipmentshare/react-ui-components';

import useEquipmentClassLoader from '../../hooks/data/use-equipment-class-loader.js';

const xFields = '{ category_id, equipment_class_id, name }';

/**
 * @deprecated use EquipmentClassPicker from es-admin-componnets instead
 */
const EquipmentClassPicker = ({
	onChange,
	showMiscellaneousFirst,
	placeholder = 'Search Equipment Classes',
	selectedCategoryId,
	selectedEquipmentClassId,
	showLoading = true,
	sortBy = 'name',
	xFields: overrideXFields,
	...rest
}) => {
	const initialSelectedEquipmentClass =
		selectedCategoryId && !selectedEquipmentClassId ? null : selectedEquipmentClassId;

	const sortWithMiscellaneous = (equipmentClassData) => {
		const sortedEquipmentClasses = _sortBy(equipmentClassData, sortBy);
		const miscellaneousOption = _remove(
			sortedEquipmentClasses,
			(equipmentClass) => equipmentClass.name === 'Misc Equipment'
		);
		return [...miscellaneousOption, ...sortedEquipmentClasses];
	};

	const query = useMemo(() => {
		return selectedCategoryId ? { category_id: selectedCategoryId } : null;
	}, [selectedCategoryId, selectedEquipmentClassId]);

	const { data: equipmentClasses = [], isLoading } = useEquipmentClassLoader(query, {
		xFields: overrideXFields || xFields,
	});

	const sortedEquipmentClasses = useMemo(() => {
		return showMiscellaneousFirst ? sortWithMiscellaneous(equipmentClasses) : _sortBy(equipmentClasses, sortBy);
	}, [equipmentClasses]);

	return (
		<Autocomplete
			isLoading={showLoading ? isLoading : null}
			labelKey="name"
			onChange={onChange}
			options={sortedEquipmentClasses}
			placeholder={placeholder}
			simpleValue={false}
			value={initialSelectedEquipmentClass}
			valueKey="equipment_class_id"
			{...rest}
		/>
	);
};

EquipmentClassPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	showMiscellaneousFirst: PropTypes.bool,
	placeholder: PropTypes.string,
	selectedCategoryId: PropTypes.number,
	selectedEquipmentClassId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
	showLoading: PropTypes.bool,
	sortBy: PropTypes.string,
	xFields: PropTypes.string,
};

export default EquipmentClassPicker;
