import angular from 'angular';
import template from './table-row-column.html';

angular.module("esNgComponents").component('tableRowColumn', {
  template,
  transclude: true,
  bindings: {
    layout: '@',
    layoutAlign: '@'
  }
});
