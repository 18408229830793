import React from 'react';
import { useAuth0 } from '@equipmentshare/auth0-react';
import { FlagProvider } from '@equipmentshare/feature-flags';
import { LAUNCH_DARKLY_CLIENT_ID } from '../const/env';
import { CancellationProvider } from './cancellation-context';
import RentalCancellationPortal from '../js/components/rental-status-actions/rental-cancellation-portal';

export const FeatureFlagProvider: React.FC = ({ children }) => {
	const { isLoading: loadingAuth } = useAuth0();

	if (loadingAuth) {
		return <>{children}</>;
	}

	return (
		<FlagProvider
			clientId={LAUNCH_DARKLY_CLIENT_ID as string}
			flags={{
				'enable-asset-transfer-tab': false,
				'enable-asset-transfer-request': false,
				'enable-billing-disputes-admin': false,
				'enable-billing-node-service-invoice-approval': false,
				'enable-billing-invoice-approval': false,
				'enable-fleet-restrict-federated-user-fields': false,
				'enable-billing-pay-from-admin-company-transactions-invoice-view': true,
				'enable-billing-delete-rental-invoice': false,
				EnableDCOGetOriginalContract: false,
				EnableDCOOrderNoteAlignment: false,
				EnableDCOCancelRentalReason: false,
			}}
		>
			<CancellationProvider>
				{children}
				<RentalCancellationPortal />
			</CancellationProvider>
		</FlagProvider>
	);
};

export default FeatureFlagProvider;
