import ngModule from '../../../../ng/index.js';
import _clone from 'lodash/clone';
import moment from 'moment-timezone';
import { createErrorHandler } from '../../../../state/helpers.js';
import toastService from '@services/toast.service.js';

import template from './service-record-edit-form.html';

ngModule
	.component('serviceRecordEditForm', {
		template,
		controller: 'ServiceRecordEditFormCtrl',
		bindings: {
			serviceRecordId: '<',
			onSave: '&',
			onCancel: '&',
		},
	})
	.controller(
		'ServiceRecordEditFormCtrl',
		function ServiceRecordEditFormCtrl($scope, esAdminMaintenanceService, $mdDialog) {
			const ctrl = this;

			ctrl.$onChanges = (changes) => {
				if (ctrl.serviceRecordId) {
					esAdminMaintenanceService
						.getServiceRecord(ctrl.serviceRecordId)
						.then((record) => ($scope.record = record))
						.then(ctrl.updateModelFromRecord)
						.catch(createErrorHandler('Failed to load service records'));
				}
			};

			$scope.submitting = false;
			$scope.model = {};

			$scope.cancel = ctrl.onCancel;
			$scope.submitForm = () => {
				$scope.submitting = true;

				const payload = new FormData();

				payload.append('date_completed', moment($scope.model.date_completed).valueOf());
				payload.append('service_provider', $scope.model.service_provider);
				payload.append('cost_labor', $scope.model.cost_labor);
				payload.append('cost_parts', $scope.model.cost_parts);
				payload.append('description', $scope.model.description);

				if ($scope.model.file) {
					payload.append('file', $scope.model.file);
				}

				esAdminMaintenanceService
					.patchServiceRecord(ctrl.serviceRecordId, payload)
					.then(() => {
						toastService.showSuccess('Updated service record');
						ctrl.onSave();
					})
					.catch(createErrorHandler('Failed to update service record'))
					.then(() => ($scope.submitting = false));
			};

			$scope.deleteRecordFile = () => {
				const confirm = $mdDialog
					.confirm()
					.title('Delete Service Report File?')
					.textContent('Are you sure you want to delete the file for this service report?')
					.ariaLabel('Delete File')
					.cancel('Cancel')
					.ok('Delete File');

				$mdDialog.show(confirm).then(ctrl.deleteRecordFile);
			};

			ctrl.deleteRecordFile = () => {
				$scope.submitting = true;

				const payload = new FormData();

				payload.append('filename', null);

				return esAdminMaintenanceService
					.patchServiceRecord(ctrl.serviceRecordId, payload)
					.then(() => {
						toastService.showSuccess('Deleted Service Report File');
					})
					.catch(createErrorHandler('There was an error deleting the Service Report file.'))
					.then(() => ($scope.submitting = false));
			};

			ctrl.updateModelFromRecord = (record) => {
				$scope.model = _clone(record);

				$scope.model.date_completed = moment($scope.model.date_completed).toDate();
			};
		}
	);
