import esApi from './es-api.service';

export async function fetch(id, query, opts) {
	return await esApi.request(esApi.url`/models/${id}`, query, opts);
}

export async function fetchAll() {
	return await esApi.request(esApi.url`/models`);
}

export async function search(query, opts) {
	return await esApi.request(esApi.url`/models`, query, opts);
}

const makesService = {
	fetch,
	fetchAll,
	search,
};

export default makesService;
