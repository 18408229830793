import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { VariableSizeList } from "react-window";

export function getCurrentIndex ( children ) {
  return Math.max(
    children.findIndex( ( { props } ) => props.isFocused ),
    0
  );
}

class MenuList extends PureComponent {
  constructor ( props ) {
    super( props );

    this.list = React.createRef();
    this.getItemSize = this.getItemSize.bind( this );

    this.state = {
      currentIndex: 0,
    };
  }

  componentDidUpdate() {
    this.scrollSelectedIntoView();
  }

  scrollSelectedIntoView() {
    const { children } = this.props;
    const itemData = React.Children.toArray( children );
    const currentIndex = getCurrentIndex( itemData );

    if ( currentIndex >= 1 ) {
      this.list.current.scrollToItem( currentIndex );
    }
  }

  getItemSize () {
    const { selectProps } = this.props;
    const { optionHeight } = selectProps;

    return optionHeight;
  }

  render() {
    const { maxHeight, children, selectProps } = this.props;
    const { optionHeight } = selectProps;

    const itemData = React.Children.toArray( children );
    const numberOfOptionsInMenu = React.Children.count( children );
    const height = Math.min( maxHeight, numberOfOptionsInMenu ? optionHeight * numberOfOptionsInMenu : optionHeight );

    return (
      <VariableSizeList
        estimatedItemSize={35}
        height={height}
        itemCount={itemData.length}
        itemData={itemData}
        itemSize={this.getItemSize}
        ref={this.list}
      >
        {( { data, index, style } ) => {
          return (
            <div style={style}>
              {data[index]}
            </div>
          );
        }}
      </VariableSizeList>
    );
  }
}

MenuList.propTypes = {
  children: PropTypes.any.isRequired,
  maxHeight: PropTypes.number.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export default MenuList;
