import React, { useContext, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

const Context = React.createContext();

export function useSelection() {
	return useContext(Context);
}

function useFunctionalOnChange(prop, onChange) {
	return useCallback(
		(state) => {
			if (onChange) {
				const value = typeof state === 'function' ? state(prop) : state;

				onChange(value);
			}
		},
		[prop, onChange]
	);
}
function useStateOrProps(prop, onChange, initValue) {
	const [state, setState] = useState(initValue);
	const functionalOnChange = useFunctionalOnChange(prop, onChange);

	if (prop === null) {
		return [state, setState];
	} else {
		return [prop, functionalOnChange];
	}
}

const SelectionProvider = ({ children, ids, selectedIds: propSelectedIds, onChange }) => {
	const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

	const [selectedIds, setSelectedIds] = useStateOrProps(propSelectedIds, onChange, []);

	const saveLastSelectedIndex = useCallback(
		(id) => {
			setLastSelectedIndex(() => (ids.includes(id) ? ids.indexOf(id) : null));
		},
		[setLastSelectedIndex, ids]
	);

	const selectSingleId = useCallback(
		(selectId) => {
			setSelectedIds((selected) => {
				if (selected.includes(selectId)) {
					return selected.filter((id) => id !== selectId);
				} else {
					return selected.concat(selectId);
				}
			});

			saveLastSelectedIndex(selectId);
		},
		[saveLastSelectedIndex, setSelectedIds]
	);

	const selectMultiId = useCallback(
		(selectId) => {
			setSelectedIds((selected) => {
				const idx1 = lastSelectedIndex;
				const idx2 = ids.indexOf(selectId);
				const effectedIds = ids.slice(Math.min(idx1, idx2), Math.max(idx1, idx2) + 1);

				const removedIds = effectedIds.filter((id) => selected.includes(id));
				const addedIds = effectedIds.filter((id) => !selected.includes(id));

				if (!selected.includes(selectId)) {
					return selected.concat(addedIds);
				} else {
					return selected.filter((id) => !removedIds.includes(id));
				}
			});

			saveLastSelectedIndex(selectId);
		},
		[ids, lastSelectedIndex, saveLastSelectedIndex, setSelectedIds]
	);

	const selectAllIds = useCallback(() => {
		setSelectedIds((selected) => {
			const notSelectedId = ids.find((id) => !selected.includes(id));

			if (notSelectedId !== undefined) {
				return Array.from(ids);
			} else {
				return [];
			}
		});
	}, [ids, setSelectedIds]);

	const context = useMemo(
		() => ({
			selectSingleId,
			selectMultiId,
			selectAllIds,
			selectedIds,
			ids,
		}),
		[selectSingleId, selectMultiId, selectAllIds, selectedIds, ids]
	);

	return <Context.Provider value={context}>{children}</Context.Provider>;
};

SelectionProvider.Context = Context;
SelectionProvider.propTypes = {
	children: PropTypes.node,
	idKey: PropTypes.string,
	ids: PropTypes.arrayOf(PropTypes.number).isRequired,
	onChange: PropTypes.func,
	selectedIds: PropTypes.arrayOf(PropTypes.number),
};

export default SelectionProvider;
