import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useSort } from '../providers/sort-provider.js';

const SortedRows = ({ rows, children }) => {
	const { sortObjects } = useSort();

	const sortedRows = useMemo(() => sortObjects(rows), [sortObjects, rows]);

	if (typeof children === 'function') {
		return children(sortedRows);
	} else {
		return children;
	}
};

SortedRows.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default SortedRows;
