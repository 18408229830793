import angular from 'angular';
import moment from 'moment-timezone';
import ngModule from './ng-module';

ngModule
	.filter('esApiDate', () => {
		return (dateString) => {
			return new Date(dateString);
		};
	})
	.filter('localeShortDate', () => {
		const cache = new Map();

		return (dateObject) => {
			const dateValue = dateObject.valueOf();
			if (!cache.has(dateValue)) {
				cache.set(dateValue, dateObject.toLocaleDateString('short'));
			}

			return cache.get(dateValue);
		};
	})
	.filter('localeShort', () => {
		const cache = new Map();

		return (dateObject) => {
			const dateValue = dateObject.valueOf();
			if (!cache.has(dateValue)) {
				cache.set(dateValue, dateObject.toLocaleString('short'));
			}

			return cache.get(dateValue);
		};
	})
	.filter('dateFromWeeks', () => {
		return (weeksAfterEpoch) => {
			return new Date(weeksAfterEpoch * 604800000);
		};
	})
	.filter('totalHours', () => {
		return (timeInMillis) => {
			return (timeInMillis / (1000 * 60 * 60)) % 24;
		};
	})
	.filter('totalMinutes', () => {
		return (timeInMillis) => {
			return (timeInMillis / (1000 * 60)) % 60;
		};
	})
	.filter('padMinutes', () => {
		return (input) => {
			if (input < 10) {
				input = `0${input}`;
			}

			return input;
		};
	})
	.filter('miles', () => {
		return (kilometers) => {
			return kilometers * 0.62137;
		};
	})
	.filter('gallons', () => {
		return (liters) => {
			return liters * 0.26417;
		};
	})
	.filter('moment', () => {
		return (date, format = 'MMM D, YYYY') => {
			return moment(date).format(format);
		};
	})
	.filter('fromNow', () => {
		return (dateString) => {
			return moment(dateString).fromNow();
		};
	})
	.filter('money', () => {
		return (v) => {
			return v ? `$${parseFloat(v).toFixed(2)}` : 'N/A';
		};
	})
	.filter('percent', () => {
		return (v) => {
			return v ? `${parseFloat(v) * 100}%` : 'N/A';
		};
	})
	.filter('emptyToEnd', () => {
		return (array, key) => {
			if (!angular.isArray(array)) {
				return;
			}
			const present = array.filter((item) => {
				return item[key];
			});
			const empty = array.filter((item) => {
				return !item[key];
			});

			return present.concat(empty);
		};
	})
	.filter('phone', () => {
		return (value, format = '($1 ) $2-$3') => {
			return String(value).replace(/.*(\d{3} ).*(\d{3} ).*(\d{4} )/, format);
		};
	})
	.filter('esApiLocation', () => {
		return (location, type = 'single-line') => {
			if (!location) {
				return '';
			}
			const parts = [];

			switch (type) {
				default:
				case 'single-line':
					if (location.nickname && location.nickname !== location.street_1) {
						parts.push(`${location.nickname},`);
					}
					if (location.street_1) {
						parts.push(location.street_1);
					}
					if (location.city && location.state) {
						parts.push(`${location.city}, ${location.state.abbreviation}`);
					}

					return parts.join(' ');
			}
		};
	})
	.filter('tel', () => {
		return function (tel) {
			if (!tel) {
				return '';
			}

			const value = tel.toString().trim().replace(/\D/g, '');

			let country;
			let city;
			let number;

			switch (value.length) {
				case 10: // +1PPP####### -> C (PPP) ###-####
					country = 1;
					city = value.slice(0, 3);
					number = value.slice(3);
					break;

				case 11: // +CPPP####### -> CCC (PP) ###-####
					country = value[0];
					city = value.slice(1, 4);
					number = value.slice(4);
					break;

				case 12: // +CCCPP####### -> CCC (PP) ###-####
					country = value.slice(0, 3);
					city = value.slice(3, 5);
					number = value.slice(5);
					break;

				default:
					return tel;
			}

			if (country === 1) {
				country = '';
			}

			number = `${number.slice(0, 3)}-${number.slice(3)}`;

			return `${country} (${city}) ${number}`.trim();
		};
	});
