import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Portal } from "react-portal";

import SideDrawer from "./side-drawer.js";

import styles from "./side-drawer-creator.module.css";

class SideDrawerCreator extends PureComponent {
  constructor( props ) {
    super( props );

    this.handleSideDrawerClose = this.handleSideDrawerClose.bind( this );
  }

  UNSAFE_componentWillMount() {
    this.containerElement = document.createElement( "div" );
    this.containerElement.classList.add( styles.container );

    document.body.appendChild( this.containerElement );
  }

  componentWillUnmount() {
    if ( this.isContainerInDOM() ) {
      this.containerElement.remove();
    }
  }

  handleSideDrawerClose() {
    if ( this.isContainerInDOM() ) {
      const { onClose } = this.props;

      if ( onClose ) {
        onClose();
      }
    }
  }

  isContainerInDOM() {
    return !!this.containerElement.parentNode;
  }

  render() {
    const { children, isOpen, onClose, title, showBackdrop, closeOnBackdropClick, drawerWidth, scrollable } = this.props;

    return (
      <Portal node={this.containerElement}>
        <SideDrawer
          closeOnBackdropClick={closeOnBackdropClick}
          drawerWidth={drawerWidth}
          isOpen={isOpen}
          onClose={onClose}
          scrollable={scrollable}
          showBackdrop={showBackdrop}
          title={title}
        >
          {children}
        </SideDrawer>
      </Portal>
    );
  }
}

SideDrawerCreator.propTypes = {
  children: PropTypes.node,
  closeOnBackdropClick: PropTypes.bool,
  drawerWidth: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  showBackdrop: PropTypes.bool,
  title: PropTypes.node
};

SideDrawerCreator.defaultProps = {
  children: null,
  closeOnBackdropClick: true,
  drawerWidth: null,
  isOpen: false,
  onClose: null,
  scrollable: false,
  showBackdrop: true,
  title: null,
};

export default SideDrawerCreator;
