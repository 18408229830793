import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import { ControlledPopoverCreator } from '@equipmentshare/react-ui-components';
import { EuiLoadingSpinner } from '@equipmentshare/ds2';

import FilterDisplayButton from './filter-display-button';
import FilteredChipGroup from './filtered-chip-group';
import FilterSelector from './filter-selector';

import { useRatesProvider } from '../providers/rates-provider';
import { useFilterDispatch, useFilterState } from '../providers/filter-provider';
import { useLayoutProvider } from '../providers/layout-provider';

import styles from './filter.module.css';

const FilterPopover = ({ rateType }) => {
	const { dispatcher } = useFilterDispatch();
	const { state: filterState } = useFilterState();
	const { ratesCount, resetRates } = useRatesProvider();
	const { isLoading } = useLayoutProvider();

	const [filterSelectorIsVisible, setFilterSelectorIsVisible] = useState(false);
	const [filterChips, setFilterChips] = useState([]);

	useEffect(() => {
		const initFilterSelectorTimer = setTimeout(handleFilterToggle, 10);
		return () => clearTimeout(initFilterSelectorTimer);
	}, []);

	const handleFilterToggle = () => {
		setFilterSelectorIsVisible(!filterSelectorIsVisible);
	};

	const getFilterChips = () => {
		const activeFilters = dispatcher.getFilterChips();
		setFilterChips(activeFilters);
	};

	const handleApplyFilters = () => {
		getFilterChips();
		handleFilterToggle();
		dispatcher.createFilterQueryItems();
	};

	const handleClearFilters = () => {
		setFilterChips([]);
		dispatcher.clearFilters(rateType);
		resetRates();
	};

	const handleChipRemove = (filter) => {
		const updatedActiveFilters = filterChips.filter((item) => item._id !== filter._id);

		setFilterChips(updatedActiveFilters);
		dispatcher.removeFilterChip(filter);
		if (!updatedActiveFilters.length) {
			handleClearFilters();
			handleFilterToggle();
		}
	};

	const filterSelector = (
		<FilterSelector
			onApplyFilters={handleApplyFilters}
			onClearFilters={handleClearFilters}
			onClose={handleFilterToggle}
		/>
	);

	return (
		<div className={styles.layout}>
			<div className={styles.header}>
				<ControlledPopoverCreator
					align="bottom right"
					anchor="sw"
					content={filterSelector}
					isOpen={filterSelectorIsVisible}
					offset={{ top: 6 }}
					onClose={() => {}}
				>
					<FilterDisplayButton
						className={styles.filterButton}
						onClick={handleFilterToggle}
						tooltipText={`${filterSelectorIsVisible ? 'Close' : 'Open'} Filter Selector`}
					/>
				</ControlledPopoverCreator>

				<FilteredChipGroup chipClassName={styles.filteredChip} filters={filterChips} onClose={handleChipRemove}>
					{!_isEmpty(filterState.filterQuery) && (
						<h4 className={styles.results}>
							{isLoading ? (
								<div className={styles.resultsLoader}>
									<EuiLoadingSpinner size="l" />
								</div>
							) : (
								`${ratesCount} results`
							)}
						</h4>
					)}
				</FilteredChipGroup>
			</div>
		</div>
	);
};

FilterPopover.propTypes = {
	rateType: PropTypes.string,
};

export default FilterPopover;
