import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@equipmentshare/react-ui-components';

import errorHandlerService from '@services/error-handler.service';
import partsService from '@services/parts.service';
import storePartsQuery from '@services/graphql/inventory/queries/store-parts.js';
import partsQuery from '@services/graphql/inventory/queries/parts.js';

const PART_QUERY_DEBOUNCE_KEY = 'PART_PICKER_QUERY_DEBOUNCE_KEY';

const PartTypePicker = ({
	apolloClient,
	branchId,
	onChange,
	placeholder,
	queryOptions = { fetchPolicy: 'network-only' },
	selectedPartId,
	...rest
}) => {
	const [loaded, setLoaded] = useState(true);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (selectedPartId) {
			getParts({ partIds: [selectedPartId], branchIds: branchId ? [branchId] : [], excludeEmptyQuantity: false }, true);
		}
	}, []);

	const processPartsQuery = (parts) => {
		return parts.map((p) => {
			p.label = partsService.getPartLabel(p);

			return p;
		});
	};

	const processStorePartsQuery = (storeParts) => {
		return storeParts.map((sp) => {
			const p = {
				cost: sp.cost,
				availableQuantity: sp.availableQuantity,
				...sp.part,
				partTypeId: sp.part.partClass.id,
			};

			p.label = `${p.partNumber} - ${p.provider.name} - ${partsService.getPartLabel(p)}`;

			return p;
		});
	};

	const setAllPartsVariables = (search) => {
		return {
			search,
			size: 10,
		};
	};

	const setStorePartsByBranchVariables = (search) => {
		return {
			search,
			branchIds: [branchId],
			size: 10,
		};
	};

	const queryBody = useMemo(() => {
		if (branchId) {
			return {
				processQuery: processStorePartsQuery,
				query: storePartsQuery,
				queryKey: 'storeParts',
				setVariables: setStorePartsByBranchVariables,
			};
		} else {
			return {
				processQuery: processPartsQuery,
				query: partsQuery,
				queryKey: 'parts',
				setVariables: setAllPartsVariables,
			};
		}
	}, [branchId]);

	const getParts = (variables = '', selectInitialOption = false) => {
		setLoaded(false);

		return apolloClient
			.query({
				...queryOptions,
				context: {
					PART_QUERY_DEBOUNCE_KEY,
				},
				query: queryBody.query,
				variables,
			})
			.then(({ data }) => {
				let _options = [];
				if (data) {
					_options = data[queryBody.queryKey];
					_options = queryBody.processQuery(_options);

					if (selectInitialOption) {
						if (_options.length > 0) {
							const selectedOpt = _options[0];
							onChange(selectedOpt.id, selectedOpt);
						}
					}
				}

				setLoaded(true);
				setOptions(_options);
			})
			.catch((err) => {
				errorHandlerService.genericErrorHandler(err, 'Failed to retrieve parts');
				setLoaded(true);
				setOptions([]);
			});
	};

	const handleSearchChange = (search) => {
		if (search.length >= 2) {
			getParts(queryBody.setVariables(search));
		}
	};

	return (
		<Autocomplete
			isLoading={!loaded}
			labelKey="label"
			onChange={onChange}
			onInputChange={handleSearchChange}
			options={options}
			placeholder={placeholder || 'Search Parts'}
			value={selectedPartId}
			valueKey="id"
			{...rest}
		/>
	);
};

PartTypePicker.propTypes = {
	apolloClient: PropTypes.object.isRequired,
	branchId: PropTypes.number,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	queryOptions: PropTypes.object,
	selectedPartId: PropTypes.number,
};

export default PartTypePicker;
