import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useFilter } from '../providers/filter-provider.js';

const FilteredRows = ({ rows, children }) => {
	const { filterObjects } = useFilter();

	const filteredRows = useMemo(() => filterObjects(rows), [filterObjects, rows]);

	if (typeof children === 'function') {
		return children(filteredRows);
	} else {
		return children;
	}
};

FilteredRows.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default FilteredRows;
