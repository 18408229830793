import { saveAs } from "file-saver";

function getCellValues( columns, rows ) {
  return rows.map( ( row ) => (
    columns.map( ( column ) => {
      let value = ( typeof column.exportData === "function" )
        ? column.exportData( row )
        : row[ column.key ];

      /* Escape double quotes within the value and wrap
       * each value in double quotes to avoid corrupting the
       * CSV file with values containing one or more commas. */
      return ( value !== null && value !== undefined )
        ? `"${String( value ).replace( /"/g, '""' )}"`
        : "";
    } ).join( "," )
  ) );
}

function getColumnTitles( columns ) {
  return columns.map( ( { title } ) => ( title ) ).join( "," );
}

export function getCsvString( columns, rows ) {
  return [
    getColumnTitles( columns ),
    ...getCellValues( columns, rows ),
  ].join( "\n" );
}

export function exportCsvFile( csv, filename ) {
  const blob = new Blob( [ csv ], { type: "text/csv;charset=utf-8;" } );

  return saveAs( blob, `${filename}.csv` );
}
