import ngModule from '../ng/index.js';

import '@views/orders/routes';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.orders', {
			url: '/orders',
			redirectTo: 'home.rentals',
			template: '<order-routes flex="100" />',
		})
		.state('home.orders.create', {
			url: '/create?companyId&userId&salespersonId&branchId&reference',
		})
		.state('home.orders.order', {
			url: '/:orderId',
		})
		.state('home.orders.order.transports', {
			url: '/transports',
			redirectTo: 'home.orders.order',
		})
		.state('home.orders.order.transports.create', {
			url: '/create?companyId',
		})
		.state('home.orders.order.transports.transport', {
			url: '/:transportId',
		})
		.state('home.orders.order.edit', {
			url: '/edit',
		});
});
