import QRCode from "qrcode-reader";

export function readQrFromFile( file ) {
  return new Promise( ( resolve, reject ) => {
    let reader = new FileReader();
    let qr = new QRCode();

    reader.onload = ( readerEvent ) => ( qr.decode( readerEvent.target.result ) );
    qr.callback = ( error, value ) => {
      if ( value ) {
        resolve( value.result );
      }
      else {
        reject( error );
      }
    };

    reader.readAsDataURL( file );
  } );
}
