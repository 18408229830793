import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import listGroupStyles from "../list-group.module.css";

const ListGroupItem = ( { children, onClick, className } ) => (
  <li
    className={classNames( listGroupStyles.item, { [listGroupStyles.clickable]: !!onClick }, className )}
    onClick={onClick}
  >
    {children}
  </li>
);

ListGroupItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
ListGroupItem.defaultProps = {
  children: null,
  className: null,
  onClick: null,
};

export default ListGroupItem;
