import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

/*
 * Formatting tokens: https://momentjs.com/docs/#/displaying/format/
 */

export default function Timestamp( { dateTime, format } ) {
  return (
    <div className="timestamp">
      {moment( dateTime ).format( format )}
    </div>
  );
}

Timestamp.propTypes = {
  dateTime: PropTypes.object.isRequired,
  format: PropTypes.string,
};

Timestamp.defaultProps = {
  format: "h:mm a",
};
