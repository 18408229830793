import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';

import { EuiButtonIcon } from '@equipmentshare/ds2';
import { CloseFillIcon } from '@equipmentshare/ds2-icons';

import FilterActions from './filter-actions';
import FilterRow from './filter-row';

import { useFilterState } from '../../providers/filter-provider';

import styles from './filter-selector.module.css';

const FilterSelector = ({ onApplyFilters, onClearFilters, onClose }) => {
	const { state: filterState } = useFilterState();

	const applyDisabled = !filterState.rows.some((row) => {
		return _get(row, 'value', null);
	});

	const filterRows = useMemo(() => {
		return _sortBy(filterState.rows, 'id').map((row) => {
			const selectedFilterGroup = _get(row, 'type', null);
			return (
				<FilterRow
					filterGroups={filterState.filterGroups}
					key={row.id}
					rowData={row}
					selectedGroup={selectedFilterGroup}
				/>
			);
		});
	}, [filterState.rows]);

	const handleSubmit = (e) => {
		e.preventDefault();
		onApplyFilters();
	};

	return (
		<div className={styles.layout}>
			<div className={styles.header}>
				<h4>Filters</h4>
				<EuiButtonIcon
					onClick={onClose}
					size="xs"
					color="text"
					iconType={CloseFillIcon}
					aria-label="closeFiltersButton"
				/>
			</div>

			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.filterRows}>{filterRows}</div>

				<FilterActions applyDisabled={applyDisabled} onClearFilters={onClearFilters} />
			</form>
		</div>
	);
};

FilterSelector.propTypes = {
	onApplyFilters: PropTypes.func.isRequired,
	onClearFilters: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default FilterSelector;
