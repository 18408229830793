import angular from 'angular';
import template from './branch-picker.html';
import './branch-picker-ctrl.js';

angular.module("esNgComponents").component('branchPicker', {
  template,
  controller: 'BranchPickerCtrl',
  bindings: {
    branches: '<',
    ngModel: '=',
    selectedBranchId: '<',
    autoFocus: '<',
    ngRequired: '<',
    name: '@',
    ngDisabled: '<',
    onChange: '<',
    label: '@',
    placeholder: '@',

    companyId: '<',
    showInactiveBranches: '<',
    showPublicRsp: '@',

    onSelectBranch: '&',
    inputType: '@',

    nullAsAllOption: '<', // only works for input type "select"
    nullLabel: '@',

    waitForBranches: '<'
  }
});
