/**
 * This is an infrastructure component that helps you get "canonical" objects.
 * In other words: This helps ensure that, for any Entity in the system, there is only
 * one object per entity id - there cannot be two objects representing the same logical Entity.
 *
 * This obviously does not apply to copied objects used to track changes in forms et cetera.
 *
 * The examples in the comments below refer to Deliveries and Rentals a lot, that's only for illustration.
 * The canonicalizer works on any object that uses the `object_type_id` id-field scheme.
 *
 * The point of this is that it can be used in a service to ensure that each time it returns,
 * say, a Delivery, it returns the same actual object for the same delivery id. This means that
 * one component working on Delivery(1) in one part of the app can *ensure* it's working on the
 * exact same object as any other component working on Delivery(1).
 *
 * This is useful because Angular uses two-way bindings on objects to update the GUI, so
 * having canonical objects ends up simplifying the job of keeping disparate parts of the UI updated.
 *
 * ## Details
 *
 * Internally, this keeps a map of "types"  (like "rental", "delivery", etc.). For each type, it keeps
 * a map of id -> canonical object. To find the canonical 'rental', it just does
 *
 *     canonicalEntities['rental'][rentalId]
 *
 * Users can then call this service with an object, and ask to ensure the object they have is the
 * canonical one:
 *
 *     loadDelieveryFromSomewhere()
 *       .then((delivery) => esCanonicalizer.canonicalize('delivery', delivery));
 *
 * If the canonicalizer hasn't seen an entity with that id previously, it will store it as the
 * canonical object and return it. If it *has* seen an object with that id before, it will return
 * the pre-existing canonical object instead.
 *
 * If you pass in an object instead of an id, any pre-existing canonical object will be updated
 * to get the property values of the passed-in object. This lets different parts of the app
 * pull down just the properties they need from the API, and not worry about getting an object that is
 * then missing those properties, or is out of date, when it asks for the canonical version.
 *
 * ## Flattening
 *
 * The canonicalizer works recursively - if you pass in a `Delivery` object that has a nested `Rental`
 * object, it will canonicalize that Rental object as well. Imagine your app has been working on a rental
 * previously, so a canonical Rental exists already.
 *
 * If the user then opens a delivery to edit, and that delivery came with a Rental object from the API,
 * the canonicalizer will replace that Rental object with the canonical one, updating the canonical
 * Rental with any properties it can find on the new Rental object.
 *
 * ## Dates
 *
 * Oh, it also converts things that sneeze in the direction of being a date into moment() instances,
 * because screw SRP. <insanitywolf.gif>
 */
import _map from 'lodash/map';
import _isDate from 'lodash/isDate';
import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';

import moment from "moment";

angular.module('esApiClient').factory('esCanonicalizer', function () {
  var ISO_8601_DATE = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(\.\d{3})?([+-](\d{2})\:(\d{2})|Z)/;

  // Keys are entity types ("delivery", "rental" - must match the naming before `_id` in the object)
  // Values are a second object of id => canonical object, eg:
  // {
  //   'delivery' : {
  //     1: {delivery_id:1, ..}
  //   }
  // }
  var canonicalEntities = {};

  // To canonicalize nested fields: "if you see this field name, this is the object type of that field"
  // The canonicalizer will automatically resolve the type of eg `rental: {rental_id:1}` as 'rental'.
  // And simple plurals like `rentals:[{rental_id:1}]` as well. For fields where these simple inferences
  // don't work, it will look up field names here, and if this list does not have a type, it will not
  // try and canonicalize the given field
  var fieldNameToType = {
    'drop_off_delivery': 'delivery',
    'return_delivery': 'delivery',
    'move_delivery': 'delivery',
    'deliveries': 'delivery',
    'facilitator_type': 'delivery_facilitator_type'
  };

  /**
   * This is the main canonicalization function, it takes an entity type (which should match the
   * identifier used as prefix for `_id` field on the object, eg. `rental` for objects that have
   * their primary key stored as `rental_Id`), and then either an id to look for, or a full object
   * to use to populate or update the canonical entry.
   *
   * If you pass in a full object, it will be recursively canonicalized. This mean that you could
   * pass in a Rental object the canonicalizer has never seen before, and that object would become
   * the canonical one - but the rental might have a `drop_of_delivery` field with a delivery on it.
   *
   * If the canonicalizer has previously seen a delivery with that id, the delivery object on the rental
   * will be replaced by the canonical one - and the canonical one will be updated with any additional
   * data available on the non-canonical delivery.
   *
   * @param entityType
   * @param objOrId simple primary key or a full entity object.
   * @returns the canonical object of the type/id combination given
   */
  function canonicalize(entityType, objOrId) {
    if(objOrId === undefined || objOrId === null) {
      return objOrId;
    }

    var entities = canonicalEntities[entityType];
    if(!entities) {
      entities = canonicalEntities[entityType] = {};
    }

    var obj, id;
    if(_isObject(objOrId)) {
      obj = objOrId;
      id = objOrId[entityType + "_id"] || objOrId['id'];
    } else {
      obj = {};
      obj[entityType + "_id"] = objOrId;
      id = objOrId;
    }

    // Cannot canonicalize if there is no id
    if(!id) {
      return obj;
    }

    var canonical = entities[id];
    if(!canonical) {
      entities[id] = obj;
      canonical = obj;
      // Helps identify non-canonical objects, and to debug canonicalization errors
      canonical._canonicalId = entityType + "@" + id;
      var clone = Object.assign({}, obj);
      canonicalizeFields(clone);
      Object.assign(obj, clone);
    } else if(canonical !== obj) {
      canonicalizeFields(obj);
      for(var k in obj) {
        if(obj.hasOwnProperty(k)) {
          canonical[k] = obj[k];
        }
      }
    }

    return canonical;
  }

  // For a given object, go through all fields and canonicalize any nested objects
  // Eg. the user passed in a Delivery, which has a Rental object under 'rental' field -
  // ensure that Rental object is canonical.
  function canonicalizeFields(obj) {
    for(var k in obj) {
      if(obj.hasOwnProperty(k)) {
        var value = obj[k];

        // Try and canonicalize nested objects and arrays of objects.
        // eg. if this is a delivery with a rental object attached, then use the canonical rental object.
        // This is a bit tricky - we need to figure out the type of the nested object
        if(_isArray(value)) {
          if (value.length > 0) {
            // Try and figure out the object type from the field key and object id fields
            var singularish = k.substr(0, k.length-1);
            var type;
            if (k in fieldNameToType) {
              type = fieldNameToType[k]
            } else if (typeof value[0] === 'object' && singularish + "_id" in value[0]) {
              type = singularish;
            } else {
              continue;
            }

            for (var i = 0; i < value.length; i++) {
              value[i] = canonicalize(type, value[i]);
            }
          }
        } else if(_isObject(value) && !moment.isMoment(value)) {
          // Do we have a known type for this field name?
          if(k in fieldNameToType) {
            obj[k] = canonicalize(fieldNameToType[k], value);

            // No - does the nested object have an _id field matching the field name?
            // if so, assume the field name is the type
          } else if(k + "_id" in value) {
            obj[k] = canonicalize(k, value);
          }
        } else if(_isDate(value) || (_isString(value) && value.match(ISO_8601_DATE))) {
          obj[k] = moment(value);
        }
      }
    }
  }

  // Returns a function that when called calls `canonicalize` with the given type already filled in.
  // Saves some typing where, instead of doing:
  //
  //    rentals = _map(rentals, (rental) => esCanonicalizer.canonicalize('rental', rental));
  //
  // You can do:
  //
  //     rentals = _map(rentals, esCanonicalizer.canonicalizer('rental'));
  //
  function canonicalizer(type) {
    return function(objOrId) {
      return canonicalize(type, objOrId);
    };
  }

  return {
    canonicalize:canonicalize,
    canonicalizer:canonicalizer
  };
});
