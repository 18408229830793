import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EuiFieldNumber, EuiSelect } from '@equipmentshare/ds2';

const COMPARATORS = {
	LESS_THAN: 'less-than',
	EQUAL_TO: 'equal-to',
	GREATER_THAN: 'greater-than',
};
const COMPARATOR_NAMES = {
	LESS_THAN: 'Less than',
	EQUAL_TO: 'Equal to',
	GREATER_THAN: 'Greater than',
};

const NumberFilter = React.forwardRef(({ state, setState, setActive }, ref) => {
	const handleInputChange = useCallback(
		(event) => {
			const value = event.target.value;

			setState((s) => ({ ...s, value }));
			setActive(!!value);
		},
		[setState]
	);
	const handleComparatorChange = useCallback(
		(event) => {
			const comparator = event.target.value;

			setState((s) => ({ ...s, comparator }));
		},
		[setState]
	);

	const style = { minWidth: 150, padding: 10 };

	return (
		<div style={style} ref={ref}>
			<EuiSelect
				options={Object.keys(COMPARATORS).map((key) => ({ value: COMPARATORS[key], text: COMPARATOR_NAMES[key] }))}
				value={state.comparator || COMPARATORS.EQUAL_TO}
				onChange={handleComparatorChange}
			/>
			<EuiFieldNumber type="number" autoFocus value={state.value || ''} onChange={handleInputChange} />
		</div>
	);
});

NumberFilter.displayName = 'NumberFilter';
NumberFilter.propTypes = {
	state: PropTypes.shape({
		value: PropTypes.string,
		comparator: PropTypes.oneOf(Object.values(COMPARATORS)),
	}).isRequired,
	setState: PropTypes.func.isRequired,
	setActive: PropTypes.func.isRequired,
};

NumberFilter.filterMethod = (entry, { value, comparator }, key) => {
	const parsedValue = parseFloat(value);
	const entryValue = parseFloat(_get(entry, key));

	switch (comparator) {
		case COMPARATORS.LESS_THAN:
			return entryValue < parsedValue;
		case COMPARATORS.GREATER_THAN:
			return entryValue > parsedValue;
		default:
		case COMPARATORS.EQUAL_TO:
			return entryValue === parsedValue;
	}
};

export default NumberFilter;
