import React from 'react';
import PropTypes from 'prop-types';

import { withState } from '@equipmentshare/react2angular-router';
import LayoutView from './layout-view';
import LayoutProvider from './providers/layout-provider';

export const Layout = ({ history, match }) => {
	return (
		<LayoutProvider>
			<LayoutView history={history} path={match.path} />
		</LayoutProvider>
	);
};

Layout.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	match: PropTypes.shape({
		path: PropTypes.string.isRequired,
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default withState(Layout);
