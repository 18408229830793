import React, { useContext } from 'react';

import { Breakpoints } from './breakpoints.js';

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withBreakpoints(mapToProps) {
	return (WrappedComponent) => {
		const WithBreakpoints = ({ children, ...props }) => {
			const breakpoints = useContext(Breakpoints);
			const mappedProps = mapToProps ? mapToProps(breakpoints) : {};

			return (
				<WrappedComponent {...props} {...mappedProps} breakpoints={breakpoints}>
					{children}
				</WrappedComponent>
			);
		};

		WithBreakpoints.displayName = `WithBreakpoints(${getDisplayName(WrappedComponent)})`;
		WithBreakpoints.WrappedComponent = WrappedComponent;

		return WithBreakpoints;
	};
}

export default withBreakpoints;
