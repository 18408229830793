import { useHeaper, HeaperOptions, AppName, PromotionLevel } from '@equipmentshare/heaper';

import { HEAPER_ENV } from '@const/env';
import { useIdentityWithProviderCheck } from '@hooks/use-identity-with-provider-check';

const isPromotionLevel = (s: string | undefined): s is PromotionLevel =>
	!!s && ['Production', 'Staging', 'Sandbox'].includes(s);

export const HEAPER_APP_NAME: AppName = 'Admin';

export const HeaperComponent = () => {
	const identity = useIdentityWithProviderCheck();

	if (!identity) {
		return null;
	}

	const { accessToken, idToken } = identity;
	const environment: PromotionLevel = isPromotionLevel(HEAPER_ENV) ? HEAPER_ENV : 'Staging';
	const heaperOptions: HeaperOptions = {
		accessToken,
		idToken,
		internalProducts: true,
	};

	useHeaper(HEAPER_APP_NAME, environment, heaperOptions);

	return null;
};

export default HeaperComponent;
