import esApi from '@services/es-api.service';

export async function fetchQuotesByOrderId(orderId) {
	return await esApi.request(orderId ? esApi.url`/quotes-web-api/quotes?order_id=${orderId}` : null);
}

const quotesService = {
	fetchQuotesByOrderId,
};

export default quotesService;
