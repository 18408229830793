import React from "react";
import PropTypes from "prop-types";

const TooltipText = ( { title, description } ) => {
  const hasTitle = Boolean( title );

  return (
    <React.Fragment>
      {hasTitle && <strong>{title}</strong>}
      {hasTitle && <br />}
      {description}
    </React.Fragment>
  );
};

TooltipText.defaultProps = {
  title: null,
};

TooltipText.propTypes = {
  description: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default TooltipText;
