import ngModule from '../../../../ng/index.js';
import template from './service-interval.html';

ngModule
	.component('serviceInterval', {
		template,
		controller: 'ServiceIntervalCtrl',
		bindings: {
			serviceInterval: '<',
			onComplete: '&',
		},
	})
	.controller('ServiceIntervalCtrl', function ServiceIntervalCtrl($scope, esCanonicalizer) {
		const ctrl = this;

		ctrl.$onChanges = () => {
			$scope.interval = ctrl.serviceInterval.intervals[0];
			$scope.maintenanceInterval = ctrl.serviceInterval.maintenance_group_interval.service_interval;

			const assetId =
				ctrl.serviceInterval.asset_id || ctrl.serviceInterval.equipment_id || ctrl.serviceInterval.vehicle_id;

			if (assetId) {
				$scope.asset = esCanonicalizer.canonicalize('asset', assetId);
			}
		};

		$scope.getPercent = () => {
			if (!$scope.interval) {
				return 0;
			}

			const start = $scope.interval.previous_interval;
			const end = $scope.interval.next_interval;
			const range = end - start;
			const current = end - start - $scope.interval.until_next_interval;

			return current / range;
		};

		$scope.toggleRecordForm = (state = !$scope.recordFormOpen) => {
			$scope.recordFormOpen = state;
		};
	});
