import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  SORT_DIRECTION_NONE,
  SORT_DIRECTION_ASCENDING,
  SORT_DIRECTION_DESCENDING
} from "../../utils/constants.js";
import SortIndicator from "./components/sort-indicator/sort-indicator.js";

import tableStyles from "./column-header.module.css";

export default class ColumnHeader extends PureComponent {
  constructor( props ) {
    super( props );

    this.state = {
      filterValue: props.filterValue,
    };

    this.onFilterChange = this.onFilterChange.bind( this );
    this.handleSortChange = this.handleSortChange.bind( this );
  }

  onFilterChange( event ) {
    const { handleFilterChange, columnKey } = this.props;
    const filterValue = event.target.value;

    this.setState( { filterValue } );
    handleFilterChange( columnKey, filterValue );
  }

  handleSortChange() {
    if ( this.props.sortable ) {
      this.props.handleSortChange( this.props.columnKey, ( this.props.sortDirection === SORT_DIRECTION_ASCENDING ) ? SORT_DIRECTION_DESCENDING : SORT_DIRECTION_ASCENDING );
    }
  }

  renderFilter() {
    const { filterable } = this.props;

    if ( !filterable ) {
      return null;
    }

    return (
      <input
        onChange={this.onFilterChange}
        placeholder={`Filter ${this.props.title}`}
        type="text"
        value={this.state.filterValue}
      />
    );
  }

  renderHeadingTitle() {
    const { sortDirection } = this.props;

    return (
      <div
        className={tableStyles.columnTitle}
        onClick={this.handleSortChange}
        title={this.props.title}
      >
        <div className={tableStyles.title}>{this.props.title}</div>
        {this.props.sortable && (
          <SortIndicator direction={sortDirection} />
        )}
      </div>
    );
  }

  render() {
    let { customHeader } = this.props;
    let className = classNames( {
      [tableStyles.activeSort]: this.props.sortDirection !== SORT_DIRECTION_NONE,
      [tableStyles.sortable]: this.props.sortable,
    } );

    let content = customHeader ? (
      customHeader()
    ) : (
      <div className={className}>
        {this.renderHeadingTitle()}
        {this.renderFilter()}
      </div>
    );

    return content;
  }
}

ColumnHeader.defaultProps = {
  customHeader: null,
  filterValue: "",
  filterable: true,
  sortDirection: SORT_DIRECTION_NONE,
  sortable: true,
  title: "",
};

ColumnHeader.propTypes = {
  columnKey: PropTypes.string.isRequired,
  customHeader: PropTypes.func,
  filterValue: PropTypes.string,
  filterable: PropTypes.bool,
  handleFilterChange: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  sortable: PropTypes.bool,
  title: PropTypes.string,
};
