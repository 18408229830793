import moment from 'moment';

import { COLORSLIGHT } from '@equipmentshare/ds2';
import {
	DraftLineIcon,
	CheckboxCircleLineIcon,
	TimeLineIcon,
	PieChartLineIcon,
	ExchangeDollarLineIcon,
	FlagLineIcon,
	DeleteBinLineIcon,
} from '@equipmentshare/ds2-icons';

import { Invoice } from '@helpers/invoice';

export const STATUSES = {
	UNAPPROVED: 'unapproved',
	UNPAID: 'unpaid',
	PARTIALLY_PAID: 'partiallyPaid',
	OVERDUE: 'overdue',
	PAID: 'paid',
	IN_DISPUTE: 'inDispute',
	DELETED: 'deleted',
};

type StatusKeys = keyof typeof STATUSES;
type Status = typeof STATUSES[StatusKeys];

export const getStatusInfo = (status: Status) => {
	switch (status) {
		case STATUSES.UNAPPROVED:
			return {
				color: COLORSLIGHT.mediumShade,
				icon: DraftLineIcon,
				text: 'Unapproved',
			};
		case STATUSES.PARTIALLY_PAID:
			return {
				color: COLORSLIGHT.warning,
				icon: PieChartLineIcon,
				text: 'Partially Paid',
			};
		case STATUSES.OVERDUE:
			return {
				color: COLORSLIGHT.danger,
				icon: TimeLineIcon,
				text: 'Past Due',
			};
		case STATUSES.PAID:
			return {
				color: COLORSLIGHT.success,
				icon: CheckboxCircleLineIcon,
				text: 'Paid',
			};
		case STATUSES.IN_DISPUTE:
			return {
				color: COLORSLIGHT.danger,
				icon: FlagLineIcon,
				text: 'Disputed',
			};
		case STATUSES.DELETED:
			return {
				color: COLORSLIGHT.mediumShade,
				icon: DeleteBinLineIcon,
				text: 'Deleted',
			};
		default:
			return {
				color: COLORSLIGHT.primary,
				icon: ExchangeDollarLineIcon,
				text: 'Unpaid',
			};
	}
};

export const checkInvoiceStatus = (invoice: Invoice.BillingServiceInvoice, status: Status) => {
	const billingApproved = Invoice.isGetDtoData(invoice)
		? invoice.billingApprovedData?.approved
		: invoice.billingApproved;
	const owedAmount = parseFloat(invoice.owedAmount ?? '0');
	const billedAmount = parseFloat(invoice.billedAmount ?? '0');

	switch (status) {
		case STATUSES.UNAPPROVED:
			return !billingApproved;
		case STATUSES.UNPAID:
			return billingApproved && !invoice.paid;
		case STATUSES.PARTIALLY_PAID:
			return billingApproved && !invoice.paid && owedAmount < billedAmount;
		case STATUSES.OVERDUE:
			return billingApproved && !invoice.paid && moment().isAfter(invoice.dueDate);
		case STATUSES.PAID:
			return !!invoice.paid;
		case STATUSES.IN_DISPUTE:
			return billingApproved && !!invoice.inDispute;
		case STATUSES.DELETED:
			return !!invoice.deleted;
	}

	return false;
};

export const getStatuses = (invoice: Invoice.BillingServiceInvoice, allowDisputed: boolean) => {
	if (checkInvoiceStatus(invoice, STATUSES.DELETED)) {
		return [STATUSES.DELETED];
	}

	const statuses = [];

	if (checkInvoiceStatus(invoice, STATUSES.UNAPPROVED)) {
		statuses.push(STATUSES.UNAPPROVED);
	} else if (checkInvoiceStatus(invoice, STATUSES.PAID)) {
		statuses.push(STATUSES.PAID);
	} else if (checkInvoiceStatus(invoice, STATUSES.OVERDUE)) {
		statuses.push(STATUSES.OVERDUE);

		if (checkInvoiceStatus(invoice, STATUSES.PARTIALLY_PAID)) {
			statuses.push(STATUSES.PARTIALLY_PAID);
		}
	} else if (checkInvoiceStatus(invoice, STATUSES.PARTIALLY_PAID)) {
		statuses.push(STATUSES.PARTIALLY_PAID);
	} else if (checkInvoiceStatus(invoice, STATUSES.UNPAID)) {
		statuses.push(STATUSES.UNPAID);
	}

	if (allowDisputed && checkInvoiceStatus(invoice, STATUSES.IN_DISPUTE)) {
		statuses.push(STATUSES.IN_DISPUTE);
	}

	return statuses;
};

export const getStatusText = (invoice: Invoice.BillingServiceInvoice, allowDisputed: boolean) => {
	const statuses = getStatuses(invoice, allowDisputed);
	const statusName = statuses.map((s) => getStatusInfo(s).text);

	return statusName.join(', ');
};

function getDisputedSortValue(statuses: Status[], baseValue: number) {
	const disputedValue = baseValue * 2;

	if (statuses.includes(STATUSES.IN_DISPUTE)) {
		return disputedValue + 1;
	}

	return disputedValue;
}

export const getStatusSortValue = (invoice: Invoice.BillingServiceInvoice, allowDisputed: boolean) => {
	const statuses = getStatuses(invoice, allowDisputed);

	if (statuses.includes(STATUSES.DELETED)) {
		return getDisputedSortValue(statuses, 0);
	} else if (statuses.includes(STATUSES.UNAPPROVED)) {
		return getDisputedSortValue(statuses, 2);
	} else if (statuses.includes(STATUSES.PAID)) {
		return getDisputedSortValue(statuses, 1);
	} else if (statuses.includes(STATUSES.OVERDUE)) {
		if (statuses.includes(STATUSES.PARTIALLY_PAID)) {
			return getDisputedSortValue(statuses, 6);
		}

		return getDisputedSortValue(statuses, 5);
	} else if (statuses.includes(STATUSES.PARTIALLY_PAID)) {
		return getDisputedSortValue(statuses, 4);
	} else if (statuses.includes(STATUSES.UNPAID)) {
		return getDisputedSortValue(statuses, 3);
	}

	return 100;
};

export const sortInvoicesByStatus = (
	a: Invoice.BillingServiceInvoice,
	b: Invoice.BillingServiceInvoice,
	allowDisputed: boolean
) => {
	return getStatusSortValue(a, allowDisputed) - getStatusSortValue(b, allowDisputed);
};
