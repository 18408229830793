import { gql } from '@apollo/client';
import getOperationNameFromQuery from '../../utils/get-operation-name-from-query.js';

const userAuthorizationsQuery = gql`
	query UserAuthorizations {
		userAuthorizations {
			permissions {
				id
				name
				objectId
				friendlyName
			}
			groups {
				id
				name
			}
		}
	}
`;

export const userAuthorizationsQueryName = getOperationNameFromQuery(userAuthorizationsQuery);
export default userAuthorizationsQuery;
