export function FilterChange( columnKey, filters, data ) {
  return {
    name: "filterChange",
    values: { columnKey, filters },
    data: Object.keys( filters ).length ? data : undefined,
  };
}

export function SortChange( columnKey, sortDirection ) {
  return {
    name: "sortChange",
    values: { columnKey, sortDirection }
  };
}
