import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Header from "./components/card-header.js";
import Body, { VARIANTS as CardBodyVariants } from "./components/card-body.js";
import Footer from "./components/card-footer.js";

import cardStyles from "./card.module.css";

const Card = ( { cardClass, children } ) => {
  let className = classNames( cardStyles.card, cardClass );

  return (
    <div className={className}>
      {children}
    </div>
  );
};

Card.Header = Header;
Card.Body = Body;
Card.Footer = Footer;
Card.propTypes = {
  /** A custom class name that can be added to the card */
  cardClass: PropTypes.string,
  /** Any children that will be rendered inside of the card */
  children: PropTypes.any,
};

Card.defaultProps = {
  cardClass: null,
  children: null,
};

export default Card;
export { CardBodyVariants };
