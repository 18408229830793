import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TableProvider from '../providers/table-provider.js';
import SortProvider from '../providers/sort-provider.js';
import FilterProvider from '../providers/filter-provider.js';
import SelectionProvider from '../providers/selection-provider.js';
import ColumnProvider from '../providers/column-provider.js';

import Head from '../head/head.js';
import Header from '../header/header.js';
import Column from '../column/column.js';
import CheckboxColumn from '../checkbox-column/checkbox-column.js';
import ActionColumn from '../action-column/action-column.js';
import Body from '../body/body.js';
import SortedBody from '../sorted-body/sorted-body.js';
import VirtualizedBody from '../virtualized-body/virtualized-body.js';
import Row from '../row/row.js';
import Cell from '../cell/cell.js';
import CheckboxCell from '../checkbox-cell/checkbox-cell.js';
import ActionCell from '../action-cell/action-cell.js';
import MarkdownCell from '../markdown-cell/markdown-cell.js';
import EmptyRow from '../empty-row/empty-row.js';
import { NumberFilter, SearchFilter } from '../filters/index.js';
import SelectionColumn from '../selection-column/selection-column.js';
import SelectionCell from '../selection-cell/selection-cell.js';
import RowMeasurer from '../row-measurer/row-measurer.js';
import withRowMeasurer from '../row-measurer/with-row-measurer.js';
import SortedRows from '../sorted-rows/sorted-rows.js';
import FilteredRows from '../filtered-rows/filtered-rows.js';
import SortedVirtualizedBody from '../sorted-virtualized-body/sorted-virtualized-body.js';
import BoolCell from '../bool-cell/bool-cell.js';
import LinkRow from '../link-row/link-row.js';

import ExpandableRow from '../expandable-row/expandable-row.js';
import PersistentExpandableRow from '../persistent-expandable-row/persistent-expandable-row.js';

import styles from './table.module.css';

export const Table = ({ children, className, bordered, style }) => (
	<TableProvider>
		<div className={classNames(styles.table, className, bordered && styles.bordered)} style={style}>
			{children}
		</div>
	</TableProvider>
);

Table.propTypes = {
	bordered: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.object,
};

// providers
Table.TableProvider = TableProvider;
Table.SortProvider = SortProvider;
Table.FilterProvider = FilterProvider;
Table.ColumnProvider = ColumnProvider;

// base elements
Table.Head = Head;
Table.Header = Header;
Table.Column = Column;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;

// sorted/filtered rows
Table.SortedRows = SortedRows;
Table.FilteredRows = FilteredRows;
Table.SortedBody = SortedBody;
Table.SortedVirtualizedBody = SortedVirtualizedBody;

// modified elements
Table.CheckboxColumn = CheckboxColumn;
Table.CheckboxCell = CheckboxCell;
Table.MarkdownCell = MarkdownCell;
Table.BoolCell = BoolCell;
Table.EmptyRow = EmptyRow;

Table.ActionCell = ActionCell;
Table.ActionColumn = ActionColumn;

Table.LinkRow = LinkRow;
Table.ExpandableRow = ExpandableRow;
Table.PersistentExpandableRow = PersistentExpandableRow;

Table.SelectionColumn = SelectionColumn;
Table.SelectionCell = SelectionCell;
Table.SelectionProvider = SelectionProvider;

// virtualized elements
Table.VirtualizedBody = VirtualizedBody;
Table.RowMeasurer = RowMeasurer;
Table.withRowMeasurer = withRowMeasurer;

// filters
Table.NumberFilter = NumberFilter;
Table.SearchFilter = SearchFilter;

export default Table;
