import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { difference } from '@helpers/utilities';

import {
	EuiButton,
	EuiFormRow,
	EuiButtonEmpty,
	EuiFieldText,
	EuiCheckbox,
	EuiTitle,
	EuiColorPicker,
} from '@equipmentshare/ds2';
import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';
import { BranchPicker, Stack } from '@equipmentshare/es-admin-components';
import EquipmentMakePicker from '@components/equipment-make-picker/equipment-make-picker';
import EquipmentModelPicker from '@components/equipment-model-picker/equipment-model-picker';
import MaintenanceGroupPicker from '@components/maintenance-group-picker/maintenance-group-picker';

import toastService from '@services/toast.service.js';
import imageService from '@services/image.service';
import assetsService from '@services/assets.service';
import companyViewSettingsService from '@services/company-view-settings.service';
import authService from '@services/auth.service';

import { TOOLS_APP_URL } from '@const/env';

import { AssetTypeFields, VEHICLE_TYPE_ID } from './assetTypeFields';

const FormLayout = styled.form`
	padding: 10px 15px;
`;

const FlexEndContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const HiddenInput = styled.input`
	display: none;
`;

const MinWidthEmptyButton = styled(EuiButtonEmpty)`
	width: min-content;
`;

const APPLICATION_ID = 1;
const COLORS = [
	'#4e4e4e',
	'#909194',
	'#cfcfd1',
	'#2ab6ea',
	'#094874',
	'#46294e',
	'#7e2779',
	'#ed3895',
	'#f6911e',
	'#fcb22e',
	'#9dcc45',
	'#60aa45',
	'#3b6731',
];

const AssetEditForm = ({ asset, userIsFleetOperations, refresh, editRentalService }) => {
	const [imageFile, setImageFile] = useState();
	const [markerColor, setMarkerColor] = useState();
	const [companySettings, setCompanySettings] = useState();
	const [formData, setFormData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const uploadInput = useRef(null);
	const onUploadClick = () => uploadInput.current.click();
	const setPhoto = (event) => {
		const file = _get(event, 'target.files[0]', null);
		setImageFile(file);
	};

	const getViewSettings = async () => {
		const settings = await companyViewSettingsService.fetch(APPLICATION_ID);
		const color = _get(settings, `preferences.assetMarkerColors.${asset.asset_id}`, null);
		setMarkerColor(color);
		setCompanySettings(settings);
	};
	useEffect(() => {
		setFormData({
			...asset,
			equipment_model_id: _get(asset, 'equipment_model.equipment_model_id', null),
			hours: _get(asset, 'asset_status.hours', null),
		});
		getViewSettings();
	}, [asset.asset_id]);

	const setFormValue = (key, value) => {
		setFormData({ ...formData, [key]: value });
	};
	const getFormValue = (key) => formData[key];

	const updateAsset = async (payload) => {
		return await assetsService.updateAsset(asset.asset_id, payload);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsLoading(true);
		try {
			if (imageFile) {
				const apiToken = await authService.getApiToken();
				const uploadResult = await imageService.uploadFiles([imageFile], { type: 'cdn' }, apiToken);
				if (!uploadResult.length) {
					throw new Error('Upload failed');
				}

				// get the download_filename of the first file in the array
				const download_filename = uploadResult[0].download_filename;
				const userId = authService.cookies.userId.get();
				const photo = await imageService.create(download_filename, userId);
				formData.photo_id = photo.photo_id;
			}
			if (
				markerColor &&
				markerColor !== _get(companySettings, `preferences.assetMarkerColors.${asset.asset_id}`, null)
			) {
				companySettings.preferences.assetMarkerColors[asset.asset_id] = markerColor;
				await companyViewSettingsService.update(APPLICATION_ID, companySettings);
			}
			const assetPayload = difference(formData, {
				...asset,
				equipment_model_id: _get(asset, 'equipment_model.equipment_model_id', null),
				hours: _get(asset, 'asset_status.hours', null),
			});
			if (assetPayload.tracker_id === '') {
				assetPayload.tracker_id = null;
			}
			if (!_isEmpty(assetPayload)) {
				await updateAsset(assetPayload);
				toastService.showSuccess(`Asset #${asset.asset_id} successfully updated`);
				refresh();
			}
		} catch (error) {
			if (error.status === 204) {
				toastService.showSuccess(`Asset #${asset.asset_id} successfully updated`);
				refresh();
			} else {
				const errorResponse = await error.json();
				const message = _get(errorResponse, 'message', 'Unable to update asset');
				toastService.showError(message);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const renderTypeSpecificInputFields = () => {
		const fieldsAnyoneCanEdit = ['odometer', 'hours'];
		if (asset) {
			const fields = AssetTypeFields[asset.asset_type_id] || [];
			const newFields = fields.map((field) => {
				if (field.label == 'Driver Name') {
					return (
						<EuiFormRow key={field.name} fullWidth>
							<Stack dir="column">
								{renderInput(
									field.name,
									field.label,
									field.required,
									fieldsAnyoneCanEdit.includes(field.name) ? false : !userIsFleetOperations,
									field.type ? field.type : 'text'
								)}
								<MinWidthEmptyButton onClick={onAssignDriverClick}>Assign ES Driver to this Asset</MinWidthEmptyButton>
							</Stack>
						</EuiFormRow>
					);
				}
				return renderInput(
					field.name,
					field.label,
					field.required,
					fieldsAnyoneCanEdit.includes(field.name) ? false : !userIsFleetOperations,
					field.type ? field.type : 'text'
				);
			});
			return newFields;
		}
	};

	const renderInput = (name, label, required, disabled, type) => {
		return (
			<EuiFormRow key={name} label={label} fullWidth>
				<EuiFieldText
					type={type}
					key={name}
					value={getFormValue(name) || ''}
					placeholder={label}
					required={required}
					onChange={({ target: { value } }) => setFormValue(name, value)}
					disabled={disabled}
					fullWidth
				/>
			</EuiFormRow>
		);
	};

	const onAssignDriverClick = () => {
		window.open(`${TOOLS_APP_URL}/assign-drivers?asset_id=${getFormValue('custom_name')}`, '_blank');
	};
	return (
		<FormLayout action="#" onSubmit={handleSubmit}>
			{isLoading && <LoadingOverlay />}
			<HiddenInput ref={uploadInput} type="file" onChange={(event) => setPhoto(event)} />
			<EuiFormRow fullWidth>
				<Stack dir="row" justifyContent="space-between" alignItems="center">
					<EuiTitle size="xs">
						<h2>{imageFile ? imageFile.name : 'No Photo Selected'}</h2>
					</EuiTitle>
					<EuiButton color="text" onClick={onUploadClick}>
						Upload Photo
					</EuiButton>
				</Stack>
			</EuiFormRow>
			{editRentalService && (
				<>
					<EuiFormRow label="Rental Service Provider" fullWidth>
						<BranchPicker
							selectedBranchId={getFormValue('rental_branch_id')}
							selectedBranchCompanyId={asset.company.company_id} // this will allow fetching the rental branch if the asset is outside of EquipmentShare (1854)
							placeholder="No Rental Service Provider"
							onChange={(value) => setFormValue('rental_branch_id', value ?? null)}
							isClearable={userIsFleetOperations}
							fullWidth
						/>
					</EuiFormRow>
					<EuiFormRow label="Maintenance Service Provider" fullWidth>
						<BranchPicker
							selectedBranchId={getFormValue('service_branch_id')}
							selectedBranchCompanyId={asset.company.company_id} // this will allow fetching the service branch if the asset is outside of EquipmentShare (1854)
							placeholder="No Maintenance Service Provider"
							onChange={(value) => setFormValue('service_branch_id', value ?? null)}
							isClearable={userIsFleetOperations}
							fullWidth
						/>
					</EuiFormRow>
				</>
			)}
			<EuiFormRow label="Make" fullWidth>
				<EquipmentMakePicker
					label=""
					placeholder="Make"
					makeId={getFormValue('equipment_make_id')}
					onChange={(value) => setFormValue('equipment_make_id', value)}
				/>
			</EuiFormRow>
			<EuiFormRow label="Model" fullWidth>
				<EquipmentModelPicker
					label=""
					placeholder="Model"
					modelId={getFormValue('equipment_model_id')}
					makeId={getFormValue('equipment_make_id')}
					onChange={(value) => setFormValue('equipment_model_id', value)}
				/>
			</EuiFormRow>

			{renderInput('model', 'Custom Model Name (if not available above)', false, false, 'text')}
			{renderInput('year', 'Year', false, false, 'number')}
			{renderInput('name', 'Type', false, !userIsFleetOperations, 'text')}
			{renderInput('custom_name', 'Custom Name', true, false, 'text')}
			{renderInput('serial_number', 'Serial Number', false, !userIsFleetOperations, 'text')}
			{renderInput('tracker_id', 'Tracker ID', false, false, 'number')}
			{renderTypeSpecificInputFields()}
			{asset.asset_type_id === VEHICLE_TYPE_ID && (
				<EuiFormRow label="Model" fullWidth>
					<MaintenanceGroupPicker
						label=""
						placeholder="Model"
						value={getFormValue('maintenance_group_id')}
						onChange={(value) => setFormValue('maintenance_group_id', value)}
						disabled={!userIsFleetOperations}
					/>
				</EuiFormRow>
			)}
			{asset.asset_type_id !== VEHICLE_TYPE_ID && (
				<EuiFormRow fullWidth>
					<EuiCheckbox
						id="available_to_rapid_rent"
						label="Available to Rapid Rent"
						checked={getFormValue('available_to_rapid_rent')}
						onChange={() => setFormValue('available_to_rapid_rent', !getFormValue('available_to_rapid_rent'))}
					/>
				</EuiFormRow>
			)}
			<EuiFormRow fullWidth>
				<EuiColorPicker
					color={markerColor}
					onChange={setMarkerColor}
					mode="swatch"
					swatches={COLORS}
					fullWidth
					placeholder="No Color Selected"
				/>
			</EuiFormRow>
			<EuiFormRow fullWidth>
				<FlexEndContainer>
					<EuiButton fill type="submit">
						Save Details
					</EuiButton>
				</FlexEndContainer>
			</EuiFormRow>
		</FormLayout>
	);
};

AssetEditForm.propTypes = {
	asset: PropTypes.object,
	refresh: PropTypes.func,
	userIsFleetOperations: PropTypes.bool,
};

export default AssetEditForm;
