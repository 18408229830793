import ngModule from '../../../../ng/index.js';
import _map from 'lodash/map';
import _union from 'lodash/union';
import _filter from 'lodash/filter';

function NewLocationFormCtrl($scope) {
	const controller = this;

	controller.$onInit = () => {
		if (controller.location) {
			$scope.newLocation = {
				latitude: controller.location.latitude,
				longitude: controller.location.longitude,
			};
		} else {
			$scope.newLocation = {};
		}

		$scope.allowDefaultGeofence =
			controller.allowDefaultGeofence === undefined ? true : controller.allowDefaultGeofence;

		$scope.createDefaultGeofence = true;
		$scope.locationMoved = false;
		$scope.existingLocations = [];
	};

	$scope.returnNewLocation = (location) => {
		if (controller.onPickNewLocation) {
			if ($scope.allowDefaultGeofence && $scope.createDefaultGeofence) {
				location.geofence = true;
			}
			controller.onPickNewLocation(location);
		}
	};

	$scope.returnExistingLocation = (location) => {
		if (controller.onPickExistingLocation) {
			controller.onPickExistingLocation(location);
		}
	};

	$scope.cancel = () => {
		if (controller.onCancel) {
			controller.onCancel();
		}
	};

	$scope.dragEnd = (lat, lng) => {
		$scope.locationMoved = true;

		$scope.existingLocations = [];
		if (controller.existingGeofences) {
			const poly = new google.maps.Polygon();
			const geofences = _filter(controller.existingGeofences, (geofence) => {
				const points = _map(geofence.geojson.geometry.coordinates[0], (p) => {
					return new google.maps.LatLng(p[0], p[1]);
				});

				poly.setPath(points);

				return google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), poly);
			});

			$scope.existingLocations = _union(
				$scope.existingLocations,
				_map(geofences, (geofence) => geofence.location)
			);
		}

		if (controller.fetchExistingGeofences) {
			controller.fetchExistingGeofences(lat, lng).then((geofences) => {
				$scope.existingLocations = _union(
					$scope.existingLocations,
					_map(geofences, (geofence) => geofence.location)
				);
			});
		}
	};

	return controller;
}

ngModule.controller('NewLocationFormCtrl', NewLocationFormCtrl);
