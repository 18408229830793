import React, { PureComponent } from "react";
import { Portal } from "react-portal";
import PropTypes from "prop-types";

import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";

import Popover from "./popover.js";

let ID_PREFIX = "popover-container-";
let popoverIds = 0;

let KEYCODES = {
  ESCAPE: 27
};

export default class ControlledPopoverCreator extends PureComponent {
  constructor( ...args ) {
    super( ...args );

    this.forceUpdateFailsafe = 0;

    this.setAnchorRef = this.setAnchorRef.bind( this );
    this.setPopoverRef = this.setPopoverRef.bind( this );

    this.handleScroll = this.handleScroll.bind( this );
    this.handleKeydown = this.handleKeydown.bind( this );
    this.handleOutsideMouseClick = this.handleOutsideMouseClick.bind( this );
  }

  componentDidMount() {
    let { closeOnEsc, closeOnOutsideClick } = this.props;

    if ( closeOnEsc ) {
      document.addEventListener( "keydown", this.handleKeydown );
    }

    if ( closeOnOutsideClick ) {
      document.addEventListener( "click", this.handleOutsideMouseClick, true );
    }

    window.addEventListener( "scroll", this.handleScroll, true );
  }

  componentDidUpdate( prevProps ) {
    if( !this.anchorRef || this.forceUpdateFailsafe++ >= 10 ) {
      return;
    }

    let rect = this.anchorRef.getBoundingClientRect();
    let props = [ "x", "y", "width", "height" ];

    let newPosition = _pick( rect, props );
    let oldPosition = _pick( this.lastRect, props );

    if( !_isEqual( newPosition, oldPosition ) ) {
      this.forceUpdateFailsafe = 10;
      this.forceUpdate();
      this.forceUpdateFailsafe = 0;
    }

    this.lastRect = rect;

    if ( prevProps.isOpen && !this.props.isOpen && this.portalNode ) {
      this.portalNode.remove();
      this.portalNode = undefined;
    }
  }

  componentWillUnmount() {
    let { closeOnEsc, closeOnOutsideClick } = this.props;

    if ( closeOnEsc ) {
      document.removeEventListener( "keydown", this.handleKeydown );
    }

    if ( closeOnOutsideClick ) {
      document.removeEventListener( "click", this.handleOutsideMouseClick );
    }

    window.removeEventListener( "scroll", this.handleScroll );

    if ( this.portalNode ) {
      this.portalNode.remove();
      this.portalNode = undefined;
    }
  }

  UNSAFE_componentWillUpdate( newProps ){
    if ( !this.props.isOpen && newProps.isOpen && !this.portalNode ) {
      this.portalNode = document.createElement( "div" );
      this.portalNode.id = ID_PREFIX + ( popoverIds++ );
      this.portalNode.classList.add( "popover-container" );
      document.body.appendChild( this.portalNode );
    }
  }

  handleOutsideMouseClick( clickEvent ) {
    if ( !this.props.isOpen || !this.popoverRef ) {
      return;
    }

    let popoverContainsElement = this.popoverRef.contains( clickEvent.target );
    let documentContainsElement = this.popoverRef.ownerDocument.contains( clickEvent.target );
    let wasPrimaryButton = clickEvent.button === 0;

    if ( popoverContainsElement && clickEvent && clickEvent.nativeEvent ) {
      clickEvent.nativeEvent.stopImmediatePropagation();
    }

    if ( !documentContainsElement || popoverContainsElement || !wasPrimaryButton ) {
      return;
    }

    this.props.onClose();
  }

  handleKeydown( keyEvent ) {
    if ( keyEvent.keyCode === KEYCODES.ESCAPE && this.props.isOpen ) {
      this.props.onClose();
    }
  }

  handleScroll() {
    if( !this.props.isOpen ) {
      return;
    }

    if( this.props.closeOnScroll ) {
      this.props.onClose();
    } else {
      this.forceUpdate();
    }
  }

  setAnchorRef( el ){
    this.anchorRef = el;
  }

  setPopoverRef( el ){
    this.popoverRef = el;
  }

  render() {
    let { children, content, anchor, align, isOpen, offset, popoverClassName } = this.props;

    return (
      <span className="popover-anchor" ref={this.setAnchorRef}>
        {children}
        {isOpen &&
          <Portal node={this.portalNode}>
            <div ref={this.setPopoverRef}>
              <Popover align={align} anchor={anchor} anchorNode={this.anchorRef} className={popoverClassName} offset={offset}>
                {content}
              </Popover>
            </div>
          </Portal>
        }
      </span>
    );
  }
}

ControlledPopoverCreator.propTypes = {
  /** the `align` prop passed to the Popover component */
  align: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.arrayOf( PropTypes.string )
  ] ),
  /** the anchor provided to the Popover component */
  anchor: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.func
  ] ),
  /** The node used to trigger the popover */
  children: PropTypes.node.isRequired,
  /** close the popover when the Esc key is pressed */
  closeOnEsc: PropTypes.bool,
  /** close the popover when the user clicks outside of the popover */
  closeOnOutsideClick: PropTypes.bool,
  /** close the popover when the window fires the `scroll` event */
  closeOnScroll: PropTypes.bool,
  /** Accepts any elements being passed in that render when the popover is opened */
  content: PropTypes.any,
  /** A boolean that controls the visbility of the popover content */
  isOpen: PropTypes.bool,
  /** a CSS value or an object that will be used to offset the popover from its anchor */
  offset: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape( {
      top: PropTypes.any,
      left: PropTypes.any,
      right: PropTypes.any,
      bottom: PropTypes.any,
      vertical: PropTypes.any,
      horizontal: PropTypes.any,
    } ),
  ] ),
  /** a method passed in that is called to close the popover from inside the popover */
  onClose: PropTypes.func,
  /** custom popover class */
  popoverClassName: PropTypes.string,
};

ControlledPopoverCreator.defaultProps = {
  align: undefined,
  anchor: undefined,
  content: null,
  closeOnEsc: true,
  closeOnOutsideClick: true,
  closeOnScroll: true,
  isOpen: false,
  offset: undefined,
  onClose: null,
  popoverClassName: "",
};
