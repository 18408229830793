import ngModule from '../ng/index.js';
import '@views/rates/routes';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.rates', {
			url: '/rates',
			template: '<rates-routes flex="100" />',
			redirectTo: 'home.rates.company',
		})
		.state('home.rates.company', {
			url: '/company',
		})
		.state('home.rates.company.add-rate', {
			url: '/add-rate',
		})
		.state('home.rates.location', {
			url: '/location',
		})
		.state('home.rates.location.add-rate', {
			url: '/add-rate',
		})
		.state('home.rates.branch', {
			url: '/branch',
		});
});
