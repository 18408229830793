import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap } from '../../js/components/google-map/google-map.js';

const LocationDisplayMap = ({ location, mapOptions = {} }) => {
	const [map, setMap] = useState(null);
	const [marker, setMarker] = useState(null);

	const handleCreateMap = useCallback(
		(map) => {
			setMap(map);

			const newMarker = new google.maps.Marker({
				position: { lat: 0, lng: 0 },
				map,
			});
			setMarker(newMarker);
		},
		[setMap, setMarker]
	);

	useEffect(() => {
		const latlng = { lat: location.latitude, lng: location.longitude };

		if (location && marker) {
			marker.setPosition(latlng);
		}
		if (location && map) {
			map.setCenter(latlng);
		}
	}, [location, marker]);

	const options = {
		disableDefaultUI: true,
		...mapOptions,
	};

	return <GoogleMap mapOptions={options} onCreateMap={handleCreateMap} />;
};

LocationDisplayMap.propTypes = {
	mapOptions: PropTypes.object,
	location: PropTypes.shape({
		latitude: PropTypes.number.isRequired,
		longitude: PropTypes.number.isRequired,
	}).isRequired,
};

export default LocationDisplayMap;
