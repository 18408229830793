import esApi from './es-api.service';

export async function fetch(id, query, opts) {
	return await esApi.request(esApi.url`/equipment-classes/${id}`, query, opts);
}

export async function search(query, opts) {
	return await esApi.request(esApi.url`/equipment-classes`, query, opts);
}

const equipmentClassService = {
	fetch,
	search,
};

export default equipmentClassService;
