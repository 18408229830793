import React from 'react';
import GlobalProviders from '../../providers/global-providers';

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withGlobalProviders(WrappedComponent) {
	const WithGlobalProviders = ({ children, ...props }) => (
		<GlobalProviders>
			<WrappedComponent {...props}>{children}</WrappedComponent>
		</GlobalProviders>
	);

	WithGlobalProviders.displayName = `WithGlobalProviders(${getDisplayName(WrappedComponent)})`;
	/* https://reacttraining.com/react-router/web/api/withRouter/componentwrappedcomponent */
	WithGlobalProviders.WrappedComponent = WrappedComponent;

	return WithGlobalProviders;
}
