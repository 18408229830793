import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List, AutoSizer, CellMeasurerCache } from 'react-virtualized';

import tableBodyStyles from '../body/body.module.css';

const MeasurerCache = React.createContext();

export class VirtualizedBody extends PureComponent {
	static MeasurerCache = MeasurerCache;

	constructor(...args) {
		super(...args);

		this.renderRow = this.renderRow.bind(this);
		this.cache = new CellMeasurerCache({
			fixedWidth: true,
			minHeight: this.props.minRowHeight,
		});
	}

	UNSAFE_componentWillUpdate(nextProps) {
		if (nextProps.rows !== this.props.rows) {
			this.cache.clearAll();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.minRowHeight !== prevProps.minRowHeight && Number.isFinite(this.props.minRowHeight)) {
			this.cache._minHeight = this.props.minRowHeight;
			this.cache.clearAll();
		}
	}

	renderRow({ key, index, parent, style }) {
		const { rowComponent: RowComponent, rows, additionalRowProps, keyProp } = this.props;

		const entry = rows[index];
		const rowKey = keyProp ? entry[keyProp] : key;

		return RowComponent ? (
			<RowComponent key={rowKey} data={entry} parent={parent} style={style} index={index} {...additionalRowProps} />
		) : null;
	}

	render() {
		const { overscanRowCount, className, style, rows, additionalRowProps } = this.props;

		return (
			<MeasurerCache.Provider value={this.cache}>
				<div className={classNames(tableBodyStyles.body, className)} style={style}>
					<AutoSizer>
						{({ width, height }) => (
							<List
								rowCount={rows.length}
								rowHeight={this.cache.rowHeight}
								rowRenderer={this.renderRow}
								overscanRowCount={overscanRowCount}
								width={width}
								height={height}
								rows={rows} // pass rows to the List to trigger a re-render when rows change
								additionalRowProps={additionalRowProps} // pass additional row props to List so it re-renders the rows when it changes
							/>
						)}
					</AutoSizer>
				</div>
			</MeasurerCache.Provider>
		);
	}
}

VirtualizedBody.propTypes = {
	className: PropTypes.string,
	keyProp: PropTypes.string,
	overscanRowCount: PropTypes.number,
	rowComponent: PropTypes.func,
	minRowHeight: PropTypes.number,
	rows: PropTypes.arrayOf(PropTypes.object),
	style: PropTypes.string,
	additionalRowProps: PropTypes.object,
};
VirtualizedBody.defaultProps = {
	overscanRowCount: 10,
	minRowHeight: 40,
	additionalRowProps: {},
};

export default VirtualizedBody;
