import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import cardStyles from "../card.module.css";

const VARIANTS = {
  default: "default",
  flush: "flush",
};

/* eslint-disable valid-jsdoc */
/**
 * Component used to store the body of a card
 */
const CardBody = ( { children, className, variant, ...props } ) => {
  const cardBodyClassNames = classNames(
    cardStyles.body,
    { [ cardStyles.flush ]: variant === VARIANTS.flush },
    className
  );

  return (
    <div className={cardBodyClassNames} {...props}>{children}</div>
  );
};
/* eslint-enable valid-jsdoc */

CardBody.propTypes = {
  /** Markup to be displayed in the card's body */
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  /** Sets the card variant */
  variant: PropTypes.oneOf( [ VARIANTS.default, VARIANTS.flush ] )
};

CardBody.defaultProps = {
  className: null,
  variant: VARIANTS.default,
};

export default CardBody;
export { VARIANTS };
