import ngModule from '../../../ng/index.js';
import template from './rental-edit-form.html';
import '@components/location-display/location-display-component.js';

ngModule.component('rentalEditForm', {
	template,
	controller: 'RentalEditFormCtrl',
	bindings: {
		rental: '<',
		onSave: '&',
	},
});
