import ngModule from '../../../ng/index.js';
import template from './order-note-manager.html';

function orderNoteManager() {
	return {
		template,
		controller: 'OrderNoteManagerCtrl',
		scope: {
			order: '=',
		},
	};
}

ngModule.directive('orderNoteManager', orderNoteManager);
