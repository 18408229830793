export const FACILITATOR_TYPES_BY_NAME = {
	DELIVERED: 1,
	OUTSOURCED: 2,
	CUSTOMER: 3,
};
export const FACILITATOR_TYPES = [
	{ id: 1, name: 'In House' },
	{ id: 2, name: 'Third Party' },
	{ id: 3, name: 'Customer' },
];
