import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "../icon/icon.js";
import Checkbox from "../form-controls/checkbox/checkbox.js";

import multiCheckboxStyles from "./multi-checkbox.module.css";

function areAllSelected ( values ) {
  return values.every( ( value ) => ( value.checked === true && ( !value.children || areAllSelected( value.children ) ) ) );
}

function areSomeSelectedRecursive ( values ) {
  return values.some( ( value ) => ( value.checked === true || ( value.children && areSomeSelectedRecursive( value.children ) ) ) );
}

function areSomeSelected ( values ) {
  return !areAllSelected( values ) && areSomeSelectedRecursive( values );
}

class MultiCheckbox extends Component {
  constructor ( props ) {
    super( props );

    this.state = {
      allAreSelected: areAllSelected( props.values ),
      someAreSelected: areSomeSelected( props.values ),
      values: props.values,
      collapsed: props.collapsible ? new Set( props.values.map( ( v ) => v.id ) ) : undefined
    };

    this.toggleSelectAll = this.toggleSelectAll.bind( this );
    this.toggleInput = this.toggleInput.bind( this );
    this.generateCheckboxes = this.generateCheckboxes.bind( this );
    this.toggleCollapse = this.toggleCollapse.bind( this );
  }

  UNSAFE_componentWillReceiveProps ( nextProps ) {
    if ( nextProps.values !== this.props.values ) {
      let newState = {
        allAreSelected: areAllSelected( nextProps.values ),
        someAreSelected: areSomeSelected( nextProps.values ),
        values: nextProps.values,
        collapsed: nextProps.collapsible ? new Set( nextProps.values.map( ( v ) => v.id ) ) : undefined
      };

      this.setState( newState );
    }
  }

  recurseToggleAll ( values, allAreSelected ) {
    return values.map( ( value ) => {
      value = {
        ...value,
        checked: allAreSelected,
      };

      if ( value.children ) {
        value.children = this.recurseToggleAll( value.children, allAreSelected );
      }

      return value;
    } );
  }

  toggleSelectAll() {
    this.setState( ( prevState ) => {
      let values = this.recurseToggleAll( prevState.values, !prevState.allAreSelected );

      return {
        allAreSelected: !prevState.allAreSelected,
        someAreSelected: areSomeSelected( values ),
        values,
      };
    }, () => this.props.valuesToggle( this.state.values ) );
  }

  recurseToggleInput ( value, values ) {
    return values.map( ( mappedValue ) => {
      if ( mappedValue.id === value.id ) {
        return {
          ...mappedValue,
          checked: !value.checked,
        };
      }

      if ( mappedValue.children ) {
        mappedValue.children = this.recurseToggleInput( value, mappedValue.children );
      }

      return mappedValue;
    } );
  }

  toggleInput ( value ) {
    return () => {
      var values = this.recurseToggleInput( value, this.state.values );

      this.props.valuesToggle( values );

      this.setState(
        {
          allAreSelected: areAllSelected( values ),
          someAreSelected: areSomeSelected( values ),
          values
        }
      );
    };
  }

  toggleCollapse ( id ) {
    return () => {
      this.setState( ( prevState ) => {
        let collapsed = new Set( prevState.collapsed.values() );

        if ( prevState.collapsed.has( id ) ) {
          collapsed.delete( id );
        } else {
          collapsed.add( id );
        }

        return { collapsed };
      } );
    };
  }

  generateCheckboxes ( values ) {
    return values.map( ( value ) => {
      let children = null;
      // use an empty div to align all list items so that those with children and those without children
      // will have the same indentation level
      let collapser = this.props.collapsible ? <div className={multiCheckboxStyles.collapserIconWrapper} /> : null;
      let isCollapsed = false;

      if ( value.children ) {
        if ( this.props.collapsible ) {
          isCollapsed = this.state.collapsed.has( value.id );
          collapser = (
            <div className={multiCheckboxStyles.collapserIconWrapper} onClick={this.toggleCollapse( value.id )}>
              <Icon className={multiCheckboxStyles.collapserIcon} name={isCollapsed ? "fasCaretRight" : "fasCaretDown"} />
            </div>
          );
        }

        if ( !isCollapsed ) {
          children = <ul>{this.generateCheckboxes( value.children )}</ul>;
        }
      }

      return (
        <li key={value.id}>
          {collapser}
          <div className={multiCheckboxStyles.checkboxWrapper}>
            <Checkbox
              checked={value.checked}
              id={value.id}
              labelText={value.name}
              onClick={this.toggleInput( value )}
              partiallyChecked={value.children && areSomeSelectedRecursive( value.children )}
              value={value.id}
            />
          </div>
          {children}
        </li>
      );
    } );
  }

  render () {
    let checkboxes = this.generateCheckboxes( this.state.values );
    let selectAllPart = this.props.selectAllLabel
      ? (
        <li className={multiCheckboxStyles.selectAll}>
          <Checkbox
            checked={this.state.allAreSelected}
            id="selectAll"
            labelText={this.props.selectAllLabel}
            onClick={this.toggleSelectAll}
            partiallyChecked={this.state.someAreSelected}
          />
        </li>
      )
      : null;

    return (
      <ul className={classNames( multiCheckboxStyles.multiCheckbox, this.props.className )}>
        {selectAllPart}
        {checkboxes}
      </ul>
    );
  }
}

MultiCheckbox.propTypes = {
  /** class applied to checkbox component */
  className: PropTypes.string,
  /** if there are nested children, determines whether they should be collapsible or always expanded */
  collapsible: PropTypes.bool,
  /** Text label for the select all option, if no value is passed in in no select all option will be present */
  selectAllLabel: PropTypes.string,
  /** Array of values that make up the list */
  values: PropTypes.arrayOf( PropTypes.shape( {
    /** is the value checked */
    checked: PropTypes.bool,
    /** id of the value */
    id: PropTypes.number.isRequired, // this is required to ensure elements are unique.
    /** label for the checkbox to display */
    name: PropTypes.node,
    /** an array of values with the same shape as this value */
    children: PropTypes.array,
  } ) ).isRequired,
  /** Callback for when a value is clicked */
  valuesToggle: PropTypes.func
};

MultiCheckbox.defaultProps = {
  className: null,
  collapsible: false,
  selectAllLabel: null,
  valuesToggle: () => {},
};

export default MultiCheckbox;
