import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@equipmentshare/ds2';

import { LOCATION_TYPES } from '../../const';

export const LocationTypePicker = ({ onChange, selectedLocationType, className }) => {
	const setLocationTypeToStreet = useCallback(() => onChange(LOCATION_TYPES.STREET), [onChange]);
	const setLocationTypeToPoBox = useCallback(() => onChange(LOCATION_TYPES.PO_BOX), [onChange]);

	const isSelected = useCallback((locationType) => locationType === selectedLocationType, [selectedLocationType]);
	const getButtonColor = useCallback(
		(locationType) => {
			return isSelected(locationType) ? 'primary' : 'text';
		},
		[isSelected]
	);

	return (
		<EuiFlexGroup className={className} gutterSize="s">
			<EuiFlexItem>
				<EuiButton
					fill={isSelected(LOCATION_TYPES.STREET)}
					color={getButtonColor(LOCATION_TYPES.STREET)}
					onClick={setLocationTypeToStreet}
				>
					Street
				</EuiButton>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiButton
					fill={isSelected(LOCATION_TYPES.PO_BOX)}
					color={getButtonColor(LOCATION_TYPES.PO_BOX)}
					onClick={setLocationTypeToPoBox}
				>
					PO Box
				</EuiButton>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
};

LocationTypePicker.propTypes = {
	selectedLocationType: PropTypes.any,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

export default LocationTypePicker;
