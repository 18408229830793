import React, { Suspense } from 'react';
import { react2angular } from 'react2angular';
import { Route, Switch, Redirect } from 'react-router-dom';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

import withGlobalProviders from '@components/with-global-providers';
import ngModule from '../../ng/index';

const CoverSheetLineItems = React.lazy(() => import('./cover-sheet-line-items'));

const Routes = () => (
	<Suspense fallback={<LoadingOverlay />}>
		<Switch>
			<Route path="/home/special-collections/line-items">
				<CoverSheetLineItems />
			</Route>
			<Redirect from="/home/special-collections" to="/home/special-collections/line-items" />
		</Switch>
	</Suspense>
);

ngModule.component('specialCollectionsRoutes', react2angular(withGlobalProviders(Routes)));

export default Routes;
