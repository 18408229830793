import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _values from 'lodash/values';
import _assign from 'lodash/assign';
import _forEach from 'lodash/forEach';

/** Used when loading a long list of rentals */
const RENTAL_LIST_GRAPQL_MASK = `{
  rental_id,
  rental_type_id,
  start_date,
  end_date,
  price_per_day,
  price_per_week,
  price_per_month,
  rental_status {
    rental_status_id,
    name
  },
  order {
    order_id,
    reference,
    user {
      user_id,
      company {
        company_id,
        name
      }
    }
  },
  equipment {
    equipment_id,
    asset_id,
    name,
    equipment_model {
      equipment_model_id,
      name,
      equipment_make {
        equipment_make_id,
        name
      },
      equipment_classes {
        equipment_class_id,
        name
      }
    }
  },
  equipment_class {
    equipment_class_id,
    name
  },
  current_location {
    location_id,
    nickname,
    street_1,
    city,
    state {
      state_id,
      abbreviation
    }
  }
}`;

angular.module('esApiClient').factory('esAdminRentalService', esAdminRentalService);

function esAdminRentalService(esApiFactoryV2, esCanonicalizer) {
  var RENTAL_STATUS = {
    OPEN_ID: 1,
    OPEN_LABEL: 'Open',
    DRAFT_ID: 2,
    DRAFT_LABEL: 'Draft',
    PENDING_ID: 3,
    PENDING_LABEL: 'Pending',
    SCHEDULED_ID: 4,
    SCHEDULED_LABEL: 'Scheduled',
    ON_RENT_ID: 5,
    ON_RENT_LABEL: 'On Rent',
    OFF_RENT_ID: 6,
    OFF_RENT_LABEL: 'Off Rent',
    COMPLETED_ID: 7,
    COMPLETED_LABEL: 'Completed',
    CANCELLED_ID: 8,
    CANCELLED_LABEL: 'Cancelled',
    BILLED_ID: 9,
    BILLED_LABEL: 'Billed'
  };

  var rentalStatuses = [
    {'name': RENTAL_STATUS.OPEN_LABEL, 'rental_status_id': RENTAL_STATUS.OPEN_ID},
    {'name': RENTAL_STATUS.DRAFT_LABEL, 'rental_status_id': RENTAL_STATUS.DRAFT_ID},
    {'name': RENTAL_STATUS.PENDING_LABEL, 'rental_status_id': RENTAL_STATUS.PENDING_ID},
    {'name': RENTAL_STATUS.SCHEDULED_LABEL, 'rental_status_id': RENTAL_STATUS.SCHEDULED_ID},
    {'name': RENTAL_STATUS.ON_RENT_LABEL, 'rental_status_id': RENTAL_STATUS.ON_RENT_ID},
    {'name': RENTAL_STATUS.OFF_RENT_LABEL, 'rental_status_id': RENTAL_STATUS.OFF_RENT_ID},
    {'name': RENTAL_STATUS.COMPLETED_LABEL, 'rental_status_id': RENTAL_STATUS.COMPLETED_ID},
    {'name': RENTAL_STATUS.CANCELLED_LABEL, 'rental_status_id': RENTAL_STATUS.CANCELLED_ID},
    {'name': RENTAL_STATUS.BILLED_LABEL, 'rental_status_id': RENTAL_STATUS.BILLED_ID}
  ];

  var preOnRentStatuses = _filter(rentalStatuses, function (status) {
    return status.name === RENTAL_STATUS.OPEN_LABEL || status.name === RENTAL_STATUS.DRAFT_LABEL ||
      status.name === RENTAL_STATUS.PENDING_LABEL || status.name === RENTAL_STATUS.SCHEDULED_LABEL
  });

  var markets = [];
  var canonicalizeRental = esCanonicalizer.canonicalizer('rental');

  var states = esApiFactoryV2.getMarkets()
    .then(function (response) {
      var new_markets = response;
      var new_states = {};

      // Add markets to their respective state objects
      _forEach(new_markets, function (market) {
        if (market.state) {
          new_states[market.state.abbreviation] = new_states[market.state.abbreviation] || market.state;

          var marketsInState = new_states[market.state.abbreviation].markets || [];

          marketsInState.push(market);
          market.state.markets = _sortBy(marketsInState, ["name"]);

          new_states[market.state.abbreviation].markets = _sortBy(marketsInState, ["name"]);
        }
      });

      _assign(markets, new_markets);
      return _sortBy(_values(new_states), "name");
    });

  function getMarketsByState() {
    return states;
  }

  function getRentals(config) {
    if (config.rental_id) {
      return getRental(config.rental_id).then(rental => [rental]);
    }

    return esApiFactoryV2.adminGetRentals(config, RENTAL_LIST_GRAPQL_MASK)
      .then( rentals => _map(rentals, canonicalizeRental) );
  }

  function getRental(rentalId) {
    return esApiFactoryV2.adminGetRental(rentalId)
      .then(canonicalizeRental);
  }

  /** Refer to the backend API documentation for available rentalAttributes */
  function createRental(rentalAttributes) {
    return esApiFactoryV2.adminPostRental(rentalAttributes).then(response => {
      return canonicalizeRental(response.data);
    });
  }

  function patchRental(rentalId, payload) {
    return esApiFactoryV2.adminPatchRental(rentalId, payload).then( function() {
      return getRental(rentalId);
    })
  }

  function rentalAssignLocation(rentalId, locationId, startDate){
    return esApiFactoryV2.adminRentalAssignLocation(rentalId, locationId, startDate);
  }

  return {
    rentals: undefined,
    rental: undefined,
    getRentals,
    getRental,
    createRental,
    patchRental,
    rentalAssignLocation,
    refreshRental: getRental,
    markets: markets,
    getMarketsByState,
    rentalStatuses: rentalStatuses,
    preOnRentStatuses: preOnRentStatuses,
    RENTAL_STATUS: RENTAL_STATUS
  }
}

export default esAdminRentalService;
