import ngModule from '../../../ng/index.js';
import _ from 'lodash';

function DriverListCtrl($scope, esDeliveriesService) {
	const controller = this;

	$scope.getShortDeliveryType = esDeliveriesService.getShortDeliveryType;
	$scope.getInitialDropoffStyle = esDeliveriesService.getInitialDropoffStyle;

	$scope.drivers = [];
	$scope.focusedDrivers = new Set();

	$scope.updateDrivers = () => {
		$scope.drivers = [];

		if (!_.isArray(controller.deliveries) || controller.deliveries.length === 0) {
			return;
		}

		_.each(controller.deliveries, (delivery) => {
			if (!delivery.driver) {
				return;
			}

			let driver = _.find($scope.drivers, ['user_id', delivery.driver.user_id]);

			if (!driver) {
				driver = _.clone(delivery.driver);
				driver.deliveries = [];
				$scope.drivers.push(driver);
			}

			driver.deliveries.push(delivery);
		});

		_.each($scope.drivers, (driver) => {
			driver.deliveries = _.sortBy(driver.deliveries, 'run_name');
		});
	};

	$scope.selectDelivery = (delivery) => {
		controller.onDeliverySelect({ delivery });
	};

	$scope.setDeliveryFocus = (delivery, focused) => {
		if (focused) {
			controller.onDeliveryFocus({ delivery });
		} else {
			controller.onDeliveryBlur({ delivery });
		}
	};

	$scope.focusDriver = (driver) => {
		$scope.focusedDrivers.add(driver.user_id);
		controller.onDriverFocus({ driver });
	};
	$scope.blurDriver = (driver) => {
		$scope.focusedDrivers.delete(driver.user_id);
		controller.onDriverBlur({ driver });
	};

	controller.$onChanges = $scope.updateDrivers;
}

ngModule.controller('DriverListCtrl', DriverListCtrl);
