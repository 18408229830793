import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import DebounceLink from 'apollo-link-debounce';

import onErrorLink from '../error-handlers.js';

import esApi from '@services/es-api.service';
import authService from '@services/auth.service';

const DEFAULT_DEBOUNCE_TIME_IN_MS = 300;

const httpLink = createHttpLink({
	uri: esApi.buildEndpoint(esApi.url`/asset-transfer-api/graphql`),
});

const authLink = setContext(async (_, { headers }) => {
	const token = await authService.getApiToken();

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

export const apolloClient = new ApolloClient({
	link: from([new DebounceLink(DEFAULT_DEBOUNCE_TIME_IN_MS), onErrorLink, authLink, httpLink]),
	cache: new InMemoryCache(),
	defaultOptions: {
		query: {
			fetchPolicy: 'network-only',
		},
	},
});
