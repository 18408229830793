import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _isNumber from "lodash/isNumber";

import moment from "moment";

import { getUrls } from "@equipmentshare/env-config";

angular.module( "esApiClient" ).factory( "esApiFactoryV2", esApiFactoryV2 );

function esApiFactoryV2 ( $window, $http, User, $log, $q, $document, esEnv ) {
  const { api } = getUrls();
  let urlRoot = api;

  function setUrlRoot(url) {
    urlRoot = url || api;
  }

  function getPhotoUrl( equipment, thumbnailBoolean, smallBoolean ) {
    if ( equipment.photo ) {
      if ( thumbnailBoolean ) {
        return esEnv.getCdnHost() + "/uploads/thumbnail/" + equipment.photo.filename;
      } else if ( smallBoolean ) {
        return esEnv.getCdnHost() + "/uploads/small/" + equipment.photo.filename;
      } else {
        return esEnv.getCdnHost() + "/uploads/" + equipment.photo.filename;
      }
    } else {
      let rootUrl = esEnv.getEnvLevel() === "production" ? "https://equipmentshare.com" : "https://staging.equipmentshare.com";

      return rootUrl + "/img/" + ( equipment.vehicle_id ? "vehicleplaceholder.png" : "equipmentplaceholder.png" );
    }
  }

  function getDocumentUrl( filename ) {
    return esEnv.getCdnHost() + "/uploads/" + filename;
  }

  function getUser( userId ) {
    return $http.get( urlRoot + "/users/" + userId );
  }

  function postUser( user ) {
    return $http.post( urlRoot + "/users", user );
  }

  function getResponseData( httpPromise ) {
    let defer = $q.defer();

    httpPromise
      .then( ( response ) => defer.resolve( response.data ? response.data : response ) )
      .catch( ( err ) => {
        if( err.status == -1 ) { // https://docs.angularjs.org/api/ng/service/$http
          defer.reject( { message: "Unable to connect to the platform, ensure you have a working internet connection." } );
        } else {
          defer.reject( err );
        }
      } );

    return defer.promise;
  }

  function getEquipmentClasses( categoryId, forceReload ) {
    return getResponseData( $http.get( urlRoot + "/equipment-classes",
      { params: { category_id: categoryId }, cache: !forceReload } ) );
  }

  function getAllEquipmentClasses( forceReload ) {
    return getResponseData( $http.get( urlRoot + "/equipment-classes", { cache: !forceReload } ) );
  }

  function getEquipmentClass( equipmentClassId ) {
    return $http.get( urlRoot + "/equipment-classes/" + equipmentClassId )
      .then( ( response ) => {
        return response.data;
      } );
  }

  function getModels() {
    return $http.get( urlRoot + "/models" );
  }

  function queryModels( query, equipment_make_id ) {
    return getResponseData( $http.get( urlRoot + "/models", { params: { query: query, equipment_make_id: equipment_make_id }, method: "GET" } ) );
  }

  function getMakes() {
    return $http.get( urlRoot + "/makes" );
  }

  function getMake( makeId ) {
    return $http.get( urlRoot + "/makes/" + makeId );
  }

  function queryMakes( query ) {
    return getResponseData( $http.get( urlRoot + "/makes", { params: { query: query }, method: "GET" } ) );
  }

  function getMarkets( forceReload ) {
    return getResponseData( $http.get( urlRoot + "/markets", { cache: !forceReload } ) );
  }

  function getStates() {
    return $http.get( urlRoot + "/states" );
  }

  function getAsset( assetId ) {
    return $http.get( urlRoot + "/assets/" + assetId );
  }

  function postAsset( assetId, assetData ) {
    return $http.post( urlRoot + "/assets/" + assetId, assetData );
  }

  function patchAsset( assetId, assetData ) {
    return $http.patch( urlRoot + "/assets/" + assetId, assetData );
  }

  function getUsersAssets( userId, params, fields, forceReload ) {
    let requestUrl = urlRoot + "/users/" + userId + "/assets";


    return $http( { method: "GET", url: requestUrl, params: params, cache: !forceReload, headers: { "X-Fields": fields } } );
  }

  function postUsersAssets( userId, assetsData ) {
    return $http.post( urlRoot + "/users/" + userId + "/assets", assetsData );
  }

  function getAssetHistory( assetId, start, end ) {
    return $http.get( urlRoot + "/assets/" + assetId + "/history?start=" + start + "&end=" + end );
  }

  function getAssetRecentHistory( assetId ) {
    return $http.get( urlRoot + "/assets/" + assetId + "/history/recent" );
  }

  function getAssetMaintenanceGroup( assetId ) {
    return $http.get( urlRoot + "/assets/" + assetId + "/maintenance-group" );
  }

  function getUsersRentals( userId ) {
    return $http.get( urlRoot + "/users/" + userId + "/rentals" );
  }

  function getAssetPurchaseHistory( assetId ) {
    return getResponseData( $http.get( urlRoot + "/assets/" + assetId + "/purchase-history" ) );
  }

  function postAssetPurchaseHistory( assetId, purchaseHistory ) {
    return getResponseData( $http.post( urlRoot + "/assets/" + assetId + "/purchase-history", purchaseHistory ) );
  }

  function patchRental( rentalId, rental ) {
    return $http.patch( urlRoot + "/rentals/" + rentalId, rental );
  }

  function getUsersVehicleGroups( userId ) {
    return $http.get( urlRoot + "/users/" + userId + "/vehicle-groups" );
  }

  function getMyAccount() {
    return $http.get( urlRoot + "/tokens/" + User.getApiToken() )
      .then( ( response ) => {
        let userId = response.data.user_id;

        return $http.get( urlRoot + "/users/" + userId );
      } );
  }

  /*
   @deprecated
   Use patchUserAccount() instead;
   */
  function saveMyAccount( user ) {
    return $http.get( urlRoot + "/tokens/" + User.getApiToken() )
      .then( ( response ) => {
        let userId = response.data.user_id;

        return $http.patch( urlRoot + "/users/" + userId, user );
      } );
  }

  function patchUserAccount( userId, payload ) {
    let requestUrl = urlRoot + "/users/" + userId;

    return getResponseData( $http( { method: "PATCH", url: requestUrl, data: payload } ) );
  }

  function login ( loginUser ) {
    return $http.post( urlRoot + "/login", loginUser );
  }

  function resetPassword( user, userId, resetToken ) {
    User.setUserId( userId );
    User.setApiToken( resetToken );

    return $http.patch( urlRoot + "/users/" + userId, user );
  }

  function getCategories ( forceReload ) {
    return $http.get( urlRoot + "/categories", { cache: !forceReload } );
  }

  function getToken ( tokenId ) {
    return $http.get( urlRoot + "/tokens/" + tokenId );
  }

  function forgotPassword( forgotPasswordUser, directory ) {
    if ( !directory ) {
      directory = "/manage";
    }

    let resetUrl = urlRoot.replace( "/api/v2", directory + "/#/reset-password" );

    return $http.post( urlRoot + "/forgot-password-tokens", { email: forgotPasswordUser.username, url: resetUrl } );
  }

  function getGeofence( geofenceId ) {
    return $http.get( urlRoot + "/geofences/" + geofenceId );
  }

  function getMyGeofences( userId ) {
    return $http.get( urlRoot + "/users/" + userId + "/geofences" );
  }

  function getCompanyGeofences( companyId, params ) {
    let requestUrl = urlRoot + "/companies/" + companyId + "/geofences";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getCompaniesDocumentTypes() {
    return getResponseData( $http.get(urlRoot + '/admin/companies/company-document-types'));
  }

  function getDocuments() {
    return getResponseData( $http.get(urlRoot + '/admin/companies/documents' ));
  }

  function getCompanyDocuments( companyId, params ) {
    return getResponseData( $http.get( `${urlRoot}/admin/companies/${companyId}/documents`, { params } ) );
  }

  function getCompanyDocument( companyDocumentId ) {
    return getResponseData( $http.get(urlRoot + `/admin/companies/documents/${companyDocumentId}` ));
  }

  function addCompanyDocument( companyId, payload ) {
    return getResponseData( $http.post(urlRoot + `/admin/companies/${companyId}/documents`, payload, {
      transformRequest: angular.identity,
      headers: { "Content-Type": undefined }
    } ));
  }

  function updateCompanyDocument(companyDocumentId, payload ) {
    return getResponseData( $http.patch(urlRoot + `/admin/companies/documents/${companyDocumentId}`, payload));
  }

  function patchGeofence( geofenceId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/geofences/" + geofenceId, payload ) );
  }

  function postGeofence( geofence ) {
    return $http.post( urlRoot + "/geofences", geofence );
  }

  function deleteGeofence( geofenceId ) {
    return getResponseData( $http.delete( urlRoot + "/geofences/" + geofenceId ) );
  }

  function postVehicleGroup( groupName ) {
    return $http.get( urlRoot + "/tokens/" + User.getApiToken() )
      .then( ( response ) => {
        return $http.post( urlRoot + "/users/" + response.data.user_id + "/vehicle-groups", { name: groupName } );
      } );
  }

  function getRental( rentalId ) {
    return $http.get( urlRoot + "/rentals/" + rentalId );
  }

  function getRentalHistory( rentalId, start, end ) {
    return $http.get( urlRoot + "/rentals/" + rentalId + "/history?start=" + start + "&end=" + end );
  }

  function getTimefences() {
    return $http.get( urlRoot + "/tokens/" + User.getApiToken() )
      .then( ( response ) => {
        return $http.get( urlRoot + "/users/" + response.data.user_id + "/time-fences" );
      } );
  }

  function postTimefence( timefence ) {
    return $http.post( urlRoot + "/time-fences", timefence );
  }

  function patchTimefence( timefenceId, timefence ) {
    return $http.patch( urlRoot + "/time-fences/" + timefenceId, timefence );
  }

  function getUsersAlerts( userId, params ) {
    let requestUrl = urlRoot + "/users/" + userId + "/alerts";

    return $http( { method: "GET", url: requestUrl, params: params } );
  }

  function getUsersAlert( userId, alertId, params ) {
    let requestUrl = urlRoot + "/users/" + userId + "/alerts/" + alertId;

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getMaintenanceGroups() {
    return $http.get( urlRoot + "/maintenance-groups" );
  }

  function getMaintenanceGroup( maintenance_group_id ) {
    return getResponseData( $http.get( urlRoot + "/maintenance-groups/" + maintenance_group_id ) );
  }

  function getMaintenanceGroupInterval( maintenance_group_interval_id ) {
    return getResponseData( $http.get( urlRoot + "/maintenance-group-intervals/" + maintenance_group_interval_id ) );
  }

  function getAssetServiceRecords( assetId ) {
    return getResponseData( $http.get( `${urlRoot}/assets/${assetId}/service-records` ) );
  }

  function getRentalServiceRecords( rentalId ) {
    return getResponseData( $http.get( `${urlRoot}/assets/${rentalId}/service-records` ) );
  }

  function getServiceRecord( recordId ) {
    return $http.get( `${urlRoot}/service-records/${recordId}` );
  }

  function patchServiceRecord( recordId, formData ) {
    return $http.patch( `${urlRoot}/service-records/${recordId}`, formData, {
      transformRequest: angular.identity,
      headers: { "Content-Type": undefined }
    } );
  }

  function postAssetServiceRecord( assetId, formData, request = true ) {
    return getResponseData( $http.post( urlRoot + "/assets/" + assetId + "/service-records" + ( request ? "?request=true" : "" ), formData, {
      transformRequest: angular.identity,
      headers: { "Content-Type": undefined }
    } ) );
  }

  function postRentalServiceRecord( rentalId, payload ) {
    return getResponseData( $http.post( urlRoot + "/rentals/" + rentalId + "/service-records?request=true", payload, {
      transformRequest: angular.identity,
      headers: { "Content-Type": undefined }
    } ) );
  }

  function getServiceDocuments( documentCollectionId ) {
    return $http.get( urlRoot + "/service-document-collections/" + documentCollectionId );
  }

  function getAssetAlerts( assetId, params ) {
    let requestUrl = urlRoot + "/assets/" + assetId + "/alerts";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getRentalAlerts( company, rentalId, params ) {
    let requestUrl = urlRoot + "/companies/" + company + "/rentals/" + rentalId + "/alerts";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getUpcomingService( userId, threshold ) {
    let requestString = urlRoot + "/users/" + userId + "/assets/service/upcoming";

    if( threshold ) {
      requestString += "?threshold=" + threshold;
    }

    return $http.get( requestString );
  }

  function getUserPayouts( userId, startMillis ) {
    let requestString = urlRoot + "/users/" + userId + "/payouts";

    if ( startMillis ) {
      requestString += "?start=" + startMillis;
    }

    return $http.get( requestString );
  }

  function getAssetCurrentStatuses() {
    return $http.get( urlRoot + "/users/" + User.getUserId() + "/assets/statuses" );
  }

  function getAssetCurrentStatus( assetId ) {
    return getResponseData( $http.get( urlRoot + "/assets/" + assetId + "/status" ) );
  }

  function getUserLocations( userId ) {
    return $http.get( urlRoot + "/users/" + userId + "/locations" );
  }

  function postUserLocations( userId, locationData ) {
    return $http.post( urlRoot + "/users/" + userId + "/locations", locationData );
  }

  function postPhoto( formData ) {
    return $http.post( urlRoot + "/photos", formData, {
      transformRequest: angular.identity,
      headers: { "Content-Type": undefined }
    } );
  }

  function getUsersVehiclesStateUsage( userId, end, start ) {
    if ( end && start ) {
      return $http.get( urlRoot + "/users/" + userId + "/assets/state-usage?end=" + end + "&start=" + start );
    } else {
      return $http.get( urlRoot + "/users/" + userId + "/assets/state-usage" );
    }
  }

  function postRentalRequest( rentalData ) {
    return $http.post( urlRoot + "/rental-requests", rentalData );
  }

  function getLineItemTypes() {
    return getResponseData( $http.get( urlRoot + "/line-item-types" ) );
  }

  function adminGetLineItem( id ) {
    return getResponseData( $http.get( urlRoot + "/admin/line-items/" + id ) );
  }

  function adminGetLineItemsByRentalId( rentalId ) {
    return getResponseData( $http.get( urlRoot + "/admin/line-items?rental_id=" + rentalId ) );
  }

  function adminPostLineItem( lineItem ) {
    return getResponseData( $http.post( urlRoot + "/admin/line-items", lineItem ) );
  }

  function adminPatchLineItem( id, lineItem ) {
    return $http.patch( urlRoot + "/admin/line-items/" + id, lineItem );
  }

  function adminDeleteLineItem( id ) {
    return $http.delete( urlRoot + "/admin/line-items/" + id );
  }

  function adminGetUser( userId, params ) {
    return getResponseData( $http.get( urlRoot + "/admin/users/" + userId, { params } ) );
  }

  function adminPostUser( user ) {
    return $http.post( urlRoot + "/admin/users", user );
  }

  function adminPatchUser( userId, user, params ) {
    return $http.patch( urlRoot + "/admin/users/" + userId, user, { params } );
  }
  function getInvoicePdf( invoiceId ) {
    return getResponseData( $http.get(urlRoot + `/invoice-render/${invoiceId}.pdf`, { responseType: "blob" }));
  }
  function getBulkInvoicesZip( invoiceIds = [] ) {
    let queryString = invoiceIds.map(id => 'invoice_ids='+id).join('&');
    return getResponseData( $http.get(urlRoot + `/invoice-render/pdf/zip?` + queryString, { responseType: "blob" }));
  }
  function adminGetInvoice(invoiceId) {
    return getResponseData( $http.get(urlRoot + '/admin/invoices/' + invoiceId));
  }

  function adminGetInvoices(config) {
    return getResponseData( $http.get(urlRoot + '/admin/invoices', {params: config, method: 'GET'}));
  }

  function getRentalEstimate( rental ) {
    return getResponseData( $http.post( urlRoot + "/rentals/invoice-estimate", rental ) );
  }

  function adminGetRentalEstimate( rental ) {
    return getResponseData( $http.post( urlRoot + "/admin/rentals/invoice-estimate", rental ) );
  }

  function adminGetRental( rentalId ) {
    return getResponseData($http.get( urlRoot + "/admin/rentals/" + rentalId ));
  }

  function adminGetRentals( config, mask ) {
    let headers = null;

    if( mask ) {
      headers = { "X-Fields": mask.replace( /\r?\n|\r/g, " " ) };
    }

    return getResponseData($http.get( urlRoot + "/admin/rentals", { params: config, headers } ));
  }

  function adminPostRental( rental ) {
    return $http.post( urlRoot + "/admin/rentals", rental );
  }

  function adminPatchRental( rentalId, payload ) {
    return $http.patch( urlRoot + "/admin/rentals/" + rentalId, payload );
  }

  function adminPostInvoice( invoice ) {
    return $http.post( urlRoot + "/admin/invoices", invoice );
  }

  function adminPatchInvoice(invoiceId, invoice) {
    return getResponseData( $http.patch(urlRoot + '/admin/invoices/'+invoiceId, invoice));
  }

  function adminPatchCharge( rental_charge ) {
    return $http.patch( urlRoot + "/admin/rental-charges/" + rental_charge.rental_charge_id, rental_charge );
  }

  function adminGetPayout( payoutId ) {
    return $http.get( urlRoot + "/admin/payouts/" + payoutId );
  }

  function adminGetPayouts( config ) {
    return $http.get( urlRoot + "/admin/payouts", { params: config, method: "GET" } );
  }

  function adminPostPayout( payout ) {
    return $http.post( urlRoot + "/admin/payouts", payout );
  }

  function adminPatchPayout( payoutId, payload ) {
    return $http.patch( urlRoot + "/admin/payouts/" + payoutId, payload );
  }

  function adminGetPayoutProgramAssignment(assignmentId) {
    return getResponseData( $http.get( `${urlRoot}/admin/payout-program-assignments/${assignmentId}` ) );
  }

  function adminPatchPayoutProgramAssignment(assignmentId, payload) {
    return getResponseData( $http.patch( `${urlRoot}/admin/payout-program-assignments/${assignmentId}`, payload ) );
  }

  function adminPostPayoutProgramAssignments(payload) {
    return getResponseData( $http.post( `${urlRoot}/admin/payout-program-assignments`, payload ) );
  }

  function adminGetPayoutProgramAssignments( config ) {
    return getResponseData( $http.get( `${urlRoot}/admin/payout-program-assignments`, { params: config, method: "GET" } ) );
  }

  function adminGetOrders( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/orders", { params: config, method: "GET" } ) );
  }

  function adminGetOrder( orderId ) {
    return getResponseData( $http.get( urlRoot + "/admin/orders/" + orderId ) );
  }

  function adminPostOrder( order ) {
    return getResponseData( $http.post( urlRoot + "/admin/orders", order ) );
  }

  function adminPatchOrder( orderId, order ) {
    return getResponseData( $http.patch( urlRoot + "/admin/orders/" + orderId, order ) );
  }

  function adminPostOrderNote( orderId, order_note ) {
    return getResponseData( $http.post( urlRoot + "/admin/orders/" + orderId + "/order-notes", order_note ) );
  }

  function adminDeleteOrderNote( id ) {
    return $http.delete( urlRoot + "/admin/order-notes/" + id );
  }

  function adminSearchUsers( params ) {
    return getResponseData( $http.get( urlRoot + "/admin/users", { params, method: "GET" } ) );
  }

  function adminSearchCompanies( query ) {
    return getResponseData( $http.get( urlRoot + "/admin/companies", { params: { query: query }, method: "GET" } ) );
  }

  function adminSearchEquipment( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/equipment", { params: config, method: "GET" } ) );
  }

  function adminSearchEquipmentClasses( query ) {
    return getResponseData( $http.get( urlRoot + "/equipment-classes", { params: { query: query }, method: "GET" } ) );
  }

  function adminSearchVehicles( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/vehicles", { params: config, method: "GET" } ) );
  }

  function adminPostEquipment( equipment ) {
    return getResponseData( $http.post( urlRoot + "/admin/equipment", equipment ) );
  }

  function adminGetCompany( companyId ) {
    return getResponseData( $http.patch( urlRoot + "/admin/companies/" + companyId ) );
  }

  function adminPostCompany( company ) {
    return getResponseData( $http.post( urlRoot + "/admin/companies", company ) );
  }

  function adminPatchCompany( companyId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/admin/companies/" + companyId, payload ) );
  }

  function adminGetEquipment( equipmentId ) {
    return getResponseData( $http.get( urlRoot + "/admin/equipment/" + equipmentId ) );
  }

  function adminGetVehicle( vehicleId ) {
    return getResponseData( $http.get( urlRoot + "/admin/vehicles/" + vehicleId ) );
  }

  function adminGetNetTerms() {
    return getResponseData( $http.get( urlRoot + "/admin/net-terms" ) );
  }
  function adminGetBillingProviders() {
    return getResponseData( $http.get( urlRoot + "/admin/companies/billing_providers" ) );
  }
  function getTimeZones() {
    return getResponseData( $http.get( urlRoot + "/timezones" ) );
  }

  function adminGetDeliveries( config, fields ) {
    let headers = null;

    if( fields ) {
      headers = { "X-Fields": fields.replace( /\r?\n|\r/g, " " ) };
    }

    return getResponseData( $http.get( urlRoot + "/admin/deliveries", { params: config, method: "GET", headers: headers } ) );
  }

  function adminGetDelivery( deliveryId, fields ) {
    let headers = null;

    if( fields ) {
      headers = { "X-Fields": fields.replace( /\r?\n|\r/g, " " ) };
    }

    return getResponseData( $http.get( urlRoot + "/admin/deliveries/" + deliveryId, { headers: headers } ) );
  }

  function adminPostDelivery( payload ) {
    return getResponseData( $http.post( urlRoot + "/admin/deliveries", payload ) );
  }

  function adminPatchDelivery( deliveryId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/admin/deliveries/" + deliveryId, payload ) );
  }

  function adminGetRentalActivity( configOrRentalId, configOrNone ) {
    let config, url;

    if( _isNumber( configOrRentalId ) ) {
      url = urlRoot + "/admin/rentals/" + configOrRentalId + "/activities";
      config = configOrNone;
    } else {
      url = urlRoot + "/admin/rentals/activities";
      config = configOrRentalId;
    }

    return getResponseData( $http.get( url, { params: config, method: "GET" } ) );
  }

  function adminGetOverdueRentalTasks( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/rentals/overdue", { params: config, method: "GET" } ) );
  }

  function adminGetRentalsNeedingDeliveries( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/rentals/needing-deliveries", { params: config, method: "GET" } ) );
  }

  function adminGetMaintenanceReports( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/maintenance", { params: config, method: "GET" } ) );
  }

  function adminGetReport( name, config ) {
    return getResponseData( $http.get( urlRoot + "/admin/reports/" + name, { params: config, method: "GET" } ) );
  }

  function adminGetAssetAuditReport( assetIds ) {
    let param = assetIds.map(id => 'asset_id='+id).join('&');
    return getResponseData( $http.get( urlRoot + "/admin/reports/asset-audit?" + param));
  }

  function adminRentalAssignLocation( rentalId, locationId, startDate ) {
    return getResponseData( $http.post( urlRoot + `/admin/rentals/${rentalId}/assigned-location`, {
      location_id: locationId,
      start_date: moment( startDate ).valueOf()
    } ) );
  }

  function getCompany( companyId ) {
    return getResponseData( $http.get( urlRoot + "/companies/" + companyId ) );
  }

  function getCompaniesUsers( companyId ) {
    return getResponseData( $http.get( urlRoot + "/companies/" + companyId + "/users" ) );
  }

  function postCompanyUser( companyId, user ) {
    return $http.post( urlRoot + "/companies/" + companyId + "/users", user );
  }

  function patchCompanyUser( companyId, userId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/companies/" + companyId + "/users/" + userId, payload ) );
  }

  function getCompanyRentals( companyId, params ) {
    let requestUrl = urlRoot + "/companies/" + companyId + "/rentals";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getCompanyInvoices( companyId ) {
    return getResponseData( $http.get( urlRoot + "/companies/" + companyId + "/invoices" ) );
  }

  function getInvoice( invoiceId ) {
    return getResponseData( $http.get( urlRoot + "/invoices/" + invoiceId ) );
  }

  function getCompanyPaymentMethods( companyId, params ) {
    let requestUrl = urlRoot + "/companies/" + companyId + "/payment-methods";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function postCompanyPaymentMethod( companyId, paymentMethodInfo ) {
    return $http.post( urlRoot + "/companies/" + companyId + "/payment-methods", paymentMethodInfo );
  }

  function postCompanyPayment( companyId, paymentInfo ) {
    return $http.post( urlRoot + "/companies/" + companyId + "/payments", paymentInfo );
  }

  function getCompanyLocations( companyId ) {
    return getResponseData( $http.get( urlRoot + "/companies/" + companyId + "/locations" ) );
  }

  function postCompanyLocation( companyId, location ) {
    return getResponseData( $http.post( urlRoot + "/companies/" + companyId + "/locations", location ) );
  }

  function getUsersOrganizations( userId ) {
    return getResponseData( $http.get( urlRoot + "/users/" + userId + "/organizations" ) );
  }

  function getCompaniesOrganizations( companyId ) {
    return $http.get( urlRoot + "/companies/" + companyId + "/organizations" );
  }

  function postCompanyOrganization( companyId, organization ) {
    return $http.post( urlRoot + "/companies/" + companyId + "/organizations", organization );
  }

  function deleteCompanyOrganization( companyId, organizationId ) {
    return getResponseData( $http.delete( urlRoot + "/companies/" + companyId + "/organizations/" + organizationId ) );
  }

  function postCompanyOrganizationUser( companyId, organizationId, userInfo ) {
    return $http.post( urlRoot + "/companies/" + companyId + "/organizations/" + organizationId + "/users", userInfo );
  }

  function patchCompanyOrganizationUser( companyId, organizationId, userId, contactInfo ) {
    return $http.patch( urlRoot + "/companies/" + companyId + "/organizations/" + organizationId + "/users/" + userId, contactInfo );
  }

  function getCompanyOrganizationUser( companyId, organizationId ) {
    return $http.get( urlRoot + "/companies/" + companyId + "/organizations/" + organizationId + "/users" );
  }

  function deleteCompanyOrganizationUser( companyId, organizationId, userId ) {
    return $http.delete( urlRoot + "/companies/" + companyId + "/organizations/" + organizationId + "/users/" + userId );
  }

  function getCompanyTrackers( companyId ) {
    return $http.get( urlRoot + "/companies/" + companyId + "/trackers" );
  }

  function getCompanyOrders( companyId, params ) {
    let requestUrl = urlRoot + "/companies/" + companyId + "/orders";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getCompanyOrder( companyId, orderId, params ) {
    let requestUrl = urlRoot + "/companies/" + companyId + "/orders/" + orderId;

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getOrderContracts( orderId ) {
    return getResponseData( $http.get( urlRoot + "/orders/" + orderId + "/contracts" ) );
  }

  function adminGetAssetEquipmentAssignments ( assetId ) {
    return getResponseData( $http.get( urlRoot + "/admin/assets/" + assetId + "/equipment-assignments" ) );
  }

  function getTrackerVendors() {
    return getResponseData( $http.get( urlRoot + "/tracker-vendors" ) );
  }

  function adminGetTracker( trackerId ) {
    return $http.get( urlRoot + "/admin/trackers/" + trackerId );
  }

  function adminGetTrackers( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/trackers", { params: config, method: "GET" } ) );
  }

  function adminPostTracker( tracker ) {
    return $http.post( urlRoot + "/admin/trackers", tracker );
  }

  function adminPatchTracker( tracker ) {
    return $http.patch( urlRoot + "/admin/trackers/" + tracker.tracker_id, tracker );
  }

  function getCompanyOrganizationGeofences( companyId, organizationId ) {
    return getResponseData( $http.get( urlRoot + "/companies/" + companyId + "/organizations/" + organizationId + "/geofences" ) );
  }

  function postCompanyOrganizationGeofences( companyId, organizationId, payload ) {
    return getResponseData( $http.post( urlRoot + "/companies/" + companyId + "/organizations/" + organizationId + "/geofences", payload ) );
  }

  function patchCompanyAssets( companyId, assetSettings ) {
    return $http.patch( urlRoot + "/companies/" + companyId + "/assets", assetSettings );
  }

  function patchCompanyRental( companyId, rentalId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/companies/" + companyId + "/rentals/" + rentalId, payload ) );
  }

  function getRentalProtectionPlans() {
    return getResponseData( $http.get( urlRoot + "/rental-protection-plans" ) );
  }

  function postAdminRentalAgreement( payload ) {
    return getResponseData( $http.post( urlRoot + "/admin/rental-agreements", payload ) );
  }

  function getUsersGeofenceUsage( userId, geofenceId, start, end ) {
    return getResponseData( $http.get( urlRoot + "/users/" + userId + "/geofences/" + geofenceId + "/usage?start=" + start + "&end=" + end ) );
  }

  function adminGetLocation( locationId ) {
    return getResponseData( $http.get( urlRoot + "/admin/locations/" + locationId ) );
  }

  /**
   * Get jobsites by the specified filter.
   * @param filter {query: string, jobsite: bool, location_type: int} filter to pass to the API, see API docs for details
   * @returns Promise of array of locations
   */
  function adminGetLocations( filter ) {
    return getResponseData( $http.get( urlRoot + "/admin/locations", { params: filter, method: "GET" } ) );
  }

  function adminPostLocation( payload ) {
    return getResponseData( $http.post( urlRoot + "/admin/locations", payload ) );
  }

  function adminPatchLocation( locationId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/admin/locations/" + locationId, payload ) );
  }

  function adminGetEquipmentAssignment( assignmentId ) {
    return getResponseData( $http.get( urlRoot + "/admin/equipment-assignments/" + assignmentId ) );
  }

  function adminGetEquipmentAssignments( config ) {
    return getResponseData( $http.get( urlRoot + "/admin/equipment-assignments", { params: config, method: "GET" } ) );
  }

  function adminPostEquipmentAssignment( payload ) {
    return getResponseData( $http.post( urlRoot + "/admin/equipment-assignments", payload ) );
  }

  function adminPatchEquipmentAssignment( assignmentId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/admin/equipment-assignments/" + assignmentId, payload ) );
  }

  function adminPostSwapEquipmentAssignment( rentalId, payload ) {
    return getResponseData( $http.post( urlRoot + "/admin/rentals/" + rentalId + "/assigned-equipment", payload ) );
  }

  function adminPatchAssetOwner( assetId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/admin/assets/" + assetId + "/owner", payload ) );
  }

  function getCompaniesFuelPurchases( companyId, start, end ) {
    return getResponseData( $http.get( urlRoot + "/companies/" + companyId + "/fuel-purchases?start=" + start + "&end=" + end ) );
  }

  function patchCompanyFuelPurchase( companyId, fuelPurchaseId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/companies/" + companyId + "/fuel-purchases/" + fuelPurchaseId, payload ) );
  }

  function opsGetAsset( assetId ){
    return getResponseData( $http.get( urlRoot + "/operations/assets/" + assetId ) );
  }

  function opsGetAssetWithInventoryStatus( assetId ) {
    return getResponseData( $http.get( urlRoot + "/operations/assets/" + assetId + "/inventory-status" ) );
  }
  function addConditionSnapshot( snapshot ) {
    return getResponseData( $http.post( urlRoot + "/assets/condition-snapshots", snapshot) );
  }
  function addDeliveryInspection( deliveryId, payload ) {
    return getResponseData( $http.post( urlRoot + `/deliveries/${deliveryId}/delivery-inspections`, payload) );
  }
  function opsGetConditionSnapshots( params, mask ) {
    let headers = null;
    if( mask ) {
      headers = { "X-Fields": mask.replace( /\r?\n|\r/g, " " ) };
    }
    return getResponseData( $http.get( urlRoot + "/operations/assets/condition-snapshots", { params, headers }) );
  }
  function opsGetAssetsWithInventoryStatus( marketId, mask, opts ) {
    let headers = null;
    const allMarkets = marketId === "all";

    if( mask ) {
      headers = { "X-Fields": mask.replace( /\r?\n|\r/g, " " ) };
    }
    let params = !allMarkets ? { market_id: marketId } : {};
    if ( opts && opts.view_all_types ) {
      params.view_all_types = true;
    }
    let url = `${urlRoot}/operations/assets/inventory-status`;
    return getResponseData( $http.get( url, { params, headers } ) );
  }

  function getAssetInspections( assetId ) {
    return getResponseData( $http.get( urlRoot + "/operations/assets/" + assetId + "/inspections" ) );
  }

  function createInspectionItem( assetId, inspectionId, item ) {
    return getResponseData( $http.post( urlRoot + "/operations/assets/" + assetId + "/inspection/" + inspectionId + "/items", item ) );
  }

  function getInspectionItem( assetId, inspectionId, itemId ) {
    return getResponseData( $http.get( urlRoot + "/operations/assets/" + assetId + "/inspection/" + inspectionId + "/item/" + itemId ) );
  }

  function patchInspectionItem( assetId, inspectionId, itemId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/operations/assets/" + assetId + "/inspection/" + inspectionId + "/item/" + itemId, payload ) );
  }

  function getAssetInspection( assetId, inspectionId ) {
    return getResponseData( $http.get( urlRoot + "/operations/assets/" + assetId + "/inspection/" + inspectionId ) );
  }

  function patchAssetInspection( assetId, inspectionId, payload ) {
    return getResponseData( $http.patch( urlRoot + "/operations/assets/" + assetId + "/inspection/" + inspectionId, payload ) );
  }

  function createAssetInspection( assetId, rentalId ) {
    let payload = {};

    if ( rentalId ) {
      payload.rental_id = rentalId;
    }

    return getResponseData( $http.post( urlRoot + "/operations/assets/" + assetId + "/inspections", payload ) );
  }

  function getAssetInspectionStatuses( params ) {
    return getResponseData( $http.get( urlRoot + "/operations/assets/inspection_statuses", { params: params } ) );
  }

  function adminPostCommand( commandName, params ) {
    return getResponseData( $http.post( urlRoot + "/admin/commands/" + commandName, { params: params } ) );
  }

  function getAssetStatus( assetId ) {
    return getResponseData( $http.get( urlRoot + "/assets/" + assetId + "/status" ) );
  }

  function getInventoryStatuses() {
    return getResponseData( $http.get( urlRoot + "/assets/inventory-statuses", { cache: true } ) );
  }

  function getAssetCurrentTrip( userId, assetId, fields ) {
    let requestUrl = urlRoot + "/users/" + userId + "/assets/" + assetId + "/current-trip";

    return getResponseData( $http( { method: "GET", url: requestUrl, headers: { "X-Fields": fields } } ) );
  }

  function getRentalCurrentTrip( userId, rentalId, fields ) {
    let requestUrl = urlRoot + "/users/" + userId + "/rental/" + rentalId + "/current-trip";

    return getResponseData( $http( { method: "GET", url: requestUrl, headers: { "X-Fields": fields } } ) );
  }

  function getAssetTrips( assetId, params, fields ) {
    let requestUrl = urlRoot + "/assets/" + assetId + "/trips";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params, headers: { "X-Fields": fields } } ) );
  }

  function getRentalTrips( rentalId, params, fields ) {
    let requestUrl = urlRoot + "/rentals/" + rentalId + "/trips";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params, headers: { "X-Fields": fields } } ) );
  }

  function getRentalKeypadEntries( rentalId, params, fields ) {
    let requestUrl = urlRoot + "/rentals/" + rentalId + "/keypad-entries";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params, headers: { "X-Fields": fields } } ) );
  }

  function adminGetCompanyNoteTypes() {
    return getResponseData( $http.get( `${urlRoot}/admin/companies/company-note-types` ) );
  }

  function adminGetCompanyNotes( companyId ) {
    return getResponseData( $http.get( `${urlRoot}/admin/companies/${companyId}/notes` ) );
  }

  function adminPostCompanyNote( companyId, noteTypeId, note ) {
    return getResponseData( $http.post( `${urlRoot}/admin/companies/${companyId}/notes`, {
      "note_text": note,
      "company_note_type_id": noteTypeId
    } ) );
  }

  function getAssetUpcomingServices( assetId ) {
    return getResponseData( $http.get( urlRoot + "/assets/" + assetId + "/services/minimal?threshold=1" ) );
  }

  function getAssetEngineCodes( assetId, params ) {
    let requestUrl = urlRoot + "/assets/" + assetId + "/codes";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getRentalEngineCodes( rentalId, params ) {
    let requestUrl = urlRoot + "/rentals/" + rentalId + "/codes";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getElogsToken( userId, params ) {
    let requestUrl = urlRoot + "/users/" + userId + "/elogs-token";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getUsersAssetEngineStatuses( userId, assetId, params ) {
    let requestUrl = urlRoot + "/users/" + userId + "/assets/" + assetId + "/engine-statuses";

    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function lockAsset( id ) {
    return getResponseData( $http.post( urlRoot + "/assets/" + id + "/lock", {} ) );
  }

  function unlockAsset( id ) {
    return getResponseData( $http.post( urlRoot + "/assets/" + id + "/unlock", {} ) );
  }

  function getScheduledLocks( id ) {
    return getResponseData( $http.get( urlRoot + "/assets/" + id + "/scheduled-locks" ) );
  }
  function getScheduledLock( assetId, lockId ) {
    return getResponseData( $http.get( urlRoot + "/assets/" + assetId + "/scheduled-lock/" + lockId ) );
  }
  function getActiveScheduledLock( id ) {
    return getResponseData( $http.get( urlRoot + "/assets/" + id + "/active-scheduled-lock" ) );
  }
  function setScheduledLock( id, lockSchedule ) {
    return getResponseData( $http.post( urlRoot + "/assets/" + id + "/scheduled-lock", lockSchedule ) );
  }
  function patchScheduledLock( assetId, lockId, lockSchedule ) {
    return getResponseData( $http.patch( urlRoot + "/assets/" + assetId + "/scheduled-lock/" + lockId, lockSchedule ) );
  }
  function deleteScheduledLock( assetId, lockId ) {
    return $http.delete( urlRoot + "/assets/" + assetId + "/scheduled-lock/" + lockId );
  }

  /**
   * @param params {start:Number, end:Number} time span to cover
   * @returns [{}] list of deliveries
   */
  function getDeliveries( params ) {
    let requestUrl = urlRoot + "/deliveries";


    return getResponseData( $http( { method: "GET", url: requestUrl, params: params } ) );
  }

  function getDelivery( id ) {
    let requestUrl = urlRoot + "/deliveries/" + id;


    return getResponseData( $http( { method: "GET", url: requestUrl } ) );
  }

  function patchDelivery( id, params ) {
    let requestUrl = urlRoot + "/deliveries/" + id;


    return getResponseData( $http( { method: "PATCH", url: requestUrl, params: params } ) );
  }

  function getFuelTaxRates() {
    return getResponseData( $http.get( urlRoot + "/fuel-tax-rates" ) );
  }

  function sendRentalRequestEmails( emailData ) {
    return getResponseData( $http.post( urlRoot + "/order-rentals", emailData ) );
  }

  function getMyAssignments () {
    return $http.get( urlRoot + "/tokens/" + User.getApiToken() )
      .then( ( response ) => {
        let userId = response.data.user_id;

        return $http.get( urlRoot + "/assignments" + "?user_id=" + userId )
          .then( ( response ) => {
            let assignmentsList = response.data;

            _forEach( assignmentsList, ( assignment ) => {
              let locationTitle = "";

              if ( !_isEmpty( assignment.location.nickname ) ) {
                locationTitle = assignment.location.nickname;
              } else {
                locationTitle = assignment.location.street_1;
                locationTitle += ", " + assignment.location.city;
                locationTitle += " " + assignment.location.state.abbreviation;
              }
              assignment.location._locationTitle = locationTitle;
            } );

            return response;
          } );
      } );
  }

  function getZipCode ( zipCodeId ) {
    return $http.get( urlRoot + "/zip-codes/" + zipCodeId );
  }

  function getBranches(query){
    return getResponseData($http.get(urlRoot + '/branches', { params: query } ));
  }
  function getCompanyBranches(companyId, query) {
    return getResponseData($http.get(urlRoot + '/company/' + companyId + '/branches', query));
  }
  function getBranch(branchId, query) {
    return getResponseData($http.get(urlRoot + '/branches/' + branchId, query));
  }

  return {
    setUrlRoot,
    patchAssetInspection: patchAssetInspection,
    createAssetInspection: createAssetInspection,
    patchInspectionItem: patchInspectionItem,
    getAssetStatus: getAssetStatus,
    createInspectionItem: createInspectionItem,
    getInspectionItem: getInspectionItem,
    getAssetInspection,
    getAssetInspections,
    getPhotoUrl: getPhotoUrl,
    getDocumentUrl: getDocumentUrl,
    // Endpoints
    login: login,
    getUser: getUser,
    postUser: postUser,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    getCategories: getCategories,
    getToken: getToken,
    getModels: getModels,
    queryModels: queryModels,
    getMakes: getMakes,
    getMake: getMake,
    queryMakes: queryMakes,
    getMarkets: getMarkets,
    getStates: getStates,
    getInventoryStatuses: getInventoryStatuses,
    getEquipmentClass: getEquipmentClass,
    getEquipmentClasses: getEquipmentClasses,
    getAllEquipmentClasses: getAllEquipmentClasses,
    getUsersRentals: getUsersRentals,
    getAssetPurchaseHistory: getAssetPurchaseHistory,
    postAssetPurchaseHistory: postAssetPurchaseHistory,
    getAsset: getAsset,
    postAsset: postAsset,
    patchAsset: patchAsset,
    getUsersAssets: getUsersAssets,
    postUsersAssets: postUsersAssets,
    getAssetHistory: getAssetHistory,
    getAssetRecentHistory: getAssetRecentHistory,
    getAssetMaintenanceGroup: getAssetMaintenanceGroup,
    patchRental: patchRental,
    getUsersVehicleGroups: getUsersVehicleGroups,
    getMyAccount: getMyAccount,
    saveMyAccount: saveMyAccount,
    patchUserAccount: patchUserAccount,
    getMyGeofences: getMyGeofences,
    getCompanyGeofences: getCompanyGeofences,
    getCompaniesDocumentTypes: getCompaniesDocumentTypes,
    getDocuments: getDocuments,
    getCompanyDocuments,
    getCompanyDocument,
    addCompanyDocument,
    updateCompanyDocument,
    getGeofence: getGeofence,
    patchGeofence: patchGeofence,
    postGeofence: postGeofence,
    deleteGeofence: deleteGeofence,
    postVehicleGroup: postVehicleGroup,
    getTrackerVendors: getTrackerVendors,
    getRental: getRental,
    getRentalHistory: getRentalHistory,
    getTimefences: getTimefences,
    postTimefence: postTimefence,
    patchTimefence: patchTimefence,
    getUsersAlerts: getUsersAlerts,
    getUsersAlert: getUsersAlert,
    getMaintenanceGroups: getMaintenanceGroups,
    getMaintenanceGroup: getMaintenanceGroup,
    getMaintenanceGroupInterval: getMaintenanceGroupInterval,
    getAssetServiceRecords: getAssetServiceRecords,
    getRentalServiceRecords: getRentalServiceRecords,
    getServiceRecord: getServiceRecord,
    patchServiceRecord: patchServiceRecord,
    postAssetServiceRecord: postAssetServiceRecord,
    postRentalServiceRecord: postRentalServiceRecord,
    getServiceDocuments: getServiceDocuments,
    getRentalAlerts: getRentalAlerts,
    getAssetAlerts: getAssetAlerts,
    getUpcomingService: getUpcomingService,
    getUserPayouts: getUserPayouts,
    getAssetCurrentStatuses: getAssetCurrentStatuses,
    getAssetCurrentStatus: getAssetCurrentStatus,
    getUserLocations: getUserLocations,
    postUserLocations: postUserLocations,
    postPhoto: postPhoto,
    getUsersVehiclesStateUsage: getUsersVehiclesStateUsage,
    getMyAssignments: getMyAssignments,
    getZipCode: getZipCode,
    getLineItemTypes: getLineItemTypes,
    postRentalRequest: postRentalRequest,
    getCompany: getCompany,
    getCompaniesUsers: getCompaniesUsers,
    postCompanyUser: postCompanyUser,
    patchCompanyUser: patchCompanyUser,
    getCompanyInvoices: getCompanyInvoices,
    getDelivery: getDelivery,
    getDeliveries: getDeliveries,
    patchDelivery: patchDelivery,
    getInvoice: getInvoice,
    getCompanyPaymentMethods: getCompanyPaymentMethods,
    postCompanyPaymentMethod: postCompanyPaymentMethod,
    postCompanyPayment: postCompanyPayment,
    getCompanyRentals: getCompanyRentals,
    getCompanyLocations: getCompanyLocations,
    postCompanyLocation: postCompanyLocation,
    getUsersOrganizations: getUsersOrganizations,
    getCompaniesOrganizations: getCompaniesOrganizations,
    postCompanyOrganization: postCompanyOrganization,
    deleteCompanyOrganization: deleteCompanyOrganization,
    getCompanyOrganizationUser: getCompanyOrganizationUser,
    postCompanyOrganizationUser: postCompanyOrganizationUser,
    patchCompanyOrganizationUser: patchCompanyOrganizationUser,
    deleteCompanyOrganizationUser: deleteCompanyOrganizationUser,
    getCompanyTrackers: getCompanyTrackers,
    getCompanyOrders: getCompanyOrders,
    getCompanyOrder: getCompanyOrder,
    getOrderContracts: getOrderContracts,
    getCompanyOrganizationGeofences: getCompanyOrganizationGeofences,
    postCompanyOrganizationGeofences: postCompanyOrganizationGeofences,
    patchCompanyAssets: patchCompanyAssets,
    patchCompanyRental: patchCompanyRental,
    getRentalProtectionPlans: getRentalProtectionPlans,
    getUsersGeofenceUsage: getUsersGeofenceUsage,
    postAdminRentalAgreement: postAdminRentalAgreement,
    adminGetAssetEquipmentAssignments: adminGetAssetEquipmentAssignments,
    adminGetTracker: adminGetTracker,
    adminGetTrackers: adminGetTrackers,
    adminPostTracker: adminPostTracker,
    adminPatchTracker: adminPatchTracker,
    adminGetUser: adminGetUser,
    adminPostUser: adminPostUser,
    adminPatchUser: adminPatchUser,
    getInvoicePdf: getInvoicePdf,
    getBulkInvoicesZip: getBulkInvoicesZip,
    adminGetInvoice: adminGetInvoice,
    adminGetInvoices: adminGetInvoices,
    adminPostInvoice: adminPostInvoice,
    adminPatchInvoice: adminPatchInvoice,
    adminPatchCharge: adminPatchCharge,
    adminSearchUsers: adminSearchUsers,
    adminSearchCompanies: adminSearchCompanies,
    adminSearchEquipment: adminSearchEquipment,
    adminSearchEquipmentClasses: adminSearchEquipmentClasses,
    adminSearchVehicles: adminSearchVehicles,
    adminPostEquipment: adminPostEquipment,
    adminGetCompany: adminGetCompany,
    adminPostCompany: adminPostCompany,
    adminPatchCompany: adminPatchCompany,
    adminGetPayout: adminGetPayout,
    adminGetPayouts: adminGetPayouts,
    adminPatchPayout: adminPatchPayout,
    adminPostPayout: adminPostPayout,
    adminGetRental: adminGetRental,
    adminGetRentals: adminGetRentals,
    adminPostRental: adminPostRental,
    adminPatchRental: adminPatchRental,
    adminRentalAssignLocation: adminRentalAssignLocation,
    adminGetOrders: adminGetOrders,
    adminGetOrder: adminGetOrder,
    adminPostOrder: adminPostOrder,
    adminPatchOrder: adminPatchOrder,
    adminPostOrderNote: adminPostOrderNote,
    adminDeleteOrderNote: adminDeleteOrderNote,
    adminGetLineItem: adminGetLineItem,
    adminGetLineItemsByRentalId: adminGetLineItemsByRentalId,
    adminPostLineItem: adminPostLineItem,
    adminPatchLineItem: adminPatchLineItem,
    adminDeleteLineItem: adminDeleteLineItem,
    adminGetEquipment: adminGetEquipment,
    adminGetVehicle: adminGetVehicle,
    adminGetDeliveries: adminGetDeliveries,
    adminGetDelivery: adminGetDelivery,
    adminPostDelivery: adminPostDelivery,
    adminPatchDelivery: adminPatchDelivery,
    adminGetRentalActivity: adminGetRentalActivity,
    adminGetOverdueRentalTasks: adminGetOverdueRentalTasks,
    getRentalEstimate: getRentalEstimate,
    adminGetRentalEstimate: adminGetRentalEstimate,
    adminGetRentalsNeedingDeliveries: adminGetRentalsNeedingDeliveries,
    adminGetMaintenanceReports: adminGetMaintenanceReports,
    adminGetLocation: adminGetLocation,
    adminGetLocations: adminGetLocations,
    adminPostLocation: adminPostLocation,
    adminPatchLocation: adminPatchLocation,
    adminGetEquipmentAssignment: adminGetEquipmentAssignment,
    adminGetEquipmentAssignments: adminGetEquipmentAssignments,
    adminPostEquipmentAssignment: adminPostEquipmentAssignment,
    adminPatchEquipmentAssignment: adminPatchEquipmentAssignment,
    adminPostSwapEquipmentAssignment: adminPostSwapEquipmentAssignment,
    adminPatchAssetOwner: adminPatchAssetOwner,
    adminPostPayoutProgramAssignments: adminPostPayoutProgramAssignments,
    adminPatchPayoutProgramAssignment: adminPatchPayoutProgramAssignment,
    adminGetPayoutProgramAssignment: adminGetPayoutProgramAssignment,
    adminGetPayoutProgramAssignments: adminGetPayoutProgramAssignments,
    adminGetReport: adminGetReport,
    adminGetAssetAuditReport,
    adminPostCommand: adminPostCommand,
    adminGetNetTerms: adminGetNetTerms,
    adminGetBillingProviders: adminGetBillingProviders,
    getTimeZones: getTimeZones,
    addConditionSnapshot: addConditionSnapshot,
    opsGetConditionSnapshots: opsGetConditionSnapshots,
    opsGetAsset: opsGetAsset,
    opsGetAssetsWithInventoryStatus: opsGetAssetsWithInventoryStatus,
    opsGetAssetWithInventoryStatus: opsGetAssetWithInventoryStatus,
    getCompaniesFuelPurchases: getCompaniesFuelPurchases,
    patchCompanyFuelPurchase: patchCompanyFuelPurchase,
    getAssetCurrentTrip: getAssetCurrentTrip,
    getRentalCurrentTrip: getRentalCurrentTrip,
    getAssetTrips: getAssetTrips,
    getRentalTrips: getRentalTrips,
    getRentalKeypadEntries: getRentalKeypadEntries,
    adminGetCompanyNotes: adminGetCompanyNotes,
    adminGetCompanyNoteTypes: adminGetCompanyNoteTypes,
    adminPostCompanyNote: adminPostCompanyNote,
    getAssetUpcomingServices: getAssetUpcomingServices,
    getAssetEngineCodes: getAssetEngineCodes,
    getRentalEngineCodes: getRentalEngineCodes,
    getElogsToken: getElogsToken,
    getUsersAssetEngineStatuses: getUsersAssetEngineStatuses,
    getAssetInspectionStatuses: getAssetInspectionStatuses,
    getFuelTaxRates: getFuelTaxRates,
    sendRentalRequestEmails: sendRentalRequestEmails,
    lockAsset: lockAsset,
    unlockAsset: unlockAsset,
    getScheduledLock: getScheduledLock,
    getScheduledLocks: getScheduledLocks,
    getActiveScheduledLock: getActiveScheduledLock,
    setScheduledLock: setScheduledLock,
    patchScheduledLock: patchScheduledLock,
    deleteScheduledLock: deleteScheduledLock,
    getBranches,
    getCompanyBranches,
    getBranch,
    addDeliveryInspection,
  };
}

export default esApiFactoryV2;
