import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Input from "../input/input.js";

/**
 * Any props passed in will get passed down into the Input component, the only
 * prop that gets modified is onChange
 */
export class StrictDecimalInput extends PureComponent {
  constructor( props ) {
    super( props );

    this.onChange = this.onChange.bind( this );
  }

  onChange( event ) {
    let accuracy = this.props.accuracy;
    var decimalValue = new RegExp( `^(?:\\d*\\.\\d{0,${accuracy}}|\\d+)?$` ); // Allows for an empty string or a number or a number with one decimal followed by as many digits as the accuracy prop allows

    if ( String( event.target.value ).match( decimalValue ) ) {
      if ( String( event.target.value ).match( /^\./ ) ) {
        event.target.value = `0${event.target.value}`;
      }

      this.props.onChange( event );
    }
  }

  render() {
    return (
      <Input {...this.props} onChange={this.onChange} type="text" />
    );
  }
}

StrictDecimalInput.defaultProps = {
  accuracy: 2,
};

StrictDecimalInput.propTypes = {
  /** Determines accuracy of decimal numbers **/
  accuracy: PropTypes.number,
  /** onChange event handler */
  onChange: PropTypes.func.isRequired,
};

export default StrictDecimalInput;
