import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../tooltip/tooltip";

const BleLowBatteryIcon = ( { tooltipDirection } ) => {
  return (
    <Tooltip direction={tooltipDirection} text="Low Battery">
      <img src="images/low-battery-red.svg" />
    </Tooltip>
  );
};

BleLowBatteryIcon.propTypes = {
  tooltipDirection: PropTypes.string,
};
BleLowBatteryIcon.defaultProps = {
  tooltipDirection: "right"
};

export default BleLowBatteryIcon;