import React from 'react';
import PropTypes from 'prop-types';

import { CACHE_PRESETS, useLineItemTypes } from '@equipmentshare/admin-hooks';

import Table from '@components/table';

/** @deprecated use LineItemTypeName instead */
export const LineItemTypeNameCell = ({ lineItemType, lineItemTypeId, ...props }) => {
	const { data: lineItemTypes } = useLineItemTypes({}, CACHE_PRESETS.STATIC_PICKER);

	const lineItemTypeName = (lineItemType ?? lineItemTypes?.find((lit) => lit.lineItemTypeId === lineItemTypeId))?.name;

	return <Table.Cell {...props}>{lineItemTypeName}</Table.Cell>;
};

LineItemTypeNameCell.propTypes = {
	lineItemType: PropTypes.shape({
		name: PropTypes.string,
	}),
	lineItemTypeId: PropTypes.number,
};

export default LineItemTypeNameCell;
