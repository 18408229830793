import {
	getPriceByType,
	renderAddedByCellDatum,
	renderCurrencyCellDatum,
	renderDateCellDatum,
	renderLocationCellDatum,
} from '../rate.helpers';

const getAddedBy = (rate) => {
	if (rate?.created_by_user) {
		return renderAddedByCellDatum(rate);
	}
	return null;
};

const getCustomer = (rate, rateType) => {
	if (rateType === 'company') {
		return rate?.company?.name || null;
	} else if (rateType === 'location') {
		return rate?.location?.company?.name || null;
	}
	return null;
};

const getDateAdded = (rate) => {
	if (rate?.date_created) {
		return renderDateCellDatum(rate.date_created);
	}
	return null;
};

const getDateVoided = (rate) => {
	if (rate?.date_voided) {
		return rate.date_voided;
	} else if (rate?.end_date) {
		return rate.end_date;
	}
	return null;
};

const getLocation = (rate) => {
	return renderLocationCellDatum(rate);
};

const getRentalRateId = (rate, rateType) => {
	switch (rateType) {
		case 'company':
			return rate.company_rental_rate_id;
		case 'location':
			return rate.location_rental_rate_id;
		case 'branch':
			return rate.branch_rental_rate_id;
		default:
			return null;
	}
};

const getVoidStatus = (rate) => {
	if (rate?.voided) {
		return rate?.voided;
	} else if ('active' in rate) {
		return !rate.active;
	}
	return null;
};

const generateBranchRowData = (rateData) => {
	const priceCollection = { Book: null, Benchmark: null, Floor: null };
	const _rateData = rateData.reduce((acc, curr) => {
		const rate = {
			_eqId: curr.equipment_class.equipment_class_id,
			_marketId: curr.branch.market_id,
			_rentalRateId: curr.branch_rental_rate_id,
			_class: curr.equipment_class.name || '--',
			_category: curr.equipment_class.category.name || '--',
			_branch: curr.branch.name || '--',
			rentalRateIds: [curr.branch_rental_rate_id],
			rateTypeMeta: curr.rate_type.name,
			prices: {
				...priceCollection,
				[curr.rate_type.name]: {
					_pricePerHour: renderCurrencyCellDatum(curr.price_per_hour),
					_pricePerDay: renderCurrencyCellDatum(curr.price_per_day),
					_pricePerWeek: renderCurrencyCellDatum(curr.price_per_week),
					_pricePerMonth: renderCurrencyCellDatum(curr.price_per_month),
				},
			},
		};

		acc.push(rate);

		return acc;
	}, []);

	const rateRows = {};
	_rateData.forEach((curr) => {
		const rowKey = `eqId_${curr._eqId}::marketId_${curr._marketId}`;
		const rateType = curr.rateTypeMeta;
		delete curr.rateTypeMeta;
		if (!rateRows[rowKey]) {
			rateRows[rowKey] = curr;
		} else if (rateRows[rowKey]._eqId === curr._eqId && rateRows[rowKey]._marketId === curr._marketId) {
			rateRows[rowKey].prices[rateType] = { ...curr.prices[rateType] };
			rateRows[rowKey].rentalRateIds.push(curr._rentalRateId);
		}
	});

	return Object.values(rateRows);
};

export const setRateProps = (rateData, rateType) => {
	if (rateType === 'branch') {
		return generateBranchRowData(rateData);
	}
	const _rates = rateData.map((rate) => {
		return {
			// COMMON
			_rentalRateId: getRentalRateId(rate, rateType),
			_class: rate?.equipment_class?.name || null,
			_pricePerHour: getPriceByType(rate, 'price_per_hour'),
			_pricePerHourSort: rate?.price_per_hour || null,
			_pricePerDay: getPriceByType(rate, 'price_per_day'),
			_pricePerDaySort: rate?.price_per_day || null,
			_pricePerWeek: getPriceByType(rate, 'price_per_week'),
			_pricePerWeekSort: rate?.price_per_week || null,
			_pricePerMonth: getPriceByType(rate, 'price_per_month'),
			_pricePerMonthSort: rate?.price_per_month || null,
			// CUSTOMER / LOCATION
			_customer: getCustomer(rate, rateType),
			_dateAdded: getDateAdded(rate),
			_dateAddedSort: rate?.date_created || null,
			_addedBy: getAddedBy(rate),
			_expires: getDateVoided(rate),
			// Additional void/expire props needed for export
			_voided: getVoidStatus(rate),
			date_voided: rate?.date_voided || null,
			voided_by_user: rate?.voided_by_user || null,
			end_date: rate?.end_date || null,
			// LOCATION
			_location: getLocation(rate),
		};
	});

	return _rates;
};
