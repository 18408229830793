import React from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {
			retry: false,
		},
	},
});

const ReactQueryProvider = ({ children }) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
ReactQueryProvider.propTypes = {
	children: PropTypes.node,
};

export default ReactQueryProvider;
