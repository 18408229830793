import ngModule from '../../ng/index.js';

ngModule.directive('bgImage', (esApiFactoryV2) => {
	return {
		link(scope, element, attr) {
			attr.$observe('bgImage', () => {
				const asset = {};
				if (attr.vehicleId) {
					asset.vehicle_id = attr.vehicleId;
				}
				if (attr.equipmentId) {
					asset.equipment_id = attr.equipmentId;
				}

				if (!attr.bgImage) {
					element.css('background-image', `url( ${esApiFactoryV2.getPhotoUrl(asset)} )`);
				} else {
					const image = new Image();
					image.src = attr.bgImage;
					image.onload = function () {
						element.css('background-image', `url( ${attr.bgImage} )`);
					};
					image.onerror = function () {
						element.css('background-image', `url( ${esApiFactoryV2.getPhotoUrl(asset)} )`);
					};
				}
			});
		},
	};
});
