import moment from "moment";

function friendlyDate() {
  return (date) => {
    if(!date) {
      return "...";
    }

    date = moment(date);

    let timeOfday = date.format('h:mm A');
    let dayOfYear = date.format('dddd Do MMMM');
    if(date.isSame(moment().subtract(1,'day'), 'day')) {
      return `${timeOfday} yesterday`;
    }
    if(date.isSame(moment(), 'day')) {
      return `${timeOfday} today`;
    }
    if(date.isSame(moment().add(1, 'day'), 'day')) {
      return `${timeOfday} tomorrow`;
    }
    return `${timeOfday} ${dayOfYear}`
  };
}

angular.module('esApiClient')
  .filter('rentalEquipmentTitle', function() {
    return function(rental) {
      if(!rental.equipment && rental.equipment_class) {
        return rental.equipment_class.name;
      } else if(rental.equipment) {
        return 'ES#' + rental.equipment.equipment_id + ' ' +
          (rental.equipment.make && rental.equipment.make.equipment_make_id != '1' ?
          rental.equipment.make.name + ' ' + rental.equipment.model :
            rental.equipment.name);
      } else {
        return "Unknown Equipment [" + rental.rental_id + "]";
      }
    };
  })
  .filter('friendlyDate', friendlyDate)
  .filter('deliveryDateFormat', friendlyDate);
