import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiToolTip } from '@equipmentshare/ds2';
import { AddFillIcon } from '@equipmentshare/ds2-icons';

import styles from './filter-actions.module.css';

import { useFilterDispatch } from '../../../providers/filter-provider';

const FilterActions = ({ applyDisabled = false, onClearFilters }) => {
	const { dispatcher } = useFilterDispatch();

	const handleAddFilter = () => {
		dispatcher.insertFilterRow();
	};

	const RenderApplyButton = () => (
		<EuiButton fill size="s" type="submit" isDisabled={applyDisabled}>
			Apply filters
		</EuiButton>
	);

	const ApplyButton = () => (
		<Fragment>
			{applyDisabled ? (
				<EuiToolTip position="left" content="At least one filter must be selected">
					<RenderApplyButton />
				</EuiToolTip>
			) : (
				<RenderApplyButton />
			)}
		</Fragment>
	);

	return (
		<div className={styles.layout}>
			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<EuiButton size="s" onClick={handleAddFilter} iconType={AddFillIcon}>
						Add filter
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>

			<EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
				<EuiFlexItem grow={false}>
					<EuiButton color="text" size="s" onClick={onClearFilters}>
						Clear
					</EuiButton>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<ApplyButton />
				</EuiFlexItem>
			</EuiFlexGroup>
		</div>
	);
};

FilterActions.propTypes = {
	applyDisabled: PropTypes.bool,
	onClearFilters: PropTypes.func.isRequired,
};

export default FilterActions;
