import esApi from './es-api.service';

export async function create(payload) {
	const location = await esApi.request(
		'/admin/locations',
		{},
		{
			method: 'POST',
			body: payload,
		}
	);

	return location;
}

export async function fetch(locationId) {
	return await esApi.request(esApi.url`/admin/locations/${locationId}`);
}

export async function search(query = {}, opts) {
	return await esApi.request('/admin/locations', query, opts);
}

export async function update(locationId, payload) {
	return await esApi.request(
		esApi.url`/admin/locations/${locationId}`,
		{},
		{
			method: 'PATCH',
			body: payload,
		}
	);
}

function hasLatLng(location) {
	return !!location && Number.isFinite(location.latitude) && Number.isFinite(location.longitude);
}

const locationsService = {
	create,
	fetch,
	search,
	update,
	hasLatLng,
};

export default locationsService;
