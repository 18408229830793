import { library } from "@fortawesome/fontawesome-svg-core";

// explicit imports are used to decrease the final bundle size; please do not import
// any of the index files for an icon set, ie @fortawesome/pro-light-svg-icons/index.es.js

import { faAngleDown as falAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown.js";
import { faAngleLeft as falAngleLeft } from "@fortawesome/pro-light-svg-icons/faAngleLeft.js";
import { faAngleRight as falAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight.js";
import { faAngleUp as falAngleUp } from "@fortawesome/pro-light-svg-icons/faAngleUp.js";
import { faBuilding as falBuilding } from "@fortawesome/pro-light-svg-icons/faBuilding.js";
import { faCamera as falCamera } from "@fortawesome/pro-light-svg-icons/faCamera.js";
import { faCarBattery as falCarBattery } from "@fortawesome/pro-light-svg-icons/faCarBattery.js";
import { faCheckCircle as falCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle.js";
import { faCircle as falCircle } from "@fortawesome/pro-light-svg-icons/faCircle.js";
import { faClipboardListCheck as falClipboardListCheck } from "@fortawesome/pro-light-svg-icons/faClipboardListCheck.js";
import { faClock as falClock } from "@fortawesome/pro-light-svg-icons/faClock.js";
import { faCog as falCog } from "@fortawesome/pro-light-svg-icons/faCog.js";
import { faCogs as falCogs } from "@fortawesome/pro-light-svg-icons/faCogs.js";
import { faCopy as falCopy } from "@fortawesome/pro-light-svg-icons/faCopy.js";
import { faDownload as falDownload } from "@fortawesome/pro-light-svg-icons/faDownload.js";
import { faEnvelope as falEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope.js";
import { faExclamationCircle as falExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle.js";
import { faExclamationTriangle as falExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle.js";
import { faExternalLink as falExternalLink } from "@fortawesome/pro-light-svg-icons/faExternalLink.js";
import { faFileChartPie as falFileChartPie } from "@fortawesome/pro-light-svg-icons/faFileChartPie.js";
import { faFileInvoice as falFileInvoice } from "@fortawesome/pro-light-svg-icons/faFileInvoice.js";
import { faFlag as falFlag } from "@fortawesome/pro-light-svg-icons/faFlag.js";
import { faGasPump as falGasPump } from "@fortawesome/pro-light-svg-icons/faGasPump.js";
import { faGlobe as falGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe.js";
import { faHeartRate as falHeartRate } from "@fortawesome/pro-light-svg-icons/faHeartRate.js";
import { faInfoCircle as falInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle.js";
import { faKey as falKey } from "@fortawesome/pro-light-svg-icons/faKey.js";
import { faListUl as falListUl } from "@fortawesome/pro-light-svg-icons/faListUl.js";
import { faLocation as falLocation } from "@fortawesome/pro-light-svg-icons/faLocation.js";
import { faMap as falMap } from "@fortawesome/pro-light-svg-icons/faMap.js";
import { faMapMarker as falMapMarker } from "@fortawesome/pro-light-svg-icons/faMapMarker.js";
import { faMapMarkerPlus as falMapMarkerPlus } from "@fortawesome/pro-light-svg-icons/faMapMarkerPlus.js";
import { faMapMarkedAlt as falMapMarkedAlt } from "@fortawesome/pro-light-svg-icons/faMapMarkedAlt.js";
import { faOilTemp as falOilTemp } from "@fortawesome/pro-light-svg-icons/faOilTemp.js";
import { faPhoneSquare as falPhoneSquare } from "@fortawesome/pro-light-svg-icons/faPhoneSquare.js";
import { faQrcode as falQrcode } from "@fortawesome/pro-light-svg-icons/faQrcode.js";
import { faQuestionCircle as falQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle.js";
import { faSave as falSave } from "@fortawesome/pro-light-svg-icons/faSave.js";
import { faSignal as falSignal } from "@fortawesome/pro-light-svg-icons/faSignal.js";
import { faSignOutAlt as falSignOutAlt } from "@fortawesome/pro-light-svg-icons/faSignOutAlt.js";
import { faSlidersH as falSlidersH } from "@fortawesome/pro-light-svg-icons/faSlidersH.js";
import { faSpinnerThird as falSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird.js";
import { faTachometer as falTachometer } from "@fortawesome/pro-light-svg-icons/faTachometer.js";
import { faTachometerAltAverage as falTachometerAltAverage } from "@fortawesome/pro-light-svg-icons/faTachometerAltAverage.js";
import { faTachometerAltFastest as falTachometerAltFastest } from "@fortawesome/pro-light-svg-icons/faTachometerAltFastest.js";
import { faTag as falTag } from "@fortawesome/pro-light-svg-icons/faTag.js";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons/faTimes.js";
import { faToggleOff as falToggleOff } from "@fortawesome/pro-light-svg-icons/faToggleOff.js";
import { faToggleOn as falToggleOn } from "@fortawesome/pro-light-svg-icons/faToggleOn.js";
import { faToolbox as falToolbox } from "@fortawesome/pro-light-svg-icons/faToolbox.js";
import { faTools as falTools } from "@fortawesome/pro-light-svg-icons/faTools.js";
import { faTruck as falTruck } from "@fortawesome/pro-light-svg-icons/faTruck.js";
import { faUserCircle as falUserCircle } from "@fortawesome/pro-light-svg-icons/faUserCircle.js";
import { faUserClock as falUserClock } from "@fortawesome/pro-light-svg-icons/faUserClock.js";
import { faUserHardHat as falUserHardHat } from "@fortawesome/pro-light-svg-icons/faUserHardHat.js";
import { faUsersCog as falUsersCog } from "@fortawesome/pro-light-svg-icons/faUsersCog.js";
import { faVectorSquare as falVectorSquare } from "@fortawesome/pro-light-svg-icons/faVectorSquare.js";
import { faWarehouseAlt as falWarehouseAlt } from "@fortawesome/pro-light-svg-icons/faWarehouseAlt.js";
import { faWindowMinimize as falWindowMinimize } from "@fortawesome/pro-light-svg-icons/faWindowMinimize.js";
import { faWrench as falWrench } from "@fortawesome/pro-light-svg-icons/faWrench.js";

import { faArrowLeft as farArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft.js";
import { faArrowRight as farArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight.js";
import { faBatteryFull as farBatteryFull } from "@fortawesome/pro-regular-svg-icons/faBatteryFull.js";
import { faBatteryQuarter as farBatteryQuarter } from "@fortawesome/pro-regular-svg-icons/faBatteryQuarter.js";
import { faBatterySlash as farBatterySlash } from "@fortawesome/pro-regular-svg-icons/faBatterySlash.js";
import { faCalendarAlt as farCalendarAlt } from "@fortawesome/pro-regular-svg-icons/faCalendarAlt.js";
import { faCommentAltSlash as farCommentAltSlash } from "@fortawesome/pro-regular-svg-icons/faCommentAltSlash.js";
import { faCommentAltLines as farCommentAltLines } from "@fortawesome/pro-regular-svg-icons/faCommentAltLines.js";
import { faCircleNotch as farCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch.js";
import { faClock as farClock } from "@fortawesome/pro-regular-svg-icons/faClock.js";
import { faDownload as farDownload } from "@fortawesome/pro-regular-svg-icons/faDownload.js";
import { faEllipsisH as farEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH.js";
import { faExchangeAlt as farExchangeAlt } from "@fortawesome/pro-regular-svg-icons/faExchangeAlt.js";
import { faExclamationCircle as farExclamationCircle } from "@fortawesome/pro-regular-svg-icons/faExclamationCircle.js";
import { faFileUpload as farFileUpload } from "@fortawesome/pro-regular-svg-icons/faFileUpload.js";
import { faListUl as farListUl } from "@fortawesome/pro-regular-svg-icons/faListUl.js";
import { faLongArrowAltRight as farLongArrowAltRight } from "@fortawesome/pro-regular-svg-icons/faLongArrowAltRight.js";
import { faLock as farLock } from "@fortawesome/pro-regular-svg-icons/faLock.js";
import { faMap as farMap } from "@fortawesome/pro-regular-svg-icons/faMap.js";
import { faPencilAlt as farPencilAlt } from "@fortawesome/pro-regular-svg-icons/faPencilAlt.js";
import { faRedoAlt as farRedoAlt } from "@fortawesome/pro-regular-svg-icons/faRedoAlt.js";
import { faSearch as farSearch } from "@fortawesome/pro-regular-svg-icons/faSearch.js";
import { faSignal as farSignal } from "@fortawesome/pro-regular-svg-icons/faSignal.js";
import { faSignalSlash as farSignalSlash } from "@fortawesome/pro-regular-svg-icons/faSignalSlash.js";
import { faSignal3 as farSignal3 } from "@fortawesome/pro-regular-svg-icons/faSignal3.js";
import { faSlidersH as farSlidersH } from "@fortawesome/pro-regular-svg-icons/faSlidersH.js";
import { faTimes as farTimes } from "@fortawesome/pro-regular-svg-icons/faTimes.js";
import { faTrashAlt as farTrashAlt } from "@fortawesome/pro-regular-svg-icons/faTrashAlt.js";
import { faUndoAlt as farUndoAlt } from "@fortawesome/pro-regular-svg-icons/faUndoAlt.js";
import { faUnlock as farUnlock } from "@fortawesome/pro-regular-svg-icons/faUnlock.js";
import { faUser as farUser } from "@fortawesome/pro-regular-svg-icons/faUser.js";
import { faUserCog as farUserCog } from "@fortawesome/pro-regular-svg-icons/faUserCog";

import { faArchive as fasArchive } from "@fortawesome/pro-solid-svg-icons/faArchive.js";
import { faArrowAltToBottom as fasArrowAltToBottom } from "@fortawesome/pro-solid-svg-icons/faArrowAltToBottom.js";
import { faBan as fasBan } from "@fortawesome/pro-solid-svg-icons/faBan.js";
import { faBars as fasBars } from "@fortawesome/pro-solid-svg-icons/faBars.js";
import { faCar as fasCar } from "@fortawesome/pro-solid-svg-icons/faCar.js";
import { faCaretDown as fasCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown.js";
import { faCaretRight as fasCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight.js";
import { faCaretUp as fasCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp.js";
import { faCheck as fasCheck } from "@fortawesome/pro-solid-svg-icons/faCheck.js";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle.js";
import { faCheckSquare as fasCheckSquare } from "@fortawesome/pro-solid-svg-icons/faCheckSquare.js";
import { faChevronCircleLeft as fasChevronCircleLeft } from "@fortawesome/pro-solid-svg-icons/faChevronCircleLeft.js";
import { faChevronCircleRight as fasChevronCircleRight } from "@fortawesome/pro-solid-svg-icons/faChevronCircleRight.js";
import { faChevronDown as fasChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown.js";
import { faChevronLeft as fasChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft.js";
import { faChevronRight as fasChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight.js";
import { faChevronUp as fasChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp.js";
import { faCircle as fasCircle } from "@fortawesome/pro-solid-svg-icons/faCircle.js";
import { faCog as fasCog } from "@fortawesome/pro-solid-svg-icons/faCog.js";
import { faCogs as fasCogs } from "@fortawesome/pro-solid-svg-icons/faCogs.js";
import { faExclamationTriangle as fasExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle.js";
import { faFile as fasFile } from "@fortawesome/pro-solid-svg-icons/faFile.js";
import { faFileArchive as fasFileArchive } from "@fortawesome/pro-solid-svg-icons/faFileArchive";
import { faFileChartLine as fasFileChartLine } from "@fortawesome/pro-solid-svg-icons/faFileChartLine.js";
import { faFileExcel as fasFileExcel } from "@fortawesome/pro-solid-svg-icons/faFileExcel.js";
import { faFileImage as fasFileImage } from "@fortawesome/pro-solid-svg-icons/faFileImage";
import { faFileInvoiceDollar as fasFileInvoiceDollar } from "@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar.js";
import { faFilePdf as fasFilePdf } from "@fortawesome/pro-solid-svg-icons/faFilePdf.js";
import { faFilePlus as fasFilePlus } from "@fortawesome/pro-solid-svg-icons/faFilePlus.js";
import { faFileWord as fasFileWord } from "@fortawesome/pro-solid-svg-icons/faFileWord.js";
import { faForklift as fasForklift } from "@fortawesome/pro-solid-svg-icons/faForklift.js";
import { faHeadset as fasHeadset } from "@fortawesome/pro-solid-svg-icons/faHeadset.js";
import { faHome as fasHome } from "@fortawesome/pro-solid-svg-icons/faHome.js";
import { faInfoCircle as fasInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle.js";
import { faList as fasList } from "@fortawesome/pro-solid-svg-icons/faList.js";
import { faListUl as fasListUl } from "@fortawesome/pro-solid-svg-icons/faListUl.js";
import { faMap as fasMap } from "@fortawesome/pro-solid-svg-icons/faMap.js";
import { faMapMarkedAlt as fasMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkedAlt.js";
import { faMinus as fasMinus } from "@fortawesome/pro-solid-svg-icons/faMinus.js";
import { faMinusCircle as fasMinusCircle } from "@fortawesome/pro-solid-svg-icons/faMinusCircle.js";
import { faPaperclip as fasPaperclip } from "@fortawesome/pro-solid-svg-icons/faPaperclip.js";
import { faPause as fasPause } from "@fortawesome/pro-solid-svg-icons/faPause.js";
import { faPlay as fasPlay } from "@fortawesome/pro-solid-svg-icons/faPlay.js";
import { faPlus as fasPlus } from "@fortawesome/pro-solid-svg-icons/faPlus.js";
import { faPrint as fasPrint } from "@fortawesome/pro-solid-svg-icons/faPrint.js";
import { faSignOut as fasSignOut } from "@fortawesome/pro-solid-svg-icons/faSignOut.js";
import { faSlidersH as fasSlidersH } from "@fortawesome/pro-solid-svg-icons/faSlidersH.js";
import { faSort as fasSort } from "@fortawesome/pro-solid-svg-icons/faSort.js";
import { faSortDown as fasSortDown } from "@fortawesome/pro-solid-svg-icons/faSortDown.js";
import { faSortUp as fasSortUp } from "@fortawesome/pro-solid-svg-icons/faSortUp.js";
import { faSpinner as fasSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner.js";
import { faSquare as fasSquare } from "@fortawesome/pro-solid-svg-icons/faSquare.js";
import { faSyncAlt as fasSyncAlt } from "@fortawesome/pro-solid-svg-icons/faSyncAlt.js";
import { faTrash as fasTrash } from "@fortawesome/pro-solid-svg-icons/faTrash.js";
import { faUserCheck as fasUserCheck } from "@fortawesome/pro-solid-svg-icons/faUserCheck.js";
import { faUserCircle as fasUserCircle } from "@fortawesome/pro-solid-svg-icons/faUserCircle.js";
import { faUsers as fasUsers } from "@fortawesome/pro-solid-svg-icons/faUsers.js";
import { faWrench as fasWrench } from "@fortawesome/pro-solid-svg-icons/faWrench.js";

export let iconList = {
  falAngleDown,
  falAngleLeft,
  falAngleRight,
  falAngleUp,
  falBuilding,
  falCamera,
  falCarBattery,
  falCheckCircle,
  falCircle,
  falClipboardListCheck,
  falClock,
  falCog,
  falCogs,
  falCopy,
  falDownload,
  falEnvelope,
  falExclamationCircle,
  falExclamationTriangle,
  falExternalLink,
  falFileChartPie,
  falFileInvoice,
  falFlag,
  falGasPump,
  falGlobe,
  falHeartRate,
  falInfoCircle,
  falKey,
  falListUl,
  falLocation,
  falMap,
  falMapMarkedAlt,
  falMapMarker,
  falMapMarkerPlus,
  falOilTemp,
  falPhoneSquare,
  falQrcode,
  falQuestionCircle,
  falSave,
  falSignal,
  falSignOutAlt,
  falSlidersH,
  falSpinnerThird,
  falTachometer,
  falTachometerAltAverage,
  falTachometerAltFastest,
  falTag,
  falTimes,
  falToggleOff,
  falToggleOn,
  falToolbox,
  falTools,
  falTruck,
  falUserCircle,
  falUserClock,
  falUserHardHat,
  falUsersCog,
  falVectorSquare,
  falWarehouseAlt,
  falWindowMinimize,
  falWrench,
  farArrowLeft,
  farArrowRight,
  farBatteryFull,
  farBatteryQuarter,
  farBatterySlash,
  farCalendarAlt,
  farCircleNotch,
  farClock,
  farCommentAltLines,
  farCommentAltSlash,
  farDownload,
  farEllipsisH,
  farExchangeAlt,
  farExclamationCircle,
  farFileUpload,
  farListUl,
  farLock,
  farLongArrowAltRight,
  farMap,
  farPencilAlt,
  farRedoAlt,
  farSearch,
  farSignal,
  farSignal3,
  farSignalSlash,
  farSlidersH,
  farTimes,
  farTrashAlt,
  farUndoAlt,
  farUnlock,
  farUser,
  farUserCog,
  fasArchive,
  fasArrowAltToBottom,
  fasBan,
  fasBars,
  fasCar,
  fasCaretDown,
  fasCaretRight,
  fasCaretUp,
  fasCheck,
  fasCheckCircle,
  fasCheckSquare,
  fasChevronCircleLeft,
  fasChevronCircleRight,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasCircle,
  fasCog,
  fasCogs,
  fasExclamationTriangle,
  fasFile,
  fasFileArchive,
  fasFileChartLine,
  fasFileExcel,
  fasFileImage,
  fasFileInvoiceDollar,
  fasFilePdf,
  fasFilePlus,
  fasFileWord,
  fasForklift,
  fasHeadset,
  fasHome,
  fasInfoCircle,
  fasList,
  fasListUl,
  fasMap,
  fasMapMarkedAlt,
  fasMinus,
  fasMinusCircle,
  fasPaperclip,
  fasPause,
  fasPlay,
  fasPlus,
  fasPrint,
  fasSignOut,
  fasSlidersH,
  fasSort,
  fasSortDown,
  fasSortUp,
  fasSpinner,
  fasSquare,
  fasSyncAlt,
  fasTrash,
  fasUserCheck,
  fasUserCircle,
  fasUsers,
  fasWrench,
};

library.add( Object.values( iconList ) );
