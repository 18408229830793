import esApi from './es-api.service';
import { inventoryApolloClient } from '@services/graphql/inventory-apollo-client';
import partsQuery from '@services/graphql/inventory/queries/parts.js';
import _compact from 'lodash/compact';
import _get from 'lodash/get';

export function getPartLabel(part, fallback = '--') {
	const description = _get(part, 'partClass.description') || null;

	if (!description) {
		return fallback;
	}
	return description;
}

export async function fetchParts(search) {
	return await esApi.request(esApi.url`/billing/parts?search=${search || ''}`);
}

export async function fetchPartsById(idList = [], queryOptions = { fetchPolicy: 'network-only' }) {
	const { data } = await inventoryApolloClient.query({
		...queryOptions,
		query: partsQuery,
		variables: { partIds: _compact(idList) },
	});

	return data.parts;
}

export function getPartDescription(part, fallback = '--') {
	if (!part) {
		return fallback;
	}

	return [part.partNumber, part.provider.name, part.partClass.description].join(' - ');
}

const partsService = {
	fetchParts,
	fetchPartsById,
	getPartLabel,
	getPartDescription,
};

export default partsService;
