import React from 'react';
import PropTypes from 'prop-types';
import ErrorFallback from './error-fallback';
import analyticsService from '@services/analytics.service';

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed';
const retryPageLoading = () => {
	const pageHasAlreadyBeenForceRefreshed = JSON.parse(
		window.sessionStorage.getItem(PageHasBeenForceRefreshed) || 'false'
	);
	if (!pageHasAlreadyBeenForceRefreshed) {
		window.sessionStorage.setItem(PageHasBeenForceRefreshed, 'true');
		window.location.reload();
	}
};

class ErrorBoundary extends React.PureComponent {
	constructor() {
		super();

		this.state = {
			error: null,
			errorInfo: null,
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error, errorInfo });

		const { onError, ..._props } = this.props;

		if (error.message.includes('ChunkLoadError')) {
			retryPageLoading();
		}

		if (typeof onError === 'function') {
			try {
				onError(error, errorInfo, _props);
			} catch (e) {}
		}

		analyticsService.error(error.message, error);
	}

	render() {
		const { fallbackComponent: FallbackComponent, children } = this.props;

		if (this.state.error) {
			const { error, errorInfo } = this.state;

			return <FallbackComponent error={error} errorInfo={errorInfo} />;
		}

		return children;
	}
}
ErrorBoundary.propTypes = {
	children: PropTypes.node,
	onError: PropTypes.func,
	fallbackComponent: PropTypes.func,
};
ErrorBoundary.defaultProps = {
	fallbackComponent: ErrorFallback,
};

export default ErrorBoundary;
