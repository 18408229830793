import React, { useContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

const Context = React.createContext();

export function useColumns() {
	return useContext(Context);
}

const ColumnProvider = ({ children }) => {
	const [columnIds, setColumnIds] = useState([]);
	const [columnWidth, setColumnWidth] = useState([]);

	const register = useCallback(
		(columnId) => {
			setColumnIds((ids) => {
				return !ids.includes(columnId) ? ids.concat(columnId) : ids;
			});
		},
		[setColumnIds]
	);
	const unregister = useCallback(
		(columnId) => {
			setColumnIds((ids) => {
				return ids.includes(columnId) ? ids.filter((id) => id !== columnId) : ids;
			});
		},
		[setColumnIds]
	);

	const setWidth = useCallback(
		(columnId, width) => {
			setColumnWidth((state) => ({ ...state, [columnId]: width }));
		},
		[setColumnWidth]
	);
	const getWidth = useCallback((columnId) => columnWidth[columnId], [columnWidth]);

	const context = useMemo(
		() => ({
			register,
			unregister,
			columnIds,
			setWidth,
			getWidth,
		}),
		[register, unregister, columnIds]
	);

	return <Context.Provider value={context}>{children}</Context.Provider>;
};

ColumnProvider.propTypes = {
	children: PropTypes.node,
};

ColumnProvider.Context = Context;

export default ColumnProvider;
