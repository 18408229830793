import ngModule from '../../../ng/index.js';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pickBy from 'lodash/pickBy';
import moment from 'moment-timezone';
import toastService from '@services/toast.service.js';
import { createErrorHandler } from '../../../state/helpers.js';

import moveDialogTemplate from './rental-move-dialog.html';
import swapDialogTemplate from './rental-swap-dialog.html';
import { fetchWorkOrders } from '@services/work-orders.service';
import { ESTRACK_DOMAIN } from '@const/env';
import rentalsService from '@services/rentals.service';
import locationsService from '@services/locations.service.js';
import { ES_COMPANY_ID } from '@const/es';

function RentalEditFormCtrl(
	$scope,
	$mdDialog,
	$timeout,
	$q,
	esCanonicalizer,
	esApiFactoryV2,
	esAdminRentalService,
	esAdminInvoiceService,
	esRentalService,
	esRentalActivityService,
	esDeliveriesService,
	esRentalOverdueTasksService,
	esCompanyService
) {
	const controller = this;
	const rentalCanonicalizer = esCanonicalizer.canonicalizer('rental');

	controller.lastUpdatePayload = {};
	$scope.submitting = false;
	$scope.isCompanyTaxable = true;
	$scope.model = {};
	$scope.workOrders = null;
	$scope.esTrackWorkOrdersUrl = `${ESTRACK_DOMAIN}#/service/work-orders`;
	$scope.onSave = controller.onSave;

	controller.$onInit = () => {
		$scope.rentalStatuses = esAdminRentalService.rentalStatuses;
		$scope.preOnRentStatuses = esAdminRentalService.preOnRentStatuses;
	};

	controller.$onChanges = (changes) => {
		if (changes.rental) {
			const newRental = changes.rental.currentValue;

			esAdminRentalService
				.getRental(newRental.rental_id || newRental)
				.then(rentalCanonicalizer)
				.then((rental) => {
					$scope.rental = rental;
					$scope.updateModelFromRental();

					esCompanyService.isCompanyTaxable(rental.order.user.company.company_id).then((isTaxable) => {
						$scope.isCompanyTaxable = isTaxable;
					});
				})
				.then(() => {
					const assetId = _get($scope, 'rental.equipment.asset_id');
					if (assetId) {
						controller.fetchAssetOpenWorkOrders($scope.rental.equipment.asset_id);
					}
				})
				.then(() => {
					esAdminInvoiceService.getInvoices({ order_id: $scope.rental.order_id }).then((invoices) => {
						$scope.rentalHasInvoices =
							invoices.filter((invoice) =>
								invoice.line_items.find((line_item) => line_item.rental_id === $scope.rental.rental_id)
							).length > 0;
					});
				});
		}
	};

	controller.fetchAssetOpenWorkOrders = (assetId) => {
		const WORK_ORDER_STATUS_OPEN = 1;
		const query = {
			asset_id: assetId,
			work_order_status_id: WORK_ORDER_STATUS_OPEN,
		};

		const workOrdersPromise = fetchWorkOrders(query);

		workOrdersPromise.then((workOrders) => {
			$scope.workOrders = workOrders.length > 0 ? workOrders : null;
			$scope.$digest();
		});
	};

	$scope.showLocationWarning = () => {
		return !locationsService.hasLatLng($scope.model.current_location);
	};
	$scope.handleLocationIdChange = (locationId, location) => {
		$scope.model.current_location = location;
		$scope.$digest();
	};

	$scope.updateRentalObject = (rental) => {
		$scope.rental = rental;
		controller.onSave();
		$scope.$digest();
	};

	$scope.getWorkOrderPrefix = (workOrder) => {
		const workOrderTypePrefixes = {
			1: 'WO',
			2: 'INSP',
		};

		return workOrderTypePrefixes[workOrder.work_order_type_id];
	};

	$scope.$watch('model.end_date', (newValue, oldValue, scope) => {
		// recalculate the date range between start date and end date when end_date changes
		scope.calculateRentalDateRange();
	});

	$scope.$watch('model.start_date', (newValue, oldValue, scope) => {
		// maintain the initial range between start_date and end_date
		// if there were 4 days between start and end date, add 4 days to end date every time
		// start_date is modified.
		if (oldValue && newValue) {
			const datesValid = moment(oldValue).isValid() && moment(newValue).isValid();
			const datesDifferent = !moment(newValue).isSame(oldValue);

			if (datesValid && datesDifferent) {
				const diff = moment(newValue).diff(oldValue, 'minutes');

				scope.model.end_date = moment(scope.model.end_date).add(diff, 'minutes').startOf('minute').toDate();
			}
		}
	});
	// NOTE: the order of watchers is very specific, changing the order could cause a defect
	// reference: https://trello.com/c/i06rUjMl
	$scope.$watch('rental.end_date', (newValue, oldValue, scope) => {
		if (newValue && oldValue && !moment(newValue).isSame(moment(oldValue))) {
			scope.updateModelFromRental();
		}
	});

	$scope.updateModelFromRental = (rental = $scope.rental) => {
		$scope.model.job_description = rental.job_description;
		$scope.model.price_per_day = rental.price_per_day;
		$scope.model.price_per_week = rental.price_per_week;
		$scope.model.price_per_month = rental.price_per_month;
		$scope.model.price_per_hour = rental.price_per_hour;
		$scope.model.start_date = moment(rental.start_date).startOf('minute').toDate();
		$scope.model.end_date = moment(rental.end_date).startOf('minute').toDate();
		$scope.model.equipment_class = rental.equipment_class;
		$scope.model.equipment = rental.equipment;
		$scope.model.current_location = rental.current_location;
		$scope.model.rental_protection_plan_id = rental.rental_protection_plan_id;
		if (rental.off_rent_date_requested) {
			$scope.model.off_rent_date_requested = rental.off_rent_date_requested;
		}

		controller.lastUpdatePayload = controller.createPayload();
		$scope.calculateRentalDateRange();
	};

	$scope.calculateRentalDateRange = () => {
		$scope.dateRange = moment($scope.model.end_date).diff(moment($scope.model.start_date), 'days');
	};

	$scope.editForm = (form) => {
		$scope.updateModelFromRental();
		$scope.selectedForm = form;
	};

	$scope.cancelEdit = () => {
		$scope.selectedForm = undefined;
		$scope.updateModelFromRental();
	};

	$scope.isEditing = (form) => form === $scope.selectedForm;

	$scope.isPartRental = (rental = $scope.rental) => {
		if (!rental) {
			return false;
		}
		return rental.rental_type_id === 3;
	};

	$scope.partRentalPartId = (rental) =>
		rental && rental.part_assignments && rental.part_assignments.length > 0 ? rental.part_assignments[0].part_id : null;

	$scope.isOnRent = (rental = $scope.rental) => {
		if (!rental) {
			return false;
		}

		return rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.ON_RENT_ID;
	};

	$scope.isNeedsApproval = (rental = $scope.rental) => {
		if (!rental) {
			return false;
		}

		// FG: this will need to be updated to NEEDS_APPROVAL_ID
		// once OPEN_ID has been renamed in esAdminService
		return rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.OPEN_ID;
	};

	$scope.isPreOnRent = (rental = $scope.rental) => {
		if (!rental) {
			return false;
		}

		return (
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.OPEN_ID ||
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.DRAFT_ID ||
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.PENDING_ID ||
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.SCHEDULED_ID
		);
	};

	$scope.isPostOffRent = (rental = $scope.rental) => {
		if (!rental) {
			return false;
		}

		return (
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.OFF_RENT_ID ||
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.COMPLETED_ID ||
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.CANCELLED_ID ||
			rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.BILLED_ID
		);
	};

	$scope.showMoveDialog = () => {
		$mdDialog.show({
			controller: 'RentalMoveDialogCtrl',
			template: moveDialogTemplate,
			clickOutsideToClose: false,
			locals: {
				rental: $scope.rental,
				onSave: controller.onSave,
			},
		});
	};

	$scope.showSwapDialog = () => {
		$mdDialog.show({
			controller: 'RentalSwapDialogCtrl',
			template: swapDialogTemplate,
			clickOutsideToClose: false,
			locals: {
				rental: $scope.rental,
				onSave: controller.onSave,
			},
		});
	};

	$scope.isDatesEffectedByDST = () => {
		return moment($scope.model.start_date).isDST() !== moment($scope.model.end_date).isDST();
	};

	$scope.maxDate = moment().add(10, 'year').toDate();

	$scope.maxStartDate;

	$scope.$watch('rentalHasInvoices', () => {
		const allowFutureStartDate = $scope.isPreOnRent() || ($scope.isOnRent() && !$scope.rentalHasInvoiecs);
		$scope.maxStartDate = allowFutureStartDate ? moment().add(10, 'year').toDate() : moment().toDate();
	});

	$scope.getPeriodDurationHumanized = () => {
		return esRentalService.getHumanizedDuration($scope.model.start_date, $scope.model.end_date);
	};

	$scope.submitForm = () => {
		$scope.updateRental();
	};

	$scope.updateRental = () => {
		if ($scope.submitting === true) {
			return;
		}
		const saving = [];

		const payload = controller.createPayload();
		const diff = _pickBy(payload, (value, prop) => value !== controller.lastUpdatePayload[prop]);

		if (
			$scope.rental &&
			_get($scope.model, 'current_location.location_id') !== _get($scope.rental, 'current_location.location_id') &&
			_get($scope.model, 'current_location.location_id') == null &&
			_get($scope.rental, 'drop_off_delivery.delivery_status_id') === esDeliveriesService.deliveryStatusIds.COMPLETED
		) {
			return toastService.showError("You can't remove the location once the dropoff is complete");
		}

		if (!_isEmpty(diff)) {
			// If the rental is on-rent, don't set the assigned equipment through patching, this is handled via the
			// swap equipment code paths instead. This is a band-aid solution, need to think through how to refactor this.
			if ($scope.rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.ON_RENT_ID) {
				delete diff.asset_id;
			}

			if (diff.end_date) {
				// end date changed and there is an original off rent date that needs to be kept in sync
				if ($scope.rental.off_rent_date_requested) {
					diff.off_rent_date_requested = diff.end_date;
				}
			}
			saving.push(esApiFactoryV2.adminPatchRental($scope.rental.rental_id, diff));
		}

		if (_get($scope.model, 'current_location.location_id') !== _get($scope.rental, 'current_location.location_id')) {
			const location = $scope.model.current_location;

			saving.push(
				esAdminRentalService.rentalAssignLocation(
					$scope.rental.rental_id,
					location && location.location_id,
					$scope.rental.start_date
				)
			);
		}

		if (saving.length) {
			$scope.submitting = true;
			$q.all(saving)
				.then(() => {
					controller.onSave();
					toastService.showSuccess('Rental Updated!');
				})
				.catch(createErrorHandler('Failed to update rental'))
				.then(() => esAdminRentalService.getRental($scope.rental.rental_id))
				.then((rental) => {
					$scope.updateModelFromRental(rental);
					controller.rentalChanged();
					$scope.submitting = false;
				});

			controller.lastUpdatePayload = controller.createPayload();
		}

		$scope.selectedForm = undefined;
	};

	controller.rentalChanged = (rental = $scope.rental) => {
		esRentalActivityService.refreshActivitiesForRental(rental.rental_id);
		esDeliveriesService.refreshDeliveriesByRentalId(rental.rental_id);
		esRentalOverdueTasksService.refresh(rental.order.market_id);
	};

	controller.createPayload = () => {
		return {
			start_date: $scope.model.start_date ? $scope.model.start_date.valueOf() : moment().startOf('day').valueOf(),
			end_date: $scope.model.end_date ? $scope.model.end_date.valueOf() : moment().endOf('day').valueOf(),
			equipment_id: $scope.model.equipment ? $scope.model.equipment.asset_id : null,
			job_description: $scope.model.job_description,
		};
	};
}

ngModule.controller('RentalEditFormCtrl', RentalEditFormCtrl);
