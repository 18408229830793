import ngModule from '../../../ng/index.js';

import template from './delivery-picker-dialog/delivery-picker-dialog.html';

function DeliveryMapCtrl($scope, $mdDialog, DeliveryMapService) {
	const controller = this;

	controller.clickMarker = (point) => {
		if (point.delivery) {
			controller.onSelectDelivery(point.delivery);
		} else if (point.deliveries) {
			$mdDialog
				.show({
					controller: 'DeliveryPickerDialogCtrl',
					template,
					clickOutsideToClose: true,
					bindToController: true,
					parent: document.querySelector('.panel.dispatch'),
					locals: {
						deliveries: point.deliveries,
					},
				})
				.then((delivery) => controller.onSelectDelivery(delivery));
		}
	};

	controller.setMapPoints = (points) => {
		if (controller.onSelectDelivery) {
			points.forEach((point) => (point.onClick = controller.clickMarker));
		}

		controller.points = points;
	};

	controller.updateFocusedDeliveries = () => {
		controller.points = controller.points.map((point) => {
			if (controller.focusedDeliveries.length === 0) {
				point.opacity = 1;

				return point;
			}

			const deliveries = point.deliveries || [point.delivery];
			const focusedDelivery = deliveries.find((d) => controller.focusedDeliveries.includes(d));

			if (focusedDelivery) {
				point.opacity = 1;
			} else {
				point.opacity = 0.25;
			}

			return point;
		});
	};

	controller.$onInit = () => {
		let deliveries = [];

		if (controller.delivery) {
			deliveries.push(controller.delivery);
		} else if (controller.deliveries) {
			deliveries = controller.deliveries;
		}

		const points = DeliveryMapService.getMapMarkers(deliveries);

		controller.setMapPoints(points);
		controller.updateFocusedDeliveries();
	};

	controller.$onChanges = (changes) => {
		let deliveries = [];

		if (controller.delivery) {
			deliveries.push(controller.delivery);
		} else if (controller.deliveries) {
			deliveries = controller.deliveries;
		}

		if (changes.deliveries || changes.delivery) {
			const points = DeliveryMapService.getMapMarkers(deliveries);

			controller.setMapPoints(points);
		} else if (changes.focusedDeliveries) {
			controller.updateFocusedDeliveries();
		}
	};
}

ngModule.controller('DeliveryMapCtrl', DeliveryMapCtrl);
