import ngModule from '../../../../ng/index.js';

function EquipmentClassPickerCtrl($scope, esApiFactoryV2, esEquipmentService, $timeout) {
	const controller = this;

	$scope.onChange = () => {
		$timeout(() => {
			controller.onClassChange({
				value: controller.ngModel,
				equipment_class: controller.ngModel,
				equipment_class_id: controller.ngModel ? controller.ngModel.equipment_class_id : null,
			});
		});
	};

	$scope.onFocus = () => {
		$scope.focus = true;
		controller.ngFocus();
	};

	$scope.queryClassNames = (searchText) => {
		return esApiFactoryV2.adminSearchEquipmentClasses(searchText);
	};
}

ngModule.controller('EquipmentClassPickerCtrl', EquipmentClassPickerCtrl);
