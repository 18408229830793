import _get from 'lodash/get';

import { isString } from '@helpers/utilities';
import { stringToCamelCase } from '../js/formatting';

export const ASSET_STATUS_COLOR = {
	onRPO: {
		rgb: '150, 100, 200',
		hex: '#9664c8',
	},
	onRent: {
		rgb: '43, 65, 188',
		hex: '#2b41bc',
	},
	preDelivered: {
		rgb: '47, 193, 64',
		hex: '#2fc140',
	},
	assigned: {
		rgb: '47, 193, 64',
		hex: '#2fc140',
	},
	readyToRent: {
		rgb: '47, 193, 64',
		hex: '#2fc140',
	},
	needsInspection: {
		rgb: '255, 194, 0',
		hex: '#ffc400',
	},
	pendingReturn: {
		rgb: '247, 151, 34',
		hex: '#f79722',
	},
	softDown: {
		rgb: '255, 29, 35',
		hex: '#ff1d25',
	},
	hardDown: {
		rgb: '255, 29, 35',
		hex: '#ff1d25',
	},
	makeReady: {
		rgb: '189, 189, 189',
		hex: '#bdbdbd',
	},
	other: {
		rgb: '189, 189, 189',
		hex: '#bdbdbd',
	},
	nA: {
		rgb: '189, 189, 189',
		hex: '#bdbdbd',
	},
};

export const getAssetStatusColor = (assetStatus: string, colorType = 'rgb', fallback = 'Other') => {
	const status = isString(assetStatus) ? stringToCamelCase(assetStatus) : 'other';

	return _get(ASSET_STATUS_COLOR, `${status}.${colorType}`, fallback) || fallback;
};
