import React from 'react';
import PropTypes from 'prop-types';

import Row from '../row/row.js';
import Cell from '../cell/cell.js';

import { useColumns } from '../providers/column-provider.js';

const EmptyRow = (props) => {
	const { columnIds } = useColumns();

	return (
		<Row {...props}>
			{columnIds.map((colId) => (
				<Cell key={colId} columnId={colId} />
			))}
		</Row>
	);
};

EmptyRow.propTypes = {
	height: PropTypes.any,
};
EmptyRow.defaultProps = {
	height: '2.5em',
};

export default EmptyRow;
