import React from 'react';
import PropTypes from 'prop-types';

import { EuiIcon } from '@equipmentshare/ds2';
import { ArrowUpDownLineIcon, ArrowUpLineIcon, ArrowDownLineIcon } from '@equipmentshare/ds2-icons';

import { SORT_DIRECTIONS } from '../../providers/sort-provider.js';

const SortIcon = ({ sortDirection }) => {
	switch (sortDirection) {
		case SORT_DIRECTIONS.ASC:
			return <EuiIcon type={ArrowUpLineIcon} />;
		case SORT_DIRECTIONS.DESC:
			return <EuiIcon type={ArrowDownLineIcon} />;
		default:
		case SORT_DIRECTIONS.NONE:
			return <EuiIcon type={ArrowUpDownLineIcon} />;
	}
};

SortIcon.propTypes = {
	sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
};

export default SortIcon;
