import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { BranchPicker } from '@equipmentshare/es-billing-components';
import { formatAddress } from '@equipmentshare/admin-hooks';
import { isString } from '@helpers/utilities';
import EquipmentClassPicker from '@components/equipment-class-picker';
import CompanyPickerComplex from '@components/company-picker-complex';
import LocationPicker from '@components/location-picker';

import { useFilterDispatch, useFilterState } from '../../../../providers/filter-provider';
import { filterDefaults, filterTypeIds } from '../../../../providers/filter-group-options';

import styles from './filter-renderer.module.css';

const FilterRenderer = ({ className, filter, filterType, rowIndex }) => {
	const { dispatcher } = useFilterDispatch();
	const { state: filterState } = useFilterState();

	const getPropKey = (prop) => (isString(prop) ? prop : prop.key);

	const getRowItem = (rowName) => {
		return filterState.rows.find((row) => row.type === rowName);
	};

	/**
	 * Returns the selectedIds for for the current filter which are pulled from
	 * the Filter object's `value` attribute.
	 * The `value` attribute contains a key which holds the selected ID.
	 */
	const getFilterValueFromKey = (key) => {
		if (Array.isArray(_get(filter, 'value', null))) {
			const res = filter.value.map((f) => {
				return f[key];
			});
			return res;
		}
		return _get(filter, `value.${key}`, null);
	};

	/**
	 * Generates details of selection and dispatches to updateFilterRow
	 */
	const handleMultiOptionChange = (val, opt) => {
		const detail = opt.reduce((acc, curr) => {
			const filterTypeId = filterTypeIds[filterType].key;
			const filterIdVal = curr[filterTypeId];
			acc.push({ [filterTypeId]: filterIdVal, name: curr.name, _id: `${filterType}_${filterIdVal}` });
			return acc;
		}, []);
		dispatcher.updateFilterRow({ id: rowIndex, type: filterType, value: detail });
	};

	/**
	 * Adds `name` attribute to the selected locations before
	 * before handling the option change
	 */
	const handleLocationOptionChange = (val, opt) => {
		const opts = opt.map((o) => {
			return {
				name: o.nickname,
				...o,
			};
		});
		return handleMultiOptionChange(val, opts);
	};

	/**
	 * Enforce business logic requiring that
	 * only a single company can be selected when multiple locations are selected,
	 * or that a company must be selected to filter by location.
	 *
	 * Generates dynamic props that are passed to components
	 */
	const getLocationDynamicProps = () => {
		const companyPropKey = getPropKey(filterTypeIds.company);
		const companyRowData = getRowItem('company')?.value || [];
		const companyId = companyRowData.length === 1 ? companyRowData[0][companyPropKey] : null;
		let placeholder = defaultProps.placeholder;

		if (companyRowData.length > 1) {
			placeholder = 'Only a single company can be selected to filter by location';
		} else if (!companyId) {
			placeholder = 'A company must be selected to filter by location';
		}

		// the following props will be passed to the current `filter`
		return {
			companyId,
			disabled: !companyId,
			placeholder,
		};
	};

	const locationOptionValueRenderer = (location) => {
		return location.nickname || formatAddress(location, false);
	};

	const defaultProps = filterType
		? {
				...filterDefaults.defaults,
				...filterDefaults[filterType],
				className,
		  }
		: null;

	const renderFilter = () => {
		switch (filterType) {
			case 'branch':
				return (
					<BranchPicker
						{...defaultProps}
						onChange={handleMultiOptionChange}
						selectedBranchId={getFilterValueFromKey(filterTypeIds.branch.key)}
					/>
				);
			case 'equipmentClass':
				return (
					<EquipmentClassPicker
						{...defaultProps}
						onChange={handleMultiOptionChange}
						selectedEquipmentClassId={getFilterValueFromKey(filterTypeIds.equipmentClass.key)}
					/>
				);
			case 'company':
				return (
					<CompanyPickerComplex
						{...defaultProps}
						onChange={handleMultiOptionChange}
						selectedCompanyId={getFilterValueFromKey(filterTypeIds.company.key)}
					/>
				);
			case 'location':
				return (
					<LocationPicker
						{...defaultProps}
						{...getLocationDynamicProps()}
						onChange={handleLocationOptionChange}
						selectedLocationId={getFilterValueFromKey(filterTypeIds.location.key)}
						valueRenderer={locationOptionValueRenderer}
					/>
				);
			default:
				return null;
		}
	};

	return <div className={styles.filterWrapper}>{renderFilter()}</div>;
};

FilterRenderer.propTypes = {
	className: PropTypes.string,
	filter: PropTypes.object,
	filterType: PropTypes.string,
	rowIndex: PropTypes.number.isRequired,
};

export default FilterRenderer;
