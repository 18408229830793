import React from 'react';

import { EuiIconTip, EuiText } from '@equipmentshare/ds2';
import { Stack } from '@equipmentshare/es-admin-components';
import { useFlags } from '@equipmentshare/feature-flags';

import { getStatusInfo, getStatuses } from '@helpers/invoice-status';
import { Invoice } from '@helpers/invoice';

export type InvoiceStatusDisplayProps = {
	className?: string;
	invoice: Invoice.BillingServiceInvoice;
	showText?: boolean;
};

export const InvoiceStatusDisplay: React.VFC<InvoiceStatusDisplayProps> = ({
	className,
	invoice,
	showText = false,
}) => {
	const { enableBillingDisputesAdmin } = useFlags();
	const statuses = getStatuses(invoice, enableBillingDisputesAdmin);

	return (
		<Stack className={className} gap="1rem" alignItems="center" justifyContent="center">
			{statuses.map((status) => {
				const statusInfo = getStatusInfo(status);

				return (
					<Stack key={statusInfo.text.replace(/\s/g, '')} gap="0.2rem" alignItems="end">
						<EuiIconTip
							type={statusInfo.icon}
							color={statusInfo.color.toString()}
							content={statusInfo.text}
							aria-label={statusInfo.text}
							size="l"
						/>
						{showText && <EuiText color={statusInfo.color.toString()}>{statusInfo.text}</EuiText>}
					</Stack>
				);
			})}
		</Stack>
	);
};

export default InvoiceStatusDisplay;
