import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CellMeasurer } from 'react-virtualized';
import { VirtualizedBody } from '../virtualized-body/virtualized-body.js';

export const RowMeasurer = ({ index, parent, style, children, ...props }) => {
	const cache = useContext(VirtualizedBody.MeasurerCache);

	return (
		<CellMeasurer cache={cache} parent={parent} rowIndex={index} columnIndex={0}>
			{({ measure }) => children({ style, measure, ...props })}
		</CellMeasurer>
	);
};

RowMeasurer.propTypes = {
	index: PropTypes.number.isRequired,
	parent: PropTypes.object.isRequired,
	style: PropTypes.object.isRequired,
	children: PropTypes.func.isRequired,
};

export default RowMeasurer;
