import esApi from '@services/es-api.service';

export async function fetch(id) {
	return await esApi.request(esApi.url`/maintenance-groups/${id}`);
}

export async function fetchAll() {
	return await esApi.request(esApi.url`/maintenance-groups`);
}

const maintenanceGroupsService = {
	fetch,
	fetchAll,
};

export default maintenanceGroupsService;
