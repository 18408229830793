angular.module('esApiClient').factory('esGoogleMaps', function ($window, $http, User, $log, $q) {

  let gmapsAPIKey = 'AIzaSyCMAN8KmM0lrKnKYlZiaewOw4WX8BIXnA8';
  
  function getResponseData(httpPromise) {
    var defer = $q.defer();
    httpPromise
      .then(function (response) {
        if (response.data) {
          defer.resolve(response.data);
        } else {
          $log.warn("Have response without .data; Return response");
          defer.resolve(response);
        }
      })
      .catch(function (response) {
        defer.reject(response);
      });
    return defer.promise;
  }

  function getDirections(params) {
    var requestUrl = 'https://maps.googleapis.com/maps/api/directions/json?' + params + '&key=' + gmapsAPIKey;

    return getResponseData($http({method: 'GET', url: requestUrl}));
  }

  return {
    getDirections: getDirections
  }
});
