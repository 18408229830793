import React from 'react';

import { Location } from '@equipmentshare/admin-hooks';

import AddressDisplay from '../../js/components/address-display/address-display';

export type LocationDisplayProps = {
	className?: string;
	location: Location & { nickname?: string };
	showNickname?: boolean;
	inline?: boolean;
};

export const LocationDisplay: React.VFC<LocationDisplayProps> = ({
	className,
	location,
	showNickname = true,
	inline,
	...props
}) => (
	<AddressDisplay
		className={className}
		nickname={showNickname ? location?.nickname : null}
		address={location}
		inline={inline}
		{...props}
	/>
);

export default LocationDisplay;
