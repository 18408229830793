import React from 'react';

import { EuiButtonIcon, EuiButtonIconProps, EuiToolTip } from '@equipmentshare/ds2';
import { ExternalLinkLineIcon } from '@equipmentshare/ds2-icons';

export type OpenInNewTabButtonProps = {
	url: string;
} & Omit<EuiButtonIconProps, 'iconType' | 'onClick'>;

export const OpenInNewTabButton: React.VFC<OpenInNewTabButtonProps> = ({ url, ...props }) => {
	return (
		<EuiToolTip content={'Open in new tab'} delay="long">
			<EuiButtonIcon
				aria-label="Open in new tab"
				iconType={ExternalLinkLineIcon}
				size="xs"
				onClick={() => window.open(url, '_blank')}
				display="empty"
				{...props}
			/>
		</EuiToolTip>
	);
};
