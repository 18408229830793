import Authentication from "@equipmentshare/kernel-legacy/src/Authentication.js";

/**
 * Most of this repurposed from 'cfx.auth' module
 * (which also has support for roles if we ever need that)
 */

angular.module('esAuthentication', ['ngRoute', 'ipCookie', 'ui.router']);

angular.module('esAuthentication').config(function ($httpProvider, $routeProvider) {
  $httpProvider.interceptors.push('authTokenHttpInterceptor');

  var userLoggedIn = function ($q, User) {
    var deferred = $q.defer();

    if (User.hasToken() && User.hasUserId()) {
      deferred.resolve();
    } else {
      deferred.reject({needsAuthentication: true});
    }

    return deferred.promise;
  };

  var userCorrectRole = function ($q, User, $route) {
    var deferred = $q.defer();

    if (User.hasRole($route.current.access.roles))
      deferred.resolve();
    else {
      deferred.reject({needsCorrectRole: true});
    }

    return deferred.promise;
  };

  var originalWhen = $routeProvider.when;
  $routeProvider.when = function (path, route) {
    route.resolve = route.resolve || {};

    if (route && route.access && route.access.anonymous === false)
      angular.extend(route.resolve, {userLoggedIn: userLoggedIn});
    if (route && route.access && route.access.roles)
      angular.extend(route.resolve, {userCorrectRole: userCorrectRole});

    return originalWhen.call($routeProvider, path, route);
  };
});

angular.module('esAuthentication').run(function ($rootScope, User, $location, authRoutePath, authStateName, $state, $log) {
  $rootScope.$on('$stateChangeStart', function (event, to, toParams, from, fromParams) {
      if(to.access && to.access.roles && !User.hasRole(to.access.roles)) {
        $log.warn('User does not have the correct role for this route: ', to.url);
        event.preventDefault();

        // if this is the first state the user loads, then redirect back to login
        if(from.abstract){
          $state.go(authStateName.login);
        }
      }

      if(to.access && to.access.readOnly === false && Authentication.getReadOnly()){
        $log.warn("User does not have the correct modify permissions for this route: ", to.url);
        event.preventDefault();

        if( from.name === "" ) {
          $state.go( authStateName.notAuthorized );
        }
      }

      if(to.access && to.access.anonymous === false && !(User.hasToken() && User.getUserId())) {
        $log.warn('You must be logged in to view this route', to.url);
        event.preventDefault();
        $state.go(authStateName.login);
      }
    });
  $rootScope.$on('$routeChangeError', function (ev, current, previous, rejection) {
    $log.warn("Event $routeChangeError");
    var returnUrl = $location.url();

    if (rejection && rejection.needsAuthentication === true) {
      $log.warn("needsAuthentication");
      // TODO: User angular provider to allow apps to configure path to Login
      $location.replace().path(authRoutePath.login).search({next: returnUrl});
    }

    if (rejection && rejection.needsCorrectRole === true) {
      $log.warn("needsCorrectRole");
      // TODO: User angular provider to allow apps to configure path to Not Authorized
      $location.replace().path(authRoutePath.notAuthorized).search({next: returnUrl});
    }
  });
});
