import React, { createContext, useCallback, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import RatesProvider from './rates-provider';
import FilterProvider from './filter-provider';

const Context = createContext();

export function useLayoutProvider() {
	return useContext(Context);
}

const LayoutProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState();
	const setLoading = useCallback((loadingState) => setIsLoading(loadingState), [setIsLoading]);

	const context = useMemo(
		() => ({
			isLoading,
			setLoading,
		}),
		[isLoading, setLoading]
	);

	return (
		<Context.Provider value={context}>
			<FilterProvider>
				<RatesProvider>{children}</RatesProvider>
			</FilterProvider>
		</Context.Provider>
	);
};

LayoutProvider.propTypes = {
	children: PropTypes.node,
};

LayoutProvider.Context = Context;

export default LayoutProvider;
