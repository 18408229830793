import esApi from './es-api.service';

interface TransferPayload {
	rental_branch_id?: number | null;
	service_branch_id?: number | null;
	inventory_branch_id?: number;
}

export const transferAsset = async (assetId: number, payload: TransferPayload) => {
	const req = await esApi.createRequest(esApi.buildEndpoint(`/assets/${assetId}`), { method: 'PATCH', body: payload });

	return fetch(req).then((res) => {
		if (!res.ok) {
			return Promise.reject(res);
		}

		return res;
	});
};
