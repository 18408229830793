import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { get as _get } from "lodash";

import Icon from "../../icon/icon.js";
import { iconList } from "../../icon/icon-config.js";

import styles from "../steps.module.css";

export default class Step extends PureComponent {
  getStatusClassName( status ) {
    switch( status ) {
      case "finish":
        return styles.stepsItemFinish;
      case "process":
        return styles.stepsItemProcess;
      case "error":
        return styles.stepsItemError;
      default:
        return styles.stepsItemWait;
    }
  }

  renderIconNode() {
    let { stepNumber, status, icon } = this.props;
    let finishIcon = _get( this.props, "icons.finish", "fasCheck" );
    let errorIcon = _get( this.props, "icons.error", "fasExclamationTriangle" );

    if ( status === "finish" ) {
      return ( <span className={styles.stepsIcon}><Icon className={styles.stepsItemIconFinish} name={finishIcon} /></span> );
    }
    
    if ( status === "error" ) {
      return ( <span className={styles.stepsIcon}><Icon name={errorIcon} /></span> );
    }
    
    if ( icon ) {
      return ( <span className={styles.stepsIcon}><Icon name={icon} /></span> );
    }

    return ( <span className={styles.stepsIcon}>{stepNumber}</span> );
  }

  render() {
    let { className, style, itemWidth, status, description, title } = this.props;

    let classString = classNames(
      styles.stepsItem,
      this.getStatusClassName( status ),
      className
    );
    let stepItemStyle = { ...style };

    if ( itemWidth ) {
      stepItemStyle.width = itemWidth;
    }

    return (
      <div className={classString} style={stepItemStyle}>
        <div className={styles.stepsItemTail} />
        <div className={styles.stepsItemIcon}>
          {this.renderIconNode()}
        </div>
        <div className={styles.stepsItemContent}>
          <div className={styles.stepsItemTitle}>
            {title}
          </div>
          {description && <div className={styles.stepsItemDescription}>{description}</div>}
        </div>
      </div>
    );
  }
}

Step.propTypes = {
  /** Do not modify, applied from the parent Steps component: A class applied to the Step container */
  className: PropTypes.string,
  /** The text description for the Step */
  description: PropTypes.any,
  /** Icon string used, must be one of the imported FontAwesome 5 found in the icon-config.js */
  icon: PropTypes.oneOf( Object.keys( iconList ) ),
  /** Used when the icons for "finish" or "error" states need to be overridden on a single step */
  icons: PropTypes.shape( {
    finish: PropTypes.oneOf( Object.keys( iconList ) ),
    error: PropTypes.oneOf( Object.keys( iconList ) ),
  } ),
  /** Do not modify, applied from the parent Steps component: Override for the width of the step */
  itemWidth: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string,
  ] ),
  /** The status used for the Step, "wait", "process", "error", "finish" */
  status: PropTypes.oneOf( [ "wait", "process", "error", "finish" ] ),
  /** Do not modify, applied from the parent Steps component: The step number, applied by the outer Steps container */
  stepNumber: PropTypes.number,
  /** Do not modify, applied from the parent Steps component: Additional styles that can get directly applied to the Step outer container */
  style: PropTypes.object,
  /** The title of the Step, can be a string or node such as a Button */
  title: PropTypes.PropTypes.oneOfType( [
    PropTypes.node,
    PropTypes.string,
  ] ),
};

Step.defaultProps = {
  className: "",
  description: undefined,
  icon: undefined,
  icons: undefined,
  itemWidth: undefined,
  status: undefined,
  stepNumber: undefined,
  style: undefined,
  title: undefined,
};
