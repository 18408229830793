import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import getActiveBreakpoints from './get-active-breakpoints.js';

export const Breakpoints = React.createContext([]);

export default class BreakpointsProvider extends PureComponent {
	constructor(...args) {
		super(...args);

		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize() {
		this.forceUpdate();
	}

	render() {
		const { children, overrideWidth } = this.props;
		const breakpoints = overrideWidth ? getActiveBreakpoints(overrideWidth) : getActiveBreakpoints();

		return <Breakpoints.Provider value={breakpoints}>{children}</Breakpoints.Provider>;
	}
}

BreakpointsProvider.propTypes = {
	children: PropTypes.node.isRequired,
	overrideWidth: PropTypes.number,
};
