import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { withErrorHandler } from '@components/error-boundary';
import GoogleMap from '../google-map/google-map';
import createOverlay from './static-marker-overlay/static-marker-overlay';

const DEFAULT_MAP_ZOOM = 17;

function useMapEventListener(map, event, cb) {
	useEffect(() => {
		if (map) {
			const listener = map.addListener(event, cb);

			return function cleanup() {
				listener.remove();
			};
		}
	}, [map, cb]);
}

export const LatLngModificationMap = ({
	className,
	center,
	onChange,
	mapOptions,
	originalLocation,
	originalLocationRadius,
	onCreateMap,
	fixedZoomEnabled,
}) => {
	const [map, setMap] = useState(null);
	const [overlay, setOverlay] = useState(null);
	const [originalMarker, setOriginalMarker] = useState(null);
	const handleCenterChange = useCallback(() => {
		if (map) {
			if (onChange) {
				const latlng = map.getCenter();
				onChange({ lat: latlng.lat(), lng: latlng.lng() });
			}
		}
	}, [onChange, map]);
	const handleMapCreated = useCallback(
		(map) => {
			setMap(map);

			if (originalLocation && !center) {
				map.setCenter(originalLocation);
			}
			if (onCreateMap) {
				onCreateMap(map);
			}
		},
		[setMap, originalLocation, center, onCreateMap]
	);

	useMapEventListener(map, 'dragend', handleCenterChange);

	if (map) {
		if (center) {
			map.setCenter(center);
		}

		if (!overlay) {
			setOverlay(createOverlay(map));
		}

		if (!originalMarker) {
			setOriginalMarker(
				new google.maps.Circle({
					map,
					radius: originalLocationRadius,
					center: originalLocation,
					strokeWeight: 1,
				})
			);
		}
	}

	const opts = {
		streetViewControl: false,
		zoom: DEFAULT_MAP_ZOOM,
		controlSize: 32,
		clickableIcons: false,
		scrollwheel: false,
		...mapOptions,
	};

	return (
		<GoogleMap
			className={className}
			onCreateMap={handleMapCreated}
			mapOptions={opts}
			fixedZoomEnabled={fixedZoomEnabled}
		/>
	);
};

LatLngModificationMap.propTypes = {
	className: PropTypes.string,
	center: PropTypes.shape({
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
	}),
	fixedZoomEnabled: PropTypes.bool,
	onChange: PropTypes.func,
	mapOptions: PropTypes.object,
	originalLocation: PropTypes.shape({
		lat: PropTypes.number.isRequired,
		lng: PropTypes.number.isRequired,
	}),
	originalLocationRadius: PropTypes.number,
	onCreateMap: PropTypes.func,
};
LatLngModificationMap.defaultProps = {
	originalLocationRadius: 100,
};

export default withErrorHandler()(LatLngModificationMap);
