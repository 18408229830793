import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useColumns } from '../providers/column-provider.js';

import styles from './cell.module.css';

function stopPropagation(fn) {
	return (event) => {
		event.stopPropagation();
		if (typeof fn === 'function') {
			fn(event);
		}
	};
}

const Cell = ({
	className,
	children,
	style,
	noPadding,
	onClick,
	stopClickPropagation,
	truncateText,
	title,
	columnId,
	textAlign,
	preserveNewLines,
	...props
}) => {
	const { getWidth } = useColumns();

	const cellTitle = title || (typeof children === 'string' || typeof children === 'number' ? String(children) : null);
	const width = columnId ? getWidth(columnId) : null;

	const clickHandler = stopClickPropagation ? stopPropagation(onClick) : onClick;

	return (
		<div
			title={cellTitle}
			style={{ width, textAlign, ...style }}
			className={classNames(
				styles.cell,
				noPadding && styles.noPadding,
				truncateText && styles.truncateText,
				preserveNewLines && styles.preserveNewLines,
				className
			)}
			onClick={clickHandler}
			{...props}
		>
			{children}
		</div>
	);
};

Cell.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	/** the columnId of this cell, this is used to get the columnWidth for the cell */
	columnId: PropTypes.string,
	noPadding: PropTypes.bool,
	style: PropTypes.object,
	/** sets the `text-align` style */
	textAlign: PropTypes.string,
	title: PropTypes.string,
	/** hide text that overflows */
	truncateText: PropTypes.bool,
	preserveNewLines: PropTypes.bool,

	onClick: PropTypes.func,
	stopClickPropagation: PropTypes.bool,
};
Cell.defaultProps = {
	truncateText: true,
};

export default React.memo(Cell);
