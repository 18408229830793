import Ajax from "@equipmentshare/kernel-legacy/src/Ajax.js";
import NgClient from "../NgClient";

angular
  .module( "esApiClient", [ "esAuthentication" ] )
  .run( setAjaxClient );

function setAjaxClient ( $http ) {
  Ajax.setClient( new NgClient( $http ) );
}
