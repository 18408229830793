import React from 'react';
import PropTypes from 'prop-types';
import Table from '@components/table';
import { sortStringIgnoreCase } from '@services/sort.service';
import { renderExpiresCellChip, getExpiredCellDataForExport } from '../rate.helpers';

import styles from './rates-table.module.css';

export const HEADERS = {
	selection: {
		label: null,
		column: () => <Table.SelectionColumn key="selection" />,
	},
	customer: {
		label: 'Customer',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_customer"
				filterKey="_customer"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_customer"
			>
				{HEADERS.customer.label}
			</Table.Column>
		),
	},
	class: {
		label: 'Class',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_class"
				filterKey="_class"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_class"
			>
				{HEADERS.class.label}
			</Table.Column>
		),
	},
	location: {
		label: 'Location',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_location"
				filterKey="_location"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_location"
			>
				{HEADERS.location.label}
			</Table.Column>
		),
	},
	hourly: {
		label: 'Hourly',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_pricePerHourSort"
				filterKey="_pricePerHourSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_pricePerHourSort"
			>
				{HEADERS.hourly.label}
			</Table.Column>
		),
	},
	day: {
		label: 'Day',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_pricePerDaySort"
				filterKey="_pricePerDaySort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_pricePerDaySort"
			>
				{HEADERS.day.label}
			</Table.Column>
		),
	},
	week: {
		label: 'Week',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_pricePerWeekSort"
				filterKey="_pricePerWeekSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_pricePerWeekSort"
			>
				{HEADERS.week.label}
			</Table.Column>
		),
	},
	fourWeek: {
		label: '4-Week',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_pricePerMonthSort"
				filterKey="_pricePerMonthSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_pricePerMonthSort"
			>
				{HEADERS.fourWeek.label}
			</Table.Column>
		),
	},
	dateAdded: {
		label: 'Date Added',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_dateAddedSort"
				filterKey="_dateAddedSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_dateAddedSort"
			>
				{HEADERS.dateAdded.label}
			</Table.Column>
		),
	},
	addedBy: {
		label: 'Added By',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_addedBy"
				filterKey="_addedBy"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_addedBy"
			>
				{HEADERS.addedBy.label}
			</Table.Column>
		),
	},
	expires: {
		label: 'Expires',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_expires"
				filterKey="_expires"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_expires"
				sortMethod={sortStringIgnoreCase}
			>
				{HEADERS.expires.label}
			</Table.Column>
		),
	},
};

export const CELLS = {
	selection: {
		prop: null,
		cell: ({ rate }) => <Table.SelectionCell key="selection" selectionId={rate._rentalRateId} />,
	},
	customer: {
		prop: '_customer',
		cell: ({ rate }) => <Table.Cell columnId="_customer">{rate._customer}</Table.Cell>,
	},
	class: {
		prop: '_class',
		cell: ({ rate }) => <Table.Cell columnId="_class">{rate._class}</Table.Cell>,
	},
	location: {
		prop: '_location',
		cell: ({ rate }) => <Table.Cell columnId="_location">{rate._location}</Table.Cell>,
	},
	hourly: {
		prop: '_pricePerHour',
		cell: ({ rate }) => <Table.Cell columnId="_pricePerHourSort">{rate._pricePerHour}</Table.Cell>,
	},
	day: {
		prop: '_pricePerDay',
		cell: ({ rate }) => <Table.Cell columnId="_pricePerDaySort">{rate._pricePerDay}</Table.Cell>,
	},
	week: {
		prop: '_pricePerWeek',
		cell: ({ rate }) => <Table.Cell columnId="_pricePerWeekSort">{rate._pricePerWeek}</Table.Cell>,
	},
	fourWeek: {
		prop: '_pricePerMonth',
		cell: ({ rate }) => <Table.Cell columnId="_pricePerMonthSort">{rate._pricePerMonth}</Table.Cell>,
	},
	dateAdded: {
		prop: '_dateAdded',
		cell: ({ rate }) => <Table.Cell columnId="_dateAddedSort">{rate._dateAdded}</Table.Cell>,
	},
	addedBy: {
		prop: '_addedBy',
		cell: ({ rate }) => <Table.Cell columnId="_addedBy">{rate._addedBy}</Table.Cell>,
	},
	expires: {
		prop: '_expires',
		exportMethod: getExpiredCellDataForExport,
		cell: ({ rate }) => (
			<Table.Cell className={styles.expires} columnId="_expires">
				{renderExpiresCellChip(rate)}
			</Table.Cell>
		),
	},
};

Object.values(CELLS).forEach((cellComponent) => {
	cellComponent.propTypes = {
		rate: PropTypes.object,
	};
});
