import React from "react";
import PropTypes from "prop-types";

import Circle from "../circle/circle.js";

export function Dashboard( { gapDegree, gapPosition, ...restProps } ) {
  return (
    <Circle
      {...restProps}
      gapDegree={gapDegree}
      gapPosition={gapPosition}
    />
  );
}

Dashboard.propTypes = {
  /** An integer value for how much space the dashboard covers */
  gapDegree: PropTypes.number,
  /** the gap position, options: `top` `right` `bottom` `left` */
  gapPosition: PropTypes.oneOf( [ "top", "right", "bottom", "left" ] ),
};

Dashboard.defaultProps = {
  gapDegree: 75,
  gapPosition: "bottom",
};

export default Dashboard;
