import { WindowPostMessageProxy } from 'window-post-message-proxy';
import appNavStyles from '@components/app-menu/app-menu.module.css';

const APP_CONTEXT = 'admin';

function asyncTimeout(time) {
	return new Promise((res) => setTimeout(() => res(null), time));
}

async function messageHandler(message) {
	const handler = MESSAGE_TYPES[message.type];

	if (handler) {
		return await handler(message.payload);
	} else {
		console.error('unable to handle message from parent window', message);
	}

	return null;
}
function canHandleMessage(message) {
	return Object.keys(MESSAGE_TYPES).includes(message.type);
}

let proxy;

// if there is a parent window start talking to it
if (window.parent && window.parent !== window) {
	console.info('found parent window');

	proxy = new WindowPostMessageProxy({ receiveWindow: window });

	proxy.addHandler({ test: canHandleMessage, handle: messageHandler });
}

const MESSAGE_TYPES = {
	reload() {
		location.reload();
	},
	toggleAppNav: (showAppNav) => {
		const appNav = document.getElementsByClassName(appNavStyles.container)[0];

		appNav.style.display = showAppNav ? '' : 'none';
	},
	randomNumber: () => {
		return Math.random();
	},
	consoleLog: (message) => {
		console.log(message);
	},
	route: (path) => {
		window.location.hash = `#/${path}`;
	},
	stop: () => {
		proxy.stop();
	},
};

function sendMessage(type, data) {
	if (proxy) {
		return proxy.postMessage(window.parent, { type, ...data });
	}

	return null;
}

async function askForApiToken() {
	for (let i = 0; i < 5; i++) {
		const res = await Promise.race([sendMessage('apiTokenRequest'), asyncTimeout(1000 * 60)]);

		if (res && res.token) {
			return res.token;
		}
	}

	return null;
}

function sendInitializedMessage() {
	sendMessage('initialized', { context: APP_CONTEXT });
}

const iframeControllerService = {
	sendMessage,
	askForApiToken,
	sendInitializedMessage,
};

export default iframeControllerService;
