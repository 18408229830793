import ngModule from '../../../ng/index.js';
import template from './rental-status-actions.html';

ngModule.component('rentalStatusActions', {
	template,
	controller: 'RentalStatusActionsCtrl',
	bindings: {
		rental: '<',
		onSave: '&',
	},
});
