import ngModule from '../../ng/index.js';

function fileUpload($parse) {
	return {
		restrict: 'A',
		link(scope, elm, attrs) {
			elm.bind('change', () => {
				$parse(attrs.fileUpload).assign(scope, elm[0].files[0]);
				//TODO: why do I have to dig up to the parent scope on create-photo.html to set file when equipment-edit.html does not?
				$parse(attrs.fileUpload).assign(scope.$parent, elm[0].files[0]);
				scope.$apply();

				if (attrs.autoUpload) {
					scope.uploadImage();
				}
			});
		},
	};
}

ngModule.directive('fileUpload', fileUpload);
