/* eslint-disable react/jsx-no-bind, react/no-unused-prop-types */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { AutoSizer, MultiGrid } from "react-virtualized";

import Icon from "../icon/icon.js";
import Button from "../button/button.js";
import ButtonGroup from "../button-group/button-group.js";
import MultiSelectCurtain from "../multi-select-curtain/multi-select-curtain.js";

import ColumnSizer from "./components/column-sizer/column-sizer.js";
import ColumnHeader from "./components/column-header/column-header.js";
import CsvExportButton from "./components/csv-export-button/csv-export-button.js";
import { exportCsvFile, getCsvString } from "./utils/csv-helper/csv-helper.js";
import { FilterChange, SortChange } from "./utils/state-events.js";
import { SORT_DIRECTION_NONE, SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING } from "./utils/constants.js";
// Exported here for backwards compatability
export { SORT_DIRECTION_NONE, SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING };

import tableStyles from "./virtualized-table-form.module.css";

const CELL_HEIGHT_PX = 45;
const CELL_WIDTH_PX = 150;
const FILTERABLE_HEADER_HEIGHT_PX = 66;
const HEADER_HEIGHT_PX = 37;
const ROW_SELECT_COLUMN_KEY = "row_select_column";

/**
 * Handles use-case when the user needs to take action on a combination of rows/columns (not be intended for simple table views). Active development will occur with an eye towards the Design System’s new direction. Future roadmap for this component will be breaking it apart into smaller, composable components (ex: form actions vs virtualized table) so the internal implementation can be replaced.
 *
 * Renders a table with a fixed header and (optionally) fixed columns, based on the MultiGrid component from react-virtualized.
 * Unless otherwise noted the properties available to [MultiGrid](https://raw.githubusercontent.com/bvaughn/react-virtualized/master/docs/MultiGrid.md) and [Grid](https://raw.githubusercontent.com/bvaughn/react-virtualized/master/docs/Grid.md) will be directly applied, as-is.
 *
 * The table will stretch to fill all available space within its container. If the table overflows the width of its container, the user may scroll horizontally to view overflowed content. Optionally, you may choose to fix one or more columns in place, so that they do not scroll horizontally with the rest of the table.
 *
 * **Notes:**
 * - The preferred implementation is to abstract the columns out of the table declaration file and into a `./columns/` subdirectory. Examine the code for the "ESVirtualizedTable with Abstracted Columns" below. Currently, a real-life example can be found at `es-track/manage/js/components/asset-keypad-table/`.
 * - If the UI design calls for a highly customized layout for the record, you can create custom records. See the code for  "VirtualizedTableForm with Custom Record Component" for an example. Because sorting and filtering could return unexpected results on a column of custom records, consider adding a custom header and search component.
 * - You can create a custom header. See the code for "VirtualizedTableForm with Custom Header" for an example.
 *
 * **Ignored `MultiGrid` and `Grid` Properties**
 *
 * - cellRenderer
 * - classNameBottomLeftGrid
 * - classNameBottomRightGrid
 * - classNameTopLeftGrid
 * - classNameTopRightGrid
 * - columnCount
 * - enableFixedColumnScroll
 * - enableFixedRowScroll
 * - estimatedColumnSize
 * - estimatedRowSize
 * - fixedRowCount
 * - height
 * - noContentRenderer
 * - rowCount
 * - scrollToRow
 * - width
 */
export default class VirtualizedTableForm extends PureComponent {
  constructor( props ) {
    super( props );

    this.calculateRowHeight = this.calculateRowHeight.bind( this );
    this.cellRenderer = this.cellRenderer.bind( this );
    this.exportAllCsv = this.exportAllCsv.bind( this );
    this.exportFilteredCsv = this.exportFilteredCsv.bind( this );
    this.getFixedColumnCount = this.getFixedColumnCount.bind( this );
    this.handleFilterChange = this.handleFilterChange.bind( this );
    this.handleGridScroll = this.handleGridScroll.bind( this );
    this.handleRowHover = this.handleRowHover.bind( this );
    this.handleSortChange = this.handleSortChange.bind( this );
    this.noContentRenderer = this.noContentRenderer.bind( this );
    this.showScrollIndicators = this.showScrollIndicators.bind( this );
    this.isRowSelected = this.isRowSelected.bind( this );
    this.toggleRowSelection = this.toggleRowSelection.bind( this );
    this.areAllRowsSelected = this.areAllRowsSelected.bind( this );
    this.toggleSelectAllRows = this.toggleSelectAllRows.bind( this );
    this.getVisibleColumns = this.getVisibleColumns.bind( this );
    this.setVisibleColumns = this.setVisibleColumns.bind( this );
    this.getSelectedRows = this.getSelectedRows.bind( this );
    this.renderSelectRowCheckbox = this.renderSelectRowCheckbox.bind( this );
    this.renderSelectAllRowsCheckbox = this.renderSelectAllRowsCheckbox.bind( this );

    // row selection column is not displayed by default so the index is incremented by one to skip first column
    const columnIndexes = [ ...Array( props.columns.length ).keys() ];
    const visibleColumnIds = new Set( columnIndexes.map( ( index ) => index + 1 ) );

    const rowSelectColumn = {
      key: ROW_SELECT_COLUMN_KEY,
      title: "Row Select",
      filterable: false,
      sortable: false,
      excludeFromExport: true,
      hideTooltip: true,
      width: 50,
      className: tableStyles.centeredCheckbox,
      customHeader: this.renderSelectAllRowsCheckbox,
      render: this.renderSelectRowCheckbox,
    };

    const columns = [
      rowSelectColumn,
      ...props.columns
    ];

    this.state = {
      areScrollIndicatorsHidden: true,
      canScrollLeft: true,
      canScrollRight: true,
      filters: props.defaultFilterState,
      hoveredRowIndex: null,
      rows: [ ...props.rows ],
      columns,
      scrollToCriteria: null,
      scrollToRow: null,
      sort: props.defaultSortState,
      selectedRowIds: new Set(),
      visibleColumnIds,
    };
  }

  componentDidMount() {
    const { sort, filters } = this.state;

    const defaultSortedColumn = this.getVisibleColumns().find( ( column ) => column.defaultSort );
    
    if ( Object.keys( sort ).length || Object.keys( filters ).length ) {
      const columnKeyToSort = Object.keys( sort )[0];

      this.handleSortChange( columnKeyToSort, sort[columnKeyToSort] );
    }
    else if ( defaultSortedColumn && defaultSortedColumn.sortable !== false ) {
      let defaultSortDirection = SORT_DIRECTION_DESCENDING;

      if ( defaultSortedColumn.defaultSortDirection && ( defaultSortedColumn.defaultSortDirection === SORT_DIRECTION_ASCENDING || defaultSortedColumn.defaultSortDirection === SORT_DIRECTION_DESCENDING ) ) {
        defaultSortDirection = defaultSortedColumn.defaultSortDirection;
      }

      this.handleSortChange( defaultSortedColumn.key, defaultSortDirection );
    }

    this.showScrollIndicators();
  }

  componentDidUpdate( prevProps, prevState ) {
    const { rows } = this.props;

    if ( !_isEqual( prevProps.rows, rows ) ) {
      const filteredRows = this.filterRows( this.state.filters );
      const sortedFilteredRows = this.sortRows( filteredRows, this.state.sort );

      // eslint-disable-next-line
      this.setState( { rows: sortedFilteredRows } );
  
      if ( this.gridRef ) {
        this.gridRef.forceUpdateGrids();
      }
    }

    if ( this.state.scrollToCriteria !== prevState.scrollToCriteria ) {
      this._scrollToRow();
    }
  }

  componentWillUnmount() {
    clearTimeout( this.scrollIndicatorTimeout );
    clearTimeout( this.scrollToCriteriaTimeout );
    clearTimeout( this.scrollToRowTimeout );
  }

  areAllRowsSelected() {
    const { selectedRowIds, rows } = this.state;

    // should evaluate to false when both counts are zero (rows can be an empty array)
    if ( selectedRowIds.size === 0 ) {
      return false;
    }

    return selectedRowIds.size === rows.length;
  }

  isRowSelected( rowIndex ) {
    const { selectedRowIds } = this.state;

    return selectedRowIds.has( rowIndex );
  }

  toggleSelectAllRows() {
    const { rows } = this.state;

    // add one to row index to offset for the header
    const selectedRowIds = this.areAllRowsSelected()
      ? new Set()
      : new Set( rows.map( ( _, index ) => index + 1 ) );

    this.setState( {
      selectedRowIds,
    } );
  }

  toggleRowSelection( event ) {
    const { selectedRowIds } = this.state;

    const rowIndex = parseInt( event.target.value, 10 );
    const isRowSelected = this.isRowSelected( rowIndex );
    const updatedSelectedRowIds = new Set( selectedRowIds );

    isRowSelected ? updatedSelectedRowIds.delete( rowIndex ) : updatedSelectedRowIds.add( rowIndex );

    this.setState( {
      selectedRowIds: updatedSelectedRowIds
    } );
  }

  renderSelectRowCheckbox( _, rowIndex ) {
    const isChecked = this.isRowSelected( rowIndex );
    
    return ( <input checked={isChecked} onChange={this.toggleRowSelection} type="checkbox" value={rowIndex} /> );
  }

  renderSelectAllRowsCheckbox() {
    const isChecked = this.areAllRowsSelected();

    return (
      <div className={tableStyles.centeredCheckbox}>
        <input checked={isChecked} onChange={this.toggleSelectAllRows} type="checkbox" />
      </div>
    );
  }

  calculateRowHeight( { index } ) {
    const { rowHeight, customHeaderHeight, filterable } = this.props;

    const getBodyRowHeight = () => ( rowHeight || CELL_HEIGHT_PX );
    const getHeaderRowHeight = () => {
      if ( customHeaderHeight ) {
        return customHeaderHeight;
      }

      let isFilterable = (
        filterable &&
        this.getVisibleColumns().some( ( column ) => ( column.filterable !== false ) )
      );

      return isFilterable
        ? FILTERABLE_HEADER_HEIGHT_PX
        : HEADER_HEIGHT_PX;
    };

    return ( index === 0 )
      ? getHeaderRowHeight()
      : getBodyRowHeight();
  }

  cellRenderer( { columnIndex, rowIndex, style } ) {
    let column = this.getColumnByIndex( columnIndex );
    let row = this.getRowByIndex( rowIndex );

    let columnKey = _get( column, "key", columnIndex );
    let rowKey = _get( row, "key", rowIndex );
    let iteratorKey = `${columnKey}-${rowKey}`;

    let className = classNames(
      tableStyles.tableCell,
      `table-cell--${columnKey}`,
      this.props.rowClassName( row ),
      {
        [tableStyles.firstColumn]: ( columnIndex === 0 ),
        [tableStyles.firstRow]: ( rowIndex === 0 ),
        [tableStyles.hovered]: ( rowIndex === this.state.hoveredRowIndex ),
        [tableStyles.selected]: ( this.state.selectedRowIds.has( rowIndex ) ),
        [tableStyles.textLeft]: ( !column.rightAligned ),
        [tableStyles.textRight]: ( column.rightAligned ),
      }
    );

    return (
      <div
        className={className}
        key={iteratorKey}
        onClick={this.handleCellClick.bind( this, rowIndex, columnIndex )}
        onMouseEnter={this.handleRowHover.bind( this, rowIndex )}
        style={style}
      >
        {
          rowIndex === 0
            ? this.renderColumnHeader( columnIndex )
            : this.renderTableCell( columnIndex, rowIndex )
        }
      </div>
    );
  }

  noContentRenderer() {
    return ( <div className={tableStyles.emptyContent}>{this.props.emptyStateText}</div> );
  }

  setVisibleColumns ( columns ) {
    const selectedColumns = columns.filter( ( column ) => column.checked );
    const visibleColumnIds = new Set( selectedColumns.map( ( column ) => column.id ) );
  
    this.setState( {
      visibleColumnIds
    } );
  }

  getVisibleColumns() {
    const { columns, visibleColumnIds } = this.state;
    
    const visibleColumns = columns.filter( ( _, index ) => {
      return visibleColumnIds.has( index );
    } );

    return visibleColumns;
  }

  getSelectedRows( rows ) {
    const { selectedRowIds } = this.state;

    const isSelectRowColumn = ( column ) => column.key === ROW_SELECT_COLUMN_KEY;
    const isRowSelectionEnabled = this.getVisibleColumns().find( isSelectRowColumn );

    if ( !isRowSelectionEnabled ) {
      return rows;
    }

    const selectedRows = rows.filter( ( _, index ) => {
      return selectedRowIds.has( index + 1 );
    } );

    return selectedRows;
  }

  getColumnByIndex( columnIndex ) {
    return ( columnIndex >= 0 )
      ? this.getVisibleColumns()[ columnIndex ]
      : null;
  }

  getColumnByKey( columnKey ) {
    const { columns } = this.state;

    return columns.find( ( { key } ) => ( columnKey === key ) );
  }

  getRowByIndex( rowIndex ) {
    const { rows } = this.state;

    // The row at index 0 is the header, so we must offset indexing by 1.
    return ( rowIndex > 0 )
      ? rows[ rowIndex - 1 ]
      : null;
  }

  filterRows( filters ) {
    const { rows } = this.props;

    if ( Object.keys( filters ).length === 0 ) {
      return rows;
    }

    let filterMatch = ( filterTerm ) => {
      filterTerm = String( filterTerm ).toLowerCase();

      return ( testValue ) => ( String( testValue ).toLowerCase().includes( filterTerm ) );
    };

    let getCellValues = ( column, row ) => {
      let cellValue = row[column.key];

      if ( column.filterData ) {
        cellValue = column.filterData( row );
      }
      else if ( column.filterSortData ) {
        cellValue = column.filterSortData( row );
      }

      if ( !Array.isArray( cellValue ) ) {
        cellValue = [ cellValue ];
      }

      return cellValue;
    };

    return rows.filter( ( row ) => {
      return Object.keys( filters ).every( ( columnKey ) => {
        let column = this.getColumnByKey( columnKey );
        let filterTerm = filters[columnKey];

        if ( column.customFilter ) {
          return column.customFilter( filterTerm, row );
        }

        return getCellValues( column, row ).some( filterMatch( filterTerm ) );
      } );
    } );
  }

  getFixedColumnCount( doesExceedWidth ) {
    return doesExceedWidth()
      ? this.props.fixedColumnCount
      : 0;
  }

  handleCellClick( rowIndex, columnIndex ) {
    // Don't respond to clicks on a header cell...
    if ( rowIndex > 0 ) {
      let row = this.getRowByIndex( rowIndex );
      let column = this.getColumnByIndex( columnIndex );

      if ( typeof this.props.onCellClick === "function" ) {
        this.props.onCellClick( row, column );
      }
    }
  }

  handleFilterChange( columnKey, filterValue ) {
    const filters = { ...this.state.filters };

    if ( filterValue ) {
      filters[columnKey] = filterValue;
    }
    else {
      delete filters[columnKey];
    }

    const filteredRows = this.filterRows( filters );
    const sortedFilteredRows = this.sortRows( filteredRows, this.state.sort );

    this.setState( { filters, rows: sortedFilteredRows } );
    this.props.onStateEvent( new FilterChange( columnKey, filters, sortedFilteredRows ) );
    if ( this.gridRef ) {
      this.gridRef.forceUpdateGrids();
    }
  }

  handleGridScroll( { clientWidth, scrollLeft, scrollWidth } ) {
    if ( isNaN( clientWidth ) || isNaN( scrollLeft ) || isNaN( scrollWidth ) ) {
      return;
    }

    this.showScrollIndicators();

    let canScrollLeft = scrollLeft > 0;
    let canScrollRight = ( scrollLeft + clientWidth ) < scrollWidth;

    if ( this.state.canScrollLeft !== canScrollLeft || this.state.canScrollRight !== canScrollRight ) {
      this.setState( { canScrollLeft, canScrollRight } );
    }
  }

  handleRowHover( hoveredRowIndex ) {
    const { disableRowHoverHighlighting } = this.props;

    if ( disableRowHoverHighlighting ) {
      return;
    }
    
    this.setState( { hoveredRowIndex } );
      
    if ( this.gridRef ) {
      this.gridRef.forceUpdateGrids();
    }
  }

  handleSortChange( columnKey, currentSortDirection = SORT_DIRECTION_ASCENDING ) {
    const sort = {
      columnKey,
      direction: ( [ SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING ].includes( currentSortDirection ) ? currentSortDirection : SORT_DIRECTION_ASCENDING )
    };
    const filteredRows = this.filterRows( this.state.filters );
    const sortedFilteredRows = this.sortRows( filteredRows, sort );

    this.setState( { rows: sortedFilteredRows, sort } );
    this.props.onStateEvent( new SortChange( columnKey, sort.direction ) );
    if ( this.gridRef ) {
      this.gridRef.forceUpdateGrids();
    }
  }

  renderColumnHeader( columnIndex ) {
    const { filters, sort, selectedRowIds } = this.state;
    const { filterable } = this.props;

    let column = this.getColumnByIndex( columnIndex );

    let filterValue = filters[column.key];
    let sortDirection = ( sort.columnKey === column.key )
      ? sort.direction
      : SORT_DIRECTION_NONE;
    let customHeader = ( typeof column.customHeader === "function" ) ? column.customHeader : null;

    // selectedRowCount passed to force re-render when row count changes
    return (
      <ColumnHeader
        {...column}
        columnKey={column.key}
        customHeader={customHeader}
        filterValue={filterValue}
        filterable={( filterable && column.filterable )}
        handleFilterChange={this.handleFilterChange}
        handleSortChange={this.handleSortChange}
        selectedRowCount={selectedRowIds.size}
        sortDirection={sortDirection}
      />
    );
  }

  renderTableCell( columnIndex, rowIndex ) {
    let column = this.getColumnByIndex( columnIndex );
    let row = this.getRowByIndex( rowIndex );

    let className = column.className;
    let style = { width: "100%" };

    let title = column.tooltip
      ? column.tooltip( row )
      : row[ column.key ];

    let cellProps = !column.hideTooltip && [ "string", "number" ].includes( typeof( title ) )
      ? { className, style, title }
      : { className, style };

    let content = column.render
      ? column.render( row, rowIndex )
      : row[ column.key ];

    return (
      <div {...cellProps}>
        {content}
      </div>
    );
  }

  _scrollToRow() {
    let criteria = this.state.scrollToCriteria;

    if ( criteria ) {
      let rowIndex = this.state.rows.findIndex( ( row ) => (
        row[ criteria.propKey ] === criteria.matchValue
      ) );

      if ( rowIndex >= 0 ) {
        this.setState( { scrollToRow: rowIndex + 1 } );

        clearTimeout( this.scrollToRowTimeout );
        this.scrollToRowTimeout = setTimeout( () => {
          this.setState( { scrollToRow: null } );
        }, 0 );
      }
    }
  }

  scrollToRowByProp( scrollToCriteria ) {
    this.setState( { scrollToCriteria } );

    clearTimeout( this.scrollToCriteriaTimeout );
    this.scrollToCriteriaTimeout = setTimeout( () => {
      this.setState( { scrollToCriteria: null } );
    }, 1000 );
  }

  showScrollIndicators() {
    if ( this.state.areScrollIndicatorsHidden ) {
      this.setState( { areScrollIndicatorsHidden: false } );
    }

    clearTimeout( this.scrollIndicatorTimeout );
    this.scrollIndicatorTimeout = setTimeout( () => {
      this.setState( { areScrollIndicatorsHidden: true } );
    }, 1500 );
  }

  sortRows( rows, sort ) {
    let column = this.getColumnByKey( sort.columnKey );

    if ( !column ) {
      return rows;
    }

    if ( column.customSortComparer ) {
      return [ ...rows ].sort( column.customSortComparer.bind( null, sort.direction ) );
    }
    else {
      let getValue = ( source ) => {
        let value = source[column.key];

        if ( column.sortData ) {
          value = column.sortData( source );
        }
        else if ( column.filterSortData ) {
          value = column.filterSortData( source );
        }

        if ( typeof value === "string" ) {
          value = value.toLowerCase();
        }

        return value;
      };

      return [ ...rows ].sort( ( a, b ) => {
        let aValue = getValue( a );
        let bValue = getValue( b );

        if ( aValue === undefined || aValue === null || aValue === "" ) {
          return 1;
        }

        if ( bValue === undefined || bValue === null || bValue === "" ) {
          return -1;
        }

        if ( sort.direction === SORT_DIRECTION_ASCENDING ) {
          return ( aValue > bValue ) ? 1 : -1;
        }

        if ( sort.direction === SORT_DIRECTION_DESCENDING ) {
          return ( aValue < bValue ) ? 1 : -1;
        }

        return 0;
      } );
    }
  }

  exportAllCsv() {
    this.exportCsv( this.props.rows, this.props.csvFileName );
  }

  exportFilteredCsv() {
    this.exportCsv( this.state.rows, `${this.props.csvFileName}-(filtered)` );
  }

  exportCsv( rows, fileName ) {
    const { enableCsvExport } = this.props;

    if ( !enableCsvExport ) {
      return;
    }
    
    const columns = this.getVisibleColumns().filter( ( column ) => !column.excludeFromExport );
    const selectedRows = this.getSelectedRows( rows );

    exportCsvFile( getCsvString( columns, selectedRows ), fileName );
  }

  renderExportButton() {
    const displayFilteredExport = !!( this.props.filterable || this.props.csvExportType === EXPORT_TYPES.advanced );
    const isFilteredExportDisabled = !!(
      Object.keys( this.state.filters ).length === 0 && Object.keys( this.state.sort ).length === 0
    );

    const props = displayFilteredExport
      ? {
        exportAllCsv: this.exportAllCsv,
        disableFilteredExport: isFilteredExportDisabled,
        exportFilteredCsv: this.exportFilteredCsv,
      }
      : {
        exportAllCsv: this.exportAllCsv,
      };

    if ( this.props.renderCustomExportButton ) {
      return this.props.renderCustomExportButton( props );
    }

    return <CsvExportButton {...props} />;
  }

  renderButtonGroup() {
    const { columnsButton, enableCsvExport, buttons } = this.props;
    const { columns, visibleColumnIds } = this.state;

    const columnOptions = columns.map( ( column, index ) => ( {
      id: index,
      name: column.title,
      checked: visibleColumnIds.has( index ),
    } ) );

    return (
      <ButtonGroup className={tableStyles.csvButton} inlineRight>
        {buttons}
        {enableCsvExport && this.renderExportButton()}
        <MultiSelectCurtain
          clickOutsideToClose
          direction="right"
          selectAllLabel="Show All Columns"
          toggleElement={columnsButton}
          values={columnOptions}
          valuesToggle={this.setVisibleColumns}
        />
      </ButtonGroup>
    );
  }

  render() {
    const { defaultMinColumnWidth, rowHeight, onSectionRendered } = this.props;
    const { areScrollIndicatorsHidden, rows, scrollToRow, canScrollLeft, canScrollRight } = this.state;

    const visibleColumns = this.getVisibleColumns();
    const scrollIndicatorClassName = classNames( tableStyles.scrollIndicators, {
      [tableStyles.hiddenScrollIndicators]: areScrollIndicatorsHidden,
    } );

    return (
      <React.Fragment>
        {this.renderButtonGroup()}
        <div
          className={tableStyles.table}
          onMouseLeave={this.handleRowHover.bind( this, null )}
          onMouseMove={this.showScrollIndicators}
        >
          <AutoSizer>
            {( { height, width } ) => (
              <ColumnSizer
                columns={visibleColumns}
                containerWidth={width}
                defaultMinColumnWidth={defaultMinColumnWidth}
              >
                {( { doesExceedWidth, getColumnWidth, registerChild } ) => (
                  <MultiGrid
                    {...this.props}
                    cellRenderer={this.cellRenderer}
                    classNameBottomLeftGrid={classNames( tableStyles.tableBody, tableStyles.tableBodyLeft )}
                    classNameBottomRightGrid={classNames( tableStyles.tableBody, tableStyles.tableBodyRight )}
                    classNameTopLeftGrid={classNames( tableStyles.tableHeader, tableStyles.tableHeaderLeft )}
                    classNameTopRightGrid={classNames( tableStyles.tableHeader, tableStyles.tableHeaderRight )}
                    columnCount={visibleColumns.length}
                    columnWidth={getColumnWidth}
                    enableFixedColumnScroll
                    enableFixedRowScroll
                    estimatedColumnSize={defaultMinColumnWidth}
                    estimatedRowSize={rowHeight}
                    fixedColumnCount={this.getFixedColumnCount( doesExceedWidth )}
                    fixedRowCount={1}
                    height={height}
                    noContentRenderer={this.noContentRenderer}
                    onScroll={this.handleGridScroll}
                    onSectionRendered={onSectionRendered}
                    ref={( ref ) => {
                      this.gridRef = ref;
                      registerChild( ref );
                    }}
                    rowCount={( rows.length + 1 )}
                    rowHeight={this.calculateRowHeight}
                    scrollToRow={scrollToRow}
                    width={width}
                  />
                )}
              </ColumnSizer>
            )}
          </AutoSizer>
          <div className={scrollIndicatorClassName}>
            {canScrollLeft && <Icon name="fasChevronCircleLeft" />}
            {canScrollRight && <Icon className={tableStyles.rightScroller} name="fasChevronCircleRight" />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EXPORT_TYPES = {
  basic: "basic",
  advanced: "advanced",
};

VirtualizedTableForm.exportTypes = EXPORT_TYPES;

VirtualizedTableForm.defaultProps = {
  buttons: [],
  columnsButton: <Button label="Columns" />,
  csvFileName: "export",
  csvExportType: EXPORT_TYPES.basic,
  customHeaderHeight: null,
  defaultFilterState: {},
  defaultMinColumnWidth: CELL_WIDTH_PX,
  defaultSortState: {},
  emptyStateText: "No Data",
  enableCsvExport: false,
  filterable: true,
  fixedColumnCount: 0,
  onCellClick: () => {},
  disableRowHoverHighlighting: false,
  onSectionRendered: undefined,
  onStateEvent: () => {},
  renderCustomExportButton: undefined,
  rowClassName: () => ( "" ),
  rowHeight: CELL_HEIGHT_PX,
};

VirtualizedTableForm.propTypes = {
  /** An ordered array of `Buttons` to be displayed in the upper right button group.
   * Note: You must specify the `key` attribute of each button in the array.
   */
  buttons: PropTypes.array,
  /** An array of column configurations */
  columns: PropTypes.arrayOf( PropTypes.shape( {
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    customFilter: PropTypes.func,
    customHeader: PropTypes.func,
    customSortComparer: PropTypes.func,
    defaultSort: PropTypes.bool,
    defaultSortDirection: PropTypes.string,
    excludeFromExport: PropTypes.bool,
    exportData: PropTypes.func,
    filterable: PropTypes.bool,
    filterData: PropTypes.func,
    filterSortData: PropTypes.func,
    hideTooltip: PropTypes.bool,
    minWidth: PropTypes.number,
    render: PropTypes.func,
    rightAligned: PropTypes.bool,
    sortable: PropTypes.bool,
    sortData: PropTypes.func,
    tooltip: PropTypes.func,
    width: PropTypes.number,
  } ) ).isRequired,
  /** Button used to toggle multi-select curtain to show and hide table columns. **/
  columnsButton: PropTypes.element,
  /** Providing the `basic` type will display a basic export button when csv export is enabled.  Providing the `advanced` type will display an export button with expanded options for exporting.
   * Note: If `ESVirtualizedTable.filterable == true` then the advanced button will display.  This is to maintain backwards compatibility for now.
   */
  csvExportType: PropTypes.oneOf( Object.values( EXPORT_TYPES ) ),
  /** The base file name (excluding file extension) that will be used when exporting to a CSV file. */
  csvFileName: PropTypes.string,
  /** A fixed height applied to each column header. */
  customHeaderHeight: PropTypes.number,
  /** Applies a default filter to the table as it renders for the first time.  Multiple columns can be filtered.
   * Expected format is: `{ one_column_key: "on filter", two_column_key: "two filter" }`
   */
  defaultFilterState: PropTypes.object,
  /** Applies a minimum width to columns that don't define their own minimum width. */
  defaultMinColumnWidth: PropTypes.number,
  /** Applies default sort to the table as it renders for the first time.  This will override the sorting from the Column definition.
   * Expected format is:
   * `{ column_key: "ASC" }`
   * `{ column_key: "DESC" }`
   */
  defaultSortState: PropTypes.object,
  /** Turns off row highlighting on hover for performance */
  disableRowHoverHighlighting: PropTypes.bool,
  /** Text to display if no data is available to the table */
  emptyStateText: PropTypes.string,
  /** Set to `true` to enable an "Export to CSV" button for the table */
  enableCsvExport: PropTypes.bool,
  /** Set to `false` to disable filtering of the entire table. */
  filterable: PropTypes.bool,
  /** The number of columns (from the left edge of the table) to fix in place if horizontal scrolling becomes necessary to view table content. */
  fixedColumnCount: PropTypes.number,
  /** The callback to invoke whenever a cell in the table is clicked.  The callback is invoked with the row item and column configuration associated with the clicked cell. */
  onCellClick: PropTypes.func,
  /** A passthrough to Grid's onSectionRendered */
  onSectionRendered: PropTypes.func,
  /** The callback to invoke whenever a state event is broadcasted.  The callback is invoked with the name of the event and the new state values.
   * State events setup for broadcast:
   * `"filterChange"`
   * `"sortChange"`
   */
  onStateEvent: PropTypes.func,
  /** Renders a custom csv export button. Expects the following argument:
   * { exportAllCsv } or, if allowing a filtered export
   * { exportAllCsv, disableFilteredExport, exportFilteredCsv }
   */
  renderCustomExportButton: PropTypes.func,
  /** Takes a row object and returns a string that will be applied to the cells within the associated row */
  rowClassName: PropTypes.func,
  /** A fixed height applied to each row. */
  rowHeight: PropTypes.number,
  /** An array of row objects. */
  rows: PropTypes.array.isRequired,
};
