import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _pick from 'lodash/pick';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { EuiFieldNumber, EuiTextArea } from '@equipmentshare/ds2';
import { CheckLineIcon, CloseLineIcon } from '@equipmentshare/ds2-icons';
import { LineItemTypePicker } from '@equipmentshare/es-billing-components';
import { CompanyPartsPicker } from '@equipmentshare/es-admin-components';

import Table from '@components/table';
import { PARTS_LINE_ITEM_TYPES } from '@const/invoices';

const StyledForm = styled(Form)`
	display: contents;
`;

const LineItemForm = ({ onSubmit, onCancelClick, initialLineItem }) => {
	const defaultInitialValues = useMemo(
		() => ({
			line_item_type_id: null,
			description: '',
			number_of_units: null,
			price_per_unit: null,
			part_id: null,
			..._pick(initialLineItem, [
				'line_item_id',
				'line_item_type_id',
				'description',
				'number_of_units',
				'price_per_unit',
			]),
		}),
		[initialLineItem]
	);

	const iconProps = {
		size: 'l',
	};

	return (
		<Table.Row>
			<Formik initialValues={defaultInitialValues} onSubmit={onSubmit} enableReinitialize>
				{({ handleSubmit, values, setFieldValue, handleChange, submitForm, dirty }) => (
					<StyledForm onSubmit={handleSubmit}>
						<Table.Cell columnId="type" truncateText={false}>
							<LineItemTypePicker
								name="line_item_type_id"
								selectedTypeId={values.line_item_type_id ?? undefined}
								onChange={(id) => setFieldValue('line_item_type_id', id)}
								autoFocus
								isRequired
							/>
						</Table.Cell>
						<Table.Cell columnId="description" truncateText={false}>
							{PARTS_LINE_ITEM_TYPES.includes(values.line_item_type_id) ? (
								<CompanyPartsPicker
									defaultPartId={values.part_id}
									defaultDescription={values.description}
									onChange={(partId, description) => {
										setFieldValue('part_id', partId);
										setFieldValue('description', description);
									}}
									isDisabled={false}
									isRequired={true}
									fullWidth
								/>
							) : (
								<EuiTextArea
									name="description"
									value={values.description}
									onChange={handleChange}
									rows={1}
									required
									autoComplete="off"
									fullWidth
								/>
							)}
						</Table.Cell>
						<Table.Cell columnId="qty">
							<EuiFieldNumber
								min={0}
								required
								step={0.01}
								type="number"
								name="number_of_units"
								value={Number.isFinite(values.number_of_units) ? values.number_of_units : ''}
								onChange={handleChange}
							/>
						</Table.Cell>
						<Table.Cell columnId="price">
							<EuiFieldNumber
								min={0}
								required
								step={0.01}
								type="number"
								name="price_per_unit"
								value={Number.isFinite(values.price_per_unit) ? values.price_per_unit : ''}
								onChange={handleChange}
							/>
						</Table.Cell>
						<Table.ActionCell columnId="actions">
							<Table.ActionCell.Action
								icon={CheckLineIcon}
								iconProps={iconProps}
								onClick={submitForm}
								disabled={!dirty}
							/>
							<Table.ActionCell.Action icon={CloseLineIcon} iconProps={iconProps} onClick={onCancelClick} />
						</Table.ActionCell>
					</StyledForm>
				)}
			</Formik>
		</Table.Row>
	);
};

LineItemForm.propTypes = {
	onSubmit: PropTypes.func,
	onCancelClick: PropTypes.func,
	initialLineItem: PropTypes.object,
	companyId: PropTypes.number,
};

export default LineItemForm;
