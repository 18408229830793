import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import _last from 'lodash/last';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { EuiButton, EuiFormRow, EuiTitle, EuiText, EuiSelect } from '@equipmentshare/ds2';
import { EuiSimpleDatePicker, LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

import payoutProgramAssignmentsService from '@services/payout-program-assignments.service';
import toastService from '@services/toast.service.js';

const FormLayout = styled.form`
	padding: 10px 15px;
`;

const FlexEndContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const PayoutProgramForm = ({ assetId, refresh }) => {
	const [currentPayoutProgram, setCurrentPayoutProgram] = useState({});
	const [payoutProgramList, setPayoutProgramList] = useState([]);
	const [programId, setProgramId] = useState();
	const [startDate, setStartDate] = useState(moment());
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function getPayoutProgram() {
			const payoutProgramAssignments = await payoutProgramAssignmentsService.search({
				asset_id: assetId,
			});
			const lastAssignment = _last(_orderBy(payoutProgramAssignments, ['id'], ['asc']));
			if (lastAssignment && lastAssignment.end_date === null) {
				setCurrentPayoutProgram(lastAssignment);
			}
		}
		getPayoutProgram();
	}, [assetId]);

	useEffect(() => {
		async function getPayoutPrograms() {
			const programs = await payoutProgramAssignmentsService.getPrograms();
			const programOptions = programs.map((program) => ({
				value: program.payout_program_id,
				text: program.name,
			}));
			programOptions.push({ value: -1, text: 'Unenroll From Payouts' });
			setPayoutProgramList(programOptions);
		}
		getPayoutPrograms();
	}, []);

	const setChangeDate = (value) => setStartDate(value);
	const setProgram = ({ target: { value } }) => setProgramId(value);

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsLoading(true);
		if (parseInt(programId, 10) === -1) {
			handleUnenrollment();
		} else {
			const payoutProgramModel = {
				asset_id: assetId,
				payout_program_id: programId,
				start_date: startDate,
			};
			try {
				const newProgram = await payoutProgramAssignmentsService.create(payoutProgramModel);
				setCurrentPayoutProgram(newProgram);
				toastService.showSuccess(`Asset #${assetId} successfully updated`);
				refresh();
			} catch (error) {
				const errorResponse = await error.json();
				const message = _get(errorResponse, 'message', 'Unable to Payout Program');
				toastService.showError(message);
			} finally {
				setIsLoading(false);
				setProgramId(null);
			}
		}
	};

	const handleUnenrollment = async () => {
		if (_isEmpty(currentPayoutProgram)) {
			toastService.showError(`Asset ${assetId} is not currently enrolled an any payout programs`);
			setIsLoading(false);
		} else {
			try {
				const payload = {
					end_date: startDate,
				};
				await payoutProgramAssignmentsService.update(currentPayoutProgram.id, payload);
				setCurrentPayoutProgram({});
				toastService.showSuccess(`Asset #${assetId} successfully unenrolled from payouts`);
				refresh();
			} catch (error) {
				const errorResponse = await error.json();
				const message = _get(errorResponse, 'message', 'Unable to unassign asset');
				toastService.showError(message);
			} finally {
				setIsLoading(false);
				setProgramId(null);
			}
		}
	};

	return (
		<FormLayout action="#" onSubmit={handleSubmit}>
			{isLoading && <LoadingOverlay />}
			<EuiFormRow fullWidth>
				<EuiTitle size="s">
					<h2>Asset Payout Program</h2>
				</EuiTitle>
			</EuiFormRow>
			<EuiFormRow label="Current Payout Program" fullWidth>
				<EuiText>{currentPayoutProgram.program?.name || 'None'}</EuiText>
			</EuiFormRow>
			<EuiFormRow label="New Payout Program" fullWidth>
				<EuiSelect options={payoutProgramList} value={programId} onChange={setProgram} hasNoInitialSelection />
			</EuiFormRow>
			<EuiFormRow label="Change Date" fullWidth>
				<EuiSimpleDatePicker selected={startDate} onChange={setChangeDate} />
			</EuiFormRow>
			<EuiFormRow fullWidth>
				<FlexEndContainer>
					<EuiButton fill type="submit">
						Save New Payout Program
					</EuiButton>
				</FlexEndContainer>
			</EuiFormRow>
		</FormLayout>
	);
};

PayoutProgramForm.propTypes = {
	assetId: PropTypes.number.isRequired,
	refresh: PropTypes.func.isRequired,
};

export default PayoutProgramForm;
