import ngModule from '../../../../ng/index.js';
import moment from 'moment-timezone';
import template from './service-record-form.html';
import { createErrorHandler } from '../../../../state/helpers.js';
import toastService from '@services/toast.service.js';

ngModule
	.component('serviceRecordForm', {
		template,
		controller: 'ServiceRecordFormCtrl',
		bindings: {
			assetId: '=',
			serviceIntervalId: '=',
			onClose: '&',
			onComplete: '&',
		},
	})
	.controller('ServiceRecordFormCtrl', function ServiceRecordFormCtrl($scope, User, esAdminMaintenanceService) {
		const ctrl = this;

		$scope.data = {
			description: '',
			date_completed: new Date(),
		};

		$scope.submitForm = () => {
			const payload = new FormData();

			payload.append('file', $scope.data.file);
			payload.append('user_created', true);
			payload.append('created_by', User.getUserId());
			payload.append('service_provider', $scope.data.service_provider);
			payload.append('description', $scope.data.description);
			payload.append('service_interval_id', ctrl.serviceIntervalId);
			payload.append('date_completed', moment($scope.data.date_completed).valueOf());
			payload.append('cost_labor', $scope.data.cost_labor);
			payload.append('cost_parts', $scope.data.cost_parts);
			payload.append('equipment_id', ctrl.assetId);
			payload.append('hours', $scope.data.hours);

			esAdminMaintenanceService
				.postServiceRecord(ctrl.assetId, payload)
				.then(() => {
					toastService.showSuccess('Completed!');
					ctrl.onComplete();
				})
				.catch(createErrorHandler('Failed to completed service. please contact engineering'));
		};
	});
