import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import localCache from '@services/local-cache.service';
import errorHandlerService from '@services/error-handler.service';
import { Autocomplete } from '@equipmentshare/react-ui-components';
import CompanyOptionRenderer from '@components/company-option-renderer';
import companiesService from '@services/companies.service';
import { makeXFields } from '@helpers/xFields';

import styles from './company-picker-complex.module.css';

const CompanyXfields = makeXFields`{
  company_id,
  name,
  do_not_rent,
  net_terms,
  credit_limit,
  billing_location { street_1, city, state { abbreviation } },
}`;

export const CompanyPickerComplex = ({
	className,
	clearable = true,
	disabled,
	label,
	id,
	name,
	onChange,
	required,
	selectedCompanyId,
	placeholder = 'Search Companies',
	autoFocus,
	multi,
}) => {
	const [companies, setCompanies] = useState([]);
	const [loaded, setLoaded] = useState(true);

	useEffect(() => {
		fetchSelectedCompanies();
	}, [selectedCompanyId]);

	const fetchSelectedCompanies = async () => {
		if (!selectedCompanyId) {
			return;
		}

		try {
			setLoaded(false);
			const _selectedCompanies = Array.isArray(selectedCompanyId) ? selectedCompanyId : [selectedCompanyId];
			await localCache.loadBoth(
				localCache.createEntityKey('company-picker-fetch', _selectedCompanies),
				() => companiesService.fetchMultiple(_selectedCompanies, { headers: { 'X-Fields': CompanyXfields } }),
				(_companies) => setCompanies(_companies)
			);
		} catch (error) {
			errorHandlerService.genericErrorHandler(error, 'Failed to fetch companies. Please contact engineering.');
		} finally {
			setLoaded(true);
		}
	};

	const searchCompanies = useCallback(
		async (searchString = '') => {
			if (searchString && searchString.length < 3) {
				return;
			}

			setLoaded(false);
			try {
				await localCache.loadBoth(
					localCache.createEntityKey('company-picker-search', searchString),
					() => companiesService.search({ query: searchString }, { headers: { 'X-Fields': CompanyXfields } }),
					(_companies) => setCompanies(_.unionBy(_companies, companies, 'company_id'))
				);
			} catch (error) {
				errorHandlerService.genericErrorHandler(error, 'Failed to fetch companies. Please contact engineering.');
			} finally {
				setLoaded(true);
			}
		},
		[companies]
	);

	const debounceSearchCompanies = useCallback(_.debounce(searchCompanies, 250), [searchCompanies]);

	const handleInputChange = useCallback(
		(value) => {
			if (!value) {
				return;
			}

			debounceSearchCompanies(value);
		},
		[debounceSearchCompanies]
	);

	return (
		<Autocomplete
			className={classNames(styles.autocomplete, className)}
			clearable={clearable}
			disableFiltering
			disabled={disabled}
			isLoading={!loaded}
			label={label}
			labelKey="name"
			loadingText="Fetching Companies..."
			id={id}
			name={name}
			noResultsText="No Companies Found"
			onChange={onChange}
			onInputChange={handleInputChange}
			optionHeight={60}
			optionRenderer={CompanyOptionRenderer}
			options={companies}
			placeholder={placeholder}
			required={required}
			value={selectedCompanyId}
			valueKey="company_id"
			autoFocus={autoFocus}
			multi={multi}
			simpleValue={false}
		/>
	);
};

CompanyPickerComplex.propTypes = {
	className: PropTypes.string,
	clearable: PropTypes.bool,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	selectedCompanyId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
	placeholder: PropTypes.string,
	autoFocus: PropTypes.bool,
	multi: PropTypes.bool,
};

export default CompanyPickerComplex;
