angular.module('esAuthentication').provider('authStateName', function () {
  var login = 'my-app-login';
  var notAuthorized = 'my-app-not-authorized';
  var changePassword = 'my-app-change-password';
  var exemptPaths = ['my-app-exempt-path'];

  return {
    setLogin: function (stringValue) {
      login = stringValue;
    },
    setNotAuthorized: function (stringValue) {
      notAuthorized = stringValue;
    },
    setChangePassword: function (stringValue) {
      changePassword = stringValue
    },
    addExemptPath: function(pathName) {
      exemptPaths.push(pathName);
    },
    $get: function () {
      return {
        login: login,
        notAuthorized: notAuthorized,
        changePassword: changePassword,
        exemptPaths: exemptPaths
      }
    }
  }
});
