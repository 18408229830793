/**
 * Implementing app:
   app.config(function (authRoutePathProvider) {
       authRoutePathProvider.setLogin('/login');
       authRoutePathProvider.setNotAuthorized('/not-authorized');
       authRoutePathProvider.setChangePassword('/change-password');
   });

   app.service('MyService', function (authRoutePath) {
       var loginPath = authRoutePath.login;
       var notAuthorizedPath = authRoutePath.notAuthorized;
       var changePasswordPath = authRoutePath.changePasswordPath;
   );
 */

angular.module('esAuthentication').provider('authRoutePath', function () {
  var login = '/my-app-login';
  var notAuthorized = '/my-app-not-authorized';
  var changePassword = '/my-app-change-password';
  var exemptPaths = ['/my-app-exempt-path'];

  return {
    setLogin: function (stringValue) {
      login = stringValue;
    },
    setNotAuthorized: function (stringValue) {
      notAuthorized = stringValue;
    },
    setChangePassword: function (stringValue) {
      changePassword = stringValue
    },
    addExemptPath: function(pathName) {
      exemptPaths.push(pathName);
    },
    $get: function () {
      return {
        login: login,
        notAuthorized: notAuthorized,
        changePassword: changePassword,
        expemptPaths: exemptPaths
      }
    }
  }
});
