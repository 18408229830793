import { useReducer } from 'react';
import moment from 'moment-timezone';
import { RENTAL_RATE_TYPES } from '@const/company-rental-rates';

const INITIAL_STATE = {
	companyId: null,
	equipmentClassId: null,
	locationId: null,
	rateTypeId: RENTAL_RATE_TYPES.FIXED,
	hourlyRate: null,
	dailyRate: null,
	weeklyRate: null,
	monthlyRate: null,
	rateFactor: null,
	expirationDate: moment().add(1, 'years'),
};

const init = () => ({ ...INITIAL_STATE });

const _reducer = (state, action) => {
	switch (action.type) {
		case 'RESET':
			return INITIAL_STATE;
		case 'UPDATE':
			return {
				...state,
				[action.name]: action.value,
			};
		default:
			return state;
	}
};

export function reducer() {
	return useReducer(_reducer, INITIAL_STATE, init);
}
