import React from 'react';
import PropTypes from 'prop-types';

import Table from '@components/table';
import styles from './rates-table.module.css';

export const HEADERS = {
	selection: {
		label: null,
		column: () => <Table.SelectionColumn key="selection" />,
	},
	branch: {
		label: 'Branch',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_branch"
				filterKey="_branch"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_branch"
			>
				{HEADERS.branch.label}
			</Table.Column>
		),
	},
	category: {
		label: 'Category',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_category"
				filterKey="_category"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_category"
			>
				{HEADERS.category.label}
			</Table.Column>
		),
	},
	class: {
		label: 'Class',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_class"
				filterKey="_class"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_class"
			>
				{HEADERS.class.label}
			</Table.Column>
		),
	},
	book: {
		label: 'Book',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_bookPriceSort"
				filterKey="_bookPriceSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_bookPriceSort"
			>
				{HEADERS.book.label}
			</Table.Column>
		),
	},
	advertised: {
		label: 'Advertised',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_advertisedPriceSort"
				filterKey="_advertisedPriceSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_advertisedPriceSort"
			>
				{HEADERS.advertised.label}
			</Table.Column>
		),
	},
	benchmark: {
		label: 'Benchmark',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_benchmarkPriceSort"
				filterKey="_benchmarkPriceSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_benchmarkPriceSort"
			>
				{HEADERS.benchmark.label}
			</Table.Column>
		),
	},
	floor: {
		label: 'Floor',
		column: () => (
			<Table.Column
				className={styles.column}
				columnId="_floorPriceSort"
				filterKey="_floorPriceSort"
				filterComponent={Table.SearchFilter}
				filterMethod={Table.SearchFilter.filterMethod}
				sortKey="_floorPriceSort"
			>
				{HEADERS.floor.label}
			</Table.Column>
		),
	},
};

const renderPriceDatum = (rate, type) => {
	//Here i either should be able to either add in floor rates, or figure out where 'rate' is getting re-built into an object with floor rates as "null"
	switch (type) {
		case 'Hour':
			return `${rate.prices.Benchmark?._pricePerHour || '--'} (${rate.prices.Book?._pricePerHour || '--'} /${
				rate.prices.Floor?._pricePerHour || '--'
			} )`;
		case 'Day':
			return `${rate.prices.Benchmark?._pricePerDay || '--'} (${rate.prices.Book?._pricePerDay || '--'} / ${
				rate.prices.Floor?._pricePerDay || '--'
			})`;
		case 'Week':
			return `${rate.prices.Benchmark?._pricePerWeek || '--'} (${rate.prices.Book?._pricePerWeek || '--'} / ${
				rate.prices.Floor?._pricePerWeek || '--'
			})`;
		case 'Month':
			return `${rate.prices.Benchmark?._pricePerMonth || '--'} (${rate.prices.Book?._pricePerMonth || '--'} / ${
				rate.prices.Floor?._pricePerMonth || '--'
			})`;
		default:
			return '--';
	}
};

const renderPriceDatumWithAdvertisedRate = (rate, type) => {
	switch (type) {
		case 'Book':
			return `${rate.prices.Book?._pricePerHour || '--'} / ${rate.prices.Book?._pricePerDay || '--'} / ${
				rate.prices.Book?._pricePerWeek || '--'
			} / ${rate.prices.Book?._pricePerMonth || '--'}`;
		case 'Advertised':
			return `${rate.prices.Advertised?._pricePerHour || '--'} / ${rate.prices.Advertised?._pricePerDay || '--'} / ${
				rate.prices.Advertised?._pricePerWeek || '--'
			} / ${rate.prices.Advertised?._pricePerMonth || '--'}`;
		case 'Benchmark':
			return `${rate.prices.Benchmark?._pricePerHour || '--'} / ${rate.prices.Benchmark?._pricePerDay || '--'} / ${
				rate.prices.Benchmark?._pricePerWeek || '--'
			} / ${rate.prices.Benchmark?._pricePerMonth || '--'}`;
		case 'Floor':
			return `${rate.prices.Floor?._pricePerHour || '--'} / ${rate.prices.Floor?._pricePerDay || '--'} / ${
				rate.prices.Floor?._pricePerWeek || '--'
			} / ${rate.prices.Floor?._pricePerMonth || '--'}`;
		default:
			return '--';
	}
};

export const CELLS = {
	selection: {
		prop: null,
		cell: ({ rate }) => <Table.SelectionCell key="selection" selectionId={rate._rentalRateId} />,
	},
	branch: {
		prop: '_branch',
		cell: ({ rate }) => <Table.Cell columnId="_branch">{rate._branch}</Table.Cell>,
	},
	category: {
		prop: '_category',
		cell: ({ rate }) => <Table.Cell columnId="_category">{rate._category}</Table.Cell>,
	},
	class: {
		prop: '_class',
		cell: ({ rate }) => <Table.Cell columnId="_class">{rate._class}</Table.Cell>,
	},
	book: {
		prop: '_bookPrice',
		exportArg: 'Book',
		exportMethod: renderPriceDatumWithAdvertisedRate,
		cell: ({ rate }) => (
			<Table.Cell columnId="_bookPriceSort" title="Book">
				{renderPriceDatumWithAdvertisedRate(rate, 'Book')}
			</Table.Cell>
		),
	},
	advertised: {
		prop: '_advertisedPrice',
		exportArg: 'Advertised',
		exportMethod: renderPriceDatumWithAdvertisedRate,
		cell: ({ rate }) => (
			<Table.Cell columnId="_advertisedPriceSort" title="Advertised">
				{renderPriceDatumWithAdvertisedRate(rate, 'Advertised')}
			</Table.Cell>
		),
	},
	benchmark: {
		prop: '_benchmarkPrice',
		exportArg: 'Benchmark',
		exportMethod: renderPriceDatumWithAdvertisedRate,
		cell: ({ rate }) => (
			<Table.Cell columnId="_benchmarkPriceSort" title="Benchmark">
				{renderPriceDatumWithAdvertisedRate(rate, 'Benchmark')}
			</Table.Cell>
		),
	},
	floor: {
		prop: '_floorPrice',
		exportArg: 'Floor',
		exportMethod: renderPriceDatumWithAdvertisedRate,
		cell: ({ rate }) => (
			<Table.Cell columnId="_floorPriceSort" title="Floor">
				{renderPriceDatumWithAdvertisedRate(rate, 'Floor')}
			</Table.Cell>
		),
	},
};

Object.values(CELLS).forEach((cellComponent) => {
	cellComponent.propTypes = {
		rate: PropTypes.object,
	};
});
