import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import styled from 'styled-components';

import {
	EuiFieldText,
	EuiForm,
	EuiFormRow,
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiCallOut,
} from '@equipmentshare/ds2';

import statesService from '@services/states.service';
import hereApiService from '@services/here-api.service';
import { isValidFiveDigitZipCode } from '@helpers/utilities';
import StatePicker from '@components/state-picker/state-picker';
import AddressSuggestionInput from '../address-suggestion-input/address-suggestion-input';
import { Location, State } from '../common-types';

const Form = styled(EuiForm)`
	& > * {
		margin-bottom: 1em;
	}
`;

export function filterHereLocations(results: Record<string, any>[]) {
	return results.filter((result) => {
		const matchedHouseNumber = !!_get(result, 'scoring.fieldScore.houseNumber');
		const matchStreetIntersection = _get(result, 'scoring.fieldScore.streets.length', 0) > 1;

		return matchedHouseNumber || matchStreetIntersection;
	});
}

export type POBoxFormProps = {
	onSubmit: (location: Location) => void;
	submitLabel: string;
	onCancel: () => void;
	initialLocationName?: string;
};

export const POBoxForm: React.VFC<POBoxFormProps> = ({ initialLocationName, onSubmit, submitLabel, onCancel }) => {
	const [states, setStates] = useState<State[]>([]);
	const [street, setStreet] = useState<string>('');
	const [city, setCity] = useState<string | undefined>(undefined);
	const [stateId, setStateId] = useState<number | undefined>(undefined);
	const [zipCode, setZipCode] = useState<string | undefined>(undefined);
	const [validating, setValidating] = useState<boolean>(false);
	const [locationName, setLocationName] = useState<string | undefined>(initialLocationName);

	useEffect(() => {
		statesService.fetchAll().then(setStates);
	}, []);

	const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value);
	const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => setZipCode(e.target.value);
	const handleLocationNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setLocationName(e.target.value);

	const handleSelectSuggestion = async (location: Location) => {
		// strip zip+4 if its set
		if (location.zip_code) {
			const zipParts = String(location.zip_code).split('-');
			location.zip_code = zipParts[0] || location.zip_code;
		}

		setStreet(location.street_1);
		setCity(location.city);
		setZipCode(location.zip_code);

		const state = states.find((state) => state.name.toLowerCase() === location.state?.name?.toLocaleLowerCase());
		setStateId(state ? state.state_id : undefined);
	};
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		e.stopPropagation();

		setValidating(true);
		const state = states.find((s) => s.state_id === stateId);
		const valid = await hereApiService.validate({ city, state: state?.name, zip: zipCode });
		setValidating(false);

		const location = {
			nickname: locationName,
			state,
			state_id: state?.state_id,
			city,
			street_1: street,
			zip_code: zipCode,
			needs_review: !valid,
		};

		if (onSubmit) {
			onSubmit(location);
		}
	};

	return (
		<Form component="form" onSubmit={handleSubmit}>
			<EuiFormRow label="Name" fullWidth>
				<EuiFieldText fullWidth value={locationName || ''} onChange={handleLocationNameChange} autoFocus required />
			</EuiFormRow>

			<AddressSuggestionInput
				id="address-suggestions"
				label="Street"
				placeholder="Street Address with PO Box"
				value={street}
				onChange={setStreet}
				onSelectLocation={handleSelectSuggestion}
				required
				filterSuggestions={filterHereLocations}
				fullWidth
			/>

			<EuiFormRow label="City" fullWidth>
				<EuiFieldText fullWidth placeholder="City" value={city || ''} onChange={handleCityChange} required />
			</EuiFormRow>

			<EuiFormRow fullWidth>
				<EuiFlexGroup gutterSize="s" alignItems="center">
					<EuiFlexItem>
						<StatePicker label="State" selectedStateId={stateId} onChange={setStateId} required />
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFormRow label="Zip Code">
							<EuiFieldText
								type="text"
								placeholder="12345"
								value={zipCode || ''}
								onChange={handleZipChange}
								required
								isInvalid={!isValidFiveDigitZipCode(zipCode || '')}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFormRow>

			<EuiFormRow fullWidth>
				<EuiCallOut title="Warning" color="warning">
					<p>
						PO Boxes do not support <strong>rentals</strong> or <strong>deliveries</strong> as they are not real
						physical locations
					</p>
				</EuiCallOut>
			</EuiFormRow>

			<EuiFormRow fullWidth>
				<EuiFlexGroup gutterSize="s">
					<EuiFlexItem grow={false}>
						<EuiButton fill type="submit" isDisabled={validating}>
							{submitLabel}
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButton color="text" onClick={onCancel}>
							Cancel
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFormRow>
		</Form>
	);
};

export default POBoxForm;
