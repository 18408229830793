import angular from 'angular';
import template from './photo-dialog.html';

angular.module("esNgComponents").factory('photoDialogService', function($mdPanel, $q) {
  let _mdPanelRef;

  function showPhoto(imageSource) {
    var position = $mdPanel.newPanelPosition()
      .absolute()
      .center();

    var config = {
      attachTo: angular.element(document.body),
      controller: 'PhotoDialogCtrl',
      controllerAs: 'ctrl',
      disableParentScroll: true,
      template,
      hasBackdrop: true,
      panelClass: 'photo-dialog',
      position: position,
      trapFocus: true,
      zIndex: 150,
      clickOutsideToClose: true,
      escapeToClose: true,
      focusOnOpen: true,
      bindToController: true,
      locals: { imageSource, closeDialog }
    };

    // First close the dialog and destroy the reference then create and open
    closeDialog().then(() => {
      _mdPanelRef = $mdPanel.create(config);
      _mdPanelRef.open();
    });

  };

  function closeDialog() {
    if (!_mdPanelRef) {
      return $q.resolve();
    }
    return _mdPanelRef.close().then(() => {
      _mdPanelRef.destroy();
      _mdPanelRef = null;
    });
  };

  return {
    showPhoto: showPhoto,
    closeDialog: closeDialog
  }
});
