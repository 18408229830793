import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@equipmentshare/react-ui-components';
import equipmentModelService from '@services/equipment-model.service';

const EquipmentModelPicker = ({ modelId, makeId, onChange, label, ...props }) => {
	const [models, setModels] = useState([]);
	useEffect(() => {
		async function getModel() {
			const model = await equipmentModelService.fetch(modelId);
			models.push(model);
			setModels(models);
			onChange(modelId);
		}
		if (modelId && !models.find(({ equipment_model_id }) => modelId === equipment_model_id)) {
			getModel();
		}
	}, [modelId]);

	const fetchModels = async (value) => {
		const query = { query: value };
		if (makeId) {
			query.equipment_make_id = makeId;
		}
		const equipmentModels = await equipmentModelService.search(query);
		setModels(equipmentModels);
	};

	const handleInputChange = (value) => {
		if (value && value.length > 1) {
			fetchModels(value);
		}

		return value;
	};

	return (
		<Autocomplete
			label={label}
			value={modelId}
			onChange={onChange}
			disabled={!makeId}
			labelKey="name"
			valueKey="equipment_model_id"
			options={models}
			onInputChange={(value) => handleInputChange(value)}
			{...props}
		/>
	);
};

EquipmentModelPicker.propTypes = {
	label: PropTypes.string,
	modelId: PropTypes.number,
	makeId: PropTypes.number,
	onChange: PropTypes.func,
};
EquipmentModelPicker.defaultProps = {
	label: 'Branch',
};

export default EquipmentModelPicker;
