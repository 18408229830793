import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EuiButton, EuiFieldText } from '@equipmentshare/ds2';

import styles from './lat-lng-address-input.module.css';
import googleGeocoderService from '@services/google-geocoder.service.js';

const PARSE_LAT_LNG = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/;

const LatLngAddressInput = ({ onSelectAddress }) => {
	const [value, setValue] = useState('');
	const [showWarning, setShowWarning] = useState(false);

	const handleSubmit = async () => {
		setShowWarning(false);
		try {
			const parsed = value.trim().match(PARSE_LAT_LNG);

			const lat = parseFloat(parsed[1]) || null;
			const lng = parseFloat(parsed[3]) || null;

			if (Number.isFinite(lat) && Number.isFinite(lng)) {
				const address = await googleGeocoderService.getAddressFromLatLng({ lat, lng });

				// overwrite the lat lng, since google returns the closest street address
				address.latitude = lat;
				address.longitude = lng;

				if (onSelectAddress) {
					onSelectAddress(address);
				}
			} else {
				setShowWarning(true);
			}
		} catch (e) {
			setShowWarning(true);
		}
	};

	return (
		<div>
			<div className={styles.layout}>
				<EuiFieldText
					className={styles.input}
					placeholder="Latitude, Longitude"
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
				<EuiButton fill onClick={handleSubmit}>
					Submit
				</EuiButton>
			</div>
			{showWarning && <p className={styles.warning}>Unable to parse Geo-coordinates</p>}
		</div>
	);
};

LatLngAddressInput.propTypes = {
	onSelectAddress: PropTypes.func,
};

export default LatLngAddressInput;
