import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Icon from "../icon/icon.js";

import buttonStyles from "./button.module.css";

export const buttonTypes = [ "primary", "positive", "negative", "warning", "link", "transparent", "outline" ];

export class Button extends React.PureComponent {
  renderIcon() {
    if ( this.props.icon ) {
      return (
        <Icon {...this.props.iconProps} className={this.props.iconClass} name={this.props.icon} />
      );
    }

    if ( this.props.iconClass ) {
      return (
        <i aria-hidden="true" className={this.props.iconClass} />
      );
    }

    return null;
  }

  render() {
    let {
      block,
      buttonClassName,
      buttonType,
      children,
      disabled,
      inverse,
      label,
      large,
      onClick,
      rightIcon,
      small,
      styles,
      submitType,
      uppercase,
    } = this.props;

    let overrideClasses = styles && styles.match( /([^\s]+)/g ).map( ( style ) => ( buttonStyles[ style ] ) );

    let className = classNames(
      buttonStyles.btn,
      buttonType && buttonStyles[ buttonType ],
      {
        [ buttonStyles.block ]: block,
        [ buttonStyles.inverse ]: inverse,
        [ buttonStyles.large ]: large,
        [ buttonStyles.small ]: small,
        [ buttonStyles.uppercase ]: uppercase,
      },
      overrideClasses,
      buttonClassName
    );

    return (
      <button
        className={className}
        disabled={disabled}
        onClick={onClick}
        type={submitType ? "submit" : "button"}
      >
        { !children && !rightIcon && this.renderIcon()}
        { !children && label && ( <span>{label}</span> ) }
        { !children && rightIcon && this.renderIcon()}
        { children }
      </button>
    );
  }
}

Button.propTypes = {
  /** applies 100% width to the button */
  block: PropTypes.bool,
  /** Button class name to override styles at implementation */
  buttonClassName: PropTypes.string,
  /** Button type */
  buttonType: PropTypes.oneOf( buttonTypes ),
  /** child nodes allow for custom content to go inside of the button, overrides all other values like icons and labels */
  children: PropTypes.any,
  /** disabled */
  disabled: PropTypes.bool,
  /** icon from our Icon component */
  icon: PropTypes.string,
  /** icon class for example `fa fa-plus` */
  iconClass: PropTypes.string,
  /** props to be applied to the underlying icon */
  iconProps: PropTypes.object,
  /** Swaps the background and text colors of the button */
  inverse: PropTypes.bool,
  /** button label */
  label: PropTypes.string,
  /** larger button */
  large: PropTypes.bool,
  /** button click event */
  onClick: PropTypes.func,
  /** when `icon` or `iconClass` is provided rightIcon will place the icon on the right */
  rightIcon: PropTypes.bool,
  /** smaller button */
  small: PropTypes.bool,
  /** DEPRECATED: Please use buttonClassName to override styles */
  styles: PropTypes.string,
  /** `type="submit"` */
  submitType: PropTypes.bool,
  /** uppercase label */
  uppercase: PropTypes.bool,
};

Button.defaultProps = {
  block: null,
  buttonClassName: null,
  buttonType: null,
  children: null,
  disabled: false,
  icon: null,
  iconClass: null,
  iconProps: {},
  inverse: false,
  label: "",
  large: false,
  rightIcon: false,
  onClick: null,
  small: false,
  styles: null,
  submitType: false,
  uppercase: false,
};

export default Button;
