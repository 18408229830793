import React from 'react';
import PropTypes from 'prop-types';

import Body from '../body/body.js';
import SortedRows from '../sorted-rows/sorted-rows.js';
import FilteredRows from '../filtered-rows/filtered-rows.js';

const SortedBody = ({ rows, children, ...props }) => (
	<Body {...props}>
		<FilteredRows rows={rows}>
			{(filteredRows) => <SortedRows rows={filteredRows}>{(sortedRows) => sortedRows.map(children)}</SortedRows>}
		</FilteredRows>
	</Body>
);

SortedBody.propTypes = {
	children: PropTypes.func.isRequired,
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SortedBody;
