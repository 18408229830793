import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { inventoryApolloClient as apolloClient } from '@services/graphql/inventory-apollo-client';

import { Input, InputLabel } from '@equipmentshare/react-ui-components';
import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';
import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@equipmentshare/ds2';

import PartTypePicker from '@components/part-type-picker';
import RentalNameDisplay from '@components/rental-name-display';
import rentalsService from '@services/rentals.service.js';
import toastService from '@services/toast.service.js';

import styles from './part-picker-edit-form.module.css';
import errorHandlerService from '@services/error-handler.service';

const PART_RENTAL_TYPE_ID = 3;

const PartPickerEditForm = ({
	forceUpdate,
	partTypeId,
	partId,
	branchId,
	rentalId,
	quantity = 0,
	updateRentalObject,
	showEdit,
}) => {
	const [editing, setEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [selectedPartTypeId, setSelectedPartTypeId] = useState(partTypeId);
	const [selectedQuantity, setSelectedQuantity] = useState(quantity);
	const [selectedPartId, setSelectedPartId] = useState(partId);
	const [currentInventory, setCurrentInventory] = useState(null);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		const payload = {
			part_id: selectedPartId,
			part_type_id: selectedPartTypeId,
			quantity: selectedQuantity,
		};

		try {
			const rental = await rentalsService.assignPart(rentalId, payload);
			updateRentalObject(rental);
			setEditing(!editing);
			toastService.showSuccess(`Rental #${rentalId} successfully updated.`);
		} catch (error) {
			errorHandlerService.genericErrorHandler(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handlePartSelect = (_partId, part) => {
		setSelectedQuantity(0);
		setSelectedPartId(_partId);
		setCurrentInventory(_get(part, 'availableQuantity'));
		setSelectedPartTypeId(_get(part, 'partTypeId'));

		if (!_partId) {
			setCurrentInventory(0);
			setSelectedQuantity(0);
		}
	};

	const handleQuantityChange = ({ target: { value } }) => {
		setSelectedQuantity(value);
	};

	const toggleEditing = () => {
		if (editing) {
			setSelectedPartId(partId);
			setSelectedQuantity(quantity);
			setSelectedPartTypeId(partTypeId);
			if (selectedPartId !== partId) {
				setCurrentInventory(0);
			}
		}
		setEditing(!editing);
	};

	const renderCurrentInventory = useCallback(
		() => (
			<InputLabel
				className={styles.currentInventoryLabel}
				label={`Current Inventory: ${currentInventory}`}
				title="Current Inventory at the Branch"
			></InputLabel>
		),
		[currentInventory]
	);

	const quantityLabel = useMemo(() => (quantity ? `Quantity: ${quantity}` : ''), [quantity]);

	return (
		<form className={styles.row} onSubmit={handleSubmit}>
			{isLoading && <LoadingOverlay />}
			{editing || (currentInventory === null && partId) ? ( // Empty Check on Available Quantity to force a PartPicker render on load, which fetches the data
				<React.Fragment>
					<PartTypePicker
						key={selectedPartId}
						apolloClient={apolloClient}
						branchId={branchId}
						className={styles.extendedInput}
						disabled={!editing}
						onChange={handlePartSelect}
						placeholder="Search Bulk Items"
						queryOptions={{ fetchPolicy: 'no-cache' }}
						required
						selectedPartId={selectedPartId}
					/>
					<Input
						type="number"
						required
						disabled={!editing}
						className={styles.quantityInput}
						label="Quantity"
						value={selectedQuantity}
						min={1}
						step={1}
						onChange={handleQuantityChange}
					/>
					{!!currentInventory && renderCurrentInventory()}
				</React.Fragment>
			) : (
				<div className={styles.inputs}>
					<InputLabel
						className={styles.input}
						label={<RentalNameDisplay partId={partId} rentalTypeId={PART_RENTAL_TYPE_ID} />}
					></InputLabel>
					<InputLabel className={styles.quantityLabel} label={quantityLabel}></InputLabel>
					{showEdit && !!currentInventory && renderCurrentInventory()}
				</div>
			)}
			{showEdit &&
				(editing ? (
					<EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
						<EuiFlexItem grom={false}>
							<EuiButton fill type="submit">
								SAVE
							</EuiButton>
						</EuiFlexItem>
						<EuiFlexItem grom={false}>
							<EuiButton color="text" onClick={toggleEditing}>
								CANCEL
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				) : (
					<EuiButton color="text" onClick={toggleEditing}>
						EDIT
					</EuiButton>
				))}
		</form>
	);
};

PartPickerEditForm.propTypes = {
	branchId: PropTypes.number,
	forceUpdate: PropTypes.func,
	partId: PropTypes.number.isRequired,
	partTypeId: PropTypes.number.isRequired,
	quantity: PropTypes.number.isRequired,
	rentalId: PropTypes.number.isRequired,
	showEdit: PropTypes.bool.isRequired,
	updateRentalObject: PropTypes.func.isRequired,
};

export default PartPickerEditForm;
