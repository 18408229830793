import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EuiFormRow, EuiLoadingSpinner } from '@equipmentshare/ds2';

import locationsService from '@services/locations.service';
import errorHandlerService from '@services/error-handler.service';

import StreetAddressForm from '../components/street-address-form/street-address-form';
import PoBoxForm from '../components/po-box-form/po-box-form';
import LocationTypePicker from '../components/location-type-picker/location-type-picker';
import { LOCATION_TYPES } from '../const';
import styles from '../location-drawer.module.css';

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CreateForm = ({ companyId, onCancel, onCreateLocation, onSelectExistingGeofence, userId }) => {
	const [locationType, setLocationType] = useState(LOCATION_TYPES.STREET);
	const [locationName, setLocationName] = useState();
	const [loading, setLoading] = useState(false);

	const handleCreateLocation = async (locationPayload) => {
		setLoading(true);
		let location;
		let isSuccess = false;
		try {
			const payload = {
				...locationPayload,
				state_id: locationPayload.state.state_id,
				user_id: userId,
			};
			delete payload.state;

			location = await locationsService.create(payload);
			isSuccess = true;
		} catch (err) {
			errorHandlerService.genericErrorHandler(err);
		} finally {
			if (onCreateLocation) {
				onCreateLocation(location, isSuccess);
			}
			setLoading(false);
		}
	};

	if (loading) {
		return (
			<SpinnerContainer>
				<EuiLoadingSpinner size="xl" />
			</SpinnerContainer>
		);
	}

	const commonProps = {
		companyId,
		locationName,
		setLocationName,
		handleLocationTypeChange: setLocationType,
		onCancel,
		submitLabel: 'Create Location',
		onSubmit: handleCreateLocation,
	};

	const renderForm = () => {
		switch (locationType) {
			case LOCATION_TYPES.STREET:
				return (
					<StreetAddressForm {...commonProps} onSelectExistingGeofence={onSelectExistingGeofence} showGeofenceOption />
				);
			case LOCATION_TYPES.PO_BOX:
				return <PoBoxForm {...commonProps} />;
			default:
				return null;
		}
	};

	const form = renderForm();

	return form ? (
		<>
			<EuiFormRow label="Address Type" fullWidth>
				<LocationTypePicker className={styles.picker} selectedLocationType={locationType} onChange={setLocationType} />
			</EuiFormRow>
			{form}
		</>
	) : null;
};

CreateForm.propTypes = {
	userId: PropTypes.number.isRequired,
	companyId: PropTypes.number,
	onCancel: PropTypes.func,
	onCreateLocation: PropTypes.func,
	onSelectExistingGeofence: PropTypes.func,
};

export default CreateForm;
