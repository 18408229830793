import angular from 'angular';

const angularBridge = {
	getAngularService(serviceName) {
		const injector = angular.element(document.body).injector();

		return injector && injector.get(serviceName);
	},

	fetchServiceVariable(serviceName, methodName) {
		const service = this.getAngularService(serviceName);

		return service && service[methodName];
	},

	updateServiceVariable(serviceName, methodName, assignment) {
		const service = this.getAngularService(serviceName);

		if (service) {
			return (service[methodName] = assignment);
		}
	},

	callServiceMethod(serviceName, methodName, ...params) {
		const service = this.getAngularService(serviceName);

		return service && service[methodName] && service[methodName](...params);
	},

	updateAngularService(serviceName, methodName, ...params) {
		this.getAngularService('$timeout')(() => {
			this.callServiceMethod(serviceName, methodName, ...params);
		});
	},
};

export default angularBridge;
