import angular from 'angular';
import Chart from 'chart.js';

angular.module("esNgComponents").controller('VerticalBarChartCtrl', function() {
  let controller = this;

  controller.$onInit = refresh;
  controller.$onChanges = refresh;

  function refresh() {
      let ctx = document.getElementById('verticalBarChartCanvas');
      new Chart(ctx, {
        type: 'bar',
        data: controller.data,
        options: controller.options
      });
  }
});
