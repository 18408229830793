import angular from 'angular';
import _get from 'lodash/get';

angular.module( "esNgComponents" ).controller( "BranchPickerCtrl", function ( $scope, esApiFactoryV2, esBranchService, $q ) {
  let ctrl = this;

  $scope.branches = [];
  $scope.selectedBranch;

  $scope.searchBranches = ( query ) => {
    let load = $scope.branches.length ? $q.resolve( $scope.branches ) : ctrl.fetchBranches();
    let regexp = new RegExp( query, "i" );

    return load.then( branches => {
      return branches.filter( branch => {
        return regexp.test( branch.name );
      });
    })
  };

  ctrl.onBranchChange = () => {
    let updatedBranchId = _get($scope, "selectedBranch.id", null);

    if ( updatedBranchId === null && ctrl.nullAsAllOption === true ) {
        ctrl.onSelectBranch( { branch: null } );
    } else if (updatedBranchId !== ctrl.selectedBranchId && !ctrl.loadingBranches) {
        ctrl.onSelectBranch( { branch: $scope.selectedBranch } );
    }
  };

  $scope.$watch( "selectedBranch", ctrl.onBranchChange );

  ctrl.loadingBranches = null;
  ctrl.loadingBranchesCache = null;

  ctrl.fetchBranches = () => {
    if( ctrl.waitForBranches ){ return; }

    let query = {};
    if( !ctrl.showInactiveBranches ){
      query.active = true;
    }

    if( ctrl.showPublicRsp ) {
      query.is_public_rsp = true;
    }

    if( ctrl.loadingBranches && angular.equals( ctrl.loadingBranchesCache, query ) ){
      return ctrl.loadingBranches;
    }

    ctrl.loadingBranchesCache = query;

    let setBranches = branches => {
      $scope.branches = branches;
      ctrl.loadingBranches = null;
    };

    if( ctrl.companyId ) {
      return ctrl.loadingBranches = esApiFactoryV2.getCompanyBranches( ctrl.companyId, query ).then( setBranches );
    }

    return ctrl.loadingBranches = esBranchService.getBranches( query ).then( setBranches );
  };

  ctrl.updateSelectedBranch = () => {
    if( !ctrl.waitForBranches ){
      let load = $scope.branches.length ? $q.resolve( $scope.branches ) : ctrl.fetchBranches();
      load.then( () => {
        $scope.selectedBranch = $scope.branches.find( b => b.id === ctrl.selectedBranchId ) || null;
      })
    }
    else{
      $scope.selectedBranch = $scope.branches ? $scope.branches.find( b => b.id === ctrl.selectedBranchId ) : null;
    }
  };

  ctrl.$onInit = () => {
    if( !ctrl.waitForBranches ){
      ctrl.fetchBranches().then( ctrl.updateSelectedBranch );
    }
    else{
      $scope.branches = ctrl.branches;
      ctrl.updateSelectedBranch();
    }
  };

  ctrl.$onChanges = ( changes ) => {
    if(
      ( changes.companyId && changes.companyId.currentValue ) ||
      ( changes.showInactiveBranches && changes.showInactiveBranches.currentValue )
    ){
      ctrl.fetchBranches().then( ctrl.updateSelectedBranch );
    }
    else if(
      changes.selectedBranchId &&
      changes.selectedBranchId.currentValue &&
      ( !$scope.selectedBranch || $scope.selectedBranch.id !== changes.selectedBranchId.currentValue )
    ){
      ctrl.updateSelectedBranch();
    }
    else if( changes.branches && changes.branches.currentValue && changes.branches.currentValue.length ){
      $scope.branches = changes.branches.currentValue;
      ctrl.updateSelectedBranch();
    }
  };

});
