import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import { parse } from 'json2csv';

import { EuiIcon, EuiToolTip } from '@equipmentshare/ds2';
import { FileExcelLineIcon } from '@equipmentshare/ds2-icons';

import { CELLS as companyCells, HEADERS as companyHeaders } from '../../rates-table/company-columns';
import { CELLS as locationCells, HEADERS as locationHeaders } from '../../rates-table/location-columns';
import { CELLS as branchCells, HEADERS as branchHeaders } from '../../rates-table/branch-columns';

import { useRatesProvider } from '../../providers/rates-provider';
import toastService from '@services/toast.service';
import { downloadCsvFile } from '@helpers/csv.js';

import styles from './export-button.module.css';

const ExportButton = ({ rateType }) => {
	const { rates, selectedRateIds, selectedRates } = useRatesProvider();
	const ratesForExport = !selectedRateIds.length ? rates : selectedRates;

	const getColumnData = () => {
		switch (rateType) {
			case 'company':
				return { headers: companyHeaders, cells: companyCells };
			case 'location':
				return { headers: locationHeaders, cells: locationCells };
			case 'branch':
				return { headers: branchHeaders, cells: branchCells };
			default:
				return null;
		}
	};

	const generateCsvData = () => {
		const columns = getColumnData();

		return ratesForExport.map((rate) => {
			return Object.entries(columns.headers).reduce((acc, [key, val]) => {
				if (!val.label) {
					return acc;
				}

				const cell = columns.cells[key];
				const cellDatum = cell.exportMethod
					? // if cell exportMethod exists, use it to populate data
					  // additionally, pass cell.exportArg to cell.exportMethod if it exists
					  cell.exportMethod(rate, cell.exportArg || null)
					: // otherwise get the cell prop from rate
					  _get(rate, cell.prop);

				return {
					...acc,
					[val.label]: cellDatum,
				};
			}, {});
		});
	};

	const handleCsvDownload = () => {
		if (rates.length <= 0) {
			return toastService.showError('No data available for CSV generation.');
		}

		try {
			const csvData = generateCsvData();
			const fileName = `${rateType}_rates_${moment().format('MM-DD-YYYY_hhmm')}.csv`;
			const fields = Object.keys(csvData[0]);
			const csvString = parse(csvData, { fields });

			return downloadCsvFile(csvString, fileName);
		} catch (err) {
			return toastService.showError(
				err.data && err.data.message
					? err.data.message
					: 'There was an issue generating the CSV file. If the issue persists please contact engineering.'
			);
		}
	};

	return (
		<EuiToolTip position="left" content="Download as CSV">
			<div className={styles.button} onClick={handleCsvDownload}>
				<EuiIcon type={FileExcelLineIcon} />
			</div>
		</EuiToolTip>
	);
};

ExportButton.propTypes = {
	rateType: PropTypes.string.isRequired,
};

export default ExportButton;
