import angular from 'angular';
import template from './vertical-bar-chart.html';
import './vertical-bar-chart-ctrl.js';

angular.module("esNgComponents").component('verticalBarChart', {
  template,
  controller: 'VerticalBarChartCtrl',
  bindings: {
    data: '<',
    options: '<'
  }
});
