import ngModule from '../../../ng/index.js';
import template from './equipment-edit-form.html';
import EquipmentEditFormCtrl from './equipment-edit-form-ctrl';

function equipmentEditForm() {
	return {
		template,
		controller: EquipmentEditFormCtrl,
		scope: {
			assetColor: '=',
			equipmentId: '=',
			gettingEquipmentData: '=',
			onSave: '=',
			showAssetOwner: '@',
		},
	};
}

ngModule.directive('equipmentEditForm', equipmentEditForm);
