import esApi from './es-api.service';
import { CONTRACTS_DOMAIN } from '@const/env';
import { saveAs } from 'file-saver';
import authService from './auth.service';
import { API_URL } from '@const/env';
import toastService from '@services/toast.service.js';
import { EuiToast } from '@equipmentshare/ds2';

const CONTRACT_TYPES = ['rental', 'rapid_rent'];
const CONTRACT_TYPE_IDS = [1, 2];

/**
 * Filter contract based on contract type.
 */
function isRentalContract({ type = null, contract_type_id = null }) {
	if (type && CONTRACT_TYPES.includes(type)) {
		return true;
	}

	if (contract_type_id && CONTRACT_TYPE_IDS.includes(contract_type_id)) {
		return true;
	}

	return false;
}

async function getRentalContract(orderId, useOriginalTemplate = false) {
	const contracts = await esApi.request(`/orders/${orderId}/contracts`);

	return contracts.filter(isRentalContract)[0];
}

function showPDFError() {
	toastService.makeToast({
		title: 'Could not open the order confirmation',
		description: 'The pdf could not be opened. Please try again.',
		type: 'error',
		burnIn: 5,
	});
}

async function downloadOrderContractPDF(contract, useOriginalTemplate = false) {
	const token = await authService.getApiToken();
	let pdfUrl = `${API_URL}/contracts/${contract.contract_id}.pdf`;
	if (useOriginalTemplate) {
		pdfUrl += '?original_template=true';
	}
	const response = await fetch(new URL(pdfUrl, API_URL), {
		headers: { Authorization: `Bearer ${token}` },
	});

	if (response.ok) {
		const file = await response.blob();
		window.open(URL.createObjectURL(file));
	} else {
		throw new Error('Error occurred fetching PDF.');
	}
	return response;
}

function getContractLink(contract) {
	return `${CONTRACTS_DOMAIN}/c/${contract.contract_id}`;
}

async function downloadContractPDF(companyId, companyContractId, envelopeId) {
	const token = await authService.getApiToken(companyId, companyContractId);
	const response = await fetch(new URL(`/companies/${companyId}/company-contracts/${companyContractId}/pdf`, API_URL), {
		headers: { Authorization: `Bearer ${token}` },
	});
	const file = await response.blob();
	if (response.ok) {
		saveAs(file, `${envelopeId}.pdf`);
	}
	return response;
}

/**
 * Fetch contracts and filter for admin specific contract types.
 * @deprecated
 */
export async function getRentalAgreementLink(orderId, query, opts) {
	const contracts = await esApi.request(`/orders/${orderId}/contracts`, query, opts);
	const filteredContracts = contracts.filter(isRentalContract);

	if (!filteredContracts || filteredContracts.length === 0) {
		return 'There are no rental contracts available.';
	}

	const contractId = filteredContracts[0].contract_id;

	return `${CONTRACTS_DOMAIN}/c/${contractId}`;
}

function postAdminRentalAgreement({ order_id, subject, message, method, recipient }) {
	const body = { order_id, subject, message, method, recipient };
	// Not awaiting here because we need to do multiple requests in parallel, so need the promise
	return esApi.request('/admin/rental-agreements', null, { body, method: 'POST' });
}

const rentalContractsService = {
	getRentalAgreementLink,
	getRentalContract,
	getContractLink,
	downloadOrderContractPDF,
	postAdminRentalAgreement,
	downloadContractPDF,
};

export default rentalContractsService;
