import React from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';

import { EuiSimpleFlyout } from '@equipmentshare/es-admin-eui-simple-components';

import AssetInfoDrawer from './asset-info-drawer.js';
import ngModule from '../../ng/ng-module.js';
import withGlobalProviders from '@components/with-global-providers';

export const AssetInfoDrawerComponent = ({ isVisible, toggleVisible, assetId }) => {
	if (!isVisible || !assetId) {
		return null;
	}

	return (
		<EuiSimpleFlyout onClose={toggleVisible} title="Asset Details" outsideClickCloses paddingSize="m">
			<AssetInfoDrawer assetId={assetId} />
		</EuiSimpleFlyout>
	);
};

AssetInfoDrawerComponent.propTypes = {
	assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isVisible: PropTypes.bool,
	toggleVisible: PropTypes.func,
};

ngModule.component(
	'reactAssetInfoDrawer',
	react2angular(
		withGlobalProviders(AssetInfoDrawerComponent),
		Object.keys(AssetInfoDrawer.propTypes).concat('forceUpdate', 'isVisible', 'toggleVisible')
	)
);
