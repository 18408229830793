export const markerColors = {
	choices: [
		'#4E4E4E',
		'#909194',
		'#CFCFD1',
		'#2AB6EA',
		'#094874',
		'#46294E',
		'#7E2779',
		'#ED3895',
		'#F47D2A',
		'#FCB22E',
		'#9DCC45',
		'#60AA45',
		'#3B6731',
	],
	default: '#4E4E4E',
};
