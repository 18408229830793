import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Breakpoints } from './breakpoints.js';

function arrayContains(arr = [], items = []) {
	if (!Array.isArray(items)) {
		return arr.includes(items);
	}

	return !items.find((item) => arr.includes(item));
}

export const ForBreakpoint = ({ children, breakpoint }) => {
	const breakpoints = useContext(Breakpoints);

	return arrayContains(breakpoints, breakpoint) ? children : null;
};

ForBreakpoint.propTypes = {
	children: PropTypes.node,
	breakpoint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default ForBreakpoint;
