import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const PopoverAnchorOverlay = ({ buttonElement, children, container }) => {
	useEffect(() => {
		const updatePosition = () => {
			if (buttonElement && container) {
				const buttonRect = buttonElement.getBoundingClientRect();
				container.style.position = 'absolute';
				container.style.zIndex = '9999';
				const centerX = buttonRect.left + buttonRect.width / 2;
				container.style.top = `${buttonRect.bottom + window.scrollY}px`;
				// Position left edge of popover at button center, then transform to center the popover
				container.style.left = `${centerX}px`;
				container.style.transform = 'translateX(-50%)';
			}
		};

		updatePosition();
		window.addEventListener('resize', updatePosition);
		document.addEventListener('scroll', updatePosition, true);

		return () => {
			window.removeEventListener('resize', updatePosition);
			document.removeEventListener('scroll', updatePosition, true);
		};
	}, [buttonElement, container]);

	return ReactDOM.createPortal(children, container);
};

export default PopoverAnchorOverlay;
