import angular from 'angular';
import template from './line-chart.html';
import './line-chart-ctrl.js';

angular.module("esNgComponents").component('lineChart', {
  template,
  controller: 'LineChartCtrl',
  bindings: {
    data: '<'
  }
});