import ngModule from '../../../ng/index.js';
import template from './transport-create-form.html';

ngModule.component('transportCreateForm', {
	template,
	controller: 'TransportCreateFormCtrl',
	bindings: {
		onSubmit: '<',
		order: '<',
	},
});
