import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./avatar.module.css";

export function Avatar ( { children, shape, size, onClick } ) {
  if ( size < 2 ) {
    size = 2;
  }

  let avatarSize = { "--avatar-size": `${size}em` };

  let avatarClasses = classNames( styles.avatar, styles[shape] );

  return (
    <div className={avatarClasses} onClick={onClick} style={avatarSize}>
      <div className={styles.innerContent}>
        {children}
      </div>
    </div>
  );
}

Avatar.propTypes = {
  /** child elements to render inside of the avatar */
  children: PropTypes.node.isRequired,
  /** function used when the avatar is clicked on */
  onClick: PropTypes.func,
  /** the shape of the avatar, must be 'circle' or 'square' */
  shape: PropTypes.oneOf( [ "square", "circle" ] ),
  /** the size of the avatar in 'em' */
  size: PropTypes.number,
};

Avatar.defaultProps = {
  onClick: () => {},
  shape: "circle",
  size: 2,
};

export default Avatar;
