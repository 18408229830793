import ngModule from '../../../ng/index.js';

function SimpleMapCtrl($scope, $element, simpleMapService) {
	const controller = this;

	controller.$onInit = () => {
		controller.data = {
			center: controller.center || null,
			points: (controller.point && [controller.point]) || controller.points || [],
		};

		setTimeout(() => {
			controller.map = simpleMapService.createMap(controller.data, $element[0].querySelector('#simpleMap'));
			controller.onMapCreated({ map: controller.map });
		});
	};

	controller.$onChanges = (changesObj) => {
		if (controller.map) {
			controller.data = {
				center: (changesObj.center && changesObj.center.currentValue) || controller.center || null,
				points:
					(changesObj.point && [changesObj.point.currentValue]) ||
					(changesObj.points && changesObj.points.currentValue) ||
					controller.points ||
					[],
			};

			simpleMapService.updateMap(controller.map, controller.data);
			controller.onMapUpdated({ map: controller.map });
		}
	};

	$scope.$on('simple-map.resize', () => {
		if (controller.map) {
			simpleMapService.triggerResize(controller.map);
			simpleMapService.centerMap(controller.map, controller.data);
		}
	});
}

ngModule.controller('SimpleMapCtrl', SimpleMapCtrl);
