import esApi from '@services/es-api.service';

export async function search(query, opts) {
	return await esApi.request(esApi.url`/rental-rates`, query, opts);
}

const rentalRatesService = {
	search,
};

export default rentalRatesService;
