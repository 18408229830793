import esApi from './es-api.service';

export async function fetch(branchId) {
	return await esApi.request(esApi.url`/branches/${branchId}`);
}

export async function fetchPublic() {
	return await esApi.request('/branches');
}

export async function search(query, opts) {
	return await esApi.request(esApi.url`/branches`, query, opts);
}

export async function fetchBranchRentalRates(branchId, query = {}) {
	return await esApi.request(esApi.url`/branches/${branchId}/rental-rates`, query);
}

export async function getBranchById(id, query = {}, opts = {}) {
	return await esApi.request(esApi.url`/branches/${id}`, query, opts);
}

const branchesService = {
	search,
	fetch,
	fetchPublic,
	fetchBranchRentalRates,
	getBranchById,
};

export default branchesService;
