import esApi from './es-api.service';

export function search(params, opts) {
	return esApi.request('/company-rental-rates', params, opts);
}

export function create(payload) {
	return esApi.request(
		'/company-rental-rates',
		{},
		{
			method: 'POST',
			body: payload,
		}
	);
}

export function update(id, payload) {
	return esApi.request(
		esApi.url`/company-rental-rates/${id}`,
		{},
		{
			method: 'PATCH',
			body: payload,
		}
	);
}

export async function updateMultiple(ids, payload) {
	return await Promise.all(
		ids.map(async (id) => {
			return await update(id, payload);
		})
	);
}

export async function getBulkUploadTemplate() {
	return await esApi.request(esApi.url`/company-rental-rates/bulk`);
}

export async function bulkUpload(payload) {
	return await esApi.request(esApi.url`/company-rental-rates/bulk`, null, { body: payload, method: 'POST' });
}

const companyRentalRatesService = {
	search,
	create,
	update,
	updateMultiple,
	getBulkUploadTemplate,
	bulkUpload,
};

export default companyRentalRatesService;
