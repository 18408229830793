import { makeXFields } from '@helpers/xFields';

export const equipmentClassXFields = makeXFields`{ category_id, equipment_class_id, name }`;
export const equipmentClassExportXFields = makeXFields`{ equipment_class_id, name, description }`;

export const companyXFields = makeXFields`{
	company_rental_rate_id,
	company {
		name
	},
	equipment_class {
		name
	},
	price_per_hour,
	price_per_day,
	price_per_week,
	price_per_month,
	percent_discount,
	created_by_user {
		first_name,
		last_name
	},
	date_created,
	date_voided,
	voided,
	voided_by_user {
		first_name,
		last_name
	},
	end_date
}`;

export const locationXFields = makeXFields`{
	company,
	location_rental_rate_id,
	location {
		city,
		company {
			name
		},
		nickname,
		state,
		street_1,
		street_2,
		zip_code
	},
	equipment_class {
		name
	},
	price_per_hour,
	price_per_day,
	price_per_week,
	price_per_month,
	percent_discount,
	created_by_user {
		first_name,
		last_name
	},
	date_created,
	date_voided,
	active,
	voided_by_user {
		first_name,
		last_name
	},
	end_date
}`;

export const branchXFields = makeXFields`{
	branch_rental_rate_id,
	branch {
		market_id,
		name
	},
	equipment_class {
		equipment_class_id,
		category {
			name
		},
		name
	},
	price_per_hour,
	price_per_day,
	price_per_week,
	price_per_month,
	percent_discount,
	rate_type_id,
	rate_type
}`;

const xFields = {
	companyXFields,
	locationXFields,
	branchXFields,
	equipmentClassXFields,
	equipmentClassExportXFields,
};

export default xFields;
