export const EQUIPMENT_TYPE_ID = 1;
export const VEHICLE_TYPE_ID = 2;

export const AssetTypeFields = {
	[EQUIPMENT_TYPE_ID]: [
		{
			name: 'hours',
			label: 'Hours',
			required: false,
			order: 1,
			type: 'number',
		},
		{
			name: 'description',
			label: 'Configuration/Options',
			required: false,
			order: 2,
			type: 'text',
		},
		{
			name: 'price_per_hour',
			label: 'Hourly Cost of Operating Equipment',
			required: false,
			order: 3,
			type: 'number',
		},
	],
	[VEHICLE_TYPE_ID]: [
		{
			name: 'driver_name',
			label: 'Driver Name',
			required: false,
			order: 1,
			type: 'text',
		},
		{
			name: 'vin',
			label: 'VIN',
			required: false,
			order: 2,
			type: 'text',
		},
		{
			name: 'odometer',
			label: 'Odometer',
			required: false,
			order: 3,
			type: 'number',
		},
	],
};

export default AssetTypeFields;
