import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

import { PopoverCreator } from '@equipmentshare/react-ui-components';

import { useFilter } from '../providers/filter-provider.js';

const DEBOUNCE_FILTER_STATE_DELAY = 250;

const FilterPopover = ({
	columnId,
	filterKey,
	filterMethod,
	filterComponent: FilterComponent,
	refFilterComponent,
	...props
}) => {
	const { setFilterMethod, setFilterKey, setFilterState, getFilterState, setFilterActive, getFilterActive } =
		useFilter();

	// update filter methods
	useEffect(() => {
		setFilterKey(columnId, filterKey);
		setFilterMethod(columnId, filterMethod);

		return () => {
			setFilterKey(columnId, null);
			setFilterMethod(columnId, null);
		};
	}, [columnId, filterKey, filterMethod, setFilterKey, setFilterMethod]);

	const debounceSetFilterState = useCallback(
		_debounce((...a) => setFilterState(...a), DEBOUNCE_FILTER_STATE_DELAY),
		[setFilterState]
	);
	const debounceSetFilterActive = useCallback(
		_debounce((...a) => setFilterActive(...a), DEBOUNCE_FILTER_STATE_DELAY),
		[setFilterActive]
	);

	const [localState, setLocalState] = useState(getFilterState(columnId));
	const [localActive, setLocalActive] = useState(getFilterActive(columnId));

	const handleSetFilterState = useCallback(
		(state) => {
			setLocalState(state);
			debounceSetFilterState(columnId, state);
		},
		[columnId, setLocalState, debounceSetFilterState]
	);
	const handleSetFilterActive = useCallback(
		(active) => {
			setLocalActive(active);
			debounceSetFilterActive(columnId, active);
		},
		[columnId, setLocalActive, debounceSetFilterActive]
	);

	return FilterComponent ? (
		<PopoverCreator {...props}>
			<FilterComponent
				ref={refFilterComponent}
				state={localState}
				active={localActive}
				setState={handleSetFilterState}
				setActive={handleSetFilterActive}
			/>
		</PopoverCreator>
	) : null;
};

FilterPopover.propTypes = {
	columnId: PropTypes.string,
	filterKey: PropTypes.string,
	filterMethod: PropTypes.func,
	filterComponent: PropTypes.any,
	refFilterComponent: PropTypes.any,
};

export default FilterPopover;
