import Authentication from "@equipmentshare/kernel-legacy/src/Authentication.js";

angular.module('esAuthentication').factory('User', function ($window, ipCookie) {

  var service = this;

  var attributesKey = 'es_user_attributes';
  var marketIdKey = 'es_market_id';
  var organizationObjKey = 'es_user_organization_information';
  var viewSettingsKey = 'es_user_view_settings'
  var User = {
  };

  function store(key, value) {
    $window.localStorage.setItem(key, value);
  }

  function load(key) {
    return $window.localStorage.getItem(key);
  }

  service.setUserId = function (userId) {
    Authentication.setUserId( userId );
  };

  service.getUserId = function () {
    return Authentication.getUserId();
  };

  service.hasUserId = function() {
    var userId = this.getUserId();
    return userId !== undefined && userId !== '';
  };

  service.setApiToken = function (token) { Authentication.setToken( token ) };

  service.getApiToken = function () {
      User.apiToken = Authentication.getToken();

      return User.apiToken;
  };

  service.hasApiToken = function () {
    return angular.isString(this.getApiToken()) && this.getApiToken().length > 0;
  };

  service.getJWTToken = function () {
    User.jwtToken = Authentication.getJWT();

    return User.jwtToken;
  };

  service.hasJWTToken = function () {
    let jwtToken = this.getJWTToken();

    return angular.isString(jwtToken) && jwtToken.length > 0;
  };

  service.hasToken = function () {

    return !!(this.hasApiToken() || this.hasJWTToken());
  }

  service.setRoles = function (roles) {
    Authentication.setRoles( roles );
  };

  service.getRoles = function () {
    User.roles = Authentication.getRoles();

    return User.roles;
  };

  service.hasRole = function (roles) {
    var matchedRoles = service.getRoles().filter(function (role) {
      return roles.indexOf(role) > -1;
    });

    return matchedRoles.length > 0;
  };

  service.setCompanyId = function (companyId) {
    Authentication.setCompanyId( companyId );
  };

  service.getCompanyId = function() {
    return Authentication.getCompanyId();
  };

  service.setOrganizationInformation = function (organizationObjs) {
    store(organizationObjKey, JSON.stringify(organizationObjs));
  };

  service.getOrganizationInformation = function() {
    return JSON.parse(load(organizationObjKey));
  };

  service.setMarketIdKey = function (marketId) {
    store(marketIdKey, JSON.stringify(marketId));
  };

  service.getMarketIdKey = function() {
    return JSON.parse(load(marketIdKey));
  };

  service.hasMarketIdKey = function() {
    return angular.isString(this.getMarketIdKey()) && this.getMarketIdKey().length > 0;
  };

  service.setViewSettings = function (viewSettings) {
    store(viewSettingsKey, JSON.stringify(viewSettings));
  };

  service.getViewSettings = function() {
    return JSON.parse(load(viewSettingsKey));
  };

  function _attributes() {
    if(!User._attributes) {
      User._attributes = JSON.parse(load( attributesKey ) || "{}");
    }
    return User._attributes;
  }

  /**
   * Attributes are arbitrary metadata associated with the current user, like which market they last viewed in some
   * pages. This can help tailor the GUI to how the user uses it.
   * @return the value, or `null` if no value is set and no defaultValue was specified
   */
  service.getAttribute = function( key, defaultValue ) {
    var value = _attributes()[key];
    return value !== undefined ? value : (defaultValue || null);
  };

  /** Store an attribute, or clear it by setting its value to `null`. */
  service.setAttribute = function( key, value ) {
    var attrs = _attributes();
    if( value === null || value === undefined ) {
      delete attrs[key];
    } else {
      attrs[key] = value;
    }
    store(attributesKey, JSON.stringify( attrs ));
  };

  return {
    setApiToken: service.setApiToken,
    getApiToken: service.getApiToken,
    hasApiToken: service.hasApiToken,
    getJWTToken: service.getJWTToken,
    hasJWTToken: service.hasJWTToken,
    hasToken: service.hasToken,
    setRoles: service.setRoles,
    getRoles: service.getRoles,
    hasRole: service.hasRole,
    setUserId: service.setUserId,
    getUserId: service.getUserId,
    hasUserId: service.hasUserId,
    getAttribute: service.getAttribute,
    setAttribute: service.setAttribute,
    setCompanyId: service.setCompanyId,
    getCompanyId: service.getCompanyId,
    setMarketIdKey: service.setMarketIdKey,
    getMarketIdKey: service.getMarketIdKey,
    hasMarketIdKey: service.hasMarketIdKey,
    setViewSettings: service.setViewSettings,
    getViewSettings: service.getViewSettings,
    setOrganizationInformation: service.setOrganizationInformation,
    getOrganizationInformation: service.getOrganizationInformation,
    logout: function () {
      Authentication.logout();
    }
  };
});
