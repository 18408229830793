import _each from "lodash/each";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";

import AssetJobs from "@equipmentshare/kernel-legacy/src/es/jobs/Assets.js";

angular.module( "esApiClient" ).factory( "esVehicleService", esVehicleService );

export default function esVehicleService ( esApiFactoryV2, $q ) {
  function setVehicleCounts( vehicles, categories ) {
    _each( categories, ( category ) => {
      var foundVehicles = _filter( vehicles, ( vehicle ) => {
        return category.vehicle_group_id === vehicle.vehicle_group_id;
      } );

      category._vehicleCount = foundVehicles ? foundVehicles.length : 0;
    } );
  }

  function setPhotoUrls( vehicles ) {
    _each( vehicles, ( vehicle ) => {
      vehicle._photoUrl = esApiFactoryV2.getPhotoUrl( vehicle, false, true );
    } );
  }

  function setHasTrackers( vehicles ) {
    _each( vehicles, ( vehicle ) => {
      vehicle._hasTracker = !_isEmpty( vehicle.current_status );
    } );
  }

  function setHasDiagnosticCodes( vehicles ) {
    _each( vehicles, ( vehicle ) => {
      vehicle._hasDiagnosticCodes = vehicle.diagnostic_codes && vehicle.diagnostic_codes.length > 0;
    } );
  }

  function setIsFilterActive( vehicles, isActive ) {
    _each( vehicles, ( vehicle ) => {
      vehicle._isFilterActive = isActive;
    } );
  }

  function getVehicle ( vehicleId, forceReload ) {
    var that = this;
    var deferred = $q.defer();

    if ( that.vehicle && that.vehicle.vehicle_id === vehicleId && !forceReload ) {
      deferred.resolve( that.vehicle );
    } else {
      AssetJobs
        .fetchAsset( vehicleId )
        .subscribe( ( event ) => {
          if ( event.name === "asset" ) {
            let vehicle = event.asset;

            that.vehicle = vehicle;
            that.vehicle._hasDiagnosticCodes = that.vehicle.diagnostic_codes && that.vehicle.diagnostic_codes.length > 0;
            that.vehicle._hasTracker = !_isEmpty( that.vehicle.current_status );
            deferred.resolve( that.vehicle );
          }
        } );
    }

    return deferred.promise;
  }

  function getUsersVehicleGroups( userId, forceReload ) {
    var that = this;
    var deferred = $q.defer();

    if ( that.vehicleCategories && !forceReload ) {
      deferred.resolve( that.vehicleCategories );
    } else {
      esApiFactoryV2.getUsersVehicleGroups( userId )
        .then( ( response ) => {
          setVehicleCounts( that.vehicles, response.data );
          that.vehicleCategories = response.data;
          deferred.resolve( true );
        } );
    }

    return deferred.promise;
  }

  function getUsersVehicles ( userId, forceReload ) {
    var that = this;
    var deferred = $q.defer();

    if ( that.vehicles && !forceReload ) {
      deferred.resolve( that.vehicles );
    } else {
      esApiFactoryV2.getUsersVehicles( userId )
        .then( ( response ) => {
          that.vehicles = response.data;
          setPhotoUrls( that.vehicles );
          setHasTrackers( that.vehicles );
          setVehicleCounts( that.vehicles, that.vehicleCategories );
          setHasDiagnosticCodes( that.vehicles );
          setIsFilterActive( that.vehicles, true );
          deferred.resolve( that.vehicles );
        } );
    }

    return deferred.promise;
  }

  function patchVehicle ( vehicleId, vehicleData ) {
    var deferred = $q.defer();

    AssetJobs
      .updateAsset( vehicleId, vehicleData )
      .subscribe( ( event ) => {
        if ( event.name === "updated" ) {
          deferred.resolve();
        }

        if ( event.name === "error" ) {
          deferred.reject( event.error );
        }
      } );

    return deferred.promise;
  }

  function uploadPhoto ( vehicleId, formData ) {
    return esApiFactoryV2.postPhoto( formData );
  }

  function postVehicleGroup ( groupName ) {
    return esApiFactoryV2.postVehicleGroup( groupName );
  }

  return {
    vehicles: undefined,
    vehicle: undefined,
    filtering: false,
    filteredVehicles: undefined,
    vehicleCategories: undefined,
    getVehicle: getVehicle,
    getUsersVehicleGroups: getUsersVehicleGroups,
    getUsersVehicles: getUsersVehicles,
    patchVehicle: patchVehicle,
    uploadPhoto: uploadPhoto,
    postVehicleGroup: postVehicleGroup
  };
}
