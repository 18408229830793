import React, { useState, useEffect, useCallback, Fragment } from 'react';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import styled from 'styled-components';

import {
	EuiFieldText,
	EuiForm,
	EuiFormRow,
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiCallOut,
	EuiCheckbox,
	EuiIcon,
} from '@equipmentshare/ds2';
import { InformationFillIcon } from '@equipmentshare/ds2-icons';
import { zeroPad } from '@equipmentshare/admin-hooks';

import googleGeocoderService from '@services/google-geocoder.service';
import statesService from '@services/states.service';
import { isValidFiveDigitZipCode } from '@helpers/utilities';

import LatLngModificationMap from '../../../lat-lng-modification-map/lat-lng-modification-map';
import StatePicker from '@components/state-picker/state-picker';
import NearbyLocationsWarning from '../nearby-locations-warning/nearby-locations-warning';
import AdvancedAddressSearch from '../advanced-address-search/advanced-address-search';
import { Location, State } from '../common-types';

const Form = styled(EuiForm)`
	& > * {
		margin-bottom: 1em;
	}
`;

const Icon = styled(EuiIcon)`
	margin-right: 0.4em;
`;

const CityStateZipContainer = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	background: #f7f9fa;
	padding: 8px 0;

	& > * {
		margin: 0 12px;
	}

	& > *:last-child {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		color: #019af9;
		cursor: pointer;
	}
`;

const getMatchingStateId = (stateName: string | undefined, states: State[]) =>
	_get(
		states.find((state) => state.name.toLowerCase() === stateName?.toLocaleLowerCase()),
		'state_id'
	);

const getStateName = (id: number | undefined, states: State[]) =>
	_get(
		states.find((state) => state.state_id === id),
		'name'
	);

type GeoFence = {
	geofence_id: number;
	name: string;
	location?: Location;
};

type Center = {
	lng?: number;
	lat?: number;
};

type SuggestedLocation = {
	state?: State | string;
	city?: string;
	street_1: string;
	zip_code?: string;
	longitude?: number;
	latitude?: number;
};

export type StreetAddressFormProps = {
	onSubmit: (location: Location) => void;
	onCancel: () => void;
	companyId: number;
	showGeofenceOption?: boolean;
	initialLocationName?: string;
	submitLabel: string;
	onSelectExistingGeofence: (geofence: GeoFence) => void;
	geofenceDefaultChecked?: boolean;
};

export const StreetAddressForm: React.VFC<StreetAddressFormProps> = ({
	onSubmit,
	onCancel,
	submitLabel,
	companyId,
	showGeofenceOption,
	initialLocationName,
	onSelectExistingGeofence,
	geofenceDefaultChecked = false,
}) => {
	const [center, setCenter] = useState<Center | undefined>(undefined);
	const [states, setStates] = useState<State[]>([]);
	const [street, setStreet] = useState<string>('');
	const [locationName, setLocationName] = useState<string | undefined>(initialLocationName);
	const [modifyingCityStateZip, setModifyingCityStateZip] = useState<boolean>(false);
	const [city, setCity] = useState<string | undefined>(undefined);
	const [stateId, setStateId] = useState<number | undefined>(undefined);
	const [zipCode, setZipCode] = useState<string | undefined>(undefined);
	const [addGeofence, setAddGeofence] = useState(geofenceDefaultChecked);

	const currentSelectedStateName = getStateName(stateId, states);

	const streetUpdater = (e: React.ChangeEvent<HTMLInputElement>) => setStreet(e.target.value);
	const cityUpdater = (e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value);
	const zipCodeUpdater = (e: React.ChangeEvent<HTMLInputElement>) => setZipCode(e.target.value);
	const locationNameUpdater = (e: React.ChangeEvent<HTMLInputElement>) => setLocationName(e.target.value);
	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => setAddGeofence(e.target.checked);

	useEffect(() => {
		statesService.fetchAll().then(setStates);
	}, []);

	const handleSelectSuggestion = (location: SuggestedLocation) => {
		setStreet(location.street_1);
		setCity(location.city);
		setZipCode(location.zip_code);
		setModifyingCityStateZip(false);

		if (location.state === 'New Zealand') {
			setStateId(60);
		} else {
			if (typeof location.state !== 'string') {
				setStateId(getMatchingStateId(location.state?.name, states));
			}
		}

		const latLng = { lng: location.longitude, lat: location.latitude };
		setCenter(latLng);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const state = states.find((s) => s.state_id === stateId);
		const newLocation = {
			nickname: locationName,
			state_id: stateId,
			state,
			city,
			geofence: showGeofenceOption ? addGeofence : undefined,
			street_1: street,
			zip_code: zipCode,
			needs_review: false,
			latitude: center && center.lat,
			longitude: center && center.lng,
			company_id: companyId,
		};

		if (onSubmit) {
			onSubmit(newLocation);
		}
	};

	const handleMapChange = useCallback(
		_debounce(async (latlng) => {
			setCenter(latlng);

			const location = await googleGeocoderService.getAddressFromLatLng(latlng);

			setStreet(location.street_1);
			setCity(location.city);
			setZipCode(location.zip_code);
			setStateId(getMatchingStateId(location.state.name, states));
			setModifyingCityStateZip(false);
		}, 500),
		[setCenter, setStreet, setCity, setZipCode, states, setStateId]
	);

	const cityStateZipFragment = modifyingCityStateZip ? (
		<Fragment>
			<EuiFormRow fullWidth label="City">
				<EuiFieldText placeholder="City" value={city || ''} onChange={cityUpdater} required fullWidth />
			</EuiFormRow>
			<EuiFormRow fullWidth>
				<EuiFlexGroup gutterSize="s" alignItems="center">
					<EuiFlexItem>
						<StatePicker label="State" selectedStateId={stateId} onChange={setStateId} required />
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFormRow label="Zip Code">
							<EuiFieldText
								type="text"
								placeholder="12345"
								value={zipCode || ''}
								onChange={zipCodeUpdater}
								required
								isInvalid={!isValidFiveDigitZipCode(zipCode || '')}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFormRow>
			<EuiFormRow fullWidth>
				<EuiCallOut color="warning">
					<p>Changing address details derived from geo-coordinates could cause address to be invalid</p>
				</EuiCallOut>
			</EuiFormRow>
		</Fragment>
	) : (
		<Fragment>
			<CityStateZipContainer onClick={() => setModifyingCityStateZip(true)}>
				<div>
					{city}
					<br />
					{currentSelectedStateName}
					{zipCode && `, ${zeroPad(zipCode, 5)}`}
				</div>
				<div>Modify</div>
			</CityStateZipContainer>
		</Fragment>
	);

	const otherInputs = (
		<Fragment>
			<EuiFormRow fullWidth label="Name">
				<EuiFieldText value={locationName || ''} onChange={locationNameUpdater} autoFocus required fullWidth />
			</EuiFormRow>
			<EuiFormRow fullWidth label="Street">
				<EuiFieldText placeholder="Street" value={street} onChange={streetUpdater} required fullWidth />
			</EuiFormRow>
			{cityStateZipFragment}
			{center && (
				<NearbyLocationsWarning
					companyId={companyId}
					latitude={center.lat}
					longitude={center.lng}
					onSelectExistingGeofence={onSelectExistingGeofence}
				/>
			)}
			{showGeofenceOption && (
				<EuiCheckbox
					id="addGeofenceCheckbox"
					label="Create geofence around location"
					checked={addGeofence}
					onChange={handleCheckboxChange}
				/>
			)}
		</Fragment>
	);

	return (
		<Form component="form" onSubmit={handleSubmit}>
			<EuiFormRow fullWidth label="Search By">
				<AdvancedAddressSearch onSelectAddress={handleSelectSuggestion} />
			</EuiFormRow>

			{center && (
				<Fragment>
					<LatLngModificationMap center={center} onChange={handleMapChange} />

					<div>
						<Icon type={InformationFillIcon} />
						<span>Deliveries and tax calculation are based on pin location. Adjust pin to exact location.</span>
					</div>
				</Fragment>
			)}

			{center && city && stateId ? otherInputs : null}

			<EuiFlexGroup gutterSize="s">
				<EuiFlexItem grow={false}>
					<EuiButton fill type="submit" isDisabled={!city || !stateId}>
						{submitLabel}
					</EuiButton>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton color="text" onClick={onCancel}>
						Cancel
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Form>
	);
};

export default StreetAddressForm;
