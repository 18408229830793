import angular from 'angular';
import ngModule from '../../../../ng/index.js';
import moment from 'moment-timezone';
import template from './datetime-picker.html';

import dialogTemplate from './datetime-picker-dialog.html';

function datetimePicker() {
	return {
		template,
		controller: 'DatetimePickerCtrl as ctrl',
		scope: {
			ngModel: '=',
			ngChange: '=',
			label: '=',
			format: '=?',
		},
	};
}

function DatetimePickerCtrl($scope, $mdDialog, $mdMedia, $timeout, $filter) {
	$scope.openDialog = function (ev) {
		const dialog = $mdDialog.show({
			template: dialogTemplate,
			parent: angular.element(document.body),
			targetEvent: ev,
			scope: $scope.$new(),
			clickOutsideToClose: true,
			fullscreen: $mdMedia('sm') || $mdMedia('xs'),
		});
	};

	$scope.closeDialog = function () {
		$mdDialog.hide($scope.dialog);
	};

	$scope.$watch('ngModel', () => {
		$scope.formattedDate = $filter('localeShort')(moment($scope.ngModel).toDate());
	});

	$scope.save = function () {
		// `this` is the child scope we create when we create the dialog,
		// so this transfers the updated date from the dialog scope to ours.
		if (!moment($scope.ngModel).isSame(moment(this.ngModel))) {
			$scope.ngModel = moment(this.ngModel);
			// This change handler needs to run after the `ngModel` assignment
			// above has been digested, otherwise listeners will see the old
			// value if they use angular bindings to access it
			if ($scope.ngChange) {
				$timeout($scope.ngChange);
			}
		}
		$scope.closeDialog();
	};
}

ngModule.directive('datetimePicker', datetimePicker).controller('DatetimePickerCtrl', DatetimePickerCtrl);
