const parsedOverrideConfig: Record<string, string> = {};
const configTag = document.head.querySelector('meta[name=config]');
if (configTag) {
	try {
		const entries = String(configTag.getAttribute('content')).split(',');

		entries
			.map((v) => v.split('='))
			.forEach(([key, value]) => {
				parsedOverrideConfig[key] = value;
				console.log(`loaded config ${key}=${value}`);
			});
	} catch (e) {
		console.error('Failed to load meta config', e);
	}
}

function getOverrideEnvValue(key: string, defaultValue?: string) {
	return parsedOverrideConfig[key] || defaultValue;
}

export const NODE_ENV = process.env.NODE_ENV;
export const IS_DEV = NODE_ENV === 'development';
export const IS_PROD = NODE_ENV === 'production';

export const API_URL = getOverrideEnvValue('API_URL', process.env.API_URL);
export const CDN_URL = getOverrideEnvValue('CDN_URL', process.env.CDN_URL);
export const PLACEHOLDER_IMAGE_URL = process.env.PLACEHOLDER_IMAGE_URL;
export const TOOLS_APP_URL = getOverrideEnvValue('TOOLS_APP_URL', process.env.TOOLS_APP_URL);
export const LOOKER_URL = process.env.LOOKER_URL;
export const ROLLBAR_TOKEN = getOverrideEnvValue('ROLLBAR_TOKEN', process.env.ROLLBAR_TOKEN);
export const ROLLBAR_ENV = getOverrideEnvValue('ROLLBAR_ENV', process.env.ROLLBAR_ENV);
export const ROLLBAR_ENABLED = getOverrideEnvValue('ROLLBAR_ENABLED', process.env.ROLLBAR_ENABLED) === 'true';
export const EMBEDS_DOMAIN = getOverrideEnvValue('EMBEDS_DOMAIN', process.env.EMBEDS_DOMAIN);
export const CONTRACTS_DOMAIN = getOverrideEnvValue('CONTRACTS_DOMAIN', process.env.CONTRACTS_DOMAIN);
export const ESTRACK_DOMAIN = getOverrideEnvValue('ESTRACK_DOMAIN', process.env.ESTRACK_DOMAIN);
export const TRANSFERS_DOMAIN = getOverrideEnvValue('TRANSFERS_DOMAIN', process.env.TRANSFERS_DOMAIN);
export const GOOGLE_MAPS_TOKEN = getOverrideEnvValue('GOOGLE_MAPS_TOKEN', process.env.GOOGLE_MAPS_TOKEN);
export const INTERCOM_APP_ID = getOverrideEnvValue('INTERCOM_APP_ID', process.env.INTERCOM_APP_ID);
export const HEAPER_APP_ID = getOverrideEnvValue('HEAPER_APP_ID', process.env.HEAPER_APP_ID);
export const HEAPER_ENV = getOverrideEnvValue('HEAPER_ENV', process.env.HEAPER_ENV);
export const LAUNCH_DARKLY_CLIENT_ID = getOverrideEnvValue(
	'LAUNCH_DARKLY_CLIENT_ID',
	process.env.LAUNCH_DARKLY_CLIENT_ID
);
export const PROJECT_TYPE_URL = getOverrideEnvValue('PROJECT_TYPE_URL', process.env.PROJECT_TYPE_URL);

// dynamic config
export const IS_USING_STAGING_API = String(API_URL).includes('://staging-api.equipmentshare.com');

export const AUTH0_DISABLED = process.env.AUTH0_DISABLED === 'true' || (window.parent && window.parent !== window);
export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN || null;
export const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID || null;
export const AUTH0_API_AUDIENCE = process.env.AUTH0_API_AUDIENCE || null;
export const AUTH0_LOGIN_METHOD = process.env.AUTH0_LOGIN_METHOD || 'redirect';

export const DD_APP_ID = getOverrideEnvValue('DD_APP_ID', process.env.DD_APP_ID || '');
export const DD_TOKEN = getOverrideEnvValue('DD_TOKEN', process.env.DD_TOKEN || '');
export const DD_ENV = getOverrideEnvValue('DD_ENV', process.env.DD_ENV || '');

export const APP_VERSION = getOverrideEnvValue('APP_VERSION', process.env.SEMCOUNTER_NEW_VERSION || '');
