import ngModule from '../../../ng/index.js';

import '@components/company-location-picker/company-location-picker-component.js';
import template from './rental-create-form.html';

ngModule.component('rentalCreateForm', {
	template,
	controller: 'RentalCreateFormCtrl',
	bindings: {
		onSubmit: '<',
		order: '<',
	},
});
