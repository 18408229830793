import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EuiFieldSearch } from '@equipmentshare/ds2';

export const SearchFilter = React.forwardRef(({ state, setState, setActive }, ref) => {
	const handleInputChange = useCallback(
		(event) => {
			setState({ value: event.target.value });
			setActive(!!event.target.value);
		},
		[setState]
	);

	const styles = { minWidth: 200, padding: 10 };

	return (
		<div style={styles} ref={ref}>
			<EuiFieldSearch type="search" value={state.value || ''} autoFocus onChange={handleInputChange} />
		</div>
	);
});

export const filterMethod = (entry, { value }, key) => {
	return String(_get(entry, key, ''))
		.toLowerCase()
		.includes(String(value || '').toLowerCase());
};

SearchFilter.displayName = 'SearchFilter';
SearchFilter.filterMethod = filterMethod;

SearchFilter.propTypes = {
	state: PropTypes.shape({
		value: PropTypes.string,
	}).isRequired,
	setState: PropTypes.func.isRequired,
	setActive: PropTypes.func.isRequired,
};

export default SearchFilter;
