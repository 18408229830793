import { LineItemTypeId } from '@equipmentshare/billing-common/types/LineItemType';

// TODO: Would be good to remove this entirely and use LineItemTypeId directly instead
export const LINE_ITEM_TYPES: Record<string, number> = {
	ASSET_RENTAL: LineItemTypeId.Rental,
	SERVICE_EQUIPMENT_PARTS: LineItemTypeId.ServiceEquipmentParts,
	SALES_TAX: LineItemTypeId.SalesTaxPayable,
	WARRANTY_PARTS_REVENUE: LineItemTypeId.WarrantyPartsRevenue,
	DAMAGE_PARTS: LineItemTypeId.DamageParts,
	OVERPAYMENT: LineItemTypeId.OverpaymentDeferredRevenue,
	PREPAYMENT: LineItemTypeId.Prepayment,
	RPP_CHARGE: LineItemTypeId.RentalProtectionPlan,
	TERP_CHARGE: LineItemTypeId.TERP,
	ENVIRONMENTAL_FEE: LineItemTypeId.EnvironmentalFee,
	NON_SERIALIZED_RENTAL: LineItemTypeId.NonSerializedRentalCharge,
	PARTS_RETAIL_SALE: LineItemTypeId.PartsRetailSale,
	NEW_FLEET_EQUIPMENT_SALES: LineItemTypeId.NewFleetEquipmentSales,
	NEW_DEALERSHIP_EQUIPMENT_SALES: LineItemTypeId.NewDealershipEquipmentSales,
	USED_FLEET_EQUIPMENT_SALES: LineItemTypeId.UsedFleetEquipmentSales,
	RPO_EQUIPMENT_SALES: LineItemTypeId.RPOEquipmentSales,
	T3_4_DEMO_DISCOUNT: LineItemTypeId.T3_4DemoDiscount,
	ADDITIONAL_HOURLY_USAGE: LineItemTypeId.AdditionalHourlyUsage,
	COLORADO_RETAIL_DELIVERY_FEE: LineItemTypeId.CORetailDeliveryFee,
};

export const PARTS_LINE_ITEM_TYPES: readonly number[] = [
	LineItemTypeId.ServiceEquipmentParts,
	LineItemTypeId.DamageParts,
	LineItemTypeId.WarrantyPartsRevenue,
	LineItemTypeId.PartsRetailSale,
	LineItemTypeId.PartsRetailSaleInStoreLT10k,
];

export const RENTAL_LINE_ITEM_TYPES: readonly number[] = [
	LineItemTypeId.Rental,
	LineItemTypeId.NonSerializedRentalCharge,
];

export const EQUIPMENT_SALES_LINE_ITEM_TYPES: readonly number[] = [
	LineItemTypeId.NewFleetEquipmentSales,
	LineItemTypeId.NewDealershipEquipmentSales,
	LineItemTypeId.UsedFleetEquipmentSales,
	LineItemTypeId.RPOEquipmentSales,
	LineItemTypeId.LSDInvoice,
	LineItemTypeId.ReMarketingAgreementEquipmentSale,
	LineItemTypeId.RetailToRentalDealershipEquipmentSale,
	LineItemTypeId.LSDOwnEquipmentSales,
	LineItemTypeId.OwnEquipmentSales,
	LineItemTypeId.NewDealershipEquipmentSalesInStoreLT10k,
	LineItemTypeId.UsedDealershipEquipmentSalesInStoreLT10k,
	LineItemTypeId.NewFleetEquipmentSalesInStoreLT10k,
	LineItemTypeId.UsedFleetEquipmentSalesInStoreLT10k,
	LineItemTypeId.NewAttachmentSalesInStoreLT10k,
	LineItemTypeId.UsedAttachmentSalesInStoreLT10k,
];

export const RENTAL_RELATED_LINE_ITEM_TYPES: readonly number[] = [
	LineItemTypeId.RentalProtectionPlan,
	LineItemTypeId.TERP,
	LineItemTypeId.EnvironmentalFee,
];
