import React from "react";
import PropTypes from "prop-types";

import Icon from "../icon/icon.js";

import breadcrumbStyles from "./breadcrumbs.module.css";

export function insertComponent( children, Component ){
  return React.Children.toArray( children )
    .reduce( ( newChildren, child, i, childrenArray ) => {
      let key = "level-" + i;

      newChildren.push( <span key={key}>{child}</span> );

      if( childrenArray.length > 1 && i !== childrenArray.length - 1 ){
        let divider = Component
          ? ( <Component key={key + "-divider"} /> )
          : ( <Icon className={breadcrumbStyles.divider} key={key + "-divider"} name="fasChevronRight" /> );

        newChildren.push( divider );
      }

      return newChildren;
    }, [] );
}

const Breadcrumbs = ( { children, dividerComponent, ...props } ) => {
  return (
    <div className={breadcrumbStyles.breadcrumbs} {...props}>
      {insertComponent( children, dividerComponent )}
    </div>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.any.isRequired,
  dividerComponent: PropTypes.func
};

Breadcrumbs.defaultProps = {
  dividerComponent: null
};

export default Breadcrumbs;
