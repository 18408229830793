import ngModule from '../../../../ng/index.js';
import template from './service-record.html';

ngModule
	.component('serviceRecord', {
		template,
		controller: 'ServiceRecordCtrl',
		bindings: {
			serviceRecord: '<',
		},
	})
	.controller('ServiceRecordCtrl', function ServiceRecordCtrl($scope) {
		const ctrl = this;

		ctrl.$onChanges = () => {
			$scope.record = ctrl.serviceRecord;
		};

		$scope.toggleEditing = (state = !$scope.editing) => {
			$scope.editing = state;

			if (state) {
				$scope.drawerOpen = true;
			}
		};

		$scope.toggleDrawer = (state = !$scope.drawerOpen) => {
			$scope.drawerOpen = state;

			if (!state) {
				$scope.editing = false;
			}
		};
	});
