import React from "react";
import PropTypes from "prop-types";

import Tooltip from "../../../tooltip/tooltip.js";

// TODO: uncomment when es-track can load images
// import keypadIcon from "../icon-images/keypad-icon.svg";

const AssetKeypadIcon = ( { tooltipDirection } ) => (
  <Tooltip direction={tooltipDirection} text="Keypad Assigned">
    <img src="images/keypad-icon.svg" />
  </Tooltip>
);

AssetKeypadIcon.propTypes = {
  tooltipDirection: PropTypes.string,
};
AssetKeypadIcon.defaultProps = {
  tooltipDirection: "right"
};

export default AssetKeypadIcon;
