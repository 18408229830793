import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import tabsStyles from "./tabs.module.css";

class TabMenuItem extends PureComponent {
  constructor( ...args ){
    super( ...args );

    this.handleClick = this.handleClick.bind( this );
  }

  componentDidMount() {
    this.checkFocus();
  }

  componentDidUpdate() {
    this.checkFocus();
  }

  checkFocus() {
    if ( this.props.focus ) {
      this.node.focus();
    }
  }

  handleClick( event ){
    this.context.tabsHandleClick( this.props.id, event );
  }

  render(){
    const { id, selected, disabled, children } = this.props;
    const ref = ( node ) => this.node = node;
    let tabClassName = classNames(
      tabsStyles.tab,
      {
        [tabsStyles.verticalTab]: this.context.orientation === "vertical",
        [tabsStyles.horizontalTab]: this.context.orientation === "horizontal",
        [tabsStyles.selected]: selected,
        [tabsStyles.disabled]: disabled,
      } );

    return(
      <li
        aria-controls={id + "__panel"}
        aria-disabled={disabled ? "true" : "false"}
        aria-selected={selected ? "true" : "false"}
        className={tabClassName}
        id={id + "__tab"}
        onClick={this.handleClick}
        onKeyDown={this.context.tabsHandleKeyDown}
        ref={ref}
        role="tab"
        tabIndex={selected ? "0" : null}
      >
        {children}
      </li>
    );
  }
}

TabMenuItem.contextTypes = {
  orientation: PropTypes.string,
  tabsHandleClick: PropTypes.func,
  tabsHandleKeyDown: PropTypes.func
};

TabMenuItem.propTypes = {
  children: PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.node ),
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ] ),
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  id: PropTypes.string,
  selected: PropTypes.bool
};

TabMenuItem.defaultProps = {
  id: null,
  selected: false,
  disabled: false,
  children: null,
  focus: false
};

export default TabMenuItem;
