const AngularBridge = {
  getAngularService( serviceName ){
    if( !window.angular ){
      return null;
    }
 
    let injector = angular.element( document.body ).injector();

    return injector && injector.get( serviceName );
  },

  fetchServiceVariable( serviceName, methodName ){
    let service = this.getAngularService( serviceName );

    return service && service[ methodName ];
  },

  updateServiceVariable( serviceName, methodName, assignment ){
    let service = this.getAngularService( serviceName );

    if( service ){
      return service[ methodName ] = assignment;
    }
  },

  callServiceMethod( serviceName, methodName, ...params ){
    let service = this.getAngularService( serviceName );

    return service && service[ methodName ] && service[ methodName ]( ...params );
  },

  updateAngularService( serviceName, methodName, ...params ){
    this.getAngularService( "$timeout" )( () => {
      this.callServiceMethod( serviceName, methodName, ...params );
    } );
  }
};

export default AngularBridge;