import ngModule from '../../ng/ng-module';
import { react2angular } from 'react2angular';
import LocationDisplay from './location-display';
import withGlobalProviders from '@components/with-global-providers';

ngModule.component(
	'reactLocationDisplay',
	react2angular(withGlobalProviders(LocationDisplay), [
		'className',
		'location',
		'showNickname',
		'inline',
		'forceUpdate',
	])
);
