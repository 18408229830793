import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import formControlStyles from "../form-control.module.css";
import textAreaStyles from "./textarea.module.css";

let inputId = 0;

export default class TextArea extends PureComponent {
  UNSAFE_componentWillMount() {
    this.generatedId = `textarea-${inputId++}`;
  }
  isRequired() {
    return ( this.props.required && <span className={formControlStyles.required}>&nbsp;(required)</span> );
  }
  render() {
    const {
      autocomplete,
      autofocus,
      className,
      cols,
      iconClickHandler,
      iconName,
      id,
      label,
      name,
      onChange,
      placeholder,
      required,
      resize,
      rows,
      value,
      wrap,
      ...props
    } = this.props;

    let styles = {
      resize,
      whiteSpace: wrap ? "pre-wrap" : "pre",
    };

    return (
      <div className={classNames( formControlStyles.control, textAreaStyles.textarea, className )}>
        {label &&
          <label htmlFor={id || this.generatedId}>{ label }{ this.isRequired() }</label>
        }
        {
          iconName &&
          <span aria-hidden="true" className={classNames( textAreaStyles.icon, { [formControlStyles.hasAction]: iconClickHandler } )} onClick={iconClickHandler}>
            <i className={iconName} />
          </span>
        }
        <div className={formControlStyles.inputContainer}>
          <textarea
            aria-labelledby={id || this.generatedId}
            autoComplete={autocomplete}
            autoFocus={autofocus}
            className={formControlStyles.input}
            cols={cols}
            id={id || this.generatedId}
            name={name || id}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            rows={rows}
            style={styles}
            value={value}
            {...props}
          />
        </div>
      </div>
    );
  }
}

TextArea.propTypes = {
  /** see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes */
  autocomplete: PropTypes.string,
  /** see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes */
  autofocus: PropTypes.bool,
  /** custom class */
  className: PropTypes.string,
  cols: PropTypes.number,
  /** set icon click handler *only* if `iconName` is provided */
  iconClickHandler: PropTypes.oneOfType( [
    PropTypes.func,
    PropTypes.instanceOf( null )
  ] ),
  /** icon name for example `fa fa-paperclip` */
  iconName: PropTypes.string,
  /** input id attribute. If not set a random id will be assigned with the prefix `input` */
  id: PropTypes.string,
  /** label for the input */
  label: PropTypes.string,
  /** name attribute */
  name: PropTypes.string,
  /** onChange event handler */
  onChange: PropTypes.func,
  /** any placeholder text for the textarea */
  placeholder: PropTypes.string,
  /** mark the textarea as required */
  required: PropTypes.bool,
  resize: PropTypes.oneOf( [ "none", "both", "vertical", "horizontal" ] ),
  /** the number of rows the textarea should have */
  rows: PropTypes.number,
  value: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number
  ] ),
  /** Should the textarea contain the text without scrolling horizontally */
  wrap: PropTypes.bool,
};
TextArea.defaultProps = {
  autocomplete: null,
  autofocus: false,
  className: "",
  iconName: null,
  iconClickHandler: null,
  id: null,
  label: null,
  name: null,
  onChange: null,
  placeholder: "",
  required: false,
  resize: "vertical",
  value: "",
  wrap: false
};
