import _each from 'lodash/each';
import { markerColors } from '@const/map';

const MapMarkers = {
	geofenceLayer: 1,
	assetLayer: 2,
	userLayer: 5,
	highlightLayer: 4,
	selectedLayer: 5,
	markerOpacity: 0.9,

	getSelectedMapMarker: (markerSrc) => {
		const highlightColor = '08A7CF';
		const updatedMarkerSrc = markerSrc.replace(
			/%3C\/svg%3E/,
			`<path class="activeMapMarker" d="M33.1 66.5C14.82 66.5 0 51.613 0 33.25S14.82 0 33.1 0s33.1 14.887 33.1 33.25S51.38 66.5 33.1 66.5zm.11-5.5c15.578 0 28.207-12.536 28.207-28S48.787 5 33.21 5C17.63 5 5 17.536 5 33s12.63 28 28.21 28z" fill="%23${highlightColor}"></path></svg>`
		);

		return updatedMarkerSrc;
	},

	resetMapMarker: (markerSrc) => {
		const updatedMarkerSrc = markerSrc.replace(
			/<path class="activeMapMarker" d="M33.1 66.5C14.82 66.5 0 51.613 0 33.25S14.82 0 33.1 0s33.1 14.887 33.1 33.25S51.38 66.5 33.1 66.5zm.11-5.5c15.578 0 28.207-12.536 28.207-28S48.787 5 33.21 5C17.63 5 5 17.536 5 33s12.63 28 28.21 28z" fill="%2308A7CF"><\/path><\/svg>/,
			'%3C/svg%3E'
		);

		return updatedMarkerSrc;
	},

	getGeofenceMarker: (bgColor) => {
		const markerColor = bgColor ? bgColor.substring(1) : '4e4e4e';
		const marker = `data:image/svg+xml,
      <svg width='45px' height='66px' viewBox='0 0 45 66' xmlns='http://www.w3.org/2000/svg'>
          <g class='geofenceMarker' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
              <g>
                  <path fill='%23${markerColor}' opacity='0.8' d='M43.615,22.301 C43.615,25.616 42.861,28.746 41.509,31.549 C41.494,31.578 41.481,31.607 41.452,31.649 L39.972,34.225 L39.958,34.239 L22.301,64.829 L4.515,34.025 L3.277,31.877 C1.811,29.002 1,25.744 1,22.301 C1,10.533 10.533,1 22.301,1 C34.068,1 43.615,10.533 43.615,22.301'></path>
                  <path fill='%23ffffff' opacity='0.8' d='M13,21.499 C13,16.252 17.253,12 22.499,12 C27.745,12 31.998,16.252 31.998,21.499 C31.998,26.745 27.745,30.998 22.499,30.998 C17.253,30.998 13,26.745 13,21.499'></path>
              </g>
          </g>
      </svg>`;

		return marker;
	},

	attachMarkerColorToAssets: (assets, settings) => {
		_each(assets, (asset) => {
			const assetId = asset.asset_id || asset.equipment.asset_id;
			const assetHasColor = assetId in (settings.assetMarkerColors || []);

			if (assetHasColor) {
				asset.marker_color = settings.assetMarkerColors[assetId];
			} else {
				asset.marker_color = markerColors.default;
			}
		});

		return assets;
	},

	fetchColorForAsset: (assetId, settings) => {
		let color;

		if (Object.keys(settings).length > 0) {
			if (assetId in (settings.assetMarkerColors || [])) {
				color = settings.assetMarkerColors[assetId];
			}
		}

		return color;
	},
};

export default MapMarkers;
