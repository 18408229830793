import ngModule from '../ng/index';
import '@views/special-collections/routes';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.special-collections', {
			url: '/special-collections',
			template: '<special-collections-routes flex="100" />',
			redirectTo: 'home.special-collections.line-items',
		})
		.state('home.special-collections.line-items', {
			url: `/line-items`,
		});
});
