import ngModule from '../../../ng/index.js';
import template from './delivery-display-component.html';
import { formatAddress } from '@equipmentshare/admin-hooks';

function deliveryDisplayComponent() {
	return {
		template,
		scope: {
			delivery: '=',
		},
		controller($scope, esDeliveriesService) {
			$scope.getShortDeliveryType = esDeliveriesService.getShortDeliveryType;
			$scope.getInitialDropoffStyle = esDeliveriesService.getInitialDropoffStyle;

			$scope.formatLocation = (location) => {
				return location ? formatAddress(location, false) : 'N/A';
			};
			$scope.getReturnPartAssignment = (delivery) => {
				if (delivery.rental && delivery.rental.rental_type_id === 3 && delivery.rental.part_assignments) {
					return delivery.rental.part_assignments.find(
						(assignment) => assignment.return_delivery_id === delivery.delivery_id
					);
				}
				return undefined;
			};
			$scope.isFinalReturn = () => {
				return $scope.delivery.delivery_id === $scope.delivery.rental.return_delivery_id;
			};
			$scope.setCustomerNotes = (delivery) => {
				return delivery.rental?.off_rent_note?.content || 'N/A';
			};
		},
	};
}

ngModule.directive('deliveryDisplayComponent', deliveryDisplayComponent);
