import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';

import errorHandlerService from '@services/error-handler.service';
import partsService from '@services/parts.service';
import { inventoryApolloClient as apolloClient } from '@services/graphql/inventory-apollo-client';
import partQuery from '@services/graphql/inventory/queries/part.js';

const RentalNameDisplay = ({ assetId, assetName, equipmentClassName, partId, rentalTypeId }) => {
	const renderEquipmentName = () =>
		assetId
			? `#${assetId}${assetName ? ` - ${assetName}` : ''}` //Some views don't want name showing
			: `${equipmentClassName ? `${equipmentClassName}` : 'No Asset Assigned'}`;

	const renderPartName = () => {
		return partId ? (
			<Query client={apolloClient} query={partQuery} skip={!partId} variables={{ partId }}>
				{({ loading, error, data }) => {
					if (loading) {
						return 'loading...';
					} else if (error) {
						errorHandlerService.genericErrorHandler(error, `Failed to retrieve rental description for part: ${partId}`);
						return partId;
					}

					return partsService.getPartLabel(data.part);
				}}
			</Query>
		) : (
			'No Part Assigned'
		);
	};

	return rentalTypeId === 3 ? renderPartName() : renderEquipmentName();
};

RentalNameDisplay.propTypes = {
	assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	assetName: PropTypes.string,
	equipmentClassName: PropTypes.string,
	partId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	rentalTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RentalNameDisplay;
