import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';

import { Autocomplete } from '@equipmentshare/react-ui-components';
import statesService from '@services/states.service';
import errorHandlerService from '@services/error-handler.service';

export class StatePicker extends PureComponent {
	static propTypes = {
		selectedStateId: PropTypes.number,
	};

	state = {
		states: [],
		loading: false,
	};

	componentDidMount() {
		this.fetchStates();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	async fetchStates() {
		this.setState({ loading: true });

		try {
			let states = await statesService.fetchAll();

			states = _sortBy(states, 'name');

			if (!this.unmounted) {
				this.setState({ states });
			}
		} catch (err) {
			errorHandlerService.genericErrorHandler(err);
		} finally {
			this.setState({ loading: false });
		}
	}

	render() {
		const { className, required, disabled, name, placeholder, label, selectedStateId, onChange, useAbbreviations } =
			this.props;
		const { states, loading } = this.state;

		return (
			<Autocomplete
				className={className}
				disabled={disabled}
				label={label}
				name={name}
				onChange={onChange}
				options={states}
				placeholder={placeholder || 'Search States'}
				required={required}
				isLoading={loading}
				labelKey={useAbbreviations ? 'abbreviation' : 'name'}
				loadingText="Fetching States..."
				noResultsText="No States Found"
				valueKey="state_id"
				value={selectedStateId}
			/>
		);
	}
}

StatePicker.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	selectedStateId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
	useAbbreviations: PropTypes.bool,
};
StatePicker.defaultProps = {
	className: null,
	disabled: false,
	label: null,
	name: null,
	onChange: null,
	placeholder: null,
	required: false,
	selectedStateId: null,
	useAbbreviations: false,
};

export default StatePicker;
