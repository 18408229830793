/* eslint-disable react/jsx-no-bind, react/no-unused-prop-types */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import { AutoSizer, MultiGrid } from "react-virtualized";

import Icon from "../icon/icon.js";
import ButtonGroup from "../button-group/button-group.js";

import ESColumnSizer from "./es-column-sizer.js";
import ESColumnHeader from "./es-column-header.js";
import { exportCsvFile, getCsvString } from "../virtualized-table-form/utils/csv-helper/csv-helper";
import { FilterChange, SortChange } from "./state-events.js";
import CsvExportButton from "./components/csv-export-button/csv-export-button.js";
import { SORT_DIRECTION_NONE, SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING } from "./constants";
// Exported here for backwards compatability
export { SORT_DIRECTION_NONE, SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING };

import tableStyles from "./es-virtualized-table.module.css";

const CELL_HEIGHT = 45; // px
const CELL_WIDTH = 150; // px
const FILTERABLE_HEADER_HEIGHT = 66; // px
const HEADER_HEIGHT = 37; // px

/**
 * **DEPRECATED COMPONENT - UNSAFE TO USE**
 *
 * No new development will occur on this component. Defect resolution will continue until new components are available for migration, at which point full removal will be scheduled.
 * - If you need virtualization and user actions on data (export, email, etc.) migrate to `VirtualizedTableForm` (also contained in this repo).
 * - If you need a simple, styled table without virtualization, the Design System will be releasing a styled table suitable for migration. In the meantime, you can continue using the existing `ESVirtualizedTable` (not recommended) or work with the Design System team at creating a simple, styled table suitable to promotion into the new component library (recommended).
 * - If you need a virtualized table with virtualization and without user actions on data, you can continue using the existing `ESVirtualizedTable` (not recommended) or break apart the virtualized table from the form components in `VirtualizedTableForm` (recommended).
 *
 * The decision to deprecate this component was made by the F.E. Guild, on 07/30/2020. Please see https://www.notion.so/equipmentshare/Meeting-Doc-Thurs-07-30-20-1pm-8a5375a48e99412fb3be02f4f5331f73 (which is the notes from that day’s guild meeting) for more details.
 *
 * ---
 *
 * Renders a table with a fixed header and (optionally) fixed columns, based on the MultiGrid component from react-virtualized.
 * Unless otherwise noted the properties available to [MultiGrid](https://raw.githubusercontent.com/bvaughn/react-virtualized/master/docs/MultiGrid.md) and [Grid](https://raw.githubusercontent.com/bvaughn/react-virtualized/master/docs/Grid.md) will be directly applied, as-is.
 *
 * The table will stretch to fill all available space within its container. If the table overflows the width of its container, the user may scroll horizontally to view overflowed content. Optionally, you may choose to fix one or more columns in place, so that they do not scroll horizontally with the rest of the table.
 *
 * **Notes:**
 * - The preferred implementation is to abstract the columns out of the table declaration file and into a `./columns/` subdirectory. Examine the code for the "ESVirtualizedTable with Abstracted Columns" below. Currently, a real-life example can be found at `es-track/manage/js/components/asset-keypad-table/`.
 * - If the UI design calls for a highly customized layout for the record, you can create custom records. See the code for  "ESVirtualizedTable with Custom Record Component" for an example. Because sorting and filtering could return unexpected results on a column of custom records, consider adding a custom header and search component.
 * - You can create a custom header. See the code for "ESVirtualizedTable with Custom Header" for an example.
 *
 * **Ignored `MultiGrid` and `Grid` Properties**
 *
 * - cellRenderer
 * - classNameBottomLeftGrid
 * - classNameBottomRightGrid
 * - classNameTopLeftGrid
 * - classNameTopRightGrid
 * - columnCount
 * - enableFixedColumnScroll
 * - enableFixedRowScroll
 * - estimatedColumnSize
 * - estimatedRowSize
 * - fixedRowCount
 * - height
 * - noContentRenderer
 * - rowCount
 * - scrollToRow
 * - width
 */
export default class DEPRECATED_ESVirtualizedTable extends PureComponent {
  constructor( props ) {
    super( props );

    this.state = {
      areScrollIndicatorsHidden: true,
      canScrollLeft: true,
      canScrollRight: true,
      filters: props.defaultFilterState,
      hoveredRowIndex: null,
      rows: [ ...props.rows ],
      scrollToCriteria: null,
      scrollToRow: null,
      sort: props.defaultSortState,
    };

    this.calculateRowHeight = this.calculateRowHeight.bind( this );
    this.cellRenderer = this.cellRenderer.bind( this );
    this.exportAllCsv = this.exportAllCsv.bind( this );
    this.exportFilteredCsv = this.exportFilteredCsv.bind( this );
    this.getFixedColumnCount = this.getFixedColumnCount.bind( this );
    this.handleFilterChange = this.handleFilterChange.bind( this );
    this.handleGridScroll = this.handleGridScroll.bind( this );
    this.handleRowHover = this.handleRowHover.bind( this );
    this.handleSortChange = this.handleSortChange.bind( this );
    this.noContentRenderer = this.noContentRenderer.bind( this );
    this.showScrollIndicators = this.showScrollIndicators.bind( this );
  }

  componentDidMount() {
    let defaultSortedColumn = this.props.columns.find( ( column ) => column.defaultSort );

    if ( Object.keys( this.state.sort ).length || Object.keys( this.state.filters ).length ) {
      let columnKeyToSort = Object.keys( this.state.sort )[0];

      this.handleSortChange( columnKeyToSort, this.state.sort[columnKeyToSort] );
    }
    else if ( defaultSortedColumn && defaultSortedColumn.sortable !== false ) {
      let defaultSortDirection = SORT_DIRECTION_DESCENDING;

      if ( defaultSortedColumn.defaultSortDirection && ( defaultSortedColumn.defaultSortDirection === SORT_DIRECTION_ASCENDING || defaultSortedColumn.defaultSortDirection === SORT_DIRECTION_DESCENDING ) ) {
        defaultSortDirection = defaultSortedColumn.defaultSortDirection;
      }

      this.handleSortChange( defaultSortedColumn.key, defaultSortDirection );
    }

    this.showScrollIndicators();
  }

  UNSAFE_componentWillReceiveProps( newProps ) {
    if ( _isEqual( newProps.rows, this.props.rows ) ) {
      return;
    }

    let rows = this.sortRows( this.filterRows( newProps.rows ) );

    this.setState( { rows } );

    if ( this.gridRef ) {
      this.gridRef.forceUpdateGrids();
    }
  }

  componentDidUpdate( prevProps, prevState ) {
    if ( this.state.scrollToCriteria !== prevState.scrollToCriteria ) {
      this._scrollToRow();
    }
  }

  componentWillUnmount() {
    clearTimeout( this.scrollIndicatorTimeout );
    clearTimeout( this.scrollToCriteriaTimeout );
    clearTimeout( this.scrollToRowTimeout );
  }

  calculateRowHeight( { index } ) {
    let getBodyRowHeight = () => ( this.props.rowHeight || CELL_HEIGHT );
    let getHeaderRowHeight = () => {
      if ( this.props.customHeaderHeight ) {
        return this.props.customHeaderHeight;
      }

      let isFilterable = (
        this.props.filterable &&
        this.props.columns.some( ( column ) => ( column.filterable !== false ) )
      );

      return isFilterable
        ? FILTERABLE_HEADER_HEIGHT
        : HEADER_HEIGHT;
    };

    return ( index === 0 )
      ? getHeaderRowHeight()
      : getBodyRowHeight();
  }

  cellRenderer( { columnIndex, rowIndex, style } ) {
    let column = this.getColumnByIndex( columnIndex );
    let row = this.getRowByIndex( rowIndex );

    let columnKey = _get( column, "key", columnIndex );
    let rowKey = _get( row, "key", rowIndex );
    let iteratorKey = `${columnKey}-${rowKey}`;

    let className = classNames(
      tableStyles.tableCell,
      `table-cell--${columnKey}`,
      this.props.rowClassName( row ),
      {
        [tableStyles.firstColumn]: ( columnIndex === 0 ),
        [tableStyles.firstRow]: ( rowIndex === 0 ),
        [tableStyles.hovered]: ( rowIndex === this.state.hoveredRowIndex ),
        [tableStyles.textLeft]: ( !column.rightAligned ),
        [tableStyles.textRight]: ( column.rightAligned ),
      }
    );

    return (
      <div
        className={className}
        key={iteratorKey}
        onClick={this.handleCellClick.bind( this, rowIndex, columnIndex )}
        onMouseEnter={this.handleRowHover.bind( this, rowIndex )}
        style={style}
      >
        {
          rowIndex === 0
            ? this.renderColumnHeader( columnIndex )
            : this.renderTableCell( columnIndex, rowIndex )
        }
      </div>
    );
  }

  noContentRenderer() {
    return ( <div className={tableStyles.emptyContent}>{this.props.emptyStateText}</div> );
  }

  getColumnByIndex( columnIndex ) {
    return ( columnIndex >= 0 )
      ? this.props.columns[ columnIndex ]
      : null;
  }

  getColumnByKey( columnKey ) {
    return this.props.columns.find( ( { key } ) => ( columnKey === key ) );
  }

  getRowByIndex( rowIndex ) {
    // The row at index 0 is the header, so we must offset indexing by 1.
    return ( rowIndex > 0 )
      ? this.state.rows[ rowIndex - 1 ]
      : null;
  }

  filterRows( rows, filters ) {
    filters = filters || this.state.filters;

    if ( Object.keys( filters ).length === 0 ) {
      return rows;
    }

    let filterMatch = ( filterTerm ) => {
      filterTerm = String( filterTerm ).toLowerCase();

      return ( testValue ) => ( String( testValue ).toLowerCase().includes( filterTerm ) );
    };

    let getCellValues = ( column, row ) => {
      let cellValue = row[column.key];

      if ( column.filterData ) {
        cellValue = column.filterData( row );
      }
      else if ( column.filterSortData ) {
        cellValue = column.filterSortData( row );
      }

      if ( !Array.isArray( cellValue ) ) {
        cellValue = [ cellValue ];
      }

      return cellValue;
    };

    return rows.filter( ( row ) => {
      return Object.keys( filters ).every( ( columnKey ) => {
        let column = this.getColumnByKey( columnKey );
        let filterTerm = filters[columnKey];

        if ( column.customFilter ) {
          return column.customFilter( filterTerm, row );
        }

        return getCellValues( column, row ).some( filterMatch( filterTerm ) );
      } );
    } );
  }

  getFixedColumnCount( doesExceedWidth ) {
    return doesExceedWidth()
      ? this.props.fixedColumnCount
      : 0;
  }

  handleCellClick( rowIndex, columnIndex ) {
    // Don't respond to clicks on a header cell...
    if ( rowIndex > 0 ) {
      let row = this.getRowByIndex( rowIndex );
      let column = this.getColumnByIndex( columnIndex );

      if ( typeof this.props.onCellClick === "function" ) {
        this.props.onCellClick( row, column );
      }
    }
  }

  handleFilterChange( columnKey, filterValue ) {
    let filters = { ...this.state.filters };

    if ( filterValue ) {
      filters[columnKey] = filterValue;
    }
    else {
      delete filters[columnKey];
    }

    let rows = this.sortRows( this.filterRows( this.props.rows, filters ) );

    this.setState( { filters, rows } );
    this.props.onStateEvent( new FilterChange( columnKey, filters, rows ) );
    if ( this.gridRef ) {
      this.gridRef.forceUpdateGrids();
    }
  }

  handleGridScroll( { clientWidth, scrollLeft, scrollWidth } ) {
    if ( isNaN( clientWidth ) || isNaN( scrollLeft ) || isNaN( scrollWidth ) ) {
      return;
    }

    this.showScrollIndicators();

    let canScrollLeft = scrollLeft > 0;
    let canScrollRight = ( scrollLeft + clientWidth ) < scrollWidth;

    if ( this.state.canScrollLeft !== canScrollLeft || this.state.canScrollRight !== canScrollRight ) {
      this.setState( { canScrollLeft, canScrollRight } );
    }
  }

  handleRowHover( hoveredRowIndex ) {
    if( this.props.onHoverRowHighlighting ){
      this.setState( { hoveredRowIndex } );
      if ( this.gridRef ) {
        this.gridRef.forceUpdateGrids();
      }
    }
  }

  handleSortChange( columnKey, currentSortDirection = SORT_DIRECTION_ASCENDING ) {
    let sort = { columnKey, direction: ( [ SORT_DIRECTION_ASCENDING, SORT_DIRECTION_DESCENDING ].includes( currentSortDirection ) ? currentSortDirection : SORT_DIRECTION_ASCENDING ) };
    let rows = this.sortRows( this.filterRows( this.props.rows ), sort );

    this.setState( { rows, sort } );
    this.props.onStateEvent( new SortChange( columnKey, sort.direction ) );
    if ( this.gridRef ) {
      this.gridRef.forceUpdateGrids();
    }
  }

  renderColumnHeader( columnIndex ) {
    let column = this.getColumnByIndex( columnIndex );

    let filterValue = this.state.filters[column.key];
    let sortDirection = ( this.state.sort.columnKey === column.key )
      ? this.state.sort.direction
      : SORT_DIRECTION_NONE;
    let customHeader = ( typeof column.customHeader === "function" ) ? column.customHeader : null;

    return (
      <ESColumnHeader
        {...column}
        columnKey={column.key}
        customHeader={customHeader}
        filterValue={filterValue}
        filterable={( this.props.filterable && column.filterable )}
        handleFilterChange={this.handleFilterChange}
        handleSortChange={this.handleSortChange}
        sortDirection={sortDirection}
      />
    );
  }

  renderTableCell( columnIndex, rowIndex ) {
    let column = this.getColumnByIndex( columnIndex );
    let row = this.getRowByIndex( rowIndex );

    let className = column.className;
    let style = { width: "100%" };

    let title = column.tooltip
      ? column.tooltip( row )
      : row[ column.key ];

    let cellProps = !column.hideTooltip && [ "string", "number" ].includes( typeof( title ) )
      ? { className, style, title }
      : { className, style };

    let content = column.render
      ? column.render( row, rowIndex )
      : row[ column.key ];

    return (
      <div {...cellProps}>
        {content}
      </div>
    );
  }

  _scrollToRow() {
    let criteria = this.state.scrollToCriteria;

    if ( criteria ) {
      let rowIndex = this.state.rows.findIndex( ( row ) => (
        row[ criteria.propKey ] === criteria.matchValue
      ) );

      if ( rowIndex >= 0 ) {
        this.setState( { scrollToRow: rowIndex + 1 } );

        clearTimeout( this.scrollToRowTimeout );
        this.scrollToRowTimeout = setTimeout( () => {
          this.setState( { scrollToRow: null } );
        }, 0 );
      }
    }
  }

  scrollToRowByProp( scrollToCriteria ) {
    this.setState( { scrollToCriteria } );

    clearTimeout( this.scrollToCriteriaTimeout );
    this.scrollToCriteriaTimeout = setTimeout( () => {
      this.setState( { scrollToCriteria: null } );
    }, 1000 );
  }

  showScrollIndicators() {
    if ( this.state.areScrollIndicatorsHidden ) {
      this.setState( { areScrollIndicatorsHidden: false } );
    }

    clearTimeout( this.scrollIndicatorTimeout );
    this.scrollIndicatorTimeout = setTimeout( () => {
      this.setState( { areScrollIndicatorsHidden: true } );
    }, 1500 );
  }

  sortRows( rows, sort ) {
    sort = sort || this.state.sort;
    let column = this.getColumnByKey( sort.columnKey );

    if ( !column ) {
      return rows;
    }

    if ( column.customSortComparer ) {
      return rows.sort( column.customSortComparer.bind( null, sort.direction ) );
    }
    else {
      let getValue = ( source ) => {
        let value = source[column.key];

        if ( column.sortData ) {
          value = column.sortData( source );
        }
        else if ( column.filterSortData ) {
          value = column.filterSortData( source );
        }

        if ( typeof value === "string" ) {
          value = value.toLowerCase();
        }

        return value;
      };

      return [ ...rows ].sort( ( a, b ) => {
        let aValue = getValue( a );
        let bValue = getValue( b );

        if ( aValue === undefined || aValue === null || aValue === "" ) {
          return 1;
        }

        if ( bValue === undefined || bValue === null || bValue === "" ) {
          return -1;
        }

        if ( sort.direction === SORT_DIRECTION_ASCENDING ) {
          return ( aValue > bValue ) ? 1 : -1;
        }

        if ( sort.direction === SORT_DIRECTION_DESCENDING ) {
          return ( aValue < bValue ) ? 1 : -1;
        }

        return 0;
      } );
    }
  }

  exportAllCsv() {
    this.exportCsv( this.props.rows, this.props.csvFileName );
  }

  exportFilteredCsv() {
    this.exportCsv( this.state.rows, `${this.props.csvFileName}-(filtered)` );
  }

  exportCsv( rows, fileName ) {
    if ( this.props.enableCsvExport ) {
      let columns = this.props.columns.filter( ( column ) => !column.excludeFromExport );

      exportCsvFile( getCsvString( columns, rows ), fileName );
    }
  }

  renderExportButton() {
    const displayFilteredExport = !!( this.props.filterable || this.props.csvExportType === EXPORT_TYPES.advanced );
    const isFilteredExportDisabled = !!(
      Object.keys( this.state.filters ).length === 0 && Object.keys( this.state.sort ).length === 0
    );

    const props = displayFilteredExport
      ? {
        exportAllCsv: this.exportAllCsv,
        disableFilteredExport: isFilteredExportDisabled,
        exportFilteredCsv: this.exportFilteredCsv,
      }
      : {
        exportAllCsv: this.exportAllCsv,
      };

    if ( this.props.renderCustomExportButton ) {
      return this.props.renderCustomExportButton( props );
    }

    return <CsvExportButton {...props} />;
  }

  renderButtonGroup() {
    const isExportEnabled = this.props.enableCsvExport;
    const hasCustomButtons = this.props.buttons.length;

    if ( !isExportEnabled && !hasCustomButtons ) {
      return null;
    }

    return (
      <ButtonGroup className={tableStyles.csvButton} inlineRight>
        {this.props.buttons}
        {isExportEnabled && this.renderExportButton()}
      </ButtonGroup>
    );
  }

  render() {
    let props = this.props;
    let scrollIndicatorClassName = classNames( tableStyles.scrollIndicators, {
      [tableStyles.hiddenScrollIndicators]: this.state.areScrollIndicatorsHidden,
    } );

    return (
      <React.Fragment>
        {this.renderButtonGroup()}
        <div
          className={classNames( tableStyles.table, props.className )}
          onMouseLeave={this.handleRowHover.bind( this, null )}
          onMouseMove={this.showScrollIndicators}
        >
          <AutoSizer>
            {( { height, width } ) => (
              <ESColumnSizer
                columns={props.columns}
                containerWidth={width}
                defaultMinColumnWidth={props.defaultMinColumnWidth}
              >
                {( { doesExceedWidth, getColumnWidth, registerChild } ) => (
                  <MultiGrid
                    {...props}
                    cellRenderer={this.cellRenderer}
                    classNameBottomLeftGrid={classNames( tableStyles.tableBody, tableStyles.tableBodyLeft )}
                    classNameBottomRightGrid={classNames( tableStyles.tableBody, tableStyles.tableBodyRight )}
                    classNameTopLeftGrid={classNames( tableStyles.tableHeader, tableStyles.tableHeaderLeft )}
                    classNameTopRightGrid={classNames( tableStyles.tableHeader, tableStyles.tableHeaderRight )}
                    columnCount={props.columns.length}
                    columnWidth={getColumnWidth}
                    enableFixedColumnScroll
                    enableFixedRowScroll
                    estimatedColumnSize={props.defaultMinColumnWidth}
                    estimatedRowSize={props.rowHeight}
                    fixedColumnCount={this.getFixedColumnCount( doesExceedWidth )}
                    fixedRowCount={1}
                    height={height}
                    noContentRenderer={this.noContentRenderer}
                    onScroll={this.handleGridScroll}
                    onSectionRendered={this.props.onSectionRendered}
                    ref={( ref ) => {
                      this.gridRef = ref;
                      registerChild( ref );
                    }}
                    rowCount={( this.state.rows.length + 1 )}
                    rowHeight={this.calculateRowHeight}
                    scrollToRow={this.state.scrollToRow}
                    width={width}
                  />
                )}
              </ESColumnSizer>
            )}
          </AutoSizer>
          <div className={scrollIndicatorClassName}>
            {this.state.canScrollLeft && <Icon name="fasChevronCircleLeft" />}
            {this.state.canScrollRight && <Icon className={tableStyles.rightScroller} name="fasChevronCircleRight" />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EXPORT_TYPES = {
  basic: "basic",
  advanced: "advanced",
};

DEPRECATED_ESVirtualizedTable.exportTypes = EXPORT_TYPES;

DEPRECATED_ESVirtualizedTable.defaultProps = {
  buttons: [],
  className: undefined,
  csvFileName: "export",
  csvExportType: EXPORT_TYPES.basic,
  customHeaderHeight: null,
  defaultFilterState: {},
  defaultMinColumnWidth: CELL_WIDTH,
  defaultSortState: {},
  emptyStateText: "No Data",
  enableCsvExport: false,
  filterable: true,
  fixedColumnCount: 0,
  onCellClick: () => {},
  onHoverRowHighlighting: true,
  onSectionRendered: undefined,
  onStateEvent: () => {},
  rowClassName: () => ( "" ),
  renderCustomExportButton: undefined,
  rowHeight: CELL_HEIGHT,
};

DEPRECATED_ESVirtualizedTable.propTypes = {
  /** An ordered array of `Buttons` to be displayed in the upper right button group.
   * Note: You must specify the `key` attribute of each button in the array.
   */
  buttons: PropTypes.array,
  className: PropTypes.string,
  /** An array of column configurations */
  columns: PropTypes.arrayOf( PropTypes.shape( {
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    customFilter: PropTypes.func,
    customHeader: PropTypes.func,
    customSortComparer: PropTypes.func,
    defaultSort: PropTypes.bool,
    defaultSortDirection: PropTypes.string,
    excludeFromExport: PropTypes.bool,
    exportData: PropTypes.func,
    filterable: PropTypes.bool,
    filterData: PropTypes.func,
    filterSortData: PropTypes.func,
    hideTooltip: PropTypes.bool,
    minWidth: PropTypes.number,
    render: PropTypes.func,
    rightAligned: PropTypes.bool,
    sortable: PropTypes.bool,
    sortData: PropTypes.func,
    tooltip: PropTypes.func,
    width: PropTypes.number,
  } ) ).isRequired,
  /** Providing the `basic` type will display a basic export button when csv export is enabled.  Providing the `advanced` type will display an export button with expanded options for exporting.
   * Note: If `ESVirtualizedTable.filterable == true` then the advanced button will display.  This is to maintain backwards compatibility for now.
   */
  csvExportType: PropTypes.oneOf( Object.values( EXPORT_TYPES ) ),
  /** The base file name (excluding file extension) that will be used when exporting to a CSV file. */
  csvFileName: PropTypes.string,
  /** A fixed height applied to each column header. */
  customHeaderHeight: PropTypes.number,
  /** Applies a default filter to the table as it renders for the first time.  Multiple columns can be filtered.
   * Expected format is: `{ one_column_key: "on filter", two_column_key: "two filter" }`
   */
  defaultFilterState: PropTypes.object,
  /** Applies a minimum width to columns that don't define their own minimum width. */
  defaultMinColumnWidth: PropTypes.number,
  /** Applies default sort to the table as it renders for the first time.  This will override the sorting from the Column definition.
   * Expected format is:
   * `{ column_key: "ASC" }`
   * `{ column_key: "DESC" }`
   */
  defaultSortState: PropTypes.object,
  /** Text to display if no data is available to the table */
  emptyStateText: PropTypes.string,
  /** Set to `true` to enable an "Export to CSV" button for the table */
  enableCsvExport: PropTypes.bool,
  /** Set to `false` to disable filtering of the entire table. */
  filterable: PropTypes.bool,
  /** The number of columns (from the left edge of the table) to fix in place if horizontal scrolling becomes necessary to view table content. */
  fixedColumnCount: PropTypes.number,
  /** The callback to invoke whenever a cell in the table is clicked.  The callback is invoked with the row item and column configuration associated with the clicked cell. */
  onCellClick: PropTypes.func,
  onHoverRowHighlighting: PropTypes.bool,
  /** A passthrough to Grid's onSectionRendered */
  onSectionRendered: PropTypes.func,
  /** The callback to invoke whenever a state event is broadcasted.  The callback is invoked with the name of the event and the new state values.
   * State events setup for broadcast:
   * `"filterChange"`
   * `"sortChange"`
   */
  onStateEvent: PropTypes.func,
  /** Renders a custom csv export button. Expects the following argument:
   * { exportAllCsv } or, if allowing a filtered export
   * { exportAllCsv, disableFilteredExport, exportFilteredCsv }
   */
  renderCustomExportButton: PropTypes.func,
  /** Takes a row object and returns a string that will be applied to the cells within the associated row */
  rowClassName: PropTypes.func,
  /** A fixed height applied to each row. */
  rowHeight: PropTypes.number,
  /** An array of row objects. */
  rows: PropTypes.array.isRequired,
};
