import ngModule from '../../../ng/index.js';
import { createErrorHandler } from '../../../state/helpers.js';
import template from './asset-service.html';

ngModule
	.component('assetService', {
		template,
		controller: 'AssetService',
		bindings: {
			assetId: '<',
		},
	})
	.controller('AssetService', function AssetService($scope, esApiFactoryV2, esAdminMaintenanceService, $state, $q) {
		const ctrl = this;

		$scope.$state = $state;
		$scope.isLoading = true;

		ctrl.refresh = () => {
			if (!ctrl.assetId) {
				return;
			}
			$scope.isLoading = true;
			$q.all([
				esAdminMaintenanceService
					.getAssetUpcomingServices(ctrl.assetId)
					.then((services) => ($scope.upcomingServices = services)),
				esAdminMaintenanceService
					.getAssetServiceRecords(ctrl.assetId)
					.then((serviceRecords) => ($scope.serviceRecords = serviceRecords)),
			])
				.catch(createErrorHandler('Unable to load services, please contact engineering.'))
				.finally(() => ($scope.isLoading = false));
		};

		ctrl.$onInit = ctrl.refresh;
		ctrl.$onChanges = ctrl.refresh;
	});
