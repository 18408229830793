const findColorHex = /^#?([0-9a-f]{3,6})$/i;

function getMapMarkerPath( asset, statusCode, opts = {} ){
  const { markerColor = "4e4e4e", direction = 0 } = opts;
  let labelColor = "ffffff";

  let markerHexColor = findColorHex.test( markerColor ) ? findColorHex.exec( markerColor )[1] : "4e4e4e";

  switch( statusCode ) {
    case "manual-location":
      return `
      <g class="manual-location" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g>
          <circle cx="33.5" cy="33.5" r="33.5" fill="%23${markerHexColor}"></circle>
          <path d="M48.2105 46.9553L18.6842 16.6721L16 19.3441L19.4932 22.8743C19.4932 22.8743 17.863 25.9102 17.863 30.3473C17.863 34.7844 19.9259 38.3743 21.3562 40.1557C22.7865 41.9371 33.9315 53 33.9315 53L41.3836 45.5269L45.5263 49.6274L48.2105 46.9553Z" fill="%23${labelColor}"></path>
          <path d="M33.8947 14C27.6316 13.9931 23.6849 17.7365 23.6849 17.7365L32.5342 26.8443C32.5342 26.8443 34.7037 25.7635 36.4931 27.5449C38.2826 29.3263 37.1918 31.515 37.1918 31.515L46.0411 40.6228C46.0411 40.6228 50 36.2741 50 30.0323C50 23.7906 46.9226 19.8255 45.5263 18.4465C44.1301 17.0674 40.1579 14.007 33.8947 14Z" fill="%23${labelColor}"></path>
        </g>
      </g>
      `;
    case "asset-off":
    case "assetOff":
      return `
        <g class="assetOff" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <path fill="%23${markerHexColor}" d="M66.4901,34.0341 C66.4901,52.2891 51.6921,67.0881 33.4371,67.0881 C15.1821,67.0881 0.3831,52.2891 0.3831,34.0341 C0.3831,15.7791 15.1821,0.9801 33.4371,0.9801 C51.6921,0.9801 66.4901,15.7791 66.4901,34.0341"></path>
            <path fill="%23${labelColor}" d="M20.6786,38.0585 L21.4356,37.3005 L21.4356,29.6375 L20.6786,28.8795 L17.4926,28.8795 L16.7356,29.6375 L16.7356,37.3005 L17.4926,38.0585 L20.6786,38.0585 Z M12.5026,38.7935 L12.5026,28.1445 L14.8636,25.7835 L23.2846,25.7835 L25.6686,28.1445 L25.6686,38.7935 L23.2846,41.1555 L14.8636,41.1555 L12.5026,38.7935 Z"></path>
            <polygon fill="%23${labelColor}" points="30.1234 25.7831 40.6614 25.7831 40.6614 28.9701 34.3334 28.9701 34.3334 32.1991 40.0154 32.1991 40.0154 35.3631 34.3334 35.3631 34.3334 41.1551 30.1234 41.1551"></polygon>
            <polygon fill="%23${labelColor}" points="44.5819 25.7831 55.1199 25.7831 55.1199 28.9701 48.7929 28.9701 48.7929 32.1991 54.4739 32.1991 54.4739 35.3631 48.7929 35.3631 48.7929 41.1551 44.5819 41.1551"></polygon>
          </g>
        </g>
      `;
    case "asset-bluetooth-node":
    case "assetBluetoothNode":
      return `
        <g class="assetBluetoothNode" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <path fill="%23${markerHexColor}" d="M66.4901,34.0341 C66.4901,52.2891 51.6921,67.0881 33.4371,67.0881 C15.1821,67.0881 0.3831,52.2891 0.3831,34.0341 C0.3831,15.7791 15.1821,0.9801 33.4371,0.9801 C51.6921,0.9801 66.4901,15.7791 66.4901,34.0341"></path>
            <path fill="%23${labelColor}" d="M39.949 41.601l-3.606 3.763v-7.526l3.606 3.763zm-3.606-20.935l3.606 3.762-3.606 3.743v-7.505zm9.033 3.762L34.426 13h-1.919v15.171l-8.803-9.167L21 21.827l10.72 11.188L21 44.183l2.704 2.842 8.803-9.187V53.03h1.918L45.376 41.6l-8.247-8.586 8.247-8.587z" />
          </g>
        </g>
      `;
    case "asset-not-communicating":
    case "assetNotCommunicating":
      return `
        <g class="assetNotCommunicating" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <path fill="%23${markerHexColor}" d="M66.49 34.034c0 18.255-14.798 33.054-33.053 33.054-18.255 0-33.054-14.8-33.054-33.054C.383 15.78 15.183.98 33.437.98c18.255 0 33.053 14.8 33.053 33.054"></path>
            <path fill="%23${labelColor}" d="M51.1871,26.1558 C50.4951,25.6338 43.6071,20.0058 33.2901,20.0058 C30.9831,20.0058 28.8461,20.2978 26.9091,20.7438 L42.7921,36.6108 L51.1871,26.1558 Z"></path>
            <path fill="%23${labelColor}" d="M40.646,39.187 L19.474,18 L17.521,19.967 L20.673,23.135 C17.383,24.642 15.354,26.272 15,26.548 L32.881,48.828 L32.897,48.843 L32.912,48.828 L38.909,41.355 L44.013,46.459 L45.966,44.507 L40.646,39.187 Z"></path>
          </g>
        </g>
      `;
    case "asset-heading":
    case "assetHeading":
      return `
        <g class="assetHeading" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="rotate(${direction}, 33.5, 33.5)">
            <path fill="%23${markerHexColor}" d="M66.2928,33.5272 C66.2928,51.7822 51.4948,66.5812 33.2398,66.5812 C14.9848,66.5812 0.1858,51.7822 0.1858,33.5272 C0.1858,15.2722 14.9848,0.4742 33.2398,0.4742 C51.4948,0.4742 66.2928,15.2722 66.2928,33.5272"></path>
            <polygon fill="%23${labelColor}" points="52.194 40.097 33.097 21 14 40.097"></polygon>
          </g>
        </g>
      `;
    case "asset-idling":
    case "assetIdling":
      return `
        <g class="assetIdling" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <path fill="%23${markerHexColor}" d="M66.2928,33.5272 C66.2928,51.7822 51.4948,66.5812 33.2398,66.5812 C14.9848,66.5812 0.1858,51.7822 0.1858,33.5272 C0.1858,15.2722 14.9848,0.4742 33.2398,0.4742 C51.4948,0.4742 66.2928,15.2722 66.2928,33.5272"></path>
            <polygon fill="%23${labelColor}" points="14 35.75 52.245 35.75 52.245 32 14 32"></polygon>
          </g>
        </g>
      `;
    case "asset-running":
    case "assetRunning":
      return `
        <g class="assetRunning" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <path fill="%23${markerHexColor}" d="M66.2928,33.5272 C66.2928,51.7822 51.4948,66.5812 33.2398,66.5812 C14.9848,66.5812 0.1858,51.7822 0.1858,33.5272 C0.1858,15.2722 14.9848,0.4742 33.2398,0.4742 C51.4948,0.4742 66.2928,15.2722 66.2928,33.5272"></path>
            <path fill="%23${labelColor}" d="M33.1382563,40.168 C29.5402563,40.168 26.6132563,37.241 26.6132563,33.643 C26.6132563,30.045 29.5402563,27.118 33.1382563,27.118 C36.7362563,27.118 39.6632563,30.045 39.6632563,33.643 C39.6632563,37.241 36.7362563,40.168 33.1382563,40.168 L33.1382563,40.168 Z M46.9902563,35.47 C47.0652563,34.873 47.1202563,34.277 47.1202563,33.643 C47.1202563,33.009 47.0652563,32.412 46.9902563,31.816 L50.9242563,28.74 C51.2782563,28.46 51.3712563,27.957 51.1472563,27.547 L47.4192563,21.096 C47.1952563,20.686 46.6922563,20.537 46.2822563,20.686 L41.6392563,22.551 C40.6702563,21.805 39.6262563,21.189 38.4892563,20.724 L37.7802563,15.783 C37.7242563,15.335 37.3332563,15 36.8672563,15 L29.4092563,15 C28.9432563,15 28.5522563,15.335 28.4962563,15.783 L27.7872563,20.724 C26.6502563,21.189 25.6062563,21.823 24.6372563,22.551 L19.9942563,20.686 C19.5662563,20.519 19.0812563,20.686 18.8572563,21.096 L15.1292563,27.547 C14.8872563,27.957 14.9982563,28.46 15.3522563,28.74 L19.2862563,31.816 C19.2122563,32.412 19.1562563,33.027 19.1562563,33.643 C19.1562563,34.258 19.2122563,34.873 19.2862563,35.47 L15.3522563,38.546 C14.9982563,38.826 14.9052563,39.329 15.1292563,39.739 L18.8572563,46.189 C19.0812563,46.6 19.5842563,46.749 19.9942563,46.6 L24.6372563,44.735 C25.6062563,45.481 26.6502563,46.097 27.7872563,46.563 L28.4962563,51.503 C28.5522563,51.951 28.9432563,52.286 29.4092563,52.286 L36.8672563,52.286 C37.3332563,52.286 37.7242563,51.951 37.7802563,51.503 L38.4892563,46.563 C39.6262563,46.097 40.6702563,45.463 41.6392563,44.735 L46.2822563,46.6 C46.7102563,46.768 47.1952563,46.6 47.4192563,46.189 L51.1472563,39.739 C51.3712563,39.329 51.2782563,38.826 50.9242563,38.546 L46.9902563,35.47 Z"></path>
          </g>
        </g>
      `;
    case "asset-being-hauled":
    case "assetBeingHauled":
      return `
        <g class="assetBeingHauled" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <path fill="%23${markerHexColor}" d="M66.2928,33.5272 C66.2928,51.7822 51.4948,66.5812 33.2398,66.5812 C14.9848,66.5812 0.1858,51.7822 0.1858,33.5272 C0.1858,15.2722 14.9848,0.4742 33.2398,0.4742 C51.4948,0.4742 66.2928,15.2722 66.2928,33.5272"></path>
            <polygon fill="%23${labelColor}" points="52.194 37.097 33.097 18 14 37.097"></polygon>
            <path fill="%23${labelColor}" d="M32.84,30.8402 L14.21,49.4712 L51.47,49.4712 L32.84,30.8402 Z M32.84,36.6922 L41.481,45.3332 L24.199,45.3332 L32.84,36.6922 Z"></path>
          </g>
        </g>
      `;
    default:
      return `
        <g class="assetOff" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g>
            <path fill="%23${markerHexColor}" d="M66.4901,34.0341 C66.4901,52.2891 51.6921,67.0881 33.4371,67.0881 C15.1821,67.0881 0.3831,52.2891 0.3831,34.0341 C0.3831,15.7791 15.1821,0.9801 33.4371,0.9801 C51.6921,0.9801 66.4901,15.7791 66.4901,34.0341"></path>
            <path fill="%23${labelColor}" d="M20.6786,38.0585 L21.4356,37.3005 L21.4356,29.6375 L20.6786,28.8795 L17.4926,28.8795 L16.7356,29.6375 L16.7356,37.3005 L17.4926,38.0585 L20.6786,38.0585 Z M12.5026,38.7935 L12.5026,28.1445 L14.8636,25.7835 L23.2846,25.7835 L25.6686,28.1445 L25.6686,38.7935 L23.2846,41.1555 L14.8636,41.1555 L12.5026,38.7935 Z"></path>
            <polygon fill="%23${labelColor}" points="30.1234 25.7831 40.6614 25.7831 40.6614 28.9701 34.3334 28.9701 34.3334 32.1991 40.0154 32.1991 40.0154 35.3631 34.3334 35.3631 34.3334 41.1551 30.1234 41.1551"></polygon>
            <polygon fill="%23${labelColor}" points="44.5819 25.7831 55.1199 25.7831 55.1199 28.9701 48.7929 28.9701 48.7929 32.1991 54.4739 32.1991 54.4739 35.3631 48.7929 35.3631 48.7929 41.1551 44.5819 41.1551"></polygon>
          </g>
        </g>
      `;
  }
}

function getMapMarker ( asset, statusCode, opts = {} ) {
  const { markerColor, heading, height = "67px", width = "67px" } = opts;

  let path = getMapMarkerPath( asset, statusCode, { markerColor, direction: heading } );
  let svg = `<svg width="${width}" height="${height}" viewBox="0 0 67 67" xmlns="http://www.w3.org/2000/svg">${path}</svg>`;

  let marker = `data:image/svg+xml, ${svg}`;

  return marker.replace( /\n/g, "" ).replace( /( )+/g, " " ).replace( /</g, "%3C" ).replace( />/g, "%3E" );
}

const AssetMapIconFactory = {
  getMapMarker
};

export default AssetMapIconFactory;