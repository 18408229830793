import angular from 'angular';

angular.module("esNgComponents").directive( 'ignoreMouseWheel', function() {
  return {
    restrict: 'A',
    link: function( scope, element, attrs ){
      element.bind('mousewheel', function ( event ) {
        element.blur();
      } );
    }
  }
} );
