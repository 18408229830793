import _find from 'lodash/find';
import _map from 'lodash/map';

angular
  .module('esApiClient')
  .factory('opsAssetRepository', (esApiFactoryV2, esCanonicalizer, esTopics) =>
    new OperationsAssetRepository(esApiFactoryV2, esCanonicalizer, esTopics));

// This will be better placed in kernel once that's in
const statusSortOrder = ['Ready To Rent', 'Needs Inspection', 'Pending Return', 'Soft Down', 'Hard Down', 'On Rent'];
const assetMask = `{
  asset_id,
  custom_name,
  model,
  asset_type {
    asset_type_id,
    name
  }
  rental_info {
    inventory_status
  },
  last_delivered_to,
  company {
    company_id,
    name
  },
  equipment_model {
    equipment_model_id,
    name,
    equipment_make {
      equipment_make_id,
      name
    },
    equipment_class {
      equipment_class_id,
      name,
      category {
        category_id,
        name
      }
    }
  },
  keypad {
    asset_id
    date_created
    keypad_id
    serial_number
  }
  tracker {
    tracker_id,
    device_serial
  },
  inspections {
    asset_inspection_id,
    completed,
    created,
    inspection_items {
      asset_inspection_item_id,
      passed,
      notes
    }
  },
  rental_branch {
    market_id,
    name
  }
}`;

const assetInventoryStatusMask = `{
  asset_id,
  asset_type_id,
  rental_info {
    inventory_status
  }
}`;

class OperationsAssetRepository {
  constructor(api, canonicalizer, esTopics) {
    this._api = api;
    this._canonicalizer = canonicalizer;
    this._inventoryStatuses = []; // loaded by getInventoryStatuses
    this._esTopics = esTopics;
    this._canonicalizeAndMakeSortable = (asset) => {
      let status = _find(this._inventoryStatuses, s => s.name === asset.rental_info.inventory_status);
      asset._statusSortIndex = status ? status.sort_index : -1;
      return this._canonicalizer.canonicalize('asset', asset);
    };
  }

  assetsByMarket(marketId, options) {
    return this.getInventoryStatuses() // _canonicalizeAndMakeSortable depends on having these loaded
        .then(() => this._api.opsGetAssetsWithInventoryStatus(marketId, assetMask, options))
        .then((assets) => _map(assets, this._canonicalizeAndMakeSortable));
  }

  assetById(assetId) {
    return this.getInventoryStatuses() // _canonicalizeAndMakeSortable depends on having these loaded
      .then(() => this._api.opsGetAssetWithInventoryStatus(assetId))
      .then(this._canonicalizeAndMakeSortable);
  }

  getAssetInventoryStatus(assetId) {
    return this._api.opsGetAssetWithInventoryStatus(assetId, assetInventoryStatusMask)
      .then( asset => this._canonicalizer.canonicalize('asset', asset));
  }

  getInventoryStatuses() {
    return this._api.getInventoryStatuses()
      .then(statuses => {
        this._inventoryStatuses = statuses;
        return statuses;
      });
  }

  /**
   * Subscribe to push updates from the backend whenever the inventory status of any asset in the
   * given market changes. Any asset object retrieved via {@link #assetById} will transparently be
   * updated whenever the backend sends us a notification.
   *
   * @param marketId - the market to monitor
   * @return a subscription that you *must* call #unsubscribe() when you no longer need live updates for this market,
   *         each subscription is taking up backend resources until it is unsubscribed.
   */
  watchMarketInventoryChanges(marketId) {
    // Implementation note:
    // This subscribes to get asset_id's whenever an asset changes, and then it
    // fetches that whole asset back and updates the local canonical object. For several
    // reasons, mainly the mutating of objects held by unknown other parts of the system and the
    // inability to "just" hook code onto reacting to the asset changing. This is simply
    // an easy way to leverage this functionality given Angular. As we discuss alternate approaches
    // to application state, a more reactive/immutable approach to driving state changes from this
    // would be preferable.

    let repository = this;
    return this._esTopics
      .topic("/assets/inventory-changes", {market_id: marketId})
      .subscribe({
        next(event) {
          // Reload and pass to canonicalizer; updating the systems canonical instance of this asset.
          repository.assetById(event.asset_id);
        },

        error(error) {
          console.error(error);
        },
      });
  }
}
