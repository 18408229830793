import { gql } from '@apollo/client';
import getOperationNameFromQuery from '../../utils/get-operation-name-from-query.js';

const partsQuery = gql`
	query Parts($partIds: [Int!], $search: String) {
		parts(partIds: $partIds, search: $search) {
			id
			__typename
			partClass {
				id
				description
				__typename
			}
		}
	}
`;

export const partsQueryName = getOperationNameFromQuery(partsQuery);
export default partsQuery;
