import { SHIFT_MULTIPLIERS } from '@const/rate-types';

export function getRateMultiplier(selectedShiftId) {
	switch (selectedShiftId) {
		case 1:
			return SHIFT_MULTIPLIERS.single;
		case 2:
			return SHIFT_MULTIPLIERS.double;
		case 3:
			return SHIFT_MULTIPLIERS.triple;
		default:
			return 1;
	}
}

export function getMultipliedRates(selectedShiftId, rates, rateConvention) {
	const multiplier = getRateMultiplier(selectedShiftId);

	let multipliedRates = { ...rates };

	if (rateConvention === 'price_per') {
		multipliedRates.price_per_hour = parseFloat((multipliedRates.price_per_hour * multiplier).toFixed(2));
		multipliedRates.price_per_day = parseFloat((multipliedRates.price_per_day * multiplier).toFixed(2));
		multipliedRates.price_per_week = parseFloat((multipliedRates.price_per_week * multiplier).toFixed(2));
		multipliedRates.price_per_month = parseFloat((multipliedRates.price_per_month * multiplier).toFixed(2));
	} else {
		multipliedRates.hourlyRate = parseFloat((multipliedRates.hourlyRate * multiplier).toFixed(2));
		multipliedRates.dailyRate = parseFloat((multipliedRates.dailyRate * multiplier).toFixed(2));
		multipliedRates.weeklyRate = parseFloat((multipliedRates.weeklyRate * multiplier).toFixed(2));
		multipliedRates.monthlyRate = parseFloat((multipliedRates.monthlyRate * multiplier).toFixed(2));
	}

	return multipliedRates;
}

export function getBaseRates(shiftRateId, rates, rateConvention) {
	const multiplier = getRateMultiplier(shiftRateId);
	let rentalRates = { ...rates };
	if (rateConvention === 'price_per') {
		rentalRates = {
			hourlyRate: rates.price_per_hour,
			dailyRate: rates.price_per_day,
			weeklyRate: rates.price_per_week,
			monthlyRate: rates.price_per_month,
		};
	}

	if (shiftRateId > 1) {
		rentalRates.hourlyRate /= multiplier;
		rentalRates.dailyRate /= multiplier;
		rentalRates.weeklyRate /= multiplier;
		rentalRates.monthlyRate /= multiplier;
	}

	return rentalRates;
}
