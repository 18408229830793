import React from "react";
import PropTypes from "prop-types";

import Tooltip from "../../../tooltip/tooltip.js";

import assetStatusIcons from "../asset-status-icons.module.css";

const AssetDiagnosticCodesIcon = ( { asset, tooltipDirection } ) => (
  <Tooltip direction={tooltipDirection} text="Diagnostic Code">
    <div className={`${assetStatusIcons.diagnosticCodesIcon} ${assetStatusIcons.error}`}>{asset.diagnostic_codes.length}</div>
  </Tooltip>
);

AssetDiagnosticCodesIcon.propTypes = {
  asset: PropTypes.shape( {
    diagnostic_codes: PropTypes.array
  } ).isRequired,
  tooltipDirection: PropTypes.string,
};
AssetDiagnosticCodesIcon.defaultProps = {
  tooltipDirection: "right"
};

export default AssetDiagnosticCodesIcon;
