import angular from 'angular';
import ngModule from '../../ng/index.js';
import moment from 'moment-timezone';

import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';
import each from 'lodash/fp/each';
import flatten from 'lodash/fp/flatten';
import uniqBy from 'lodash/fp/uniqBy';

import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _map from 'lodash/map';
import _unionBy from 'lodash/unionBy';
import _get from 'lodash/get';
import toastService from '@services/toast.service.js';

import colorLegendTemplate from './color-legend/color-legend.html';

function DispatchHomeCtrl(
	$q,
	$interval,
	$scope,
	esApiFactoryV2,
	esAdminRentalService,
	esRentalOverdueTasksService,
	esDeliveriesService,
	esRentalActivityService,
	User,
	takeoverCard,
	$timeout,
	$mdDialog
) {
	const isRentalEndingTodayOrBefore = (rental) => rental && moment(rental.end_date).isSameOrBefore(moment(), 'day');
	const isDeliveryScheduleDateBefore = (delivery) => moment(delivery.scheduled_date).isBefore(moment().endOf('day'));
	const isOnRent = (rental) => rental && rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.ON_RENT_ID;
	const isOffRent = (rental) => rental && rental.rental_status_id === esAdminRentalService.RENTAL_STATUS.OFF_RENT_ID;
	const isSwapReturn = (delivery) => delivery._type === esDeliveriesService.deliveryTypes.SWAP_RETURN;
	const isFinalReturn = (delivery) => delivery._type === esDeliveriesService.deliveryTypes.FINAL_RETURN;
	const isReturn = (delivery) => isFinalReturn(delivery) || isSwapReturn(delivery);
	const isDeliveryStatusRequested = (delivery) =>
		delivery && delivery.delivery_status_id === esDeliveriesService.deliveryStatusIds.REQUESTED;
	const isDeliveryStatusCompleted = (delivery) =>
		delivery && delivery.delivery_status_id === esDeliveriesService.deliveryStatusIds.COMPLETED;

	const markUrgentReturnDelivery = (delivery) => {
		delivery._isUrgent = moment(delivery.rental.end_date).add(72, 'hours') < moment();

		return delivery;
	};

	const FILTER_DATE_FORMAT = 'MMM D';

	$scope.highlightedDrivers = new Set();
	$scope.explicitlyFocusedDeliveries = new Set(); // this overrides highlightedDrivers

	$scope.$on('angular-resizable.resizeEnd', () => {
		$scope.$broadcast('simple-map.resize');
	});

	$scope.sort = {
		orderBy: 'scheduled_date._i',
		reverse: false,
	};
	$scope.deliveries = [];
	$scope.mapDeliveries = [];
	$scope.overdueDeliveries = [];
	$scope.deliveryGroups = [
		{
			id: 'today',
			title: 'Today',
			collapsed: false,
			filter: (deliveries) => {
				const today = moment().startOf('day');

				return filter(
					(delivery) =>
						!isFinalReturn(delivery) &&
						!isDeliveryStatusCompleted(delivery) &&
						moment(delivery.scheduled_date).isSame(today, 'day')
				)(deliveries);
			},
		},
		{
			id: 'tomorrow',
			title: 'Tomorrow',
			collapsed: true,
			filter: (deliveries) => {
				const tomorrow = moment().add(1, 'day').startOf('day');

				return filter(
					(delivery) => !isFinalReturn(delivery) && moment(delivery.scheduled_date).isSame(tomorrow, 'day')
				)(deliveries);
			},
		},
		{
			id: 'dayAfterTomorrow',
			title: moment().add(2, 'days').format(FILTER_DATE_FORMAT),
			collapsed: true,
			category: 'deliveries',
			filter: (deliveries) => {
				const date = moment().add(2, 'day').startOf('day');

				return filter((delivery) => !isFinalReturn(delivery) && moment(delivery.scheduled_date).isSame(date, 'day'))(
					deliveries
				);
			},
		},
		{
			id: '3days',
			title: moment().add(3, 'days').format(FILTER_DATE_FORMAT),
			collapsed: true,
			category: 'deliveries',
			filter: (deliveries) => {
				const date = moment().add(3, 'day').startOf('day');

				return filter((delivery) => !isFinalReturn(delivery) && moment(delivery.scheduled_date).isSame(date, 'day'))(
					deliveries
				);
			},
		},
		{
			id: 'futureDeliveries',
			title: 'Future Deliveries',
			collapsed: true,
			filter: (deliveries) => {
				const startFuture = moment().add(4, 'days').startOf('day');

				return flow([
					filter((delivery) => !isFinalReturn(delivery) && moment(delivery.scheduled_date).isSameOrAfter(startFuture)),
					sortBy('scheduled_date'),
				])(deliveries);
			},
		},
		{
			id: 'assignedReturns',
			title: 'Assigned Returns',
			collapsed: true,
			filter: flow([
				filter((delivery) => {
					const swapReturnCondition = isSwapReturn(delivery) && moment(delivery.scheduled_date).isBefore(moment());
					const offRentCondition = isOffRent(delivery.rental) && isFinalReturn(delivery);

					return (
						(swapReturnCondition || offRentCondition) &&
						isDeliveryStatusRequested(delivery) &&
						!!delivery.driver_user_id
					);
				}),
				each(markUrgentReturnDelivery),
			]),
		},
		{
			id: 'unassignedReturns',
			title: 'Unassigned Returns',
			collapsed: false,
			filter: flow([
				filter((delivery) => {
					const swapReturnCondition = isSwapReturn(delivery) && moment(delivery.scheduled_date).isBefore(moment());
					const offRentCondition = isOffRent(delivery.rental) && isFinalReturn(delivery);

					return (
						(swapReturnCondition || offRentCondition) && isDeliveryStatusRequested(delivery) && !delivery.driver_user_id
					);
				}),
				each(markUrgentReturnDelivery),
				sortBy('destination.city.name'),
			]),
		},
		{
			id: 'completedToday',
			title: 'Completed Today',
			collapsed: true,
			filter: (deliveries) => {
				const dayStart = moment().startOf('day');
				const todayEnd = moment().endOf('day');

				return filter(
					(delivery) =>
						isDeliveryStatusCompleted(delivery) &&
						moment(delivery.scheduled_date).isSameOrAfter(dayStart) &&
						moment(delivery.scheduled_date).isSameOrBefore(todayEnd)
				)(deliveries);
			},
		},
		{
			id: 'offRentOrExtend',
			title: "Today's Off Rents or Extensions",
			collapsed: true,
			filter: (deliveries) => {
				return flow([
					filter(
						(delivery) =>
							delivery.rental &&
							(isDeliveryScheduleDateBefore(delivery) || isRentalEndingTodayOrBefore(delivery.rental)) &&
							isOnRent(delivery.rental) &&
							isFinalReturn(delivery)
					),
				])(deliveries);
			},
		},
	];
	$scope.state = 'init';
	$scope.searchQuery = '';
	$scope.assignedDeliveriesTabs = [
		{
			title: 'Today',
			filter(deliveries = []) {
				const today = moment().startOf('day');

				return deliveries.filter((delivery) => moment(delivery.scheduled_date).isSame(today, 'day'));
			},
		},
		{
			title: 'Tomorrow',
			filter(deliveries = []) {
				const tomorrow = moment().startOf('day').add(1, 'day');

				return deliveries.filter((delivery) => moment(delivery.scheduled_date).isSame(tomorrow, 'day'));
			},
		},
		{
			title: moment().startOf('day').add(2, 'days').format('MMM Do'),
			filter(deliveries = []) {
				const tomorrow = moment().startOf('day').add(2, 'day');

				return deliveries.filter((delivery) => moment(delivery.scheduled_date).isSame(tomorrow, 'day'));
			},
		},
		{
			title: moment().startOf('day').add(3, 'days').format('MMM Do'),
			filter(deliveries = []) {
				const tomorrow = moment().startOf('day').add(3, 'day');

				return deliveries.filter((delivery) => moment(delivery.scheduled_date).isSame(tomorrow, 'day'));
			},
		},
	];
	$scope.focusedDeliveries = [];

	let loadAndUpdateDeliveriesIntervalId;

	$scope.init = () => {
		takeoverCard.closeAll();
		$scope.marketId = User.getAttribute('dispatch.market', null);

		const loadMarkets = esApiFactoryV2
			.getMarkets()
			.then((markets) => {
				$scope.markets = markets;
				$scope.updateDeliveries();
			})
			.catch((err) => {
				toastService.showError(
					err.message || 'Something went wrong when loading the market list, please contact engineering.'
				);
			});

		const loadAndUpdateDeliveries = $scope.loadAndUpdateDeliveries();
		const loadOverdueDeliveries = $scope.loadOverdueDeliveries();

		// load and update deliveries every minute
		loadAndUpdateDeliveriesIntervalId = $interval(() => $scope.refreshAndUpdateDeliveries(), 60000);

		return $q.all([loadMarkets, loadAndUpdateDeliveries, loadOverdueDeliveries]).then($scope.updateDeliveries);
	};
	// remove the interval when we route somewhere else
	$scope.$on('$destroy', () => {
		if (angular.isDefined(loadAndUpdateDeliveriesIntervalId)) {
			$interval.cancel(loadAndUpdateDeliveriesIntervalId);
			loadAndUpdateDeliveriesIntervalId = undefined;
		}
	});

	$scope.selectDelivery = (delivery) => {
		if (delivery.rental) {
			$scope.rentalToEdit = delivery.rental;
			$scope.selectedDelivery = delivery;
			$scope.selectedTab = 3;
			takeoverCard.open('rentalEditTakeoverCard');
		} else {
			// For deliveries that are independent of a rental (ie. transports), show a simple takeover card
			$scope.selectedDelivery = delivery;
			$scope.rentalToEdit = null;
			takeoverCard.open('rentalEditTakeoverCard');
		}
	};

	$scope.onMarketChange = () => {
		$scope.clearDeliveryGroups();
		$scope.updateDeliveries();

		User.setAttribute('dispatch.market', $scope.marketId);

		$scope.loadAndUpdateDeliveries();
		$scope.loadOverdueDeliveries();
	};

	$scope.selectOverdueTaskGroup = (group) => {
		// If any task pertains to a specific delivery, show that delivery to the user
		const deliveryRelatedTask = _find(group.tasks, (task) => !!task.properties.delivery_id);

		if (deliveryRelatedTask) {
			esDeliveriesService.getDelivery(deliveryRelatedTask.properties.delivery_id).then($scope.selectDelivery);
		} else {
			// Just show the initial delivery for the rental
			esDeliveriesService
				.getDeliveryByRentalAndType(group.rental_id, esDeliveriesService.deliveryTypes.FINAL_RETURN)
				.then($scope.selectDelivery);
		}
	};

	$scope.focusDelivery = (delivery) => {
		if (delivery) {
			$scope.explicitlyFocusedDeliveries.add(delivery);
			$scope.updateFocusedDeliveries();
		}
	};

	$scope.unfocusDelivery = (delivery) => {
		if (delivery) {
			$scope.explicitlyFocusedDeliveries.delete(delivery);
			$scope.updateFocusedDeliveries();
		}
	};
	/** Filter the map view to highlight deliveries assigned to this driver, and any other drivers selected previously */
	$scope.focusDriver = (driver) => {
		$scope.highlightedDrivers.add(driver.user_id);
		$scope.updateFocusedDeliveries();
	};

	$scope.unfocusDriver = (driver) => {
		$scope.highlightedDrivers.delete(driver.user_id);
		$scope.updateFocusedDeliveries();
	};

	$scope.onDeliveryGroupToggle = () => {
		$scope.updateFocusedDeliveries();
		$scope.updateMapDeliveries();
	};

	let overdueDeliveriesSubscription;

	const getDeliveryAddOnAttributes = () => {
		return $scope.deliveries.map((delivery) => {
			const rentalTypeId = _get(delivery, 'rental.rental_type_id', null);
			if (rentalTypeId !== 3) {
				delivery._itemDescription = _get(delivery, 'rental.equipment_class.name')
					? delivery.rental.equipment_class.name
					: '--';
				delivery._itemId = _get(delivery, 'asset') ? _get(delivery, 'asset.asset_id', '--') : '--';
			} else {
				delivery._itemDescription = delivery.part_display
					? `${delivery.part_display} / Qty: ${delivery.quantity}`
					: '--';
				delivery._itemId = delivery.part_id ? delivery.part_id : '--';
			}

			return delivery;
		});
	};

	$scope.loadOverdueDeliveries = () => {
		if (!$scope.marketId) {
			return;
		}

		if (overdueDeliveriesSubscription) {
			overdueDeliveriesSubscription.unsubscribe();
		}

		overdueDeliveriesSubscription = esRentalOverdueTasksService
			.observeOverdueTasks($scope.marketId)
			.subscribe((overdue) => {
				$scope.overdueDeliveries = overdue;
			});
	};

	$scope.clearDeliveryGroups = () => {
		$scope.deliveryGroups.forEach(({ deliveries }) => (deliveries = []));
	};
	$scope.refreshAndUpdateDeliveries = () => {
		// no market was selected return
		if (!$scope.marketId) {
			return;
		}

		// load deliveries and update the existing deliveries
		$q.all([$scope.loadDeliveries(), $scope.loadCompletedTodayDeliveries()])
			.then((results) => [].concat(...results))
			.then((deliveries) => {
				$scope.deliveries = _unionBy($scope.deliveries, deliveries, 'delivery_id');
				getDeliveryAddOnAttributes();
			})
			.then($scope.updateDeliveries);
	};
	$scope.loadAndUpdateDeliveries = () => {
		if (!$scope.marketId) {
			return;
		}
		let load = [];

		$scope.state = 'loading';
		$scope.deliveries = [];

		load.push($scope.loadDeliveries());
		load.push($scope.loadCompletedTodayDeliveries());
		load.push($scope.loadExtendDeliveries());

		const loadId = ($scope.loadAndUpdateDeliveries.loadId = Math.random());

		load = _map(load, (q) => {
			return q
				.then((deliveries) => {
					if (loadId === $scope.loadAndUpdateDeliveries.loadId) {
						$scope.deliveries = _unionBy($scope.deliveries, deliveries, 'delivery_id');
						getDeliveryAddOnAttributes();
					}
				})
				.then($scope.updateDeliveries);
		});

		$q.all(load).then(() => {
			$scope.state = 'done';
		});

		return $q.all(load);
	};

	$scope.loadDeliveries = () => {
		return esDeliveriesService.getDeliveries({
			delivery_status: 'Requested',
			market_id: $scope.marketId,
			exclude_cancelled_rentals: true,
			exclude_cancelled_deliveries: true,
		});
	};
	$scope.loadCompletedTodayDeliveries = () => {
		return esDeliveriesService.getDeliveries({
			delivery_status: 'Completed',
			start: moment().startOf('day').valueOf(),
			end: moment().endOf('day').valueOf(),
			market_id: $scope.marketId,
			exclude_cancelled_rentals: true,
			exclude_cancelled_deliveries: true,
		});
	};
	$scope.loadExtendDeliveries = () => {
		return esDeliveriesService.getDeliveries({
			rental_status: 'On Rent',
			rental_end_before: moment().endOf('day').valueOf(),
			end: moment().endOf('day').valueOf(),
			market_id: $scope.marketId,
			exclude_cancelled_rentals: true,
			exclude_cancelled_deliveries: true,
		});
	};

	$scope.refreshDeliveriesByRentalId = (rental) => {
		return esDeliveriesService.refreshDeliveriesByRentalId(rental.rental_id).then($scope.updateDeliveries);
	};

	$scope.updateDeliveries = () => {
		$scope.updateDeliveryGroups();
		$scope.updateDriverListDeliveries();
		$scope.updateFocusedDeliveries();
		$scope.updateMapDeliveries();
	};

	$scope.updateDeliveryGroups = () => {
		_each($scope.deliveryGroups, (group) => {
			group.deliveries = group.filter($scope.deliveries);
		});
	};

	$scope.updateMapDeliveries = () => {
		$scope.mapDeliveries = flow([
			filter((g) => !g.collapsed),
			map((g) => g.deliveries),
			flatten,
			uniqBy('delivery_id'),
		])($scope.deliveryGroups);

		return $scope.mapDeliveries;
	};

	$scope.updateFocusedDeliveries = () => {
		if ($scope.explicitlyFocusedDeliveries.size > 0) {
			$scope.focusedDeliveries = [...$scope.explicitlyFocusedDeliveries];
		} else {
			$scope.focusedDeliveries = _filter($scope.deliveries, (delivery) => {
				return delivery.driver && $scope.highlightedDrivers.has(delivery.driver.user_id);
			});
		}
	};

	$scope.updateDriverListDeliveries = () => {
		if (!$scope.deliveries) {
			$scope.assignedDeliveriesTabs.forEach((tab) => {
				tab.deliveries = [];
			});
		} else {
			$scope.assignedDeliveriesTabs.forEach((tab) => {
				tab.deliveries = tab.filter($scope.deliveries);
			});
		}
	};
	$scope.showColorLegendModal = () => {
		$mdDialog.show({
			controller: 'ColorLegendCtrl',
			template: colorLegendTemplate,
			preserveScope: true,
			clickOutsideToClose: true,
		});
	};
	$scope.init();
}

ngModule.controller('DispatchHomeCtrl', DispatchHomeCtrl);
