import angular from 'angular';
import _remove from 'lodash/remove';
import ngModule from '../../../ng/index.js';

function OrderNoteManagerCtrl($scope, esApiFactoryV2) {
	$scope.init = function () {
		$scope.order._newOrderNote = {};
	};

	$scope.editOrderNote = function (orderNote) {
		orderNote._isEditing = true;
	};

	$scope.cancelEditOrderNote = function (orderNote) {
		orderNote._isEditing = false;
	};

	$scope.deleteOrderNote = function (order, orderNote) {
		esApiFactoryV2.adminDeleteOrderNote(orderNote.order_note_id).then(() => {
			_remove(order.notes, orderNote);
		});
	};

	$scope.saveNewOrderNote = function (NewOrderNoteForm, order) {
		if (NewOrderNoteForm.$invalid) {
			return false;
		}
		const postOrderNote = angular.copy(order._newOrderNote);

		esApiFactoryV2.adminPostOrderNote(order.order_id, postOrderNote).then((savedOrderNote) => {
			order.notes.push(savedOrderNote);
			NewOrderNoteForm.$setPristine();
			order._newOrderNote = {};
		});
	};

	$scope.init();
}

ngModule.controller('OrderNoteManagerCtrl', OrderNoteManagerCtrl);
