import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import tabsStyles from "./tabs.module.css";

export default class Tab extends Component { // eslint-disable-line react/prefer-stateless-function, react/require-optimization
  render() {
    let tabClasses = classNames(
      this.props.className,
      tabsStyles.panel,
      {
        [tabsStyles.verticalPanel]: this.context.orientation === "vertical",
        [tabsStyles.horizontalPanel]: this.context.orientation === "horizontal",
        [tabsStyles.noPadding]: this.props.noPadding,
        [tabsStyles.scrolling]: this.props.scrolling,
      } );

    return (
      <div
        aria-labelledby={this.props.id ? this.props.id + "__tab" : null}
        className={tabClasses}
        id={this.props.id ? this.props.id + "__panel" : null}
        role="tabpanel"
      >
        {this.props.children}
      </div>
    );
  }
}

Tab.contextTypes = {
  orientation: PropTypes.string
};

Tab.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  id: null,
  noPadding: false,
  scrolling: false,
  title: null,
};

export const TabPropTypes = {
  children: PropTypes.node,
  /** custom class */
  className: PropTypes.string,
  /** whether the tab is disabled or not */
  disabled: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  /** the id of the tab, used in the `selected` in the parent Tab component */
  id: PropTypes.string,
  /** disables padding for the tab */
  noPadding: PropTypes.bool,
  scrolling: PropTypes.bool,
  /** the title of the tab, this is displayed int eh tab menu */
  title: PropTypes.oneOfType( [ // eslint-disable-line react/no-unused-prop-types
    PropTypes.node,
    PropTypes.string
  ] ),
};

Tab.propTypes = TabPropTypes;
