import React from 'react';
import { react2angular } from 'react2angular';
import MobileNav from './mobile-nav.js';
import { ForBreakpoint } from '@components/breakpoints';
import withGlobalProviders from '@components/with-global-providers';
import ngModule from '../../ng/index.js';

const MobileNavWithBreakpoint = (props) => (
	<ForBreakpoint breakpoint="tablet-portrait-and-down">
		<MobileNav {...props} />
	</ForBreakpoint>
);

ngModule.component('reactMobileNav', react2angular(withGlobalProviders(MobileNavWithBreakpoint), ['onClick']));
