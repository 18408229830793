import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@equipmentshare/auth0-react';
import { createGlobalStyle } from 'styled-components';

import { SimpleApiProviderWithAuth } from '@equipmentshare/simple-json-api-client';
import { EuiProvider, THEME_TOKENS } from '@equipmentshare/ds2';

import ReactQueryProvider from './react-query-provider';
import { BreakpointsProvider } from '@components/breakpoints';
import { FeatureFlagProvider } from './feature-flag-provider';
import { reactQueryInventoryApolloClient } from '@services/graphql/inventory-apollo-client';
import { API_URL, AUTH0_API_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '../const/env';

const EuiTweaks = createGlobalStyle`
	.euiTableRow:hover {
		background-color: rgb(242, 245, 247);
	}
`;

const GlobalProviders: React.FC = ({ children }) => (
	<HashRouter basename="/">
		<ReactQueryProvider>
			<Auth0Provider
				clientId={AUTH0_CLIENT_ID as string}
				domain={AUTH0_DOMAIN as string}
				redirectUri={location.origin + location.pathname}
				audience={AUTH0_API_AUDIENCE as string}
				useRefreshTokens
				cacheLocation="localstorage"
				allowUnauthorizedAccess
			>
				<SimpleApiProviderWithAuth apiUrl={API_URL as string}>
					<ApolloProvider client={reactQueryInventoryApolloClient}>
						<FeatureFlagProvider>
							<BreakpointsProvider>
								<EuiTweaks />
								<EuiProvider modify={THEME_TOKENS}>{children}</EuiProvider>
							</BreakpointsProvider>
						</FeatureFlagProvider>
					</ApolloProvider>
				</SimpleApiProviderWithAuth>
			</Auth0Provider>
		</ReactQueryProvider>
	</HashRouter>
);

GlobalProviders.propTypes = {
	children: PropTypes.node,
};

export default GlobalProviders;
