import moment from 'moment';
import '@equipmentshare/es-api-client';
import '@equipmentshare/ng-components';
import { ReloadNotification } from '@equipmentshare/reload-notification';
import analyticsService from '@services/analytics.service';
import authService from '@services/auth.service';
import localCacheService from '@services/local-cache.service';
import iframeControllerService from './services/iframe-controller.service';
import { authLoaded } from './js/layout/layout-ctrl';
import { datadogRum } from '@datadog/browser-rum';
import { DD_APP_ID, DD_TOKEN, APP_VERSION, DD_ENV, NODE_ENV, API_URL } from '@const/env';

function setupCustomElements() {
	customElements.define('reload-notification', ReloadNotification);
}

function initDatadogRum() {
	if (NODE_ENV === 'production') {
		datadogRum.init({
			applicationId: DD_APP_ID,
			clientToken: DD_TOKEN,
			// `site` refers to the Datadog site parameter of your organization
			// see https://docs.datadoghq.com/getting_started/site/
			site: 'datadoghq.com',
			service: 'es-admin',
			env: DD_ENV,
			// Specify a version number to identify the deployed version of your application in Datadog
			version: APP_VERSION,
			sessionSampleRate: 100,
			sessionReplaySampleRate: 100,
			allowedTracingUrls: [(url) => url.startsWith(`${API_URL}`) && !url.startsWith(`${API_URL}/stripe`)],
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: 'allow',
			beforeSend: (event) => {
				const ignoredPhrases = ['TypeError: Failed to fetch', '[Response]', 'ChunkLoadError', 'Loading CSS Chunk'];
				if (event.type === 'error' && ignoredPhrases.some((phrase) => event.error.message.includes(phrase))) {
					event.context.ignored = true;
				}
				return true;
			},
		});
	}

	authService.getAuth0User().then(({ email, name }) => {
		const userId = authService.cookies.userId.get();
		datadogRum.setUser({
			email,
			name,
			id: String(userId),
		});
	});
}

export async function init() {
	try {
		await authService.setup();
	} catch (error) {
		authLoaded.reject(error);
		throw error;
	}

	try {
		initDatadogRum();
	} catch (error) {
		console.error(error);
		throw error;
	}

	try {
		// dont "await" for analytics setup. since its not critical for the app to run
		analyticsService.setup();
		analyticsService.updateAuthenticatedUser();
	} catch (e) {
		console.error('Failed to setup analytics', e);
	}

	try {
		window.Intercom('update', {
			alignment: 'left',
			vertical_padding: '150',
		});
	} catch (e) {
		console.error('Failed to update intercom widget', e);
	}

	setupCustomElements();

	// resolve the deferred promise. this will layout-ctrl to show the app
	authLoaded.resolve();

	// app setup is complete. message parent window
	iframeControllerService.sendInitializedMessage();

	// periodically cleanup the indexeddb cache
	setInterval(() => {
		localCacheService.pruneCache();
	}, moment.duration(10, 'minutes').as('milliseconds'));
}
