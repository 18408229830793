import esApi from './es-api.service';

export async function fetch(deliveryId, opts) {
	return await esApi.request(esApi.url`/admin/deliveries/${deliveryId}`, null, opts);
}

export async function search(query, opts) {
	return await esApi.request(esApi.url`/admin/deliveries`, query, opts);
}

export async function update(deliveryId, payload) {
	return await esApi.request(esApi.url`/admin/deliveries/${deliveryId}`, null, { body: payload, method: 'PATCH' });
}

async function create(payload) {
	return await esApi.request(esApi.url`/admin/deliveries`, null, { body: payload, method: 'POST' });
}

const deliveriesService = {
	search,
	fetch,
	update,
	create,
};

export default deliveriesService;
