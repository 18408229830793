/* eslint-disable react/no-danger */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import MarkdownIt from "markdown-it";
import classNames from "classnames";

const defaultOptions = {
  html: false,
  breaks: true,
  typographer: true,
};

export class Markdown extends PureComponent {
  renderMarkdown( source ) {
    let { options } = this.props;

    if ( !this.md ) {
      this.md = new MarkdownIt( Object.assign( {}, defaultOptions, options || {} ) );
    }

    return this.md.render( source );
  }

  render() {
    let { container: Container, className, source, ...props } = this.props;

    return (
      <Container
        className={classNames( className )}
        dangerouslySetInnerHTML={source ? { __html: this.renderMarkdown( source ) } : null}
        {...props}
      />
    );
  }
}

Markdown.propTypes = {
  className: PropTypes.string,
  container: PropTypes.oneOfType( [ PropTypes.string, PropTypes.func ] ),
  options: PropTypes.shape( { html: PropTypes.bool, linkify: PropTypes.bool, typographer: PropTypes.bool, breaks: PropTypes.bool } ),
  source: PropTypes.string.isRequired,
};
Markdown.defaultProps = {
  className: null,
  container: "div",
  options: null
};

export default Markdown;
