import React, { Fragment } from 'react';
import { formatAddress } from '@equipmentshare/admin-hooks';

const locationOptionRenderer = (location) => (
	<div>
		{location.nickname ? (
			<Fragment>
				<strong>{location.nickname}</strong>
				<br />
				<small>{formatAddress(location, false)}</small>
			</Fragment>
		) : (
			<span>{formatAddress(location, false)}</span>
		)}
	</div>
);

export default locationOptionRenderer;
