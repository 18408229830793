import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import loadingAnimationStyles from "./loading-animation.module.css";

/**
 * Loading Animation: Renders an animated loading indicator.
*/

export default function LoadingAnimation( { isVisible, isSmall } ) {
  let loadingClasses = classNames(
    loadingAnimationStyles.loadingAnimation,
    { [loadingAnimationStyles.small]: isSmall } );

  if ( isVisible ){
    return(
      <div className={loadingClasses}>
        <div className={loadingAnimationStyles.bounce1} />
        <div className={loadingAnimationStyles.bounce2} />
        <div className={loadingAnimationStyles.bounce3} />
      </div>
    );
  }

  return null;
}

LoadingAnimation.propTypes = {
  isSmall: PropTypes.bool,
  isVisible: PropTypes.bool,
};

LoadingAnimation.defaultProps = {
  isSmall: false,
  isVisible: false,
};
