import React, { useState, useRef, useEffect } from 'react';
import {
	EuiPopover,
	EuiPopoverTitle,
	EuiFormRow,
	EuiSuperSelect,
	EuiTextArea,
	EuiText,
	EuiSpacer,
	EuiIcon,
	EuiButton,
	EuiButtonEmpty,
} from '@elastic/eui';
import useRentalCancellation from '@hooks/data/use-cancel-reason-type';
import './cancel-reasons-styling.css';

const RentalCancellationPopover = ({ rentalId, cancelRental, onClose }) => {
	const dummyButton = <div style={{ display: 'none' }} />;
	const [note, setNote] = useState('');
	const [value, setValue] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { mutateAsync, isLoading } = useRentalCancellation();
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const onConfirm = async () => {
		if (isSubmitting) return;

		setIsSubmitting(true);
		try {
			await mutateAsync({
				rentalId,
				cancelType: value,
				cancelNote: note,
			});

			try {
				await cancelRental();

				if (isMounted.current) {
					setValue('');
					setNote('');
					onClose();
				}
			} catch (cancelError) {
				console.error('Error in cancelRental:', cancelError);
				throw cancelError;
			}
		} catch (err) {
			if (isMounted.current) {
				console.error('Error in cancellation process:', err);
				if (err?.status === 500) {
					console.error('Server error during cancellation');
				}
			}
		} finally {
			if (isMounted.current) {
				setIsSubmitting(false);
			}
		}
	};

	const options = [
		{
			value: 'chose_competitor',
			inputDisplay: 'Chose a competitor',
		},
		{
			value: 'duplicate_or_order_error',
			inputDisplay: 'Duplicate/order error',
		},
		{
			value: 'customer_rates',
			inputDisplay: 'Pricing',
		},
		{
			value: 'customer_dont_need',
			inputDisplay: 'No longer need',
		},
		{
			value: 'customer_weather',
			inputDisplay: 'Weather/project delay',
		},
		{
			value: 'eqs_availability',
			inputDisplay: 'Asset unavailable',
		},
		{
			value: 'eqs_rates',
			inputDisplay: 'Quote issue',
		},
		{
			value: 'eqs_delivery',
			inputDisplay: 'Delivery/timeline',
		},
		{
			value: 'other',
			inputDisplay: 'Other',
		},
	];

	const handleReasonChange = (selectedValue) => {
		setValue(selectedValue);
		if (selectedValue !== 'other') {
			setNote('');
		}
	};

	const placeholderText =
		value === 'other' ? 'Please provide additional context' : 'Include additional context (Optional)';

	const isNoteInvalid = value === 'other' && !note;

	return (
		<EuiPopover
			button={dummyButton}
			isOpen={true}
			closePopover={onClose}
			anchorPosition="downCenter"
			panelPaddingSize="s"
			repositionOnScroll
		>
			<div style={{ width: '350px' }}>
				<EuiPopoverTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					Cancel Rental
					<EuiIcon type="cross" onClick={onClose} style={{ cursor: 'pointer' }} />
				</EuiPopoverTitle>
				<div style={{ padding: '12px' }}>
					<EuiText size="s">
						<p>Please select the reason for canceling this rental.</p>
					</EuiText>
					<EuiSpacer size="m" />
					<EuiFormRow label="Reason">
						<EuiSuperSelect
							options={options}
							valueOfSelected={value}
							placeholder="Select a Cancel Reason"
							onChange={handleReasonChange}
							itemLayoutAlign="top"
							hasDividers
						/>
					</EuiFormRow>
					<EuiFormRow label="Note" isInvalid={isNoteInvalid}>
						<EuiTextArea
							placeholder={placeholderText}
							value={note}
							onChange={(e) => setNote(e.target.value)}
							rows={4}
							className={isNoteInvalid ? 'placeholder-red' : ''}
							isInvalid={isNoteInvalid}
						/>
					</EuiFormRow>
					<EuiSpacer size="m" />
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 12px 12px' }}>
					<EuiButtonEmpty onClick={onClose} disabled={isLoading} color="primary" style={{ marginRight: '8px' }}>
						Cancel
					</EuiButtonEmpty>
					<EuiButton
						onClick={onConfirm}
						color="primary"
						isLoading={isLoading}
						disabled={isLoading || !value || (value === 'other' && !note)}
					>
						Confirm Cancellation
					</EuiButton>
				</div>
			</div>
		</EuiPopover>
	);
};

export default RentalCancellationPopover;
