export const RATE_TYPE_IDS = {
	BOOK_RATE_TYPE_ID: 1,
	BENCHMARK_RATE_TYPE_ID: 2,
	FLOOR_RATE_TYPE_ID: 3,
	COMPANY_RATE_TYPE_ID: 4,
	LOCATION_RATE_TYPE_ID: 5,
	CUSTOM_RATE_TYPE_ID: 6,
	ADVERTISED_RATE_TYPE_ID: 7,
};

export const RATE_TYPES = {
	[RATE_TYPE_IDS.BOOK_RATE_TYPE_ID]: 'Book',
	[RATE_TYPE_IDS.BENCHMARK_RATE_TYPE_ID]: 'Benchmark',
	[RATE_TYPE_IDS.COMPANY_RATE_TYPE_ID]: 'Company',
	[RATE_TYPE_IDS.FLOOR_RATE_TYPE_ID]: 'Floor',
	[RATE_TYPE_IDS.LOCATION_RATE_TYPE_ID]: 'Location',
	[RATE_TYPE_IDS.ADVERTISED_RATE_TYPE_ID]: 'Advertised',
	[RATE_TYPE_IDS.CUSTOM_RATE_TYPE_ID]: 'Manual',
};

export const RATE_TYPE_ORDERING_WEIGHTS = {
	[RATE_TYPE_IDS.LOCATION_RATE_TYPE_ID]: 100,
	[RATE_TYPE_IDS.COMPANY_RATE_TYPE_ID]: 90,
	[RATE_TYPE_IDS.BOOK_RATE_TYPE_ID]: 80,
	[RATE_TYPE_IDS.ADVERTISED_RATE_TYPE_ID]: 70,
	[RATE_TYPE_IDS.BENCHMARK_RATE_TYPE_ID]: 60,
	[RATE_TYPE_IDS.FLOOR_RATE_TYPE_ID]: 50,
	[RATE_TYPE_IDS.CUSTOM_RATE_TYPE_ID]: 40,
};

export const RATES_BE_TO_FE_MAP = {
	price_per_hour: { stateKey: 'hourlyRate', name: 'Hourly Rate' },
	price_per_day: { stateKey: 'dailyRate', name: 'Daily Rate' },
	price_per_week: { stateKey: 'weeklyRate', name: 'Weekly Rate' },
	price_per_month: { stateKey: 'monthlyRate', name: '4-Week Rate' },
};

export const RATES_FE_TO_BE_MAP = {
	hourlyRate: { name: 'Hour Rate' },
	dailyRate: { name: 'Day Rate' },
	weeklyRate: { name: 'Week Rate' },
	monthlyRate: { name: '4-Week Rate' },
};

export const SHIFT_MULTIPLIERS = {
	single: 1,
	double: 1.5,
	triple: 2,
};

export const SHIFT_RATE_TYPES = {
	single: 1,
	double: 2,
	triple: 3,
};
