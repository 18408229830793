import esApi from './es-api.service';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

import { RENTAL_LINE_ITEM_TYPES, LINE_ITEM_TYPES } from '@const/invoices';
import { PARTS_RETAIL_SALE_LINE_ITEM_TYPES } from '@equipmentshare/billing-common/types/LineItemType';

async function update(invoiceId, payload) {
	return await esApi.request(esApi.url`/invoices/${invoiceId}`, null, {
		body: payload,
		method: 'PATCH',
	});
}

async function getPaymentApplications(invoiceId) {
	return await esApi.request(`/billing/invoices/${invoiceId}/payment-applications`);
}

function getRentalLineItem(invoice) {
	return _get(invoice, 'line_items', []).find((lineItem) =>
		_includes(RENTAL_LINE_ITEM_TYPES, lineItem.line_item_type_id)
	);
}

function isRentalInvoice(invoice) {
	return !!getRentalLineItem(invoice);
}

function isRetailSaleInvoice({ line_items }) {
	return line_items.some((li) => !!li.line_item_type_id && PARTS_RETAIL_SALE_LINE_ITEM_TYPES.has(li.line_item_type_id));
}

export async function estimate(metaData = {}, lineItemsPayload = []) {
	if (lineItemsPayload.length === 0) {
		return {
			tax_amount: 0,
			line_items: [],
		};
	}

	const payload = {
		...metaData,
		line_items: lineItemsPayload,
	};

	return await esApi.request(
		'/admin/taxes/invoice-estimate',
		{},
		{
			body: payload,
			method: 'POST',
		}
	);
}

async function requestPdfAccess(invoiceId) {
	return await esApi.request(esApi.url`/billing/invoices/${invoiceId}/pdf`);
}

const invoicesService = {
	update,
	estimate,
	isRentalInvoice,
	getRentalLineItem,
	getPaymentApplications,
	requestPdfAccess,
	isRetailSaleInvoice,
};

export default invoicesService;
