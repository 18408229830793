import React from "react";
import PropTypes from "prop-types";

import LoadingAnimation from "../loading-animation/loading-animation.js";

import loadingOverlayStyles from "./loading-overlay.module.css";

/**
 * Loading Overlay: Creates a semi-transparent mask which overlays a loading component. Displays the loading animation, centered on the overlay.

 * NOTE: The overlay uses position: absolute. It will position itself relative to its first ancestor with !position: static.
 * You need to set position: relative || fixed || absolute || sticky on the parent.
*/

export default function LoadingOverlay( { isLoading, smallAnimation } ) {
  if ( !isLoading ) {
    return null;
  }

  return (
    <div className={loadingOverlayStyles.loadingOverlay}>
      <LoadingAnimation  isSmall={smallAnimation} isVisible />
    </div>
  );
}

LoadingOverlay.propTypes = {
  /** controls the visibility of the overlay */
  isLoading: PropTypes.bool.isRequired,
  /** renders a smaller version of the loading animation */
  smallAnimation: PropTypes.bool,
};

LoadingOverlay.defaultProps = {
  smallAnimation: false,
};
