import ngModule from '../../../ng/index.js';

import template from './rental-takeover-card.html';
import rentalsService from '@services/rentals.service.js';
import './rental-takeover-card-service.js';
import { rentalXFields } from '../../../views/rentals/providers/xFields';

function RentalTakeoverCardCtrl($scope, rentalTakeoverCardService) {
	$scope.rental = null;
	$scope.service = rentalTakeoverCardService;

	const opts = { headers: { 'X-Fields': rentalXFields } };

	$scope.$watch(
		() => rentalTakeoverCardService.rentalId,
		(newValue) => {
			if (newValue) {
				this.fetchRental();
			}
		}
	);

	this.fetchRental = async () => {
		const rental = await rentalsService.fetch(rentalTakeoverCardService.rentalId, opts);
		$scope.rental = rental;
		$scope.$digest();
	};

	$scope.toggleExpanded = () => {
		rentalTakeoverCardService.setExpanded(!rentalTakeoverCardService.expanded);
	};

	$scope.onRentalChange = (...args) => {
		$scope.service.rentalChangeListeners.forEach((fn) => {
			fn(...args);
		});
	};

	$scope.close = () => {
		$scope.service.isOpen = false;
	};
}

ngModule
	.component('rentalTakeoverCard', {
		template,
		controller: 'RentalTakeoverCardCtrl',
		bindings: {
			onUpdate: '&',
			onClose: '&',
		},
	})
	.controller('RentalTakeoverCardCtrl', RentalTakeoverCardCtrl);
