import React from 'react';
import { formatAddress } from '@equipmentshare/admin-hooks';

const CompanyOptionRenderer = (company) => (
	<div className="company-picker__company-option">
		<strong>{company.name}</strong>
		<br />
		<span>{company.billing_location ? formatAddress(company.billing_location, false) : ''}</span>
	</div>
);

export default CompanyOptionRenderer;
