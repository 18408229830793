import esApi from '@services/es-api.service';
import _get from 'lodash/get';
import { CDN_URL, PLACEHOLDER_IMAGE_URL } from '@const/env';
import moment from 'moment-timezone';

const assetTypes = {
	vehicle: 2,
	equipment: 1,
};

export const VALID_TYPES = {
	images: 'cdn',
	other: 'cdn_direct',
};

const prepareUrl = (files, { type }) => {
	const uploadEndpoint = esApi.buildEndpoint('/uploads');
	const encodedFileNames = encodeURIComponent(files.map((file) => file.name.replace(/,/g, '')).join(','));
	return `${uploadEndpoint}?file=${encodedFileNames}&type=${type}`;
};

const getPresignedUrls = async (url, accessToken) => {
	const response = await fetch(url, { headers: { authorization: `Bearer ${accessToken}` } });
	return response.json();
};

const uploadFiles = async (files, { type }, accessToken) => {
	if (!files.length) {
		return Promise.reject();
	}

	const preparedUrl = prepareUrl(files, { type });
	const presignedUrls = await getPresignedUrls(preparedUrl, accessToken);

	return await Promise.all(
		Object.values(presignedUrls).map(async (url, i) => {
			const response = await fetch(url.upload_url, { method: 'PUT', body: files[i] });
			return { ok: response.ok, ...url };
		})
	);
};

const processUploadResponse = (responses, files) => {
	if (responses && responses.length) {
		return responses
			.map((response, i) => {
				if (response.ok) {
					const file = files[i];
					return {
						date_updated: moment(file.lastModified).toISOString(),
						mime_type: file.type,
						original_filename: file.name,
						download_filename: response.download_filename,
						size_bytes: file.size,
						url: response.download_url,
					};
				}

				return undefined;
			})
			.filter(Boolean);
	}

	return [];
};

export const useFileUpload = async (files) => {
	let filesToProcess = files;

	if (!Array.isArray(files)) {
		filesToProcess = [files].filter(Boolean);
	}

	if (!filesToProcess.length) {
		return Promise.reject();
	}

	const imagesToUpload = filesToProcess.filter((file) => file.type.includes('image'));
	const filesToUpload = filesToProcess.filter((file) => !file.type.includes('image'));

	const imageResponse = imagesToUpload.length ? await uploadFiles(imagesToUpload, { type: VALID_TYPES.images }) : [];

	const fileResponse = filesToUpload.length ? await uploadFiles(filesToUpload, { type: VALID_TYPES.other }) : [];

	const processedImageResponses = processUploadResponse(imageResponse, imagesToUpload);
	const processedFileResponses = processUploadResponse(fileResponse, filesToUpload);

	return [...processedImageResponses, ...processedFileResponses];
};

export async function create(download_filename, userId) {
	const formData = new FormData();
	formData.append('filename', download_filename);
	formData.append('user_id', userId);

	try {
		var response = await esApi.request(esApi.url`/photos-v2`, null, { body: formData, method: 'POST' });
		return response;
	} catch (error) {
		console.error('There has been a problem with your fetch operation: ', error.message);
	}
}

function imageHasSize(image, size) {
	return size && image && Boolean(image[size]);
}

function getSuffix(asset, type) {
	let suffix = '';
	const preferOrder = ['small', 'thumbnail', 'medium', 'large'];

	if (imageHasSize(asset.photo, type)) {
		suffix = `${type}/`;
	} else if (!type) {
		preferOrder.forEach((order) => {
			if (!suffix && imageHasSize(asset.photo, order)) {
				suffix = `${order}/`;
			}
		});
	}

	return suffix;
}
function getDefaultImageUrl(asset) {
	const isVehicle = asset.asset_type_id === assetTypes.vehicle;

	return `${PLACEHOLDER_IMAGE_URL}/${isVehicle ? 'vehicleplaceholder.png' : 'equipmentplaceholder.png'}`;
}

export function fetchURL(asset) {
	const defaultImageUrl = getDefaultImageUrl(asset);

	const suffix = getSuffix(asset);

	if (asset.photo && asset.photo.filename) {
		return `${CDN_URL}/uploads/${suffix}${asset.photo.filename}`;
	}

	return defaultImageUrl;
}

const imageService = {
	create,
	fetchURL,
	uploadFiles,
};

export default imageService;
