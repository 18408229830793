import { IS_DEV } from '../const/env';
import analyticsService from './analytics.service';
import toastService from './toast.service.js';

const defaultErrorMessage = 'An unexpected error occurred. Please contact engineering';

export class UserError extends Error {
	constructor(message) {
		super(message);

		this.showToast = true;
	}
}

async function handleError(error, defaultMessage = defaultErrorMessage) {
	const description = null;
	const metadata = {
		origin: 'frontend',
		browserUrl: location.href,
	};

	toastService.makeToast({
		title: error.showToast ? error.message || defaultMessage : defaultMessage,
		description,
		type: 'error',
		metadata,
		burnIn: 20,
	});

	analyticsService.error(error);
	console.error(error);
}
async function handleFailedResponse(res, defaultMessage = defaultErrorMessage) {
	let message = defaultMessage;
	let description = null;
	const metadata = {
		origin: 'api request',
		browserUrl: location.href,
		apiRequestUrl: res.url,
		statusCode: res.status,
	};

	try {
		let responseMessage = null;
		const contentType = res.headers.get('content-type');

		if (contentType.includes('text/plain')) {
			responseMessage = await res.clone().text();
		} else {
			const body = await res.clone().json();
			responseMessage = body.message;
		}

		if (typeof responseMessage === 'string') {
			message = responseMessage;
		} else if (typeof responseMessage === 'object') {
			const stringifiedObject = Object.keys(responseMessage)
				.reduce((arr, field) => arr.concat(`${field}: ${responseMessage[field]}`), [])
				.join('\n');

			message = stringifiedObject;
		} else if (res.status === 403) {
			message = 'You do not have permission to perform this action';
		} else {
			description = 'Unable to read error message from response body';
		}
	} catch (e) {
		description = `Failed to parse failed api request: ${e.message}`;
	}

	toastService.makeToast({
		title: message,
		description,
		type: 'error',
		metadata,
		burnIn: 20,
	});
}

async function genericErrorHandler(resOrErr, message) {
	if (resOrErr instanceof Response) {
		return await handleFailedResponse(resOrErr, message);
	} else {
		return await handleError(resOrErr, message);
	}
}

const errorHandlerService = {
	UserError,
	genericErrorHandler,
	handleError,
	handleFailedResponse,
};

if (IS_DEV) {
	window.errorHandlerService = errorHandlerService;
}

export default errorHandlerService;
