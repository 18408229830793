import React from 'react';
import PropTypes from 'prop-types';

import ColumnProvider from './column-provider.js';
import SortProvider from './sort-provider.js';
import FilterProvider from './filter-provider.js';

const TableProvider = ({ children }) => {
	return (
		<ColumnProvider>
			<SortProvider>
				<FilterProvider>{children}</FilterProvider>
			</SortProvider>
		</ColumnProvider>
	);
};

TableProvider.propTypes = {
	children: PropTypes.node,
};

export default TableProvider;
