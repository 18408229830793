import moment from 'moment-timezone';

export function formatCurrency(num, decimals = 2, fallback = '--') {
	return Number.isFinite(num)
		? num.toLocaleString('en-US', { minimumFractionDigits: decimals, style: 'currency', currency: 'USD' })
		: fallback;
}
export function formatPercent(num, decimals = 2, fallback = '--') {
	return Number.isFinite(num)
		? num.toLocaleString('en-US', { minimumFractionDigits: decimals, style: 'percent' })
		: fallback;
}
export function formatFixedNumber(num, decimals = 2, fallback = '--') {
	return Number.isFinite(num) ? num.toFixed(decimals) : fallback;
}

export const DATE_FORMATS = {
	short: 'M/D/YYYY',
	normal: 'MMM Do, YYYY',
	long: 'MMM Do, YYYY hh:mm a',
};
export function formatDate(date, format = 'normal', tz, fallback = '--') {
	if (!date) {
		return fallback;
	}

	let momentDate = moment(date);

	if (tz) {
		momentDate = momentDate.tz(tz);
	}

	if (format in DATE_FORMATS) {
		return momentDate.format(DATE_FORMATS[format]);
	}

	return momentDate.format(format);
}

export function formatPhoneNumber(phone) {
	const cleaned = String(phone).replace(/[^\d]/g, '');
	const match = cleaned.match(/^(\d+)?(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		if (match[1]) {
			return `+${match[1]} ${[match[2], match[3], match[4]].join('-')}`;
		}

		return [match[2], match[3], match[4]].join('-');
	}

	return null;
}

export function stringToCamelCase(text) {
	return text.replace(/^([A-Z])|[\s-_/]+(\w)/g, (match, p1, p2) => {
		if (p2) {
			return p2.toUpperCase();
		} else {
			return p1.toLowerCase();
		}
	});
}
