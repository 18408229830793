import React from 'react';
import PropTypes from 'prop-types';
import _concat from 'lodash/concat';

import { Autocomplete } from '@equipmentshare/react-ui-components';

import useCachedLoader from '@hooks/use-cached-loader';
import localCache from '@services/local-cache.service';
import shiftsService from '@services/shifts.service';

const ShiftPicker = ({ onChange, placeholder = 'None', selectedShiftId, ...rest }) => {
	const getShiftsService = async () => {
		const options = await shiftsService.search();
		return options;
	};

	const { result: shiftOptions, loaded } = useCachedLoader(
		localCache.createEntityKey('shifts'),
		getShiftsService,
		[],
		false
	);

	return (
		<>
			<Autocomplete
				showLoading={!loaded}
				labelKey="name"
				onChange={onChange}
				options={shiftOptions}
				placeholder={placeholder}
				simpleValue={false}
				disableFiltering={true}
				removeSelected={false}
				value={selectedShiftId}
				valueKey="shift_type_id"
				{...rest}
			/>
		</>
	);
};

ShiftPicker.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	selectedShiftId: PropTypes.number,
	disabled: PropTypes.bool,
};

export default ShiftPicker;
