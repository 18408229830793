import ngModule from '../../../../ng/index.js';
import template from './equipment-class-picker.html';

ngModule.component('equipmentClassPicker', {
	template,
	controller: 'EquipmentClassPickerCtrl as ctrl',
	bindings: {
		ngModel: '=',
		onClassChange: '&',
		autoFocus: '<?',
		isRequired: '<',
		name: '@',
		showHelperText: '<',
		ngBlur: '&',
		ngFocus: '&',
		ngDisabled: '<?',
	},
});
