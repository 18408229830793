import ngModule from '../../../../ng/index.js';
import _debounce from 'lodash/debounce';
import { convertPlaceToLocation } from '@services/google-places.service';

function AddressPickerGmapCtrl($scope, esLocationService, $timeout, esEnv, $q, $log) {
	const DEFAULT_ZOOM = 10;

	const COMO_LAT_LNG = {
		latitude: 38.95,
		longitude: -92.33,
	};
	const NZ_LAT_LNG = {
		latitude: -37.007226,
		longitude: 174.862675,
	};

	$scope.mapId = Math.round(Math.random() * 10000);

	$scope.inferAddress = _debounce(() => {
		const geocoder = new google.maps.Geocoder();
		const latLng = new google.maps.LatLng($scope.ngModel.latitude, $scope.ngModel.longitude);

		reverseGeocodeLatLng(geocoder, latLng)
			.then((location) => {
				$scope.ngModel.city = location.city;
				$scope.ngModel.zip_code = location.zip_code;
				$scope.ngModel.street_1 = location.street_1;

				if (location.state == 'New Zealand') {
					$scope.ngModel.state_id = 60;
				} else {
					esLocationService
						.getStateByName(location.state.name)
						.then((stateObj) => {
							$scope.ngModel.state_id = stateObj.state_id;
						})
						.catch((rejection) => {
							$log.warn('Get state by name rejected:', rejection);
						});
				}
			})
			.catch((rejection) => {
				$log.error('Reverse Geocode rejected', rejection);
			});
	}, 300);

	$scope.updateNgModalFromMap = () => {
		$scope.ngModel.latitude = $scope.map.center.lat();
		$scope.ngModel.longitude = $scope.map.center.lng();
	};
	$scope.updateMapFromNgModal = () => {
		const latLng = new google.maps.LatLng($scope.ngModel.latitude, $scope.ngModel.longitude);

		$scope.map.setCenter(latLng);
		$scope.marker.setPosition(latLng);
	};

	$scope.createMap = () => {
		$scope.ngModel = $scope.ngModel || {};
		const isNewZealandApp = esEnv.getHost().includes('co.nz');

		if (isNewZealandApp) {
			$scope.ngModel.latitude = $scope.ngModel.latitude || NZ_LAT_LNG.latitude;
			$scope.ngModel.longitude = $scope.ngModel.longitude || NZ_LAT_LNG.longitude;
		} else {
			$scope.ngModel.latitude = $scope.ngModel.latitude || COMO_LAT_LNG.latitude;
			$scope.ngModel.longitude = $scope.ngModel.longitude || COMO_LAT_LNG.longitude;
		}

		$scope.map = new google.maps.Map(document.getElementById(`address-picker-gmap-${$scope.mapId}`), {
			center: { lat: 38.9517, lng: -92.3341 },
			zoom: DEFAULT_ZOOM,
		});

		$scope.marker = new google.maps.Marker({
			map: $scope.map,
			draggable: true,
			scrollwheel: !!$scope.allowScrollZoom,
			anchorPoint: new google.maps.Point(0, -29),
		});
		$scope.marker.addListener('dragend', () => {
			$scope.map.setCenter($scope.marker.getPosition());

			$scope.updateNgModalFromMap();
			if ($scope.dragEndCallback) {
				const latitude = $scope.marker.getPosition().lat();
				const longitude = $scope.marker.getPosition().lng();

				$scope.dragEndCallback(latitude, longitude);
			}
		});

		const element = document.getElementById(`address-picker-gmap-${$scope.mapId}-search`);

		$scope.autocomplete = new google.maps.places.Autocomplete(element);
		$scope.autocomplete.setTypes([]);

		$scope.autocomplete.addListener('place_changed', () => {
			const place = $scope.autocomplete.getPlace();

			if (!place.geometry) {
				return console.log(`No details available for input: '${place.name}'`);
			}

			// If the place has a geometry, then present it on a map.
			if (place.geometry.viewport) {
				$scope.map.fitBounds(place.geometry.viewport);
			} else {
				$scope.map.setCenter(place.geometry.location);
				$scope.map.setZoom(15);
			}

			$scope.marker.setPosition(place.geometry.location);
			$scope.updateNgModalFromMap();
			$scope.inferAddress();

			if ($scope.dragEndCallback) {
				const latitude = $scope.marker.getPosition().lat();
				const longitude = $scope.marker.getPosition().lng();

				$scope.dragEndCallback(latitude, longitude);
			}
		});

		$scope.updateMapFromNgModal();
	};

	$timeout($scope.createMap, 0);

	function reverseGeocodeLatLng(geocoder, latLng) {
		const deferred = $q.defer();

		geocoder.geocode({ location: latLng }, (results, status) => {
			if (status === 'OK') {
				if (results) {
					const cityStateZipFromGeocodeResults = convertPlaceToLocation(results[0]);

					deferred.resolve(cityStateZipFromGeocodeResults);
				} else {
					console.warn('No results found');
					deferred.reject('reverseGeocodeLatLng: ' + 'No results found');
				}
			} else {
				console.warn(`Geocoder failed due to: ${status}`);
				deferred.reject(`${'reverseGeocodeLatLng: ' + 'Geocoder failed due to: '}${status}`);
			}
		});

		return deferred.promise;
	}
}

ngModule.controller('AddressPickerGmapCtrl', AddressPickerGmapCtrl);
