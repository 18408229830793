import angular from 'angular';
import ngModule from '../../../ng/index.js';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';

import toastService from '@services/toast.service.js';
import assetsService from '@services/assets.service';
import { difference } from '@helpers/utilities';
import MapMarkers from '../../components/maps';
import errorHandlerService from '@services/error-handler.service.js';
import companiesService from '@services/companies.service.js';
import authService from '@services/auth.service.js';
import { markerColors } from '@const/map';

export default function EquipmentEditFormCtrl(
	$scope,
	User,
	esEquipmentService,
	esApiFactoryV2,
	esAdminRentalService,
	APPLICATION_ID
) {
	const companyViewSettings = { assetMarkerColors: {} };

	$scope.init = () => {
		delete $scope.equipment;
		$scope.gettingEquipmentData = true;
		$scope.fileChosen = false;
		$scope.markets = esAdminRentalService.markets;
		$scope.companyId = User.getCompanyId();
		$scope.saving = false;

		$scope.colorChoices = markerColors.choices.map((choice) => ({ backgroundColor: choice }));
		$scope.selectedColor = markerColors.default;

		assetsService
			.fetchAsset($scope.equipmentId)
			.then((asset) => {
				esEquipmentService.equipment = angular.copy(asset);
				$scope.equipment = setEquipmentProperties(esEquipmentService.equipment);
				$scope.selectedColor = $scope.equipment.marker_color ? $scope.equipment.marker_color : markerColors.default;

				if (!$scope.assetColor) {
					fetchCompanyViewSettings();
				} else {
					$scope.selectedColor = $scope.assetColor;
				}
				$scope.gettingEquipmentData = false;
				$scope.$digest();
			})
			.then($scope.fetchAssetStatus)
			.catch((e) => errorHandlerService.genericErrorHandler(e));
	};

	$scope.fetchAssetStatus = () => {
		return esApiFactoryV2.getAssetStatus($scope.equipmentId).then((assetStatus) => {
			$scope.equipment.asset_status = assetStatus;
			$scope.equipment.hours = _get(assetStatus, 'hours');
		});
	};

	$scope.$watch('equipmentId', (newId, oldId) => {
		if (newId !== oldId) {
			$scope.init();
		}
	});

	$scope.selectColor = (colorHex) => {
		$scope.selectedColor = colorHex;
	};

	$scope.onRentalBranchChange = (branch) => {
		if ($scope.equipment) {
			$scope.equipment.rental_branch_id = _get(branch, 'id', null);
		}
	};

	$scope.onMaintenanceBranchChange = (branch) => {
		if ($scope.equipment) {
			$scope.equipment.service_branch_id = _get(branch, 'id', null);
		}
	};

	$scope.save = () => {
		$scope.saving = true;
		const equipment = $scope.equipment;

		if (!equipment.model && !equipment.equipment_model_id) {
			toastService.showError(
				'Please either choose a model, or enter a custom model name if you cannot find the model.'
			);
		} else {
			const patchModel = createPatchModel(equipment);

			assetsService
				.updateAsset(equipment.equipment_id, patchModel)
				.then(() => {
					const assetId = $scope.equipment.asset_id;

					companyViewSettings.assetMarkerColors[assetId] = $scope.selectedColor;

					if (typeof $scope.onSave !== 'function') {
						companiesService.updateViewSettings($scope.companyId, APPLICATION_ID, companyViewSettings);
					} else if ($scope.onSave) {
						$scope.onSave(companyViewSettings);
					}

					esEquipmentService.equipment = angular.copy(equipment);
					updateEquipmentModel(equipment);
					toastService.showSuccess('Success! Equipment saved.');
				})
				.catch((e) => errorHandlerService.genericErrorHandler(e))
				.finally(() => {
					$scope.saving = false;
					$scope.$digest();
				});
		}
	};

	$scope.uploadImage = () => {
		const formData = new FormData();
		const userId = authService.cookies.userId.get();

		formData.append('file', $scope.file);
		formData.append('user_id', userId);

		return esEquipmentService
			.uploadPhoto($scope.equipmentId, formData)
			.then((file) => {
				return assetsService
					.updateAsset($scope.equipmentId, { photo_id: file.data.photo_id })
					.then(() => {
						setPhotoOnSelectedEquipment(file.data);
						setPhotoUrlInCollection();

						$scope.$digest();
					})
					.catch((e) => errorHandlerService.genericErrorHandler(e));
			})
			.catch((err) => {
				if (err.status === 400) {
					toastService.showError('Cannot upload file with provided extension.  Please make sure file is a photo');
				}
				if (err.status === 500) {
					toastService.showError('There was an error uploading the photo.  Please try again later');
				}
			});
	};

	function setEquipmentProperties(equipment) {
		return {
			asset_id: equipment.asset_id,
			equipment_id: equipment.equipment_id,
			custom_name: equipment.custom_name,
			description: equipment.description,
			make: equipment.make,
			market_id: equipment.market ? equipment.market.market_id : null,
			rental_branch_id: equipment.rental_branch_id ? equipment.rental_branch_id : null,
			service_branch_id: equipment.service_branch_id ? equipment.service_branch_id : null,
			equipment_make_id: equipment.equipment_make_id,
			equipment_model_id: _get(equipment, 'equipment_model.equipment_model_id', null),
			equipment_model: equipment.equipment_model,
			model: equipment.model,
			year: equipment.year,
			name: equipment.name,
			hours: _get(equipment, 'asset_status.hours'),
			serial_number: equipment.serial_number,
			tracker_id: equipment.tracker_id,
			price_per_hour: equipment.price_per_hour,
			available_to_rapid_rent: equipment.available_to_rapid_rent,
			_photoUrl: equipment._photoUrl,
			keypad: equipment.keypad,
		};
	}

	function fetchCompanyViewSettings() {
		companiesService.fetchViewSettings($scope.companyId, APPLICATION_ID).then((viewSettings) => {
			const selectedColor = MapMarkers.fetchColorForAsset($scope.equipment.asset_id, viewSettings);

			if (selectedColor) {
				$scope.selectedColor = selectedColor;
			}
		});
	}

	function createPatchModel(equipment) {
		const differences = difference(equipment, setEquipmentProperties(esEquipmentService.equipment));

		return {
			custom_name: differences.custom_name,
			description: differences.description,
			equipment_make_id: differences.equipment_make_id,
			equipment_model_id: differences.hasOwnProperty('equipment_model_id')
				? differences.equipment_model_id || null
				: undefined,
			rental_branch_id: differences.rental_branch_id,
			service_branch_id: differences.service_branch_id,
			model: differences.model,
			year: differences.year,
			name: differences.name,
			hours: differences.hours,
			serial_number: differences.serial_number,
			tracker_id: differences.tracker_id === '' ? null : differences.tracker_id,
			price_per_hour: differences.price_per_hour,
			available_to_rapid_rent: !!equipment.available_to_rapid_rent,
		};
	}

	function updateEquipmentModel(equipment) {
		esEquipmentService.equipment.equipment_id = equipment.equipment_id;
		esEquipmentService.equipment.custom_name = equipment.custom_name;
		esEquipmentService.equipment.description = equipment.description;
		esEquipmentService.equipment.equipment_make_id = equipment.equipment_make_id;
		esEquipmentService.equipment.equipment_model_id = equipment.equipment_model_id;
		esEquipmentService.equipment.market_id = equipment.market_id;
		esEquipmentService.equipment.rental_branch_id = equipment.rental_branch_id;
		esEquipmentService.equipment.service_branch_id = equipment.service_branch_id;
		esEquipmentService.equipment.model = equipment.model;
		esEquipmentService.equipment.year = equipment.year;
		esEquipmentService.equipment.name = equipment.name;
		esEquipmentService.equipment.serial_number = equipment.serial_number;
		esEquipmentService.equipment.tracker_id = equipment.tracker_id;
		esEquipmentService.equipment.price_per_hour = equipment.price_per_hour;
		esEquipmentService.equipment.available_to_rapid_rent = equipment.available_to_rapid_rent;
	}

	function setPhotoOnSelectedEquipment(file) {
		$scope.file.filename = file.filename;
		$scope.equipment.photo = $scope.file;
		$scope.equipment._photoUrl = esApiFactoryV2.getPhotoUrl($scope.equipment);
		esEquipmentService.equipment = angular.copy($scope.equipment);
	}

	function setPhotoUrlInCollection() {
		if (esEquipmentService.equipmentList) {
			const foundEquipmentIndex = _findIndex(esEquipmentService.equipmentList, (equipment) => {
				return equipment.equipment_id === $scope.equipment.equipment_id;
			});

			if (foundEquipmentIndex != -1) {
				esEquipmentService.equipmentList[foundEquipmentIndex]._photoUrl = $scope.equipment._photoUrl;
			}
		}
	}

	$scope.init();
}

ngModule.controller('EquipmentEditFormCtrl', EquipmentEditFormCtrl);
