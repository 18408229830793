import { IS_DEV } from '../const/env';

let currentId = 0;
let toasts = [];
const listeners = new Set();

function burnToast(id) {
	toasts = toasts.filter((t) => t.id !== id);

	listeners.forEach((fn) => fn(toasts));
}

function cleanToaster() {
	toasts = [];

	listeners.forEach((fn) => fn(toasts));
}

function makeToast({ title, description, burnIn, type, metadata = {} }) {
	const rawToast = [title, description, ...Object.keys(metadata).map((key) => `${key}: ${metadata[key]}`)].filter(
		Boolean
	);
	const toast = {
		id: currentId++,
		title,
		description,
		burnIn,
		type,
		metadata,
		raw: type === 'error' ? rawToast.join('\n') : null,
	};
	toasts = toasts.concat(toast);

	if (burnIn) {
		setTimeout(() => {
			burnToast(toast.id);
		}, burnIn * 1000);
	}

	listeners.forEach((fn) => fn(toasts));
}

function getToasts() {
	return toasts;
}

function showSuccess(message) {
	makeToast({
		title: message,
		type: 'success',
		burnIn: 5,
	});
}

function showInfo(message) {
	makeToast({
		title: message,
		type: 'info',
		burnIn: 5,
	});
}

function showError(message) {
	makeToast({
		title: message,
		type: 'error',
		burnIn: 20,
	});
}

function addListener(fn) {
	listeners.add(fn);
}
function removeListener(fn) {
	listeners.delete(fn);
}

const toastService = {
	makeToast,
	burnToast,
	addListener,
	removeListener,
	getToasts,
	showSuccess,
	showError,
	showInfo,
	cleanToaster,
};

if (IS_DEV) {
	window.toastService = toastService;
}

export default toastService;
