import ngModule from '../../ng/ng-module.js';
import { react2angular } from 'react2angular';
import PartPickerEditForm from './part-picker-edit-form.js';
import withGlobalProviders from '@components/with-global-providers';

ngModule.component(
	'reactPartPickerEditForm',
	react2angular(
		withGlobalProviders(PartPickerEditForm),
		Object.keys(PartPickerEditForm.propTypes).concat('forceUpdate')
	)
);
