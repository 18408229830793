import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { EuiIcon, EuiIconProps } from '@equipmentshare/ds2';

type SpinProps = {
	spin?: boolean;
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const RotatingDiv = styled.div`
	height: min-content;
	width: min-content;

	${({ spin = false }: SpinProps) =>
		spin
			? css`
					animation: ${rotate} 2s linear infinite;
			  `
			: ''}
`;

export type RotatingIconProps = EuiIconProps & SpinProps;

export const RotatingIcon: React.FC<RotatingIconProps> = ({ spin, ...props }) => {
	return (
		<RotatingDiv spin={spin}>
			<EuiIcon {...props} />
		</RotatingDiv>
	);
};

export default RotatingIcon;
