import _throttle from 'lodash/throttle';
import { checkUpdates } from '@equipmentshare/reload-notification';
import { heapEvent } from '@equipmentshare/heaper';

let numberOfPreviousOutdatedWarnings = 0;

checkUpdates.fetchCurrentVersion();

checkUpdates.addEventListener((currentVersion, newVersion) => {
	heapEvent('Admin - App - Alert - Outdated Client', {
		current_version: currentVersion,
		new_version: newVersion,
		number_of_previous_outdated_warnings: numberOfPreviousOutdatedWarnings,
	});

	numberOfPreviousOutdatedWarnings += 1;
});

const checkForUpdates = checkUpdates.checkForUpdates;
const debounceCheckForUpdates = _throttle(checkUpdates.checkForUpdates, 60 * 1000);

export { checkForUpdates, debounceCheckForUpdates };
