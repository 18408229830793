import '@equipmentshare/ds2/dist/assets/ds2.css';
// import "@elastic/eui/dist/eui_theme_amsterdam_light.css";
// import "@elastic/eui/dist/eui_theme_light.css";
import './services/iframe-controller.service';
import './ng';

import '@equipmentshare/shared-styling/dist/main.css';

import { init } from './app';

import './js';

// use commonjs to force this module to be loaded after all JS files in /js/
require('./routes');

init();
