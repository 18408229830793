import { onError } from '@apollo/client/link/error';

import toastService from '@services/toast.service.js';
import { userAuthorizationsQueryName } from './inventory/queries/user-authorizations.js';

function shouldIgnoreGraphqlErrorMessage(operation, statusCode) {
	return operation.operationName === userAuthorizationsQueryName && statusCode === 403;
}

export default onError((errorData) => {
	const { operation, graphQLErrors, networkError } = errorData;
	let errorMessage;
	let errorMessageOptions;
	let statusCode;

	if (networkError) {
		errorMessage = `NE${networkError.statusCode || '000'}: An error has occurred. Please try again.`;
		errorMessageOptions = { timeout: 4000, closeOnClick: true };
		statusCode = networkError.statusCode;

		if (networkError.statusCode === 401) {
			errorMessage = 'You are not logged in. Please log out and log in again.';
		} else if (networkError.statusCode === 500 && networkError.result && networkError.result.message) {
			errorMessage = `${networkError.result.message} Please try again shortly.`;
			errorMessageOptions = { timeout: 8000, closeOnClick: false };
		}
	} else if (graphQLErrors && graphQLErrors.length) {
		const { message, extensions } = graphQLErrors[0];

		statusCode = extensions ? extensions.status_code : undefined;

		switch (statusCode) {
			case 403:
				errorMessage = 'You do not have permission to do that.';
				break;
			case 404:
				errorMessage = 'Resource not found!';
				break;
			case 409:
				errorMessage = message;
				break;
			default:
				errorMessage = 'An error occurred. Please try again.';
		}
	}

	if (errorMessage && !shouldIgnoreGraphqlErrorMessage(operation, statusCode)) {
		toastService.showError(errorMessage, errorMessageOptions);
	}
});
