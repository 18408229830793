import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import tooltipStyles from "./tooltip.module.css";

class Tooltip extends PureComponent{
  constructor( props ){
    super( props );

    this.state = {
      isVisible: false,
    };

    this.hideTooltip = this.hideTooltip.bind( this );
    this.showTooltip = this.showTooltip.bind( this );
  }

  showTooltip(){
    this.setState( { isVisible: true } );
  }

  hideTooltip(){
    this.setState( { isVisible: false } );
  }

  render(){
    let tooltipClass = classNames( tooltipStyles.bubble, tooltipStyles[this.props.direction] );

    return (
      <div className={tooltipStyles.tooltip} onMouseLeave={this.hideTooltip}>
        {this.state.isVisible && (
          <div className={tooltipClass}>
            <div className={tooltipStyles.message}>{this.props.text}</div>
          </div> )}
        <div className={tooltipStyles.trigger} onMouseOver={this.showTooltip}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Tooltip.defaultProps = {
  children: <div className={tooltipStyles.defaultTrigger}>?</div>,
  direction: "right"
};

Tooltip.propTypes = {
  /** Element that is hoverable to show tooltip */
  children: PropTypes.node,
  /** Direction to show tooltip */
  direction: PropTypes.string,
  /** Text inside of tooltip */
  text: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.node,
  ] ).isRequired,
};

export default Tooltip;
