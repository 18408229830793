import angular from 'angular';
import ngModule from '../../../../ng/index.js';
import _map from 'lodash/map';
import _isFunction from 'lodash/isFunction';
import template from './user-picker.html';

function userPicker() {
	return {
		template,
		controller: 'UserPickerCtrl as ctrl',
		scope: {
			ngModel: '=',
			selectedUser: '=?',
			selectLabel: '@',
			isRequired: '@',
			isSalesperson: '<?',
			companyId: '<',
			filter: '<?',
		},
	};
}

function UserPickerCtrl($scope, esAdminUserService) {
	const controller = this;

	$scope.$watch('ngModel', (newModel, oldModel) => {
		controller.updateSelectedUser();

		if (newModel === undefined) {
			$scope.selectedUser = undefined;
			$scope.searchText = '';
		}
	});

	controller.updateSelectedUser = () => {
		if ($scope.ngModel) {
			esAdminUserService
				.getUser($scope.ngModel)
				.then((user) => {
					if ($scope.ngModel === user.user_id) {
						$scope.selectedUser = user;
					}
				})
				.catch((err) => ($scope.selectedUser = undefined));
		} else {
			$scope.selectedUser = undefined;
		}
	};

	controller.querySearch = function (query) {
		const filterCompanyId = parseInt($scope.companyId, 10) || undefined;

		return esAdminUserService
			.searchUsers(query, $scope.isSalesperson, filterCompanyId)
			.then((users) => (_isFunction($scope.filter) ? _map(users, $scope.filter) : users));
	};

	controller.selectUser = function (user) {
		if (angular.isObject(user) && user.user_id) {
			$scope.ngModel = user.user_id;
		} else {
			$scope.ngModel = null;
		}
		controller.updateSelectedUser();
	};
}

ngModule.directive('userPicker', userPicker).controller('UserPickerCtrl', UserPickerCtrl);
