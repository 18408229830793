import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import RadioButton from "./radio-button.js";

import formControlStyles from "../form-control.module.css";

export default class RadioGroup extends PureComponent {
  constructor( props ){
    super( props );
    this.state = {
      selectedValue: props.selectedValue
    };

    this.handleChange = this.handleChange.bind( this );
  }

  UNSAFE_componentWillReceiveProps( nextProps ) {
    if ( this.props.selectedValue !== nextProps.selectedValue ) {
      this.setState( { selectedValue: nextProps.selectedValue } );
    }
  }

  handleChange( changeEvent ) {
    let newValue = changeEvent.target.value;
    let { onChange } = this.props;

    this.setState( {
      selectedValue: newValue
    } );

    if ( typeof onChange === "function" ) {
      this.props.onChange( newValue );
    }
  }

  renderRadioButtons() {
    let { radioOptions } = this.props;
    let { selectedValue } = this.state;

    return radioOptions.map( ( option ) => {
      return (
        <RadioButton
          key={option.value}
          label={option.label}
          name={option.name}
          onChange={this.handleChange}
          selected={option.value === selectedValue}
          value={option.value}
        />
      );
    } );
  }

  render() {
    let { className } = this.props;

    return (
      <div className={classNames( formControlStyles.control, className )}>
        {this.renderRadioButtons()}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  /** custom class */
  className: PropTypes.string,
  /** onChange event handler */
  onChange: PropTypes.func,
  /** array of objects to be rendered for each radio button */
  radioOptions: PropTypes.array.isRequired,
  /** any preselected value to be rendered by default */
  selectedValue: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ] ),
};

RadioGroup.defaultProps = {
  className: "",
  onChange: () => {},
  selectedValue: null,
};
