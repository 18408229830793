import ngModule from '../../../../ng/index.js';
import template from './new-location-form.html';

ngModule.component('newLocationForm', {
	template,
	controller: 'NewLocationFormCtrl',
	bindings: {
		onPickNewLocation: '<',
		onPickExistingLocation: '<',
		onCancel: '&',
		location: '<',
		existingGeofences: '<',
		fetchExistingGeofences: '<',
		allowDefaultGeofence: '<',
	},
});
