import angular from 'angular';
import nv from 'nvd3';

angular.module("esNgComponents").directive('donutChart', function () {
  return {
    scope: {
      chartData: '=',
      labelType: '=',
      showLabels: '=',
      showLegend: '='
    },
    replace: false,
    link: function (scope, element) {
      nv.addGraph(function () {
        var chart = nv.models.pieChart()
          .x(function (d) {
            return d.label;
          })
          .y(function (d) {
            return d.value;
          })
          .valueFormat(d3.format(".0f"))
          .showLabels(scope.showLabels !== undefined ? scope.showLabels : true)
          .showLegend(scope.showLegend !== undefined ? scope.showLegend : true)
          .labelThreshold(.05)
          .labelType(scope.labelType ? scope.labelType : 'value')
          .donut(true)
          .donutRatio(0.35);

        d3.select(element[0]).append('svg')
          .datum(scope.chartData)
          .transition().duration(350)
          .call(chart);

        nv.utils.windowResize(chart.update);

        return chart;
      }, function() {
        d3.selectAll('.nv-slice')
          .on('click', function(slice) {
            if(slice.data.click) {
              slice.data.click();
              scope.$apply();
            }
          });
      });
    }
  };
});