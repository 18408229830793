import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EuiCheckbox, htmlIdGenerator } from '@equipmentshare/ds2';
import Column from '../column/column.js';

import styles from './checkbox-column.module.css';

export const CheckboxColumn = ({ onChange, checked, columnId, ...props }) => (
	<Column columnId={columnId} titleClassName={classNames(styles.column)} onClick={onChange} {...props}>
		<EuiCheckbox id={htmlIdGenerator()()} className={styles.checkbox} checked={checked} readOnly onChange={() => {}} />
	</Column>
);

CheckboxColumn.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
	columnId: PropTypes.string.isRequired,
};
CheckboxColumn.defaultProps = {
	stopClickPropagation: false,
};

export default CheckboxColumn;
