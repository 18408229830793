import _find from 'lodash/find';

angular.module('esApiClient').factory('esLocationService', function (esApiFactoryV2, $q, $log) {

  var statesPromise = null;

  function getStates() {
    if (!statesPromise) {
      statesPromise = esApiFactoryV2.getStates().then(function (res) {
        return res.data;
      });
    }
    return statesPromise;
  }

  function getStateByName(name) {
    name = name.toLowerCase();
    return getStates().then(function (states) {
      var found = _find(states, function (state) {
        return state.abbreviation.toLowerCase() == name || state.name.toLowerCase() == name;
      });
      if (found) {
        return $q.when(found);
      } else {
        return $q.reject("No state match for name '" + name + "'");
      }
    });
  }

  return {
    getZipCode: function (zipId) {
      return esApiFactoryV2.getZipCode(zipId)
          .then(function (response) {
            return response.data;
          })
    },
    getStateByName: getStateByName,
    getStates: getStates
  }
});
