import React from "react";
import PropTypes from "prop-types";

import Icon from "../../icon/icon.js";

import { validProgress } from "../progress.js";

import styles from "../progress.module.css";

export function ProgressText( { progressType, percent, labelOverride } ) {
  let text = `${validProgress( percent )}%`;

  if ( typeof labelOverride === "function" ) {
    text = labelOverride( progressType, percent );
  }

  if ( progressType === "success" || parseInt( percent.toString(), 10 ) >= 100 ) {
    text = ( <Icon name="fasCheck" /> );
  }

  if ( progressType === "exception" ) {
    text = ( <Icon name="farTimes" /> );
  }

  return (
    <span className={styles.progressText}>
      {text}
    </span>
  );
}

ProgressText.propTypes = {
  /** a function that accepts two arguments: `progressType` and `percent` and returns a JSX node or string */
  labelOverride: PropTypes.func,
  /** How much of the progress is filled in */
  percent: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string
  ] ),
  /** the type of progress bar to use and any associated icons */
  progressType: PropTypes.oneOf( [
    "normal",
    "exception",
    "success"
  ] ),
};

ProgressText.defaultProps = {
  labelOverride: undefined,
  progressType: undefined,
  percent: 0,
};

export default ProgressText;
