import ngModule from '../ng-module.js';
import authService from '@services/auth.service.js';

function shouldUseAuthCacheBust(config) {
	return config.url && config.url.includes('/admin/deliveries');
}

function buildTokenFetchConfig(reqConfig) {
	const config = {};

	if (shouldUseAuthCacheBust(reqConfig)) {
		config.ignoreCache = true;
	}

	return config;
}

function ngHttpRequestInterceptor($q) {
	return {
		request: (config) => {
			return $q((res, rej) => {
				authService
					.getApiToken(buildTokenFetchConfig(config))
					.then((token) => {
						console.warn('old angular request', config.method, config.url);

						config.headers.Authorization = `Bearer ${token}`;

						res(config);
					})
					.catch((err) => rej(err));
			});
		},
	};
}

ngModule.factory('ngHttpRequestInterceptor', ngHttpRequestInterceptor);
