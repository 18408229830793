import ngModule from '../../../ng/index.js';
import moment from 'moment-timezone';
import toastService from '@services/toast.service.js';
import { createErrorHandler } from '../../../state/helpers.js';
import '@components/location-display-map/location-display-map-component.js';
import locationsService from '@services/locations.service.js';

function RentalMoveDialogCtrl($scope, $mdDialog, esAdminRentalService, rental, onSave) {
	$scope.rental = rental;
	$scope.cancel = $mdDialog.cancel;

	$scope.startDate = moment().startOf('minute').toDate();
	$scope.currentLocation = $scope.rental.current_location;
	$scope.newLocationId = null;
	$scope.newLocation = null;
	$scope.showLocationWarning = false;
	$scope.isValidLocation = false;

	$scope.handleLocationChange = (locationId, location) => {
		$scope.newLocationId = locationId;
		$scope.newLocation = location;
		$scope.showLocationWarning = !locationsService.hasLatLng(location);
		$scope.isValidLocation = locationId || location;
		$scope.$digest();
	};

	$scope.submitForm = () => {
		if ($scope.saving && $scope.newLocationId) {
			return;
		}

		$scope.saving = true;

		return esAdminRentalService
			.rentalAssignLocation($scope.rental.rental_id, $scope.newLocationId, $scope.startDate)
			.then(() => {
				onSave();
				toastService.showSuccess('Scheduled Rental Move');
				$scope.isValidLocation = false;
				$mdDialog.hide(true);
			})
			.catch(createErrorHandler('Failed to schedule rental move. please contact engineering'))
			.finally(() => ($scope.saving = false));
	};

	if ($scope.rental.current_location) {
		$scope.currentPoint = {
			lat: $scope.rental.current_location.latitude,
			lng: $scope.rental.current_location.longitude,
		};
	}
}

ngModule.controller('RentalMoveDialogCtrl', RentalMoveDialogCtrl);
