import moment from 'moment-timezone';
import esApi from './es-api.service';
import { COMPANY_DOCUMENT_TYPES } from '@const/company-documents';

export async function getCompanyTaxableFlag(companyId, branchId, isValidAt = undefined, jobsiteId = undefined) {
	if (!companyId || !branchId) {
		return true;
	}
	const params = {
		branch_id: branchId,
	};
	if (jobsiteId) {
		params.jobsite_id = jobsiteId;
	}
	if (isValidAt) {
		params.date = isValidAt;
	}
	const response = await esApi.request(esApi.url`/billing/is-company-tax-exempt/${companyId}`, params);
	return !response.is_tax_exempt;
}

function sortDocsByValidUntil(a, b) {
	// sort by valid until first
	const validUntilDiff = moment(b.valid_until).diff(a.valid_until);
	// if the valid until is the same sort by most recently added
	if (validUntilDiff === 0) {
		return moment(b.date_created).diff(a.date_created);
	}

	return validUntilDiff;
}

export async function getCurrentRentalFloater(companyId) {
	const now = moment();
	const query = { company_document_type_id: COMPANY_DOCUMENT_TYPES.RENTAL_FLOATER };
	const documents = await esApi.request(esApi.url`/admin/companies/${companyId}/documents`, query);

	documents.sort((a, b) => moment(a.date_created).diff(b.date_created));

	const validDocs = documents.filter(
		(doc) => doc.voided === false && now.isAfter(doc.valid_from) && now.isBefore(doc.valid_until)
	);
	const invalidDocs = documents.filter((doc) => !validDocs.includes(doc));

	if (validDocs.length > 0) {
		return validDocs[0];
	} else if (invalidDocs.length > 0) {
		const latestExpiredDoc = invalidDocs.sort(sortDocsByValidUntil).filter((doc) => doc.voided === false);

		return latestExpiredDoc[0] || null;
	}

	return null;
}

export function getRentalFloaterStatus(document) {
	const now = moment();
	let isFuture;
	let isExpired;
	let isVoided;
	let isInGracePeriod;

	isFuture = isExpired = isVoided = isInGracePeriod = null;

	if (document) {
		if (!document.voided) {
			isFuture = now.isBefore(document.valid_from);
			isExpired = now.isAfter(document.valid_until);
			isVoided = document.voided;
			isInGracePeriod = isExpired && moment().subtract(30, 'days').isBefore(document.valid_until);
		} else {
			isVoided = true;
		}
	}

	// these variables are used to determine if a rental should have RPP applied to it
	const exists = !!document;
	const isValid = exists && !isVoided && !isFuture && !isExpired;
	const isPartiallyValid = exists && !isVoided && isExpired && isInGracePeriod;

	return {
		exists,
		isFuture,
		isExpired,
		isVoided,
		isInGracePeriod,
		isValid,
		isPartiallyValid,
		document,
	};
}

async function getDocumentTypes() {
	return await esApi.request('/admin/companies/company-document-types');
}

async function fetch(documentId) {
	return await esApi.request(`/admin/companies/documents/${documentId}`);
}
async function fetchAll(companyId, query = {}) {
	return await esApi.request(`/admin/companies/${companyId}/documents`, query);
}

async function create(companyId, payload) {
	return await esApi.request(
		`/admin/companies/${companyId}/documents`,
		{},
		{
			method: 'POST',
			body: payload,
		}
	);
}
async function update(documentId, payload) {
	return await esApi.request(
		`/admin/companies/documents/${documentId}`,
		{},
		{
			method: 'PATCH',
			body: payload,
		}
	);
}

const companyDocumentService = {
	getDocumentTypes,
	fetch,
	fetchAll,
	create,
	update,
	getCompanyTaxableFlag,
	getCurrentRentalFloater,
	getRentalFloaterStatus,
};

export default companyDocumentService;
