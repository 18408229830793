import extend from "lodash/extend";

function NgRequest( reqConfig, http ){
  var config = reqConfig;

  config.headers = config.headers || {};
  config.params = config.params || {};
  config.data = config.data || config.body || {};

  if(config.defaultContent === false && !config.headers.hasOwnProperty("Content-Type")){
    config.headers = Object.assign({
      "Content-Type": undefined
    }, config.headers);
  }

  if(config.getter === "blob"){
    config.responseType = "blob";
  }

  this.send = ( data ) => {
    config.data = data;

    return this;
  };

  this.set = ( key, value ) => {
    if( typeof key === "object" ){
      Object.assign( config.headers, key );
    }
    else{
      config.headers[key] = value;
    }

    return this;
  };

  this.query = ( params ) => {
    extend( config.params, params );

    return this;
  };

  this.then = ( fn ) => http( config )
    .then( ( response ) => {
      fn( Object.assign( {}, response, { body: response.data, content: response.data } ) );
    } );

  return this;
}

function NgClient( http ){
  let ngHttp = http;

  this.request = ( reqConfig ) => {
    return new NgRequest( reqConfig, ngHttp );
  };

  this.delete = ( url, config = {} ) => {
    var reqConfig = Object.assign({}, config, { "method": "delete", "url": url });

    return new NgRequest( reqConfig, ngHttp );
  };

  this.get = ( url, config = {} ) => {
    var reqConfig = Object.assign({}, config, { "method": "get", "url": url });

    return new NgRequest( reqConfig, ngHttp );
  };

  this.patch = ( url, config = {} ) => {
    var reqConfig = Object.assign({}, config, { "method": "patch", "url": url });

    return new NgRequest( reqConfig, ngHttp );
  };

  this.post = ( url, config = {} ) => {
    var reqConfig = Object.assign({}, config, { "method": "post", "url": url });

    return new NgRequest( reqConfig, ngHttp );
  };

  this.put = ( url, config = {} ) => {
    var reqConfig = Object.assign({}, config, { "method": "put", "url": url });

    return new NgRequest( reqConfig, ngHttp );
  };

  this.head = ( url, config = {} ) => {
    var reqConfig = Object.assign({}, config, { "method": "head", "url": url });

    return new NgRequest( reqConfig, ngHttp );
  };

  return this;
}

export default NgClient;
