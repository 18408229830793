import moment from 'moment-timezone';
import ngModule from '../../ng/index.js';

import template from './rental-task.html';

ngModule.component('rentalTask', {
	template,
	bindings: {
		task: '=',
	},
	controller: function RentalTaskController() {
		function describe(type, props) {
			switch (type) {
				case 'on_rent':
					return 'On-rent the rental';
				case 'assign_location':
					return 'Assign a location to the rental';
				case 'assign_asset':
					return 'Assign an asset to the rental';
				case 'assign_driver':
					return `Assign driver to delivery #${props.delivery_id}`;
				case 'complete_delivery':
					return `Complete delivery #${props.delivery_id}`;
				default:
					// Make any type we don't strictly know about look a bit prettier
					return type.charAt(0).toUpperCase() + this.type.slice(1).replace(/_/g, ' ');
			}
		}

		const due = moment(this.task.due_date).fromNow();
		const description = describe(this.task.task_type, this.task.properties);

		this.description = `${description}, due ${due}`;
	},
});
