import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import inputLabelStyles from "./input-label.module.css";

const InputLabel = ( { htmlFor, label, children, className, required, ...props } ) => (
  <div
    className={classNames( inputLabelStyles.group, className )}
    {...props}
  >
    {label &&
      <label className={inputLabelStyles.label} htmlFor={htmlFor}>
        {label}
        {required &&
          <span className={inputLabelStyles.required}>&nbsp;(required)</span>
        }
      </label>
    }
    {children}
  </div>
);

InputLabel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

InputLabel.defaultProps = {
  children: null,
  className: null,
  htmlFor: null,
  label: null,
  required: false
};

export default InputLabel;
