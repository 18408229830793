import ngModule from '../../../ng/index.js';
import moment from 'moment-timezone';

function RentalHistoryCtrl($scope) {
	$scope.isAssignmentOpen = (assignment) => {
		const now = moment();

		return (
			assignment.end_date === null ||
			(now.isSameOrAfter(assignment.start_date) && now.isSameOrBefore(assignment.end_date))
		);
	};
}

ngModule.controller('RentalHistoryCtrl', RentalHistoryCtrl);
