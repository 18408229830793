import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@equipmentshare/ds2';
import { InformationFillIcon } from '@equipmentshare/ds2-icons';

import AddressSuggestionInput from '../address-suggestion-input/address-suggestion-input.js';
import hereApiService from '@services/here-api.service.js';
import GooglePlacesSuggestionInput from '@components/google-places-suggestion-input/google-places-suggestion-input.js';
import LatLngAddressInput from './lat-lng-address-input/lat-lng-address-input.js';

import styles from './advanced-address-search.module.css';

export function filterHereLocations(results) {
	return results.filter((result) => {
		const matchLevel = hereApiService.getMatchLevel(result);
		const isStreetIntersection = _get(result, 'scoring.fieldScore.streets.length', 0) > 1;

		return (
			hereApiService.isLessThanEqualToMatchLevel('houseNumber', matchLevel) ||
			matchLevel === 'intersection' ||
			(matchLevel === 'street' && isStreetIntersection)
		);
	});
}
function filterExpandedLocations(results) {
	return results.filter((result) => {
		const matchLevel = hereApiService.getMatchLevel(result);

		return hereApiService.isLessThanEqualToMatchLevel('city', matchLevel);
	});
}

const AdvancedAddressSearch = ({ onSelectAddress, hideToggleButton, forceOpen }) => {
	const [open, setOpen] = useState(false);
	const [tab, setTab] = useState('hereApi');
	const [search, setSearch] = useState('');
	const isOpen = open || forceOpen;

	const handleAddressSelect = (address) => {
		setSearch('');

		// strip zip+4 if its set
		if (address.zip_code) {
			const zipParts = String(address.zip_code).split('-');
			address.zip_code = zipParts[0] || address.zip_code;
		}

		if (onSelectAddress) {
			onSelectAddress(address);
		}
	};

	const addressSuggestionInput = (
		<AddressSuggestionInput
			placeholder="Search nearby street or intersection"
			value={search}
			onChange={setSearch}
			onSelectLocation={handleAddressSelect}
			filterSuggestions={isOpen ? filterExpandedLocations : filterHereLocations}
			fullWidth
		/>
	);

	const renderSearchInputs = () => {
		switch (tab) {
			case 'hereApi':
				return (
					<Fragment>
						{addressSuggestionInput}
						<div>
							<EuiIcon type={InformationFillIcon} />
							<span>New Construction? Try excluding house number or search a nearby street or intersection</span>
						</div>
					</Fragment>
				);
			case 'google':
				return (
					<GooglePlacesSuggestionInput
						placeholder="Search google places or plus code"
						value={search}
						onChange={setSearch}
						onSelectLocation={handleAddressSelect}
						fullWidth
					/>
				);
			case 'geocordinates':
				return <LatLngAddressInput onSelectAddress={handleAddressSelect} />;
			default:
				return null;
		}
	};

	return (
		<div className={styles.layout}>
			{isOpen ? (
				<Fragment>
					<EuiFlexGroup gutterSize="s">
						<EuiFlexItem grow={false}>
							<EuiButton
								fill={tab === 'hereApi'}
								color={tab === 'hereApi' ? 'primary' : 'text'}
								size="s"
								onClick={() => setTab('hereApi')}
							>
								Street
							</EuiButton>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButton
								fill={tab === 'google'}
								color={tab === 'google' ? 'primary' : 'text'}
								size="s"
								onClick={() => setTab('google')}
							>
								Google Places
							</EuiButton>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButton
								fill={tab === 'geocordinates'}
								color={tab === 'geocordinates' ? 'primary' : 'text'}
								size="s"
								onClick={() => setTab('geocordinates')}
							>
								Geocordinates
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
					{renderSearchInputs()}
				</Fragment>
			) : (
				addressSuggestionInput
			)}
			<div className={styles.links}>
				{!hideToggleButton ? (
					<a onClick={() => setOpen(!open)}>{open ? 'Hide Advanced' : 'Show Advanced'}</a>
				) : (
					<span />
				)}
				<a
					href="https://equipmentshare.releasenotes.io/release/X4kso-improved-search-address-location-functionality-in-es-admin"
					target="_blank"
					rel="noopener noreferrer"
				>
					Need Help?
				</a>
			</div>
		</div>
	);
};

AdvancedAddressSearch.propTypes = {
	onSelectAddress: PropTypes.func,
	hideToggleButton: PropTypes.bool,
	forceOpen: PropTypes.bool,
};

export default AdvancedAddressSearch;
