import ngModule from '../../../ng/index.js';
import template from './vehicle-edit-form.html';
import VehicleEditFormCtrl from './vehicle-edit-form-ctrl.js';

function vehicleEditForm() {
	return {
		template,
		controller: VehicleEditFormCtrl,
		scope: {
			assetColor: '=',
			gettingVehicleData: '=',
			onSave: '=',
			saveBack: '=',
			showMaintenanceGroup: '@',
			vehicleId: '=',
		},
	};
}

ngModule.directive('vehicleEditForm', vehicleEditForm);
