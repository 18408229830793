import styles from './rental-edit-component.module.css';

import ngModule from '../../../ng/index.js';
import './line-items-view/line-items-view-component';

function RentalEditComponentCtrl($scope, $timeout, esDeliveriesService, esAdminRentalService, esOperationsService) {
	$scope.deliveryTypes = esDeliveriesService.deliveryTypes;
	$scope.rentalLocation = null;

	$scope.styles = styles;

	$scope.shouldShowTab = (tabName) => !$scope.hideTabs || !$scope.hideTabs.includes(tabName);

	$scope.$watch('selectedDelivery', () => {
		if ($scope.selectedDelivery) {
			!$scope.rental || $scope.rental.rental_id !== $scope.selectedDelivery.rental_id
				? ($scope.rental = $scope.selectedDelivery.rental)
				: '';
		}
		$scope.refresh();
	});

	$scope.$watch('rental.rental_id', () => {
		$scope.refresh();
	});

	$scope.setDeliveryToEdit = (delivery) => {
		$scope.selectedDelivery = delivery;
	};

	$scope.refresh = () => {
		if (!$scope.rental) {
			return;
		}
		$scope.gettingRentalDetails = true;
		esAdminRentalService.getRental($scope.rental.rental_id).then(updateMap);
		updateMap();
		getRentalDeliveries().finally(() => {
			$scope.gettingRentalDetails = false;
		});
	};
	/**
	 * Get the snapshot associated with a specific delivery
	 * @param  {number} delivery_id delivery id
	 * @return {object}             snapshot
	 */

	$scope.rentalUpdated = () => {
		$scope.refresh();
		if ($scope.onRentalChange) {
			$scope.onRentalChange($scope.rental);
		}
	};

	$scope.scheduleUpdated = () => {
		if ($scope.onRentalChange) {
			$scope.onRentalChange($scope.rental);
		}

		// Close delivery edit form
		$scope.setDeliveryToEdit(null);
	};

	$scope.partRentalPartId = (rental) =>
		rental && rental.part_assignments && rental.part_assignments.length > 0 ? rental.part_assignments[0].part_id : null;

	function updateMap() {
		const currentLocation = $scope.rental.current_location;

		if (currentLocation && currentLocation.latitude && currentLocation.longitude) {
			$scope.rentalLocation = {
				lat: $scope.rental.current_location.latitude,
				lng: $scope.rental.current_location.longitude,
			};
		} else {
			$scope.rentalLocation = null;
		}
	}

	function getRentalDeliveries() {
		return esDeliveriesService
			.getDeliveries({ rental_id: $scope.rental.rental_id, exclude_cancelled_rentals: false })
			.then((deliveries) => {
				deliveries.map((delivery) => esDeliveriesService.transformDeliveryForLocalUse(delivery));
				$scope.deliveries = deliveries;
			});
	}
}

ngModule.controller('RentalEditComponentCtrl', RentalEditComponentCtrl);
