import angular from 'angular';
import template from './table-header-column.html';
import "./table-header-column-ctrl.js";

angular.module("esNgComponents").component('tableHeaderColumn', {
  template,
  controller: 'TableHeaderColumnCtrl',
  transclude: true,
  bindings: {
    reverse: '<',
    initReverse: '<',
    orderBy: '<',
    property: '@',
    sort: '=',
    onChangeSort: '&'
  }
});