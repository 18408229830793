import React  from "react";
import PropTypes from "prop-types";

import withState from "./withState";

const UIRoute = ( { state, exact, component: Component, children, ...props } ) => {
  let visible = exact ? props.$state.is( state ) : props.$state.includes( state );

  if( visible ){
    if( Component ){
      return ( <Component {...props} /> );
    }
    else if( children ){
      return children;
    }
  }

  return null;
};

UIRoute.propTypes = {
  $state: PropTypes.shape( {
    is: PropTypes.func,
    includes: PropTypes.func
  } ).isRequired,
  component: PropTypes.any,
  exact: PropTypes.bool,
  state: PropTypes.string.isRequired,
  children: PropTypes.node,
};
UIRoute.defaultProps = {
  exact: false
};

export default withState()( UIRoute );
