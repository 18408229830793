import React, { PureComponent } from "react";
import { Portal } from "react-portal";
import PropTypes from "prop-types";
import classNames from "classnames";

import { startToasting, subscribe, unsubscribe } from "./toast-service.js";
import Toast from "./toast.js";
import toastStyles from "./toast.module.css";

/**
 * Toast Container: The ToastContainer component must be included in the App somewhere.
 * Portal is used to insure that the container is appended to the Document Body.
 * Be sure to only included this component only once in your app. This is all that is needed to display Toasts.
*/

export class ToastContainer extends PureComponent {
  constructor( props ){
    super( props );

    this.state = { toasts: [] };

    this.burnToast = this.burnToast.bind( this );
    this.handleToast = this.handleToast.bind( this );
  }

  componentDidMount() {
    startToasting();

    subscribe( this.handleToast );
  }

  componentWillUnmount() {
    unsubscribe( this.handleToast );
  }

  handleToast( toast ) {
    this.setState( ( prevState ) => ( {
      toasts: [ ...prevState.toasts, toast ]
    } ) );
  }

  burnToast( { id } ) {
    var filteredToasts = this.state.toasts.filter( ( toast ) => toast.id !== id );

    this.setState( { toasts: filteredToasts } );
  }

  renderToasts() {
    return this.state.toasts.map( ( toast ) => {
      return <Toast burnToast={this.burnToast} key={toast.id} toast={toast} />;
    } );
  }

  render() {
    let { className } = this.props;

    return this.state.toasts.length > 0 && (
      <Portal>
        <div className={classNames( toastStyles.loaf, className )}>
          {this.renderToasts()}
        </div>
      </Portal>
    );
  }
}

ToastContainer.propTypes = {
  className: PropTypes.string
};
ToastContainer.defaultProps = {
  className: null
};

export default ToastContainer;
