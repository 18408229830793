import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import { Card } from "../card/index.js";
import Icon from "../icon/icon.js";

import styles from "./side-drawer.module.css";

const cx = classNames.bind( styles );


const SideDrawer = ( {
  children, isOpen, onClose, title, showBackdrop, closeOnBackdropClick, drawerWidth, scrollable
} ) => (
  <div className={cx( "side-drawer", { "is-open": isOpen } )} style={{ "--drawer-width": drawerWidth }}>
    {showBackdrop && isOpen && (
      <div className={cx( "backdrop" )} onClick={closeOnBackdropClick ? onClose : undefined} />
    )}
    <Card cardClass={cx( "card" )}>
      <Card.Header>
        <div className={cx( "header" )}>
          <h3 className={cx( "title" )}>{title}</h3>
          <button className={cx( "close-button" )} onClick={onClose}><Icon name="farTimes" /></button>
        </div>
      </Card.Header>
      <Card.Body className={cx( { "scrollable": scrollable } )}>
        {children}
      </Card.Body>
    </Card>
  </div>
);

SideDrawer.propTypes = {
  children: PropTypes.node,
  closeOnBackdropClick: PropTypes.bool,
  drawerWidth: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  showBackdrop: PropTypes.bool,
  title: PropTypes.node,
};

SideDrawer.defaultProps = {
  children: null,
  closeOnBackdropClick: true,
  drawerWidth: null,
  isOpen: false,
  onClose: null,
  scrollable: false,
  showBackdrop: true,
  title: null,
};

export default SideDrawer;
