import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import _orderBy from 'lodash/orderBy';

import { Autocomplete } from '@equipmentshare/react-ui-components';
import { ENTITY_TYPES, cacheKey } from '@equipmentshare/admin-hooks';

import maintenanceGroupsService from '@services/maintenance-groups.service';

const MaintenanceGroupPicker = ({
	maintenance_group_id,
	onChange,
	label,
	disabled,
	noResultsText,
	clearable,
	...props
}) => {
	const { data: maintenanceGroups, isLoading } = useQuery(
		cacheKey(ENTITY_TYPES.MAINTENANCE_GROUP, 'MaintenanceGroupPicker'),
		() => maintenanceGroupsService.fetchAll()
	);

	return (
		<Autocomplete
			label={label}
			value={maintenance_group_id}
			onChange={onChange}
			disabled={disabled || isLoading}
			isLoading={isLoading}
			loadingText="Loading Branches..."
			labelKey="name"
			valueKey="maintenance_group_id"
			options={_orderBy(maintenanceGroups, 'name', 'asc')}
			noResultsText={noResultsText}
			clearable={clearable}
			{...props}
		/>
	);
};

MaintenanceGroupPicker.propTypes = {
	label: PropTypes.string,
	maintenance_group_id: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	noResultsText: PropTypes.string,
	clearable: PropTypes.bool,
};
MaintenanceGroupPicker.defaultProps = {
	label: 'Maintenance Group',
	noResultsText: 'No Maintenance Group',
	clearable: true,
};

export default MaintenanceGroupPicker;
