import angular from 'angular';
import { react2angular } from 'react2angular';

import POCreateFormDrawer from './po-create-form-drawer';
import withGlobalProviders from '@components/with-global-providers';

angular
	.module('esBilling')
	.component(
		'reactPoCreateFormDrawer',
		react2angular(withGlobalProviders(POCreateFormDrawer), Object.keys(POCreateFormDrawer.propTypes))
	);
