import ngModule from '../../../ng/index.js';
import template from './delivery-edit-form.html';

ngModule.directive('deliveryEditForm', () => {
	return {
		template,
		controller: 'DeliveryEditFormCtrl',
		scope: {
			delivery: '=',
			order: '=?',
			rental: '=',
			isTakeover: '@?',
			onSave: '=',
			customerNotes: '=?',
		},
	};
});
