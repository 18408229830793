import { gql } from '@apollo/client';
import getOperationNameFromQuery from '../../utils/get-operation-name-from-query.js';

const storePartsQuery = gql`
	query StoreParts(
		$branchIds: [Int!]
		$aboveThreshold: Boolean
		$belowThreshold: Boolean
		$partCategoryIds: [Int!]
		$providerIds: [Int!]
		$partDescription: String
		$partIds: [Int!]
		$partNumbers: [String!]
		$search: String
		$size: Int
		$offset: Int
		$excludeEmpty: Boolean
		$excludeEmptyQuantity: Boolean
	) {
		storeParts(
			branchIds: $branchIds
			aboveThreshold: $aboveThreshold
			belowThreshold: $belowThreshold
			partCategoryIds: $partCategoryIds
			providerIds: $providerIds
			partDescription: $partDescription
			partIds: $partIds
			partNumbers: $partNumbers
			search: $search
			size: $size
			offset: $offset
			excludeEmpty: $excludeEmpty
			excludeEmptyQuantity: $excludeEmptyQuantity
		) {
			cost
			id
			location
			max
			children {
				id
				inventoryLocation {
					id
					name
				}
				availableQuantity
				__typename
			}
			part {
				...PartSummaryFragment
			}
			inventoryLocation {
				id
				__typename
				name
				branchId
			}
			note
			availableQuantity
			threshold
			partsOrdered
			total
			__typename
		}
	}

	fragment PartSummaryFragment on Part {
		id
		partNumber
		provider {
			name
		}
		partClass {
			...PartClassSummaryFragment
		}
		__typename
	}

	fragment PartClassSummaryFragment on PartClass {
		id
		description
		__typename
	}
`;

export const storePartsQueryName = getOperationNameFromQuery(storePartsQuery);
export default storePartsQuery;
