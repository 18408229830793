import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import styled from 'styled-components';

import { Autocomplete } from '@equipmentshare/react-ui-components';
import { EuiButtonIcon } from '@equipmentshare/ds2';
import { DeleteBin7LineIcon } from '@equipmentshare/ds2-icons';
import FilterRenderer from './filter-renderer';

import { useFilterDispatch } from '../../../providers/filter-provider';

import styles from './filter-row.module.css';

const DeleteButton = styled(EuiButtonIcon)`
	padding: 8px 6px;
`;

const FilterRow = ({ filterGroups = [], rowData, selectedGroup }) => {
	const { dispatcher } = useFilterDispatch();
	const [filterType, setFilterType] = useState(selectedGroup);

	useEffect(() => {
		setFilterType(_get(rowData, 'type', null));
	}, [selectedGroup]);

	const handleGroupSelect = (evt) => {
		if (evt !== filterType) {
			dispatcher.updateActiveFilterGroups(filterType);
		}
		setFilterType(evt);
		dispatcher.groupSelect(evt, rowData.id);
	};

	const handleRemoveRow = () => {
		dispatcher.removeRow(filterType, rowData.id);
	};

	return (
		<div className={styles.layout}>
			<div className={styles.groupSelector}>
				<Autocomplete
					className={styles.groupSelect}
					clearable={false}
					onChange={handleGroupSelect}
					options={filterGroups}
					placeholder="Choose filters"
					value={selectedGroup}
				/>
			</div>

			<FilterRenderer className={styles.filterSelect} filter={rowData} filterType={filterType} rowIndex={rowData.id} />
			<DeleteButton
				color="text"
				size="m"
				iconSize="l"
				iconType={DeleteBin7LineIcon}
				onClick={handleRemoveRow}
				aria-label="removeRowButton"
			/>
		</div>
	);
};

FilterRow.propTypes = {
	filterGroups: PropTypes.array,
	rowData: PropTypes.object.isRequired,
	selectedGroup: PropTypes.string,
};

export default FilterRow;
