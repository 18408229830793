import ngModule from '../../../../ng/index.js';

function DeliveryPickerDialogCtrl($scope, $mdDialog) {
	const ctrl = this;

	$scope.saved = $mdDialog.hide;
	$scope.close = $mdDialog.cancel;

	$scope.selectDelivery = (delivery) => {
		$mdDialog.hide(delivery);
	};

	$scope.deliveries = ctrl.deliveries;
}

ngModule.controller('DeliveryPickerDialogCtrl', DeliveryPickerDialogCtrl);
