import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Item from "./components/list-group-item.js";

import listGroupStyles from "./list-group.module.css";

const ListGroup = ( { children, noBorder, className } ) => (
  <ul className={classNames( listGroupStyles.group, { [listGroupStyles.noBorder]: noBorder }, className )}>
    {children}
  </ul>
);

ListGroup.Item = Item;

ListGroup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  noBorder: PropTypes.bool,
};

ListGroup.defaultProps = {
  children: null,
  className: null,
  noBorder: false,
};

export default ListGroup;
