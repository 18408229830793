import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '@equipmentshare/react2angular-router';
import Row from '../row/row.js';

import rowStyles from '../row/row.module.css';
import styles from './link-row.module.css';

const LinkRow = ({ children, className, ...props }) => (
	<Row component={Link} className={classNames(styles.href, rowStyles.clickable, className)} {...props}>
		{children}
	</Row>
);

LinkRow.propTypes = {
	params: PropTypes.object,
	to: PropTypes.string,
	children: PropTypes.any,
	className: PropTypes.string,
};

export default LinkRow;
