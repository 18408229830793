import { makeXFields } from '@helpers/xFields';

export const assetEquipmentAssignments = makeXFields`{
	equipment_assignment_id,
	end_date,
	start_date,
	rental_id,
	rental {
		order {
			user {
				first_name,
				last_name,
				company {
					name
				}
			}
		},
		rental_status {
			name
		}
	}
}`;

export const xFields = {
	assetEquipmentAssignments,
};

export default xFields;
