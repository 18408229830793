import pDefer from 'p-defer';
import ngModule from '../../ng/index.js';

import '@components/app-menu/app-menu-component.js';
import '@components/toast-stack/toast-stack-component';
import '@components/mobile-nav/mobile-nav-component';
import '../components/rental-takeover-card/rental-takeover-card-component';
import './react-query-debugger';
import '@services/toast.service';
import getActiveBreakpoints from '../../components/breakpoints/get-active-breakpoints.js';

import './layout.scss';

export const authLoaded = pDefer();

function LayoutCtrl($scope, $timeout) {
	$scope.authLoading = true;
	$scope.authError = null;
	$scope.itsTakingForever = false;
	$scope.now = new Date().valueOf();

	$scope.menuOpen = !getActiveBreakpoints().includes('tablet-landscape-and-down');

	$scope.toggleMenuOpen = () => {
		$scope.menuOpen = !$scope.menuOpen;
		$scope.$digest();
	};

	authLoaded.promise.then(() => {
		$scope.authLoading = false;
		$scope.authError = null;
		$scope.$digest();
	});

	authLoaded.promise.catch((error) => {
		$scope.authLoading = false;
		$scope.authError = error;
		$scope.$digest();
	});

	$timeout(() => {
		$scope.itsTakingForever = true;
	}, 5000);
}

ngModule.controller('LayoutCtrl', LayoutCtrl);
