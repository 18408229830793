import ngModule from '../../../ng/index.js';
import map from 'lodash/fp/map';

function AssetDetailInspectionsCtrl(
	$scope,
	$state,
	$q,
	esOpsInspectionsService,
	esCanonicalizer,
	takeoverCard,
	$mdDialog
) {
	const canonicalizer = esCanonicalizer.canonicalizer('asset_inspection');

	$scope.inspections = [];
	$scope.sort = {
		orderBy: 'created',
		reverse: true,
	};

	$scope.getInspectionStatusName = esOpsInspectionsService.getInspectionStatusName;
	$scope.getInspectionStatusColor = esOpsInspectionsService.getInspectionColor;

	$scope.loadInspections = () => {
		if (!$state.params.assetId || isNaN($state.params.assetId)) {
			return $q.resolve([]);
		}

		$scope.loadingState = 'loading';
		$scope.inspections = [];
		esOpsInspectionsService
			.getInspections($state.params.assetId)
			.then(map(canonicalizer))
			.then((inspections) => {
				$scope.loadingState = 'loaded';
				$scope.inspections = inspections;
			});
	};

	$scope.$watch(
		() => $state.params.assetId,
		() => {
			$scope.loadInspections();
		}
	);

	$scope.selectInspection = (inspection) => {
		$state.go('home.asset-detail.inspections.inspection', {
			...$state.params,
			inspectionId: inspection.asset_inspection_id,
		});
	};

	$scope.createInspection = () => {
		return $mdDialog
			.show(
				$mdDialog.confirm({
					title: 'Create Inspection',
					textContent: 'Are you sure you want to create a new inspection?',
					ok: 'Create',
					cancel: 'Cancel',
				})
			)
			.then(() => {
				return esOpsInspectionsService.createInspection($state.params.assetId).then((inspection) => {
					$scope.inspections.unshift(inspection);
					$state.selectInspection(inspection);
				});
			});
	};

	$scope.$watch(
		() => $state.current,
		() => {
			if ($state.is('home.asset-detail.inspections.inspection')) {
				takeoverCard.open('inspectionTakeoverCard');
			} else {
				takeoverCard.close('inspectionTakeoverCard');
			}
		}
	);

	$scope.loadInspections();
}

ngModule.controller('AssetDetailInspectionsCtrl', AssetDetailInspectionsCtrl);
