import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';

import { EuiFieldText, EuiFormRow } from '@equipmentshare/ds2';

import styles from './input-suggestions.module.css';

export const InputSuggestions = ({
	id,
	className,
	options,
	optionRenderer: Option,
	inputRenderer: InputComponent,
	onSelectOption,
	inputProps,
	label,
	fullWidth,
}) => {
	const [selected, setSelected] = useState(null);
	const [open, setOpen] = useState(false);

	const onFocus = useCallback(() => setOpen(true), [setOpen]);
	const onBlur = useCallback(
		_debounce(() => setOpen(false), 500),
		[setOpen]
	);
	const handleSelectOption = useCallback(
		(option) => {
			onSelectOption(option);
			setOpen(false);
		},
		[onSelectOption, setOpen]
	);

	return (
		<div className={classNames(styles.container, className)}>
			<EuiFormRow label={label} fullWidth={fullWidth}>
				<InputComponent onFocus={onFocus} onBlur={onBlur} {...inputProps} />
			</EuiFormRow>
			<div id={id} role="listbox" className={classNames(styles.menu, open && styles.isOpen)}>
				<ul role="listbox" className={classNames(styles.list)}>
					{options.map((option, index) => (
						<li
							className={styles.option}
							id={`${id}-${index}`}
							key={`${id}-${index}`}
							role="option"
							aria-selected={selected === index ? 'true' : 'false'}
							onClick={() => handleSelectOption(option)}
							onMouseOver={() => setSelected(index)}
						>
							<Option option={option} selected={selected === index} />
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

InputSuggestions.propTypes = {
	id: PropTypes.any.isRequired,
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	optionRenderer: PropTypes.func,
	inputRenderer: PropTypes.func,
	inputProps: PropTypes.object,
	options: PropTypes.array,
	onSelectOption: PropTypes.func,
	label: PropTypes.string,
	fullWidth: PropTypes.bool,
};

InputSuggestions.defaultProps = {
	inputRenderer: EuiFieldText,
};

InputSuggestions.Option = Option;

export default InputSuggestions;
