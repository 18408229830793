import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import companyGeofencesService from '@services/company-geofences.service';

import { withErrorHandler } from '@components/error-boundary';
import styles from './nearby-locations-warning.module.css';
import drawerStyles from '../../location-drawer.module.css';
import LocationDisplay from '@components/location-display/location-display';

export const NearbyLocationsWarning = ({ latitude, longitude, companyId, onSelectExistingGeofence }) => {
	const [existingGeofences, setExistingGeofences] = useState([]);

	useEffect(() => {
		if (companyId && latitude && longitude) {
			companyGeofencesService.search(companyId, { latitude, longitude }).then(setExistingGeofences);
		}
	}, [latitude, longitude, companyId, setExistingGeofences]);

	return latitude && longitude && existingGeofences.length > 0 ? (
		<div className={drawerStyles.warning}>
			<strong>Heads Up!</strong>
			<p>This location falls within another geofence. Verify it isnt a duplicate before creating a new one</p>
			<hr className={styles.hr} />
			{existingGeofences.map((geofence) => (
				<div key={geofence.geofence_id} className={styles.geofence}>
					<div className={styles.title}>
						<strong>{_get(geofence, 'location.nickname') || geofence.name}</strong>
						{onSelectExistingGeofence && geofence.location && (
							<span className={styles.useThisLocation} onClick={() => onSelectExistingGeofence(geofence)}>
								Use This Location
							</span>
						)}
					</div>
					{geofence.location && <LocationDisplay location={geofence.location} showNickname={false} />}
				</div>
			))}
		</div>
	) : null;
};

NearbyLocationsWarning.propTypes = {
	companyId: PropTypes.number,
	latitude: PropTypes.number,
	longitude: PropTypes.number,
	onSelectExistingGeofence: PropTypes.func,
};

export default withErrorHandler()(NearbyLocationsWarning);
