import React, { Suspense } from 'react';
import { react2angular } from 'react2angular';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

import withGlobalProviders from '@components/with-global-providers';
import ngModule from '../../ng/index.js';
import { Route } from '@equipmentshare/react2angular-router';

const InventoryView = React.lazy(() => import('./inventory-view'));

export const InventoryRoutes = () => (
	<Suspense fallback={<LoadingOverlay />}>
		<Route state="home.inventory" component={InventoryView} exact />
	</Suspense>
);

ngModule.component('inventoryRoutes', react2angular(withGlobalProviders(InventoryRoutes)));
