import React from "react";
import PropTypes from "prop-types";

export const DIRECTIONS = { up: "up", down: "down" };

export default function ArrowIcon( { filled, direction } ) {
  let path;

  if ( direction == DIRECTIONS.up ) {
    path = filled
      ? "M1.201 6.857H10.8c1.065 0 1.6-1.232.85-1.95L6.852.335a1.244 1.244 0 00-1.698 0l-4.8 4.572c-.756.718-.217 1.95.848 1.95z"
      : "M10.799 6.857H1.2c-1.065 0-1.604-1.232-.847-1.95L5.153.335a1.244 1.244 0 011.698 0l4.799 4.572c.75.718.214 1.95-.851 1.95zM6 1.142L1.201 5.714H10.8L6 1.142z";
  } else {
    path = filled
      ? "M10.799.143H1.2c-1.065 0-1.6 1.232-.85 1.95l4.798 4.572c.469.447 1.23.447 1.698 0l4.8-4.572c.756-.718.217-1.95-.848-1.95z"
      : "M1.201.143H10.8c1.065 0 1.604 1.232.847 1.95L6.847 6.665a1.244 1.244 0 01-1.698 0L.35 2.093C-.4 1.375.136.143 1.201.143zM6 5.858l4.799-4.572H1.2L6 5.858z";
  }

  return (
    <svg
      height={7}
      width={12}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d={path}
        fill={"currentColor"}
      />
    </svg>
  );
}

ArrowIcon.defaultProps = {
  filled: false,
  direction: DIRECTIONS.up
};

ArrowIcon.propTypes = {
  direction: PropTypes.oneOf( [ DIRECTIONS.up, DIRECTIONS.down ] ),
  filled: PropTypes.bool
};
