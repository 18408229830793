import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Cell from '../cell/cell.js';
import { EuiCheckbox, htmlIdGenerator } from '@equipmentshare/ds2';

import styles from './checkbox-cell.module.css';

export const CheckboxCell = ({ onChange, checked, className, stopClickPropagation, disabled, ...props }) => (
	<Cell
		className={classNames(styles.cell, className)}
		onClick={onChange}
		stopClickPropagation={stopClickPropagation}
		noPadding
		{...props}
	>
		<div className={styles.center}>
			<EuiCheckbox
				id={htmlIdGenerator()()}
				className={styles.checkbox}
				checked={checked}
				readOnly
				onChange={() => {}}
				disabled={disabled}
			/>
		</div>
	</Cell>
);

CheckboxCell.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	onChange: PropTypes.func,
	checked: PropTypes.bool,
	columnId: PropTypes.string,
	disabled: PropTypes.bool,
};
CheckboxCell.defaultProps = {
	stopClickPropagation: true,
	disabled: false,
};

export default CheckboxCell;
