import esApi from '@services/es-api.service';

export async function fetch(id, opts) {
	return await esApi.request(esApi.url`/admin/assets/${id}/equipment-assignments`, null, opts);
}

const assetEquipmentAssignmentsService = {
	fetch,
};

export default assetEquipmentAssignmentsService;
