import ngModule from '../ng/index.js';

import '@views/transactions/routes.js';
import { jsonApiClient } from '@services/simple-json-api.service';
import { createFilename, downloadAndSaveInvoice } from '@hooks/use-invoice-download-action';

ngModule.config(($stateProvider) => {
	$stateProvider.state('home.transactions', {
		url: '/transactions',
		template: '<transactions-routes/>',
		redirectTo: 'home.transactions.invoices',
	});

	// invoices
	$stateProvider
		.state('home.transactions.invoices', {
			url: '/invoices',
			redirectTo: 'home.transactions.invoices.search',
		})
		.state('home.transactions.invoices.search', {
			url: `/search?${[
				'query',
				'branch',
				'status',
				'start',
				'end',
				'salesperson',
				'company',
				'rentalType',
				'equipmentClass',
			].join('&')}`,
		})
		.state('home.transactions.invoices.create', {
			url: '/create?companyId&reference&date&memo&orderId',
		})
		.state('home.transactions.invoices.invoice', {
			url: '/:invoiceId',
		})
		.state('home.transactions.invoices.invoice.pdf', {
			url: '/pdf',
			onEnter: async ($state, $stateParams) => {
				const invoiceId = parseInt($stateParams.invoiceId, 10) || null;

				if (invoiceId) {
					const invoice = await jsonApiClient.get(`/billing-service/invoices/${invoiceId}`);
					await downloadAndSaveInvoice(invoiceId, createFilename(invoice));

					$state.go('home.transactions.invoices.invoice', $stateParams);
				} else {
					$state.go('home.transactions.invoices');
				}
			},
		});

	// credit notes
	$stateProvider
		.state('home.transactions.credit-notes', {
			url: '/credit-notes',
			redirectTo: 'home.transactions.credit-notes.search',
		})
		.state('home.transactions.credit-notes.search', {
			url: `/search?${['companyId', 'dateCreated', 'query', 'status', 'branchId'].join('&')}`,
		})
		.state('home.transactions.credit-notes.create', {
			url: '/create',
			redirectTo: 'home.transactions.credit-notes.create.standalone',
		})
		.state('home.transactions.credit-notes.create.from-invoice', {
			url: '/from-invoice?invoiceId&memo',
		})
		.state('home.transactions.credit-notes.create.standalone', {
			url: '/standalone?companyId',
		})
		.state('home.transactions.credit-notes.credit-note', {
			url: '/:creditNoteId',
		})
		.state('home.transactions.credit-notes.credit-note.allocate', {
			url: '/allocate',
		})
		.state('home.transactions.credit-notes.credit-note.refund', {
			url: '/refund',
		});
});
