import _each from 'lodash/each';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import map from 'lodash/fp/map';
import * as fileSaver from "file-saver";

angular.module('esApiClient').factory('esAdminInvoiceService', function (esApiFactoryV2, esCanonicalizer) {
  /**
   * Pending [unapproved]
   * Approved [approved, but not "sent"]
   * Awaiting Payment [Sent but not paid]
   * Paid [paid]
   * @type {Object}
   */
  const INVOICE_STATES = {
    ALL: "All",
    PENDING: "Pending",
    APPROVED: "Approved",
    AWAITING_PAYMENT: "Awaiting Payment",
    PAID: "Paid"
  };

  let canonicalizer = esCanonicalizer.canonicalizer('invoice');
  let canonicalizeLineItem = esCanonicalizer.canonicalizer('line_item');

  function processInvoice(invoice){
    getBranchForInvoice(invoice);

    _each(invoice.line_items, processLineItem);

    return invoice;
  }

  function processLineItem(lineItem) {
    lineItem.line_item_type_id = lineItem.line_item_type.line_item_type_id;
    return lineItem;
  }

  function getBranchForInvoice(invoice){
    if(!invoice.branch && invoice.branch_id){
      invoice.branch = esCanonicalizer.canonicalize('branch', invoice.branch_id);
    }
    if(invoice.order && !invoice.order.branch && invoice.order.branch_id){
      invoice.order.branch = esCanonicalizer.canonicalize('branch', invoice.order.branch_id);
    }
    return invoice;
  }

  function getInvoices(config) {
    return esApiFactoryV2.adminGetInvoices(config)
      .then(map(processInvoice))
      .then(map(canonicalizer))
  }

  function getInvoice(invoiceId) {
    return esApiFactoryV2.adminGetInvoice(invoiceId)
      .then(processInvoice)
      .then(canonicalizer);
  }

  function postInvoice(invoice) {
    return esApiFactoryV2.adminPostInvoice(invoice);
  }

  function patchInvoice(invoiceId, payload) {
    return esApiFactoryV2.adminPatchInvoice(invoiceId, payload)
      .then(() => getInvoice(invoiceId));
  }

  function approveInvoice(invoiceId) {
    return esApiFactoryV2.adminPatchInvoice(invoiceId, {
      billing_approved: true
    })
      .then(() => getInvoice(invoiceId));
  }

  function getLineItem(lineItemId) {
    return esApiFactoryV2.adminGetLineItem(lineItemId)
      .then(processLineItem)
      .then(canonicalizeLineItem);
  }

  function updateLineItem(lineItemId, payload) {
    return esApiFactoryV2.adminPatchLineItem(lineItemId, payload)
      .then(() => getLineItem(lineItemId));
  }

  function createLineItem(invoiceId, rentalId,  payload) {
    payload.invoice_id = invoiceId;
    payload.rental_id = rentalId;
    return esApiFactoryV2.adminPostLineItem(payload)
      .then(processLineItem)
      .then(canonicalizeLineItem)
      .then(lineItem => {
        let invoice = canonicalizer(lineItem.invoice_id);
        if(invoice && invoice.line_items){
          invoice.line_items.push(lineItem);
        }
      })
  }

  function removeLineItem(lineItemId){
    return esApiFactoryV2.adminDeleteLineItem(lineItemId)
      .then(() => {
        let invoiceId = canonicalizeLineItem(lineItemId).invoice_id;
        if(invoiceId){
          let invoice = canonicalizer(invoiceId);
          if(invoice){
            _remove(invoice.line_items, _find(invoice.line_items, ['line_item_id', lineItemId]))
          }
        }
      })
  }

  function downloadInvoicePdf( invoiceId, filename = null ) {
    const downloadPdfFile = () => esApiFactoryV2.getInvoicePdf( invoiceId )
      .then( ( pdf ) => {
        let file = new File( [ pdf ], filename, { type: "application/pdf" } );
        fileSaver.saveAs( file );
      } );
    // no file name was provided get the invoice and create a filename before
    // getting the pdf
    if ( !filename ) {
      return getInvoice( invoiceId )
        .then( ( { invoice_no, billing_approved } ) => {
          let isDraft = !billing_approved ? "_DRAFT" : "";
          filename = `Invoice_${invoice_no}${isDraft}`;
        })
        .then( downloadPdfFile );

    }
    else {
      return downloadPdfFile();
    }
  }

  function downloadBulkInvoiceZip(invoiceIds, filename = 'invoices.zip') {
    return esApiFactoryV2.getBulkInvoicesZip(invoiceIds).then(zipBlob => {
      let file = new File( [ zipBlob ], filename, { type: "application/zip" } );
      fileSaver.saveAs( file );
    });
  }

  return {
    getInvoices,
    getInvoice,
    patchInvoice,
    postInvoice,
    approveInvoice,

    getLineItem,
    updateLineItem,
    createLineItem,
    removeLineItem,
    downloadInvoicePdf,
    downloadBulkInvoiceZip,
    INVOICE_STATES
  }
});
