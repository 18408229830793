import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import { ENTITY_TYPES, cacheKey } from '@equipmentshare/admin-hooks';

import LocationAutocomplete from '../../js/components/location-autocomplete/location-autocomplete';
import companiesService from '@services/companies.service';
import locationsService from '@services/locations.service';

const LocationPicker = ({ companyId, onChange, selectedLocationId, includeEsLocations, ...props }) => {
	const { data: companyLocations = [], isLoading: companyIsLoading } = useQuery(
		cacheKey(ENTITY_TYPES.LOCATION, 'location-picker', { companyId }),
		() => companiesService.fetchLocations(companyId),
		{ enabled: !!companyId }
	);

	const { data: esLocations = [], isLoading: esLocationsIsLoading } = useQuery(
		cacheKey(ENTITY_TYPES.LOCATION, 'location-picker', { location_type: 1, query: '' }),
		() => locationsService.search({ location_type: 1, query: '' }),
		{ enabled: !!includeEsLocations }
	);

	const isLoading = includeEsLocations ? companyIsLoading || esLocationsIsLoading : companyIsLoading;
	const locations = includeEsLocations ? companyLocations.concat(esLocations) : companyLocations;

	return (
		<LocationAutocomplete
			locations={locations}
			selectedLocationId={selectedLocationId}
			onChange={onChange}
			isLoading={isLoading}
			{...props}
		/>
	);
};

LocationPicker.propTypes = {
	label: PropTypes.string,
	companyId: PropTypes.number,
	onChange: PropTypes.func,
	selectedLocationId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
	includeEsLocations: PropTypes.bool,
};

export default LocationPicker;
