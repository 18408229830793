import ngModule from '../../../ng/index.js';
import template from './delivery-map.html';

ngModule.component('deliveryMap', {
	template,
	controller: 'DeliveryMapCtrl',
	bindings: {
		delivery: '<',
		deliveries: '<',
		focusedDeliveries: '<',
		onSelectDelivery: '<',
	},
});
