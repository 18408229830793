import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '@equipmentshare/react2angular-router';

import { RotatingIcon } from '@components/rotating-icon/rotating-icon';

import styles from './action-cell.module.css';

function stopPropagation(fn) {
	return (event) => {
		event.stopPropagation();

		fn(event);
	};
}

export const Action = ({ to, href, onClick, className, icon, disabled, iconProps = {}, ...props }) => {
	let iconElement = null;

	if (icon) {
		iconElement = <RotatingIcon type={icon} {...iconProps} />;
	}

	if (to) {
		return (
			<Link
				to={to}
				className={classNames(styles.action, disabled && styles.disabled, className)}
				onClick={onClick && stopPropagation(onClick)}
				disabled={disabled}
				{...props}
			>
				{iconElement}
			</Link>
		);
	} else if (href) {
		return (
			<a
				href={href}
				className={classNames(styles.action, disabled && styles.disabled, className)}
				onClick={onClick && stopPropagation(onClick)}
				{...props}
			>
				{iconElement}
			</a>
		);
	}

	return (
		<button
			className={classNames(styles.action, disabled && styles.disabled, className)}
			onClick={onClick && stopPropagation(onClick)}
			disabled={disabled}
			type="button"
			{...props}
		>
			{iconElement}
		</button>
	);
};

Action.propTypes = {
	className: PropTypes.string,
	to: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	title: PropTypes.string,
	icon: PropTypes.func,
	disabled: PropTypes.bool,
	iconProps: PropTypes.object,
};

export default Action;
