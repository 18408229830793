import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import AngularBridge from "./angular-bridge";

function getDisplayName( WrappedComponent ) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

let historyObjectCache = {};

export function map$StateToMatchProps( $state ) {
  // https://reacttraining.com/react-router/web/api/location
  let location = {
    pathname: $state.$current.url.sourcePath,
    search: $state.$current.url.sourceSearch
  };

  return {
    // https://reacttraining.com/react-router/web/api/history
    history: Object.assign( historyObjectCache, {
      length: 0,
      push: $state.go,
      replace: $state.go,
      location
    } ),
    // https://reacttraining.com/react-router/web/api/match
    match: {
      params: $state.params,
      path: $state.$current.name,
      url: $state.$current.url.source
    },
    location
  };
}

export default function withState( shouldUpdate ){
  return ( WrappedComponent ) => {
    class WithRouter extends PureComponent {
      constructor( props ) {
        super( props );

        this.handleStateChange = this.handleStateChange.bind( this );
      }

      UNSAFE_componentWillMount(){
        this.$state = AngularBridge.getAngularService( "$state" );
        this.$rootScope = AngularBridge.getAngularService( "$rootScope" );

        this.removeStateChangeListener = this.$rootScope.$on( "$stateChangeSuccess", this.handleStateChange );
      }

      componentWillUnmount(){
        this.removeStateChangeListener();
      }

      handleStateChange( event, toState, toParams, fromState, fromParams ){
        toState.params = toParams;
        fromState.params = fromParams;
        if( shouldUpdate && shouldUpdate( toState, fromState ) === false ){
          return;
        }

        this.forceUpdate();
      }

      render() {
        const $state = this.$state;
        const { wrappedComponentRef } = this.props;

        return (
          <WrappedComponent
            $state={$state}
            ref={wrappedComponentRef}
            {...map$StateToMatchProps( $state )}
            {...this.props}
          />
        );
      }
    }

    WithRouter.displayName = `WithState(${getDisplayName( WrappedComponent )})`;
    /* https://reacttraining.com/react-router/web/api/withRouter/componentwrappedcomponent */
    WithRouter.WrappedComponent = WrappedComponent;

    WithRouter.propTypes = {
      /* https://reacttraining.com/react-router/web/api/withRouter/wrappedcomponentref-func */
      wrappedComponentRef: PropTypes.func
    };
    WithRouter.defaultProps = {
      wrappedComponentRef: null
    };

    return WithRouter;
  };
}
