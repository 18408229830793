import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Cell from '../cell/cell.js';
import Action from './action.js';

import styles from './action-cell.module.css';

export const ActionCell = ({ className, children, ...props }) => (
	<Cell className={classNames(styles.cell, className)} {...props}>
		<div className={styles.actionsContainer}>{children}</div>
	</Cell>
);

ActionCell.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};
ActionCell.defaultProps = {
	columnId: 'actions',
};

ActionCell.Action = Action;

export default ActionCell;
