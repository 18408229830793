import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import CheckboxCell from '../checkbox-cell/checkbox-cell.js';
import { useSelection } from '../providers/selection-provider.js';

const SelectionCell = ({ selectionId, disabled, ...props }) => {
	const { selectedIds, selectSingleId, selectMultiId } = useSelection();

	const handleSelect = useCallback(
		(event) => {
			event.preventDefault();
			if (!disabled) {
				if (event.shiftKey) {
					selectMultiId(selectionId);
				} else {
					selectSingleId(selectionId);
				}
			}
		},
		[selectMultiId, selectionId, disabled]
	);

	return (
		<CheckboxCell {...props} checked={selectedIds.includes(selectionId)} onChange={handleSelect} disabled={disabled} />
	);
};

SelectionCell.propTypes = {
	selectionId: PropTypes.number.isRequired,
	onSelect: PropTypes.func,
	disabled: PropTypes.bool,
};
SelectionCell.defaultProps = {
	columnId: 'selection',
	disabled: false,
};

export default React.memo(SelectionCell);
