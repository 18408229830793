import angularBridge from '../js/angular2react/angular-bridge.js';
import '../js/components/rental-takeover-card/rental-takeover-card-service';

function getServices() {
	const service = angularBridge.getAngularService('rentalTakeoverCardService');
	const rootScope = angularBridge.getAngularService('$rootScope');

	return { service, rootScope };
}

function showRentalCard(rentalId) {
	const { service, rootScope } = getServices();

	service.setRentalId(rentalId);
	service.setOpen(true);
	if (rootScope.$$phase !== '$digest') {
		rootScope.$digest();
	}
}

function hideRentalCard() {
	const { service, rootScope } = getServices();
	service.setOpen(false);
	if (rootScope.$$phase !== '$digest') {
		rootScope.$digest();
	}
}

function addRentalChangeListener(fn) {
	const { service, rootScope } = getServices();
	service.addRentalChangeListener(fn);
	if (rootScope.$$phase !== '$digest') {
		rootScope.$digest();
	}
}

function removeRentalChangeListener(fn) {
	const { service, rootScope } = getServices();
	service.removeRentalChangeListener(fn);
	if (rootScope.$$phase !== '$digest') {
		rootScope.$digest();
	}
}

const rentalTakeoverCardService = {
	showRentalCard,
	hideRentalCard,
	addRentalChangeListener,
	removeRentalChangeListener,
};

export default rentalTakeoverCardService;
