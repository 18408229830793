import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { validProgress } from "../progress.js";
import ProgressText from "../components/progress-text.js";

import styles from "../progress.module.css";

const statusColorMap = {
  normal: "#108ee9",
  exception: "#ff5500",
  success: "#87d068",
};

class Circle extends PureComponent {
  constructor( props ) {
    super( props );

    this.setRef = this.setRef.bind( this );
  }

  componentDidUpdate() {
    let pathStyle = this.path.style;

    pathStyle.transitionDuration = "0.3s, 0.3s, 0.3s, 0.06s";
    let now = Date.now();

    if ( this.prevTimeStamp && ( now - this.prevTimeStamp ) < 100 ) {
      pathStyle.transitionDuration = "0s, 0s";
    }
    this.prevTimeStamp = Date.now();
  }

  getPathStyles( progressStatus ) {
    let { percent, strokeWidth, strokeColor, gapDegree, gapPosition } = this.props;

    let circleWidth = strokeWidth || 6;
    let radius = 50 - ( circleWidth / 2 );
    let beginPositionX = 0;
    let beginPositionY = -radius;
    let endPositionX = 0;
    let endPositionY = -2 * radius;

    switch ( gapPosition ) {
      case "left":
        beginPositionX = -radius;
        beginPositionY = 0;
        endPositionX = 2 * radius;
        endPositionY = 0;
        break;
      case "right":
        beginPositionX = radius;
        beginPositionY = 0;
        endPositionX = -2 * radius;
        endPositionY = 0;
        break;
      case "bottom":
        beginPositionY = radius;
        endPositionY = 2 * radius;
        break;
      default:
    }

    let pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
      a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
      a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`;
    let len = Math.PI * 2 * radius;
    let trailPathStyle = {
      strokeDasharray: `${len - gapDegree}px ${len}px`,
      strokeDashoffset: `-${gapDegree / 2}px`,
      transition: "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s",
    };
    let strokePathStyle = {
      stroke: strokeColor || statusColorMap[progressStatus],
      strokeDasharray: `${( validProgress( percent ) / 100 ) * ( len - gapDegree )}px ${len}px`,
      strokeDashoffset: `-${gapDegree / 2}px`,
      transition: "stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s, stroke-width 0.06s ease 0.3s",
    };

    return { pathString, trailPathStyle, strokePathStyle };
  }

  setRef( ref ) {
    this.path = ref;
  }

  render() {
    let { className, percent, progressType, trailColor, width, showInfo, strokeLinecap, strokeWidth, labelOverride } = this.props;

    let progressStatus = parseInt( percent.toString(), 10 ) >= 100 && !progressType ? "success" : ( progressType || "normal" );
    let { pathString, trailPathStyle, strokePathStyle } = this.getPathStyles( progressStatus );

    let circleWidth = strokeWidth || 6;
    let circleSize = width || 120;
    let circleStyle = {
      width: circleSize,
      height: circleSize,
      fontSize: circleSize * 0.15 + 6,
    };

    let progressInfo = showInfo
      ? ( <ProgressText labelOverride={labelOverride} percent={percent} progressType={progressStatus} /> )
      : null;

    let containerClasses = classNames( styles.progress, styles.progressCircle , {
      [styles.progressStatusException]: progressStatus === "exception",
      [styles.progressStatusSuccess]: progressStatus === "success",
      [styles.progressShowInfo]: showInfo,
    }, className );
    let circleClasses = classNames( styles.progressCircle, className );

    return (
      <div className={containerClasses}>
        <div className={styles.progressInner} style={circleStyle}>
          <svg className={circleClasses} viewBox="0 0 100 100">
            <path
              className={styles.progressCircleTrail}
              d={pathString}
              fillOpacity="0"
              stroke={trailColor}
              strokeWidth={circleWidth}
              style={trailPathStyle}
            />
            <path
              className={styles.progressCirclePath}
              d={pathString}
              fillOpacity="0"
              ref={this.setRef}
              strokeLinecap={strokeLinecap}
              strokeWidth={this.props.percent === 0 ? 0 : circleWidth}
              style={strokePathStyle}
            />
          </svg>
          {progressInfo}
        </div>
      </div>
    );
  }
}

Circle.propTypes = {
  /** additional classes applied to the progress bar */
  className: PropTypes.string,
  /** How of a gap is rendered, 0 - 360 */
  gapDegree: PropTypes.number,
  /** The position of the gap in the circle */
  gapPosition: PropTypes.oneOf( [
    "top",
    "bottom",
    "left",
    "right"
  ] ),
  /** a function that accepts two arguments: `status` and `percent` and returns a JSX node or string */
  labelOverride: PropTypes.func,
  /** How much of the progress is filled in */
  percent: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string
  ] ),
  /** the type of progress bar to use and any associated icons */
  progressType: PropTypes.oneOf( [
    "normal",
    "exception",
    "success"
  ] ),
  /** should the info be displayed inside the circle */
  showInfo: PropTypes.bool,
  /** color of progress bar */
  strokeColor: PropTypes.string,
  /** type of end used for the filled in progress */
  strokeLinecap: PropTypes.oneOf( [
    "butt",
    "round",
    "square"
  ] ),
  /** How thick the progress bar is in pixels */
  strokeWidth: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string
  ] ),
  /** color of the unfilled progress bar */
  trailColor: PropTypes.string,
  /** width of the progress circle */
  width: PropTypes.number,
};

Circle.defaultProps = {
  className: undefined,
  gapDegree: 0,
  gapPosition: "bottom",
  labelOverride: undefined,
  percent: 0,
  showInfo: true,
  progressType: undefined,
  strokeColor: undefined,
  strokeLinecap: "round",
  strokeWidth: undefined,
  trailColor: "#f3f3f3",
  width: undefined
};

export default Circle;
