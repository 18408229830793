import esApi from './es-api.service';

export async function fetch(companyId, opts) {
	return await esApi.request(esApi.url`/companies/${companyId}`, null, opts);
}

export async function fetchMultiple(companyIds, opts) {
	return await Promise.all(
		companyIds.map(async (companyId) => {
			return await fetch(companyId, null, opts);
		})
	);
}

export async function create(payload) {
	return await esApi.request('/admin/companies', null, { method: 'POST', body: payload });
}

export async function search(query, opts) {
	return await esApi.request('/companies', query, opts);
}

export async function adminSearch(query, opts) {
	return await esApi.request('/admin/companies', query, opts);
}

export async function fetchLocations(companyId) {
	return await esApi.request(esApi.url`/companies/${companyId}/locations`);
}

async function fetchServicedAssets(companyId, query, opts) {
	return await esApi.request(esApi.url`/companies/${companyId}/serviced-assets`, query, opts);
}

export async function fetchNetTerms() {
	return await esApi.request(esApi.url`/admin/net-terms`);
}

export async function update(companyId, payload, overrideDuplicateEINWarning = false) {
	if (overrideDuplicateEINWarning) {
		payload.override_duplicate_ein_warning = true;
	}
	return await esApi.request(
		`/admin/companies/${companyId}`,
		{},
		{
			method: 'PATCH',
			body: payload,
		}
	);
}

export async function mergeCompanies(companyId, targetCompanyId) {
	return await esApi.request('/companies/merge', null, {
		method: 'POST',
		body: {
			company_id: companyId,
			destination_company_id: targetCompanyId,
		},
	});
}

export async function getBillingProviders() {
	return await esApi.request('/admin/companies/billing_providers');
}

export async function fetchParts() {
	return await esApi.request('/billing/parts');
}

export async function createIntacctCustomer(companyId) {
	const result = await esApi.request(
		esApi.url`/billing/intacct-customer/${companyId}`,
		{},
		{
			method: 'POST',
		}
	);
	return result;
}

async function fetchUsers(companyId, query, opts) {
	return await esApi.request(esApi.url`/companies/${companyId}/users`, query, opts);
}

async function fetchViewSettings(companyId, applicationId) {
	const result = await esApi.request(
		esApi.url`/companies/${companyId}/preferences?client_application_id=${applicationId}`
	);

	return result.preferences;
}
async function replaceViewSettings(companyId, applicationId, viewSettings) {
	return await esApi.request(
		esApi.url`/companies/${companyId}/preferences?client_application_id=${applicationId}`,
		{
			client_application_id: applicationId,
			preferences: viewSettings,
		},
		{ method: 'PUT' }
	);
}
async function updateViewSettings(companyId, applicationId, viewSettings) {
	const oldViewSettings = await fetchViewSettings(companyId, applicationId);

	return await replaceViewSettings(companyId, applicationId, { ...oldViewSettings, ...viewSettings });
}

// Contracts related endpoints:

export async function getAllCompanyContracts(companyId, query) {
	return await esApi.request(esApi.url`/companies/${companyId}/company-contracts`, query);
}

export async function getSingleCompanyContract(companyId, companyContractId) {
	return await esApi.request(esApi.url`/companies/${companyId}/company-contracts/${companyContractId}`);
}

export async function getCompanyContractPDF(companyId, companyContractId) {
	return await esApi.request(esApi.url`/companies/${companyId}/company-contracts/${companyContractId}/pdf`);
}

export async function resendCompanyContract(companyId) {
	const result = await esApi.request(
		esApi.url`/companies/${companyId}/company-contracts/send`,
		{},
		{
			method: 'POST',
		}
	);
	return result;
}

export async function regenerateCompanyContract(companyId) {
	const result = await esApi.request(
		esApi.url`/companies/${companyId}/company-contracts/regenerate`,
		{},
		{
			method: 'POST',
		}
	);
	return result;
}

export async function createCompanyContract(companyId) {
	const result = await esApi.request(
		esApi.url`/companies/${companyId}/company-contracts`,
		{},
		{
			method: 'POST',
		}
	);
	return result;
}

const companiesService = {
	fetch,
	fetchMultiple,
	create,
	search,
	adminSearch,
	fetchLocations,
	fetchServicedAssets,
	fetchNetTerms,
	update,
	mergeCompanies,
	getBillingProviders,
	fetchParts,
	createIntacctCustomer,
	fetchUsers,
	fetchViewSettings,
	replaceViewSettings,
	updateViewSettings,
	getAllCompanyContracts,
	getSingleCompanyContract,
	getCompanyContractPDF,
	resendCompanyContract,
	regenerateCompanyContract,
	createCompanyContract,
};

export default companiesService;
