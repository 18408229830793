import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@equipmentshare/react-ui-components';
import makesService from '@services/makes.service';

const EquipmentMakePicker = ({ makeId, onChange, label, disabled, ...props }) => {
	const [makes, setMakes] = useState([]);

	useEffect(() => {
		async function getMake() {
			const make = await makesService.fetch(makeId);
			makes.push(make);
			setMakes(makes);
			onChange(makeId);
		}
		if (makeId && !makes.find(({ equipment_make_id }) => makeId === equipment_make_id)) {
			getMake(makeId);
		}
	}, [makeId]);

	const fetchMakes = async (value) => {
		const query = { query: value };
		const equipmentMakes = await makesService.search(query);
		setMakes(equipmentMakes);
	};

	const handleInputChange = (value) => {
		if (value && value.length > 1) {
			fetchMakes(value);
		}

		return value;
	};

	return (
		<Autocomplete
			label={label}
			value={makeId}
			onChange={onChange}
			disabled={disabled}
			labelKey="name"
			valueKey="equipment_make_id"
			onInputChange={(value) => handleInputChange(value)}
			options={makes}
			{...props}
		/>
	);
};

EquipmentMakePicker.propTypes = {
	label: PropTypes.string,
	makeId: PropTypes.number,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};
EquipmentMakePicker.defaultProps = {
	label: 'Select a Make',
};

export default EquipmentMakePicker;
