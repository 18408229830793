import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import popoverStyles from "./popover.module.css";

const POSITIONS = {
  N: ( rect ) => ( {
    left: rect.x + rect.width / 2,
    top: rect.y
  } ),
  NE: ( rect ) => ( {
    left: rect.x + rect.width ,
    top: rect.y
  } ),
  E: ( rect ) => ( {
    left: rect.x + rect.width ,
    top: rect.y + rect.height / 2
  } ),
  SE: ( rect ) => ( {
    left: rect.x + rect.width,
    top: rect.y + rect.height
  } ),
  S: ( rect ) => ( {
    left: rect.x + rect.width / 2,
    top: rect.y + rect.height
  } ),
  SW: ( rect ) => ( {
    left: rect.x,
    top: rect.y + rect.height
  } ),
  W: ( rect ) => ( {
    left: rect.x,
    top: rect.y + rect.height / 2
  } ),
  NW: ( rect ) => ( {
    left: rect.x,
    top: rect.y
  } ),
  C: ( rect ) => ( {
    left: rect.x + rect.width / 2,
    top: rect.y + rect.height / 2
  } ),
};

const ALIGNMENT = {
  HORIZONTAL: [ "left", "center", "right" ],
  VERTICAL: [ "top", "middle", "bottom" ]
};

export function calcPopoverPosition( anchorNode, anchor ) {
  if( typeof anchor === "string" ){
    anchor = POSITIONS[String( anchor ).toUpperCase()];
  }

  if( !anchorNode || !anchor ){
    return { left: 0, top: 0 };
  }

  let rect = anchorNode.getBoundingClientRect();

  return anchor( rect, anchorNode ) || { left: 0, top: 0 };
}

export function getAlignment( align = [] ) {
  if( !Array.isArray( align ) && typeof align === "string" ){
    align = align.split( " " );
  }

  let horizontal = align.find( ( val ) => ALIGNMENT.HORIZONTAL.includes( String( val ).toLowerCase() ) ) || "center";
  let vertical = align.find( ( val ) => ALIGNMENT.VERTICAL.includes( String( val ).toLowerCase() ) ) || "bottom";

  return [ vertical, horizontal ];
}

export function getOffset( offset ){
  let styles = {};

  if( offset && typeof offset === "number" || typeof offset === "string" ){
    return {
      margin: offset
    };
  }
  else if( offset && typeof offset === "object" ){
    styles.marginTop = offset.top || offset.vertical || 0;
    styles.marginBottom = offset.bottom || offset.vertical || 0;

    styles.marginRight = offset.right || offset.horizontal || 0;
    styles.marginLeft = offset.left || offset.horizontal || 0;
  }

  return styles;
}

const Popover = ( { anchorNode, children, className, anchor, align, offset } ) => {
  let alignmentClasses = getAlignment( align ).map( ( a ) => popoverStyles[a] );
  let anchorElement = ( anchorNode && anchorNode.firstElementChild ) || anchorNode;
  let anchorStyles = calcPopoverPosition( anchorElement, anchor );
  let offsetStyles = getOffset( offset );

  return (
    <div className={classNames( popoverStyles.popover, className, { [popoverStyles.hasAnchor]: !!anchorNode } )} style={anchorStyles}>
      <div className={classNames( popoverStyles.popoverContent, alignmentClasses )} style={offsetStyles}>
        {children}
      </div>
    </div>
  );
};

Popover.propTypes = {
  /** the alignment of the popover */
  align: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.arrayOf( PropTypes.string )
  ] ),
  /** a function that is used to position the popover on the screen */
  anchor: PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.func
  ] ),
  /** the anchor node */
  anchorNode: PropTypes.instanceOf( Element ),
  /** popover contents */
  children: PropTypes.any,
  /** custom class */
  className: PropTypes.string,
  /** a CSS value or an object that will be used to offset the popover from its anchor */
  offset: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape( {
      top: PropTypes.any,
      left: PropTypes.any,
      right: PropTypes.any,
      bottom: PropTypes.any,
      vertical: PropTypes.any,
      horizontal: PropTypes.any,
    } )
  ] ),
};
Popover.defaultProps = {
  align: "center bottom",
  anchor: POSITIONS.S,
  anchorNode: null,
  children: null,
  className: "",
  offset: null,
};

export default Popover;
