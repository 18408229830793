import esApi from './es-api.service';

export function search(companyId, query) {
	return esApi.request(esApi.url`/companies/${companyId}/geofences`, query);
}

const companyGeofencesService = {
	search,
};

export default companyGeofencesService;
