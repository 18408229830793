import angular from 'angular';
import './photo-dialog-service.js';

angular.module("esNgComponents").directive('photoDialog', function (photoDialogService) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      /**
       * Open the panel dialog for a give photo
       * if the photo-dialog attribute is passed with a url argument it'll be
       * used as the source for the image in the panel other wise the src attribute
       * will be used as the source for the panel dialog.
       *
       * Usage Example: <img ng-src='<my thumbnail photo url>' alt='' photo-dialog='<my large photo url>' />
       * @return {undefined}
       */
      function openPanel() {
        photoDialogService.showPhoto(attrs.photoDialog || attrs.src);
      }
      element.on('click', openPanel);
    }
  };
}).controller('PhotoDialogCtrl', function(imageSource, closeDialog) {});
