import '../../polyfills';
import _map from "lodash/map";
import _each from "lodash/each";
import _pick from "lodash/pick";
import _forEach from "lodash/forEach";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";
import { getUrls } from '@equipmentshare/env-config';

angular.module( "esApiClient" ).factory( "esRentalService", esRentalService );

function esRentalService( esApiFactoryV2, $q ) {
  let service = this;

  service.setPhotoUrls = ( rentals ) => {
    _each( rentals, ( rental ) => {
      if ( rental.equipment ) {
        rental.equipment._photoUrl = esApiFactoryV2.getPhotoUrl( rental.equipment, false, true );
      } else {
        rental._photoUrl = esApiFactoryV2.getPhotoUrl( rental );
      }
    } );
  };

  service.setEquipmentHasTracker = ( rentals ) => {
    _forEach( rentals, ( rental ) => {
      if ( rental.equipment ) {
        rental.equipment._hasTracker = !_isEmpty( rental.equipment.current_status );
      } else {
        rental._hasTracker = !_isEmpty( rental.current_status );
      }
    } );
  };

  service.setIsFilterActive = ( rentals, isActive ) => {
    _each( rentals, ( asset ) => {
      asset._isFilterActive = isActive;
    } );
  };

  function calculateRentalDays( start, end ) {
    let startMoment = moment( start );
    let endMoment = moment( end );

    return Math.round( moment.duration( endMoment.diff( startMoment ) ).asDays() );
  }

  function calculateRentalDurations( days ) {
    let durations = { "days": 0, "weeks": 0, "months": 0 };

    while ( days > 0 ) {
      if ( days >= 18 ) {
        days -= 28;
        durations.months++;
        continue;
      }

      if ( days >= 4 ) {
        days -= 7;
        durations.weeks++;
        continue;
      }

      if ( days ) {
        days--;
        durations.days++;
      }
    }

    return durations;
  }

  function calculateRentalDurationsWithHours( hours ) {
    let durations = { "hours": 0, "days": 0, "weeks": 0, "months": 0 };

    while (hours > 0) {
      if (hours >= 28 * 24) {
        hours -= 28 * 24;
        durations.months++;
        continue;
      }

      if (hours >= 7 * 24) {
        hours -= 7 * 24;
        durations.weeks++;
        continue;
      }

      if (hours >= 24) {
        hours -= 24;
        durations.days++;
        continue;
      }

      if (hours) {
        hours--;
        durations.hours++;
      }
    }

    return durations;
  }

	function getHumanizedDuration( startDate, endDate ) {
		if(!startDate || !endDate) return "";

    let hours = moment.duration( moment(endDate).diff( moment(startDate)) ).asHours();
    let duration = calculateRentalDurationsWithHours( hours );

		let parts = [];

		if ( duration.months ) {
			parts.push(duration.months + " month" + ( duration.months > 1 ? "s" : "" ));
		}
		if ( duration.weeks ) {
			parts.push(duration.weeks + " week" + ( duration.weeks > 1 ? "s" : "" ));
		}
		if ( duration.days ) {
			parts.push(duration.days + " day" + ( duration.days > 1 ? "s" : "" ));
		}
		if ( duration.hours ) {
			parts.push(duration.hours + " hour" + ( duration.hours > 1 ? "s" : "" ));
		}

		return parts.join(" ");
	}

  function getRentalAgreementLink( rentalOrderId ) {
    return esApiFactoryV2.getOrderContracts( rentalOrderId )
      .then( ( contracts ) => {
        return contracts.filter( ( contract ) => contract.type === "rental" || contract.type === "rapid_rent" );
      } )
      .then( ( rentalContracts ) => {
        if ( !rentalContracts.length ) {
          return "There are no rental contracts available.";
        }

        // as of now we only have one rental contract per order
        let contractId = rentalContracts[0].contract_id;
        let urls = getUrls();
        let domain = urls.properties.escontracts;

        return `${domain}/c/${contractId}`;
      } )
      .catch( ( err ) => err.message );
  }

  function getUsersRentals ( userId, forceReload ) {
    let that = this;

    if ( that.rentals && !forceReload )
      { return $q.when( that.rentals ); }
    else {
      return esApiFactoryV2.getUsersRentals( userId )
        .then( ( response ) => {
          that.rentals = response.data;
          service.setPhotoUrls( that.rentals );
          service.setEquipmentHasTracker( that.rentals );
          service.setIsFilterActive( that.rentals, true );

          return that.rentals;
        } );
    }
  }

  function getCompanyRentals ( companyId, params, forceReload ) {
    let that = this;

    if ( that.rentals && !forceReload ) {
      return $q.when( that.rentals );
    } else {
      return esApiFactoryV2.getCompanyRentals( companyId, params )
        .then( ( response ) => {
          that.rentals = response;
          service.setPhotoUrls( that.rentals );
          service.setEquipmentHasTracker( that.rentals );
          service.setIsFilterActive( that.rentals, true );

          return that.rentals;
        } );
    }
  }

  function getRentalById ( rentalId, forceReload ) {
    let that = this;

    if ( that.rental && !forceReload ) {
      return $q.when( that.rental );
    } else {
      return esApiFactoryV2.getRental( rentalId )
        .then( ( response ) => {
          that.rental = response.data;
          service.setPhotoUrls( [ that.rental ] );
          service.setEquipmentHasTracker( [ that.rental ] );
          service.setIsFilterActive( [ that.rental ], true );

          return that.rental;
        } );
    }
  }

  function patchRental ( rentalId, payload ) {
    return esApiFactoryV2.patchRental( rentalId, payload );
  }

  function patchCompanyRental ( companyId, rentalId, payload ) {
    return esApiFactoryV2.patchCompanyRental( companyId, rentalId, payload );
  }

  function postRentalRequest ( rentalData ) {
    return esApiFactoryV2.postRentalRequest( rentalData )
      .then( ( response ) => {
        return response.data;
      } );
  }

  function getRentalEstimate ( rental, isTrackUser ) {
    let estimatePayload = _pick( rental, [
      "order_id", "start_date", "end_date", "rental_status_id", "drop_off_delivery_required",
      "return_delivery_required", "equipment_id", "equipment_class_id",
      "price_per_hour", "price_per_day", "price_per_week", "price_per_month",
      "taxable", "rental_protection_plan_id" ] );

    estimatePayload.start_date = moment( estimatePayload.start_date ).valueOf();
    estimatePayload.end_date = moment( estimatePayload.end_date ).valueOf();

    if( !isTrackUser ) {
      return esApiFactoryV2.adminGetRentalEstimate( estimatePayload );
    } else {
      return esApiFactoryV2.getRentalEstimate( estimatePayload );
    }
  }

  function getOrderEstimate ( order ) {
    let pendingEstimates = _map( order.rentals, getRentalEstimate );


    return $q.all( pendingEstimates ).then( ( estimates ) => {
      let sum = 0;

      _each( estimates, ( estimate ) => { sum += estimate.billed_amount; } );

      return sum;
    } );
  }

  return {
    rentals: undefined,
    rental: undefined,
    getUsersRentals,
    getCompanyRentals,
    getRentalById,
    getRentalEstimate,
    getOrderEstimate,
    getHumanizedDuration,
    getRentalAgreementLink,
    calculateRentalDays,
    calculateRentalDurations,
    calculateRentalDurationsWithHours,
    patchRental,
    patchCompanyRental,
    postRentalRequest
  };
}

export default esRentalService;
