import ngModule from '../../../ng/index.js';
import template from './asset-status.html';
import './asset-status-ctrl.js';

ngModule.component('assetStatus', {
	template,
	controller: 'AssetStatusCtrl',
	bindings: {
		asset: '<',
	},
});
