import ngModule from '../ng/index.js';

import '@views/rentals/routes';
import '@views/rentals/rental-detials-view/rental-details-component';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.rentals', {
			url: '/rentals',
			template: `
				<ui-view/>
			`,
			redirectTo: 'home.rentals.search',
		})
		.state('home.rentals.search', {
			url: '/search',
			template: `
			  <rental-routes/>
			`,
		})
		.state('home.rentals.rental', {
			url: '/:rentalId',
			template: `
				<rental-details/>
			`,
		});
});
