import { simpleJsonApiClient } from '@equipmentshare/simple-json-api-client';

import { API_URL } from '@const/env';
import authService from './auth.service';

const setAuth = async (opts: RequestInit) => {
	const token = await authService.getApiToken();

	opts.headers = {
		...opts.headers,
		Authorization: `Bearer ${token}`,
	};

	return opts;
};

export const jsonApiClient = simpleJsonApiClient.instance({
	baseUrl: API_URL,
	setAuth,
});

export default jsonApiClient;
