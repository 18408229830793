import React from "react";
import PropTypes from "prop-types";

import AngularBridge from "./angular-bridge";

const UILink = ( { children, to, params, ...props } ) => {
  const href = AngularBridge.callServiceMethod( "$state", "href", to, params || {} );

  return (
    <a href={href} {...props}>{children}</a>
  );
};

UILink.propTypes = {
  children: PropTypes.any,
  params: PropTypes.object,
  to: PropTypes.string,
};
UILink.defaultProps = {
  children: null,
  params: null,
  to: null,
};

export default UILink;
