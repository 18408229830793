import React, { Component } from "react";
import PropTypes from "prop-types";
import _every from "lodash/every";
import classNames from "classnames";

import MultiCheckbox from "../multi-checkbox/multi-checkbox.js";

import multiSelectCurtainStyles from "./multi-select-curtain.module.css";

/**
 * MultiSelectCurtain component
 */
class MultiSelectCurtain extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      showCurtain: props.showCurtain,
    };

    this.toggleCurtain = this.toggleCurtain.bind( this );
    this.setWrapperRef = this.setWrapperRef.bind( this );
    this.handleClickOutside = this.handleClickOutside.bind( this );
  }

  UNSAFE_componentWillReceiveProps( nextProps ) {
    if ( nextProps.parentCanClose && this.state.showCurtain !== nextProps.showCurtain ) {
      this.setState( { "showCurtain": nextProps.showCurtain, } );
    }
  }

  shouldComponentUpdate( nextProps, nextState ) {
    let propsAreEqual = this.checkForEquality( this.props, nextProps, [ "disabled", "isVisible", "showCurtain", "values" ] );
    let statesAreEqual = this.checkForEquality( this.state, nextState, [ "showCurtain" ] );

    return !propsAreEqual || !statesAreEqual;
  }

  checkForEquality( prev, next, itemsToCompare ) {
    return itemsToCompare.every( ( item ) => prev[item] === next[item] );
  }

  setWrapperRef( node ) {
    this.curtainRef = node;
  }

  handleClickOutside( event ) {
    if ( this.curtainRef && !this.curtainRef.contains( event.target ) && this.state.showCurtain ) {
      this.setState( { "showCurtain": false } );
    }
  }

  toggleCurtain() {
    if ( !this.props.disabled ) {
      if ( this.props.clickOutsideToClose ) {
        if ( this.state.showCurtain ) {
          document.removeEventListener( "mousedown", this.handleClickOutside );
        } else {
          document.addEventListener( "mousedown", this.handleClickOutside );
        }
      }

      this.props.curtainToggle();

      this.setState( { "showCurtain": !this.state.showCurtain } );
    }
  }

  isAllSelected( values ) {
    return _every( values, [ "checked", true ] );
  }

  render() {
    if ( this.props.isVisible ) {
      let curtainClasses = classNames(
        multiSelectCurtainStyles.curtain,
        multiSelectCurtainStyles[this.props.direction]
      );
      let curtain = this.state.showCurtain
        ? (
          <div className={curtainClasses}>
            <MultiCheckbox
              className={multiSelectCurtainStyles.checkboxComponent}
              selectAllLabel={this.props.selectAllLabel}
              values={this.props.values}
              valuesToggle={this.props.valuesToggle}
            />
            {this.props.listFooterChildren}
          </div>
        )
        : null;

      return (
        <div className={multiSelectCurtainStyles.curtainContainer} ref={this.setWrapperRef}>
          <span onClick={this.toggleCurtain}>{this.props.toggleElement}</span>
          {curtain}
        </div>
      );
    }

    return null;
  }
}

MultiSelectCurtain.defaultProps = {
  clickOutsideToClose: false,
  curtainToggle: () => {},
  direction: "center",
  disabled: false,
  listFooterChildren: null,
  isVisible: true,
  parentCanClose: false,
  selectAllLabel: null,
  showCurtain: false,
  toggleElement: null,
  valuesToggle: () => {},
};

MultiSelectCurtain.propTypes = {
  /** Boolean to determine if clicking outside of the curtain will close it or not */
  clickOutsideToClose: PropTypes.bool,
  /** Callback for when a toggle element is clicked */
  curtainToggle: PropTypes.func,
  /** Direction to open curtain */
  direction: PropTypes.oneOf( [ "center", "left", "right" ] ),
  /** Disables the toggle functionality of the curtain */
  disabled: PropTypes.bool,
  /** Boolean to show/hide component */
  isVisible: PropTypes.bool,
  /** React element to be rendered after the list of values */
  listFooterChildren: PropTypes.element,
  /** Boolean to determine if passing showCurtain will actually toggle any changes */
  parentCanClose: PropTypes.bool,
  /** Text label for the select all option, if no value is passed in in no select all option will be present */
  selectAllLabel: PropTypes.string,
  /** Allows passing a boolean prop to open/close curtain */
  showCurtain: PropTypes.bool,
  /** React element that is clicked on to show the MultiSelectCurtain */
  toggleElement: PropTypes.element,
  /** Array of values that make up the list */
  values: PropTypes.arrayOf( PropTypes.shape( {
    /** is the value checked */
    checked: PropTypes.bool,
    /** id of the value */
    id: PropTypes.number,
    /** label for the checkbox to display */
    name: PropTypes.string
  } ) ).isRequired,
  /** Callback for when a value is clicked */
  valuesToggle: PropTypes.func,
};

export default MultiSelectCurtain;
