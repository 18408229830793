import angular from 'angular';
import ngModule from '../../../ng/index.js';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import toastService from '@services/toast.service.js';
import assetsService from '@services/assets.service';
import { difference } from '@helpers/utilities';
import { markerColors } from '@const/map';
import MapMarkers from '../../components/maps';
import errorHandlerService from '@services/error-handler.service.js';
import authService from '@services/auth.service.js';
import companyViewSettingsService from '../../../services/company-view-settings.service.js';

export default function VehicleEditFormCtrl(
	$scope,
	esVehicleService,
	esApiFactoryV2,
	User,
	esAdminRentalService,
	APPLICATION_ID
) {
	const companyViewSettings = { assetMarkerColors: {} };

	$scope.init = () => {
		delete $scope.vehicle;
		$scope.gettingVehicleData = true;
		$scope.fileChosen = false;
		$scope.saving = false;
		$scope.markets = esAdminRentalService.markets;
		$scope.companyId = User.getCompanyId();

		$scope.colorChoices = markerColors.choices.map((choice) => ({ backgroundColor: choice }));
		$scope.selectedColor = markerColors.default;

		assetsService
			.fetchAsset($scope.vehicleId)
			.then((asset) => {
				esVehicleService.vehicle = angular.copy(asset);

				$scope.vehicle = angular.copy(esVehicleService.vehicle);
				$scope.vehicle._hasDiagnosticCodes =
					$scope.vehicle.diagnostic_codes && $scope.vehicle.diagnostic_codes.length > 0;
				$scope.vehicle._hasTracker = !_isEmpty($scope.vehicle.current_status);
				$scope.vehicle.rental_branch_id = $scope.vehicle.rental_branch_id ? $scope.vehicle.rental_branch_id : null;
				$scope.vehicle.service_branch_id = $scope.vehicle.service_branch_id ? $scope.vehicle.service_branch_id : null;
				$scope.vehicle.tracker_id = $scope.vehicle.tracker ? $scope.vehicle.tracker.tracker_id : undefined;
				$scope.vehicle.odometer = $scope.vehicle.asset_status ? $scope.vehicle.asset_status.odometer : undefined;
				$scope.selectedColor = $scope.vehicle.marker_color ? $scope.vehicle.marker_color : markerColors.default;

				if (!$scope.assetColor) {
					fetchCompanyViewSettings();
				} else {
					$scope.selectedColor = $scope.assetColor;
				}
				$scope.gettingVehicleData = false;
				$scope.$digest();
			})
			.then($scope.fetchAssetStatus)
			.catch((e) => errorHandlerService.genericErrorHandler(e));
	};

	$scope.fetchAssetStatus = () => {
		return esApiFactoryV2.getAssetStatus($scope.vehicleId).then((assetStatus) => {
			$scope.vehicle.asset_status = assetStatus;
			$scope.vehicle.odometer = assetStatus ? assetStatus.odometer : undefined;
		});
	};

	$scope.$watch('vehicleId', (newId, oldId) => {
		if (newId !== oldId) {
			$scope.init();
		}
	});

	$scope.selectColor = (colorHex) => {
		$scope.selectedColor = colorHex;
	};

	$scope.onRentalBranchChange = (branch) => {
		if ($scope.vehicle) {
			$scope.vehicle.rental_branch_id = _get(branch, 'id', null);
		}
	};

	$scope.onMaintenanceBranchChange = (branch) => {
		if ($scope.vehicle) {
			$scope.vehicle.service_branch_id = _get(branch, 'id', null);
		}
	};

	$scope.save = () => {
		$scope.saving = true;
		const vehicle = $scope.vehicle;

		if (!vehicle.model && !vehicle.equipment_model_id) {
			toastService.showError(
				'Please either choose a model, or enter a custom model name if you cannot find the model.'
			);
		} else {
			const patchModel = createPatchModel(vehicle);

			return assetsService
				.updateAsset(vehicle.vehicle_id, patchModel)
				.then(() => {
					const assetId = $scope.vehicle.asset_id;

					companyViewSettings.assetMarkerColors[assetId] = $scope.selectedColor;

					if (typeof $scope.onSave !== 'function') {
						companyViewSettingsService.update(APPLICATION_ID, companyViewSettings);
					} else if ($scope.onSave) {
						$scope.onSave(vehicle, companyViewSettings);
					}

					esVehicleService.vehicle = angular.copy(vehicle);
					toastService.showSuccess('Success! Vehicle saved.');
				})
				.catch((e) => errorHandlerService.genericErrorHandler(e))
				.finally(() => {
					$scope.saving = false;
					$scope.$digest();
				});
		}
	};

	$scope.uploadImage = () => {
		const formData = new FormData();
		const userId = authService.cookies.userId.get();

		formData.append('file', $scope.file);
		formData.append('user_id', userId);

		return esVehicleService
			.uploadPhoto($scope.vehicleId, formData)
			.then((file) => {
				return assetsService
					.updateAsset($scope.vehicleId, { photo_id: file.data.photo_id })
					.then(() => {
						setPhotoOnSelectedVehicle(file.data);
						setPhotoUrlInCollection();
						$scope.uploadSuccess = true;
						$scope.uploadFailure = false;
						$scope.$digest();
					})
					.catch((e) => errorHandlerService.genericErrorHandler(e));
			})
			.catch((err) => {
				$scope.uploadFailure = true;
				if (err.status == 400) {
					$scope.failureMsg = 'Cannot upload file with provided extension. Please make sure file is a photo.';
				}
				if (err.status == 500) {
					$scope.failureMsg = 'There was an error uploading the photo. Please try again later.';
				}
			});
	};

	function fetchCompanyViewSettings() {
		companyViewSettingsService.fetch(APPLICATION_ID).then((preferences) => {
			const selectedColor = MapMarkers.fetchColorForAsset($scope.vehicle.asset_id, preferences);

			if (selectedColor) {
				$scope.selectedColor = selectedColor;
			}
		});
	}

	function createPatchModel(vehicle) {
		const differences = difference(vehicle, esVehicleService.vehicle);

		return {
			custom_name: differences.custom_name,
			rental_branch_id: differences.rental_branch_id,
			equipment_make_id: differences.equipment_make_id,
			equipment_model_id: differences.equipment_model_id || null,
			model: differences.model,
			year: differences.year,
			driver_name: differences.driver_name,
			odometer: differences.odometer,
			vin: differences.vin,
			tracker_id: differences.tracker_id === '' ? null : differences.tracker_id,
			maintenance_group_id: differences.maintenance_group_id,
		};
	}

	function setPhotoOnSelectedVehicle(file) {
		$scope.file.filename = file.filename;
		$scope.vehicle.photo = $scope.file;
		$scope.vehicle._photoUrl = esApiFactoryV2.getPhotoUrl($scope.vehicle);
		esVehicleService.vehicle = angular.copy($scope.vehicle);
	}

	function setPhotoUrlInCollection() {
		if (esVehicleService.vehicles) {
			const foundVehicleIndex = _findIndex(esVehicleService.vehicles, (vehicle) => {
				return vehicle.vehicle_id === $scope.vehicle.vehicle_id;
			});

			if (foundVehicleIndex != -1) {
				esVehicleService.vehicles[foundVehicleIndex]._photoUrl = $scope.vehicle._photoUrl;
			}
		}
	}

	$scope.init();
}

ngModule.controller('VehicleEditFormCtrl', VehicleEditFormCtrl);
