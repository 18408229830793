import React from 'react';
import PropTypes from 'prop-types';

import styles from './rental-create-form-components.module.css';

const SectionWrapper = ({ header, subHeader, children, rightHeaderComponent = null }) => (
	<div className={styles.section}>
		{!subHeader && !rightHeaderComponent ? (
			<h3>{header}</h3>
		) : (
			<div className={styles.headerGroup}>
				<h3>{header}</h3>
				{subHeader && <h4>{subHeader}</h4>}
				{rightHeaderComponent && rightHeaderComponent}
			</div>
		)}
		{children}
	</div>
);

SectionWrapper.propTypes = {
	header: PropTypes.string.isRequired,
	subHeader: PropTypes.string,
	children: PropTypes.node.isRequired,
	rightHeaderComponent: PropTypes.node,
};

export default SectionWrapper;
