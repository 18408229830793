import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import { EuiBadge, COLORSLIGHT } from '@equipmentshare/ds2';

import RentalProtectionPlanPicker from '@components/rental-protection-plan-picker';

import SectionWrapper from './section-wrapper';

import styles from '../rental-create-form-components/rental-create-form-components.module.css';
import RentalFloaterText from '../rental-create-form/rental-floater';

const RentalProtectionPlanSelect = ({
	rentalProtectionPlanId,
	onChange,
	rentalFloater,
	rentalFloaterStatus,
	required,
	taxable = true,
	orderId,
	...rest
}) => {
	const taxableComponent = useCallback(
		() => (
			<EuiBadge className={styles.chip} color={COLORSLIGHT.lightestShade}>
				{taxable ? 'Taxable' : 'Tax Exempt'}
			</EuiBadge>
		),
		[taxable]
	);

	const determineDefaultRPP = () => {
		if (required || !!rentalProtectionPlanId) {
			return rentalProtectionPlanId;
		} else {
			return null;
		}
	};

	return (
		<SectionWrapper
			header="Rental Protection Plan"
			subHeader={required ? '(required)' : ''}
			rightHeaderComponent={taxableComponent()}
		>
			<RentalProtectionPlanPicker
				className={styles.extendedInput}
				onChange={onChange}
				name="rentalProtectionPlanId"
				selectedRentalProtectionPlanId={determineDefaultRPP()}
				required={required}
				disabled={!required}
				orderId={orderId}
				{...rest}
			/>
			{!_isEmpty(rentalFloater) && (
				<RentalFloaterText rentalFloater={rentalFloater} rentalFloaterStatus={rentalFloaterStatus} />
			)}
		</SectionWrapper>
	);
};

RentalProtectionPlanSelect.propTypes = {
	rentalProtectionPlanId: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	rentalFloaterStatus: PropTypes.object,
	rentalFloater: PropTypes.object,
	required: PropTypes.bool,
	orderId: PropTypes.number,
	taxable: PropTypes.bool,
};

export default RentalProtectionPlanSelect;
