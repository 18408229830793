import ngModule from '../ng/index.js';
import '@views/payments/routes.js';

ngModule.config(($stateProvider) => {
	$stateProvider
		.state('home.payments', {
			url: '/payments',
			template: '<payments-routes flex="100" />',
			redirectTo: 'home.payments.search',
		})
		.state('home.payments.create', {
			url: `/create?${['companyId', 'amount', 'selectedInvoices', 'selectedCreditNotes', 'selectedPayments'].join(
				'&'
			)}`,
		})
		.state('home.payments.search', {
			url: `/search?${[
				'status',
				'order',
				'stripeId',
				'customer',
				'isErpSynced',
				'hasBalance',
				'before',
				'after',
				'reference',
				'checkNumber',
				'enteredAsPrepayment',
				'amount',
			].join('&')}`,
		})
		.state('home.payments.details', {
			url: '/:paymentId',
		});
});
