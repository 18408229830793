export const UPDATE_RATE_TYPE = 'UPDATE_RATE_TYPE';
export const RESET_RATE_TYPE = 'RESET_RATE_TYPE';

export const INSERT_FILTER_ROW = 'INSERT_FILTER_ROW';
export const REMOVE_FILTER_ROW = 'REMOVE_FILTER_ROW';
export const UPDATE_FILTER_ROW = 'UPDATE_FILTER_ROW';
export const RESET_FILTER_ROWS = 'RESET_FILTER_ROWS';

export const UPDATE_ACTIVE_FILTER_GROUPS = 'UPDATE_ACTIVE_FILTER_GROUPS';
export const RESET_FILTER_GROUPS = 'RESET_FILTER_GROUPS';

export const SET_FILTER_QUERY = 'SET_FILTER_QUERY';
export const RESET_FILTER_QUERY = 'RESET_FILTER_QUERY';

const actions = {
	UPDATE_RATE_TYPE,
	RESET_RATE_TYPE,

	INSERT_FILTER_ROW,
	REMOVE_FILTER_ROW,
	UPDATE_FILTER_ROW,
	RESET_FILTER_ROWS,

	UPDATE_ACTIVE_FILTER_GROUPS,
	RESET_FILTER_GROUPS,

	SET_FILTER_QUERY,
	RESET_FILTER_QUERY,
};

export default actions;
