import angular from 'angular';
import template from './search-bar.html';
import './search-bar-ctrl.js';

angular.module("esNgComponents").directive('searchBar', function() {
  return {
    template,
    controller: 'SearchBarCtrl',
    scope: {
      query: '=',
      placeholderText: '@',
      firstIcon: '@',
      lastIcon: '@',
      ngModelOptions: '<?'
    }
  };
});
