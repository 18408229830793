import * as React from "react";
import _debounce from "lodash/debounce";
import PropTypes from "prop-types";

export default class TextTruncator extends React.PureComponent {
  constructor( props ) {
    super( props );

    this.debounceProcessContent = _debounce( this.processContent, 200 );
  }

  componentDidMount() {
    this.text = this.container.innerHTML;
    this.allowedHeight = this.getAllowedHeight();

    this.processContent();

    window.addEventListener( "resize", this.debounceProcessContent, false );
  }

  componentDidUpdate() {
    this.text = this.container.innerHTML;
    this.allowedHeight = this.getAllowedHeight();

    this.processContent();
  }

  componentWillUnmount() {
    window.removeEventListener( "resize", this.debounceProcessContent, false );
  }

  getAllowedHeight() {
    let lineHeight = parseFloat( this.getComputedStyle( "line-height" ) );

    return this.props.lines * lineHeight;
  }

  getComputedStyle( name ) {
    return document.defaultView
      .getComputedStyle( this.container, null )
      .getPropertyValue( name );
  }

  processContent() {
    if ( this.text ) {
      this.container.innerHTML = "";

      this.truncateContent();
    }
  }

  truncateContent( end = 1 ) {
    // If there's room for the full text,
    // then show it all without an ellipsis.
    if ( end >= this.text.length ) {
      this.container.innerHTML = this.text;
    }

    // If the content exceeds the allowed height,
    // reduce the shown text by one character.
    else if ( this.container.offsetHeight > this.allowedHeight ) {
      this.container.innerHTML = `${this.text.slice( 0, end - 1 )}...`;
    }

    // If there's room to grow,
    // add another character and check again.
    else {
      this.container.innerHTML = `${this.text.slice( 0, ++end )}...`;

      this.truncateContent( end );
    }
  }

  render() {
    return (
      <div
        ref={( node ) => { this.container = node; }} // eslint-disable-line react/jsx-no-bind
        style={{ width: "100%", wordWrap: "break-word" }}
      >
        {this.props.children}
      </div>
    );
  }
}

TextTruncator.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number.isRequired,
};
