import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import { EuiButton, EuiBadge, COLORSLIGHT } from '@equipmentshare/ds2';
import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

import rentalsService from '@services/rentals.service';
import { formatCurrency } from '../../js/formatting';
import { ES_COMPANY_ID } from '@const/es';
import errorHandlerService from '@services/error-handler.service';

import styles from './rental-estimate.module.css';

const RentalEstimate = ({
	asset,
	rental,
	taxable,
	startDate,
	endDate,
	rates,
	equipmentClassId,
	rentalProtectionPlanId,
}) => {
	const [estimate, setEstimate] = useState(undefined);
	const [isLoading, setIsLoading] = useState(false);

	const calculateEstimate = useCallback(
		async (showLoading) => {
			const payload = {
				order_id: rental.order.order_id,
				rental_status_id: rental.rental_status_id,
				rental_protection_plan_id: rentalProtectionPlanId || rental.rental_protection_plan_id,
				return_delivery_required: rental.return_delivery_required,
				drop_off_delivery_required: rental.drop_off_delivery_required,
				// Mobile tool trailer does not do deliveries, set price to 0
				drop_off_charge: rental.drop_off_delivery?.charge || 0,
				return_charge: rental.return_delivery?.charge || 0,
				current_location_id: rental.current_location.location_id,
				start_date: startDate?.valueOf() || moment().startOf('day').valueOf(),
				end_date: endDate?.valueOf() || moment().endOf('day').valueOf(),
				equipment_class_id: equipmentClassId || rental.equipment_class_id,
				equipment_id: asset?.equipment_id || null,
				price_per_hour: rates.hourlyRate || rental.price_per_hour,
				price_per_day: rates.dailyRate || rental.price_per_day,
				price_per_week: rates.weeklyRate || rental.price_per_week,
				price_per_month: rates.monthlyRate || rental.price_per_month,
			};

			// Short circuit if ES is not the Renter
			if (rental.order.supplier_company_id && rental.order.supplier_company_id !== ES_COMPANY_ID) {
				setEstimate({
					line_item_amount: 0,
					tax_amount: 0,
					billed_amount: 0,
				});
				return;
			}

			setIsLoading(showLoading);
			try {
				const result = await rentalsService.invoiceEstimate(payload);
				setEstimate(result);
			} catch (error) {
				errorHandlerService.genericErrorHandler(
					error,
					'Failed to get an updated Rental Estimate. Please contact Engineering for additional support.'
				);
			} finally {
				setIsLoading(false);
			}
		},
		[
			asset?.equipment_id,
			endDate,
			equipmentClassId,
			rates.dailyRate,
			rates.hourlyRate,
			rates.monthlyRate,
			rates.weeklyRate,
			rental.current_location.location_id,
			rental.drop_off_delivery?.charge,
			rental.drop_off_delivery_required,
			rental.equipment_class_id,
			rental.order.order_id,
			rental.order.supplier_company_id,
			rental.price_per_day,
			rental.price_per_hour,
			rental.price_per_month,
			rental.price_per_week,
			rental.rental_protection_plan_id,
			rental.rental_status_id,
			rental.return_delivery?.charge,
			rental.return_delivery_required,
			rentalProtectionPlanId,
			startDate,
		]
	);

	// Recalculate Estimate if it was already calculated when the Rental is updated or select values are changed
	useEffect(() => {
		if (!_isEmpty(estimate)) {
			calculateEstimate(false);
		}
	}, [rental, startDate, endDate, equipmentClassId, rentalProtectionPlanId]); //Do not include estimate or calculate estimate in dep list due to circular dependency

	const handleRentalEstimateClick = () => {
		calculateEstimate(true);
	};

	return (
		<div className={styles.row}>
			{isLoading && <LoadingOverlay />}
			<EuiBadge className={styles.taxChip} color={COLORSLIGHT.lightestShade}>
				{taxable ? 'Taxable' : 'Tax Exempt'}
			</EuiBadge>
			<EuiButton color="text" size="s" onClick={handleRentalEstimateClick}>
				Calculate Rental Estimate
			</EuiButton>
			{estimate && (
				<div className={styles.bold}>
					<span className={styles.spacing}>Rental charges: {formatCurrency(estimate.line_item_amount, 2, '--')}</span>
					<span className={styles.spacing}>Tax: {formatCurrency(estimate.tax_amount, 2, '--')}</span>
					<span className={styles.spacing}>
						Estimated Total Cost: {formatCurrency(estimate.billed_amount, 2, '--')}
					</span>
				</div>
			)}
		</div>
	);
};

RentalEstimate.propTypes = {
	asset: PropTypes.object,
	endDate: PropTypes.instanceOf(Date),
	rental: PropTypes.object,
	startDate: PropTypes.instanceOf(Date),
	rates: PropTypes.shape({
		hourlyRate: PropTypes.number,
		dailyRate: PropTypes.number,
		weeklyRate: PropTypes.number,
		monthlyRate: PropTypes.number,
	}),
	rentalProtectionPlanId: PropTypes.number,
	equipmentClassId: PropTypes.number,
	taxable: PropTypes.bool,
};

export default RentalEstimate;
