import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Circle from "./circle/circle.js";
import Dashboard from "./dashboard/dashboard.js";
import ProgressText from "./components/progress-text.js";

import styles from "./progress.module.css";

export const validProgress = ( progress ) => {
  if ( !progress || progress < 0 ) {
    return 0;
  } else if ( progress > 100 ) {
    return 100;
  }

  return progress;
};

export function Progress( props ) {
  let {
    className, percent, progressType, small, strokeWidth, showInfo, strokeColor, strokeLinecap, labelOverride,
    ...restProps
  } = props;

  let progressStatus = parseInt( percent.toString(), 10 ) >= 100 && !progressType ? "success" : ( progressType || "normal" );
  let progressInfo = showInfo
    ? ( <ProgressText labelOverride={labelOverride} percent={percent} progressType={progressStatus} /> )
    : null;

  let progressWidth = validProgress( percent );
  let percentStyle = {
    width: `${progressWidth}%`,
    height: strokeWidth || ( small ? 6 : 8 ),
    background: strokeColor,
    borderRadius: strokeLinecap === "square" ? 0 : "100px",
  };

  let classString = classNames( styles.progress, styles.progressLine, {
    [styles.progressStatusException]: progressStatus === "exception",
    [styles.progressStatusSuccess]: progressStatus === "success",
    [styles.progressShowInfo]: showInfo,
    [styles.progressSmall]: small,
  }, className );

  return (
    <div {...restProps} className={classString}>
      <div className={styles.progressOuter}>
        <div className={styles.progressInner}>
          <div className={styles.progressBg} style={percentStyle} />
        </div>
      </div>
      {progressInfo}
    </div>
  );
}

Progress.Circle = Circle;
Progress.Dashboard = Dashboard;

Progress.propTypes = {
  /** additional classes applied to the progress bar */
  className: PropTypes.string,
  /** a function that accepts two arguments: `progressType` and `percent` and returns a JSX node or string */
  labelOverride: PropTypes.func,
  /** How much of the progress is filled in */
  percent: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string
  ] ),
  /** the type of progress bar to use and any associated icons */
  progressType: PropTypes.oneOf( [
    "normal",
    "exception",
    "success"
  ] ),
  /** should the info be displayed next to the bar */
  showInfo: PropTypes.bool,
  /** renders the bar in a smaller height */
  small: PropTypes.bool,
  /** color of progress bar */
  strokeColor: PropTypes.string,
  /** type of end used for the filled in progress */
  strokeLinecap: PropTypes.oneOf( [
    "round",
    "square"
  ] ),
  /** How thick the progress bar is in pixels */
  strokeWidth: PropTypes.oneOfType( [
    PropTypes.number,
    PropTypes.string
  ] ),
};

Progress.defaultProps = {
  className: undefined,
  labelOverride: undefined,
  percent: 0,
  progressType: undefined,
  showInfo: true,
  small: false,
  strokeColor: undefined,
  strokeLinecap: "round",
  strokeWidth: undefined,
};

export default Progress;
