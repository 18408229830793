import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';
import { RedirectFromSlimcdPayment } from './redirect-from-slimcd-payment';

const SearchView = React.lazy(() => import('./search-view/search-view'));
const DetailsRoutes = React.lazy(() => import('./details-view/routes'));

const Routes = () => (
	<Suspense fallback={<LoadingOverlay />}>
		<Switch>
			<Route path="/home/companies/search">
				<SearchView />
			</Route>
			<Route path="/home/companies/:companyId">
				<DetailsRoutes />
			</Route>
			<Route path="/home/companies/">
				<RedirectFromSlimcdPayment />
			</Route>
			<Redirect from="/home/companies" to="/home/companies/search" />
		</Switch>
	</Suspense>
);

export default Routes;
