import React, { Suspense } from 'react';
import { react2angular } from 'react2angular';
import { Route, Switch, Redirect } from 'react-router-dom';

import { LoadingOverlay } from '@equipmentshare/es-admin-eui-simple-components';

import withGlobalProviders from '@components/with-global-providers';
import ngModule from '../../ng/index';

const CreateView = React.lazy(() => import('./create-view/create-view'));
const SearchView = React.lazy(() => import('./search-view/search-view'));
const PaymentDetailsView = React.lazy(() => import('./details-view/details-view'));

const Routes = () => (
	<Suspense fallback={<LoadingOverlay />}>
		<Switch>
			<Route path="/home/payments/create">
				<CreateView />
			</Route>
			<Route path="/home/payments/search">
				<SearchView />
			</Route>
			<Route path="/home/payments/:paymentId">
				<PaymentDetailsView />
			</Route>
			<Redirect from="/home/payments" to="/home/payments/search" />
		</Switch>
	</Suspense>
);

ngModule.component('paymentsRoutes', react2angular(withGlobalProviders(Routes)));

export default Routes;
