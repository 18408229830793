import esApi from '@services/es-api.service';

export async function update(id, payload) {
	return await esApi.request(esApi.url`/admin/assets/${id}/owner`, null, { body: payload, method: 'PATCH' });
}

const assetOwnerService = {
	update,
};

export default assetOwnerService;
