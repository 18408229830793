import React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { useCancellation } from '../../../providers/cancellation-context';
import PopoverAnchorOverlay from './popover-anchor-overlay';
import RentalCancellationPopover from './rental-cancellation-popover';
import { useFlags } from '@equipmentshare/feature-flags';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1,
			refetchOnWindowFocus: false,
		},
	},
});

const RentalCancellationPortal = () => {
	const [state, _, hideCancellation] = useCancellation();
	const { enableDcoCancelRentalReason } = useFlags();

	if (!state.isOpen || !state.buttonElement || !state.container) {
		return null;
	}

	if (!enableDcoCancelRentalReason) {
		state.cancelRental();
		hideCancellation();
		return null;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<PopoverAnchorOverlay buttonElement={state.buttonElement} container={state.container}>
				<RentalCancellationPopover
					rentalId={state.rentalId}
					cancelRental={state.cancelRental}
					onClose={hideCancellation}
				/>
			</PopoverAnchorOverlay>
		</QueryClientProvider>
	);
};

export default RentalCancellationPortal;
